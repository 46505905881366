import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import {getAllStatusRequest} from "../RequestsSlice";

const useRequestOptions = () => {
  const allReq = useSelector(getAllStatusRequest);

  const requestNumberOptions = useMemo(() => {
    return allReq.length > 0
      ? allReq.map(item => {
          return {title: item.requestNumber, value: item.requestNumber};
        })
      : [];
  }, [allReq]);

  return requestNumberOptions;
};

export default useRequestOptions;
