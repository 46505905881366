import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../../conteiners/ReactTable";
import ModCheckList from "../../../CheckLists/ModCheckList";
import {selectCurrentSkuRequest} from "../../../redux/selectors";

import useWarehouseLowerMainTablesPartService from "./useWarehouseLowerMainTablesPartService";

const WarehouseLowerMainTablesPart = ({setCheckedSp, checkedSp}) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const currentSkuRequest = useSelector(selectCurrentSkuRequest);

  const [isModalOpen, setModalOpen] = useState(false);

  const {
    onWarehouseRqstsTableRowDoubleClick,
    onMainWarehouseTableSelect,
    onWarehouseStockPoolCheck,
    warehouseMainTableData,
    warehouseSpTpTableData,
    warehouseMainTableColumns,
    warehouseSpTpTableColumns,
  } = useWarehouseLowerMainTablesPartService(setCheckedSp, setModalOpen);

  return (
    <div className="fc w100 gap mb nowrap h100" style={{overflow: "auto"}}>
      <ReactTable
        columns={warehouseSpTpTableColumns}
        defaultData={warehouseSpTpTableData}
        setMultiSelect={onWarehouseStockPoolCheck}
        enableMultiSelection={true}
        defaultMultiSelection={checkedSp}
        style={{width: "21%"}}
      />

      <ReactTable
        columns={warehouseMainTableColumns}
        defaultData={warehouseMainTableData}
        onSelect={onMainWarehouseTableSelect}
        current={currentSkuRequest?.skuRequestId}
        className="w100 pb scrollX"
        onRowDoubleClick={onWarehouseRqstsTableRowDoubleClick}
        style={{width: "78%"}}
      />
      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
    </div>
  );
};

export default WarehouseLowerMainTablesPart;
