import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getSuccess} from "../../../../../../../utilities/toasts";
import {
  addConfirmingDoc,
  payUp,
  removeConfirmingDoc,
} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import {getCurrentPayment} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

import useDocTypesOptionsService from "./utils/hooks/useDocTypesOptionsService";

const usePaidBlockService = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentPayment = useSelector(getCurrentPayment);
  //   const requestId = useSelector(selectCurrentPaymentRequestId);

  const {values} = useFormikContext();
  const {
    confirmingDocNumber: docNumber,
    confirmingDocType: docTypeId,
    confirmingDocNote: note,
    confirmingDocSum: paymentSum,
  } = values;

  const paymentId = currentPayment?.paymentId;
  const isPaidBlockDisabled = !currentPayment;
  const arePrePdfFieldsDisabled = !currentPayment || currentPayment?.confirmingDoc;
  const isConfirmingDocPickerDisabled = Boolean(
    isPaidBlockDisabled || !values.confirmingDocType || !values.confirmingDocNumber || currentPayment?.confirmingDoc,
  );
  const confirmingDocPickerTitle = currentPayment?.confirmingDoc
    ? t("Confirming Doc has already been added!")
    : isConfirmingDocPickerDisabled
    ? t("Select payment and enter confirming doc`s number and type first!")
    : t("Upload Confirming Doc");
  const confirmingDoc = currentPayment?.confirmingDoc;
  const isPayUpBtnDisabled = Boolean(currentPayment?.paymentSum || !values.confirmingDocSum || !confirmingDoc);
  const payUpBtnTitle = currentPayment?.paymentSum
    ? t("Document has already been payed!")
    : isPayUpBtnDisabled
    ? t("Upload Confirming Doc and enter document sum first!")
    : "";
  const isRemoveConfirmingDocBtnDisabled = Boolean(currentPayment?.paymentSum || !confirmingDoc);

  const docTypeOptions = useDocTypesOptionsService();

  function onPayUpBtnClick() {
    dispatch(payUp({paymentId, data: {note, paymentSum}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess(t("Payment is successful!"));
        // dispatch(getPaymentsForRequest({requestId}));
      }
    });
  }
  function onDeleteConfirmingDocBtnClick() {
    dispatch(removeConfirmingDoc(paymentId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess(t("Payment document has been removed"));
      }
    });
  }

  function onFileAdding(file) {
    const formdata = new FormData();
    const filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", docNumber);
    formdata.append("docTypeId", docTypeId);

    if (formdata.has("doc")) {
      dispatch(addConfirmingDoc({paymentId, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("Payment document has been created"));
        }
      });
    }
  }

  return {
    onPayUpBtnClick,
    onDeleteConfirmingDocBtnClick,
    docTypeOptions,
    onFileAdding,
    isPaidBlockDisabled,
    isConfirmingDocPickerDisabled,
    confirmingDocPickerTitle,
    isPayUpBtnDisabled,
    isRemoveConfirmingDocBtnDisabled,
    payUpBtnTitle,
    arePrePdfFieldsDisabled,
  };
};

export default usePaidBlockService;
