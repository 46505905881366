import React from "react";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

const PageContainer = ({title, children, loading}) => {
  return (
    <div className="limitedSize">
      <p className="limitedSize_label">{title}</p>
      <div className="overflow_container">
        <div className="limitedSize__container">{loading ? <Spinner /> : children}</div>
      </div>
    </div>
  );
};
PageContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default PageContainer;
