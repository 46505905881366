import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {formPlaceAddress} from "../common/functions";
import {ORDER_TABLE_COLUMNS} from "../LogModRqstCheckList/constants";
import {getCurentCheckListDoc, selectCurrentSkuRequest} from "../redux/selectors";

const useLogistCheckList = () => {
  const {t} = useTranslation();
  const curentSkuRequest = useSelector(selectCurrentSkuRequest);

  // const [curentFile, setCurentFile] = useState([]);
  const initialValues = {
    //HeaderInputRow
    managerFea: curentSkuRequest?.request?.managerFea?.name || "",
    spNumber: curentSkuRequest?.stockPool?.stockPoolNumber || "",
    tpNumber: curentSkuRequest?.stockPool?.cargo?.cargoNumber || "",
    managerCO: curentSkuRequest?.order?.co?.managerCo?.name || "",
    departurePlaceAddress: formPlaceAddress(curentSkuRequest?.stockPool?.cargo?.startPoint, t) || "",
    deliveryPlaceAddress: formPlaceAddress(curentSkuRequest?.stockPool?.cargo?.endPoint, t) || "",
    managerLogist: curentSkuRequest?.stockPool?.logist?.name || "",
    // transportTo: "",
    executionDate: curentSkuRequest?.requestDates?.[7]?.actualDate || "",
    //DeliveryInfo
    shippingInfoArea: "",
    todo: "",
    //GroupAndType
    orderNumber: "",
    //DocumentsInfo
    documentType: "",
    documentNumber: "",
    //ShipmentInfo
    destination: "",
    shipmentRqstNumber: "",

    groupAndTypeInfo: curentSkuRequest?.productGroups
      .map(prod => prod?.productGroup?.name + ", " + prod?.productType?.name)
      .join(" \n"),
  };
  const headerInputNames = [
    {name: "managerCO", label: "CO Manager", readOnly: true, width: "15rem"},
    {name: "managerFea", label: "Manager FEA", readOnly: true, width: "15rem"},
    {name: "managerLogist", label: "Logist", readOnly: true, width: "15rem"},
    {name: "tpNumber", label: "TP №", autolabel: true, readOnly: true},
  ];
  const orderTableColumns = useColumns(ORDER_TABLE_COLUMNS);
  const orderTableData = [];
  // useMemo(
  //   () =>
  //     curentSkuRequest?.stockPool?.dates.map((date, index) => {
  //       return {
  //         id: date?.dateId ?? " - ",
  //         number: ++index,
  //         eventDate: dateFormat(date?.actualDate) ?? " - ",
  //         orderNumber: curentSkuRequest?.order?.orderNumber ?? " - ",
  //         requestNumber: curentSkuRequest?.request?.requestNumber ?? " - ",
  //         spNumber: curentSkuRequest?.stockPool?.stockPoolNumber ?? " - ",
  //         tpNumber: curentSkuRequest?.stockPool?.cargo?.cargoNumber ?? " - ",
  //         actionPlace: " - ",
  //         eventType: (i18n.language === "en" ? date?.dateType?.nameEng : date?.dateType?.nameUkr) ?? " - ",
  //         plannedEvent: " - ",
  //         note: date?.note ?? " - ",
  //       };
  //     }) ?? [],
  //   [curentSkuRequest?.skuRequestId],
  // );

  const curentDoc = useSelector(getCurentCheckListDoc);
  const getDocs = curentDoc => {
    const docs = [];
    if (curentDoc?.id === -1) {
      const orderFiles = curentSkuRequest?.request?.proforms?.map(pf => pf.pdfFileUrl) || [];
      return orderFiles;
    }
    if (curentDoc && curentDoc?.url) docs.push(curentDoc.url);
    return docs;
  };
  const pdfFileArray = getDocs(curentDoc);

  const documentsTableData = useMemo(() => {
    var docs = [];
    var counter = 1;
    curentSkuRequest?.proforms?.map(pdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(pdoc?.createdAt) || "",
        docType: "Проформа",
        docNumber: "Prof № " + pdoc?.proformaNumber || "",
        createdBy: "",
        url: pdoc?.pdfFileUrl || "",
      });
      counter++;
    });
    curentSkuRequest?.transportDocs?.map(tdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(tdoc?.dateFrom) || "",
        docType: tdoc?.docType.nameUkr || "",
        docNumber: tdoc?.docNumber || "",
        createdBy: "",
        url: tdoc?.fileUrl || "",
      });
      counter++;
    });
    return docs;
  }, [curentSkuRequest]);

  return {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    documentsTableData,
    curentSkuRequest,
    curentDoc,
    getDocs,
  };
};

export default useLogistCheckList;
