import React from "react";
import {Route, Routes} from "react-router-dom";

import FactoryBlock from "../Components/Logist/FactoryBlock/FactoryBlock";
import FinalDestinationBoss from "../Components/Logist/FinalDestinationBoss/FinalDestinationBoss";
import FinalDestinationManager from "../Components/Logist/FinalDestinationManager/FinalDestinationManager";
import LogSettings from "../Components/Logist/LogSettings/LogSettings";
import SleepingRequests from "../Components/Logist/SleepingRequests/SleepingRequests";
import TpListBoss from "../Components/Logist/TpListBoss/TpListBoss";
import TpListManager from "../Components/Logist/TpListManager/TpListManager";
import TransportationTP from "../Components/Logist/TransportationTP/TransportationTP";
import WarehouseBlock from "../Components/Logist/WarehouseBlock/WarehouseBlock";
// import CheckList from "../Components/Logist/TransportPool/TPmod/CheckList";

const LogistPage = () => {
  return (
    <Routes>
      <Route path="/sleeping-requests" element={<SleepingRequests />} />
      <Route path="/bos/tp-pool-preregister" element={<TpListBoss />} />
      <Route path="/tp-pool-register" element={<TpListManager />} />
      <Route path="/tp-pool-factory" element={<FactoryBlock />} />
      <Route path="/tp-transportation" element={<TransportationTP />} />
      <Route path="/warehouse" element={<WarehouseBlock />} />
      <Route path="/settings" element={<LogSettings />} />
      <Route path="/bos/final-destination" element={<FinalDestinationBoss />} />
      <Route path="/final-destination-Manager" element={<FinalDestinationManager />} />
    </Routes>
  );
};

export default LogistPage;
