import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import ReactTable from "../../../conteiners/ReactTable";
import useTPModalTableService from "../../TransportPool/TPmod/parts/useTPTableService";

import useShipmentInfo from "./useShipmentInfo";

const ShipmentInfo = ({headerNumber = 5, height = "7.5em", type, packingSpaces}) => {
  const {t} = useTranslation();
  const {shipmentTableColumns, shipmentTableData} = useShipmentInfo({packingSpaces});
  const {columns, defaultData, onSelect} = useTPModalTableService();

  return (
    <div>
      <div className="fc jcsb aibl nowrap">
        <div className="header-block">{`${headerNumber}. ${t("Shipment")}:`}</div>
        {/* <TextInput name="destination" autolabel width="5em" className="mb0_5" />
        <TextInput label={t("Request №")} name="shipmentRqstNumber" autolabel className="mb0_5" width="10em" /> */}
      </div>

      {/* <ReactTable
        selectable={false}
        columns={shipmentTableColumns}
        defaultData={shipmentTableData}
        style={{height: "80px"}}
      /> */}
      {type === "TP" ? (
        <ReactTable
          defaultData={defaultData}
          columns={columns}
          onSelect={onSelect}
          current={null}
          className="scrollX tableTac"
          loading={false}
          style={{height: height}}
        />
      ) : (
        <ReactTable
          className="tableTac"
          selectable={false}
          columns={shipmentTableColumns}
          defaultData={shipmentTableData}
          style={{height: height}}
        />
      )}
    </div>
  );
};

ShipmentInfo.propTypes = {headerNumber: PropTypes.string};
export default ShipmentInfo;
