import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../../hook/axios.hook";

const PROFORMS_PATH = "/proforms";

export const fetchProformById = createAsyncThunk("proforms/getProform", async proformId =>
  fetchData(`${PROFORMS_PATH}/get/${proformId}`, "get"),
);

export const fetchForRequestById = createAsyncThunk("proforms/getForRequest", async requestId =>
  fetchData(`${PROFORMS_PATH}/getForRequest/${requestId}`, "get"),
);

export const fetchProforms = createAsyncThunk("proforms/getAllProforms", async () =>
  fetchList(`${PROFORMS_PATH}/getAll`, "get"),
);

export const addProform = createAsyncThunk("proforms/addProform", async ({requestId, data}) =>
  fetchData(`${PROFORMS_PATH}/add/${requestId}`, "post", data),
);

export const sendProformForControl = createAsyncThunk("proforms/sendForControl", async ({requestId, data}) =>
  fetchData(`${PROFORMS_PATH}/sendForControl/${requestId}`, "post", data),
);

export const approveProform = createAsyncThunk("proforms/approveProform", async proformaId =>
  fetchData(`${PROFORMS_PATH}/approve/${proformaId}`, "patch"),
);

export const declineProform = createAsyncThunk("proforms/declineProform", async proformaId =>
  fetchData(`${PROFORMS_PATH}/decline/${proformaId}`, "patch"),
);

export const sendProformToFactory = createAsyncThunk("proforms/sendProformToFactory", async requestId =>
  fetchData(`${PROFORMS_PATH}/sendToFactory/${requestId}`, "patch"),
);

//proform approvals
const PROFORM_APPROVALS_PATH = `${PROFORMS_PATH}/approvals`;

export const fetchForCurrentApproval = createAsyncThunk("proforms/approvals/getForCurrent", async () =>
  fetchList(`${PROFORM_APPROVALS_PATH}/getForCurrent`, "get"),
);

export const fetchForUserApprovals = createAsyncThunk("proforms/approvals/getForUser", async userId =>
  fetchList(`${PROFORM_APPROVALS_PATH}/getForUser/${userId}`, "get"),
);

export const addDisapprovementReason = createAsyncThunk(
  "proforms/approvals/addDisapprovementReason",
  async ({approvalId, data}) => fetchData(`${PROFORM_APPROVALS_PATH}/addReason/${approvalId}`, "post", data),
);

export const declineProformApproval = createAsyncThunk(
  "proforms/approvals/declineProformApproval",
  async ({approvalId, data}) => fetchData(`${PROFORM_APPROVALS_PATH}/decline/${approvalId}`, "patch", data),
);

export const addProformApproval = createAsyncThunk(
  "proforms/approvals/addProformApproval",
  async ({approvalId, data}) => fetchData(`${PROFORM_APPROVALS_PATH}/approve/${approvalId}`, "patch", data),
);
