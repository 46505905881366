export const LOGIST_WINDOW_TYPES = Object.freeze({
  BOSS: "Boss",
  INCOMING: "Incoming",
  APPOINTED: "Appointed",
  DISTRIBUTED: "Distributed",
  FACTORY: "Factory",
  FACTORY_CREATING_CARGO: "Factory Creating Cargo",
  FACTORY_CARGO: "Factory Cargo",
  SLEEPING: "Sleeping",
  SLEEPING_IN_SP: "Sleeping in SP",
  TRANSPORTER: "Transporter",
  WAREHOUSE: "Warehouse",
});
