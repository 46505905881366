import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {makeFilterManager} from "../../utilities/makeFilterManager/makeFilterManager";
import {numberFormat} from "../../utilities/moneyFormat";
import TableWithSelect from "../conteiners/TableWithSelect";
import {deselect} from "../KP/kp-f/KpFinSlice";

import {SKU_FILTERS_KEY} from "./VED/Filters/SkuFilters";
import {deselectSku, fetchSku, getCurrentPage, getCurrentSku, getSkuInfo, getSkuList, getTotalPages} from "./SkuSlice";

const SkuList = () => {
  const {t} = useTranslation();

  const skuLoadingStatus = useSelector(state => state.sku.skuLoadingStatus);
  const skuList = useSelector(getSkuList);
  const currentSku = useSelector(getCurrentSku),
    totalPages = useSelector(getTotalPages),
    currPage = useSelector(getCurrentPage);

  const skuFilterManager = makeFilterManager(SKU_FILTERS_KEY);
  const filterParams = skuFilterManager.getFilters();

  const dispatch = useDispatch();

  const ths = useMemo(() => {
    return {
      // [t("Is")]: 1,
      "№": 3,
      SKU: 8,
      // UnKit: 8,
      [t("Brand c")]: 7,
      [t("Group c")]: 8,
      [t("Type c")]: 5,
      [t("Batch")]: 6,
      [t("Nomenclature")]: 8,
      [t("Finishing")]: 7,
      [t("Color")]: 7,
      [t("Price")]: 7,
      [t("RR price")]: 7,
      [t("Min price")]: 7,
      [t("Length mm")]: 7,
      [t("Width mm")]: 7,
      [t("Height mm")]: 7,
      [t("Weight kg")]: 7,
      [t("Quantity short")]: 7,
      [t("Area m2")]: 7,
      [t("Volume m3")]: 7,
    };
  }, []);

  // const toNumberFix = value => {
  //   const v = Number(value).toFixed(2);
  //   return v == 0.0 ? null : v;
  // };

  function transformForTable(item, i) {

    return {
      // avail: "",
      number: i + 1,
      article: item?.brand?.prefix + "-" + item.article,
      // skuUnkit: item.unkit,
      brandName: item.brand?.name,
      productGroupName: item.productGroup?.name,
      productTypeName: item.productType?.name,
      skuBatch: item.batch,
      skuName: item.name,
      materialName: item.material?.name,
      colorName: item.color?.name,
      price: numberFormat(item.listPrice),
      retailPrice: numberFormat(item.retailPrice),
      minPrice: numberFormat(item.minimumPrice),
      length: numberFormat(item["length"]),
      width: numberFormat(item.width),
      height: numberFormat(item.height),
      weight: numberFormat(item.weight),
      quant: numberFormat(item.amount),
      area: numberFormat(item.area),
      vol: numberFormat(item.volume),
      id: item.skuId,
    };
  }

  const paginateSkuList = () => {
    if (totalPages === currPage) return;
    dispatch(fetchSku({...filterParams, page: currPage + 1}));
  };

  const list = useMemo(() => {
    return skuList.map(transformForTable);
  }, [skuList]);

  const selectSku = id => {
    if (!id || (currentSku && currentSku.skuId === id)) {
      dispatch(deselectSku());
    } else {
      dispatch(getSkuInfo(id));
      dispatch(deselect());
    }
  };

  return (
    <TableWithSelect
      thsName={ths}
      paginatable={true}
      onPaginate={paginateSkuList}
      items={list}
      current={currentSku?.skuId}
      onSelect={selectSku}
      loadingStatus={skuLoadingStatus}
    />
  );
};

export default SkuList;
