import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {changePassword} from "../../slices/UserSlice";
import {BUTTON_TYPES} from "../../utilities/Buttons/constants";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import PasswordInput from "../Inputs/PasswordInput/PasswordInput";

import "./authForm.scss";

const PasswordForm = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {user} = useSelector(state => state.user);

  const initialValues = useMemo(() => {
    return {
      newPassword: "",
      repeatPassword: "",
    };
  }, []);

  return (
    <div className="login-form__container">
      <div className="login_form ">
        <label className="label login_form_label">{t("Password change")}</label>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string().required(t("Enter new password")),
            repeatPassword: Yup.string().test("", t("Doesn`t match"), function (item) {
              return this.parent.newPassword === item;
            }),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={values => {
            dispatch(changePassword({userId: user, password: values.newPassword}));
          }}
        >
          <Form className="project_form autolabel">
            <PasswordInput label={t("New password")} id="password" name="newPassword" />
            <PasswordInput label={t("Confirm passsword")} id="repeatPassword" name="repeatPassword" />
            <FunctionButton name={t("Confirm for entire form")} type={BUTTON_TYPES.SUBMIT} />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PasswordForm;
