import {Form, Formik} from "formik";

import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import DeliveryInfo from "../common/DeliveryInfo/DeliveryInfo";
import DocumentsInfo from "../common/DocumentsInfo/DocumentsInfo";
import GroupAndType from "../common/GroupAndType/GroupAndType";
import HeaderInputRow from "../common/HeaderInputRow/HeaderInputRow";
import OrderFullSection from "../common/OrderFullSection/OrderFullSection";
import OrderInfo from "../common/OrderInfo/OrderInfo";
import ShipmentInfo from "../common/ShipmentInfo/ShipmentInfo";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";

import useLogModRqstCheckListService from "./useLogModRqstCheckListService";

const LogModRqstCheckList = ({onItemOpen}) => {
  const {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    documentsTableData,
    curentRequest,
  } = useLogModRqstCheckListService();

  return (
    <div className="page_flex_container">
      <Formik initialValues={initialValues} enableReinitialize={true}>
        <Form className="w100 fcCol flexg1">
          <HeaderInputRow headerInputNames={headerInputNames} />
          <Divider />
          <OrderFullSection
            maxHeight={"34em"}
            // fileArray={pdfFileArray}
            firstColumn={
              <>
                <OrderInfo onItemOpen={onItemOpen} orders={curentRequest?.orders || []} />
                <DeliveryInfo />
                <CheckListModalsTodo path={"request"} entityId={curentRequest?.requestId || null} />
              </>
            }
            secondColumn={
              <>
                <GroupAndType orders={curentRequest?.orders || []} />
                <DocumentsInfo documentsTableData={documentsTableData} />
                <ShipmentInfo />
              </>
            }
          ></OrderFullSection>
          <ReactTable
            columns={orderTableColumns}
            defaultData={orderTableData}
            className="w100 pb"
            tableClassName="mt"
            style={{height: "15em", flexGrow: 1}}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default LogModRqstCheckList;
