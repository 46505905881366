import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import ToolTip from "../ToolTip";

import {BUTTON_COLORS, BUTTON_TYPES} from "./constants";

const FunctionButton = ({
  name,
  className = "",
  color = BUTTON_COLORS.BLUE,
  type = BUTTON_TYPES.BUTTON,
  autoWidth,
  onClick = () => {},
  tooltipMessage = "",
  width = null,
  style,
  disabled,
  children = null,
}) => {
  const buttonClassName = `fixed_button fixed_button__${color} ${className} ${autoWidth ? "autoWidth" : ""}`;

  const {t} = useTranslation();

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={width ? {width: width, ...style} : {...style}}
    >
      {children}
      <ToolTip title={typeof tooltipMessage === "string" ? t(tooltipMessage) : tooltipMessage}>
        <div>{typeof name === "string" ? t(name) : name}</div>
      </ToolTip>
    </button>
  );
};

FunctionButton.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(BUTTON_COLORS)),
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FunctionButton;
