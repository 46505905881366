import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Outlet} from "react-router-dom";

import {withRoleRequired} from "../Components/AccessBoundary/withRoleRequire";
import {fetchData} from "../hook/axios.hook";
import {fetchDesigners, fetchOrgStructures} from "../slices/CatalogsSlice";
import {clientsFetched, fetchUsers} from "../slices/PersonSlice";
import {fetchAccessRoutes, getUser} from "../slices/UserSlice";

const IndexPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
    dispatch(fetchDesigners());
    dispatch(fetchOrgStructures());
    dispatch(fetchUsers());
    dispatch(fetchAccessRoutes());
    fetchData("/clients/getAll", "get").then(res => {
      dispatch(clientsFetched(res));
    });
  }, []);

  return (
    <>
      <div className="App__container">
        <Outlet />
      </div>
    </>
  );
};

export default withRoleRequired(IndexPage);
