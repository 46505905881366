import {useEffect, useRef, useState} from "react";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

import IndeterminateCheckbox from "./reactTable/IndeterminateCheckbox";

const ReactTable = ({
  defaultData,
  columns,
  onSelect,
  current,
  defaultSelection,
  className = "",
  setSelectedItems,
  unchangeableBg = false,
  loading = false,
  onRowDoubleClick = () => {},
  ...props
}) => {
  const [data, setData] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setData(defaultData);
    if (current && defaultData.lenght > 0) {
      var selected = table.getRowModel().rows.find(row => row.original.id === current);
      if (selected) setSelectedRow(selected);
    }
  }, [defaultData]);

  useEffect(() => {
    // if (defaultSelection?.lenght === 0) setRowSelection({});
    if (defaultSelection?.lenght > 0 && data.length > 0) {
      var selection = {};
      data.forEach(row => {
        if (defaultSelection.includes(row.id)) {
          selection[row.number - 1] = true;
        }
      });
      setRowSelection(selection);
    } else if (Object.keys(rowSelection).length) {
      setRowSelection({});
    }
  }, [data, defaultSelection]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });
  useEffect(() => {
    //!Important for redux state management on checkbox change
    const selectedIds = Object.keys(rowSelection).map(id => {
      return table.getRow(id).original.id;
    });

    if (setSelectedItems) {
      setSelectedItems(selectedIds);
    }
  }, [rowSelection]);

  const handleRowClick = row => {
    if (onSelect) onSelect(row.original.id);

    if (selectedRow?.original?.id === row.original.id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(row);
    }
  };

  const handleRowDoubleClick = row => {
    onRowDoubleClick(row.original);
  };

  useEffect(() => {
    if (!current) setSelectedRow(null);
  }, [current]);

  const selectedRowBgColor = unchangeableBg ? "transparent" : "#bfcde2aa";

  // useEffect(() => {

  // }, [defaultData]);

  return (
    <div className={`project_list ${className}`} style={props.style}>
      <table className="project_table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {/* <th>
                   
                </th> */}
              <th
                key={"i0"}
                style={{
                  position: "relative",
                  width: 25,
                }}
              >
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              </th>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  style={{
                    position: "relative",
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" rowSpan={5}>
                <Spinner />
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <tr
                key={row.original.id}
                onClick={() => {
                  if (onSelect) handleRowClick(row);
                }}
                onDoubleClick={() => handleRowDoubleClick(row)}
              >
                <td
                  key={"j0"}
                  style={{
                    position: "relative",
                    width: 20,
                    backgroundColor:
                      row?.original?.id === selectedRow?.original?.id ? selectedRowBgColor : "transparent",
                    textAlign: "center",
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                </td>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={"reactTableRow"}
                    style={{
                      backgroundColor:
                        row?.original?.id === selectedRow?.original?.id ? selectedRowBgColor : "transparent",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

ReactTable.propTypes = {
  defaultData: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null]), PropTypes.string]),
  className: PropTypes.string,
  setSelectedItems: PropTypes.func,
  loading: PropTypes.bool,
  unchangeableBg: PropTypes.bool,
  props: PropTypes.array,
};

export default ReactTable;
