import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import TermsSettingsModal from "../../settings/modTemplates/TermsSettingsModal/TermsSettingsModal";

import useControlTermsService from "./useControlTermsService";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const ControlTermsSettings = () => {
  const {t} = useTranslation();

  const {
    onEditListBtnClick,
    isEditListBtnDisabled,
    termsSettingsTableColumns,
    termsSettingsTableData,
    onTermsSettingsTableRowSelect,
    isTermsSettingsModalActive,
    setTermsSettingsModalActive,
    onTermsSettingsModalClose,
    currentConditionId,
  } = useControlTermsService();

  return (
    <section className={styles.controlTermsSection}>
      <h2 className={styles.sectionHeader}>
        3. <span className={styles.underLined}>{t("Control Terms Adjustment")}</span>
      </h2>
      <ReactTable
        className={styles.reqAutodistrTableWrapper}
        tableClassName={styles.reqAutodistrTable}
        columns={termsSettingsTableColumns}
        defaultData={termsSettingsTableData}
        onSelect={onTermsSettingsTableRowSelect}
        current={currentConditionId}
      />
      <FunctionButton
        name="Edit list"
        disabled={isEditListBtnDisabled}
        className={styles.controlTermsSaveBtn}
        onClick={onEditListBtnClick}
        autoWidth
      />
      <ModalForm
        closable={true}
        Component={TermsSettingsModal}
        label={t("Control Terms Settings")}
        active={isTermsSettingsModalActive}
        setModalActive={setTermsSettingsModalActive}
        onModalClose={onTermsSettingsModalClose}
      />
    </section>
  );
};

export default ControlTermsSettings;
