import React, {useMemo} from "react";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import PropTypes from "prop-types";

import AddToKpfTableService from "./AddToKpfTable/AddToKpfTableService";
import ImagesRow from "./AddToKpfTable/ImagesRow";
import MainRow from "./AddToKpfTable/MainRow";
import StringRow from "./AddToKpfTable/StringRow";
import ViewRow from "./AddToKpfTable/ViewRow";

const AddToKpfTable = ({values, setFieldValue, columns, data, className, chengeable}) => {
  // console.log("data", data);

  const {dataForCommonString, dataForViewImages, dataForRows, dataForDecorImages, dataForColorImages} = data;

  const defaultColumn = useMemo(
    () => ({
      minWidth: 10,
      width: 140,
      maxWidth: 400,
    }),
    [],
  );

  const table = useReactTable({
    columns,
    data,
    defaultColumn,
    enableColumnResizing: true,
    enableRowSelection: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  return (
    <>
      <AddToKpfTableService className={className} table={table}>
        {dataForCommonString && (
          <StringRow key="string" data={dataForCommonString} columns={table.getHeaderGroups()[0].headers} />
        )}
        {dataForViewImages && (
          <ViewRow
            data={dataForViewImages}
            columns={table.getHeaderGroups()[0].headers}
            values={values}
            setFieldValue={setFieldValue}
            chengeable={chengeable}
          />
        )}
        <MainRow data={dataForRows} values={values} setFieldValue={setFieldValue} chengeable={chengeable} />
        {dataForDecorImages && (
          <ImagesRow
            type="decor"
            data={dataForDecorImages}
            columns={table.getHeaderGroups()[0].headers}
            values={values}
            setFieldValue={setFieldValue}
            chengeable={chengeable}
          />
        )}
        {dataForColorImages && (
          <ImagesRow
            type="color"
            data={dataForColorImages}
            columns={table.getHeaderGroups()[0].headers}
            values={values}
            setFieldValue={setFieldValue}
            chengeable={chengeable}
          />
        )}
      </AddToKpfTableService>
    </>
  );
};

AddToKpfTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.object),
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  chengeable: PropTypes.bool,
  className: PropTypes.string,
};

export default AddToKpfTable;
