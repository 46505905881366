import {useDispatch} from "react-redux";

import {clearCurrentDocument, clearCurrentPayment, selectDoc} from "../../redux/PaymentOperationsSlice";

const usePaymentsDoc = number => {
  const dispatch = useDispatch();

  const onDocSelect = id => {
    if (!id) {
      dispatch(clearCurrentPayment());
      dispatch(clearCurrentDocument());
      return;
    }
    dispatch(selectDoc({number, docId: id}));
  };

  return {onDocSelect};
};

export default usePaymentsDoc;
