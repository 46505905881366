import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import delImg from "../../../../images/icons/delete.png";
import saveImg from "../../../../images/icons/save.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../../utilities/dateFormat";
import {getSuccess} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import SingleFilePicker from "../../../Inputs/SingleFilePicker";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import useTransportDocsTableDataService from "../../../Requests/RequestsInProgress/ReceiptGoodsModal/utils/transportDocsTableDataService";
import useDocRefs from "../../../Requests/RequestsInProgress/ReceiptGoodsModal/utils/useDocRefs";
import {
  addTransportDoc,
  deselectTransportDoc,
  fetchAllTransportDocs,
  getRequestInfo,
  getTransportDoc,
} from "../../../Requests/RequestsSlice";
import {LOGIST_FACTORY_DOCUMENTS_TABLE_COLUMNS} from "../../common/DocumentsInfo/constants";
import {selectIsLogistLoading} from "../../redux/selectors";

import styles from "../../../Requests/RequestsInProgress/ReceiptGoodsModal/ReceiptGoodsModal.module.scss";

const FactoryInvoiceAndPackBlock = ({
  currentTransportDoc,
  isReadyMod,
  element,
  docsTableColumns,
  isNumberShown = true,
  headerClassName = "",
  children,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [allTransDocs, setAllallTransDocs] = useState(element?.transportDocs ?? []);

  const currentRequest = useSelector(getRequestInfo);
  const docsLoading = useSelector(selectIsLogistLoading);

  const {values, setFieldValue, setValues} = useFormikContext(),
    {onDocDate, docNumber, docType} = values;

  const defaultColumns = useColumns(LOGIST_FACTORY_DOCUMENTS_TABLE_COLUMNS);
  const {docRefsOptions} = useDocRefs();

  const deselect = () => {
    dispatch(deselectTransportDoc());
    setValues(prev => ({
      ...prev,
      onDocDate: "",
      docNumber: "",
      docType: "",
    }));
  };

  useEffect(() => {
    deselect();
    setAllallTransDocs(element?.transportDocs ?? []);
  }, [element]);

  useEffect(() => {
    isReadyMod && dispatch(fetchAllTransportDocs(currentRequest?.requestId));

    return () => {
      dispatch(deselectTransportDoc());
    };
  }, []);

  useEffect(() => {
    dispatch(deselectTransportDoc());
  }, [element?.stockPoolId]);

  const transportDocsTableData = useTransportDocsTableDataService(allTransDocs);

  const onDocAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.docNumber);
    formdata.append("docTypeId", values.docType);
    formdata.append("dateFrom", values.onDocDate);

    if (formdata.has("doc")) {
      const params = isReadyMod ? {requestId: element.requestId} : {stockPoolId: element.stockPoolId};
      dispatch(addTransportDoc({params, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("doc Success"));
          setFieldValue("docUploadDate", dateFormat(resp.payload.createdAt));
          setAllallTransDocs(prev => [...prev, resp.payload]);
        }
      });
    }
  };

  const onDocSelect = id => {
    if (!id) {
      dispatch(deselectTransportDoc());
      !isReadyMod && setValues(prev => ({...prev, onDocDate: "", docNumber: "", docType: ""}));
      return;
    }
    const currDoc = allTransDocs?.find(doc => doc.docId == id);

    !isReadyMod &&
      setValues(prev => ({
        ...prev,
        onDocDate: currDoc?.dateFrom,
        docNumber: currDoc?.docNumber,
        docType: currDoc?.docType?.docTypeId,
      }));

    dispatch(getTransportDoc(currDoc.docId));
  };

  //   const onDocDelete = () => {
  //     Swal.fire({
  //       text: t("Delete document") + "?",
  //       icon: "question",
  //       confirmButtonText: t("Yes"),
  //       showCancelButton: true,
  //       cancelButtonText: t("No"),
  //     }).then(value => {
  //       if (value.isConfirmed) {
  //         dispatch(deleteTransportDoc(currentTransportDoc.docId)).then(res => {
  //           if (res.meta.requestStatus === "fulfilled") {
  //             getInfo(t("doc del"));
  //             dispatch(deselectTransportDoc());

  //             !isReadyMod &&
  //               setValues({
  //                 onDocDate: "",
  //                 docNumber: "",
  //                 docType: "",
  //               });
  //           }
  //         });
  //       }
  //     });
  //   };

  return (
    <section className={styles.documentsSection}>
      <div>
        <div className={`mb ${headerClassName}`}>
          {isReadyMod && 2} {`${isNumberShown ? "3. " : ""} ${t("Documentation")}:`}
        </div>
        <div className="fc nowrap jcsb">
          <Select
            label={t("Document type")}
            name="docType"
            options={docRefsOptions}
            disabled={!!currentTransportDoc || !element}
            width="12rem"
          />
          <SingleFilePicker
            disabled={onDocDate === "" || docType === "" || docNumber === "" || !!currentTransportDoc}
            addFunc={onDocAdding}
            accept={".pdf"}
          />
        </div>
      </div>
      <div className={`${styles.sectionRow} fc nowrap gap jcsb aibl mb autolabel`}>
        <DateInput label={t("Date") + ": "} name="onDocDate" autolabel disabled={!!currentTransportDoc || !element} />
        <TextInput
          name="docNumber"
          label="№"
          question="d numb"
          width="8em"
          autolabel
          disabled={!!currentTransportDoc || !element}
        />
        <div className="fc nowrap">
          <ImageButton src={saveImg} width={2.5} height={1.5} disabled />

          <ImageButton src={delImg} width={1.5} height={1.5} disabled />
        </div>
      </div>
      {children || null}
      <ReactTable
        className="tableTac mt0_5"
        defaultData={transportDocsTableData}
        columns={docsTableColumns ?? defaultColumns}
        onSelect={onDocSelect}
        current={currentTransportDoc?.docId}
        loading={docsLoading}
        style={{height: "8em"}}
      />
      {/* <FunctionButton
        type="button"
        className={styles.delDocBtn}
        style={{
          marginTop: "10px",
        }}
        name="delete n"
        autoWidth
        disabled={!currentTransportDoc}
        onClick={onDocDelete}
      /> */}
    </section>
  );
};

export default FactoryInvoiceAndPackBlock;
