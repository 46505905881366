import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getPaymentSchemas} from "../../redux/PaymentSelectors";

const useSchemasData = () => {
  const {
    i18n: {language},
  } = useTranslation();

  const [curentSchema, setCurShema] = useState(null);

  const paymentSchemasRaw = useSelector(getPaymentSchemas);

  const nameLang = language === "en" ? "Eng" : "Ukr";

  const changeRadio = value => {
    setCurShema(value);
  };

  const finSchemeColumns = [
    {
      header: "№",
      accessorKey: "number",
      size: 10,
    },
    {
      header: <input type="radio" name="headerR" value={""} checked disabled={true} style={{height: "0.7em"}} />,
      accessorKey: "radioBtn",
      size: 15,
      cell: value => {
        var key = value.getValue();
        if (key) {
          return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <input
                type="radio"
                name="paymentSchemasRadio"
                checked={curentSchema === key}
                value={key}
                onChange={() => {
                  changeRadio(key);
                }}
              />
            </div>
          );
        }
      },
    },
    {
      header: "Payment scheme",
      accessorKey: "name",
      // size: 100,
    },
    {
      header: "Description",
      accessorKey: "desc",
      // size: 100,
    },
  ];

  const paymentSchemas = useMemo(
    () =>
      paymentSchemasRaw?.map((schema, i) => ({
        id: schema.schemaId,
        radioBtn: schema.schemaId,
        number: i + 1,
        name: schema["name" + nameLang],
        desc: "",
      })) || [],
    [paymentSchemasRaw, nameLang],
  );

  return {paymentSchemas, curentSchema, finSchemeColumns, setCurShema};
};

export default useSchemasData;
