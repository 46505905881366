import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import cloud from "../../../../images/icons/download.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../../utilities/ToolTip";
import Modal from "../../../Modal/Modal";
import Spinner from "../../../spinner/Spinner";

import FileInput from "./utils/fileInp";
import PdfPreviewItem from "./utils/PdfPreviewItem";

const PdfFilePreview = ({files, addFunc, delFunc, disable, accept = ".pdf", loading = false}) => {
  const {t} = useTranslation();
  const [modal, setModal] = useState(false);

  function renderBoxes(fileList, delFunc) {
    return fileList.map((file, i) => {
      return (
        <div key={i}>
          <PdfPreviewItem url={file} delFunc={delFunc} />
        </div>
      );
    });
  }

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = e => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    addFunc(droppedFiles);
  };

  const filesRender = renderBoxes(files, delFunc);

  return (
    <>
      <ImageButton
        src={cloud}
        alt="upload"
        className="btn__upload"
        width={1.9}
        height={1.8}
        onClick={() => {
          setModal(true);
        }}
        disabled={disable}
      />

      <Modal active={modal} setActive={setModal}>
        <div className="imgPreview">
          <div className="previewBox" style={{position: "relative"}} onDragOver={handleDragOver} onDrop={handleDrop}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {filesRender}
                <div className="previewBoxItem img-adding">
                  <ToolTip title={[t("Add document")]}>
                    <FileInput accept={accept} name="fileInput" addFunc={addFunc} />
                  </ToolTip>
                </div>
              </>
            )}
          </div>

          <div className="imgPreview__buttons">
            <FunctionButton
              name={t("Close")}
              onClick={() => {
                setModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

PdfFilePreview.propTypes = {
  files: PropTypes.array,
  addFunc: PropTypes.func,
  delFunc: PropTypes.func,
  disable: PropTypes.bool,
  accept: PropTypes.string,
  loading: PropTypes.bool,
};

export default PdfFilePreview;
