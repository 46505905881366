import React from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import {getCurentCheckListDoc} from "../../redux/selectors";
// import {getCurentCheckListDoc} from "../../../Requests/RequestsSlice";

const OrderFullSection = ({fileArray = null, firstColumn, secondColumn, maxHeight = "38em"}) => {
  const curentDoc = useSelector(getCurentCheckListDoc);
  const curentDocFile = curentDoc ? [curentDoc.url] : [];
  return (
    // <section className="mb w100 noShrink">
    <div
      className="dg w100 gap orderFullSection-grid"
      style={{gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) 340px"}}
    >
      <div className="fcCol gap aistr">{firstColumn}</div>
      <div className="fcCol gap aistr">{secondColumn}</div>
      <div className="pb1_5">
        <NewPdfViewer fileArray={fileArray ? fileArray : curentDocFile} hiding={false} />
      </div>
    </div>
    // </section>
  );
};

OrderFullSection.propTypes = {
  fileArray: PropTypes.array,
  firstColumn: PropTypes.node,
  secondColumn: PropTypes.node,
};

export default OrderFullSection;
