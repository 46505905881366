import {useDispatch} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchStockPoolBySkuId} from "../../redux/operations";

import {LOGIST_TABLE_COLUMNS} from "./constants";

const LogistTable = ({tableData, isLoading, onSelect, setModalOpen}) => {
  const dispatch = useDispatch();
  const columns = useColumns(LOGIST_TABLE_COLUMNS);

  function onRowDoubleClick(request) {
    dispatch(fetchStockPoolBySkuId(request.id));
    setModalOpen(true);
  }

  return (
    <ReactTable
      defaultData={tableData}
      columns={columns}
      onSelect={onSelect}
      current={null}
      className={"scrollX"}
      tableClassName={"request-table"}
      loading={isLoading}
      onRowDoubleClick={onRowDoubleClick}
    />
  );
};

export default LogistTable;
