import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {fetchData} from "../../hook/axios.hook";
import {dateFormat} from "../../utilities/dateFormat";

const initialState = {
  reminds: [],
  currentRemind: null,
  remindLoadingStatus: "idle",
  // editedRemind: null
};

// export const fetchReminds = createAsyncThunk(
//     'reminds/fetchReminds',
//     async ({type, id}) => {
//         const responce = fetchData('/persona/add','post')
//         return responce;
//     }
// );

export const addRemind = createAsyncThunk("reminds/addRemind", async newRemind => {
  const responce = fetchData("/todo/add", "post", newRemind);
  return responce;
});
export const updateRemind = createAsyncThunk("reminds/updateRemind", async newRemind => {
  const responce = fetchData("/todo/update", "patch", newRemind);
  return responce;
});

// export const remindsAdapter = createEntityAdapter();
// const initialState = remindsAdapter.getInitialState();

const remindsSlice = createSlice({
  name: "reminds",
  initialState,
  reducers: {
    remindsFetched: (state, action) => {
      state.reminds =
        action.payload?.map(remind => {
          return {...remind, dueDate: remind.dueDate};
        }) || [];
      state.currentRemind = null;
    },
    remindCreated: (state, {payload}) => {
      try {
        const {todo} = payload;
        state.reminds.push({...todo, dueDate: todo.dueDate});
        state.currentRemind = {...todo, dueDate: todo.dueDate};
      } catch (e) {
        state.reminds.push(payload);
        state.currentRemind = payload;
      }
      // getInfo(message)
    },
    remindUpdated: (state, {payload}) => {
      const todo = payload?.todo || payload;
      state.reminds = state.reminds.map(item => {
        if (item?.todoId === todo?.todoId) {
          return todo;
        } else return item;
      });
      state.currentRemind = {...todo, dueDate: todo.dueDate};
      // getInfo(message)
    },
    remindDeleted: state => {
      state.reminds = state.reminds.filter(item => item.id !== state.currentKp.id);
      state.currentRemind = null;
      // state.currentKp = null
    },
    selectRemind: (state, action) => {
      if (action.payload == null) {
        state.currentRemind = null;
      } else {
        state.reminds.forEach(item => {
          if (item.todoId === action.payload) state.currentRemind = item;
        });
      }
    },
    resetReminds: state => {
      state.reminds = [];
    },
    remindPlus: (state, action) => {
      state.reminds.push({
        createdAt: new Date(),
        dueDate: "",
        note: "",
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addRemind.pending, state => {
        state.remindLoadingStatus = "loading";
      })
      .addCase(addRemind.fulfilled, (state, {payload}) => {
        const {todo} = payload;
        state.remindLoadingStatus = "complete";
        // state.reminds.push(payload.todo) ;
        state.reminds.push({...todo, dueDate: todo.dueDate});
        // getInfo(message)
      })

      // .addCase(updateRemind.pending, state => {state.remindLoadingStatus = 'loading'})
      .addCase(updateRemind.fulfilled, (state, action) => {
        // state.remindLoadingStatus = 'complete';
        state.reminds = state.reminds.map(item => {
          if (item.todoId === action.payload.todo.todoId) {
            return action.payload.todo;
          } else return item;
        });
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = remindsSlice;

export const {
  remindCreated,
  remindDeleted,
  remindUpdated,
  selectRemind,
  // setEditedKp,
  remindsFetched,
  resetReminds,
  // updateRemind
} = actions;

export default reducer;
