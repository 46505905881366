import {useTranslation} from "react-i18next";

import {PriceInput, TextInput} from "../../../../Inputs";
import MoneyReadInput from "../../../../Inputs/MoneyReadInput";

const MainVerificationFields = () => {
  const {t} = useTranslation();

  return (
    <div className="v_main_fields">
      <TextInput label="SKU:" name="sku" width="100%" autolabel labelStyles={{width: "5em"}} />
      <TextInput label={t("Measurement units") + ":"} name="vwc" width="100%" autolabel labelStyles={{width: "14em"}} />

      <MoneyReadInput label={t("Price pcs")} name="price" width="100%" />

      <TextInput label={t("Quantity") + ":"} name="quantity" width="100%" />

      <MoneyReadInput label="Price" name="sum" width="100%" />
    </div>
  );
};

export default MainVerificationFields;
