import {LOGIST_STORE_NAME} from "./LogistSlice";

export const selectCurrentSkuRequest = state => state.logist.currentSkuRequest;
export const getCurentCheckListDoc = state => state.logist.curentCheckListDoc;

export const selectAllStockPools = state => state[LOGIST_STORE_NAME].stockPools;
export const selectIncomingStockPools = state => state[LOGIST_STORE_NAME].stockPools.incoming;
export const selectBossStockPools = state => state[LOGIST_STORE_NAME].stockPools.boss;
export const selectAppointedStockPools = state => state[LOGIST_STORE_NAME].stockPools.appointed;
export const selectDistributedStockPools = state => state[LOGIST_STORE_NAME].stockPools.distributed;
export const selectFactoryStockPools = state => state[LOGIST_STORE_NAME].stockPools.factory;
export const selectWarehouseComingStockPools = state => state[LOGIST_STORE_NAME].stockPools.warehouseComing;
export const selectWarehouseStockPools = state => state[LOGIST_STORE_NAME].stockPools.warehouse;
export const selectWarehouseCreatingStockPools = state => state[LOGIST_STORE_NAME].stockPools.warehouseCreatingSp;
export const selectWarehouseSpStockPools = state => state[LOGIST_STORE_NAME].stockPools.warehouseSp;
export const selectWarehouseCreatingCargoStockPools = state =>
  state[LOGIST_STORE_NAME].stockPools.warehouseCreatingCargo;
export const selectWarehouseCargoStockPools = state => state[LOGIST_STORE_NAME].stockPools.warehouseCargo;

export const selectFetchedStockPool = state => state[LOGIST_STORE_NAME].fetchedStockPool;
export const selectTransporterStockPools = state => state[LOGIST_STORE_NAME].stockPools.transporter;

export const selectFactoryCreatingCargoStockPools = state => state[LOGIST_STORE_NAME].stockPools.creatingCargo;
export const selectFactoryCargoStockPools = state => state[LOGIST_STORE_NAME].stockPools.factoryCargo;
export const selectFinalBossStockPools = state => state[LOGIST_STORE_NAME].stockPools.finalBoss;
export const selectFinalManagerStockPools = state => state[LOGIST_STORE_NAME].stockPools.finalManager;

export const selectSPSelectedInModal = state => state[LOGIST_STORE_NAME].stockPools.selectedInModal;

export const selectAllRequests = state => state[LOGIST_STORE_NAME].requests;
export const selectIncomingRequests = state => state[LOGIST_STORE_NAME].requests.incoming;
export const selectAppointedRequests = state => state[LOGIST_STORE_NAME].requests.appointed;
export const selectBossRequests = state => state[LOGIST_STORE_NAME].requests.boss;
export const selectDistributedRequests = state => state[LOGIST_STORE_NAME].requests.distributed;
export const selectFactoryRequests = state => state[LOGIST_STORE_NAME].requests.factory;
export const selectTransporterRequests = state => state[LOGIST_STORE_NAME].requests.transporter;
export const selectWarehouseComingRequests = state => state[LOGIST_STORE_NAME].requests.warehouseComing;
export const selectWarehouseRequests = state => state[LOGIST_STORE_NAME].requests.warehouse;
export const selectWarehouseCreatingSpRequests = state => state[LOGIST_STORE_NAME].requests.warehouseCreatingSp;
export const selectWarehouseSpRequests = state => state[LOGIST_STORE_NAME].requests.warehouseSp;
export const selectWarehouseCreatingCargoRequests = state => state[LOGIST_STORE_NAME].requests.warehouseCreatingCargo;
export const selectWarehouseCargoRequests = state => state[LOGIST_STORE_NAME].requests.warehouseCargo;
export const selectFinalBossRequests = state => state[LOGIST_STORE_NAME].requests.finalBoss;
export const selectFinalManagerRequests = state => state[LOGIST_STORE_NAME].requests.finalManager;

export const selectFactoryCreatingCargoRequests = state => state[LOGIST_STORE_NAME].requests.creatingCargo;
export const selectFactoryCreatingCargoSP = state => state[LOGIST_STORE_NAME].stockPools.creatingCargo;
export const selectFactoryCargoRequests = state => state[LOGIST_STORE_NAME].requests.factoryCargo;
export const selectSleepingForLogisticsRequests = state => state[LOGIST_STORE_NAME].requests.forLogistics.sleeping;
export const selectSleepingInSpForLogisticsRequests = state =>
  state[LOGIST_STORE_NAME].requests.forLogistics.sleepingInSp;
// export const selectTransporterRequests = state => state[LOGIST_STORE_NAME].requests.transporter;

export const selectStockPoolFilters = state => state[LOGIST_STORE_NAME].filters;

export const selectIsLogistLoading = state => state[LOGIST_STORE_NAME].logistLoading;
export const selectIsLogistError = state => state[LOGIST_STORE_NAME].error;

//*** Cargos ***/
export const selectAllCargos = state => state[LOGIST_STORE_NAME].cargos.all;
export const selectChosenCargo = state => state[LOGIST_STORE_NAME].cargos.chosen;
export const selectAddedCargo = state => state[LOGIST_STORE_NAME].cargos.added;

//*** Warehouses ***/
export const selectWarehouses = state => state[LOGIST_STORE_NAME].warehouses.warehouses;
export const selectCurrentWarehouse = state => state[LOGIST_STORE_NAME].warehouses.currentWarehouse;
export const selectWarehouseFilters = state => state[LOGIST_STORE_NAME].filters;
export const selectWarehouseTypes = state => state[LOGIST_STORE_NAME].warehouses.warehouseTypes;
// export const selectAddedWarehouseType = state => state[LOGIST_STORE_NAME].warehouses.addedWarehouseType;
// export const selectAddedWarehouseContact = state => state[LOGIST_STORE_NAME].warehouses.addedWarehouseContact;
// export const selectAddedWarehouseAccount = state => state[LOGIST_STORE_NAME].warehouses.addedWarehouseAccount;
// export const selectAddedWarehouseDoc = state => state[LOGIST_STORE_NAME].warehouses.addedWarehouseDoc;
// export const selectAddedWarehouseDocFile = state => state[LOGIST_STORE_NAME].warehouses.addedWarehouseDocFile;
export const selectCurrentWarehouseDocFile = state => state[LOGIST_STORE_NAME].warehouses.currentDocFile;

//***Carriers***/
export const selectCarriers = state => state[LOGIST_STORE_NAME].carriers;
export const selectCarrierTypes = state => state[LOGIST_STORE_NAME].carrierTypes;
export const selectCarrier = state => state[LOGIST_STORE_NAME].carrier;
export const selectCarrierDocTypes = state => state[LOGIST_STORE_NAME].carrierDocTypes;
export const selectCarrierCurrentDoc = state => state[LOGIST_STORE_NAME].currentCarrierDoc;
export const selectAllCarrierDocs = state => state[LOGIST_STORE_NAME].carrierDocs;
export const selectCarrierDocsLoading = state => state[LOGIST_STORE_NAME].carrierDocsLoading;

export const selectSplitedOrder = state => state[LOGIST_STORE_NAME].splitedOrder;
export const selectNewOrder = state => state[LOGIST_STORE_NAME].newOrder;
export const selectSplitedOrderSkus = state => state[LOGIST_STORE_NAME].splitedOrderSkus;
