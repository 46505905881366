import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {clearCurrentPayment} from "../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import {getCurrentPayment} from "../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

import useTopBlockService from "./PaymentToFactoryParts/TopBlock/useTopBlockService";

const usePaymentToFactoryModalService = () => {
  const {t} = useTranslation();
  const PAYMENT_DATE_INDEX = 6;
  const dispatch = useDispatch();

  const currentPayment = useSelector(getCurrentPayment);
  const currentPaymentDoc = currentPayment?.paymentDoc;
  const confirmingDoc = currentPayment?.confirmingDoc;

  const {planned, actual} = useTopBlockService();

  useEffect(() => {
    return () => {
      dispatch(clearCurrentPayment());
    };
  }, []);

  const initialValues = useMemo(
    () => ({
      //In TopBlock
      onPaymentPlan: planned || "",
      onPaymentFact: actual || "",
      //In OnPaymentBlock
      payToDate: currentPayment?.request?.requestDates[PAYMENT_DATE_INDEX].plannedDate || "",
      requestNote: t("Note for Factory Manager: ") + (currentPayment?.noteToFD || t("not specified")),
      //In PaidBlock
      confirmingDocNumber: confirmingDoc?.docNumber || "",
      confirmingDocType: confirmingDoc?.docType.docTypeId || "",
      confirmingDocSum: currentPayment?.paymentSum || "",
      confirmingDocNote: currentPayment?.noteToFEA || "",
    }),
    [confirmingDoc?.docNumber, planned, actual, currentPayment?.request],
  );

  const fileArray = [];
  if (confirmingDoc) {
    fileArray.push(confirmingDoc.fileUrl);
  }
  if (currentPaymentDoc) {
    fileArray.push(currentPaymentDoc.fileUrl);
  }

  return {initialValues, fileArray};
};

export default usePaymentToFactoryModalService;
