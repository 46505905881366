import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {dateFormat} from "../../../utilities/dateFormat";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import {getInfo, getSuccess} from "../../../utilities/toasts";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SP_CHECK_TABLE} from "../../Requests/RequestRegister/RequestTable/utils/constants";
import {profExpanded} from "../../Requests/RequestsInProgress/utils/functions/expandDataForTable";
import {deselectStockPool, selectTransportDoc} from "../../Requests/RequestsSlice";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import {DOCTYPES, formFactoryContactsField} from "../common/functions";
import {setFetchedStockPool} from "../redux/LogistSlice";
import {
  fetchAllStockPoolsNew,
  fetchStockPoolBySkuId,
  fetchStockPoolUpdated,
  updateCargo,
  updateStockPool,
} from "../redux/operations";
import {selectFactoryRequests, selectFactoryStockPools, selectFetchedStockPool} from "../redux/selectors";

import {LOGIST_FACTORY_BLOCK_TABLE_COLUMNS} from "./utils/constants";

const useFactoryBlock = ({setModalOpen}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [checkedSP, setCheckedSP] = useState([]);
  const [isFinalDestination, setIsFinalDestination] = useState(false);
  const currentSP = useSelector(selectFetchedStockPool);
  const currentTransportDoc = useSelector(selectTransportDoc);

  const pdfFileArray = currentTransportDoc?.fileUrl ? [currentTransportDoc.fileUrl] : [];

  const spColumns = useColumns(SP_CHECK_TABLE);
  const reqColumns = useColumns(LOGIST_FACTORY_BLOCK_TABLE_COLUMNS);

  const allRequests = useSelector(selectFactoryRequests);
  const stockPools = useSelector(selectFactoryStockPools);

  useEffect(() => {
    dispatch(deselectStockPool());
    dispatch(setFetchedStockPool(null));
  }, []);

  useEffect(() => {
    setIsFinalDestination(currentSP?.isFinal || false);
  }, [currentSP]);

  const formInitialValues = useMemo(
    () => ({
      stockPoolNumber: currentSP?.stockPoolNumber ?? "",
      shipmentDate: currentSP?.shipmentDate ?? "",
      spAddress: formFactoryContactsField(currentSP?.factory, t),
      nextDeliveryAddress: "",
      onDocDate: "",
      docType: "",
      docNumber: "",
    }),
    [currentSP?.stockPoolId],
  );

  const filterDisabled = checkedSP.length > 0;
  const FactoryFilterManager = makeFilterManager(LOGIST_WINDOW_TYPES.FACTORY);
  const factoryFilters = FactoryFilterManager.getFilters();
  const isTransportationBtnDisabled = !factoryFilters?.brandId;

  useEffect(() => {
    dispatch(setFetchedStockPool(null));
  }, [
    factoryFilters?.brandId,
    factoryFilters?.logistId,
    factoryFilters?.managerFeaId,
    factoryFilters?.requestNumber,
    factoryFilters?.stockPoolNumber,
  ]);

  const requestsTableData = useMemo(() => {
    return allRequests?.map(({skuRequestId, order, request, stockPool} = {}, index) => {
      return {
        id: skuRequestId ?? request?.requestId ?? index,
        stockPoolNumber: stockPool?.stockPoolNumber ?? " - ",
        orderNumber: order?.orderNumber ?? " - ",
        stockPoolId: stockPool?.stockPoolId || null,
        number: index + 1,
        requestNumber: request?.requestNumber ?? " - ",
        proformaNumber: profExpanded(request?.proforms) ?? " - ",
        managerCo: order?.co?.managerCo?.name ?? " - ",
        managerFea: request?.managerFea?.name ?? " - ",
        factory: request?.brand?.name ?? " - ",
        logisticManager: stockPool?.logist?.name ?? " - ",
        country: order?.co?.project?.country?.shortName ?? " - ",
        readinessDate: dateFormat(request?.requestDates?.[0].actualDate) || " - ",
        bringTo: dateFormat(order?.co?.bringTo) ?? " - ",
        trPoolNumber: " - ",
        packingListNumber:
          stockPool?.docs
            ?.filter(doc => doc?.docTypeId === DOCTYPES.PACKING_LIST)
            .map(item => item.docNumber)
            .join(" / ") || " - ",
        invoiceNumber:
          stockPool?.docs
            ?.filter(doc => doc?.docTypeId === DOCTYPES.INVOICE)
            .map(item => item.docNumber)
            .join(" / ") || " - ",
        note: request?.note ?? " - ",
        requestSum: request?.requestSum ?? " - ",
      };
    });
  }, [allRequests]);

  const SPTableData = useMemo(
    () =>
      stockPools?.map((sp, index) => ({
        number: index + 1,
        id: sp.stockPoolId,
        transportPoolNumber: sp.cargo?.cargoNumber || " - ",
        stockPoolNumber: sp.stockPoolNumber,
      })) || [],
    [stockPools],
  );

  const onStockPoolCheck = spList => {
    if (!spList.length) {
      dispatch(fetchAllStockPoolsNew({window: "Factory", ...factoryFilters}));
    } else {
      dispatch(fetchAllStockPoolsNew({window: "Factory", stockPoolIds: spList?.join(",")}));
    }

    setCheckedSP(spList);
  };

  const onStockPoolSelect = spId => {
    if (spId === null) {
      dispatch(setFetchedStockPool(null));
      return;
    }
    dispatch(fetchStockPoolUpdated({stockPoolId: spId}));
  };

  // function onSpTableRowDoubleClick() {
  //   const isTp = selected.transportPoolNumber !== "";
  //   if (isTp) {
  //     setModalType(FACTORY_BLOCK_MODAL_TYPES.tp);
  //   } else {
  //     setModalType(FACTORY_BLOCK_MODAL_TYPES.sp);
  //   }
  //   setModalOpen(true);
  // }
  function onRqstsTableRowDoubleClick(request) {
    dispatch(fetchStockPoolBySkuId(request.id));
    setModalOpen(true);
  }

  const onFinelDestinationCheck = value => {
    // console.log("checkvalue", value);
    if (value) {
      Swal.fire({
        title: t("Attention"),
        text: t("Info FD"),
        icon: "info",
        confirmButtonText: t("Ok"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(
            updateStockPool({
              stockPoolId: currentSP?.stockPoolId,
              params: {
                isFinal: !!value,
              },
            }),
          ).then(res => {
            if (res.meta.requestStatus === "fulfilled") {
              setIsFinalDestination(res.payload.isFinal);
              getSuccess(`Стоковий пул №${currentSP.stockPoolNumber} позначено як Final Destination`);
            }
          });
        } else {
          setIsFinalDestination(!value);
        }
      });
    } else {
      dispatch(
        updateStockPool({
          stockPoolId: currentSP?.stockPoolId,
          params: {
            isFinal: !!value,
          },
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setIsFinalDestination(res.payload.isFinal);
          getInfo(`Позначку Final Destination знято`);
        }
      });
    }
  };

  return {
    spColumns,
    reqColumns,
    requestsTableData,
    SPTableData,
    formInitialValues,
    onStockPoolCheck,
    onStockPoolSelect,
    currentSP,
    pdfFileArray,
    filterDisabled,
    onRqstsTableRowDoubleClick,
    isTransportationBtnDisabled,
    onFinelDestinationCheck,
    isFinalDestination,
  };
};

export default useFactoryBlock;
