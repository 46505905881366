import {useRef} from "react";

import cloud from "../../images/icons/download.png";
import ImageButton from "../../utilities/Buttons/ImageBtn";

// import "../../../../Styles/Buttons.scss";

const SingleFilePicker = props => {
  const {accept, addFunc, disabled} = props;
  const fileref = useRef();

  const getFileArray = fileList => {
    return Array.from(fileList);
  };

  const handleFileChange = event => {
    const arrFiles = getFileArray(event.target.files);
    const file = arrFiles.length ? arrFiles[0] : undefined;
    if (!file) return;
    addFunc(file);
  };

  return (
    <div>
      <div className="btn__upload">
        <ImageButton
          src={cloud}
          alt="upload"
          className="btn__upload"
          width={1.9}
          height={1.8}
          onClick={e => {
            e.stopPropagation();
            fileref.current.click();
          }}
          disabled={disabled}
        />

        <input
          ref={fileref}
          // {...props}
          name={`fileInput`}
          type={`file`}
          accept={accept}
          multiple={true}
          onChange={event => {
            handleFileChange(event, event.values);
          }}
          style={{display: "none"}}
        />
      </div>
    </div>
  );
};

export default SingleFilePicker;
