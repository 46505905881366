export const TEMPLATES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "index",
    size: 5,
  },
  {
    header: "Factory",
    accessorKey: "name",
    size: 45,
  },
  {
    header: "Country",
    accessorKey: "country",
    size: 35,
  },
  {
    header: "Contact person",
    accessorKey: "contactPerson",
    size: 35,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    size: 25,
  },
  {
    header: "e-mail",
    accessorKey: "email",
    size: 50,
  },
  {
    header: "Phone",
    accessorKey: "phone",
    size: 35,
  },
  {
    header: "Timeframe",
    accessorKey: "terms",
    size: 50,
  },
  {
    header: "Conditions",
    accessorKey: "termsCondition",
    size: 45,
  },
  {
    header: "Notes",
    accessorKey: "termsNote",
    size: 45,
  },
  {
    header: "Address",
    accessorKey: "address",
    size: 55,
  },
]);
