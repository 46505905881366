import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";

import FilterButton from "../../../../../../utilities/Buttons/FilterBtn/FilterButton";
import {TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

import useTPreqDistribFilter from "./useTPreqDistribFilter";

const TPreqDistribFilter = ({disabled = false, setSelectedFilters}) => {
  const {t} = useTranslation();
  const {onFilter, delFilters, areFiltersDefined, brandOptions, coManagerOptions, countryOptions, initialValues} =
    useTPreqDistribFilter({setSelectedFilters});

  return (
    <>
      <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={true} enableReinitialize>
        {({values, setValues}) => (
          <Form className=" w100 fc jcfs nowrap autolabel" style={{gap: "2em", margin: "10px 0"}}>
            <FilterButton
              onFilter={() => onFilter(values)}
              delFilter={() => delFilters(setValues)}
              questionTitle={[t("Filter")]}
              areFiltersDefined={areFiltersDefined}
              disabled={disabled}
            />

            <TextInput label={t("Request №") + ":"} name="requestNumber" disabled={disabled} />
            <Select label={t("CO Manager") + ":"} options={coManagerOptions} name="managerCoId" disabled={disabled} />
            <Select label={t("Product country") + ":"} options={countryOptions} name="countryId" disabled={disabled} />
            <Select label={t("Factory") + ":"} options={brandOptions} name="brandId" disabled={disabled} />
          </Form>
        )}
      </Formik>
    </>
  );
};

TPreqDistribFilter.propTypes = {
  TPreqDistribFilter: PropTypes.bool,
  setSelectedFilters: PropTypes.func,
};

export default TPreqDistribFilter;
