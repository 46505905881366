export const SHIPPING_TABLE_COLUMNS = Object.freeze([
  {
    header: "Date",
    accessorKey: "shippingDate",
    size: 100,
    isSortable: true,
  },
  {
    header: "Notes",
    accessorKey: "shippingNote",
    size: 100,
  },
  {
    header: "D-Day",
    accessorKey: "shippingDDay",
    size: 100,
    isSortable: true,
  },
]);
