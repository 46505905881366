import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {setCurentOrder} from "../../../Requests/RequestsSlice";
import {setSelectedInModalStockPool} from "../../redux/LogistSlice";
import {fetchStockPoolUpdated} from "../../redux/operations";
import {selectSPSelectedInModal} from "../../redux/selectors";

import {RQST_TABLE_COLUMNS, SP_TABLE_COLUMNS} from "./constants";

const useStockPoolInfoService = ({chosenCargo, onItemOpen}) => {
  const dispatch = useDispatch();

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const selectedInModalSp = useSelector(selectSPSelectedInModal);

  const spTableColumns = useColumns(SP_TABLE_COLUMNS);
  const spTableData =
    chosenCargo?.stockPools.map((stockPool, index) => ({
      spNumber: stockPool.stockPoolNumber,
      number: ++index,
      id: stockPool.stockPoolId,
    })) ?? [];
  const orderTableColumns = useColumns(RQST_TABLE_COLUMNS);
  const ordersRow = selectedInModalSp ? selectedInModalSp?.orders : chosenCargo?.orders;
  const orderTableData = makeOrderTableData(ordersRow);

  const isOpenInTabBtnDisabled = !selectedInModalSp && !selectedOrderId;

  function onOpenInTabBtnClick() {
    if (selectedInModalSp) {
      onItemOpen({item: selectedInModalSp, isStockPool: true});
    }
    if (selectedOrderId) {
      const selectedOrder = ordersRow.find(order => order.orderId === selectedOrderId);
      onItemOpen({item: selectedOrder, isStockPool: false});
      dispatch(setCurentOrder(selectedOrder));
    }
  }
  function onSpTableRowSelect(stockPoolId) {
    if (!stockPoolId) {
      dispatch(setSelectedInModalStockPool(null));
      return;
    }
    dispatch(fetchStockPoolUpdated({stockPoolId, type: "modal"}));
  }
  function onOrderTableRowSelect(orderId) {
    orderId ? setSelectedOrderId(orderId) : setSelectedOrderId(null);
  }

  return {
    spTableColumns,
    spTableData,
    orderTableColumns,
    orderTableData,
    onOpenInTabBtnClick,
    onSpTableRowSelect,
    onOrderTableRowSelect,
    isOpenInTabBtnDisabled,
    selectedInModalSp,
    selectedOrderId,
  };
};

function makeOrderTableData(orders) {
  return orders
    ? orders.map(({orderNumber, orderId, requests}, index) => ({
        id: orderId,
        number: ++index,
        orderNumber,
        requestNumber: requests.map(rqst => rqst.requestNumber).join(" "),
      }))
    : [];
}

export default useStockPoolInfoService;
