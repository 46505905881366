import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import {dateFormat} from "../../../../utilities/dateFormat";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import {getInfo, getSuccess} from "../../../../utilities/toasts";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {addNewCargoDoc, deleteCargoDoc, fetchCargoDocTypes} from "../../redux/Cargos/operations";
import {selectCargosDocTypes, selectCargosLoading} from "../../redux/Cargos/selectors";
import {deselectCarrierDoc} from "../../redux/LogistSlice";
import {fetchStockPoolBySkuId} from "../../redux/operations";

import {DOCUMENTS_FOR_TRANSPORTER_TABLE_COLUMNS} from "./constants";

const useDocsForTransporterService = (element, setPdfFileArray) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();

  const cargosDocTypes = useSelector(selectCargosDocTypes);
  const cargoDocsLoading = useSelector(selectCargosLoading);

  const [currentDoc, setCurrentDoc] = useState(null);

  useEffect(() => {
    dispatch(fetchCargoDocTypes());
  }, []);

  const {values, setFieldValue, setValues} = useFormikContext();

  const cargosDocTypesOptions = useMemo(
    () =>
      cargosDocTypes
        ? cargosDocTypes.map(
            type => ({value: type.docTypeId, title: language === "en" ? type?.nameEng : type?.nameUkr} || []),
          )
        : [],
    [cargosDocTypes?.length],
  );

  const docsForTransporterTableColumns = useColumns(DOCUMENTS_FOR_TRANSPORTER_TABLE_COLUMNS);

  const docsForTransporterTableData =
    element?.stockPool?.cargo?.docs?.map(({docId, dateFrom, docType, docNumber, createdAt, creator}, index) => ({
      number: index + 1,
      id: docId,
      docDate: dateFormat(dateFrom),
      docType: language === "en" ? docType.nameEng : docType.nameUkr,
      docNumber,
      createdAt: dateFormat(createdAt),
      addedBy: makeNameFromPersona({persona: creator?.persona, language: language}),
    })) || [];

  function onSaveDocBtnClick() {}

  const onDocAdding = file => {
    console.log("element onDocAdding:", element);
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.docNumber);
    formdata.append("docTypeId", values.docType);
    formdata.append("dateFrom", values.onDocDate);

    if (formdata.has("doc")) {
      dispatch(addNewCargoDoc({cargoId: element?.stockPool?.cargoId, params: formdata})).then(resp => {
        console.log("resp :", resp);
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("doc Success"));
          setFieldValue("docUploadDate", dateFormat(resp.payload.createdAt));
          setCurrentDoc(resp.payload);
          setPdfFileArray([resp.payload.fileUrl]);
          dispatch(fetchStockPoolBySkuId(element.skuRequestId));
        }
      });
    }
  };

  useEffect(() => {
    if (currentDoc?.cargoId !== element?.stockPool?.cargoId) {
      setCurrentDoc(null);
      setPdfFileArray([]);

      setValues({onDocDate: "", docNumber: "", docType: ""});
    }
  }, [element, currentDoc]);

  const onDocSelect = id => {
    if (!id || currentDoc?.docId === id) {
      setCurrentDoc(null);
      setPdfFileArray([]);
      return;
    }
    const selectedDoc = element?.stockPool?.cargo?.docs?.find(doc => doc.docId === id);
    setCurrentDoc(selectedDoc);
    setPdfFileArray([selectedDoc?.fileUrl]);

    setValues(prev => ({
      ...prev,
      onDocDate: selectedDoc?.dateFrom,
      docNumber: selectedDoc?.docNumber,
      docType: selectedDoc?.docType?.docTypeId,
    }));
  };

  const onDocDelete = () => {
    Swal.fire({
      text: t("Delete document") + "?",
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteCargoDoc(currentDoc.docId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getInfo(t("doc del"));
            dispatch(deselectCarrierDoc());

            setValues({
              onDocDate: "",
              docNumber: "",
              docType: "",
            });
            dispatch(fetchStockPoolBySkuId(element.skuRequestId));
            setCurrentDoc(null);
          }
        });
      }
    });
  };

  return {
    docsForTransporterTableColumns,
    cargosDocTypesOptions,
    onDocSelect,
    onDocAdding,
    onDocDelete,
    currentDoc,
    cargoDocsLoading,
    docsForTransporterTableData,
    values,
    onSaveDocBtnClick,
  };
};

export default useDocsForTransporterService;
