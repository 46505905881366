import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import {numberFormat} from "../../../../utilities/moneyFormat";
import useSkuCatalogsService from "../../../SKU/SkuCatalogsService";

import {formatter, procformatter} from "./utils/functionalFormatters";
import AddToKpfTable from "./AddToKpfTable";

import "./kpFinTable.scss";

const AddSkuTable = ({values, setFieldValue, overallRender, className, chengeable}) => {
  const {t, i18n} = useTranslation();

  const {lengthMeasurementQuantites, areaMeasurementQuantites, volumeMeasurementQuantites, weightMeasurementQuantites} =
    useSkuCatalogsService();

  const langForOvh = i18n.language;

  const countOvh = (value, unit) => {
    if (value && unit) {
      return value > 0
        ? numberFormat(value) + (unit[0]?.title ? unit[0].title : langForOvh === "en" ? unit?.engName : unit?.name)
        : "";
    }
    return "";
  };

  const getOvh = (type, plan) => {
    if (!plan[type]) {
      return "";
    }

    const dimensionUnit = lengthMeasurementQuantites.filter(item => item.value === plan.overallSizeUnitId);
    const areaUnit = areaMeasurementQuantites.filter(item => item.value === plan.areaUnitId);
    const volumeUnit = volumeMeasurementQuantites.filter(item => item.value === plan.volumeUnitId);
    const weightUnit = weightMeasurementQuantites.filter(item => item.value === plan.weightUnitId);

    // const dimArray = {l: plan.length, w: plan.width, h: plan.height};
    // const dimenSh = Object.values(dimArray);
    const dimenSh = [plan.length, plan.width, plan.height];

    if (!dimensionUnit && !areaUnit && !volumeUnit && !weightUnit && plan.palletCheck === false) {
      return "";
    }
    switch (type) {
      case "overallSizeCheck":
        if (dimenSh.some(item => item !== null)) {
          const updatedNumbers = dimenSh.map(num => (num === null || num === "0.00" ? "0" : num));

          const dimensionUnitString = dimensionUnit[0]?.title
            ? `${dimensionUnit[0]?.title}`
            : `${langForOvh === "en" ? plan.overallSizeUnit?.engName : plan.overallSizeUnit?.name}`;

          return `${updatedNumbers[0]}x${updatedNumbers[1]}x${updatedNumbers[2]} ${dimensionUnitString}`;
        }
        break;

      case "areaCheck":
        return countOvh(plan.area, areaUnit);

      case "volumeCheck":
        return countOvh(plan.volume, volumeUnit);

      case "weightCheck":
        return countOvh(plan.weight, weightUnit);

      case "palletCheck":
        if (plan.pallet) {
          return (
            [numberFormat(plan.pallet.length), numberFormat(plan.pallet.width)]
              .filter(e => e != null && e !== "")
              .join("×") + `(${plan.pallet.name})`
          );
        }

        break;

      default:
        break;
    }
  };

  const mappingObj = useMemo(() => {
    return {
      productSeriesCheck: "productSeries",
      materialCheck: "material",
      colorCheck: "color",
      batchCheck: "batch",
    };
  }, []);

  const joinCharacteristics = plan => {
    var currList = [];
    for (var prop in mappingObj) {
      if (plan[prop]) {
        var name = mappingObj[prop];
        if (plan[name] && plan[name] !== "") currList.push(plan[name]);
      }
    }

    return currList.join(", ");
  };

  // brings the information to the desired view (for the table)
  const getViewingInfo = plan => {
    const {settlementPrice, amount, topDiscount, discount, topDiscountCheck, discountCheck} = plan;

    var summ = (+settlementPrice || +plan.retailPrice) * (amount || 1);

    var total = discountCheck && discount ? summ - (summ * discount) / 100 : summ;
    var toPay = topDiscountCheck && topDiscount ? total - (total * topDiscount) / 100 : total;

    const ovhKeys = ["overallSizeCheck", "areaCheck", "volumeCheck", "weightCheck", "palletCheck"];

    const ovhValue = ovhKeys.map(key => getOvh(key, plan)).join(" ");

    return {
      dataForCommonString: {
        commonString: plan.note ? plan.note : "",
      },
      dataForViewImages: {
        images: overallRender ? plan.addedOverallView : [],
      },
      dataForRows: {
        brandName: plan?.brand.name,
        name: plan.nameCheck ? plan.name : plan.nameEng,

        characteristics: plan.charCheck ? joinCharacteristics(plan) : "",

        ovh: ovhValue,

        image1: plan.addedImages?.length ? plan.addedImages[0] : "",

        image2: plan.addedImages?.length >= 2 ? plan.addedImages[1] : "",
        complectDesc: plan.complectDescCheck ? plan.complectDesc : "",
        amount: plan.amount,
        price: formatter.format(plan.retailPrice),
        summ: formatter.format(summ),
        discount: plan.discountCheck && plan.discount ? procformatter.format(plan.discount / 100) : "",
        totalSumm: formatter.format(total),
        topDiscount: plan.topDiscountCheck && plan.topDiscount > 0 ? procformatter.format(plan.topDiscount / 100) : "",
        paymentSumm: formatter.format(toPay),
      },
      dataForDecorImages: {
        text: plan.materialTypeCheck ? plan.materialType : "",
        images: plan.addedDecorImages ? plan.addedDecorImages : [],
      },
      dataForColorImages: {
        text: plan.colorTypeCheck ? plan.colorType : "",
        images: plan.addedColorImages ? plan.addedColorImages : [],
      },
    };
  };

  const listData = useMemo(() => {
    if (values) {
      return getViewingInfo(values);
    }
  }, [values]);

  const columns = useMemo(
    () => [
      {
        header: () => [t("Brand w*")],
        accessorKey: "brandName",
      },
      {
        header: () => [t("Nomenclature")],
        accessorKey: "name",
      },
      {
        header: () => [t("Characteristics")],
        accessorKey: "characteristics",
      },
      {
        header: () => [t("VWC")],
        accessorKey: "ovh",
      },
      {
        header: () => [t("Image")],
        accessorKey: "image1",
        size: 250,
        width: 250,
      },
      {
        header: () => [t("Image")],
        accessorKey: "image2",
        size: 250,
        width: 250,
      },

      {
        header: () => [t("Set description")],
        accessorKey: "complectDesc",
      },

      {
        header: () => [t("Quantity")],
        accessorKey: "amount",
      },
      {
        header: () => [t("Price pcs")],
        accessorKey: "price",
      },
      {
        header: () => [t("Sum €")],
        accessorKey: "summ",
      },
      {
        header: () => [t("Discount %")],
        accessorKey: "discount",
        size: 10,
        width: 10,
      },
      {
        header: () => [t("Total €")],
        accessorKey: "totalSumm",
      },
      {
        header: () => [t("Total discount %")],
        accessorKey: "topDiscount",
      },
      {
        header: () => [t("Payment €")],
        accessorKey: "paymentSumm",
      },
    ],
    [],
  );

  return (
    <div style={{height: "100%", paddingBottom: "1em"}}>
      <div className="kpf_table_overflow">
        <AddToKpfTable
          values={values}
          setFieldValue={setFieldValue}
          columns={columns}
          data={listData}
          className={className}
          chengeable={chengeable}
        />
      </div>
    </div>
  );
};

AddSkuTable.propTypes = {values: PropTypes.object, setFieldValue: PropTypes.func, overallRender: PropTypes.bool};

export default AddSkuTable;
