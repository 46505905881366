import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {REQ_WINDOW_TYPES} from "../../../../RequestsInProgress/utils/functions/requestsFiltersManager";
import {fetchRequests, getFourthStatusRequests} from "../../../../RequestsSlice";

const useStatus4Requests = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRequests({window: REQ_WINDOW_TYPES.PROCESSED}));
  }, []);

  const status4Requests = useSelector(getFourthStatusRequests);
  return status4Requests;
};

export default useStatus4Requests;
