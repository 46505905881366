import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import QuestionItem from "../../../../utilities/QuestionItem";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

import useLogistFilters from "./useLogistFilters";
import withLogistFilters from "./withLogistFilters";

const BaseLogistFilters = () => {
  const {t} = useTranslation();
  const {
    brandsOptions,
    // feaManagersOptions,
    coManagersOptions,
    requestStatusOptions,
    requestNumberOptions,
    logistOptions,
    clientNumberOptions,
    countryOptions,
  } = useLogistFilters();

  return (
    <>
      <div className="f_block" style={{paddingTop: "0.7em"}}>
        <Select label={t("Status")} name="statusId" options={requestStatusOptions} width="12em" labelwidth="8em" />
        <Select
          label={t("Request №")}
          name="requestNumber"
          options={requestNumberOptions}
          width="12em"
          labelwidth="8em"
        />
        <Select label={t("CO Manager")} name="managerCoId" options={coManagersOptions} width="12em" labelwidth="8em" />
        <Select label={t("Factory")} name="brandId" options={brandsOptions} width="12em" labelwidth="8em" />
        <TextInput label="Prof №" name="proformaNumber" width="12em" labelStyles={{width: "8em"}} />
        {/* <TextInput label={t("Invoice №")} name="invoice" width="12em" labelStyles={{width: "8em"}} disabled />
        <Select
          label={t("FEA Manager")}
          name="managerFeaId"
          options={feaManagersOptions}
          width="12em"
          labelwidth="8em"
        /> */}
        <Select label={t("Client №")} name="clientNumber" options={clientNumberOptions} width="12em" labelwidth="8em" />
        <Select label={t("Logist")} name="logistId" options={logistOptions} width="12em" labelwidth="8em" />

        <Select label={t("Pr country")} name="countryId" options={countryOptions} width="12em" labelwidth="8em" />
      </div>
      <div className="period-wrapper" style={{paddingTop: "0.7em"}}>
        <QuestionItem title={"Select date"} />
        {t("d period")}
        <div className="request-date-wrapper">
          <DateInput label={t("From")} name="dateStart" />
          <DateInput label={t("To")} name="dateEnd" />
        </div>
      </div>
    </>
  );
};

const FullLogistFilters = withLogistFilters(BaseLogistFilters);

const FullLogistFiltersForm = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(FullLogistFilters);

export default FullLogistFiltersForm;
