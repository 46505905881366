import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import QuestionItem from "../../../../utilities/QuestionItem";
import {TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {selectFactoryData} from "../../RequestsSlice";

import styles from "./ReceiptGoodsModal.module.scss";

const AdressBlock = () => {
  const {t} = useTranslation();

  const {values, setFieldValue} = useFormikContext();

  const factoryData = useSelector(selectFactoryData);

  const adressData = useMemo(() => {
    return factoryData.length !== 0
      ? factoryData?.map(item => ({
          value: item.contactId,
          title: item.address || `${item.name} (address not specified)`,
        }))
      : [];
  }, [factoryData]);

  // useEffect(() => {
  //   const factoryContactInfo = factoryData.find(item => item.contactId === values.factoryName);

  //   if (factoryContactInfo) {
  //     setFieldValue(
  //       "factoryNote",
  //       `${t("cont pers")}: ${t("Name")}: ${factoryContactInfo?.name} / ${t("tel")}: ${
  //         factoryContactInfo?.phone
  //       } / E-mail: ${factoryContactInfo?.email} `,
  //     );
  //   } else setFieldValue("factoryNote", "");
  // }, [values.factoryName, factoryData]);

  return (
    <section className={styles.addressSection}>
      <div className={styles.sectionHeader}>
        1.
        {t("Factory / warehouse address")}
        <QuestionItem title="adress req" />
      </div>
      <Select name="factoryName" options={adressData} />

      <TextAreaInput name="factoryNote" className={styles.factoryNote} width="100%" height="60px" disabled />
    </section>
  );
};

export default AdressBlock;
