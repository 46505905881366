// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestBlock_reqBlockWrapper__IyMuc {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
}

.RequestBlock_reqBlockTextarea__psx9y {
  flex-shrink: 0;
  width: 100%;
  height: 7vh;
  padding: 0.5rem;
  background-color: #fff;
}

.RequestBlock_reqBlockInput__lEzJr {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.RequestBlock_reqBlockInput__lEzJr::placeholder {
  color: #a3b9d9;
}`, "",{"version":3,"sources":["webpack://./src/Components/Requests/RequestBlock/RequestBlock.module.scss","webpack://./src/Components/App/vars.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,SAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;EACA,sBCZa;ADWf;;AAIA;EACE,cAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;AADF;AAEE;EACE,cCpBe;ADoBnB","sourcesContent":["@import \"../../App/vars.scss\";\n\n.reqBlockWrapper {\n  flex-shrink: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin: 0;\n}\n\n.reqBlockTextarea {\n  flex-shrink: 0;\n  width: 100%;\n  height: 7vh;\n  padding: 0.5rem;\n  background-color: $tableBgColor;\n}\n\n.reqBlockInput {\n  display: block;\n  margin-top: 0;\n  margin-bottom: 0;\n  width: 100%;\n  &::placeholder {\n    color: $placeholderColor;\n  }\n}\n","$border-thin: 1px solid #426ca65e;\n\n//colors\n$tableBgColor: #fff;\n$placeholderColor: #a3b9d9;\n$borderColor: #a3b9d9;\n$accentFilterColor: #ff8040;\n\n//fonts\n$baseFontSize: 16px;\n\n//sizes\n$baseHeight: 720;\n$baseWidth: 1190;\n$borderRadius: 5px;\n\n$inputSelectBgColor: #ffffffeb;\n$inputSelectHoverBgColor: #d6dfea93;\n\n//shadows\n$filterBtnBoxShadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reqBlockWrapper": `RequestBlock_reqBlockWrapper__IyMuc`,
	"reqBlockTextarea": `RequestBlock_reqBlockTextarea__psx9y`,
	"reqBlockInput": `RequestBlock_reqBlockInput__lEzJr`
};
export default ___CSS_LOADER_EXPORT___;
