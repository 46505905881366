import {useMemo} from "react";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {getRequestInfo} from "../../Requests/RequestsSlice";

import {ORDER_TABLE_COLUMNS} from "./constants";

const useLogModRqstCheckListService = () => {
  const curentRequest = useSelector(getRequestInfo);

  // const [curentFile, setCurentFile] = useState([]);
  const initialValues = {
    //HeaderInputRow
    managerFea: curentRequest?.managerFea?.name || "",
    spNumber: curentRequest?.stockPool?.stockPoolNumber || "",
    tpNumber: "",
    // managerCO: "",
    // transportTo: "",
    executionDate: curentRequest?.requestDates?.[7]?.actualDate || "",
    //DeliveryInfo
    shippingInfoArea: "",
    todo: "",
    //GroupAndType
    orderNumber: "",
    //DocumentsInfo
    documentType: "",
    documentNumber: "",
    //ShipmentInfo
    destination: "",
    shipmentRqstNumber: "",
  };
  const headerInputNames = [
    {name: "managerFea", label: "Manager FEA", readOnly: true, width: "15rem"},
    {name: "spNumber", label: "SP №", autolabel: true, readOnly: true},
    {name: "tpNumber", label: "TP №", autolabel: true, readOnly: true},
    // {name: "managerCO", label: "CO Manager"},
    // {name: "transportTo", label: "Transport To", autolabel: true},
    {name: "executionDate", label: "Execution date", autolabel: true, type: "date", readOnly: true},
  ];
  const orderTableColumns = useColumns(ORDER_TABLE_COLUMNS);
  const orderTableData = [];
  const pdfFileArray = [];

  const documentsTableData = useMemo(() => {
    var docs = [];
    var counter = 1;
    curentRequest?.proforms?.map(pdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(pdoc?.createdAt) || "",
        docType: "Проформа",
        docNumber: "Prof № " + pdoc?.proformaNumber || "",
        createdBy: "",
        url: pdoc?.pdfFileUrl || "",
      });
      counter++;
    });
    curentRequest?.transportDocs?.map(tdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(tdoc?.dateFrom) || "",
        docType: tdoc?.docType.nameUkr || "",
        docNumber: tdoc?.docNumber || "",
        createdBy: "",
        url: tdoc?.fileUrl || "",
      });
      counter++;
    });
    return docs;
  }, [curentRequest]);

  return {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    documentsTableData,
    curentRequest,
  };
};

export default useLogModRqstCheckListService;
