import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

// Загрузка шрифтов для pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePdf = (data, headers, common) => {
  // Определение стилей для таблицы
  const styles = {
    tableHeader: {
      bold: true,
      fontSize: 10,
      color: "black",
      alignment: "center",
    },
    cell: {
      fontSize: 9,
      color: "black",
      alignment: "center",
    },
    justy: {
      alignment: "center",
    },
    nestedTable: {
      margin: [0, 0, 0, 0],
      alignment: "left", // Убираем отступы внутри ячейки с вложенной таблицей
    },
    header: {
      bold: true,
      fontSize: 9,
    },
    headerH1: {
      bold: true,
      fontSize: 16,
      alignment: "center",
      margin: [0, 10, 0, 0],
    },
    headerH2: {
      bold: true,
      fontSize: 14,
      alignment: "center",
      margin: [0, 10, 0, 5],
    },
    simpletext: {
      margin: [0, 5, 0, 0],
    },
    emptyRow: {
      lineHeight: 2,
      color: "white",
    },
  };

  // Определение содержимого таблицы
  const header = [];
  const headersAccesors = [];
  const tableContent = [];

  headers.forEach((th, i) => {
    if (i === 0) {
      header.push({text: "№", style: "tableHeader"});
      headersAccesors.push(th.accessorKey);
      return;
    }
    header.push({text: th.header, style: "tableHeader"});
    headersAccesors.push(th.accessorKey);
  });
  tableContent.push(header);
  data.forEach((rowData, i) => {
    const tableRow = [];
    for (var key in rowData) {
      if (headersAccesors.includes(key)) {
        tableRow.push({
          text: rowData[key],
          style: "cell",
          pageBreak: "avoid",
          alignment: "center",
        });
      }
    }

    tableContent.push(tableRow);
  });

  // Определение документа PDF
  const documentDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    footer: function (currentPage, pageCount) {
      return {
        margin: [40, 0, 40, 0],

        table: {
          widths: ["auto", "*", "auto", "*"],
          body: [
            [
              {text: "Прийняв: " + common.commission[0], style: "cell", alignment: "left", id: "1t"},
              {text: "підпис", style: "cell", alignment: "left", id: "2t"},
              {text: "Передав: " + common.commission[1], style: "cell", alignment: "left", id: "3t"},
              {text: "підпис", style: "cell", alignment: "left", id: "4t"},
            ],
            [
              {
                text: "Сторінка " + currentPage.toString() + " з " + pageCount,
                alignment: "right",
                colSpan: 4,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      };
    },
    content: [
      {text: "АКТ", style: "headerH1"},
      {text: "приймання - передачі матеріальних цінностей", style: "headerH1"},
      {
        columns: [
          {
            width: "auto",
            text: `місто ${common?.city || ""} `,
            alignment: "left",
            margin: [0, 20, 0, 20],
          },
          {
            width: "*",
            text: `Дата: ${common?.date.toString() || ""}`,
            alignment: "right",
            margin: [0, 20, 0, 20],
          },
        ],
      },
      {text: "Ми, що нижче підписалися:" || "", style: "simpletext"},
      ...common.commission.map(member => ({text: member, style: "simpletext"})),
      {text: common?.actText || "", style: "simpletext"},
      {text: "Факт передачі засвідчується підписами сторін на кожному аркуші Акту." || "", style: "simpletext"},
      {text: "Опис матеріальних цінностей що передаються", style: "headerH2", margin: [0, 20, 0, 5]},
      {
        table: {
          widths: ["auto", "auto", "auto", "*", "*", "*", "auto", "auto", "auto", "auto", "auto"],
          style: "nestedTable",
          body: tableContent,
        },
        layout: {
          dontBreakRows: true, // Это свойство предотвращает разрыв строк между страницами
          pageBreak: "before",
        },
      },
      {text: common.noClaimText, style: "simpletext", margin: [0, 20, 0, 5]},
    ],
    styles: styles,
  };

  // Генерация и экспорт PDF-файла
  var pdf = pdfMake.createPdf(documentDefinition);
  return pdf;
};
