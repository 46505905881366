import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import ReactTable from "../../../conteiners/ReactTable";
// import ReactTable from "../../../conteiners/ReactTableUniSelect";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {fetchRequests, getFirstStatusRequest} from "../../RequestsSlice";
import {MANAGER_CO_INCOMING_REQ_TABLE, MANAGER_CO_REQ_TABLE} from "../RequestTable/utils/constants";

import styles from "./ordering.module.scss";

const IncomingRequestTable = ({selectedCreatedReq, onRequestSelect}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const requests = useSelector(getFirstStatusRequest);

  // useEffect(() => {
  //   dispatch(fetchRequests({statusId: 1, window: "Incoming requests"}));
  // }, []);

  function compositeProductGroupNames(productGroups) {
    if (!productGroups) return "";
    return productGroups.reduce(
      (acc, productGroup) => (acc === "" ? productGroup.name : acc + ",  " + productGroup.name),
      "",
    );
  }

  const transformForTable = (item, i) => {
    const {requestId, status, brand, managerFea, managerCo, cos, createdAt, updatedAt, productGroups, ...rest} = item;

    //TODO add country field data from productGroups
    const closerBringTo = cos.reduce((prev, current) => {
      const prevDate = prev.bringTo;
      const currentDate = current.bringTo;
      return prevDate > currentDate;
    });

    const statusName = status?.engName ? status[i18n.language === "en" ? "engName" : "ukrName"] : "";
    return {
      ...rest,
      number: i + 1,
      status: statusName,
      managerCo: managerCo?.name || "",
      managerFea: managerFea?.name || "",
      factory: brand?.name || "",
      coNumber: cos[0]?.coNumber || "",
      id: requestId,
      createdAt: dateFormat(createdAt),
      updatedAt: dateFormat(closerBringTo),
      noname: "",
      // country: "",
      productGroup: compositeProductGroupNames(productGroups),
      country: cos[0]?.project?.country?.shortName,
      bringTo: dateFormat(cos[0]?.bringTo),
    };
  };
  const defaultData = useMemo(() => {
    return requests ? requests.map(transformForTable) : [];
  }, [requests, i18n.language]);

  const columns = useColumns(MANAGER_CO_INCOMING_REQ_TABLE);
  return (
    <div className={styles.table + " table_xscroll__container "}>
      <ReactTable
        defaultData={defaultData}
        columns={columns}
        onSelect={() => {}}
        current={null}
        setMultiSelect={onRequestSelect}
        enableMultiSelection={true}
        defaultMultiSelection={selectedCreatedReq}
      />
    </div>
  );
};

export default IncomingRequestTable;
