export const WAREHOUSE_DOCS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Document name",
    accessorKey: "docName",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Document date",
    accessorKey: "docDate",
    size: 70,
    isSortable: true,
  },

  {
    header: "Upload date",
    accessorKey: "uploadDate",
    size: 70,
    isSortable: true,
  },
]);

export const TRANSPORTER_DOCS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Document date",
    accessorKey: "docDate",
    size: 70,
    isSortable: true,
  },

  {
    header: "Upload date",
    accessorKey: "uploadDate",
    size: 70,
    isSortable: true,
  },
]);
