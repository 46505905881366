import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {dateFormat} from "../../../../utilities/dateFormat";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import {ovhJoin} from "../../../../utilities/ovhJoin";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectCurrentSkuRequest} from "../../redux/selectors";
import {ORDER_ACCEPTANCE_YES_COLUMNS} from "../constants";
import {generatePdf} from "../PDF/pdfAccYES";

const useOrderAcceptanceYes = ({acceptedSku}) => {
  const {initialValues, values, setValues} = useFormikContext();
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const currentSp = useSelector(selectCurrentSkuRequest);
  const users = useSelector(state => state.person.users);

  const [logists, setLogists] = useState(["", ""]);

  const usersOptions =
    users?.map(({userId, persona}) => ({value: userId, title: makeNameFromPersona({persona, language})})) ?? [];

  const [currentSkuId, setCurrentSkuId] = useState(null);

  useEffect(() => {
    if (!currentSp || !currentSp?.stockPoolId) {
      setValues(initialValues);
    } else {
      setValues(prev => ({
        ...prev,
        fdp: currentSp.stockPool?.cargo?.endPoint?.name ?? " - ",
        requestNumber: currentSp.request.requestNumber ?? "",
        orderNumber: currentSp.order.orderNumber ?? "",
        managerCo: makeNameFromPersona({persona: currentSp.order.co.managerCo.persona, language}) ?? "",
        logist: makeNameFromPersona({persona: currentSp.stockPool.logist.persona, language}) ?? "",
        city: (currentSp.request.brand.factoryContacts ?? [])[0]?.address ?? "",
        actDate: dateFormat(Date.now()),
        actInfo: [t("Act Logist Info", {firstLogist: logists[0], secondLogist: logists[1]})],
        claimsInfo: `${t("Claims info text")} ${logists[1]}`,
      }));
    }
  }, [currentSp?.stockPoolId, logists]);

  useEffect(() => {
    const transferedLogist =
      values.transferByYourOption || usersOptions?.find(user => user.value === values.transferedBy)?.title || "  -  ";
    const acceptedLogist =
      values.acceptedByYourOption || usersOptions?.find(user => user.value === values.acceptedBy)?.title || "  -  ";
    setLogists([transferedLogist, acceptedLogist]);
  }, [values.transferByYourOption, values.transferedBy, values.acceptedByYourOption, values.acceptedBy]);

  const skuTableColumns = useColumns(ORDER_ACCEPTANCE_YES_COLUMNS);
  const skuTableData =
    acceptedSku?.map((sku, index) => {
      const {
        brand,
        skuId,
        skuRequestId,
        name,
        nameEng,
        amount,
        retailPrice,
        complectDesc,
        material,
        materialType,
        color,
        measurementUnit,
      } = sku;
      const ovh = ovhJoin(sku);
      return {
        id: skuId,
        skuRequestId,
        number: ++index,
        skuNumber: skuId,
        brandName: brand?.name ?? " - ",
        skuName: (language === "en" ? nameEng : name) ?? " - ",
        characteristics: [material, materialType, color].filter(item => !!item && item !== "").join("; "),
        ovh: ovh,
        complectDesc: complectDesc ?? " - ",
        amount: amount ?? " - ",
        measurementUnit: measurementUnit[language === "en" ? "engName" : "name"] ?? " - ",
        price: retailPrice ?? " - ",
        sum: (retailPrice * amount) / 1 ?? " - ",
      };
    }) ?? [];

  const isSaveForSignatureBtnDisabled =
    !(values.transferedBy || values.transferByYourOption) ||
    !(values.acceptedBy || values.acceptedByYourOption) ||
    !values.actInfo ||
    !values.claimsInfo;

  function onSkuTableSelect(skuId) {
    setCurrentSkuId(skuId);
  }

  const exportToPdf = async () => {
    const common = {
      city: values?.city,
      date: values?.actDate,
      commission: logists,
      actText: values.actInfo,
      noClaimText: values.claimsInfo,
    };

    var pdf = generatePdf(skuTableData, skuTableColumns, common);
    // pdf.open();
    pdf.download(`act_receive_sp-${currentSp?.stockPoolId}_doc`);
  };

  return {
    skuTableColumns,
    skuTableData,
    onSkuTableSelect,
    currentSkuId,
    usersOptions,
    isSaveForSignatureBtnDisabled,
    exportToPdf,
  };
};

export default useOrderAcceptanceYes;
