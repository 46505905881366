import {useEffect, useMemo, useState} from "react";
import {Tabs} from "@mui/base/Tabs";
import PropTypes from "prop-types";

import CloseBtn from "../../../utilities/Buttons/CloseBtn";
import {Tab, TabPanel, TabsList} from "../ModalWithTabs/styledTabs";

const TabsModalDynamic = ({
  active,
  setModalActive,
  modalTabs = [],
  setModalTabs = () => {},
  onModalClose = () => {},
  closable = false,
  closableTabs = false,
  className = "",
  modalInnerWrapperClassName = "",
  height = "70%",
  ...generalProps
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(modalTabs);
  }, [modalTabs]);

  function closeModal() {
    setTabs(modalTabs);
    setTabValue(0);
    onModalClose();
    setModalActive(false);
  }

  const handleEscapeKey = event => {
    if (event.key === "Escape" && closable) {
      closeModal();
    }
  };

  useEffect(() => {
    if (closable) {
      window.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
      if (closableTabs) {
        setTabs(modalTabs);
      }
    };
  }, []);

  const removeFocusFromButton = isActive => {
    if (isActive) {
      const openButton = document.activeElement;
      if (openButton && openButton.blur) {
        openButton.blur(); // remove focus on button when modal was closed by Escape
      }
    }
  };

  const onTabClose = index => {
    setTabs(prevTabs => prevTabs.filter((tab, tabIndex) => tabIndex !== index));
    if (index <= tabValue && tabValue !== 0) {
      setTabValue(tabValue - 1);
      try {
        tabs[index].onClose();
      } catch (e) {
        return;
      }
    }
  };

  useEffect(() => {
    removeFocusFromButton(active);
  }, [active]);

  const tabsRender = useMemo(
    () =>
      tabs.map((tab, index) => (
        <TabPanel key={index} value={index} className={`fGrow ${index !== tabValue ? "hiddenEl" : ""}`}>
          <tab.Component setModalTabs={setModalTabs} setTabValue={setTabValue} {...tab.props} {...generalProps} />
        </TabPanel>
      )),
    [tabs, tabValue, generalProps],
  );

  const tabsButtons = tabs.map((tab, index) => {
    return (
      <Tab key={index} value={index} index={index} style={{cursor: "pointer"}}>
        {tab?.label ?? "No label"}
        {closableTabs && tabs.length > 1 && index !== 0 && (
          <span
            style={{marginLeft: "1em", cursor: "pointer"}}
            onClick={e => {
              e.preventDefault();
              onTabClose(index);
            }}
          >
            &#215;
          </span>
        )}
      </Tab>
    );
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return active ? (
    <div className="modal-form__container">
      <Tabs
        value={tabValue}
        className={`modal_form ${className}`}
        onChange={handleTabChange}
        style={{minHeight: height}}
      >
        <div className="madal_header">
          <TabsList>{tabsButtons}</TabsList>
          {closable && (
            <CloseBtn
              style={{position: "relative", top: "0.2em", marginLeft: "2em"}}
              onClick={() => {
                if (closable) {
                  closeModal();
                }
              }}
            />
          )}
        </div>
        <div className="overflow_form_modal">
          <div className={`${modalInnerWrapperClassName} tabmodal_form__child fGrow fcCol`}>{tabsRender}</div>
        </div>
      </Tabs>
    </div>
  ) : null;
};

TabsModalDynamic.propTypes = {
  active: PropTypes.bool.isRequired,
  setModalActive: PropTypes.func.isRequired,
  modalTabs: PropTypes.arrayOf(PropTypes.object),
  setModalTabs: PropTypes.func,
  onModalClose: PropTypes.func,
  closable: PropTypes.bool,
  closableTabs: PropTypes.bool,
  className: PropTypes.string,
  modalInnerWrapperClassName: PropTypes.string,
  height: PropTypes.string,
};
export default TabsModalDynamic;
