import {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {apiUrl} from "../../../../api/baseURL";
import {getKpInfoForKp, kpDocAdded} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import PdfFormatSwith from "../../../pdfGenerate/PdfFormatSwith";
import {generatePdf} from "../../../pdfGenerate/pdfMaker";
import {deselectSku} from "../../../SKU/SkuSlice";
import {
  addSkuPlanDoc,
  changeSequenceNumber,
  deselect,
  getCurrentSkuPlan,
  getPlanSkuList,
  getSkuPlanInfo,
} from "../KpFinSlice";

import useKpFinCollumnsService from "./utils/kpFinColumns";
import useKpFinDataService from "./KpFinDataService";
import useKpFinTableService from "./KpFinTableService";

import "./kpFinTable.scss";

const KpFinTable = ({currentKpF, kpFin}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const {currentKpId} = location.state || {currentRequestId: ""};
  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const [pdfFormat, setPDFFormat] = useState("A4");

  const planSkuList = useSelector(getPlanSkuList);

  const {getKpFinTableData} = useKpFinDataService();

  const kpFinTableData = useMemo(() => {
    return planSkuList?.map(getKpFinTableData) || [];
  }, [planSkuList]);

  // for numeration change, when user moves the lines in table
  const onReorder = useCallback(
    reorderedTableData => {
      if (reorderedTableData) {
        var sequenceObject = reorderedTableData.map(plan => {
          return {
            skuPlanId: plan.id,
            sequenceNumber: plan.number,
          };
        });

        dispatch(changeSequenceNumber({sequenceObject}));
      }
    },
    [currentKpF],
  );

  const selectSkuPlan = id => {
    if (currentSkuPlan && currentSkuPlan.skuPlanId === id) {
      dispatch(deselect());
      return;
    }
    if (kpFin) {
      dispatch(getSkuPlanInfo(id));

      dispatch(deselectSku());
    }
  };

  const exportToPdf = () => {
    const data = generateDataForExport(`${t("CO(f)")}_${currentKpF.cofNumber}`, pdfFormat);
    var pdf = generatePdf(data, pdfFormat);
    // pdf.open();
    pdf.download(`kpf_${currentKpF.cofNumber}_doc`);
    pdf.getBlob(file => {
      var formData = new FormData();
      var fileName = `kpf_${currentKpF.cofNumber}_doc.pdf`;
      formData.append("doc", file, fileName);

      dispatch(addSkuPlanDoc({cofId: currentKpF.cofId, formData})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(kpDocAdded(res.payload.docs));
        }
      });
    });
  };

  const onReturnToKpClick = () => {
    Swal.fire({
      // title: t("Sure"),
      text: t("export before return co"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(value => {
      if (value.isConfirmed) {
        exportToPdf();
        setTimeout(() => {
          dispatch(getKpInfoForKp(currentKpId));
          navigate("/ba3a/co");
        }, 2000);
      } else {
        // dispatch(getKpInfoForKp(currentKpId));
        navigate("/ba3a/co", {state: {needUpd: true, coId: currentKpId}});
      }
    });
  };

  const toggledColl = useMemo(() => ["article", "characteristics", "complectDesc", "discount", "topDiscount"], []);
  const {collumns} = useKpFinCollumnsService(toggledColl);

  const {KpFinTableRender, generateDataForExport} = useKpFinTableService(
    collumns,
    kpFinTableData,
    onReorder,
    selectSkuPlan,
    toggledColl,
  );
  const KpFinTable = KpFinTableRender();

  return (
    <>
      {KpFinTable}
      <div
        className="fc jcsb"
        style={{
          marginBottom: "10px",
          // position: "absolute",
          bottom: "-0.23em",
          width: "100%",
        }}
      >
        <div className="fc">
          <FunctionButton name={t("Export")} onClick={exportToPdf} disabled={planSkuList?.length === 0} />
          <FunctionButton name={t("Dublicate")} style={{marginLeft: "10px"}} disabled={true} />
        </div>
        <PdfFormatSwith setFormat={setPDFFormat} />
        <div className="fc">
          <FunctionButton name={t("Template")} style={{marginLeft: "10px"}} autoWidth disabled={true} />
          <FunctionButton name={t("Postings")} style={{marginLeft: "10px"}} disabled={true} />
        </div>
        <div>
          <FunctionButton name={t("return to kp")} style={{marginLeft: "10px"}} onClick={onReturnToKpClick} />
        </div>
      </div>
    </>
  );
};

KpFinTable.propTypes = {
  onUpdate: PropTypes.func,
  currentKpF: PropTypes.object,
  setCurrSkuPlan: PropTypes.func,
  active: PropTypes.object,
  setActive: PropTypes.func,
};

export default KpFinTable;
