import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import {TextInput} from "../../Inputs";
import ModalForm from "../../Modal/FormModal";
import {getRequestInfo} from "../../Requests/RequestsSlice";
import LogistRequestSkuTable from "../CheckLists/tabsType/LogistRequestSkuTable";
import AdditionalServices from "../common/AdditionalServices/AdditionalServices";
import DeliveryInfo from "../common/DeliveryInfo/DeliveryInfo";
import DocumentsInfo from "../common/DocumentsInfo/DocumentsInfo";
import FactoryInfo from "../common/FactoryInfo/FactoryInfo";
import {GROUP_AND_TYPE_MODES} from "../common/GroupAndType/constants";
import GroupAndType from "../common/GroupAndType/GroupAndType";
import HeaderInputRow from "../common/HeaderInputRow/HeaderInputRow";
import OrderFullSection from "../common/OrderFullSection/OrderFullSection";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";

import useLogModOrderCheckListService from "./useLogModOrderCheckListService";

const LogModOrderCheckList = ({orderId}) => {
  const {t} = useTranslation();
  const curentRequest = useSelector(getRequestInfo);

  const [requestSkusModalActive, setRequestSkusModalActive] = useState(false);

  const {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    // onCheckSkuBtnClick,
    curentOrder,
    requestSkus,
    documentsTableData,
  } = useLogModOrderCheckListService(orderId);

  // const {documentsTableData: documentsTableDataREQ} = useLogModRqstCheckListService();
  // const {documentsTableData: documentsTableDataSP} = useLogModSPCheckList();

  // const documentsTableData = curentRequest ? documentsTableDataREQ : documentsTableDataSP;

  const onCheckSkuBtnClick = () => {
    setRequestSkusModalActive(true);
  };

  return (
    <div className="page_flex_container">
      <Formik initialValues={initialValues} enableReinitialize={true}>
        <Form className="w100 fcCol flexg1">
          <div className="fc nowrap w100 g15">
            <HeaderInputRow headerInputNames={headerInputNames} />
            <FunctionButton name="View the SKU" onClick={onCheckSkuBtnClick} className="center bottom" />
          </div>
          <Divider />
          <OrderFullSection
            // fileArray={pdfFileArray}
            firstColumn={
              <>
                <FactoryInfo />
                <DeliveryInfo>
                  <div>
                    <TextInput label={t("Payer")} name="payer" autolabel />
                    <TextInput label={t("Address") + ":"} name="payerAddress" autolabel />
                    <TextInput label={t("Recipient") + ":"} name="recipient" autolabel />
                    <TextInput label={t("Address") + ":"} name="recipientAddress" autolabel />
                    <TextInput label={t("Decommissioning") + ":"} name="decommissioning" autolabel />
                    <div className="fc jcsb nowrap">
                      <TextInput label={t("Delivery condition") + ":"} name="delCondition" autolabel />
                      <TextInput label={t("Logist") + ":"} name="logist" autolabel />
                    </div>
                  </div>
                  <div style={{height: "100px", marginTop: "2em"}}>
                    <CheckListModalsTodo path={"order"} entityId={curentOrder?.orderId || null} />
                  </div>
                </DeliveryInfo>
              </>
            }
            secondColumn={
              <>
                <GroupAndType mode={GROUP_AND_TYPE_MODES.TEXTAREA} />
                <DocumentsInfo tableHeight="160px" documentsTableData={documentsTableData} />
                <AdditionalServices />
              </>
            }
          ></OrderFullSection>
          <ReactTable
            columns={orderTableColumns}
            defaultData={orderTableData}
            className="w100 pb"
            style={{height: "15em", flexGrow: 1}}
          />
        </Form>
      </Formik>
      <ModalForm
        closable={true}
        closableOnEsc={false}
        Component={LogistRequestSkuTable}
        label={t("request review") + curentOrder?.orderNumber}
        active={requestSkusModalActive}
        setModalActive={setRequestSkusModalActive}
        className="modal_sizes"
        orderId={orderId}
        // modalInnerWrapperClassName={styles.modalInnerWrapper}
      />
    </div>
  );
};

export default LogModOrderCheckList;
