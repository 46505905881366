import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";
import {numberFormat} from "../../../../utilities/moneyFormat";

import {procformatter} from "./utils/functionalFormatters";

const useKpFinDataService = () => {
  const {i18n} = useTranslation();

  const mappingObj = useMemo(() => {
    return {
      productSeriesCheck: "productSeries",
      materialCheck: "material",
      colorCheck: "color",
      batchCheck: "batch",
    };
  }, []);

  const joinCharacteristics = plan => {
    var currList = [];
    for (var prop in mappingObj) {
      if (plan[prop]) {
        var name = mappingObj[prop];
        if (plan[name] && plan[name] !== "") currList.push(plan[name]);
      }
    }
    return currList.join(", ");
  };

  const countOvh = (value, unit) => {
    if (value && unit)
      return value > 0
        ? numberFormat(value * unit.multiplier) +
            (unit?.name ? unit[i18n.language === "en" ? "engName" : "name"] : null)
        : "";
    else return "";
  };

  const ovhJoin = sku => {
    try {
      var ovhValues = [];

      if (sku.overallSizeCheck) {
        const {length, width, height, overallSizeUnit} = sku;
        var dimensionMass = [length, width, height];
        const hasDimensionValue = dimensionMass.some(item => item !== null && item !== "0.00");
        const name = i18n.language === "en" ? "engName" : "name";
        const multiplier = overallSizeUnit?.multiplier;

        dimensionMass.map(item => {
          if (item > 0) return countOvh(item, multiplier);
          else return "";
        });

        const updatedNumbers = dimensionMass.map(num => (num === null || num === "0.00" ? "0" : num));

        if (hasDimensionValue) {
          ovhValues.push(`${updatedNumbers[0]}x${updatedNumbers[1]}x${updatedNumbers[2]} ${overallSizeUnit[name]}`);
        } else {
          return null;
        }
      }

      const ovhKeys = ["area", "volume", "weight", "pallet"];

      ovhKeys.forEach(key => {
        if (sku[key + "Check"]) {
          ovhValues.push(countOvh(sku[key], sku[key + "Unit"]));
        }
      });

      return ovhValues.join(" ");
    } catch {
      return "";
    }
  };

  const getKpFinTableData = plan => {
    const {settlementPrice, amount, topDiscount, discount, topDiscountCheck, discountCheck} = plan;
    var summ = (+settlementPrice || +plan.retailPrice) * (amount || 1);
    var total = discountCheck ? summ - (summ * discount) / 100 : summ;
    var toPay = topDiscountCheck ? total - (total * topDiscount) / 100 : total;

    return {
      number: plan.sequenceNumber,
      article: plan?.brand?.prefix + "-" + plan?.article,

      id: plan.skuPlanId,
      brandName: plan.brand?.name,
      skuName: plan.nameCheck ? plan.name : plan.nameEng,
      characteristics: plan.charCheck ? joinCharacteristics(plan) : "",
      ovh: ovhJoin(plan),
      image1: plan.addedImages?.length ? plan.addedImages[0] : "",
      image2: plan.addedImages?.length >= 2 ? plan.addedImages[1] : "",
      complectDesc: plan.complectDescCheck ? plan.complectDesc : "",
      amount: plan.amount,
      price: plan.retailPrice,
      summ: summ,
      discount: plan.discountCheck ? procformatter.format(plan.discount / 100) : "",
      totalSumm: total,
      topDiscount: plan.topDiscountCheck ? procformatter.format(plan.topDiscount / 100) : "",
      paymentSumm: toPay,
      overallView: plan?.addedOverallView,
      decorText: plan?.materialTypeCheck ? plan?.materialType : "",
      decorImages: plan?.addedDecorImages,
      colorText: plan?.colorTypeCheck ? plan?.colorType : "",
      colorImages: plan?.addedColorImages,
      headerData: plan?.note,
    };
  };

  const getRequestSkuTableData = sku => {
    const {amount, listPrice} = sku;
    var summ = listPrice * (amount || 1);

    return {
      number: sku.sequenceNumber,
      article: sku?.brand?.prefix + "-" + sku?.article,

      id: sku.skuRequestId,
      brandName: sku.brand?.name,
      skuName: sku.nameCheck ? sku.name : sku.nameEng,
      characteristics: sku.charCheck ? joinCharacteristics(sku) : "",
      ovh: ovhJoin(sku),
      image1: sku.addedImages?.length ? sku.addedImages[0] : "",
      image2: sku.addedImages?.length >= 2 ? sku.addedImages[1] : "",
      complectDesc: sku.complectDescCheck ? sku.complectDesc : "",
      amount: sku.amount,
      price: listPrice,
      paymentSumm: summ,
      decorText: sku?.materialTypeCheck ? sku?.materialType : "",
      decorImages: sku?.addedDecorImages,
      colorText: sku?.colorTypeCheck ? sku?.colorType : "",
      colorImages: sku?.addedColorImages,
      headerData: sku?.note,
    };
  };

  const getRequestVarificationSkuTableData = sku => {
    const {amount, listPrice} = sku;
    var summ = listPrice * (amount || 1);

    return {
      number: sku.sequenceNumber,
      article: sku?.brand?.prefix + "-" + sku?.article,

      id: sku.skuRequestId,
      brandName: sku.brand?.name,
      skuName: sku.nameCheck ? sku.name : sku.nameEng,
      characteristics: sku.charCheck ? joinCharacteristics(sku) : "",
      ovh: ovhJoin(sku),
      image1: sku.addedImages?.length ? sku.addedImages[0] : "",
      image2: sku.addedImages?.length >= 2 ? sku.addedImages[1] : "",
      complectDesc: sku.complectDescCheck ? sku.complectDesc : "",
      amount: sku.amount,
      price: listPrice,
      paymentSumm: summ,
      decorText: sku?.materialTypeCheck ? sku?.materialType : "",
      decorImages: sku?.addedDecorImages,
      colorText: sku?.colorTypeCheck ? sku?.colorType : "",
      colorImages: sku?.addedColorImages,
      headerData: sku?.note,
      isSelectFroze: sku?.isInspected,
    };
  };

  return {getKpFinTableData, getRequestSkuTableData, getRequestVarificationSkuTableData};
};

export default useKpFinDataService;
