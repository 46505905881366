import PropTypes from "prop-types";

import ReactTable from "../../../../../conteiners/ReactTable";

import useIncomingStockPoolTableSelect from "./useIncomingStockPoolTableSelect";

import styles from "../../TPRequestDistributionModal.module.scss";

const IncomingStockPoolTableSelect = ({selectedSP, setSelectedSP}) => {
  const {defaultData, columns, onStockPoolCheck} = useIncomingStockPoolTableSelect({
    selectedSP,
    setSelectedSP,
  });

  return (
    <div className={" table_xscroll__container "} style={{height: "17em", width: "15em"}}>
      <ReactTable
        defaultData={defaultData}
        columns={columns}
        onSelect={() => {}}
        selectable={false}
        current={null}
        setMultiSelect={onStockPoolCheck}
        enableMultiSelection={true}
        defaultMultiSelection={selectedSP}
        tableClassName={styles.checkBoxPointer}
      />
    </div>
  );
};

IncomingStockPoolTableSelect.propTypes = {
  selectedSP: PropTypes.array.isRequired,
  setSelectedSP: PropTypes.func.isRequired,
};

export default IncomingStockPoolTableSelect;
