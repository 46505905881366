export function checkIfFactoryAddressValuesChanged(formikValues, stateValues) {
  if (!formikValues || !stateValues) return false;
  const {address, location, webSite} = stateValues;
  const compareStateValues = {address, location, webSite};

  const formikValuesKeys = Object.keys(formikValues);
  for (let i = 0; i < formikValuesKeys.length; ++i) {
    const key = formikValuesKeys[i];
    if (key in compareStateValues) {
      if (formikValues[key] !== compareStateValues[key]) {
        if (formikValues[key] === "" && compareStateValues[key] === null) continue;

        return true;
      }
    }
  }

  return false;
}

export function checkIfContactValuesChanged(formikValues, stateValues) {
  if (!formikValues || !stateValues) return false;
  const compareStateValues = {...stateValues, contactName: stateValues.name};
  const formikValuesKeys = Object.keys(formikValues);
  for (let i = 0; i < formikValuesKeys.length; ++i) {
    const key = formikValuesKeys[i];
    if (key in compareStateValues) {
      if (formikValues[key] !== compareStateValues[key]) return true;
    }
  }

  return false;
}

export function checkIfConditionValuesChanged(formikValues, stateValues) {
  if (!formikValues || !stateValues) return false;
  const {discount, discountNote, termsNote, termsCondition, unitId, termsFrom, termsTo} = stateValues;
  const compareStateValues = {
    discount: discount,
    discountNote,
    termsNote,
    termsCondition,
    unitId,
    termsFrom,
    termsTo,
  };
  const formikValuesKeys = Object.keys(formikValues);
  for (let i = 0; i < formikValuesKeys.length; ++i) {
    const key = formikValuesKeys[i];
    if (key in compareStateValues) {
      if (formikValues[key] !== compareStateValues[key]) {
        if (formikValues[key] === Number.parseFloat(compareStateValues[key])) continue;
        if (Number.parseFloat(formikValues[key]) === Number.parseFloat(compareStateValues[key])) continue;
        return true;
      }
    }
  }

  return false;
}
