import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import useList from "../../hook/list.hook";

const RemindsList = () => {
  const {formThs} = useList();
  const {t} = useTranslation();

  const ths = useMemo(() => {
    return formThs({
      "№": 15,
      [t("Date")]: 20,
      [t("Notes")]: 50,
      "D-day": 10,
      [t("Status")]: 5,
    });
  }, []);

  return (
    <div>
      <div className="project_list " style={{height: "260px"}}>
        <table className="project_table">
          <thead>
            <tr>{ths}</tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default RemindsList;
