// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-acc-form {
  width: 100%;
}
.all-acc-form .select {
  flex-direction: column;
}
.all-acc-form label {
  width: auto;
}
.all-acc-form .label {
  font-size: 1.2em;
  font-weight: 500;
  margin: 0.5em 0;
}

.acc_forms_container {
  border-bottom: 1px solid #A3B9D9;
  margin: 1em;
  padding-bottom: 0.8em;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/accounts/accountsForm/accountsForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,sBAAA;AAER;AAAI;EACI,WAAA;AAER;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,eAAA;AAER;;AACA;EACI,gCAAA;EACA,WAAA;EACA,qBAAA;EACA,WAAA;AAEJ","sourcesContent":[".all-acc-form{\n    width: 100%;\n    .select{\n        flex-direction: column;\n    }\n    label{\n        width: auto;\n    }\n    .label{\n        font-size: 1.2em;\n        font-weight: 500;\n        margin: 0.5em 0;\n    }\n}\n.acc_forms_container{\n    border-bottom: 1px solid #A3B9D9;\n    margin: 1em;\n    padding-bottom: 0.8em;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
