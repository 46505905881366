export const ORDER_BLOCK_COLUMNES = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Order",
    accessorKey: "requestNumber",

    // size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    // size: 100,
  },

  {
    header: "Status*",
    accessorKey: "status",
    // size: 100,
  },
]);

export const IDENTICAL_SKU_COLUMNS = Object.freeze([
  {
    header: "SKU №",
    accessorKey: "skuId",
    size: 15,
  },
  {
    header: "Order",
    accessorKey: "article",
    size: 15,
  },
]);
