export const LOGIST_FACTORY_BLOCK_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Order number",
    accessorKey: "orderNumber",
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },

  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Readiness date",
    accessorKey: "readinessDate",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "country",
  },
  {
    header: "Prof №",
    accessorKey: "proformaNumber",
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
  },
  // {
  //   header: "Tr pool №",
  //   accessorKey: "trPoolNumber",
  // },
  {
    header: "Note",
    accessorKey: "note",
  },
]);
