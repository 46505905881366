import {useTranslation} from "react-i18next";

import ReactTable from "../../../../conteiners/ReactTable";

import useWarehouseDocsAndCargoPartService from "./useWarehouseDocsAndCargoPartService";

const WarehouseDocsAndCargoPart = ({setPdfFileArray}) => {
  const {t} = useTranslation();

  const {
    warehouseCargoTableColumns,
    warehouseDocumentsTableColumns,
    warehouseDocumentsTableData,
    warehouseCargoTableData,
    onWarehouseDocSelect,
    currentWarehouseDoc,
    currentPackSpace,
  } = useWarehouseDocsAndCargoPartService(setPdfFileArray);

  return (
    <div style={{width: "50%", paddingLeft: "1em"}}>
      <div className="mb">
        <div className="fdr mb">
          <div>3.</div>
          <div className="label underline">{t("Documents") + ":"}</div>
        </div>
        <ReactTable
          defaultData={warehouseDocumentsTableData}
          columns={warehouseDocumentsTableColumns}
          current={currentWarehouseDoc?.docId}
          onSelect={onWarehouseDocSelect}
          className={"project_table"}
          style={{height: "6em"}}
        />
      </div>
      <div>
        <div className="fdr mb aibl">
          <div>4.</div>
          <div className="label underline">{t("Cargo") + ":"}</div>
        </div>
        <ReactTable
          defaultData={warehouseCargoTableData}
          columns={warehouseCargoTableColumns}
          current={currentPackSpace?.spaceId}
          selectable={false}
          // onSelect={onWarehousePackingSpaceSelect}
          className={"project_table"}
          style={{height: "5.5em"}}
        />
      </div>
    </div>
  );
};

export default WarehouseDocsAndCargoPart;
