import {WAREHOUSES_STORE_NAME} from "./WarehousesSlice";

export const selectWarehouses = state => state[WAREHOUSES_STORE_NAME].warehouses;
export const selectWarehouseFilters = state => state[WAREHOUSES_STORE_NAME].warehousesFilters;
export const selectCurrentWarehouse = state => state[WAREHOUSES_STORE_NAME].currentWarehouse;
export const selectWarehouseTypes = state => state[WAREHOUSES_STORE_NAME].warehouseTypes;
// export const selectAddedWarehouseType = state => state[WAREHOUSES_STORE_NAME].addedWarehouseType;
// export const selectAddedWarehouseContact = state => state[WAREHOUSES_STORE_NAME].addedWarehouseContact;
// export const selectAddedWarehouseAccount = state => state[WAREHOUSES_STORE_NAME].addedWarehouseAccount;
// export const selectAddedWarehouseDoc = state => state[WAREHOUSES_STORE_NAME].addedWarehouseDoc;
// export const selectAddedWarehouseDocFile = state => state[WAREHOUSES_STORE_NAME].addedWarehouseDocFile;
export const selectCurrentWarehouseDocFile = state => state[WAREHOUSES_STORE_NAME].currentDocFile;
export const selectAreWarehousesLoading = state => state[WAREHOUSES_STORE_NAME].warehousesLoading;

export const selectWarehouseServices = state => state[WAREHOUSES_STORE_NAME].warehouseServices;
export const selectCurrentWarehouseService = state => state[WAREHOUSES_STORE_NAME].currentWarehouseService;
export const selectWarehouseServiceTypes = state => state[WAREHOUSES_STORE_NAME].warehouseServiceTypes;
export const selectWarehouseServiceTransactions = state => state[WAREHOUSES_STORE_NAME].transactions;
