import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import moment from "moment";
import Swal from "sweetalert2";

import {deleteReq, fetchData, fetchList} from "../../hook/axios.hook";
import {dateFormat} from "../../utilities/dateFormat";
import {getError} from "../../utilities/toasts";

const initialState = {
  projects: [],
  projectsIdList: [],
  currentProjectManager: null,
  currentProject: null,
  statusList: [],
  projectsLoadingStatus: "idle",
  docsLoading: false,
  editedProject: null,
  contracts: null,
  // annexes: [],
  einfo: [],
};

export const fetchProjects = createAsyncThunk("projects/fetchProjects", async () => {
  const responce = fetchList("/projects/getForCurrent", "get");

  return responce;
});

export const fetchProjectById = createAsyncThunk("projects/fetchProjectById", async projectIN => {
  const responce = fetchData(`/projects/get/${projectIN}`, "get");
  return responce;
});

export const fetchProjectByUserId = createAsyncThunk("projects/fetchProjectByUserId", async userId => {
  const responce = fetchData(`/projects/getForUser/${userId}`, "get");
  return responce;
});

export const addProject = createAsyncThunk("projects/addProject", async ({clientId, project}) => {
  const responce = fetchData(`/projects/add/${clientId}`, "post", project);
  return responce;
});

export const updateProject = createAsyncThunk("projects/updateProject", async ({projectId, data}) => {
  const responce = fetchData(`/projects/update/${projectId}`, "patch", data);
  return responce;
});

export const updateProjectStatus = createAsyncThunk("projects/updateProjectStatus", async ({projectId, statusId}) => {
  const responce = await fetchData(`/projects/update/${projectId} `, "patch", {
    statusId,
  });

  return responce;
});

export const getProjectStatuses = createAsyncThunk("projects/getStatusList", async () => {
  const responce = fetchData(`/references/projectsStatuses`, "get");
  // const responce = fetchData(`/references/forSku`, "get");

  return responce;
});

////////contract

export const addProjectContract = createAsyncThunk("projects/addProjectContract", async projectId => {
  const responce = fetchList(`/contracts/add/${projectId}`, "post");
  return responce;
});

export const addProjectContractFile = createAsyncThunk(
  "projects/addProjectContractFile",
  async ({contractId, formdata}) => {
    const responce = fetchData(`/contracts/docs/update/${contractId}`, "put", formdata);
    return responce;
  },
);

export const delProjectContractFile = createAsyncThunk("projects/delProjectContractFile", async ({contractId, url}) => {
  const responce = deleteReq(`/contracts/docs/delete/${contractId}`, {
    docUrl: url,
  });
  return responce;
});

export const updateProjectContract = createAsyncThunk("projects/updateProjectContract", async ({contractId, data}) => {
  const responce = fetchData(`/contracts/update/${contractId}`, "patch", data);
  return responce;
});

////////einfo
export const addEinfo = createAsyncThunk("projects/addEinfo", async ({projectId, data}) => {
  const responce = fetchData(`/einfo/add/${projectId}`, "post", data);
  return responce;
});
export const updateEinfo = createAsyncThunk("projects/updateEinfo", async ({einfoId, data}) => {
  const responce = fetchData(`/einfo/update/${einfoId}`, "patch", data);
  return responce;
});
export const addEinfoFile = createAsyncThunk("projects/addEinfoFile", async ({einfoId, formdata}) => {
  const responce = fetchData(`/einfo/docs/update/${einfoId}`, "put", formdata);
  return responce;
});
export const delEinfoFile = createAsyncThunk("projects/delEinfoFile", async ({einfoId, url}) => {
  const responce = deleteReq(`/einfo/docs/delete/${einfoId}`, {
    docUrl: url,
  });
  return responce;
});

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    annexesFetched: (state, action) => {
      // state.filteredAnnexes = action.payload
    },
    setEditedProject: (state, action) => {
      state.editedProject = action.payload;
    },
    deselectProject: (state, action) => {
      state.currentProject = null;
      state.contracts = [];
      // state.annexes = [];
      state.einfo = [];
    },
    setCurrentProjectManager: (state, action) => {
      state.currentProjectManager = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.pending, state => {
        state.projectsLoadingStatus = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectsLoadingStatus = "complete";
        state.projects = action.payload;
        // .map((project)=>{
        //     var proj ={};
        //     for(var key in project){
        //         proj[key] = project[key] || ''
        //     }
        //     return proj
        // });
        state.projectsIdList = action.payload.map(project => {
          return {number: project.projectNumber, id: project.projectId};
        });
      })
      .addCase(fetchProjects.rejected, state => {
        state.projectsLoadingStatus = "error";
      })

      .addCase(fetchProjectByUserId.pending, state => {
        state.projectsLoadingStatus = "loading";
      })
      .addCase(fetchProjectByUserId.fulfilled, (state, action) => {
        state.projectsLoadingStatus = "complete";
        state.projects = action.payload;
        state.projectsIdList = action.payload.map(project => {
          return {number: project.projectNumber, id: project.projectId};
        });
      })
      .addCase(fetchProjectByUserId.rejected, state => {
        state.projectsLoadingStatus = "error";
      })

      .addCase(fetchProjectById.pending, state => {
        state.projectsLoadingStatus = "loading";
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.projectsLoadingStatus = "complete";
        var proj = {};
        for (var key in action.payload.project) {
          proj[key] = action.payload.project[key] || "";
        }

        state.currentProject = {
          ...proj,
          userId: proj.projectManager.userId,
          userName: proj.projectManager.name,
          designerBonus: proj.designer?.bonus || "",
          designerId: proj.designer.designerId,
          designerName: proj.designer.name,
          orgName: proj.orgStructure.shortName,
          orgStructureId: proj.orgStructure.orgStructureId,
        };
        var myproj = state.projects.find(project => project.projectKey === proj.projectKey);
        if (!myproj) {
          state.projects.unshift(proj);
        }
        state.contracts = action.payload.project.contracts;

        // state.annexes = [].concat(...action.payload.project.cos.map(co => co.annexes));

        state.einfo = action.payload.project.einfo;
      })
      .addCase(addProject.fulfilled, (state, {payload}) => {
        state.projects.unshift(payload.project);
        // state.currentProject = payload.project;
      })
      .addCase(updateProject.fulfilled, (state, {payload}) => {
        const {project} = payload;
        state.projects = state.projects.map(item => {
          if (item.projectId === project.projectId) {
            return project;
          } else return item;
        });
      })
      .addCase(addProject.rejected, (state, {error}) => {
        getError(error);
      })
      .addCase(updateProjectStatus.fulfilled, (state, {payload, meta}) => {
        const updatedProject = payload.project;
        state.currentProject = {
          ...state.currentProject,
          ...updatedProject,
        };
      })
      .addCase(getProjectStatuses.fulfilled, (state, {payload}) => {
        state.statusList = payload;
      })
      ////contracts

      .addCase(addProjectContract.fulfilled, (state, {payload}) => {
        state.contracts.push({
          ...payload.contract,
          deadline: moment().format("YYYY-MM-DD"),
          // contractDeadline: dateFormat(payload.contract.contractDeadline),
        });
      })
      .addCase(updateProjectContract.fulfilled, (state, {payload}) => {
        const {contract, message} = payload;
        state.contracts = state.contracts.map(item => {
          if (item.contractId === contract.contractId) {
            return {
              ...contract,
              contractDeadline: contract.contractDeadline,
            };
          } else return item;
        });
        console.log(payload);
        state.projects = state.projects.map(project => {
          if (project.projectId === state.currentProject.projectId) {
            return {...project, deadline: payload.contract?.deadline};
          } else return project;
        });
      })
      .addCase(addProjectContractFile.pending, state => {
        state.docsLoading = true;
      })
      .addCase(addProjectContractFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.contracts = state.contracts.map(contract => {
          if (contract.contractId === meta.arg.contractId) {
            return {...contract, docsArray: payload.docsArray};
          } else {
            return contract;
          }
        });
        state.docsLoading = false;
      })
      .addCase(delProjectContractFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.contracts = state.contracts.map(contract => {
          if (contract.contractId === meta.arg.contractId) {
            return {...contract, docsArray: payload.docsArray};
          } else {
            return contract;
          }
        });
      })

      // Einfo
      .addCase(addEinfo.fulfilled, (state, {payload}) => {
        state.einfo.unshift({
          ...payload.einfo,
          dateFrom: payload.einfo.dateFrom,
        });
      })
      .addCase(updateEinfo.fulfilled, (state, {payload}) => {
        const {einfo, message} = payload;
        state.einfo = state.einfo.map(item => {
          if (item.einfoId === einfo.einfoId) {
            return {...einfo, dateFrom: einfo.dateFrom};
          } else return item;
        });
      })
      .addCase(addEinfoFile.pending, state => {
        state.docsLoading = true;
      })
      .addCase(addEinfoFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.einfo = state.einfo.map(ein => {
          if (ein.einfoId === meta.arg.einfoId) {
            return {...ein, docsArray: payload.docsArray};
          } else {
            return ein;
          }
        });
        state.docsLoading = false;
      })
      .addCase(delEinfoFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.einfo = state.einfo.map(einfo => {
          if (einfo.einfoId === meta.arg.einfoId) {
            return {...einfo, docsArray: payload.docsArray};
          } else {
            return einfo;
          }
        });
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = projectSlice;

export default reducer;
export const {selectProject, setEditedProject, deselectProject, annexesFetched, setCurrentProjectManager} = actions;

// export const filteredAnnexSelector = createSelector(
//   state => state.projects.annexes,
//   state => state.kp.currentKp,
//   (annexes, currentKp) => {
//     if (!currentKp) {
//       return annexes;
//     } else {
//       return annexes.filter(item => item.coId === currentKp.coId);
//     }
//   },
// );

export const getProjectContracts = state => state.projects.contracts;
// export const getProjectAnnexes = state => state.projects.annexes;
export const getProjectEinfo = state => state.projects.einfo;
export const getDocsLoading = state => state.projects.docsLoading;
export const getProjectStatus = state => state.projects.statusList;
export const getCurrentProjectManager = state => state.projects.currentProjectManager;
