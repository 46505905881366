import React, {useMemo} from "react";

function addPropToReactComponents(children) {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      if (typeof child.type === "function") {
        const props = {...child.props, readOnly: true};
        const newChild = React.cloneElement(child, props);
        const nestedChildren = addPropToReactComponents(child.props.children);
        if (nestedChildren?.length) {
          return React.cloneElement(newChild, {}, nestedChildren);
        }
        return newChild;
      }
      const nestedChildren = addPropToReactComponents(child.props.children);
      if (nestedChildren?.length) {
        return React.cloneElement(child, {}, nestedChildren);
      }
    }
    return child;
  });
}

const ReadOnlyFields = ({children}) => {
  const components = addPropToReactComponents(children);
  return components;
};

export const ReadOnlyFieldsToggled = ({readOnly, children}) => {
  const components = useMemo(() => {
    return addPropToReactComponents(children);
  }, []);

  if (!readOnly) {
    return children;
  }
  return components;
};

export default ReadOnlyFields;
