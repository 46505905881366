import {useEffect, useState} from "react";
import CurrencyInput from "react-currency-input-field";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

const MoneyReadInput = ({
  label,
  width = "67%",
  question = null,
  rawValue,
  autolabel,
  labelStyles,
  labelWrap,
  generalClassName,
  ...props
}) => {
  const [value, setValue] = useState("");

  const [className, setClassName] = useState("moneyInp");
  // const [rawValue, setRawValue] = useState(' ');

  useEffect(() => {
    const rv = rawValue === "" ? null : rawValue;
    setValue(rv ? Number.parseFloat(rv).toFixed(2) : "");
  }, [rawValue]);

  return (
    <div className={`project_input ${generalClassName} ${labelWrap ? "" : "labelWrap"}`} style={props.style}>
      {label && (
        <label htmlFor={props.name} style={autolabel ? {width: "auto"} : labelStyles}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className}>
          {/* <input {...props}  {...field} /> */}
          <CurrencyInput
            {...props}
            id="validation-example-2-field"
            // placeholder="$"
            allowDecimals={true}
            readOnly={props.readOnly}
            className={`form-control ${className}`}
            // onValueChange={validateValue}
            groupSeparator={"'"}
            decimalSeparator={"."}
            decimalsLimit={2}
            fixedDecimalLength={2}
            // prefix={'$'}
            step={10}
            autoComplete="off"
            value={value}
            intlConfig={{
              locale: "en-US",
              currency: "EUR",
            }}
          />
        </div>
        {/* {meta.touched && meta.error ?(<div className='error' >{meta.error}</div>): null} */}
      </div>

      {question ? <QuestionItem title={question} /> : null}
    </div>
  );
};

MoneyReadInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
  rawValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MoneyReadInput;
