import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import OneRowTable from "../../../conteiners/OneRowTable";
import DocumentsInfo from "../../common/DocumentsInfo/DocumentsInfo";
import ShipmentInfo from "../../common/ShipmentInfo/ShipmentInfo";
import {selectCurrentSkuRequest} from "../../redux/selectors";
import withLogistCheckList from "../CheckListsHOC";

const SPCheckLIst = () => {
  const curentSkuRequest = useSelector(selectCurrentSkuRequest);
  const {
    i18n: {language},
  } = useTranslation();

  const generateDocumentsTableData = () => {
    var docs = [];
    var counter = 1;
    curentSkuRequest?.stockPool?.docs?.map(pdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(pdoc?.createdAt) || " - ",
        docType: pdoc?.docType[language === "en" ? "nameEng" : "nameUkr"] || " - ",
        docNumber: pdoc?.docNumber ? "Prof № " + pdoc?.docNumber : " - ",
        createdBy: " - ",
        url: pdoc?.fileUrl || " - ",
      });
      counter++;
    });
    return docs;
  };

  const documentsTableData = generateDocumentsTableData();
  const spInfoTable = {
    "Places quantity": curentSkuRequest?.stockPool?.packingSpaces.length,
    "Total weight": curentSkuRequest?.stockPool?.packingSpaces.reduce((accumulator, currentValue) => {
      const number = Number(currentValue?.weight);
      if (!isNaN(number)) {
        return Number(accumulator) + number;
      }
      return Number(accumulator);
    }, 0),
    "Total volume":
      curentSkuRequest?.stockPool?.packingSpaces.reduce((accumulator, currentValue) => {
        const number = Number(currentValue?.volume);
        if (!isNaN(number)) {
          return Number(accumulator) + number;
        }
        return Number(accumulator);
      }, 0) || "-",
  };
  return (
    <>
      <OneRowTable data={spInfoTable} />
      <DocumentsInfo documentsTableData={documentsTableData} tableHeight={"11.5em"} />
      <ShipmentInfo height="13.8em" packingSpaces={curentSkuRequest?.stockPool?.packingSpaces} />
    </>
  );
};

export default withLogistCheckList(SPCheckLIst);
