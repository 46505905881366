import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

import AccessBoundary from "../AccessBoundary/AccessBoundary";

import "./verticalNav.scss";
const AdminNav = () => {
  const {t} = useTranslation();
  return (
    <div className={"vertical__nav_container"}>
      <div className="vertical__nav">
        <p>{t("Go to")}</p>
        <VerticalLink to="/ba3a/admin/addPerson" name={t("Create person")} />
        <AccessBoundary>
          <VerticalLink to="/ba3a/admin/registr" name={t("User registration")} />
        </AccessBoundary>
        <VerticalLink to="/ba3a/admin/addClient" name={t("Client registration")} />
      </div>
    </div>
  );
};

const VerticalLink = props => {
  return (
    <NavLink to={props.to}>
      {" "}
      <div className="verticalLink__item">
        {props.name} <span>&#10095;</span>{" "}
      </div>
    </NavLink>
  );
};

export default AdminNav;
