import {useEffect} from "react";
import PropTypes from "prop-types";

const Modal = ({active, setActive, children, height, width}) => {
  useEffect(() => {
    var t = document.querySelector(".modal");
    var listener = function (e) {
      if (e.key === "Escape") {
        setActive(false);
      }
    };
    t.addEventListener("keypress", listener);

    return t.removeEventListener("keypress", listener);
  }, [active]);

  const handleEscapeKey = event => {
    if (event.key === "Escape") {
      setActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div
      className={active ? " windoww modal active" : "modal"}
      onClick={() => setActive(false)}
      // onKeyPress={(e)=>{console.log('esc')
      //     if(e.key === 'Escape'){

      //         setActive(false)
      //     }
      // }}
    >
      <div
        className={active ? "modal__content active" : "modal__content"}
        onClick={e => e.stopPropagation()}
        style={height && width && {height: height, width: width}}
      >
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  active: PropTypes.bool,
  setActive: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Modal;
