import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {makeRegisterRqstsTableData, makeRegisterSPTableData} from "../../../common/functions";
import {LOGIST_WINDOWS} from "../../../common/ShipmentInfo/constants";
import useStockPoolTables from "../../../common/useStockPoolTables";
import {fetchStockPoolBySkuId} from "../../../redux/operations";
import {
  selectFactoryCargoRequests,
  selectFactoryCargoStockPools,
  selectWarehouseCargoRequests,
  selectWarehouseCargoStockPools,
} from "../../../redux/selectors";
import {TP_REQUEST_TABLE_FACTORY_CREATING_MODAL, TP_TABLE_FACTORY_CREATING_MODAL} from "../constants";

const InTransportPoolTables = ({onCargoSelect, selectedFactory, selectedWarehouse, setModalOpen}) => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const [requestsData, setRequestsData] = useState([]);

  const factoryId = selectedFactory?.value;
  const warehouseId = selectedWarehouse?.value;

  const creatingFetchData = selectedFactory
    ? {
        filterProps: {factoryId},
        window: LOGIST_WINDOWS.FACTORY_CARGO,
      }
    : {
        filterProps: {warehouseId},
        window: LOGIST_WINDOWS.WAREHOUSE_CARGO,
      };

  const {spRequests, onStockPoolSelect, currentSPId, setCurrentSpId} = useStockPoolTables(creatingFetchData);

  const spcolumns = useColumns(TP_TABLE_FACTORY_CREATING_MODAL);

  const stockPoolsFactoryCargo = useSelector(selectFactoryCargoStockPools);
  const stockPoolsWarehouseCargo = useSelector(selectWarehouseCargoStockPools);

  const chosedSpData = selectedFactory ? stockPoolsFactoryCargo : stockPoolsWarehouseCargo;

  const spdata = makeRegisterSPTableData(chosedSpData, i18n.language);

  const reqcolumns = useColumns(TP_REQUEST_TABLE_FACTORY_CREATING_MODAL);

  const requestsFactoryCargo = useSelector(selectFactoryCargoRequests);
  const requestsWarehouseCargo = useSelector(selectWarehouseCargoRequests);

  useEffect(() => {
    setCurrentSpId(null);
    const chosedReqData = selectedFactory ? requestsFactoryCargo : requestsWarehouseCargo;
    setRequestsData(chosedReqData);
  }, [requestsFactoryCargo, requestsWarehouseCargo]);

  useEffect(() => {
    if (spRequests?.lenght !== 0) setRequestsData(spRequests);
    else {
      const chosedReqData = selectedFactory ? requestsFactoryCargo : requestsWarehouseCargo;
      setRequestsData(chosedReqData);
    }
  }, [spRequests]);

  // const requests = chosedReqData || spRequests;
  const reqdata = makeRegisterRqstsTableData(requestsData);

  const onWarehouseRqstsTableRowDoubleClick = skuRequest => {
    dispatch(fetchStockPoolBySkuId(skuRequest.id));
    setModalOpen(true);
  };

  return (
    <div className="fdr gap2x mb">
      <ReactTable
        className="tableTac"
        defaultData={spdata}
        columns={spcolumns}
        onSelect={id => {
          onCargoSelect(id);
          onStockPoolSelect(id);
        }}
        selectable={true}
        current={currentSPId}
        style={{width: "35%", height: "15em"}}
      />
      <ReactTable
        className="tableTac"
        defaultData={reqdata}
        columns={reqcolumns}
        selectable={false}
        onRowDoubleClick={onWarehouseRqstsTableRowDoubleClick}
        style={{width: "100%", height: "15em", overflow: "auto"}}
      />
    </div>
  );
};

export default InTransportPoolTables;
