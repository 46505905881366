import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import FilterButton from "../../../../../../utilities/Buttons/FilterBtn/FilterButton";
import {TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

import useWarehouseSplitCreateModalFilters from "./useWarehouseSplitCreateModalFilters";

const WarehouseSplitCreateModalFilters = ({disabled = false, setSelectedFilters, selectedWarehouse}) => {
  const {t} = useTranslation();
  const {onFilter, delFilters, areFiltersDefined, countryOptions, initialValues, coManagersOptions} =
    useWarehouseSplitCreateModalFilters(setSelectedFilters, selectedWarehouse);

  return (
    <>
      <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={true} enableReinitialize>
        {({values, setValues}) => (
          <Form className="w100 fdr aic mt">
            <FilterButton
              onFilter={() => onFilter(values)}
              delFilter={() => delFilters(setValues)}
              questionTitle={t("Filter")}
              areFiltersDefined={areFiltersDefined}
              disabled={disabled}
            />
            <div className=" w100 fdr jcsa gap">
              <Select label={t("Product country") + ":"} options={countryOptions} name="countryId" autolabel />
              <TextInput label={t("Order № ") + ":"} name="orderNumber" width="12em" autolabel />
              <Select
                label={t("Manager CO") + ":"}
                options={coManagersOptions}
                name="managerCoId"
                width="12em"
                autolabel
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WarehouseSplitCreateModalFilters;
