export const CROP_IMG_CATEGORIES = Object.freeze({
  DEFAULT: "DEFAULT",
  VED_PRODUCT_TYPE: "VED_PRODUCT_TYPE",
  VED_PRODUCT_COLOR_AND_DECORATION: "VED_PRODUCT_COLOR_AND_DECORATION",
  ADD_SKU_TO_KPF: "ADD_SKU_TO_KPF",
});

export const CROP_IMG_SIZES = Object.freeze({
  [CROP_IMG_CATEGORIES.DEFAULT]: {width: 250, height: 250},
  [CROP_IMG_CATEGORIES.VED_PRODUCT_TYPE]: {width: 160, height: 135},
  [CROP_IMG_CATEGORIES.VED_PRODUCT_COLOR_AND_DECORATION]: {width: 100, height: 65},
  [CROP_IMG_CATEGORIES.ADD_SKU_TO_KPF]: {width: 158, height: 133},
});
