import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectReferences} from "../../../../redux/selectors/FactorySelectors";

const useContactTypeByIdService = () => {
  const {i18n} = useTranslation();

  const references = useSelector(selectReferences);

  return (
    references?.contactTypes.reduce(
      (acc, contactType) => ({
        ...acc,
        [contactType.contactTypeId]: i18n === "eng" ? contactType.engName : contactType.ukrName,
      }),
      {},
    ) ?? {}
  );
};

export default useContactTypeByIdService;
