import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import moment from "moment";

import {apiUrl} from "../../../../../api/baseURL";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import DateStatusCircle from "../../../../../utilities/DateStatusCircle";
import QuestionItem from "../../../../../utilities/QuestionItem";
import {findRequestDateType, REQUEST_DATES_IDS} from "../../../../../utilities/requestDatesIdsService";
import {getError, getInfo} from "../../../../../utilities/toasts";
import ToolTip from "../../../../../utilities/ToolTip";
import ReactTable from "../../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";
import SingleFilePicker from "../../../../Inputs/SingleFilePicker";
import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";
import useCountryIdOptionsService from "../../../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";
import {
  approveProform,
  declineProform,
  fetchForRequestById,
  sendProformForControl,
  sendProformToFactory,
} from "../../../Proform/redux/operations/ProformOperations";
import {fetchMixedRequests, getRequest, getRequestInfo} from "../../../RequestsSlice";
import {REQ_WINDOW_TYPES} from "../../utils/functions/requestsFiltersManager";

import {dateFormat} from "./../../../../../utilities/dateFormat";
import {
  CONTROL_DETAILS_TABLE_COLUMNS,
  CONTROL_TABLE_COLUMNS,
  HISTORY_PF_TABLE_COLUMNS,
  STATUS_CONTROL_TABLE_COLUMNS,
} from "./utils/constants";
import getApprovalDates from "./utils/helpers/getApprovalDates";
import useControlDetailsPfTableData from "./utils/hooks/getControlDetailsPfTableDataService";
import useControlPfTableData from "./utils/hooks/getControlPfTableDataService";
import useForRequestService from "./utils/hooks/getForRequestService";
import useHistoryPfTableData from "./utils/hooks/getHistoryPfTableDataService";
import usePfOperationsModalFilePickerFunctions from "./utils/hooks/getPfOperationsModalFilePickerService";
import getStatusControlTableData from "./utils/hooks/getStatusControlTableData";

import styles from "./PfOperationsModal.module.scss";

const PfOperationsModal = ({setModalActive}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const navigate = useNavigate();

  const [currentPf, setCurrentPf] = useState(null);
  const [currentApprovalId, setCurrentApprovalId] = useState(null);
  const [currentPdf, setCurrPdf] = useState([]);

  const fetchProforma = () => {
    setCurrentApprovalId(null);
    setCurrentPf(null);
    dispatch(fetchForRequestById(currentRequest.requestId));
  };

  useEffect(() => {
    if (!currentPf) setCurrentApprovalId(null);
    // dispatch(getRequest(currentRequest.requestId));
  }, [currentPf]);

  //REDUX STATE
  const currentRequest = useSelector(getRequestInfo);

  //DATA
  const forRequest = useForRequestService();

  // const forRequestProforma = forRequest?.request?.proforms[0];

  const declinedProforms = forRequest?.declinedProforms ?? [];

  const statusControlTableData = getStatusControlTableData(forRequest?.request?.proforms || []);

  const translatedControlTableData = statusControlTableData.map(item => {
    return {...item, statControl: t(item?.statControl)};
  });

  const currentApproval = currentPf?.approvals?.find(apr => apr.approvalId === currentApprovalId);

  const currentApprovalReasons = useMemo(() => {
    if (!currentApprovalId) return [];
    return currentPf?.approvals?.find(apr => apr.approvalId === currentApprovalId)?.disapprovalReasons || [];
  }, [currentApprovalId, currentPf]);

  const requestDates = forRequest?.request.requestDates ?? [];

  const initReceiptDates = findRequestDateType(requestDates, REQUEST_DATES_IDS.GETTING_PROF_ID);
  const initControlPfDates = findRequestDateType(requestDates, REQUEST_DATES_IDS.PROF_CONTROL_ID);

  // const {controlPlannedPfDate, controlActualPfDate} = getApprovalDates(currentPf, currentApprovalId);

  //controlPfTable data
  const controlPfTableColumns = useColumns(CONTROL_TABLE_COLUMNS);
  const controlPfTableData = useControlPfTableData(currentPf) ?? [];

  const translatedControlPfTableData = controlPfTableData.map(item => {
    return {...item, accepted: t(item?.accepted)};
  });

  //controlDetailsPfTable data
  const controlDetailsPfTableColumns = useColumns(CONTROL_DETAILS_TABLE_COLUMNS);
  const statusControlPfTableColumns = useColumns(STATUS_CONTROL_TABLE_COLUMNS);

  const controlDetailsPfTableData = useControlDetailsPfTableData(currentApprovalReasons);

  //historyPfTable data
  const historyPfTableColumns = useColumns(HISTORY_PF_TABLE_COLUMNS);
  const historyPfTableData = useHistoryPfTableData(declinedProforms) ?? [];

  //File functions
  const {onFileAdding} = usePfOperationsModalFilePickerFunctions();

  //EVENT HANDLERS
  const onPfOperationsFormSubmit = () => {};
  //add proforma to control
  const onAddPfToControlBtnClick = noteForManagerKP => {
    dispatch(sendProformForControl({requestId: forRequest.request.requestId, data: {note: noteForManagerKP}})).then(
      res => {
        if (res.meta.requestStatus === "fulfilled") {
          fetchProforma();
        }
      },
    );
  };

  //PF not agreed btn
  const onPfNotAgreedBtnClick = () => {
    dispatch(declineProform(currentPf?.proformaId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentPf(res.payload);
        getInfo(t("Pf not approved"));
        dispatch(fetchForRequestById(currentPf.requestId));
      }
    });
  };

  //PF agreed btn
  const onPfAgreedBtnClick = () => {
    dispatch(approveProform(currentPf?.proformaId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentPf(res.payload);
        getInfo(t("Pf approved"));
      }
    });
  };

  // const declineBtnDisabled = true;

  const downloadFile = fileUrls => {
    const promises = fileUrls.map((fileUrl, i) => {
      const url = `${apiUrl}/${fileUrl}`;
      const filename = forRequest?.request.proforms[i]?.proformaNumber + "_pf";

      return fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
    });

    Promise.all(promises)
      .then(() => {
        getInfo(t("all f"));
        setTimeout(() => {
          setModalActive(false);
        }, 1000);
      })
      .catch(error => {
        getError("Error downloading files:", error);
      });
  };

  const onSendToFactory = () => {
    dispatch(sendProformToFactory(forRequest.request.requestId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        downloadFile(resp.payload);
        getInfo(t("prof send"));
        dispatch(fetchMixedRequests({window: REQ_WINDOW_TYPES.INPROGRESS}));
        setTimeout(() => {
          setModalActive(false);
        }, 1000);
      }
    });
  };

  const onAprovSelect = id => {
    if (!id || currentApprovalId === id) {
      setCurrentApprovalId(null);
      return;
    }
    setCurrentApprovalId(id);
  };

  //initiial values
  const initialValues = useMemo(() => {
    return {
      receiptPlannedPfDate: dateFormat(initReceiptDates?.plannedDate) ?? "",
      receiptActualPfDate: dateFormat(initReceiptDates?.actualDate) ?? "",
      pfNumber: currentPf?.proformaNumber ?? "",
      noteForManagerKP: "",
      controlPlannedPfDate: dateFormat(initControlPfDates?.plannedDate) || "",
      controlActualPfDate: dateFormat(initControlPfDates?.actualDate) || "",
      countryId: currentPf?.country?.countryId ?? "",

      managerFeaNote: currentApproval?.note ?? t("No note from manager FEA"),
    };
  }, [forRequest, currentPf, currentApprovalId]);

  const onPfSelect = id => {
    if (!id || (currentPf && id === currentPf.proformaId)) {
      setCurrentPf(null);
      setCurrPdf(null);
      return;
    }
    var prof = forRequest?.request?.proforms.find(pf => pf.proformaId == id);
    setCurrentPf(prof);
    setCurrPdf(prof?.pdfFileUrl?.length > 0 ? [prof.pdfFileUrl] : []);
  };

  const isApproved = useMemo(() => {
    return forRequest?.request?.proforms?.every(pf => pf.isApproved === true) || false;
  }, [forRequest]);

  const countryOptions = useCountryIdOptionsService();

  const gettingProfDate = findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.GETTING_PROF_ID);
  const profControlDate = findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.PROF_CONTROL_ID);

  const addProfToControlDisable = forRequest?.request?.proforms.find(item => item.isInControl === null);

  const approvalBtnDisabled = !currentPf || !!currentPf?.approvals.find(item => !item.isDeclined && !item.isApproved);

  const agreedBtnDisabled = !!currentPf?.approvals?.find(item => item.isDeclined);

  const declinedBtnDisabled = !!currentPf?.approvals?.every(item => item.isApproved);

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onPfOperationsFormSubmit}>
      {({values, setFieldValue}) => (
        <Form className={`${styles.modalContainer} autolabel`}>
          <div className={styles.leftColumn}>
            <section className={styles.acquiringSection}>
              <h2 className={styles.sectionHeader}>
                1. <span className={styles.underLined}>{t("Pf Acquiring") + ":"}</span>
              </h2>
              <div className={styles.acquiringSectionFirstRow}>
                <div className={styles.sectionFlexStartRow}>
                  <DateStatusCircle
                    plannedDate={gettingProfDate?.plannedDate}
                    createdAtDate={gettingProfDate?.createdAt}
                    actualDate={gettingProfDate?.actualDate}
                  />

                  <TextInput name="receiptPlannedPfDate" label={t("Date: Pf receipt (p):")} readOnly />
                </div>

                <TextInput name="receiptActualPfDate" question="upld pf" label={t("Date: Pf receipt (f):")} readOnly />
              </div>

              <div className={styles.acquiringSectionSecondRow}>
                <TextInput
                  label={t("Pf №") + ":"}
                  width="11em"
                  name="pfNumber"
                  question="num pf"
                  disabled={currentPf?.proformaNumber?.length > 0}
                />
                <Select
                  label={t("Prof Country") + ":"}
                  name="countryId"
                  options={countryOptions ?? []}
                  width="12em"
                  autolabel
                  generalClassName="f_block_item"
                />
                <ToolTip title={t("scan upload")}>
                  <SingleFilePicker
                    disabled={currentPf || values.pfNumber?.length === 0 || values.countryId === null}
                    addFunc={file => onFileAdding(file, values, setCurrentPf)}
                    accept={".pdf"}
                  />
                </ToolTip>
              </div>
              <ReactTable
                // className={styles.controlPFTableWrapper}
                // tableClassName={styles.controlDetailsPFTable}
                style={{height: "7em"}}
                columns={statusControlPfTableColumns}
                defaultData={translatedControlTableData}
                onSelect={onPfSelect}
                current={currentPf?.proformaId || null}
              />
              <TextAreaInput
                name="noteForManagerKP"
                className={styles.noteForManagerKP}
                width="101%"
                height="50px"
                placeholder={t("Enter your note for manager CO")}
              />
              <FunctionButton
                type="button"
                className={styles.addPfToControlBtn}
                name="Save and Add Pf to control"
                onClick={() => onAddPfToControlBtnClick(values.noteForManagerKP)}
                autoWidth
                disabled={!addProfToControlDisable}
              />
            </section>
            <section className={styles.controlSection}>
              <h2 className={styles.sectionHeader}>
                2. <span className={styles.underLined}>{t("Pf Control") + ":"}</span>
              </h2>
              <div className={styles.sectionRow}>
                <div className={styles.sectionFlexStartRow}>
                  {/* <DateStatusCircle
                    plannedDate={values.controlPlannedPfDate}
                    createdAtDate={currentRequest?.requestDates[4]?.createdAt}
                    actualDate={values.controlActualPfDate}
                  /> */}
                  <DateStatusCircle
                    plannedDate={profControlDate?.plannedDate}
                    createdAtDate={profControlDate?.createdAt}
                    actualDate={profControlDate?.actualDate}
                  />
                  <TextInput name="controlPlannedPfDate" label={t("Date: Pf control (p):")} readOnly />
                </div>

                <TextInput name="controlActualPfDate" question="approval" label={t("Date: Pf control (f):")} readOnly />
              </div>
              <h3 className={styles.sectionHeader}>
                <QuestionItem title="info unapproved" />
                {t("Pf approval") + ":"}
              </h3>
              <ReactTable
                className={styles.controlPFTableWrapper}
                tableClassName={styles.controlPFTable}
                columns={controlPfTableColumns}
                defaultData={translatedControlPfTableData}
                current={currentApprovalId}
                onSelect={onAprovSelect}
              />
              <ReactTable
                className={styles.controlPFTableWrapper}
                tableClassName={styles.controlDetailsPFTable}
                columns={controlDetailsPfTableColumns}
                defaultData={controlDetailsPfTableData}
                current={null}
              />
              <div className={styles.sectionRow}>
                <FunctionButton
                  // name="Pf not agreed"
                  name={currentPf?.isDeclined ? "Prof already declined" : "Pf not agreed"}
                  width="12em"
                  onClick={onPfNotAgreedBtnClick}
                  disabled={declinedBtnDisabled || approvalBtnDisabled}
                />
                <FunctionButton
                  name={currentPf?.isApproved ? "Prof already approved" : "Pf agreed"}
                  // width="12em"
                  autoWidth
                  disabled={agreedBtnDisabled || approvalBtnDisabled || currentPf?.isApproved}
                  onClick={onPfAgreedBtnClick}
                />
              </div>
            </section>
            <section className={styles.historySection}>
              <h2 className={styles.sectionHeader}>
                3. <span className={styles.underLined}>{t("History of Pf NOT consent") + ":"}</span>
              </h2>
              <ReactTable
                className={styles.historyTableWrapper}
                tableClassName={styles.historyTable}
                columns={historyPfTableColumns}
                defaultData={historyPfTableData}
                selectable={false}
                // current={null}
              />
            </section>
          </div>
          <div className={styles.rightColumn}>
            <TextAreaInput className={styles.noteArea} name="managerFeaNote" width="100%" height="50px" disabled />
            {/* <div className={styles.pdfWrapper}> */}
            <div className="kpPreview__box" style={{height: "100%"}}>
              {currentPf && <NewPdfViewer pfShow={true} fileArray={currentPdf} />}
            </div>
            <div className={styles.sectionRow}>
              <FunctionButton
                name="open pf wind"
                autoWidth
                onClick={() => {
                  navigate("/ba3a/fea/receiptAndVerific");
                }}
                disabled={currentRequest.statusId !== 6 || !currentPf}
              />
              <FunctionButton
                className={styles.createPDFBtn}
                type="submit"
                tooltipMessage="select folder"
                name="Create PDF to Factory"
                width={"15em"}
                onClick={onSendToFactory}
                disabled={!isApproved}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PfOperationsModal;
