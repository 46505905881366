import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import TableWithSelect from "../../conteiners/TableWithSelect";

import "./accWithFilter.scss";

const AccFullList = () => {
  const {t} = useTranslation();

  const accounts = [],
    currentAcc = null;

  function transformForTable(project) {
    const date1 = project.creationDate ? new Date(project.creationDate).toLocaleDateString() : "";
    const date2 = project.finalDate ? new Date(project.finalDate).toLocaleDateString() : "";
    return {
      id: project.projectKey,
      // designerId : project.designerId || '',
      designerName: project.designerName || "",
      projectAdress: project.projectAdress || "",
      creationDate: date1,
      finalDate: date2,
      status: project.projectStatus || "",
    };
  }

  const ths = useMemo(() => {
    return {
      [t("Is")]: 2,
      [t("Account №")]: 10,
      [t("Type*")]: 5,
      [t("Incoming balance Dt")]: 10,
      [t("Incoming balance Kt")]: 10,
      [t("Dt")]: 5,
      [t("Kt")]: 5,
      [t("Outgoing balance Dt")]: 10,
      [t("Outgoing balance Kt")]: 10,
      [t("Last oper. date")]: 6,
      [t("Nomenclature")]: 6,
      [t("Opening date")]: 6,
      [t("Account office")]: 6,
      [t("Status")]: 2,
    };
  }, []);

  // const onSelect = (id)=>{
  //   dispatch(fetchProjectById(id))
  // }

  return (
    <div className="project_list" style={{height: "100%"}}>
      <TableWithSelect
        thsName={ths}
        items={accounts.map(transformForTable)}
        current={currentAcc?.accKey}
        onSelect={() => {}}
        loadingStatus={"idle"}
        // formatStatus={formatStatus}
      />
    </div>
  );
};

export default AccFullList;
