import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {getError, selectFacsimile} from "../../../../../slices/UserSlice";
import {BUTTON_TYPES} from "../../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {STATUS_COLORS} from "../../../../../utilities/colorConstants";
import {makeSwalShowPassword} from "../../../../../utilities/makeSwalShowPassword/makeSwalShowPassword";
import {getSuccess} from "../../../../../utilities/toasts";
import {
  addProformApproval,
  declineProformApproval,
  fetchForCurrentApproval,
} from "../../../../Requests/Proform/redux/operations/ProformOperations";
import {selectCurrentApproval} from "../../../../Requests/Proform/redux/selectors/ProformSelectors";
import {setCurrentApproval} from "../../../../Requests/Proform/redux/slices/ProformSlice";

import styles from "../KpPfControl.module.scss";

export const PF_CONTROL_BTN_TYPES = Object.freeze({APPROVE: "Approve Prof", DISAPPROVE: "Decline Prof"});

const FPfControlBtn = ({type, disabled = false, approvalId, note = ""}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const facsimile = useSelector(selectFacsimile);
  const currentApproval = useSelector(selectCurrentApproval);

  const btnClassName = type === PF_CONTROL_BTN_TYPES.APPROVE ? styles.approveBtn : styles.disApproveBtn;

  const buttonChangeName = () => {
    switch (true) {
      case !currentApproval:
        return "select to approve";
      case !currentApproval?.isApproved && !currentApproval?.isDeclined:
        return type;
      case currentApproval?.isApproved:
        return "Already approved";
      case currentApproval?.isDeclined:
        return "Already declined";
      default:
        return type;
    }
  };

  function afterDispatch(resp, type) {
    let currentSetApproved = {};

    switch (resp.meta.requestStatus) {
      case "fulfilled":
        getSuccess(type === PF_CONTROL_BTN_TYPES.APPROVE ? t("Pf agreed") : t("Pf not agreed"));

        setTimeout(() => {
          dispatch(fetchForCurrentApproval());
        }, 500);

        currentSetApproved = {
          ...currentApproval,
          isApproved: type === PF_CONTROL_BTN_TYPES.APPROVE ? true : false,
          isDeclined: type === PF_CONTROL_BTN_TYPES.APPROVE ? false : true,
        };

        dispatch(setCurrentApproval(currentSetApproved));
        break;
    }
  }

  const onPfControlBtnClick = () => {
    if (!approvalId) return;

    if (!(currentApproval?.managerCo?.facsimile || facsimile)) {
      toast.error(t("You need to add your facsimile first!"));
      return;
    }

    Swal.fire({
      title: t("Submit your facsimile password"),
      ...makeSwalShowPassword(),
      showCancelButton: true,
      confirmButtonText: t("Submit"),
      cancelButtonText: t("Cancel"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const password = document.getElementById("swal-password-input").value;
        type === PF_CONTROL_BTN_TYPES.APPROVE
          ? dispatch(addProformApproval({approvalId, data: {password}})).then(resp => afterDispatch(resp, type))
          : dispatch(declineProformApproval({approvalId, data: {note}})).then(resp => afterDispatch(resp, type));
      },

      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <FunctionButton
      className={btnClassName}
      type={BUTTON_TYPES.BUTTON}
      name={buttonChangeName()}
      width={"11em"}
      disabled={disabled}
      onClick={onPfControlBtnClick}
    />
  );
};

FPfControlBtn.propTypes = {
  type: PropTypes.oneOf(Object.values(PF_CONTROL_BTN_TYPES)),
  disabled: PropTypes.bool,
  approvalId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  note: PropTypes.string,
};

export const PfControlBtn = React.memo(FPfControlBtn);
