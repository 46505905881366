import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import useList from "../../../../../hook/list.hook";
import {getPersons} from "../../../../../selectors/personSelectors";
import {fetchUsers} from "../../../../../slices/PersonSlice";

export const getPersonsList = createSelector([getPersons], prsons =>
  prsons?.map(item => {
    return {
      value: item.fullName,
      personaId: item.personaId,
      note: item.note,
      lastName: item.lastName,
    };
  }),
);

const UsersList = () => {
  const {t} = useTranslation();

  const users = useSelector(state => state.person.users);

  const persons = useSelector(getPersonsList);

  const dispatch = useDispatch();

  const {formThs, formTds} = useList();

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsers());
    }
  }, []);

  const ths = useMemo(() => {
    return formThs({
      ID: 6,
      [t("User")]: 10,
      [t("Name")]: 35,
      [t("Login")]: 20,
      [t("Position")]: 15,
      [t("Notes")]: 35,
    });
  }, []);

  function formTableContent(items) {
    if (items) {
      return items.map((item, i) => {
        const tds = formTds(item, 55);
        return <tr key={i}>{tds}</tr>;
      });
    }
  }
  const transformForTable = item => {
    const noteMatch = persons.find(subj => subj.personaId === item.persona.personaId);

    return {
      id: item.persona.personaId,
      emplId: item.userId,
      name: item.name,
      login: item.login,
      position: item?.position?.fullName || "",
      note: noteMatch?.note || "",
    };
  };
  const content = formTableContent(users.map(transformForTable));
  return (
    <div className="project_list" style={{height: "100px", width: "700px", position: "relative"}}>
      <table className="project_table" style={{height: "100px", width: "700px", position: "relative"}}>
        <thead style={{position: "sticky", left: "0", top: "0"}}>
          <tr>{ths}</tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
    </div>
  );
};

export default UsersList;
