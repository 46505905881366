import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {deselectCurrentRequest} from "../../Requests/RequestsSlice";
import {fetchStockPoolBySkuId} from "../redux/operations";
import {
  selectIsLogistLoading,
  selectSleepingForLogisticsRequests,
  selectSleepingInSpForLogisticsRequests,
} from "../redux/selectors";

import {LOWER_TABLE_COLUMNS, UPPER_TABLE_COLUMNS} from "./constants";

const useSleepingRequestsService = () => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const [isModalActive, setModalActive] = useState(false);
  // const [modalType, setModalType] = useState(SLEEPING_REQ_MODAL_TYPES.request);

  const sleepingUpperTableRowData = useSelector(selectSleepingForLogisticsRequests);
  const sleepingLowerTableRowData = useSelector(selectSleepingInSpForLogisticsRequests);
  const isLogistLoading = useSelector(selectIsLogistLoading);

  useEffect(() => {
    dispatch(deselectCurrentRequest());
  }, []);

  const uppperTableColumns = useColumns(UPPER_TABLE_COLUMNS);
  const upperTableData =
    sleepingUpperTableRowData?.map(({skuRequestId, order, request, stockPool}, index) => {
      const invoiceNumber =
        stockPool?.docs
          ?.filter(doc => doc?.docTypeId === 2)
          .map(doc => doc.docNumber)
          .join(" ") ?? " - ";
      const packingListNumber =
        stockPool?.docs
          ?.filter(doc => doc?.docTypeId === 1)
          .map(doc => doc.docNumber)
          .join(" ") ?? " - ";

      return {
        id: skuRequestId,
        number: index + 1,
        requestStatus: (i18n === "en" ? request?.status?.engName : request?.status?.ukrName) ?? " - ",
        requestNumber: request?.requestNumber ?? " - ",
        managerCo: order?.co?.managerCo?.name ?? " - ",
        managerFea: request?.managerFea?.name ?? " - ",
        factory: request?.brand?.name ?? " - ",
        requestSum: request?.requestSum ?? " - ",
        requestDate: dateFormat((request?.requestDates ?? [])[0]?.actualDate) ?? " - ",
        bringTo: dateFormat(order?.co?.bringTo),
        country: order?.co?.project?.country?.shortName ?? " - ",
        proformaNumber: (request?.proforms ?? [])[0]?.proformaNumber ?? " - ",
        invoiceNumber,
        packingListNumber,
        trPoolNumber: stockPool?.cargo?.cargoNumber ?? " - ",
        note: request?.note ?? " - ",
      };
    }) ?? [];
  const upperTableCurrent = null;
  const isUpperTableLoading = isLogistLoading;

  const lowerTableColumns = useColumns(LOWER_TABLE_COLUMNS);
  const lowerTableData = useMemo(() => {
    return (
      sleepingLowerTableRowData?.map(({skuRequestId, order, request, stockPool}, index) => {
        const invoiceNumber =
          stockPool?.docs
            ?.filter(doc => doc?.docTypeId === 2)
            .map(doc => doc.docNumber)
            .join(" ") ?? " - ";
        const packingListNumber =
          stockPool?.docs
            ?.filter(doc => doc?.docTypeId === 1)
            .map(doc => doc.docNumber)
            .join(" ") ?? " - ";

        return {
          id: skuRequestId,
          number: index + 1,
          requestStatus: (i18n === "en" ? request?.status?.engName : request?.status?.ukrName) ?? " - ",
          stockPoolNumber: stockPool?.stockPoolNumber ?? " - ",
          stockPoolId: stockPool?.stockPoolId || null,
          requestNumber: request?.requestNumber ?? " - ",
          managerCo: order?.co?.managerCo?.name ?? " - ",
          managerFea: request?.managerFea?.name ?? " - ",
          factory: request?.brand?.name ?? " - ",
          requestSum: request?.requestSum ?? " - ",
          requestDate: dateFormat((request?.requestDates ?? [])[0]?.actualDate) ?? " - ",
          bringTo: dateFormat(order?.co?.bringTo),
          country: order?.co?.project?.country?.shortName ?? " - ",
          proformaNumber: (request?.proforms ?? [])[0]?.proformaNumber ?? " - ",
          invoiceNumber,
          packingListNumber,
          trPoolNumber: stockPool?.cargo?.cargoNumber ?? " - ",
          note: request?.note ?? " - ",
        };
      }) ?? []
    );
  }, [sleepingLowerTableRowData?.length]);

  const lowerTableCurrent = null;
  const isLowerTableLoading = isLogistLoading;

  function onUpperTableRowSelect() {}
  function onUpperTableRowDoubleClick(item) {
    // dispatch(getRequest(request.id));
    dispatch(fetchStockPoolBySkuId(item.id));
    // setModalType(SLEEPING_REQ_MODAL_TYPES.request);
    setModalActive(true);
  }

  function onLowerTableRowSelect() {}
  function onLowerTableRowDoubleClick(sp) {
    // setModalType(SLEEPING_REQ_MODAL_TYPES.sp);
    dispatch(fetchStockPoolBySkuId(sp.id));
    setModalActive(true);
  }

  return {
    uppperTableColumns,
    onUpperTableRowSelect,
    upperTableCurrent,
    onUpperTableRowDoubleClick,
    isUpperTableLoading,
    lowerTableColumns,
    lowerTableData,
    onLowerTableRowSelect,
    lowerTableCurrent,
    onLowerTableRowDoubleClick,
    isLowerTableLoading,
    isModalActive,
    setModalActive,
    upperTableData,
  };
};

export default useSleepingRequestsService;
