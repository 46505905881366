import {useTranslation} from "react-i18next";

import {TextAreaInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";

import CharactericticsField from "./CharactericticsField";

const CharDeskBlock = () => {
  const {t} = useTranslation();

  return (
    <>
      <FieldWithSample name="charCheck" label={t("Characteristics")} style={{display: "flex", alignItems: "start"}}>
        <CharactericticsField />
      </FieldWithSample>

      <FieldWithSample
        name="complectDescCheck"
        label={t("Set description")}
        style={{display: "flex", alignItems: "start", gap: "10px"}}
      >
        <TextAreaInput name="complectDesc" width="101%" height={"41px"} />
      </FieldWithSample>
    </>
  );
};
export default CharDeskBlock;
