import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import useNameLangService from "../../../../../../../utilities/hooks/useNameLangService";
import useColumns from "../../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  clearCurrentPayment,
  getPaymentInfo,
} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import {
  getCurrentPayment,
  getCurrentPaymentId,
  getPaymentsLoader,
  getReaquestPayments,
} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

import {onPaymentListTopTableColumns} from "./constants";

const useTopBlockService = () => {
  const dispatch = useDispatch();

  const PAYMENT_DATE_TYPE_ID = 7;
  const {i18n} = useTranslation();
  const nameLang = useNameLangService();

  const requestPayments = useSelector(getReaquestPayments);
  const currentPayment = useSelector(getCurrentPayment);
  const currentPaymentId = useSelector(getCurrentPaymentId);

  useEffect(() => {
    if (currentPaymentId && !currentPayment) {
      dispatch(getPaymentInfo(currentPaymentId));
    }
  }, [currentPaymentId, currentPayment?.paymentId]);

  const paymentDates = currentPayment?.request?.requestDates.find(
    date => date.dateType.dateTypeId === PAYMENT_DATE_TYPE_ID,
  );

  const createdDate = currentPayment?.createdAt ?? "";
  const planned = paymentDates?.plannedDate ?? "";
  const actual = currentPayment?.paidAt ?? "";

  const onPaymentTableData =
    requestPayments?.map(
      ({paymentId, noteToFD, noteToFEA, paymentDoc: {docNumber, docType}, paymentSchema, status, confirmingDoc}) => ({
        id: paymentId,
        status: i18n === "eng" ? status.engName : status.ukrName,
        docNumber,
        docType: i18n === "eng" ? docType.nameEng : docType.nameUkr,
        paymentSchema: i18n === "eng" ? paymentSchema.nameEng : paymentSchema.nameUkr,
        noteForCO: noteToFD,
        confirmingDocument: confirmingDoc?.docType[`name${nameLang}`] || "",
        confirmingDocumentNumber: confirmingDoc?.docNumber || "",
        noteToFEA,
      }),
    ) ?? [];
  const current =
    onPaymentTableData?.find(requestPayment => requestPayment.id === currentPayment?.paymentId)?.id ?? null;
  const onPaymentTableColumnes = useColumns(onPaymentListTopTableColumns);

  function onPaymentTableRowSelect(paymentId) {
    if (paymentId === currentPayment?.paymentId || !paymentId) {
      dispatch(clearCurrentPayment());
      return;
    }
    dispatch(getPaymentInfo(paymentId));
  }

  return {createdDate, planned, actual, onPaymentTableData, onPaymentTableColumnes, onPaymentTableRowSelect, current};
};

export default useTopBlockService;
