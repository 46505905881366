export const TIME_UNITS_INPUT_LIST_DATA = Object.freeze([
  {
    unitName: "toFactoryDateUnit",
    inputName: "toFactoryDate",
    label: "Date: “to the Factory (f)” -> Date: “Pf receipt (p)”",
  },
  {
    unitName: "pfReceivingDateUnit",
    inputName: "pfReceivingDate",
    label: "Date: “Pf receiving (f)” -> Date: “Pf control (n)”",
  },
  {
    unitName: "pfControlDateUnit",
    inputName: "pfControlDate",
    label: "Date: “Pf control (f)” -> Date: “for payment (p)”",
  },
  {
    unitName: "paymentDateUnit",
    inputName: "paymentDate",
    label: "Date: “for payment (f)“ -> Date: upon payment (p)“",
  },
  {
    unitName: "paymentReadyDateUnit",
    inputName: "paymentReadyDate",
    label: "Date: “upon payment (f)“ -> Date: “Ready (n)“",
  },
  {
    unitName: "readyControlDateUnit",
    inputName: "readyControlDate",
    label: "Date: “Goods readiness control“ by",
  },
  {
    unitName: "logistDateUnit",
    inputName: "logistDate",
    label: "Date: “Logist (in)“ -> Date: “Logist (o)“",
  },
]);

export const DEFAULT_UNIT_ID = 19;
