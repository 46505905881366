import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";

import {deselectSku} from "../../../SKU/SkuSlice";
import {deselect, updateSkuPlan} from "../KpFinSlice";

import {withKpForm} from "./KpFinFormHOC";

const KpUpdateForm = ({setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (values, {resetForm}) => {
    dispatch(
      updateSkuPlan({
        skuPlanId: values.skuPlanId,
        skuPlan: {
          ...values,
          discount: values.discount === "" ? null : values.discount,
          topDiscount: values.topDiscount === "" ? null : values.topDiscount,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        resetForm();
        setModalActive(false);
        dispatch(deselect());
      }
    });
  };
  const onCancel = () => {
    dispatch(deselect());
    dispatch(deselectSku());

    setModalActive(false);
  };

  const KpForm = withKpForm(t("Save"), onSubmit, onCancel, true, "save and return kp upd");

  return <KpForm />;
};

KpUpdateForm.propTypes = {setModalActive: PropTypes.func};

export default KpUpdateForm;
