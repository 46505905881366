// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical__nav {
  width: 19em;
  min-height: 200px;
  position: absolute;
  top: 5em;
}
.vertical__nav_container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.vertical__nav p {
  font-size: 1.5em;
  line-height: 1.4em;
  font-weight: 300;
}
.vertical__nav a {
  text-decoration: none;
  color: rgba(66, 108, 166, 0.662745098);
}

.verticalLink__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2em;
  text-decoration: none;
}
.verticalLink__item:hover {
  color: #426BA6;
}`, "",{"version":3,"sources":["webpack://./src/Components/adminForms/verticalNav.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAEA,iBAAA;EACA,kBAAA;EACA,QAAA;AAAJ;AAII;EACI,WAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;AAFR;AAII;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;AAFR;AAII;EACI,qBAAA;EACA,sCAAA;AAFR;;AAOA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,WAAA;EACA,qBAAA;AAJJ;AAKK;EACG,cAAA;AAHR","sourcesContent":[".vertical__nav{\n    width: 19em;\n    // height: 100%;\n    min-height: 200px;\n    position: absolute;\n    top: 5em;\n    // border: 1px solid #88a6cf89;\n    // left: 50%;\n\n    &_container{\n        width: 100%;\n        height: 100vh;\n        position: relative;\n        display: flex;\n        justify-content: center;\n    }\n    p{\n        font-size: 1.5em;\n        line-height: 1.4em;\n        font-weight: 300;\n    }\n    a{\n        text-decoration: none;\n        color:#426ca6a9;    \n       \n    }\n\n}\n.verticalLink__item{\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    height: 2em;\n    text-decoration: none;\n     &:hover{\n        color:#426BA6;;\n        }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
