import {useTransition} from "react";
import {useSelector} from "react-redux";

import {STATUS_COLORS} from "../../../../../../../utilities/colorConstants";

const useControlPfTableData = forRequest => {
  const {t} = useTransition();
  const users = useSelector(state => state.person.users);

  return (
    forRequest?.approvals?.map((approval, i) => {
      // const circleColor =
      //   isApproved === null ? STATUS_COLORS.GREY : isApproved ? STATUS_COLORS.GREEN : STATUS_COLORS.RED;

      const statusProfControl = () => {
        switch (true) {
          case !approval?.isApproved && !approval?.isDeclined:
            return "On control";
          case approval?.isApproved:
            return "Pf approved";
          case approval?.isDeclined:
            return "Pf not approved";
          default:
            return "";
        }
      };

      const managerCo = users?.find(user => user.userId === approval?.managerCoId)?.name ?? t("No name");

      return {
        number: i + 1,
        id: approval?.approvalId,
        pfNumber: forRequest.proformaNumber,
        managerCo,
        accepted: statusProfControl(),
      };
    }) || []
  );
};

export default useControlPfTableData;
