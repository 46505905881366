import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import {addFactoryNote, fetchFactory} from "../../../../redux/operations/FactoryOperations";
import {selectCurrentFactory} from "../../../../redux/selectors/FactorySelectors";
import useCountryIdOptionsService from "../../../conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";

const useFactoryAddressService = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentFactory = useSelector(selectCurrentFactory);

  const countryIdsOptions = useCountryIdOptionsService();
  const factoryAddressNotesPlaceholder = !currentFactory ? t("Add factory first") : "";
  const isFactoryAddressNotesFieldDisabled = !currentFactory;

  function onSaveFactoryNoteBtnClick(note) {
    if (!currentFactory) {
      toast.error(t("Save a factory first!"));
      return;
    }

    if (!note) {
      toast.error(t("A note can`t be empty!"));
      return;
    }

    dispatch(addFactoryNote({factoryId: currentFactory.factoryId, data: {note}})).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Note saved!"));
        dispatch(fetchFactory(currentFactory.factoryId));
      }
    });
  }

  return {
    onSaveFactoryNoteBtnClick,
    countryIdsOptions,
    factoryAddressNotesPlaceholder,
    isFactoryAddressNotesFieldDisabled,
  };
};

export default useFactoryAddressService;
