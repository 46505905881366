import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import useList from "../../hook/list.hook";

const ActivesList = () => {
  const {t} = useTranslation();

  const {formThs} = useList();

  const ths = useMemo(() => {
    return formThs({
      "№": 2,
      [t("CO №")]: 6,
      [t("CO Manager")]: 8,
      [t("Nomenclature")]: 8,
      [t("Date from")]: 6,
      [t("Date to")]: 6,
      [t("Application")]: 6,
      [t("Proform")]: 5,
      [t("Order")]: 6,
      [t("Execution date")]: 6,
      [t("Warehouse Consolidation date")]: 6,
      [t("Shipment date")]: 6,
      [t("Date at customs")]: 6,
      [t("Warehouse date")]: 6,
      [t("Installation date")]: 6,
      "D-day": 6,
    });
  }, []);

  return (
    <div className="project_list " style={{width: "100%", maxWidth: "100vw", height: "90%"}}>
      <table className="project_table">
        <thead>
          <tr>{ths}</tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default ActivesList;
