import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectFetchedStockPool} from "../../redux/selectors";
import {WAREHOUSE_CARGO_TABLE_COLUMNS} from "../../WarehouseBlock/utils/constants";

const CargoInfo = () => {
  const proportionsTableColumns = useColumns(WAREHOUSE_CARGO_TABLE_COLUMNS);
  const currentSP = useSelector(selectFetchedStockPool);

  const proportionsTableData = useMemo(
    () =>
      currentSP?.packingSpaces?.map((space, index) => {
        const {spaceNumber, volume, weight, note, length, width, height, spaceId} = space;

        const dimenSh = [length, width, height];
        const updatedNumbers = dimenSh.map(num => (num === null || num === "0.00" ? "0" : num));

        const proportions = updatedNumbers.every(item => item === "0")
          ? ""
          : `${updatedNumbers[0]}x${updatedNumbers[1]}x${updatedNumbers[2]}`;

        return {
          id: spaceId,
          number: ++index,
          stockPoolNumber: currentSP?.stockPoolNumber ?? " - ",
          placeNumber: spaceNumber ?? " - ",
          proportions: proportions ?? " - ",
          weight: weight ?? " - ",
          volume: volume ?? " - ",
          note: note ?? " - ",
        };
      }) || [],
    [currentSP?.stockPoolId],
  );
  return (
    <div>
      <ReactTable
        className="tableTac"
        columns={proportionsTableColumns}
        defaultData={proportionsTableData}
        selectable={false}
        style={{height: "150px"}}
      />
    </div>
  );
};

export default CargoInfo;
