import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {checkEmptyFields} from "../../../../../../utilities/checkEmptyFields";
import useToaster from "../../../../../../utilities/hooks/useToaster";
import {fetchAllStockPoolsNew, fetchStockPoolFilters} from "../../../../redux/operations";
import {selectStockPoolFilters, selectWarehouseRequests} from "../../../../redux/selectors";

const useWarehouseSplitCreateModalFilters = ({setSelectedFilters = () => {}, selectedWarehouse}) => {
  const dispatch = useDispatch();
  const Toaster = useToaster();

  const {
    i18n: {language},
  } = useTranslation();

  const requests = useSelector(selectWarehouseRequests);
  const [areFiltersDefined, setAreFiltersDefined] = useState(false);

  useEffect(() => {
    dispatch(
      fetchStockPoolFilters({
        window: "Warehouse Creating StockPool",
        warehouseId: selectedWarehouse?.value || requests[0]?.stockPool?.warehouse?.warehouseId,
      }),
    );
  }, []);

  const initialValues = {countryId: ""};

  const allFilters = useSelector(selectStockPoolFilters);

  const countryOptions = useMemo(() => {
    return (
      allFilters?.countries?.map(item => {
        return {title: item.shortName, value: item.countryId};
      }) || []
    );
  }, [allFilters]);

  const coManagersOptions = useMemo(() => {
    return (
      allFilters?.coManagers?.map(item => {
        const name = item?.persona
          ? language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName
          : item.name;
        return {
          title: name,
          value: item.managerCoId,
        };
      }) || []
    );
  }, [allFilters, language]);

  function onFilter(values) {
    const notEmptyValues = checkEmptyFields(values);

    if (Object.keys(notEmptyValues).length === 0) {
      Toaster.toastError("You must define at least one filter value first!");
      return;
    }

    setSelectedFilters(notEmptyValues);
    Toaster.toastSuccess("Filters were applied");
    setAreFiltersDefined(true);
    dispatch(
      fetchAllStockPoolsNew({
        window: "Warehouse Creating StockPool",
        ...notEmptyValues,
        warehouseId: selectedWarehouse?.value || requests[0]?.stockPool?.warehouse?.warehouseId,
      }),
    );
  }
  function delFilters(setValues) {
    Toaster.toastSuccess("Filters were deleted");
    setAreFiltersDefined(false);
    dispatch(
      fetchAllStockPoolsNew({
        window: "Warehouse Creating StockPool",
        warehouseId: selectedWarehouse?.value || requests[0]?.stockPool?.warehouse?.warehouseId,
      }),
    );
    setValues(initialValues);
  }

  return {
    onFilter,
    delFilters,
    areFiltersDefined,
    countryOptions,
    initialValues,
    coManagersOptions,
  };
};

export default useWarehouseSplitCreateModalFilters;
