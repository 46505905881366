import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {withFormik} from "formik";

import QuestionItem from "../../../../utilities/QuestionItem";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useCountryIdOptionsService from "../../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import useRequestOptions from "../../data/useRequestOptions";
import useRequestFilters from "../filters.hook";
import withRequestFilters from "../FiltersHOC";

const BaseRequestFilters = () => {
  const {t} = useTranslation();
  const {brandsOptions, coManagersOptions} = useRequestFilters();
  const countryOptions = useCountryIdOptionsService();
  const requestNumberOptions = useRequestOptions();
  return (
    <>
      <div className="f_block" style={{paddingTop: "0.7em"}}>
        <Select
          label={t("Request №")}
          name="requestNumber"
          options={requestNumberOptions}
          width="12em"
          labelwidth="8em"
        />
        <Select label={t("CO Manager")} name="managerCoId" options={coManagersOptions} width="12em" labelwidth="8em" />
        <Select label={t("Factory")} name="brandId" options={brandsOptions} width="12em" labelwidth="8em" />

        <TextInput label="Prof №" name="proformaNumber" width="12em" labelStyles={{width: "8em"}} disabled />
        <TextInput label={t("Invoice №")} name="invoiceNumber" width="12em" labelStyles={{width: "8em"}} disabled />

        <Select label={t("Pr country")} name="countryId" options={countryOptions} width="12em" labelwidth="8em" />
      </div>
      <div className="period-wrapper" style={{paddingTop: "0.7em"}}>
        <QuestionItem title={"Select date"} />
        {t("d period")}
        <div className="request-date-wrapper">
          <DateInput label={t("From")} name="dateStart" />
          <DateInput label={t("To")} name="dateEnd" />
        </div>
      </div>
    </>
  );
};

const RequestReadyFilter = withRequestFilters(BaseRequestFilters);

const RequestReadyFilterForm = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(RequestReadyFilter);

export default RequestReadyFilterForm;
