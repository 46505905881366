export const TRANSPORTER_SETTINGS_MAIN_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Title",
    accessorKey: "transporterName",
  },
  {
    header: "Address",
    accessorKey: "address",
  },
  {
    header: "Full name",
    accessorKey: "contactFullName",
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Phone",
    accessorKey: "phone",
  },
  {
    header: "Note",
    accessorKey: "note",
  },
]);
