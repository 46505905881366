import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchForRequestById} from "../../../../../Proform/redux/operations/ProformOperations";
import {selectCurrentForRequest} from "../../../../../Proform/redux/selectors/ProformSelectors";
import {getRequestInfo} from "../../../../../RequestsSlice";

const useForRequestService = () => {
  const dispatch = useDispatch();
  const {requestId} = useSelector(getRequestInfo);

  const forRequest = useSelector(selectCurrentForRequest);

  useEffect(() => {
    dispatch(fetchForRequestById(requestId));
  }, []);

  return forRequest;
};

export default useForRequestService;
