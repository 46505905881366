import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../hook/axios.hook";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import formSearchParams from "../../../../utilities/formSearchParams";

//PATH
const FACTORIES_PATH = "factories";
const FACT_COND_PATH = "factories/conditions";
const CONTACTS_PATH = "contacts";
const FACT_COND_TEMPL_PATH = "factories/conditions/emailTemplates";
const FACT_CONTROL_TERMS_PATH = "factories/conditions/controlTerms";

//*** FACTORIES ***
//get
export const fetchAllFactories = createAsyncThunk("factories/getAllFactories", async params =>
  fetchList(`${FACTORIES_PATH}/getAll`, "get", {params: checkEmptyFields(params)}),
);
export const fetchFactoryGroups = createAsyncThunk("factories/getFactoryGroups", async factoryId =>
  fetchList(`${FACTORIES_PATH}/getGroups/${factoryId}`, "get"),
);
export const fetchReferences = createAsyncThunk("factories/getReferences", async () =>
  fetchData(`${FACTORIES_PATH}/getReferences`, "get"),
);
export const fetchFactory = createAsyncThunk("factories/getFactoryById", async factoryId =>
  fetchList(`${FACTORIES_PATH}/get/${factoryId}`, "get"),
);
//post
export const addFactory = createAsyncThunk("factories/addFactory", async data =>
  fetchData(`${FACTORIES_PATH}/add`, "post", data),
);
export const addFactoryNote = createAsyncThunk("factories/addFactory", async ({factoryId, data}) =>
  fetchData(`${FACTORIES_PATH}/notes/add/${factoryId}`, "post", data),
);
//patch
export const saveFactory = createAsyncThunk("factories/saveFactory", async factoryId =>
  fetchData(`${FACTORIES_PATH}/save/${factoryId}`, "patch"),
);
export const updateFactory = createAsyncThunk("factories/updateFactory", async ({factoryId, data}) =>
  fetchData(`${FACTORIES_PATH}/update/${factoryId}`, "patch", data),
);
//delete
export const cancelFactoryCreation = createAsyncThunk("factories/cancelFactoryCreation", async factoryId =>
  fetchData(`${FACTORIES_PATH}/cancel/${factoryId}`, "delete"),
);
export const cancelFactoryUpdate = createAsyncThunk("factories/cancelFactoryUpdate", async factoryId =>
  fetchData(`${FACTORIES_PATH}/cancelUpdate/${factoryId}`, "delete"),
);

export const factoryAppointManager = createAsyncThunk(
  "factories/appointManager",
  async ({factoryId, productGroupId, managerFeaId}) =>
    fetchData(`${FACTORIES_PATH}/appointManager/${factoryId}/${productGroupId}/${managerFeaId}`, "patch"),
);
export const factoryDisappointManager = createAsyncThunk(
  "factories/disappointManager",
  async ({factoryId, productGroupId}) =>
    fetchData(`${FACTORIES_PATH}/disappointManager/${factoryId}/${productGroupId}`, "patch"),
);

//*** SUPPLY CONDITIONS ***
//get
export const fetchAllConditions = createAsyncThunk("factories/fetchAllConditions", async paramsObject => {
  const searchParams = formSearchParams(paramsObject);
  return fetchList(`${FACT_COND_PATH}/getAll${searchParams}`, "get");
});
export const fetchFactoryConditions = createAsyncThunk("factories/fetchFactoryConditions", async factoryId => {
  return fetchList(`${FACT_COND_PATH}/getAll?factoryId=${factoryId}`, "get");
});
export const fetchFactoryContactConditions = createAsyncThunk(
  "factories/fetchFactoryContactConditions",
  async ({factoryId, contactId}) => {
    return fetchList(`${FACT_COND_PATH}/getAll?factoryId=${factoryId}&contactId=${contactId}`, "get");
  },
);
export const fetchSupplyCondition = createAsyncThunk("factories/fetchSupplyCondition", async conditionId =>
  fetchList(`${FACT_COND_PATH}/get/${conditionId}`, "get"),
);
export const fetchFilters = createAsyncThunk("factories/fetchFilters", async () =>
  fetchList(`${FACT_COND_PATH}/getFilters`, "get"),
);
//post
export const addCondition = createAsyncThunk("factories/addCondition", async ({factoryId, contactId, data}) =>
  fetchData(`${FACT_COND_PATH}/add/${factoryId}/${contactId}`, "post", data),
);
//patch
export const updateCondition = createAsyncThunk("factories/updateCondition", async ({conditionId, data}) =>
  fetchData(`${FACT_COND_PATH}/update/${conditionId}`, "patch", data),
);
//delete
export const deleteCondition = createAsyncThunk("factories/deleteCondition", async conditionId =>
  fetchData(`${FACT_COND_PATH}/delete/${conditionId}`, "delete"),
);

//*** REPRESENTATIVE CONTACTS ***
//get
export const fetchAllContacts = createAsyncThunk("factories/getAllContacts", async () =>
  fetchList(`${CONTACTS_PATH}/getAll`, "get"),
);
export const fetchContact = createAsyncThunk("factories/getContact", async contactId =>
  fetchList(`${CONTACTS_PATH}/get/${contactId}`, "get"),
);
//post
export const addContact = createAsyncThunk("factories/addContact", async data =>
  fetchData(`${CONTACTS_PATH}/add`, "post", data),
);
export const addContactNote = createAsyncThunk("factories/addContactNote", async ({contactId, data}) =>
  fetchData(`${CONTACTS_PATH}/notes/add/${contactId}`, "post", data),
);
//patch
export const updateContact = createAsyncThunk("factories/updateContact", async ({contactId, data}) =>
  fetchData(`${CONTACTS_PATH}/update/${contactId}`, "patch", data),
);
//delete
export const deleteContact = createAsyncThunk("factories/deleteContact", async contactId =>
  fetchData(`${CONTACTS_PATH}/delete/${contactId}`, "delete"),
);

//*** EMAIL TEMPLATES ***
export const fetchEmailTemplates = createAsyncThunk("factories/fetchEmailTemplates", async params =>
  fetchList(`${FACT_COND_TEMPL_PATH}/getAll`, "get", {params}),
);

export const addEmailTemplate = createAsyncThunk("factories/addEmailTemplate", async ({conditionId, values}) =>
  fetchData(`${FACT_COND_TEMPL_PATH}/add/${conditionId}`, "post", values),
);

export const updateEmailTemplate = createAsyncThunk("factories/updateEmailTemplate", async ({templateId, values}) =>
  fetchData(`${FACT_COND_TEMPL_PATH}/update/${templateId}`, "patch", values),
);

export const deleteEmailTemplate = createAsyncThunk("factories/deleteEmailTemplate", async templateId =>
  fetchData(`${FACT_COND_TEMPL_PATH}/delete/${templateId}`, "delete"),
);

//*** CONTROL TERMS ***
//get
export const fetchControlTerms = createAsyncThunk("factories/fetchControlTerms", async conditionId =>
  fetchList(`${FACT_CONTROL_TERMS_PATH}/getAll`, "get", {params: {conditionId}}),
);
//post
export const addControlTerms = createAsyncThunk("factories/addControlTerms", async conditionId =>
  fetchList(`${FACT_CONTROL_TERMS_PATH}/add/${conditionId}`, "post"),
);
//patch
export const updateTermsForCondition = createAsyncThunk(
  "factories/updateTermsForCondition",
  async ({conditionId, data}) => fetchList(`${FACT_CONTROL_TERMS_PATH}/update/${conditionId}`, "patch", data),
);
//delete
export const deleteControlTerms = createAsyncThunk("factories/deleteControlTerms", async conditionId =>
  fetchList(`${FACT_CONTROL_TERMS_PATH}/delete/${conditionId}`, "delete"),
);
