import React, {useCallback, useState} from "react";

const useInputsTooltip = field => {
  const [tooltipShown, setTooltipShown] = useState(false);

  const onMouseEnter = useCallback(() => {
    if (field?.value?.length < 1) {
      setTooltipShown(true);
    }
  }, [field]);

  const onMouseLeave = useCallback(() => {
    setTooltipShown(false);
  }, []);

  return {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown};
};

export default useInputsTooltip;
