import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import saveIcon from "../../../../images/icons/save.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../Inputs";
import CheckBoxWithLabel from "../../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel";
import PhoneInput from "../../../Inputs/PhoneInput";
import {Select} from "../../../Inputs/SelectField";

import useFactoryContactsEditService from "./utils/hooks/getFactoryContactsEditService";
import useNewFactoryContactsService from "./utils/hooks/getNewFactoryContactsService";

//edit modal
const FactoryContactsEdit = ({onUpdateContactBtnClick = () => {}, onDeleteContactBtnClick = () => {}}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {
    columns,
    contactTypeOptions,
    factoryContactTableData,
    onContactsTableRowSelect,
    isContactsUpdateBtnDisabled,
    isContactsDeleteBtnDisabled,
  } = useFactoryContactsEditService();

  const {onSaveContactNoteBtnClick, contactNotePlaceholer, isContactNoteFieldDisabled} = useNewFactoryContactsService();

  const noChangeDisabledBtnTooltipMessage = t("Make some changes first");
  const noSelectedContactTooltipMessage = t("Select a contact first");

  return (
    <>
      <div style={{marginBottom: "0.5em"}}>2. {t("repr contacts")}</div>
      <ReactTable
        defaultData={factoryContactTableData}
        columns={columns}
        className="factory_edit_table"
        current={null}
        onSelect={onContactsTableRowSelect}
      />
      <div className="edit_block">
        <TextInput
          label="E-MAIL"
          name="email"
          type="text"
          width="100%"
          generalClassName="w100"
          labelStyles={{width: "14.5em"}}
        />
        <div className="f_b_wrapper jcsb">
          <TextInput
            label={t("cont pers CP")}
            name="contactName"
            type="text"
            generalClassName="w100 edit_item"
            labelStyles={{width: "20em"}}
          />
          <Select
            label={t("Type")}
            name="contactTypeId"
            options={contactTypeOptions}
            multi={true}
            width="9.5em"
            disabled={false}
          />
        </div>
        <div className="f_b_wrapper edit_item_row jcsb">
          <PhoneInput
            label={t("tel")}
            name="phone"
            inputProps={{name: "phone"}}
            type="text"
            width="80%"
            labelStyles={{width: "15em"}}
          />
          <CheckBoxWithLabel label="mark as" checked />
        </div>
        <div className="f_tabl_wrapper edit_item edit_item_row"></div>
      </div>
      <div className="f_b_wrapper">
        <TextInput
          label={t("notes to cp")}
          name="newContactNotes"
          type="text"
          labelStyles={{width: "15em"}}
          width="100%"
          generalClassName="w100"
          placeholder={contactNotePlaceholer}
          disabled={isContactNoteFieldDisabled}
        />
        <ImageButton
          src={saveIcon}
          alt="save"
          width={1.5}
          height={1}
          onClick={() => onSaveContactNoteBtnClick(values)}
          disabled={isContactNoteFieldDisabled}
        />
      </div>
      <TextAreaInput name="contactNotes" width={"101%"} readOnly />
      <div className="fcc jcsb" style={{marginTop: "0.5em"}}>
        <FunctionButton
          name="Save Contact person`s changes"
          autoWidth
          onClick={onUpdateContactBtnClick}
          disabled={isContactsUpdateBtnDisabled}
          tooltipMessage={isContactsUpdateBtnDisabled ? noChangeDisabledBtnTooltipMessage : ""}
        />
        <FunctionButton
          name="Delete Contact"
          autoWidth
          onClick={onDeleteContactBtnClick}
          disabled={isContactsDeleteBtnDisabled}
          tooltipMessage={isContactsDeleteBtnDisabled ? noSelectedContactTooltipMessage : ""}
        />
      </div>
    </>
  );
};

FactoryContactsEdit.propTypes = {onUpdateContactBtnClick: PropTypes.func, onDeleteContactBtnClick: PropTypes.func};

export default FactoryContactsEdit;
