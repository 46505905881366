import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextAreaInput} from "../../../Inputs";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import CheckListModalsTodo from "../../common/Todo/CheckListModalsTodo";
import {selectCurrentSkuRequest} from "../../redux/selectors";
import {WAREHOUSE_ADRESS_TABLE_COLUMNS} from "../utils/constants";

const WarehouseAdressPart = () => {
  const {t} = useTranslation();

  const warehouseAdressTableColumns = useColumns(WAREHOUSE_ADRESS_TABLE_COLUMNS);
  const warehouseAdressTableData = [];
  const currentSkuRequest = useSelector(selectCurrentSkuRequest);

  return (
    <div style={{width: "50%", borderRight: "1px solid #A3B9D9", paddingRight: "1em"}}>
      <div>
        <div className="fdr">
          <div>1.</div>
          <div className="label underline">{t("Warehouse address and contacts")}</div>
        </div>
        <TextAreaInput name="warehouseContactsField" width={"100%"} readOnly className="mb0_5" height={"2em"} />
      </div>
      <div className="mb0_5">
        <div className="fdr aibl mb0_5">
          <div>2.</div>
          <div className="label underline" style={{marginRight: "auto"}}>
            {t("Next delivery adress")}
          </div>
          <DateInput label={t("Date: Delivery (p)")} name="deliveryDate" />
        </div>
        <ReactTable
          defaultData={warehouseAdressTableData}
          columns={warehouseAdressTableColumns}
          current={null}
          className={"project_table"}
          style={{height: "4em"}}
          selectable={false}
        />
      </div>
      <CheckListModalsTodo path={"stockPool"} entityId={currentSkuRequest?.stockPoolId || null} tableheight="6em" />
    </div>
  );
};

export default WarehouseAdressPart;
