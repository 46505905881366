import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchUsers, getUsers} from "../../../../../../slices/PersonSlice";
import useToaster from "../../../../../../utilities/hooks/useToaster";
import {appointStockPool, cancelStockPoolAppointment, fetchAllStockPoolsNew} from "../../../../redux/operations";
import {selectAppointedStockPools} from "../../../../redux/selectors";

const useOrderingFormLog = ({selectedForCancel, selectedSP, setSelectedSP, setSelectedForCancel, selectedFilters}) => {
  const dispatch = useDispatch();
  const Toaster = useToaster();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const initialValues = {
    note: "",
    date: "",
    managerLog: "",
  };

  const allUsers = useSelector(getUsers);
  // const managerLogOptions = allUsers.map(user => ({title: user.name, value: user.userId}));

  const managerLogOptions = useMemo(() => {
    return (
      allUsers.map(item => {
        if (item?.position?.positionId === 2 || item?.position?.positionId === 7 || item?.position?.positionId === 5) {
          const name =
            i18n.language === "en"
              ? item.persona.engFirstName + " " + item.persona.engLastName
              : item.persona.firstName + " " + item.persona.lastName;

          return {
            title: name,
            value: item?.userId,
          };
        } else {
          return {};
        }
      }) || []
    );
  }, [allUsers, i18n.language]);

  const appointedStockPools = useSelector(selectAppointedStockPools);

  const isUpBtnDisabled = !appointedStockPools || appointedStockPools.length === 0 || !selectedForCancel;
  const isDownBtnDisabled = Boolean(!selectedSP || selectedSP.length === 0);

  const onAppoint = ({values, setValues}) => {
    const {managerLog, date, note} = values;
    if (!managerLog || !date) {
      Toaster.toastError("Define a logist manager and date first!");
      return;
    }
    if (!selectedSP) {
      Toaster.toastError("Choose at least one stock pool first!");
      return;
    }

    dispatch(appointStockPool({managerLogistId: managerLog, params: {stockPoolsIds: selectedSP, date, note}})).then(
      resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess("Appointed successfully");
          dispatch(fetchAllStockPoolsNew({window: "Incoming", ...(selectedFilters ?? [])}));
          dispatch(fetchAllStockPoolsNew({window: "Appointed"}));
        }
      },
    );
    setValues(initialValues);

    setSelectedSP([]);
  };

  const onCancel = () => {
    if (!selectedForCancel) {
      Toaster.toastError("Choose at least one stock pool first!");
      return;
    }
    dispatch(cancelStockPoolAppointment(selectedForCancel)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllStockPoolsNew({window: "Incoming", ...(selectedFilters ?? [])}));
        dispatch(fetchAllStockPoolsNew({window: "Appointed"}));
      }
    });

    setSelectedForCancel(null);
    Toaster.toastSuccess("Canceled");
  };

  return {initialValues, onAppoint, onCancel, isUpBtnDisabled, isDownBtnDisabled, managerLogOptions};
};

export default useOrderingFormLog;
