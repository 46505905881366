import AvailabilityStatusCIrcule from "../../../../../utilities/AvailabilityStatusCIrcule";

export const REQUEST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Status",
    accessorKey: "statusCircle",
    size: 50,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },

  {
    header: "Request status",
    accessorKey: "requestStatus",
    size: 200,
  },

  {
    header: "Request №",
    accessorKey: "requestNumber",
    size: 250,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    isSortable: true,
    // size: 100,
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    size: 200,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    // size: 100,
  },

  {
    header: "Bring to",
    accessorKey: "bringTo",
    // size: 100,
  },
  {
    header: "Product country",
    accessorKey: "country",
    // size: 100,
  },
  {
    header: "Prof №",
    accessorKey: "prof",
    // size: 100,
  },
  {
    header: "Invoice №",
    accessorKey: "invoice",
    // size: 100,
  },
  {
    header: "Packing List №",
    accessorKey: "packL",
    // size: 100,
  },
  {
    header: "Tr pool №",
    accessorKey: "trPool",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 270,
  },
  // {
  //   header: () => [t("Status*")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD to factory")],
  //   accessorKey: "noname",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD proform")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD control")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD for payment")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD paid")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD preReady")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD ReadyProduct")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Quantity (request)")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Quantity(stock)")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Price in request")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("TP №")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Reservation")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("CO reservation")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
]);

export const MANAGER_CO_INCOMING_REQ_TABLE = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "CO №",
    accessorKey: "coNumber",
    // size: 100,
  },
  {
    header: "Order",
    accessorKey: "requestNumber",
    // size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    // size: 100,
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
    // size: 15,
  },
  {
    header: "Product country",
    accessorKey: "country",
    // size: 100,
  },

  {
    header: "Product group",
    accessorKey: "productGroup",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    // size: 100,
  },
  // {
  //   header: "Status*",
  //   accessorKey: "status",
  //   // size: 100,
  // },
]);

export const MANAGER_CO_APPOINTED_REQ_TABLE = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "CO №",
    accessorKey: "coNumber",
    // size: 100,
  },
  {
    header: "Order",
    accessorKey: "requestNumber",
    // size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    // size: 100,
  },
  {
    header: "Manager FEA",
    accessorKey: "managerFea",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    // size: 100,
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
    // size: 15,
  },
  {
    header: "Product country",
    accessorKey: "country",
    // size: 100,
  },

  {
    header: "Product group",
    accessorKey: "productGroup",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    // size: 100,
  },
  // {
  //   header: "Status*",
  //   accessorKey: "status",
  //   // size: 100,
  // },
]);

export const LOGIST_REQ_TABLE = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Date of Readiness",
    accessorKey: "readinessDate",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Country prof",
    accessorKey: "profCountry",
  },
  {
    header: "Product country",
    accessorKey: "productCountry",
  },

  {
    header: "Product group",
    accessorKey: "productGroup",
  },
]);

export const SP_TABLE = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
  },
]);
export const SP_CHECK_TABLE = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
  },
  {
    header: "Transport Pool №",
    accessorKey: "transportPoolNumber",
  },
]);
export const LOGIST_DISTRIB_REQ_TABLE = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "TP №",
    accessorKey: "tpNumber",
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Date of Readiness",
    accessorKey: "createdAt",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "country",
  },

  {
    header: "Product group",
    accessorKey: "productGroup",
  },
  {
    header: "Notes",
    accessorKey: "note",
  },
]);
