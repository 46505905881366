import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {apiUrl} from "../../../../api/baseURL";

// Загрузка шрифтов для pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePdf = (data, headers, common) => {
  // Определение стилей для таблицы
  const styles = {
    tableHeader: {
      bold: true,
      fontSize: 10,
      color: "black",
      alignment: "center",
    },
    cell: {
      fontSize: 9,
      color: "black",
      alignment: "center",
    },
    justy: {
      alignment: "center",
    },
    nestedTable: {
      margin: [0, 0, 0, 0],
      alignment: "left", // Убираем отступы внутри ячейки с вложенной таблицей
    },
    header: {
      bold: true,
      fontSize: 9,
    },
    headerH1: {
      bold: true,
      fontSize: 16,
      alignment: "center",
      margin: [0, 10, 0, 0],
    },
    headerH2: {
      bold: true,
      fontSize: 14,
      alignment: "center",
      margin: [0, 10, 0, 5],
    },
    simpletext: {
      margin: [0, 5, 0, 0],
    },
    emptyRow: {
      lineHeight: 2,
      color: "white",
    },
  };

  // Определение содержимого таблицы
  var imgCount = 1;
  var imagesDef = {}; /// перечень картинок которые будут во всём пдф
  const header = [];
  const headersAccesors = [];
  const tables = [];
  const commission = common.commission.split(",");

  headers.forEach((th, i) => {
    if (i === 0) {
      header.push({text: "№", style: "tableHeader"});
      headersAccesors.push(th.accessorKey);
      return;
    }
    header.push({text: th.header, style: "tableHeader"});
    headersAccesors.push(th.accessorKey);
  });

  const imgCountPlus = () => {
    imgCount++;
  };

  const makeImageGroup = (images, count, onImgPlus, naming, colSpan, centered, imgWidth) => {
    var cell = [];
    var rowImgCount = count;
    if (images && images?.length !== 0) {
      images.forEach((img, j) => {
        if (img) {
          if (centered) {
            var alignment = "center";
            var width = "*";
            if (images.length > 1) {
              if (j === Math.trunc(images.length / 2) && images.length !== 2) {
                alignment = "center";
              } else if (j + 1 <= images.length / 2) {
                alignment = "right";
              } else if (j + 1 > images.length / 2) {
                alignment = "left";
              }
            }

            if (j !== 0 && j !== images.length - 1) {
              width = imgWidth;
            }

            cell.push({
              image: `${naming}${rowImgCount}`,
              fit: [imgWidth, imgWidth],
              width: width,
              alignment: alignment,
            });
          } else {
            cell.push({
              image: `${naming}${rowImgCount}`,
              fit: [imgWidth, imgWidth],
              width: imgWidth,
              alignment: "left",
            });
          }
          imagesDef[`${naming}${rowImgCount}`] = `${apiUrl}/${img}`;
          onImgPlus();
          rowImgCount++;
        }
      });

      return {
        colSpan: colSpan, // Об'єднання всіх стовпців у рядку
        columns: cell,
        columnGap: 10,
        alignment: "left",
      };
    } else return null;
  };

  data.forEach((rowData, i) => {
    const {photos, description} = rowData;
    const photosUrl = photos.map(photo => photo?.imageUrl);
    const tableContent = [];

    var descriptionRow = {
      colSpan: headers.length, // Об'єднання всіх стовпців у рядку
      alignment: "left",
      text: description,
      fontSize: 9,
    };

    // var emptyRow = {
    //   colSpan: headers.length,
    //   text: "A",
    //   style: "emptyRow",
    //   pageBreak: "avoid",
    //   border: [false, false, false, false],
    // };

    const tableRow = [];

    for (var key in rowData) {
      if (headersAccesors.includes(key)) {
        tableRow.push({
          text: rowData[key],
          style: "cell",
          pageBreak: "avoid",
          alignment: "center",
        });
      }
    }

    tableContent.push(header.map((head, j) => ({...head, id: i * headers.length + j})));
    tableContent.push(tableRow);
    tableContent.push([descriptionRow]);
    if (photosUrl.length > 0) {
      const imgRow = makeImageGroup(photosUrl, imgCount, imgCountPlus, "photos", headers.length, false, 100);
      tableContent.push([imgRow]);
    }

    if (i !== 0) {
      tables.push({
        text: "A",
        style: "emptyRow",
        headlineLevel: 1,
      });
    }

    tables.push({
      table: {
        widths: ["auto", "auto", "auto", "*", "*", "*", "auto", "auto", "auto", "auto", "auto"],
        style: "nestedTable",
        body: tableContent,
      },
      layout: {
        dontBreakRows: true,
        pageBreak: "before",
      },
    });
  });

  // Определение документа PDF
  const documentDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    footer: function (currentPage, pageCount) {
      return {
        text: "Сторінка " + currentPage.toString() + " з " + pageCount,
        alignment: "right",
        margin: [0, 0, 20, 0],
      };
    },

    content: [
      {text: "АКТ", style: "headerH1"},
      {text: "дефектування матеріальних цінностей", style: "headerH1"},
      {
        columns: [
          {
            width: "auto",
            text: `місто ${common?.city || ""} `,
            alignment: "left",
            margin: [0, 20, 0, 20],
          },
          {
            width: "*",
            text: `Дата: ${common?.date.toString() || ""}`,
            alignment: "right",
            margin: [0, 20, 0, 20],
          },
        ],
      },
      {text: "Комісія у складі:" || "", style: "simpletext"},
      ...commission.map(member => ({text: member, style: "simpletext"})),
      {text: common?.actText || "", style: "simpletext"},
      {text: "Опис матеріальних цінностей що передаються", style: "headerH2", margin: [0, 20, 0, 5]},
      ...tables,
      {text: "Комісія у складі:", style: "simpletext", margin: [0, 20, 0, 5]},
      {
        table: {
          widths: [120, 230],
          body: commission.map(member => [
            {text: member, style: "cell", alignment: "left"},
            {text: "", style: "cell", alignment: "left"},
          ]),
        },
        layout: {
          dontBreakRows: true,
          noWrap: true,
        },
      },
    ],
    pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return currentNode.headlineLevel === 1 && followingNodesOnPage.length !== 0 && nodesOnNextPage.length !== 0;
    },
    images: {...imagesDef},
    styles: styles,
  };

  // Генерация и экспорт PDF-файла
  var pdf = pdfMake.createPdf(documentDefinition);

  // pdf.download(tableData.name);
  // pdf.open();
  return pdf;
};
