import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SP_TABLE} from "../../../../../Requests/RequestRegister/RequestTable/utils/constants";
import {fetchAllStockPoolsNew} from "../../../../redux/operations";
import {selectAppointedStockPools} from "../../../../redux/selectors";
import {makeStockPoolsTableData} from "../../constants";

const useDistributeStockPoolTable = ({setSelectedForCancel}) => {
  const dispatch = useDispatch();
  const appointedStockPools = useSelector(selectAppointedStockPools);

  const columns = useColumns(SP_TABLE);
  const defaultData = makeStockPoolsTableData(appointedStockPools);

  function onAppointedStockPoolSelect(stockPoolId) {
    if (stockPoolId !== null) {
      dispatch(fetchAllStockPoolsNew({window: "Appointed", stockPoolIds: stockPoolId}));
    }
    if (stockPoolId === null) {
      dispatch(fetchAllStockPoolsNew({window: "Appointed"}));
    }
    setSelectedForCancel(stockPoolId);
  }

  return {columns, defaultData, onAppointedStockPoolSelect};
};

export default useDistributeStockPoolTable;
