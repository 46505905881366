import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import {accessAllowed} from "../../gitIgnore/accessAllowedIds";
import {getCurrentUser} from "../../slices/UserSlice";

const AccessBoundary = ({children}) => {
  const [access, setAccess] = useState(false);
  const user = useSelector(getCurrentUser);

  useEffect(() => {
    const currentUser = user?.id;

    if (accessAllowed.includes(currentUser)) {
      setAccess(true);
    } else {
      setAccess(false);
    }
  }, [user]);

  return <>{access ? children : null}</>;
};

AccessBoundary.propTypes = {children: PropTypes.node};

export default AccessBoundary;
