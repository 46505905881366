import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../Inputs";
import NewPdfViewer from "../../Project/Documents/DocumentView/NewPdfViewer";
import HeaderInputRow from "../common/HeaderInputRow/HeaderInputRow";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";

import useLogistCheckList from "./useLogistCheckList";

const withLogistCheckList = WrappedComponent => {
  const WithLogistCheckList = props => {
    const {
      headerInputNames,
      initialValues,
      orderTableColumns,
      curentSkuRequest,
      orderTableData,
      curentDoc,
      pdfFileArray,
    } = useLogistCheckList();
    const {t} = useTranslation();

    return (
      <>
        <div className="page_flex_container">
          <Formik initialValues={initialValues} enableReinitialize={true}>
            <Form className="w100 fcCol flexg1">
              <HeaderInputRow headerInputNames={headerInputNames} />
              <Divider />
              <section className="mb  w100 noShrink">
                <div className="dg col3 gap">
                  <div className="fcCol gap0_5 aistr">
                    <div>
                      <TextInput label={`1. ${t("Departure place")}`} name="departurePlaceName" autolabel readOnly />
                      <TextAreaInput name="departurePlaceAddress" height="4.6em" readOnly />
                    </div>

                    <div>
                      <TextInput label={`2. ${t("Delivery place")}`} name="deliveryPlaceName" autolabel readOnly />
                      <TextAreaInput name="deliveryPlaceAddress" height="4.6em" readOnly />
                    </div>
                    <TextAreaInput
                      label={`3. ${t("Group and Type")}:`}
                      name="groupAndTypeInfo"
                      areaHeight="4.6em"
                      readOnly
                    />
                    <div style={{height: "100%"}} className="mt">
                      <CheckListModalsTodo
                        tableheight={"13.5em"}
                        path={"order"}
                        entityId={curentSkuRequest?.orderId || null}
                      />
                    </div>
                  </div>
                  <div className="fcCol gap aistr jcsb">
                    <WrappedComponent {...props} />
                  </div>
                  <div className="pb1_5" style={{height: "34em"}}>
                    <NewPdfViewer fileArray={pdfFileArray} hiding={curentDoc?.id === -1} />
                  </div>
                </div>
              </section>
              <ReactTable
                columns={orderTableColumns}
                defaultData={orderTableData}
                className="w100 pb tableTac"
                style={{height: "15em", flexGrow: 1}}
              />
            </Form>
          </Formik>
        </div>
      </>
    );
  };
  return WithLogistCheckList;
};

export default withLogistCheckList;
