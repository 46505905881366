import PropTypes from "prop-types";

import ReactTable from "../../../../../conteiners/ReactTable";

import useTemplatesTableService from "./useTemplatesTableService";

import styles from "../../modTempates.module.scss";

const TemplatesTable = () => {
  const {templatesTableColumns, templatesTableData, onTemplatesTableRowSelect, currentConditionId} =
    useTemplatesTableService();

  return (
    <ReactTable
      className={styles.temlatesTableWrapper}
      columns={templatesTableColumns}
      defaultData={templatesTableData}
      onSelect={onTemplatesTableRowSelect}
      current={currentConditionId}
    />
  );
};

TemplatesTable.propTypes = {currentConditionId: PropTypes.number};

export default TemplatesTable;
