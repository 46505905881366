import {apiUrl} from "../../../../../api/baseURL";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import cancel from "../cancel.png";

const ViewRow = ({setFieldValue, values, data, columns, chengeable}) => {
  const handleDeleteImage = image => {
    setFieldValue(
      "addedOverallView",
      values.addedOverallView.filter(item => item !== image),
    );
  };

  return (
    <>
      {data.images?.length > 0 ? (
        <tr>
          <td
            colSpan={columns.length}
            style={{
              borderBottom: "0",
              borderLeft: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: data.images.some(item => item.includes("_view_")) ? "center" : "left",
                marginLeft: "21px",
              }}
            >
              {data.images.map((image, index) => (
                <div key={index} style={{position: "relative", marginLeft: "5px"}}>
                  <img src={apiUrl + "/" + image} style={{width: "100px", height: "100px"}} />
                  {chengeable && (
                    <ImageButton
                      src={cancel}
                      alt="cancel"
                      width={1}
                      height={1}
                      onClick={() => handleDeleteImage(image)}
                      style={{
                        position: "absolute",
                        border: "0",
                        background: "transparent",
                        cursor: "pointer",
                        right: "-5px",
                        top: "1px",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default ViewRow;
