import {useTranslation} from "react-i18next";

import {TERMS_STATUS_OPTIONS} from "../constants";

const useTermsStatusOptionsService = () => {
  const {t} = useTranslation();

  return Object.values(TERMS_STATUS_OPTIONS).map(option => {
    const translatedOption = t(option);
    return {title: translatedOption, value: translatedOption};
  });
};

export default useTermsStatusOptionsService;
