import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import useToaster from "../../../../utilities/hooks/useToaster";
import {getError} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SPLIT_TYPES} from "../../../Requests/OrderProcessing/utils/splitTypes";
import {deselectFetchedStockPool, deselectOrderSkus, deselectSkuRequest} from "../../redux/LogistSlice";
import {fetchAllStockPoolsNew, fetchStockPoolBySkuRequestId, splitDefectedOrder} from "../../redux/operations";
import {selectFetchedStockPool, selectSplitedOrder, selectWarehouseRequests} from "../../redux/selectors";
import CheckSkuStorageModal from "../CheckSkuStorageModal/CheckSkuStorageModal";
import SplitDefectedOrderModal from "../SplitDefectedOrderMod/SplitDefectedOrderMod";
import WarehouseSplitCreateModal from "../WarehouseSplitCreateModal/WarehouseSplitCreateModal";

import {CHOISE_OF_TRANFSORMATION_TABLE_COLUMNES} from "./constants";

const ChoiseOfTransformationMod = ({setModalActive, checkedSp, selectedWarehouse, setCheckedSp}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const requests = useSelector(selectWarehouseRequests);
  const selectedSkuRequestOrder = useSelector(selectFetchedStockPool);

  const TRANSF_TYPES = Object.freeze({
    SPLIT_CREATE: "reformatting",
    CHECK_SKU_STORAGE: "separation",
    SPLIT_DEFECTED_ORDER: "defective",
  });

  const [innerModalActive, setInnerModalActive] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [radioValue, setRadioValue] = useState(TRANSF_TYPES.SPLIT_CREATE);

  function updateAndCleanAfterRequest() {
    dispatch(deselectOrderSkus());
    setModalActive(false);
  }

  const INNER_TYPE_MODALS = {
    SPLIT_CREATE: {
      Component: WarehouseSplitCreateModal,
      label: t("reformatting"),
      props: {selectedWarehouse: selectedWarehouse, className: "modal_sizes"},
    },
    CHECK_SKU_STORAGE: {
      Component: CheckSkuStorageModal,
      label: t("SKU defect designation in the order"),
      props: {style: {height: "70vh"}, selectedOrderId: selectedSkuRequestOrder?.order?.orderId || null},
    },
    SPLIT_DEFECTED_ORDER: {
      Component: SplitDefectedOrderModal,
      label: t("Order split"),
      props: {updateAndCleanAfterRequest: updateAndCleanAfterRequest, isStatus: SPLIT_TYPES.DEFECTED_ORDER},
    },
  };

  useEffect(() => {
    dispatch(fetchAllStockPoolsNew({stockPoolIds: checkedSp?.join(",")}));

    return () => {
      dispatch(deselectSkuRequest());
      dispatch(deselectFetchedStockPool());
    };
  }, []);

  const orderTableColumnes = useColumns(CHOISE_OF_TRANFSORMATION_TABLE_COLUMNES);

  const orderTableData = useMemo(() => {
    return requests?.map(({order, skuRequestId}, index) => ({
      id: skuRequestId,
      number: index + 1,
      orderNumber: order?.orderNumber,
    }));
  }, [requests]);

  const areBtnsDisabled = radioValue === TRANSF_TYPES.DEFECTIVE_ORDER ? !selectedSkuRequestOrder : !radioValue;

  const onRadioChange = e => {
    setRadioValue(e.target.value);
  };

  const onOrderSelect = id => {
    if (!id || selectedSkuRequestOrder?.skuRequestId === id) {
      dispatch(deselectFetchedStockPool());
      return;
    }

    dispatch(fetchStockPoolBySkuRequestId(id));
  };

  const onChoose = () => {
    switch (radioValue) {
      case TRANSF_TYPES.SPLIT_CREATE:
        setCurrentModal(INNER_TYPE_MODALS.SPLIT_CREATE);
        break;
      case TRANSF_TYPES.CHECK_SKU_STORAGE:
        if (!selectedSkuRequestOrder) {
          getError("Select an order from the table below first");
          return;
        }
        setCurrentModal(INNER_TYPE_MODALS.CHECK_SKU_STORAGE);
        break;
      case TRANSF_TYPES.SPLIT_DEFECTED_ORDER:
        if (selectedSkuRequestOrder?.sku?.length > 1) {
          dispatch(splitDefectedOrder(selectedSkuRequestOrder?.skuRequestId)).then(res => {
            if (res.meta.requestStatus === "fulfilled") {
              toast.success(t("Request before split"));
              setCurrentModal(INNER_TYPE_MODALS.SPLIT_DEFECTED_ORDER);
            }
          });
        } else {
          Swal.fire({
            title: "",
            text: `${t("split warn")}`,
            icon: "info",
            confirmButtonText: t("Continue"),
          });
        }
        break;
    }

    setInnerModalActive(true);
  };

  const onCancel = () => {
    setRadioValue(null);
  };

  return (
    <>
      <div className="fcCol jcc aic w100">
        <h2>{t("Choose a transformation type for SP №") + requests[0]?.stockPool?.stockPoolNumber}</h2>
        <ul className="mb2x list fcCol gap aist" style={{width: "40%"}}>
          <li>
            <label className="fc gap aic">
              <input
                type="radio"
                name="transfType"
                value={TRANSF_TYPES.SPLIT_CREATE}
                onChange={onRadioChange}
                checked={radioValue === TRANSF_TYPES.SPLIT_CREATE}
              />
              {t("SP Reformatting at the Warehouse")}
            </label>
          </li>
          <li>
            <label className="fc gap aic">
              <input
                type="radio"
                name="transfType"
                value={TRANSF_TYPES.CHECK_SKU_STORAGE}
                onChange={onRadioChange}
                checked={radioValue === TRANSF_TYPES.CHECK_SKU_STORAGE}
              />
              {t("Mark defective SKU")}
            </label>
          </li>
          <li>
            <label className="fc gap ">
              <input
                type="radio"
                name="transfType"
                value={TRANSF_TYPES.SPLIT_DEFECTED_ORDER}
                onChange={onRadioChange}
                checked={radioValue === TRANSF_TYPES.SPLIT_DEFECTED_ORDER}
              />
              {selectedSkuRequestOrder
                ? t("Separation of the Order №") + selectedSkuRequestOrder?.order?.orderNumber
                : t("Separation of the Order №")}
            </label>
          </li>
        </ul>
        <ReactTable
          className="tableTac mb2x"
          columns={orderTableColumnes}
          defaultData={orderTableData ?? []}
          current={selectedSkuRequestOrder?.skuRequestId}
          // current={selectedOrderId}
          onSelect={onOrderSelect}
          style={{width: "40rem", height: "12rem"}}
          loading={false}
        />
        <div className="fc jcc gap2x aibl">
          <FunctionButton name="Choose" onClick={onChoose} autoWidth disabled={areBtnsDisabled} />
          <FunctionButton name="Cancel" onClick={onCancel} disabled={!radioValue} autoWidth />
        </div>
      </div>

      {currentModal ? (
        <ModalForm
          closable={true}
          Component={currentModal.Component}
          label={currentModal.label}
          active={innerModalActive}
          setModalActive={setInnerModalActive}
          {...(currentModal.props ?? {})}
          onModalClose={() => {
            setModalActive(false);
            setCheckedSp([]);
          }}
          className="modal_sizes"
        />
      ) : null}
    </>
  );
};

export default ChoiseOfTransformationMod;
