import React from "react";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

export default function ToolTip({children, title, zIndex, bottom = false, ...props}) {
  const {t} = useTranslation();

  return (
    <Tooltip
      {...props}
      title={typeof title === "string" ? t(title) : title}
      arrow
      placement={bottom ? "bottom" : "top"}
      PopperProps={{
        sx: {
          zIndex: zIndex ?? "100000",
          "& .MuiTooltip-tooltip": {
            backgroundColor: "white",
            color: "#426BA6",
            border: " 1px solid #426ca65e",
            fontSize: "0.9em",
            fontWeight: "300",
            zIndex: "100000",
          },
          "& .MuiTooltip-arrow": {
            color: "#426ca65e",
            zIndex: "100000",
          },
          "& .MuiTooltip-popper": {
            zIndex: "100000",
          },
        },
      }}
    >
      <div>{React.Children.only(children)}</div>
    </Tooltip>
  );
}

ToolTip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  bottom: PropTypes.bool,
  props: PropTypes.array,
};
