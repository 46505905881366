import React from "react";
import {useTranslation} from "react-i18next";

const OneRowTable = props => {
  const {data} = props;
  const {t} = useTranslation();
  const tHeadRender = () => {
    var ths = [];
    var i = 0;
    for (var prop in data) {
      ths.push(<th key={i}>{t(prop)}</th>);
      i++;
    }
    return ths;
  };
  const rowRender = () => {
    var cells = [];
    var i = 0;
    for (var prop in data) {
      cells.push(<td key={i}>{data[prop]}</td>);
      i++;
    }
    return <tr>{cells}</tr>;
  };
  return (
    <div className={`project_list `} style={props.style}>
      <table className={`project_table autoLayout tableTac`}>
        <thead>
          <tr>{tHeadRender()}</tr>
        </thead>
        <tbody>{rowRender()}</tbody>
      </table>
    </div>
  );
};

export default OneRowTable;
