import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import moment from "moment";
import Swal from "sweetalert2";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getSuccess} from "../../../../utilities/toasts";
import {DateInput} from "../../../Inputs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import {
  deselectCurrentRequest,
  fetchRequests,
  fetchRequestStockPoolUpdated,
  getCheckedRequestIds,
  getRequestsInStockPool,
  removeFromStockPool,
  selectTransportDoc,
  sendToLogistics,
} from "../../RequestsSlice";
import ModalStockPoolTable from "../StockPoolTable/ModalStockPoolTable";

import StockPoolRegistrDocs from "./StockPoolRegistrDocs";

const StockPoolRegistrModal = ({stockPool, setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequestIds = useSelector(getCheckedRequestIds);

  const requestsInStockPool = useSelector(getRequestsInStockPool);

  const currentTransportDoc = useSelector(selectTransportDoc);

  const areRequestsChecked = currentRequestIds?.length > 0;

  const onSendToLogistics = date => {
    // console.log("date", date);
    Swal.fire({
      title: "",
      text: t("tranf to log warn"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(sendToLogistics({stockPoolId: stockPool.stockPoolId, data: {shipmentDate: date}})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getSuccess(t("transf to log success"));
            dispatch(fetchRequests({window: "Requests in SP"}));
            setModalActive(false);
            dispatch(deselectCurrentRequest());
          }
        });
      }
    });
  };

  const onRemoveFromStockPool = () => {
    if (currentRequestIds.length === requestsInStockPool.length) {
      Swal.fire({
        title: "",
        text: t("all reqs remove warn"),
        icon: "error",
      });
      return;
    }
    dispatch(removeFromStockPool({stockPoolId: stockPool.stockPoolId, requestIds: currentRequestIds})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getSuccess(t("remove from sp success"));
        dispatch(fetchRequestStockPoolUpdated(stockPool.stockPoolId));
        dispatch(fetchRequests({window: "Requests in SP"}));
        dispatch(fetchRequests({window: "Paid requests"}));
      }
    });
  };

  return (
    <div className="pt pb w100">
      <div className="stockPoolTable__container mb" style={{height: "10em"}}>
        <ModalStockPoolTable stockPool={stockPool} />
      </div>
      <div className="fc jcsb" style={{height: "37em"}}>
        <StockPoolRegistrDocs stockPool={stockPool} currentTransportDoc={currentTransportDoc} />
        <div className="fcCol h100 jcsb">
          <FunctionButton
            name={"removefrom sp"}
            onClick={onRemoveFromStockPool}
            disabled={!areRequestsChecked}
            tooltipMessage="remove from sp message"
            style={{width: "8em", height: "auto"}}
          />
          <Formik
            initialValues={{shipmentDate: ""}}
            // validateOnChange
          >
            {({values}) => (
              <Form>
                <div className="flex_form gap mb2x" style={{marginTop: "auto"}}>
                  <div>{t("Ship")}</div>
                  <DateInput
                    name="shipmentDate"
                    flatpikrConfig={{
                      minDate: moment().add(1, "days").format("DD-MM-YYYY"),
                    }}
                  />
                </div>
                <FunctionButton
                  name={"transfer sp to log"}
                  onClick={() => {
                    onSendToLogistics(values.shipmentDate);
                  }}
                  style={{width: "8em", height: "auto"}}
                  disabled={values.shipmentDate === ""}
                />
              </Form>
            )}
          </Formik>
        </div>
        <div className="kpPreview__box" style={{width: "30%", height: "100%"}}>
          <NewPdfViewer fileArray={currentTransportDoc ? [currentTransportDoc?.fileUrl] : []} />
        </div>
      </div>
    </div>
  );
};

export default StockPoolRegistrModal;
