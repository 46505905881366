import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import DeleteIcon from "../../../images/icons/delete.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Divider from "../../../utilities/Divider/Divider";
import ToolTip from "../../../utilities/ToolTip";
import ReactTable from "../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import SingleFilePicker from "../../Inputs/SingleFilePicker";
import NewPdfViewer from "../../Project/Documents/DocumentView/NewPdfViewer";

import useLogModFdOrderAcceptance from "./useLogModFdOrderAcceptance";

const LogModFdOrderAcceptance = ({currentSkuRequestId, setModalTabs, setTabValue, checkedSkuIds, setCheckedSkuIds}) => {
  const {t} = useTranslation();

  const {
    initialValues,
    docTypeOptions,
    onMarkedSkusCheckedBtnClick,
    onCancelAcceptanceBtnClick,
    // onSaveDocBtnClick,
    onDelDocBtnClick,
    onDocAdd,
    pdfFileArray,
    skusTableColumns,
    skusTableData,
    docsTableColumns,
    docsTableData,
    // onSkusTableCheck,
    // isMarkedSkusCheckedBtnDisabled,
    onDocSelect,
    currentDocId,
    couldCheck,
  } = useLogModFdOrderAcceptance({currentSkuRequestId, setModalTabs, setTabValue, checkedSkuIds, setCheckedSkuIds});

  return (
    <div className="page_flex_container noGapDiv w100">
      <Formik initialValues={initialValues} enableReinitialize>
        {({values, setValues}) => (
          <Form className="fcCol w100 fGrow">
            <div className="row gap mb mt w100 jcsb">
              <TextInput label={t("Request №")} name={"requestNumber"} autolabel />
              <TextInput label={t("Order number")} name={"orderNumber"} autolabel />
              <TextInput label={t("Manager CO")} name={"managerCoName"} autolabel />
              <TextInput label={t("Manager FEA")} name={"managerFeaName"} />
            </div>
            <Divider />
            <div className="row gap fGrow w100 aistr mb">
              <ReactTable
                className="tableTac overflowX fGrow"
                enableMultiSelection
                setMultiSelect={setCheckedSkuIds}
                defaultMultiSelection={checkedSkuIds}
                columns={skusTableColumns}
                defaultData={skusTableData}
                loading={false}
                style={{height: "unset"}}
                disabledMultiSelection={!couldCheck}
              />
              <div className="column fGrow" style={{width: "40%"}}>
                <div className="kpPreview__box fGrow w100" style={{maxHeight: "400px"}}>
                  <NewPdfViewer fileArray={pdfFileArray} hiding={true} />
                </div>
                <div className="w100">
                  <div className="underlined mb0_5">{t("Documents") + ":"}</div>
                  <ReactTable
                    className="tableTac w100 overflowX"
                    columns={docsTableColumns}
                    defaultData={docsTableData}
                    style={{height: "8rem"}}
                    loading={false}
                    current={currentDocId}
                    onSelect={onDocSelect}
                  />
                </div>
              </div>
            </div>
            <div className="row gap jcsb w100">
              <FunctionButton
                name="marked skus"
                autoWidth
                onClick={onMarkedSkusCheckedBtnClick}
                disabled={!couldCheck}
              />
              <FunctionButton
                name="cancel acceptance"
                autoWidth
                onClick={onCancelAcceptanceBtnClick}
                disabled={couldCheck}
              />
              <Select
                label={t("Document type")}
                name="docType"
                options={docTypeOptions}
                width="13rem"
                autolabel
                optionsAbove
              />
              <DateInput label={t("date doc")} name="docDate" flatpikrConfig={{minDate: Date.now()}} />
              <TextInput label={t("№")} name={"docNumber"} autolabel />
              <TextInput label={t("Note")} name={"note"} autolabel />
              <div className="row">
                {/* <ImageButton src={SaveIcon} onClick={() => onSaveDocBtnClick(setValues)} disabled /> */}
                <ToolTip title={"Upload doc and save"}>
                  <SingleFilePicker
                    addFunc={file => onDocAdd({values, setValues, file})}
                    accept={".pdf"}
                    disabled={!values.docDate || !values.docNumber || !values.docType || currentDocId}
                  />
                </ToolTip>

                <ImageButton
                  src={DeleteIcon}
                  onClick={onDelDocBtnClick}
                  disabled={!currentDocId}
                  tooltipMessage="Delete doc"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LogModFdOrderAcceptance;
