import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import {BUTTON_COLORS} from "../../../utilities/Buttons/constants";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModalForm from "../../Modal/FormModal";
import {selectTransportDoc} from "../../Requests/RequestsSlice";
import ModCheckList from "../CheckLists/ModCheckList";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import OrderFullSection from "../common/OrderFullSection/OrderFullSection";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";

import CreatingTPMod from "./FactoryCreatingTP/CreatingTPMod";
import CargoInfo from "./utils/CargoInfo";
import CurentSPInfo from "./utils/CurentSPInfo";
import FactoryBlockFilter from "./utils/FactoryBlockFilter";
import FactoryInvoiceAndPackBlock from "./utils/FactoryInvoiceAndPackBlock";
import useFactoryBlock from "./useFactoryBlock";

export const FACTORY_BLOCK_MODAL_TYPES = Object.freeze({
  sp: "SP",
  tp: "TP",
  request: "REQUEST",
});

const FactoryBlock = () => {
  const {t} = useTranslation();

  const [factoryCreatingTPMod, setFactoryCreatingTPMod] = useState(false);

  const [selectedFactory, setSelectedFactory] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  // const [modalType, setModalType] = useState(FACTORY_BLOCK_MODAL_TYPES.sp);

  const {
    spColumns,
    reqColumns,
    requestsTableData,
    SPTableData,
    formInitialValues,
    onStockPoolCheck,
    onStockPoolSelect,
    currentSP,
    pdfFileArray,
    filterDisabled,
    onRqstsTableRowDoubleClick,
    isTransportationBtnDisabled,
    onFinelDestinationCheck,
    isFinalDestination,
  } = useFactoryBlock({setModalOpen});
  const currentTransportDoc = useSelector(selectTransportDoc);

  return (
    <PageContainer title={t("Process block factory")}>
      <div className="form_wrapper">
        <FactoryBlockFilter
          window={LOGIST_WINDOW_TYPES.FACTORY}
          disabled={filterDisabled}
          setSelectedFactory={setSelectedFactory}
        />
        <Divider />
        <Formik initialValues={formInitialValues} enableReinitialize={true}>
          <Form className="w100 fcCol flexg1 mb">
            <OrderFullSection
              fileArray={pdfFileArray}
              firstColumn={
                <div className="fdc gap">
                  <CurentSPInfo
                    onFinelDestinationCheck={onFinelDestinationCheck}
                    isFinalDestination={isFinalDestination}
                  />

                  <CheckListModalsTodo
                    tableheight={"10em"}
                    path={"stockPool"}
                    entityId={currentSP?.stockPoolId || null}
                  />
                  <div className="fc jcsb mt nowrap">
                    <FunctionButton name={"Finances"} disabled color={BUTTON_COLORS.RED} />
                    <FunctionButton name={"Transformation"} color={BUTTON_COLORS.RED} disabled />
                    <FunctionButton
                      name={"Transportation"}
                      disabled={isTransportationBtnDisabled}
                      onClick={() => {
                        if (selectedFactory?.value === "" || !selectedFactory) return;
                        setFactoryCreatingTPMod(true);
                      }}
                    />
                  </div>
                </div>
              }
              secondColumn={
                <>
                  <FactoryInvoiceAndPackBlock currentTransportDoc={currentTransportDoc} element={currentSP} />
                  <div>{`4. ${t("Cargo")}:`}</div>
                  <CargoInfo />
                </>
              }
            />
          </Form>
        </Formik>
        <div className="fdr w100 jcsb gap h100 mb" style={{overflow: "auto"}}>
          <ReactTable
            className="tableTac"
            columns={spColumns}
            defaultData={SPTableData}
            setMultiSelect={onStockPoolCheck}
            enableMultiSelection={true}
            onSelect={onStockPoolSelect}
            current={currentSP?.stockPoolId || null}
            style={{width: "28%"}}
            // onRowDoubleClick={onSpTableRowDoubleClick}
          />

          <ReactTable
            className="tableTac overFlowAuto"
            columns={reqColumns}
            defaultData={requestsTableData}
            onRowDoubleClick={onRqstsTableRowDoubleClick}
          />
        </div>
      </div>
      <ModalForm
        closable={true}
        Component={CreatingTPMod}
        label={t("Creating (adding) to the TP")}
        active={factoryCreatingTPMod}
        setModalActive={setFactoryCreatingTPMod}
        selectedFactory={selectedFactory}
        identifier="factory"
        className="modal_sizes"
      />
      {/* <LogModRqstCheckListMC
        modalShow={isModalOpen}
        setModalShow={setModalOpen}
        type={modalType}
        selectedSP={currentSP}
        modalTypes={FACTORY_BLOCK_MODAL_TYPES}
      /> */}
      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
    </PageContainer>
  );
};

export default FactoryBlock;
