import {useCallback, useRef, useState} from "react";

export const useList = getItems => {
  const [items, setItems] = useState([]);

  const onRequest = useCallback(() => {
    // console.log("WE uSE OnREquest in list.hook")
    getItems().then(data => {
      onItemsLoaded(data);
    });
  }, [getItems]);

  const onItemsLoaded = newItems => {
    setItems(newItems);
    // setProcess('confirmed')
  };

  const onNewItemAdded = useCallback(newItem => {
    setItems(items => [newItem, ...items]);
  }, []);

  const getId = id => {
    var listId;
    items.forEach((item, i) => {
      if (item.id === id) {
        listId = i;
      }
    });
    return listId;
  };

  const onItemDel = itemId => {
    var id = getId(itemId);
    var deleteItem = items[id];
    setItems(items.filter(i => i !== deleteItem));
  };

  const onUpdate = data => {
    setItems(
      items.map(item => {
        if (item.id === data.id) {
          return {...item, ...data};
        } else return item;
      }),
    );
  };

  const formThs = useCallback(thList => {
    var ths = [];
    var i = 0;
    for (var prop in thList) {
      ths.push(
        <th key={i} width={thList[prop] + "%"}>
          {prop}
        </th>,
      );
      i++;
    }
    return ths;
  }, []);

  const formTds = (data, maxLength) => {
    var tds = [];
    var i = 0;
    for (var prop in data) {
      tds.push(
        <td title={data[prop]} key={i}>
          {data[prop].length > maxLength ? data[prop].slice(0, maxLength) + "..." : data[prop]}
        </td>,
      );
      i++;
    }
    return tds;
  };

  function formTableContent(itemss, formatStatus, onChange, itemRefs) {
    if (itemss) {
      return itemss.map((item, i) => {
        const statusInfo = formatStatus(item.status);
        const tds = formTds(item, 55);
        return (
          <tr
            style={{color: statusInfo[1]}}
            key={item.id}
            onClick={() => {
              onChange(item.id);
            }}
            ref={el => (itemRefs[i] = {element: el, itemId: item.id})}
          >
            {tds.slice(0, -1)}
            <td>{statusInfo[0]}</td>
          </tr>
        );
      });
    }
  }

  const itemRefs = useRef([]);
  const focusOnItem = useCallback(id => {
    itemRefs.current.forEach(item => {
      item.classList.remove("item_selected");
    });
    itemRefs.current[id].classList.add("item_selected");
  }, []);

  return {
    items,
    formTableContent,
    onRequest,
    itemRefs,
    focusOnItem,
    onNewItemAdded,
    setItems,
    formThs,
    formTds,
    onItemDel,
    onUpdate,
  };
};

export default useList;
