import {useTranslation} from "react-i18next";
import {ErrorMessage, useFormikContext} from "formik";
import PropTypes from "prop-types";

import delIcon from "../../../../../images/icons/delete.png";
import saveIcon from "../../../../../images/icons/save.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../../../utilities/ToolTip";
import {TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

export const SETTINGS_CONTACTS_BLOCK_TYPES = Object.freeze({WAREHOUSE: "warehouse", TRANSPORTER: "transporter"});

const SettingsContacts = ({
  contactNumberOptions,
  type,
  onAddMoreContactsBtnClick,
  onSaveContactsBtnClick,
  onDeleteContactsBtnClick,
  onContactNumberChange,
  disabled = false,
}) => {
  const {t} = useTranslation();
  const {setValues, values} = useFormikContext();

  const fieldsDisabled =
    disabled ||
    values?.contactNumber === "" ||
    !values?.contactNumber ||
    (values?.contactNumber && values?.contactNumber !== "" && !values["contactNumber"].toString().includes("temp#"));

  return (
    <div>
      <div className="row">
        <Select
          label={type === SETTINGS_CONTACTS_BLOCK_TYPES.WAREHOUSE ? t("Warehouse contacts") : t("Transporter contacts")}
          name="contactNumber"
          options={contactNumberOptions}
          width="5rem"
          labelClassName="underlined"
          makeEffect={option => onContactNumberChange({option, setValues})}
          readOnly={disabled}
          selectOnly={true}
        />
        <FunctionButton name="Add more..." autoWidth onClick={onAddMoreContactsBtnClick} disabled={disabled} />
      </div>
      <TextInput
        readOnly={fieldsDisabled}
        label={t("Title")}
        name={type === SETTINGS_CONTACTS_BLOCK_TYPES.WAREHOUSE ? "warehouseName" : "transporterName"}
        autolabel
      />
      <TextInput
        readOnly={fieldsDisabled}
        label={t("Address")}
        name={type === SETTINGS_CONTACTS_BLOCK_TYPES.WAREHOUSE ? "warehouseAddress" : "transporterAddress"}
        autolabel
      />
      <TextInput readOnly={fieldsDisabled} label={t("Full name")} name={"contactName"} autolabel />
      <div className="row relative">
        <TextInput readOnly={fieldsDisabled} label={t("email")} name={"email"} autolabel />
        <ErrorMessage name="email" component="div" className="error" />
      </div>
      <div className="row relative">
        <ToolTip title={t("11 digits only, starts with + : +80956751150")}>
          <TextInput readOnly={fieldsDisabled} label={t("phone")} name="phone" generalClassName="fGrow" autolabel />
        </ToolTip>

        <ErrorMessage name="phone" component="div" className="error" />
        <div className="row gap0_5">
          <ImageButton
            src={saveIcon}
            alt="save"
            width={2}
            height={1.3}
            onClick={onSaveContactsBtnClick}
            disabled={fieldsDisabled}
            tooltipMessage="Save new contact"
          />
          <ImageButton
            src={delIcon}
            alt="delete"
            width={1.2}
            height={1.2}
            onClick={onDeleteContactsBtnClick}
            disabled={
              disabled ||
              values?.contactNumber === "" ||
              !values?.contactNumber ||
              (values?.contactNumber &&
                values?.contactNumber !== "" &&
                values["contactNumber"].toString().includes("temp#"))
            }
            tooltipMessage="Delete selected contact"
          />
        </div>
      </div>
    </div>
  );
};

SettingsContacts.propTypes = {
  contactNumberOptions: PropTypes.array.isRequired,
  type: PropTypes.oneOf(Object.values(SETTINGS_CONTACTS_BLOCK_TYPES)).isRequired,
  onAddMoreContactsBtnClick: PropTypes.func,
  onSaveContactsBtnClick: PropTypes.func,
  onDeleteContactsBtnClick: PropTypes.func,
  onContactNumberChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SettingsContacts;
