export const SHIPMENT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Place number",
    accessorKey: "placeNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "lwh (m)",
    accessorKey: "lwh",
    size: 70,
    isSortable: true,
  },
  {
    header: "volume (m3)",
    accessorKey: "volume",
    size: 70,
    isSortable: true,
  },
  {
    header: "weight (kg)",
    accessorKey: "weight",
    size: 100,
    isSortable: true,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 100,
  },
]);

export const LOGIST_WINDOWS = Object.freeze({
  INCOMING: "Incoming",
  APPOINTED: "Appointed",
  BOSS: "Boss",
  DISTRIBUTED: "Distributed",
  FACTORY_CREATING_CARGO: "Factory Creating Cargo",
  FACTORY_CARGO: "Factory Cargo",
  TRANSPORTER: "Transporter",
  WAREHOUSE_COMING: "Warehouse coming",
  WAREHOUSE: "Warehouse",
  WAREHOUSE_CREATING_STOCKPOOL: "Warehouse Creating StockPool",
  WAREHOUSE_STOCKPOOL: "Warehouse StockPool",
  WAREHOUSE_CREATING_CARGO: "Warehouse Creating Cargo",
  WAREHOUSE_CARGO: "Warehouse Cargo",
  FINAL_DESTINATION_BOSS: "Final boss",
  FINAL_DESTINATION_MANAGER: "Final",
});
