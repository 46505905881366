import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import moment from "moment";
import * as Yup from "yup";

import plusIcon from "../../images/icons/plus.png";
import saveIcon from "../../images/icons/save.png";
import {BUTTON_TYPES} from "../../utilities/Buttons/constants";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import {formatStatus} from "../../utilities/CirculeStatus";
import {STATUS_COLORS} from "../../utilities/colorConstants";
import ControlFieldsChanges from "../../utilities/ControllChanges";
import {dateFormat} from "../../utilities/dateFormat";
import {calcStatusColorViaPlannedDate} from "../../utilities/DateStatusCircle";
import ToolTip from "../../utilities/ToolTip";
import ReactTable from "../conteiners/ReactTable";
import TableWithSelect from "../conteiners/TableWithSelect";
import {DateInput, TextInput} from "../Inputs";
import useColumns from "../Requests/OrderProcessing/utils/hooks/useColumns";

import {remindCreated, remindUpdated, resetReminds, selectRemind} from "./RemindsSlice";

import "../../Styles/Reminder.scss";

const ReminderHoc = props => {
  const {t} = useTranslation();

  const {forcedUpdate, height, setGlobalSave, create, update, loading, disabled} = props;

  const {reminds, currentRemind} = useSelector(state => state.reminds);

  const dispatch = useDispatch();

  const ths = useMemo(() => {
    return {
      "№": 5,
      [t("Date")]: 20,
      [t("Notes")]: 50,
      "D-day": 20,
      [t("Status")]: 5,
    };
  }, []);

  // const columns = useColumns(REMINDER_COLUMNS);

  useEffect(() => {
    dispatch(resetReminds());
  }, []);

  const formatStatus = statusColor => {
    const textColor = STATUS_COLORS.GREY;

    return [
      <div key="radio-label" className="availability_circule_box">
        <div className="outer_circle">
          <div className="inner_circle" style={{backgroundColor: statusColor}}></div>
        </div>
      </div>,

      <span key="text-span" style={{color: textColor}}></span>,
    ];
  };

  const remindsTableField = useMemo(() => {
    return reminds.map((item, i) => {
      const color = calcStatusColorViaPlannedDate(item.dueDate, item.createdAt);

      return {
        todoNumber: i + 1,
        createdAt: dateFormat(item.createdAt),
        note: item.note,
        dueDate: dateFormat(item.dueDate),
        status: color,
        id: item.todoId,
      };
    });
  }, [reminds]);

  const onSaveNote = newnote => {
    const {note, dueDate} = newnote;
    if (!currentRemind) {
      create({
        note,
        dueDate,
      })
        .then(res => {
          dispatch(remindCreated(res));
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      update({
        todoId: currentRemind.todoId,
        data: {
          note,
          dueDate,
        },
      }).then(res => {
        dispatch(remindUpdated(res));
      });
    }
  };

  const onPlus = () => {
    dispatch(selectRemind(null));
    create({
      note: "",
      dueDate: dateFormat(new Date()),
    }).then(res => {
      dispatch(remindCreated(res));
    });
  };

  const onNewSelect = currentNote => {
    if (currentRemind && currentNote === currentRemind?.todoId) {
      dispatch(selectRemind(null));
      return;
    }
    dispatch(selectRemind(currentNote));
  };

  const initialValues = useMemo(() => {
    return {
      // number: reminds != null ? reminds.length + 1 : 1,
      todoId: "",
      note: "",
      // dueDate: moment().add(1, "days").format("DD.MM.YYYY"),
      dueDate: "",
      status: "",
    };
  }, []);

  // console.log("today", moment().add(1, "days").format("DD.MM.YYYY"));

  return (
    <div className="reminder" style={{height: height, overflowY: "hidden"}}>
      <div className="form_wrapper">
        <Formik
          initialValues={currentRemind || initialValues}
          enableReinitialize
          validateOnChange
          validationSchema={Yup.object({
            dueDate: Yup.date().required(t("Deadline")),
            note: Yup.string().required(t("ent note")),
          })}
          onSubmit={values => {
            onSaveNote({
              ...values,
              dueDate: values.dueDate,
            });
          }}
        >
          <Form className="reminder_form fc nowrap">
            {props.setGlobalSave && (
              <ControlFieldsChanges editFunc={setGlobalSave} forcedUpdate={forcedUpdate} updFunc={onSaveNote} />
            )}

            <div style={{width: "80%"}}>
              <TextInput
                autolabel
                label={t("TD notes")}
                name="note"
                className="reminder_note"
                autoComplete="off"
                width={"100%"}
              />
            </div>
            <DateInput
              name="dueDate"
              flatpikrConfig={{
                minDate: moment().add(1, "days").format("DD-MM-YYYY"),
              }}
            />
            {/* <ImageButton
              src={plusIcon}
              alt="add remind"
              onClick={onPlus}
              tooltipMessage="New entry"
              width={1.5}
              height={1.5}
              disabled={disabled}
            /> */}
            <ImageButton
              src={saveIcon}
              alt="save"
              type={BUTTON_TYPES.SUBMIT}
              width={2.2}
              height={1.5}
              style={{zIndex: "1000"}}
              disabled={disabled}
            />
          </Form>
        </Formik>
        {/* /////////список///////// */}
        <div className="reminder_table project_list" style={{height: "100%"}}>
          <TableWithSelect
            thsName={ths}
            items={remindsTableField}
            current={currentRemind?.todoId || null}
            onSelect={onNewSelect}
            loadingStatus={loading}
            formatStatus={formatStatus}
          />
          {/* <ReactTable
            columns={columns}
            defaultData={remindsTableField}
            current={currentRemind?.todoId || null}
            onSelect={onNewSelect}
            // loading={loading}
            //  formatStatus={formatStatus}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ReminderHoc;
