import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import downIcon from "../../images/icons/Caret_Circle_Down.png";
import upIcon from "../../images/icons/Caret_Circle_Up.png";

import ImageButton from "./ImageBtn";

const UpDownButtons = ({
  onUpClick,
  onDownClick,
  isUpBtnDisabled = false,
  isDownBtnDisabled = false,
  tooltipMessage = "distrib to mansgers",
}) => {
  const {t} = useTranslation();

  return (
    <div className="fc nowrap" style={{width: "6em"}}>
      <ImageButton
        src={downIcon}
        tooltipMessage={t(tooltipMessage)}
        onClick={onDownClick}
        size={2}
        disabled={isDownBtnDisabled}
      />
      <ImageButton src={upIcon} onClick={onUpClick} size={2} disabled={isUpBtnDisabled} />
    </div>
  );
};

UpDownButtons.propTypes = {
  onUpClick: PropTypes.func,
  onDownClick: PropTypes.func,
  isDownBtnDisabled: PropTypes.bool,
  isUpBtnDisabled: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

export default UpDownButtons;
