import {useState} from "react";
import {useTranslation} from "react-i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {StatusRectangleBtn} from "../../../utilities/Buttons/StatusFunctionButton/StatusRectangleBtn";
import PageContainer from "../../conteiners/PageContainer";
import ModalForm from "../../Modal/FormModal";
import ModCheckList from "../CheckLists/ModCheckList";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import LeftSideTable from "../common/LeftSideTable/LeftSideTable";
import FullLogistFiltersForm from "../common/LogistFilters/LogistFilters";
import LogistTable from "../common/LogistTable/LogistTable";

import TPRequestDistributionModal from "./../TransportPool/TPRequestDistribution/TPRequestDistributionModal";
import useTpListBossService from "./useTpListBossService";

export const TP_LIST_BOSS_MODAL_TYPES = Object.freeze({
  sp: "SP",
  tp: "TP",
  request: "REQUEST",
});

const TpListBoss = () => {
  const {t} = useTranslation();

  const [isBossModalOpen, setBossModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  // const [modalType, setModalType] = useState(TP_LIST_BOSS_MODAL_TYPES.sp);

  const {
    tpListBossTableData,
    onTpListBossTableRowSelect,
    isLoading,
    onFactoryBtnClick,
    onTransporterBtnClick,
    onStorageBtnClick,
    leftSideTableData,
    onLeftSideTableRowSelect,
    statusBtnColor,
    onStatusBtnClick,
    isStatusBtnDisabled,
    // onShowHistoryBtnClick,
    // initialValues,
    onModalClose,
    areFiltersDisabled,
  } = useTpListBossService({isBossModalOpen, setBossModalOpen});

  return (
    <PageContainer title={t("Request Register (chief)")}>
      <div className="page_flex_container mb h100">
        <div className="w100">
          <FullLogistFiltersForm window={LOGIST_WINDOW_TYPES.BOSS} disabled={areFiltersDisabled} />
          <div style={{position: "absolute", top: "4em", left: "1.8em"}}>
            <StatusRectangleBtn
              onClick={onStatusBtnClick}
              color={statusBtnColor}
              disabled={isStatusBtnDisabled}
              title="logist indicator"
            />
          </div>
          <div className="border border_gap2"></div>
        </div>

        {/* //  bugfix/logist
//         <div className="fc nowrap gap flexg1 w100 scrollY" style={{height: "clamp(450px, 500px, 600px"}}>
//  */}
        <div className="fdr w100 jcsb gap h100 mb" style={{overflow: "auto"}}>
          <LeftSideTable
            tableData={leftSideTableData}
            isLoading={isLoading}
            onSelect={onLeftSideTableRowSelect}
            // setModalType={setModalType}
            setModalOpen={setModalOpen}
          />
          <LogistTable
            tableData={tpListBossTableData}
            isLoading={isLoading}
            onSelect={onTpListBossTableRowSelect}
            setModalOpen={setModalOpen}
            // setModalType={setModalType}
          />
        </div>
        <div className="fc g2 w100 mb">
          <FunctionButton name="Factory" onClick={onFactoryBtnClick} disabled />
          <FunctionButton name="Transporter" onClick={onTransporterBtnClick} disabled />
          <FunctionButton name="Warehouse" onClick={onStorageBtnClick} disabled />
        </div>
      </div>
      {/* <Formik initialValues={initialValues}>
        <Form>
          <TextInput label={t("Request №")} name="reqNumber" generalClassName="mb2x ml10em" width="15em" />
          <div className="fc nowrap mb2x w100 gap2x">
            <FunctionButton name="Show history" onClick={onShowHistoryBtnClick} disabled autoWidth />
            <StagesScale />
          </div>
        </Form>
      </Formik> */}

      <ModalForm
        closable={true}
        Component={TPRequestDistributionModal}
        label={t("Request distribution to Logistician")}
        active={isBossModalOpen}
        setModalActive={setBossModalOpen}
        onModalClose={onModalClose}
      />
      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
      {/* <DynamicCheckListMC
        modalShow={isModalOpen}
        setModalShow={setModalOpen}
        type={modalType}
        selectedSP={selectedSP}
        modalTypes={TP_LIST_BOSS_MODAL_TYPES}
      /> */}
    </PageContainer>
  );
};

export default TpListBoss;
