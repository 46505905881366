import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getDocTypes} from "../../../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

const useDocTypesOptionsService = () => {
  const {i18n} = useTranslation();
  const docTypes = useSelector(getDocTypes);

  return (
    docTypes?.map(({docTypeId, nameEng, nameUkr}) => ({
      title: i18n === "eng" ? nameEng : nameUkr,
      value: docTypeId,
    })) ?? []
  );
};

export default useDocTypesOptionsService;
