// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TPRequestDistributionModal_centerTable__puh4K tr td {
  text-align: center;
}

.TPRequestDistributionModal_checkBoxPointer__8QTwb tr td input[type=checkbox] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Logist/TransportPool/TPRequestDistribution/TPRequestDistributionModal.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;;AAKE;EACE,eAAA;AAFJ","sourcesContent":[".centerTable {\n  tr td {\n    text-align: center;\n  }\n}\n\n.checkBoxPointer {\n  tr td input[type=\"checkbox\"] {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centerTable": `TPRequestDistributionModal_centerTable__puh4K`,
	"checkBoxPointer": `TPRequestDistributionModal_checkBoxPointer__8QTwb`
};
export default ___CSS_LOADER_EXPORT___;
