import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchAllWarehouseService} from "../../../redux/Warehouses/operations";
import {selectWarehouseServices} from "../../../redux/Warehouses/selectors";

const useWarehouseServicesOptions = warehouseId => {
  const services = useSelector(selectWarehouseServices);
  const dispatch = useDispatch();
  useEffect(() => {
    // if (!services || services?.length === 0) {

    dispatch(fetchAllWarehouseService({warehouseId}));
    // }
  }, [warehouseId]);

  const warehouseServicesOptions =
    services?.map(serv => ({title: serv?.name, value: serv.serviceId, price: serv.price})) || [];

  return warehouseServicesOptions;
};

export default useWarehouseServicesOptions;
