import {useState} from "react";

import {fetchList} from "../../../../hook/axios.hook";

const useStockPoolTablesWarehouse = ({warehouseId, window}) => {
  const [checkedSP, setCheckedSP] = useState([]);
  const [checkedSPRequests, setCheckedSPRequests] = useState(null);
  const [selectedSPRequests, setSelectedSPRequests] = useState(null);
  const [currentSPId, setCurrentSpId] = useState(null);

  function onStockPoolCheck(spList) {
    setCheckedSP(prev => {
      if (!prev.length && !spList.length) return spList;

      if (!spList.length) {
        fetchList(`/stockPools/getAll`, "get", {params: {window, warehouseId}}).then(res => {
          setCheckedSPRequests(res?.skuGroups ?? []);
        });
      } else {
        fetchList(`/stockPools/getAll`, "get", {params: {window, warehouseId, stockPoolIds: spList?.join(",")}}).then(
          res => {
            setCheckedSPRequests(res?.skuGroups ?? []);
          },
        );
      }

      return spList;
    });
  }

  function onStockPoolSelect(id) {
    if (!id) {
      fetchList(`/stockPools/getAll`, "get", {
        params: {
          window,
          warehouseId,
        },
      }).then(res => {
        setSelectedSPRequests(res?.skuGroups ?? []);
      });
    } else {
      fetchList(`/stockPools/getAll`, "get", {
        params: {
          window,
          stockPoolIds: id,
          warehouseId,
        },
      }).then(res => {
        setSelectedSPRequests(res?.skuGroups ?? []);
      });
    }

    setCurrentSpId(id);
  }

  return {
    onStockPoolSelect,
    onStockPoolCheck,
    checkedSPRequests,
    selectedSPRequests,
    checkedSP,
    currentSPId,
    setCheckedSP,
    setCheckedSPRequests,
  };
};

export default useStockPoolTablesWarehouse;
