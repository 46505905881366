import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {apiUrl} from "../../../api/baseURL";
import {dateFormat} from "../../../utilities/dateFormat";
import useSwal from "../../../utilities/hooks/useSwal";
import useToaster from "../../../utilities/hooks/useToaster";
import makeNameFromPersona from "../../../utilities/makeNameFromPersona";
import {ovhJoin} from "../../../utilities/ovhJoin";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import useDocRefs from "../../Requests/RequestsInProgress/ReceiptGoodsModal/utils/useDocRefs";
import {
  addTransportDoc,
  cancelAcceptanceRequestSku,
  deleteTransportDoc,
  markAsAcceptedRequestSku,
} from "../../Requests/RequestsSlice";
import {fetchStockPoolBySkuId} from "../redux/operations";
import {selectCurrentSkuRequest} from "../redux/selectors";

import OrderAcceptanceNo from "./OrderAcceptanceNo/OrderAcceptanceNo";
import OrderAcceptanceYes from "./OrderAcceptanceYes/OrderAcceptanceYes";
import {DOCS_TABLE_COLUMNS, SKUS_TABLE_COLUMNS} from "./constants";

const useLogModFdOrderAcceptance = ({
  currentSkuRequestId,
  setModalTabs,
  setTabValue,
  checkedSkuIds,
  setCheckedSkuIds,
}) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const Toaster = useToaster();
  const SWAL = useSwal();

  const currentSp = useSelector(selectCurrentSkuRequest);
  const [couldCheck, setCouldCheck] = useState(true);

  const [acceptedSku, setAcceptedSku] = useState(null);

  useEffect(() => {
    if (currentSkuRequestId) {
      dispatch(fetchStockPoolBySkuId(currentSkuRequestId));
    }
  }, [currentSkuRequestId]);

  useEffect(() => {
    if (currentSp && currentSp?.sku[0]?.isAccepted !== null) {
      setCouldCheck(false);
      const accepted = [];
      const notAccepted = [];
      currentSp?.sku?.forEach(sku => (sku.isAccepted ? accepted.push(sku) : notAccepted.push(sku)));
      setAcceptedSku(accepted);
      modalsOpen(accepted, notAccepted);
    }
  }, [currentSp]);

  useEffect(() => {
    if (acceptedSku?.length > 0) setCheckedSkuIds(acceptedSku?.map(sku => sku.skuRequestId));
  }, [acceptedSku]);

  // const [checkedSkuIds, setCheckedSkuIds] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(null);

  const currentDoc =
    currentSp?.request?.transportDocs?.find(doc => doc.docId === currentDocId) ??
    currentSp?.request?.proforms?.find(proforma => proforma.proformaId === currentDocId) ??
    null;
  const {docRefsOptions: docTypeOptions} = useDocRefs();
  const pdfFileArray = useMemo(() => {
    return currentDoc && (currentDoc.fileUrl || currentDoc.pdfFileUrl)
      ? [currentDoc.fileUrl ?? currentDoc.pdfFileUrl]
      : [];
  }, [currentDoc?.fileUrl, currentDoc?.pdfFileUrl]);

  const initialValues = {
    requestNumber: currentSp?.request?.requestNumber ?? "",
    orderNumber: currentSp?.order?.orderNumber ?? "",
    managerCoName: makeNameFromPersona({persona: currentSp?.order?.co?.managerCo?.persona, language}),
    managerFeaName: makeNameFromPersona({persona: currentSp?.request?.managerFea?.persona, language}),
    docType: currentDoc ? currentDoc?.docTypeId ?? 1 : "",
    docDate: currentDoc?.dateFrom ?? currentDoc?.updatedAt ?? "",
    docNumber: currentDoc?.docNumber ?? currentDoc?.proformaNumber ?? "",
    note: currentDoc?.note ?? "",
  };

  function imgcellRender(value) {
    const imgs = value.getValue();

    if (imgs) {
      return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <div key={value} style={{position: "relative"}}>
            <img src={apiUrl + "/" + imgs} alt={imgs} style={{width: "5em", height: "5em", marginRight: "2px"}} />
          </div>
        </div>
      );
    }
    return " - ";
  }

  const skusTableColumns = useColumns(SKUS_TABLE_COLUMNS);
  skusTableColumns.splice(
    4,
    0,
    {
      header: () => [t("Image")],
      accessorKey: "image1",
      cell: imgcellRender,
    },
    {
      header: () => [t("Image")],
      accessorKey: "image2",
      cell: imgcellRender,
    },
  );

  const skusTableData = useMemo(
    () =>
      currentSp?.sku?.map((skuObj, index) => {
        const {
          brand,
          skuRequestId,
          name,
          nameEng,
          amount,
          retailPrice,
          complectDesc,
          topDiscount,
          material,
          materialType,
          color,
          discount,
          listPrice,
          addedImages,
        } = skuObj;

        const ovh = ovhJoin(skuObj);
        return {
          id: skuRequestId,
          number: ++index,
          brandName: brand?.name ?? " - ",
          skuName: (language === "en" ? nameEng : name) ?? " - ",
          characteristics: [material, materialType, color].filter(item => !!item && item !== "").join("; "),
          ovh,
          image1: addedImages?.length ? addedImages[0] : "",
          image2: addedImages?.length >= 2 ? addedImages[1] : "",
          complectDesc: complectDesc ?? " - ",
          amount: amount ?? " - ",
          price: retailPrice ?? " - ",
          paymentSumm: (retailPrice * amount) / 1 ?? " - ",
          discount: discount ?? " - ",
          totalPaymentSum: listPrice ?? " - ",
          totalDiscount: topDiscount ?? " - ",
          payable: listPrice * (topDiscount ?? 1) ?? " - ",
        };
      }) ?? [],
    [currentSp],
  );

  const docsTableColumns = useColumns(DOCS_TABLE_COLUMNS);
  const transportDocsData =
    currentSp?.request?.transportDocs?.map(({docId, docNumber, dateFrom, docType, createdAt, note}, index) => {
      return {
        id: docId,
        number: ++index,
        dateFrom: dateFormat(dateFrom),
        docType: language === "en" ? docType.nameEng : docType.nameUkr,
        docNumber,
        docUploadDate: dateFormat(createdAt),
        note: note ?? " - ",
      };
    }) ?? [];
  const proformsData =
    currentSp?.request?.proforms?.map(({proformaId, proformaNumber, updatedAt, createdAt, note}, index) => {
      return {
        id: proformaId,
        number: index + transportDocsData.length + 1,
        dateFrom: dateFormat(updatedAt),
        docType: t("Proforma"),
        docNumber: proformaNumber,
        docUploadDate: dateFormat(createdAt),
        note: note ?? " - ",
      };
    }) ?? [];
  const docsTableData = [...transportDocsData, ...proformsData];

  const isMarkedSkusCheckedBtnDisabled = checkedSkuIds.length === 0;

  const onCancelAcceptanceBtnClick = () => {
    dispatch(cancelAcceptanceRequestSku(currentSkuRequestId)).then(res => {
      setModalTabs(prev => [prev[0]]);
      setCheckedSkuIds([]);
      setCouldCheck(true);
    });
  };

  const modalsOpen = (acceptedSku, notAcceptedSku) => {
    setModalTabs(prev => {
      if (acceptedSku.length > 0 && notAcceptedSku.length > 0) {
        return [
          prev[0],
          {
            label: `${t("Not accepted SKU act")}`,
            Component: OrderAcceptanceNo,
            props: {currentSkuRequestId: currentSkuRequestId, notAcceptedSku},
          },
          {
            label: `${t("Accepted SKU act")}`,
            Component: OrderAcceptanceYes,
            props: {currentSkuRequestId: currentSkuRequestId, acceptedSku},
          },
        ];
      }
      if (acceptedSku.length > 0 && notAcceptedSku.length === 0) {
        return [
          prev[0],
          {
            label: `${t("Accepted SKU act")}`,
            Component: OrderAcceptanceYes,
            props: {currentSkuRequestId: currentSkuRequestId, acceptedSku},
          },
        ];
      }
      if (notAcceptedSku.length > 0 && acceptedSku.length === 0) {
        return [
          prev[0],
          {
            label: `${t("Not accepted SKU act")}`,
            Component: OrderAcceptanceNo,
            onClose: () => {
              console.log("closed");
            },
            props: {currentSkuRequestId: currentSkuRequestId, notAcceptedSku},
          },
        ];
      }
      return [];
    });
  };

  function onMarkedSkusCheckedBtnClick() {
    if (!currentSp || !checkedSkuIds) return;

    const notAcceptedSkuIds = [];
    currentSp.sku.forEach(sku => {
      if (!checkedSkuIds.includes(sku.skuRequestId)) {
        notAcceptedSkuIds.push(sku.skuRequestId);
      }
    });

    dispatch(markAsAcceptedRequestSku({acceptedSkuIds: checkedSkuIds, notAcceptedSkuIds})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Checked");
        const {acceptedSku, notAcceptedSku} = resp.payload;
        modalsOpen(acceptedSku, notAcceptedSku);
        setTabValue(1);
      }
    });
  }

  function onSaveDocBtnClick() {
    if (!currentSp) return;
  }

  function onDelDocBtnClick() {
    if (!currentDocId) return;

    SWAL.confirm({
      confirmFunc: () => {
        dispatch(deleteTransportDoc(currentDocId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("Transport Doc deleted");
            dispatch(fetchStockPoolBySkuId(currentSkuRequestId));
            setCurrentDocId(null);
          }
        });
      },
    });
  }

  function onDocAdd({values, setValues, file}) {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.docNumber);
    formdata.append("docTypeId", values.docType);
    formdata.append("dateFrom", values.docDate);
    formdata.append("note", values.note);

    if (formdata.has("doc")) {
      const params = {requestId: currentSp.requestId};
      dispatch(addTransportDoc({params, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess("Transport Doc added");
          dispatch(fetchStockPoolBySkuId(currentSkuRequestId));
          setCurrentDocId(null);
          setValues(prev => ({
            ...prev,
            docType: "",
            docDate: "",
            docNumber: "",
            note: "",
          }));
        }
      });
    }
  }

  function onDocSelect(docId) {
    setCurrentDocId(docId);
  }

  return {
    initialValues,
    docTypeOptions,
    onMarkedSkusCheckedBtnClick,
    onCancelAcceptanceBtnClick,
    onSaveDocBtnClick,
    onDelDocBtnClick,
    onDocAdd,
    pdfFileArray,
    skusTableColumns,
    skusTableData,
    docsTableColumns,
    docsTableData,
    isMarkedSkusCheckedBtnDisabled,
    onDocSelect,
    currentDocId,
    couldCheck,
  };
};

export default useLogModFdOrderAcceptance;
