import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Swal from "sweetalert2";

import logoImg from "../../../../../images/icons/Logo_big.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ModalForm from "../../../../Modal/FormModal";
import {createSkuNumder, deselectSku} from "../../../SkuSlice";

import SkuCreateForm from "./SkuCreateForm";

const CreateSkuBtn = () => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const preCreateSku = () => {
    Swal.fire({
      title: t("Create Sku Info"),
      text: ``,
      imageUrl: logoImg,
      imageWidth: 300,
      imageHeight: 200,
      showCancelButton: true,
      confirmButtonText: t("Creation confirm"),
      cancelButtonText: t("Cancel"),
    }).then(res => {
      if (res.isConfirmed) {
        dispatch(deselectSku());
        dispatch(createSkuNumder()).then(() => {
          setShowModal(true);
        });
      }
    });
  };

  return (
    <>
      <FunctionButton name={t("Create SKU")} onClick={preCreateSku} />
      <ModalForm active={showModal} Component={SkuCreateForm} setModalActive={setShowModal} label={t("Create SKU")} />
    </>
  );
};

export default CreateSkuBtn;
