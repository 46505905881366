import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, useFormikContext} from "formik";

import {useDebouncedCallback} from "../../../../../hook/debounced";
import FilterButton from "../../../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../../../../utilities/checkEmptyFields";
import {dateFormat} from "../../../../../utilities/dateFormat";
import useSessionFilters from "../../../../../utilities/hooks/useSessionFilters";
import ChosenFiltersLine from "../../../../Requests/Filters/ChosenFiltersLine";
import {clearCurrentCarrier} from "../../../redux/Carriers/CarriersSlice";
import {fetchAllCarriers} from "../../../redux/Carriers/operations";
import {selectCarrierFilters} from "../../../redux/Carriers/selectors";
import {selectWarehouseFilters} from "../../../redux/selectors";
import {fetchAllWarehouses} from "../../../redux/Warehouses/operations";
import {clearCurrentWarehouse} from "../../../redux/Warehouses/WarehousesSlice";

import {LOG_SETTINGS_FILTERS_TYPES} from "./LogSettingsFilters";

/* eslint-disable react/display-name */
const withLogSettingsFilters = BaseComponent => {
  return props => {
    const {window, type} = props;

    const {
      t,
      i18n: {language},
    } = useTranslation();
    const dispatch = useDispatch();
    const {values, setValues} = useFormikContext();
    const {filters, setFilters, clearFilters, initialFilters} = useSessionFilters(window);

    const [areFiltersDefined, setAreFiltersDefined] = useState(!!filters);

    useEffect(() => {
      if (initialFilters) {
        setValues({...initialFilters});
        setAreFiltersDefined(true);
      }
    }, []);

    const [savedFilters, setSavedFilters] = useState({});
    useEffect(() => {
      const filters = JSON.parse(sessionStorage.getItem(window));
      if (filters && Object.keys(filters).length > 0) setSavedFilters(filters);
    }, [values]);

    //    const {brandsOptions, feaManagersOptions, coManagersOptions, requestStatusOptions} = useRequestFilters();
    // const countryOptions = useCountryIdOptionsService();
    const storedWarehouseFilters = useSelector(selectWarehouseFilters);
    const storedCarrierFilters = useSelector(selectCarrierFilters);
    const storedFilters =
      type === LOG_SETTINGS_FILTERS_TYPES.TRANSPORTER ? storedCarrierFilters : storedWarehouseFilters;

    const typeOptions =
      storedFilters?.types?.map(({typeId, engName, ukrName}) => ({
        title: language === "en" ? engName : ukrName,
        value: typeId,
      })) ?? [];
    const nameOptions =
      storedFilters?.names?.map(name => ({
        title: name,
        value: name,
      })) ?? [];
    const addressOptions =
      storedFilters?.addresses?.map(address => ({
        title: address,
        value: address,
      })) ?? [];
    const noteOptions =
      storedFilters?.notes?.map(note => ({
        title: note,
        value: note,
      })) ?? [];

    const filterNames = useMemo(
      () => ({
        typeId: {name: t("Type"), options: typeOptions},
        address: {name: t("Address"), options: addressOptions},
        name: {name: t("Title"), options: nameOptions},
        note: {name: t("Note"), options: noteOptions},
      }),
      [typeOptions?.length, addressOptions?.length, nameOptions?.length, noteOptions?.length],
    );

    const onFilter = newFilters => {
      const {typeId, address, name, note} = newFilters ?? values;

      const params = checkEmptyFields({
        typeId,
        address,
        name,
        note,
      });

      if (Object.keys(params).length > 0) {
        setAreFiltersDefined(true);
        delayedFilter(params);
        const result = {
          typeId,
          address,
          name,
          note,
        };
        setFilters(result);
        setSavedFilters(params);
      }
      if (newFilters && Object.keys(params).length === 0) {
        setAreFiltersDefined(false);
        delayedFilter(params);
        clearFilters();
        setSavedFilters(params);
      }
    };

    const updateFilterQuery = params => {
      switch (type) {
        case LOG_SETTINGS_FILTERS_TYPES.STORAGE:
          dispatch(clearCurrentWarehouse());
          dispatch(fetchAllWarehouses(params));
          break;
        case LOG_SETTINGS_FILTERS_TYPES.TRANSPORTER:
          dispatch(clearCurrentCarrier());
          dispatch(fetchAllCarriers(params));
          break;
      }
    };

    const delFilters = () => {
      updateFilterQuery({window});
      clearFilters();
      const nullValues = {
        typeId: "",
        address: "",
        name: "",
        note: "",
      };
      setValues(nullValues);
      setSavedFilters({});
      setAreFiltersDefined(false);
    };

    const delayedFilter = useDebouncedCallback(updateFilterQuery, 500);

    return (
      <Form style={{width: "100%", marginBottom: "10px"}}>
        <div className="fc jcfs aist w100 g1 autolabel nowrap ">
          <div style={{paddingRight: "2.5em"}}>
            <FilterButton
              onFilter={onFilter}
              delFilter={delFilters}
              questionTitle={t("Filter")}
              areFiltersDefined={areFiltersDefined}
              disabled={props.disabled}
            />
          </div>
          <BaseComponent {...props} disabled={props.disabled} />
        </div>
        <ChosenFiltersLine
          window={window}
          savedFilters={savedFilters}
          setSavedFilters={setSavedFilters}
          onFilter={onFilter}
          filterNames={filterNames}
        />
      </Form>
    );
  };
};

export default withLogSettingsFilters;
