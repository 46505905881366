export const SP_REQUEST_TABLE_FACTORY_CREATING_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Request status",
    accessorKey: "requestStatus",
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },

  {
    header: "Order № ",
    accessorKey: "orderNumber",
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
  },
  {
    header: "Logist",
    accessorKey: "logist",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Date of Readiness",
    accessorKey: "readinessDate",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "country",
  },
  {
    header: "Pf №",
    accessorKey: "proformaNumber",
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
  },
  {
    header: "Notes",
    accessorKey: "note",
  },
]);

export const SP_TABLE_FACTORY_CREATING_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
  },
]);

export const TP_TABLE_FACTORY_CREATING_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
  },
  {
    header: "Transport Pool №",
    accessorKey: "trPoolNumber",
  },
]);

export const TP_REQUEST_TABLE_FACTORY_CREATING_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Request status",
    accessorKey: "requestStatus",
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },

  {
    header: "Order № ",
    accessorKey: "orderNumber",
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
  },
  {
    header: "Logist",
    accessorKey: "logist",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Date of Readiness",
    accessorKey: "readinessDate",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "country",
  },
  {
    header: "Pf №",
    accessorKey: "proformaNumber",
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
  },
  {
    header: "Notes",
    accessorKey: "note",
  },
]);
