import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import {fetchRequests, getCurrentRequestId, getStockPoolInfo} from "../../RequestsSlice";
import StockPoolRegistrModal from "../StockPoolRegistrModal/StockPoolRegistrModal";

import useStockPoolTableService from "./StockPoolTableService";

import styles from "../../OrderProcessing/OrderBlock/OrderBlock.module.scss";

const StockPoolTable = ({spModalActive, setSpModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currStockPool = useSelector(getStockPoolInfo);
  const currentReqId = useSelector(getCurrentRequestId);

  const {
    stockPoolTableColumns,
    stockPoolTableData,
    onStockPoolTableSelect,
    // current, spId
  } = useStockPoolTableService();
  return (
    <>
      <ReactTable
        className="w100 scrollX"
        columns={stockPoolTableColumns}
        defaultData={stockPoolTableData}
        onSelect={onStockPoolTableSelect}
        current={currentReqId ?? null}
        loading={false}
      />
      <ModalForm
        closable={true}
        Component={StockPoolRegistrModal}
        stockPool={currStockPool}
        label={t("req reg in sp") + currStockPool?.stockPoolNumber}
        active={spModalActive}
        setModalActive={setSpModalActive}
        className={styles.order_split_modal}
        isStatus={true}
        // onModalClose={() => dispatch(fetchRequests({window: "Requests in SP"}))}
      />
    </>
  );
};

export default StockPoolTable;
