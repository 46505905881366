import React from "react";
import {useTranslation} from "react-i18next";

import PageContainer from "../conteiners/PageContainer";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";

import ActivesList from "./ActivesList";
import RemindsList from "./RemindsList";

import "./MainPage.scss";

const MainPage = () => {
  const {t} = useTranslation();
  return (
    <ErrorBoundary>
      <PageContainer title={``} loading={false}>
        <div className="form_wrapper">
          <div style={{display: "flex", justifyContent: "flex-end", height: "400px"}}>
            <div className="mainToDo" style={{}}>
              <label className="label">TODO:</label>
              <div className="mainToDo__container">
                <RemindsList />
              </div>
            </div>
            <div></div>
          </div>
          <div className="activeProjects" style={{height: "90%"}}>
            <label className="label">{t("Active projects")}</label>
            <div className="activeProjects__container" style={{height: "90%"}}>
              <ActivesList />
            </div>
          </div>
        </div>
      </PageContainer>
    </ErrorBoundary>
  );
};

export default MainPage;
