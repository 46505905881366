import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import QuestionItem from "../../../utilities/QuestionItem";
import {IDENTICAL_SKU_COLUMNS} from "../OrderProcessing/OrderBlock/utils/constants";
import {getIdenticalSkus, getIdenticalSkus3} from "../RequestsSlice";

import ReactTable from "./../../conteiners/ReactTable";
import useColumns from "./../OrderProcessing/utils/hooks/useColumns";

import styles from "../OrderProcessing/OrderBlock/OrderBlock.module.scss";

const SimilarSkuBlock = () => {
  const {t} = useTranslation();

  const identicalSkuColumns = useColumns(IDENTICAL_SKU_COLUMNS);
  const identicalSku = useSelector(getIdenticalSkus);
  const identicalSkuTableData = identicalSku?.map(({article, request}, index) => ({
    id: index,
    skuId: article,
    article: request.requestNumber,
  }));

  return (
    <div className={styles.blockHeaderRight} style={{height: "100%"}}>
      <div className="q_wrapper">
        <QuestionItem title={"same skus"} />
        <label className={styles.blockHeaderRightText}>{t("Similar SKU") + ":"}</label>
      </div>
      <ReactTable
        defaultData={identicalSkuTableData ?? []}
        columns={identicalSkuColumns}
        onSelect={() => {}}
        current={null}
        className={styles.identicalSkusTable}
        setSelectedItems={() => {}}
      />
    </div>
  );
};

export default SimilarSkuBlock;
