import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getCurrentSkuPlan} from "../../KpFinSlice";

const UpdateControl = ({forcedUpdate, updFunc}) => {
  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const {values, dirty, setFieldValue} = useFormikContext();

  useEffect(() => {
    if (dirty) {
      updFunc(values);
    }
  }, [forcedUpdate]);

  useEffect(() => {
    if (currentSkuPlan) {
      setFieldValue("nameCheck", currentSkuPlan?.nameCheck);
    }
  }, [currentSkuPlan?.nameCheck]);

  useEffect(() => {
    if (currentSkuPlan) {
      setFieldValue("nameEngCheck", currentSkuPlan?.nameEngCheck);
    }
  }, [currentSkuPlan?.nameEngCheck]);

  return null;
};
export default UpdateControl;
