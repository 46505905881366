import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {LOGIST_REQ_TABLE} from "../../../../../Requests/RequestRegister/RequestTable/utils/constants";
import {getRequest} from "../../../../../Requests/RequestsSlice";
import {selectIncomingRequests} from "../../../../redux/selectors";
import {makeStockPoolRqstsTableData} from "../../constants";

const useIncomingReqTable = ({selectedSP}) => {
  const allRequests = useSelector(selectIncomingRequests);

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = useColumns(LOGIST_REQ_TABLE);
  const defaultData = makeStockPoolRqstsTableData(allRequests);

  const onIncomingReqTableRowDoubleClick = request => {
    dispatch(getRequest(request.id));
    setIsModalOpen(true);
  };

  return {columns, defaultData, onIncomingReqTableRowDoubleClick, isModalOpen, setIsModalOpen};
};

export default useIncomingReqTable;
