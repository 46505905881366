import {createSlice} from "@reduxjs/toolkit";

import {
  addCarrierDoc,
  deleteCarrierDoc,
  fetchAllCarrierDocs,
  fetchAllCarrierDocTypes,
  fetchAllCarriers,
  fetchAllCarrierTypes,
  fetchCarrierFilters,
  getCarrier,
  updateCarrier,
} from "./operations";

export const CARRIERS_STORE_NAME = "logistCarriers";

const initialState = {
  carriers: null,
  carrierFilters: null,
  currentCarrier: null,
  carrierTypes: null,
  carrierDocs: null,
  currentCarrierDoc: null,
  currentDocFile: null,
  carrierDocTypes: null,
  carrierDocsLoading: false,

  carriersLoading: false,
  error: false,
};

const CarrierSlice = createSlice({
  name: CARRIERS_STORE_NAME,
  initialState,
  reducers: {
    setCurrentCarrierDocFile: (state, {payload}) => {
      state.currentDocFile = payload;
    },
    clearCurrentCarrier: state => {
      state.currentCarrier = null;
    },
    deselectCarrierDoc: state => {
      state.currentCarrierDoc = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCarrierFilters.fulfilled, (state, {payload}) => {
        state.carrierFilters = payload;
      })
      .addCase(fetchAllCarrierTypes.fulfilled, (state, {payload}) => {
        state.carrierTypes = payload;
      })
      .addCase(fetchAllCarriers.fulfilled, (state, {payload}) => {
        state.carriers = payload;
      })
      .addCase(getCarrier.fulfilled, (state, {payload}) => {
        state.currentCarrier = payload;
      })
      .addCase(updateCarrier.fulfilled, (state, {payload}) => {
        state.carriers = state.carriers.map(whouse => {
          if (whouse.carrierId === payload.carrierId) {
            return {...whouse, ...payload};
          }
          return whouse;
        });
        state.currentCarrier = {...state.currentCarrier, ...payload};
      })
      .addCase(fetchAllCarrierDocTypes.fulfilled, (state, {payload}) => {
        state.carrierDocTypes = payload;
      })
      // Carrier Docs

      .addCase(fetchAllCarrierDocs.fulfilled, (state, {payload}) => {
        state.carrierDocs = payload;
      })
      .addCase(addCarrierDoc.pending, state => {
        state.carrierDocsLoading = true;
      })
      .addCase(addCarrierDoc.fulfilled, (state, {payload}) => {
        state.carrierDocsLoading = false;
        state.currentCarrierDoc = payload;
        state.carrierDocs = [payload, ...(state.carrierDocs ?? [])];
      })
      .addCase(deleteCarrierDoc.fulfilled, (state, action) => {
        state.carrierDocs = state.carrierDocs?.filter(req => req.docId !== action.meta.arg) ?? null;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(CARRIERS_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(CARRIERS_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(CARRIERS_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.carriersLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.carriersLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.carriersLoading = false;
  state.error = error;
}

const {actions, reducer} = CarrierSlice;
export const {setCurrentCarrierDocFile, deselectCarrierDoc, clearCurrentCarrier} = actions;

export default reducer;
