export const CHOISE_OF_TRANFSORMATION_TABLE_COLUMNES = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Order number",
    accessorKey: "orderNumber",
  },
]);
