import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import {fetchList} from "../../../hook/axios.hook";
import {Select} from "../SelectField";

const CurrencySelect = () => {
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const {t} = useTranslation();

  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    fetchList("/references/currency").then(res => {
      if (res.length > 0) {
        const options = res.map(currency => ({title: currency.shortName, value: currency.currencyId}));
        setCurrenciesOptions(options);
      }
    });
  }, []);

  return (
    <Select
      name="currencyId"
      minFilterLength={1}
      label={t("Currency of the Warehouse") + ":"}
      options={currenciesOptions}
      makeEffect={option => {
        setFieldValue("currency", option?.title);
      }}
    />
  );
};

export default CurrencySelect;
