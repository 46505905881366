import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import {FACTORY_MODAL_TYPES} from "../factoryModals/utils/constants";

import useFactoryCommonTableService from "./utils/hooks/getFactoryCommonTableService";

const FactoryCommonTable = ({
  onCancelBtnClick = () => {},
  setCurrentCommonCondition = () => {},
  modalType,
  children,
}) => {
  const {columns, factoryCommonData, onFactoryCommonTableRowSelect} = useFactoryCommonTableService({
    setCurrentCommonCondition,
    modalType,
  });

  return (
    <>
      <ReactTable
        defaultData={factoryCommonData}
        columns={columns}
        className="factory_edit_table_cond"
        current={null}
        onSelect={onFactoryCommonTableRowSelect}
      />

      <div className="fcc jcfe" style={{marginTop: "0.5em", gap: "1em"}}>
        {children}
        <FunctionButton name="Cancel" onClick={onCancelBtnClick} />
      </div>
    </>
  );
};

FactoryCommonTable.propTypes = {
  onCancelBtnClick: PropTypes.func,
  onFactoryCommonTableRowSelect: PropTypes.func,
  modalType: PropTypes.oneOf(Object.values(FACTORY_MODAL_TYPES)),
  children: PropTypes.node,
};
export default FactoryCommonTable;
