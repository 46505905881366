import AddSkuToTable from "../../../KP/kp-f/kpFinTable/AddSkuToTable";

const ViewSkuFromTable = ({element}) => {
  return (
    <div className="sku-preview-modal" style={{height: "100%"}}>
      <AddSkuToTable values={element} className={"order_table_width"} chengeable={false} />
    </div>
  );
};

export default ViewSkuFromTable;
