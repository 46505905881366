import React from "react";
import {useTranslation} from "react-i18next";

import PageContainer from "../../../conteiners/PageContainer";

import PaymentDocumentsList from "./PaymentDocumentsList";
import ProformaPaymentsRegistrFilter from "./ProformaPaymentsRegistrFilter";

const PaymentsToFactory = () => {
  const {t} = useTranslation();

  return (
    <PageContainer title={t("Payments register for Pf")}>
      <div className="form_wrapper">
        <ProformaPaymentsRegistrFilter />
        <PaymentDocumentsList />
      </div>
    </PageContainer>
  );
};

export default PaymentsToFactory;
