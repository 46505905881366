import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../hook/axios.hook";

//get
export const fetchAllCargos = createAsyncThunk("logistCargos/fetchAllCargos", async params =>
  fetchList(`/cargos/getAll/`, "get", {params}),
);
export const fetchCargo = createAsyncThunk("logistCargos/fetchCargo", async cargoId =>
  fetchData(`/cargos/get/${cargoId}`, "get"),
);
//post
export const addNewCargo = createAsyncThunk("logistCargos/addNewCargo", async params =>
  fetchData(`/cargos/add`, "post", params),
);

//patch
export const takeTpToWork = createAsyncThunk("logistCargos/takeTpToWork", async cargoId =>
  fetchList(`cargos/takeToWork/${cargoId}`, "patch"),
);

export const sendTP = createAsyncThunk("logistCargos/sendTP", async ({cargoId, data}) =>
  fetchList(`/cargos/send/${cargoId}`, "patch", data),
);

export const acceptCargo = createAsyncThunk("logistCargos/acceptCargo", async ({cargoId, warehouseId}) =>
  fetchList(`/cargos/accept/${cargoId}/${warehouseId}`, "patch"),
);

export const updateCargo = createAsyncThunk("logistCargos/updateCargo", async ({cargoId, data}) =>
  fetchList(`/cargos/update/${cargoId}`, "patch", data),
);

//*** Cargos payments docs */

//get
export const fetchPaymentDocTypes = createAsyncThunk("logistCargos/fetchPaymentDocTypes", async () =>
  fetchList(`/cargos/payments/docs/getDocTypes`, "get"),
);
export const fetchAllPaymentDocs = createAsyncThunk("logistCargos/fetchAllPaymentDocs", async params =>
  fetchList(`/cargos/payments/docs/getAll`, "get", {params}),
);
export const fetchPaymentDoc = createAsyncThunk("logistCargos/fetchPaymentDoc", async docId =>
  fetchList(`/cargos/payments/docs/get/${docId}`, "get"),
);

//post
export const addNewCargoPaymentDoc = createAsyncThunk("logistCargos/addNewPaymentDoc", async ({cargoId, params}) =>
  fetchData(`/cargos/payments/docs/add/${cargoId}`, "post", params),
);
export const addNewCargoPaymentDocFile = createAsyncThunk(
  "logistCargos/addNewPaymentDocFile",
  async ({docId, formdata}) => fetchData(`/cargos/payments/docs/files/add/${docId}`, "post", formdata),
);

//patch
export const updatePaymentDoc = createAsyncThunk("logistCargos/updatePaymentDoc", async ({docId, params}) =>
  fetchData(`/cargos/payments/docs/update/${docId}`, "patch", params),
);

//delete
export const deleteCargoPaymentDoc = createAsyncThunk("logistCargos/deletePaymentDoc", async docId =>
  fetchData(`/cargos/payments/docs/remove/${docId}`, "delete"),
);
export const deleteCargoPaymentDocFile = createAsyncThunk("logistCargos/deletePaymentDocFile", async fileId =>
  fetchData(`/cargos/payments/docs/files/remove/${fileId}`, "delete"),
);

//*** Cargos docs */
//get
export const fetchCargoDocTypes = createAsyncThunk("logistCargos/fetchCargoDocTypes", async () =>
  fetchList(`/cargos/docs/getAllTypes`, "get"),
);
export const fetchAllCargoDocs = createAsyncThunk("logistCargos/fetchAllCargoDocs", async params =>
  fetchList(`/cargos/docs/getAll`, "get", {params}),
);
//post
export const addNewCargoDoc = createAsyncThunk("logistCargos/addNewCargoDoc", async ({cargoId, params}) =>
  fetchData(`/cargos/docs/add/${cargoId}`, "post", params),
);
//delete
export const deleteCargoDoc = createAsyncThunk("logistCargos/deleteCargoDoc", async docId =>
  fetchData(`/cargos/docs/remove/${docId}`, "delete"),
);
