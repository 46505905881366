import {dateFormat} from "../../../../../utilities/dateFormat";

const getRequestTableData = reqs => {
  if (reqs && reqs.length > 0) {
    return reqs.map(({requestNumber, cos, brand, createdAt, note, requestSum, requestId}, i) => ({
      number: i + 1,
      reqNumber: requestNumber,
      managerCO: cos[0]?.managerCo?.name,
      factory: brand?.name,
      reqAmount: requestSum,
      reqDate: dateFormat(createdAt),
      note: note,
      id: requestId,
    }));
  } else {
    return [];
  }
};

export default getRequestTableData;
