import PropTypes from "prop-types";

import "../../Components/Modal/modal.scss";

const CloseBtn = ({
  onClick,
  // question = null,
  style = null,
}) => {
  return (
    <div className="modal_close_btn" onClick={onClick} style={style}>
      &#215;
    </div>
  );
};

CloseBtn.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

export default CloseBtn;
