import React, {useState} from "react";
import PropTypes from "prop-types";

import ControlSaveBtns from "../../utilities/Buttons/ControlSaveBtns";
import Spinner from "../spinner/Spinner";

function addForcedPropToReactComponents(children, forcedUpdate, setGlobalSave) {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      if (typeof child.type === "function") {
        const props = {...child.props, forcedUpdate, setGlobalSave};
        const newChild = React.cloneElement(child, props);
        const nestedChildren = addForcedPropToReactComponents(child.props.children, forcedUpdate, setGlobalSave);
        if (nestedChildren?.length) {
          return React.cloneElement(newChild, {}, nestedChildren);
        }
        return newChild;
      }
      const nestedChildren = addForcedPropToReactComponents(child.props.children, forcedUpdate, setGlobalSave);
      if (nestedChildren.length) {
        return React.cloneElement(child, {}, nestedChildren);
      }
    }
    return child;
  });
}

const PageControlContainer = ({title, children, loading}) => {
  const [forcedUpdate, setForsedpdate] = useState(false); // по нажатию кнопки "зберегти"
  const [isEdited, setGlobalSave] = useState(false);

  const seveAll = () => {
    setForsedpdate(val => !val);
    setGlobalSave(false);
  };

  const components = addForcedPropToReactComponents(children, forcedUpdate, setGlobalSave);

  return (
    <div className="limitedSize">
      <p className="limitedSize_label">{title}</p>
      <div className="overflow_container">
        <div className="limitedSize__container controlled">
          {loading ? <Spinner /> : components}
          <div className="control__btn">
            <ControlSaveBtns
              isEdited={isEdited}
              seveAll={seveAll}
              setGlobalSave={setGlobalSave}
              forcedUpdate={forcedUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PageControlContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PageControlContainer;
