import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../hook/axios.hook";

//get
export const fetchSystemDates = createAsyncThunk("logistReminders/fetchSystemDates", async () =>
  fetchList(`/reminders/logistics/systemDates/getAll`, "get"),
);
export const fetchAllReminders = createAsyncThunk("logistReminders/fetchAllReminders", async params =>
  fetchList(`/reminders/logistics/getAll`, "get", {params}),
);
//post
export const addNewReminder = createAsyncThunk(
  "logistReminders/addNewReminder",
  async ({dateId, warehouseId, unitId, body}) =>
    fetchData(`/reminders/logistics/add/${dateId}/${warehouseId}/${unitId}`, "post", body),
);
//delete
export const deleteReminder = createAsyncThunk("logistReminders/deleteReminder", async reminderId =>
  fetchData(`/reminders/logistics/remove/${reminderId}`, "delete"),
);
