import {useEffect, useState} from "react";

import {fetchList} from "../../../hook/axios.hook";

const useStockPoolTables = ({filterProps, window}) => {
  const [allStockPools, setAllStockPools] = useState([]);
  const [checkedSP, setCheckedSP] = useState([]);
  // const [checkedSPRequests, setCheckedSPRequests] = useState(null);
  // const [selectedSPRequests, setSelectedSPRequests] = useState(null);
  const [spRequests, setSpRequests] = useState(null);
  const [currentSPId, setCurrentSpId] = useState(null);

  useEffect(() => {
    initialFetch();
    // }, [filterProps]);
  }, []);

  const initialFetch = () => {
    fetchList(`/stockPools/getAll`, "get", {
      params: {
        window,
        ...filterProps,
      },
    }).then(res => {
      setSpRequests(res?.skuGroups ?? []);
      setAllStockPools(res);
    });
  };

  const onStockPoolCheck = spList => {
    // console.log("onStockPoolCheck in window: ", window);
    if (spList?.length === 0 && checkedSP?.length === 0) return;
    setCheckedSP(prev => {
      if (!prev.length && !spList.length) return spList;
      if (!spList.length) {
        fetchList(`/stockPools/getAll`, "get", {params: {window, ...filterProps}}).then(res => {
          setSpRequests(res?.skuGroups ?? []);
        });
      } else {
        fetchList(`/stockPools/getAll`, "get", {
          params: {window, ...filterProps, stockPoolIds: spList?.join(",")},
        }).then(res => {
          setSpRequests(res?.skuGroups ?? []);
        });
      }
      return spList;
    });
  };

  const onStockPoolSelect = id => {
    // console.log("onStockPoolSelect in window: ", window);
    if (!id) {
      fetchList(`/stockPools/getAll`, "get", {
        params: {
          window,
          ...filterProps,
        },
      }).then(res => {
        setSpRequests(res?.skuGroups ?? []);
      });
    } else {
      fetchList(`/stockPools/getAll`, "get", {
        params: {
          window,
          stockPoolIds: id,
          ...filterProps,
        },
      }).then(res => {
        setSpRequests(res?.skuGroups ?? []);
      });
    }
    setCurrentSpId(id);
  };

  return {
    onStockPoolSelect,
    onStockPoolCheck,
    spRequests,
    checkedSP,
    currentSPId,
    allStockPools,
    setCurrentSpId,
    setCheckedSP,
  };
};

export default useStockPoolTables;
