export const onPaymentListTopTableColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 155,
  },
  {
    header: "Doc. on payment №",
    accessorKey: "docNumber",
    size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    size: 80,
  },
  {
    header: "Payment schema",
    accessorKey: "paymentSchema",
    size: 100,
  },
  {
    header: "Note for CO manager",
    accessorKey: "noteForCO",
    size: 100,
  },
  {
    header: "Payment document",
    accessorKey: "confirmingDocument",
    size: 80,
  },
  {
    header: "Payment document №",
    accessorKey: "confirmingDocumentNumber",
    size: 100,
  },
  {
    header: "Note for FEA manager",
    accessorKey: "noteToFEA",
    size: 120,
  },
];
