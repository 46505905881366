import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";
import * as Yup from "yup";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../conteiners/ReactTable";
import {NoteInput, TextInput} from "../../../../Inputs";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {addTransaction, deleteTransactionPackage} from "../../../redux/Warehouses/operations";
import {selectCurrentWarehouseService, selectWarehouseServiceTransactions} from "../../../redux/Warehouses/selectors";
import {SETTINGS_STOREGE_TRECK_SCHEM_COLUMNS} from "../constants";

const TransactionScheme = () => {
  const {t} = useTranslation();

  const transactions = useSelector(selectWarehouseServiceTransactions);
  const curentService = useSelector(selectCurrentWarehouseService);
  const dispatch = useDispatch();

  const {submitForm} = useFormikContext();

  const transactionsTableData =
    transactions?.map((transaction, index) => ({
      number: ++index,
      id: transaction?.transactionId,
      serviceType: curentService?.type?.name || "",
      currency: curentService?.currency?.shortName || "",
      dt: transaction?.debitAcc || "",
      kt: transaction?.creditAcc || "",
      price: transaction?.sum || "",
      purpose: transaction?.paymentPurpose || "",
    })) || [];

  const onTransasctionDelete = () => {
    Swal.fire({
      title: t("Sure"),
      text: t("All transaction from this package will be removed"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteTransactionPackage(curentService.serviceId));
      }
    });
  };
  const onTransasctionAdd = () => {
    submitForm().then(values => {
      if (values) {
        dispatch(addTransaction({serviceId: curentService.serviceId, data: values}));
      }
    });
  };

  const columns = useColumns(SETTINGS_STOREGE_TRECK_SCHEM_COLUMNS);
  return (
    <div className="fGrow fcCols w100" style={{opacity: curentService ? 1 : 0.5}}>
      <div className="label underline mb0_5"> Налаштування схеми проводок:</div>
      <div className="autolabel w100">
        <div className="fc nowrap jcsb">
          <TextInput name="debitAcc" label={t("Дт")} style={{width: "45%"}} disabled={!curentService} />
          <TextInput name="creditAcc" label={t("Кт")} style={{width: "45%"}} disabled={!curentService} />
        </div>

        <NoteInput
          name={"paymentPurpose"}
          label={t("Призначення платежу:")}
          action={onTransasctionAdd}
          width={"100%"}
          disabled={!curentService}
        />
      </div>
      <div className="fc nowrap jcsb g2 " style={{marginBottom: "6px"}}>
        <div className="fcCols" style={{gap: "4px", width: "200px"}}>
          <FunctionButton name="Додати проводку у пакет" width={"100%"} onClick={onTransasctionAdd} disabled />
          <FunctionButton name="Видалити пакет проводок" width={"100%"} onClick={onTransasctionDelete} />
        </div>
        <div className="label label_thin">(!) проводки будуть виконуватисть послідовно з першої до останьої.</div>
      </div>
      <div className="fGrow w100">
        <ReactTable
          columns={columns}
          defaultData={transactionsTableData}
          onSelect={() => {}}
          current={null}
          className={"scrollX"}
        />
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({debitAcc: "", creditAcc: "", paymentPurpose: ""}),
  handleSubmit: () => {},
  enableReinitialize: true,
  validationSchema: () =>
    Yup.object({
      debitAcc: Yup.string().required([t("Required field")]),
      creditAcc: Yup.string().required([t("Required field")]),
    }),
})(TransactionScheme);
