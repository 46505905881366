import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useFormikContext, withFormik} from "formik";

import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useLogistFilters from "../../common/LogistFilters/useLogistFilters";
import withLogistFilters from "../../common/LogistFilters/withLogistFilters";
import {fetchStockPoolFilters} from "../../redux/operations";

const BaseLogistFactoryFilters = ({disabled, setSelectedFactory}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const filtersOptions = useLogistFilters();
  const {brandsOptions, feaManagersOptions, requestNumberOptions, logistOptions} = filtersOptions;

  // const [allBrands, setAllBrands] = useState([]);
  // const allbrandsOptions = useMemo(() => {
  //   return (
  //     allBrands?.map(item => {
  //       return {title: item.name, value: item.brandId};
  //     }) || []
  //   );
  // }, [allBrands]);

  const {
    values: {brandId},
  } = useFormikContext();

  useEffect(() => {
    if (brandId) {
      setSelectedFactory({
        title: brandsOptions.find(item => item.value === brandId)?.title,
        value: brandId,
      });
    }
  }, [brandId, brandsOptions]);

  useEffect(() => {
    dispatch(fetchStockPoolFilters({window: "Factory"}));
    // .then(res => {
    //   setAllBrands(res?.brands);
    // });
  }, []);

  return (
    <>
      <div className="f_block w100" style={{paddingTop: "0.7em"}}>
        <Select
          label={t("Factory")}
          name="brandId"
          makeEffect={setSelectedFactory}
          options={brandsOptions}
          width="100%"
          readOnly={disabled}
        />
        <TextInput label={t("SP №")} name="stockPoolNumber" width="100%" readOnly={disabled} />
        <Select
          label={t("Request №")}
          name="requestNumber"
          options={requestNumberOptions}
          width="100%"
          readOnly={disabled}
        />
        <Select
          label={t("FEA Manager")}
          name="managerFeaId"
          options={feaManagersOptions}
          width="100%"
          readOnly={disabled}
        />
        <Select label={t("Logist")} name="logistId" options={logistOptions} width="100%" readOnly={disabled} />
      </div>
    </>
  );
};

const LogistFilters = withLogistFilters(BaseLogistFactoryFilters);

const FactoryBlockFilter = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(LogistFilters);

export default FactoryBlockFilter;
