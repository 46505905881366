import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import leftIcon from "../../images/icons/Caret_Circle_Left.png";
import rightIcon from "../../images/icons/Caret_Circle_Right.png";

import ImageButton from "./ImageBtn";

const LeftRightBtns = ({
  onRightClick,
  onLeftClick,
  isRightBtnDisabled = false,
  isLeftBtnDisabled = false,
  tooltipMessage = "",
}) => {
  const {t} = useTranslation();

  return (
    <div className="column nowrap" style={{width: "6em"}}>
      <ImageButton
        src={rightIcon}
        tooltipMessage={t(tooltipMessage)}
        onClick={onRightClick}
        size={2}
        disabled={isRightBtnDisabled}
      />
      <ImageButton src={leftIcon} onClick={onLeftClick} size={2} disabled={isLeftBtnDisabled} />
    </div>
  );
};

LeftRightBtns.propTypes = {
  onRightClick: PropTypes.func,
  onLeftClick: PropTypes.func,
  isRightBtnDisabled: PropTypes.bool,
  isLeftBtnDisabled: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

export default LeftRightBtns;
