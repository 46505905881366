import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";

import useOrderIndoService from "./useOrderIndoService";

const OrderInfo = ({headerNumber = 1, onItemOpen, orders, height = "12em", children, width, dontShowButton}) => {
  const {t} = useTranslation();

  const {orderTableColumns, orderTableData, curentOrder, onOrderSelect, currentOrderId} = useOrderIndoService(orders);

  return (
    <>
      <div className="fc jcsb nowrap gap aibl w100">
        <div className={"header-block " + (children ? "" : "mb0")}>{`${headerNumber}. ${t("Order")}:`}</div>
        {dontShowButton ? null : (
          <FunctionButton
            name="Open in a tab"
            onClick={() => {
              onItemOpen({item: curentOrder});
            }}
            disabled={!curentOrder}
          />
        )}
      </div>
      <div className="fdr gap">
        {children}

        <ReactTable
          columns={orderTableColumns}
          onSelect={onOrderSelect}
          current={currentOrderId}
          defaultData={orderTableData}
          style={{height: height, width: width}}
        />
      </div>
    </>
  );
};

OrderInfo.propTypes = {headerNumber: PropTypes.string};

export default OrderInfo;
