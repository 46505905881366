import {CARRIERS_STORE_NAME} from "./CarriersSlice";

export const selectCarriers = state => state[CARRIERS_STORE_NAME].carriers;
export const selectCarrierFilters = state => state[CARRIERS_STORE_NAME].carrierFilters;
export const selectCurrentCarrier = state => state[CARRIERS_STORE_NAME].currentCarrier;
export const selectCarrierTypes = state => state[CARRIERS_STORE_NAME].carrierTypes;
export const selectAllCarrierDocs = state => state[CARRIERS_STORE_NAME].carrierDocs;
export const selectCarrierDocTypes = state => state[CARRIERS_STORE_NAME].carrierDocTypes;
export const selectCarrierCurrentDoc = state => state[CARRIERS_STORE_NAME].currentCarrierDoc;
export const selectCurrentCarrierDocFile = state => state[CARRIERS_STORE_NAME].currentDocFile;

export const selectCarrierDocsLoading = state => state[CARRIERS_STORE_NAME].carrierDocsLoading;
export const selectCarriersLoading = state => state[CARRIERS_STORE_NAME].carriersLoading;
