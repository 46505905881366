import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import * as Yup from "yup";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ControlCheck from "../../../Inputs/controlCheck/ControlCheck";
import {getCurrentSku} from "../../SkuSlice";

import VolumeWeightCharacteristics from "./vwCharacteristics/vwCharacteristics";
import Decoration from "./Decoration";
import MainCharacteristics from "./MainCharacteristics";
import Prices from "./SkuPrices";

import "../skuVed.scss";

const SkuVedForm = ({onSubmit, setModalActive, children}) => {
  const {t} = useTranslation();

  const currentSku = useSelector(getCurrentSku);
  const [numberOfChecked, setNumberOfChecked] = useState(0);

  const initialValues = {
    article: "",
    amount: "",
    countryId: "",
    brandId: "",
    productGroupId: "",
    productTypeId: "",
    materialId: "",
    colorId: "",
    material: "",
    unitId: "",
    palletId: "",
    productSeries: "",
    complectDesc: "",
    objectId: "",

    colorType: "",
    materialType: "",

    settlementPrice: "",
    minimumPrice: "",
    costPrice: "",
    discountPrice: "",
    listPrice: "",
    retailPrice: "",

    name: "",
    nameEng: "",
    skuUnkit: "",

    batch: "",
    volume: "",
    weight: "",
    width: "",
    height: "",
    length: "",
    area: "",

    // dimensionCoef: "",
    // areaCoef: "",
    // volumeCoef: "",
    // volumeWeightCoef: "",

    overallSizeCheck: false,
    volumeCheck: false,
    areaCheck: false,
    weightCheck: false,
    palletCheck: false,
  };

  const initialValuesProp = useMemo(() => {
    return currentSku instanceof Object && !!currentSku ? currentSku : initialValues;
  }, [currentSku]);

  // const formik = useFormik({
  //   initialValues: {
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //   },
  //   onSubmit: values => {
  //     alert(JSON.stringify(values, null, 2));
  //   },
  // });

  const validationObj = Yup.object().shape({
    article: Yup.string().required(t("Specify the article number")),
    // // countryId :Yup.number().required(),
    brandId: Yup.number().nullable().required(t("Select a brand")),
    productGroupId: Yup.number().nullable().required(t("Select product group")),
    productTypeId: Yup.number().nullable().required(t("Select productType")),
    materialId: Yup.number().nullable().required(t("Select material")),
    colorId: Yup.number().nullable().required(t("Select color")),
    listPrice: Yup.number().nullable().required(t("Select list price")),
    minimumPrice: Yup.number().nullable().required(t("Select minimum price")),
    retailPrice: Yup.number().nullable().required(t("Select retail price")),
    unitId: Yup.number().nullable().required(t("Select units")),
    overallSizeCheck: Yup.boolean()
      .nullable()
      .test("вкажіть данні", "вкажіть данні", function (item) {
        if (!item) return true;
        var {length, width, height} = this.parent;
        return (
          (length && length !== "" && length !== "0.00000" && length !== 0) ||
          (width && width !== "" && width !== "0.00000" && width !== 0) ||
          (height && height !== "" && height !== "0.00000" && height !== 0)
        );
      }),
    volumeCheck: Yup.boolean()
      .nullable()
      .test("вкажіть данні", "вкажіть данні", function (item) {
        if (!item) return true;
        var {volume: unit} = this.parent;
        return unit && unit !== "" && unit !== "0.00000" && unit !== 0;
      }),
    areaCheck: Yup.boolean()
      .nullable()
      .test("вкажіть данні", "вкажіть данні", function (item) {
        if (!item) return true;
        var {area: unit} = this.parent;
        return unit && unit !== "" && unit !== "0.00000" && unit !== 0;
      }),
    weightCheck: Yup.boolean()
      .nullable()
      .test("вкажіть данні", "вкажіть данні", function (item) {
        if (!item) return true;
        var {weight: unit} = this.parent;
        return unit && unit !== "" && unit !== "0.00000" && unit !== 0;
      }),
    palletCheck: Yup.boolean()
      .nullable()
      .test("вкажіть данні", "вкажіть данні", function (item) {
        if (!item) return true;
        var {palletId} = this.parent;
        return palletId && palletId !== "";
      }),
  });
  return (
    <Formik
      initialValues={initialValuesProp}
      validationSchema={validationObj}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => {
        // const {
        //   dimensionCoef,
        //   areaCoef,
        //   volumeCoef,
        //   volumeWeightCoef,
        //   volumeWeight,
        //   // decorImages,
        //   // colorImages,
        //   // drawings,
        //   // images,
        //   volume,
        //   weight,
        //   width,
        //   height,
        //   length,
        //   area,
        //   ...updatedSku} = values
        //   var sku ={ ...updatedSku,
        //     width: (width / dimensionCoef).toFixed(5),
        //     height: (height/ dimensionCoef).toFixed(5),
        //     length: (length / dimensionCoef).toFixed(5),
        //     weight: (weight/volumeWeightCoef).toFixed(5),
        //     area: (area/areaCoef).toFixed(5),
        //     volume: (volume/volumeCoef).toFixed(5)
        //   }
        console.dir(values);
        if (numberOfChecked === 0) {
          Swal.fire({
            // title: "",
            text: t("Specify at least one vw"),
            icon: "error",
            confirmButtonText: "Ok",
            showCancelButton: false,
          });
          return;
        }
        onSubmit(values);
      }}
    >
      {({handleSubmit}) => (
        <Form className="sku-ved-form" style={{width: "100%", height: "100%"}}>
          <MainCharacteristics />
          <Decoration />
          <div className="fc" style={{width: "100%", height: "17em"}}>
            <div className="half bigger">
              <VolumeWeightCharacteristics numberOfChecked={numberOfChecked} setNumberOfChecked={setNumberOfChecked} />
            </div>
            <div className="half smaller" style={{position: "relative"}}>
              <Prices mayEdit={true} />
              <div style={{position: "absolute", bottom: "1em", right: "1em"}}>
                <ControlCheck name="dd" date="ddd" initialChecked={false} />
              </div>
            </div>
          </div>

          <div className="skuForm_btns">
            {React.Children.map(children, child => {
              if (child.props.type === "submit") {
                return React.cloneElement(child, {
                  onClick: handleSubmit,
                  type: "button",
                });
              } else return child;
            })}
            <FunctionButton name={t("Post SKU")} disabled={true} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

SkuVedForm.propTypes = {onSubmit: PropTypes.func, setModalActive: PropTypes.func, children: PropTypes.node};

export default SkuVedForm;
