import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";

import {BUTTON_TYPES} from "../../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../../Inputs";
import {addDisapprovementReason} from "../../../../Requests/Proform/redux/operations/ProformOperations";

import styles from "../KpPfControl.module.scss";

export const ReasonAddingForm = ({approvalId, values, resetForm}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {skuNumber, disapprovReason, disapprovDescription} = values;

  const areInputsDisabled = !approvalId;
  const disabled = !approvalId || !skuNumber || !disapprovReason;

  const onAddDisapproveReasonBtnClick = () => {
    if (!approvalId) return;

    dispatch(
      addDisapprovementReason({approvalId, data: {skuNumber, reason: disapprovReason, note: disapprovDescription}}),
    );

    resetForm({...values, skuNumber: "", disapprovReason: "", disapprovDescription: ""});
  };

  return (
    <>
      <TextInput label={t("SKU №") + ":"} name="skuNumber" width="100%" disabled={areInputsDisabled} />
      <TextInput label={t("Reason") + ":"} name="disapprovReason" width="100%" disabled={areInputsDisabled} />
      <TextInput label={t("Description") + ":"} name="disapprovDescription" width="100%" disabled={areInputsDisabled} />
      <FunctionButton
        className={styles.createPDFBtn}
        type={BUTTON_TYPES.BUTTON}
        name="Add"
        width="11em"
        disabled={disabled}
        onClick={onAddDisapproveReasonBtnClick}
      />
    </>
  );
};

ReasonAddingForm.propTypes = {
  values: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  approvalId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
};
