import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {fetchList} from "../../../../hook/axios.hook";
import {deselectKp, fetchKpList, getAllKpsTOdos, getKpInfoForKp, getKpList, updateKp} from "../../../../slices/KPSlice";
import {dateFormat} from "../../../../utilities/dateFormat";
import TableWithSelect from "../../../conteiners/TableWithSelect";
import {remindsFetched} from "../../../reminder/RemindsSlice";

const KpList = ({forcedUpdate}) => {
  const {t} = useTranslation();

  const {kpLoadingStatus, currentKp} = useSelector(state => state.kp);
  const kpList = useSelector(getKpList);
  const user = useSelector(state => state.user.user);

  const dispatch = useDispatch();

  const {values, initialValues} = useFormikContext();

  useEffect(() => {
    if (user)
      dispatch(fetchKpList()).then(res => {
        if (res.meta.requestStatus === "fulfilled") dispatch(remindsFetched(res.payload.todoList));
      });
    if (currentKp) {
      dispatch(getKpInfoForKp(currentKp.coId));
    }
  }, [user]);

  useEffect(() => {
    if (kpList?.length > 0) {
      if (!currentKp) {
        fetchList(`/kp/getForCurrent`).then(res => {
          dispatch(remindsFetched(res.todoList));
        });
      } else {
        fetchList(`/todo/kp/getForCo/${currentKp.coId}`).then(res => {
          dispatch(remindsFetched(res));
        });
      }
    }
  }, [currentKp]);

  const ths = useMemo(() => {
    return {
      [t("CO №")]: 10,
      [t("Manager")]: 15,
      [t("CO Manager")]: 15,
      [t("Notes")]: 30,
      [t("Date from")]: 10,
      [t("Date to")]: 10,
      [t("Status")]: 5,
    };
  }, []);

  useEffect(() => {
    if (currentKp) onUpd();
  }, [forcedUpdate]);

  const onUpd = () => {
    var iadvancePayment = initialValues.advancePayment || "",
      iadvancePaymentDeadline = initialValues.advancePaymentDeadline || "",
      advancePayment = values.advancePayment || "",
      advancePaymentDeadline = values.advancePaymentDeadline || "";

    if (advancePayment !== iadvancePayment || advancePaymentDeadline !== iadvancePaymentDeadline) {
      dispatch(
        updateKp({
          coId: currentKp.coId,
          newKp: {
            advancePayment: values.advancePayment,
            advancePaymentDeadline: values.advancePaymentDeadline,
          },
        }),
      );
    }
  };

  const onChange = id => {
    if (currentKp && currentKp.coId === id) {
      dispatch(deselectKp());
    } else {
      var iadvancePayment = initialValues.advancePayment || "",
        iadvancePaymentDeadline = initialValues.advancePaymentDeadline || "",
        advancePayment = values.advancePayment || "",
        advancePaymentDeadline = values.advancePaymentDeadline || "";

      if (advancePayment !== iadvancePayment || advancePaymentDeadline !== iadvancePaymentDeadline) {
        Swal.fire({
          title: "",
          text: `${t("CO changes")} ${currentKp.coNumber} ?`,
          icon: "question",
          confirmButtonText: t("Yes"),
          showCancelButton: true,
          cancelButtonText: t("No"),
        })
          .then(answ => {
            if (answ.isConfirmed) {
              dispatch(
                updateKp({
                  coId: currentKp.coId,
                  newKp: {
                    advancePayment: values.advancePayment,
                    advancePaymentDeadline: values.advancePaymentDeadline,
                  },
                }),
              );
            }
          })
          .then(() => {
            dispatch(getKpInfoForKp(id));
          });
      } else {
        dispatch(getKpInfoForKp(id));
      }
    }
  };

  return (
    <TableWithSelect
      thsName={ths}
      items={kpList ? kpList.map(transformKpForTable) : []}
      current={currentKp?.coId}
      onSelect={onChange}
      loadingStatus={kpLoadingStatus}
      formatStatus={formatStatus}
    />
  );
};

const formatStatus = status => {
  var statusColor = "gray";
  var textColor = "#A3B9D9";
  switch (status) {
    case "tempSaved":
      statusColor = "green";
      textColor = "#426BA6";
      break;
    case "unsaved":
      statusColor = "rgb(246, 248, 96)";
      textColor = "#426BA6";
      break;
    default:
      break;
  }
  return [
    <label key="radio-label" className="radio_container">
      <div className="circules_box">
        <div className="circule1"></div>
        <div className="circule2" style={{backgroundColor: statusColor}}></div>
      </div>
    </label>,
    <span key="text-span" style={{color: textColor}}></span>,
  ];
};

function transformKpForTable(kp) {
  return {
    coNumb: kp.coNumber,
    projectManager: kp.project.projectManager.name || "",
    kpManager: kp.managerCo.name || "",
    note: kp.note || "",
    dateStart: dateFormat(kp.startDate) || "",
    dateFinish: dateFormat(kp.finalDate) || "",
    status: "",
    id: kp.coId,
  };
}

KpList.propTypes = {forcedUpdate: PropTypes.bool};

export default KpList;
