import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModalForm from "../../Modal/FormModal";
import RequestReadyFilterForm from "../Filters/filtersVariety/RequestReadyFilter";
import withRequestWithFilter from "../hoc/RequestWithFilterHOC";
import SplitOrderSku from "../OrderProcessing/splitOrderModal/splitOrderSku";
import useColumns from "../OrderProcessing/utils/hooks/useColumns";
import {SPLIT_TYPES} from "../OrderProcessing/utils/splitTypes";
import {REQUEST_TABLE_COLUMNS} from "../RequestRegister/RequestTable/utils/constants";
import {REQ_WINDOW_TYPES} from "../RequestsInProgress/utils/functions/requestsFiltersManager";
import useRequests from "../RequestsInProgress/utils/hooks/useRequests";
import {
  addToStockPool,
  clearNote,
  deselectCurrentOrder,
  deselectCurrentRequest,
  deselectStockPool,
  disbandStockPool,
  fetchRequests,
  getCheckedRequestIds,
  getCurrentRequestId,
  getCurrentRequestSKUs,
  getLoadingRequest,
  getRequest,
  getRequestInfo,
  getSplitedOrder,
  getStockPoolInfo,
  mergeRequests,
  setCheckedRequestIds,
  setCurrent,
  splitRequests,
} from "../RequestsSlice";

import StockPoolTable from "./StockPoolTable/StockPoolTable";

import styles from "../OrderProcessing/OrderBlock/OrderBlock.module.scss";

const ReadyStatusReqBase = ({updateFetch}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentReqId = useSelector(getCurrentRequestId);
  const currentRequestIds = useSelector(getCheckedRequestIds);
  const currentRequestSKUs = useSelector(getCurrentRequestSKUs);
  const reqInfo = useSelector(getRequestInfo);
  const currStockPool = useSelector(getStockPoolInfo);

  const requestLoading = useSelector(getLoadingRequest);

  const [splitModalActive, setSplitModalActive] = useState(false);
  const [defaultCheck, setDefaultCheck] = useState(null);

  const [spModalActive, setSpModalActive] = useState(false);

  const areRequestsChecked = currentRequestIds?.length > 0;

  const {tableData} = useRequests();

  const columns = useColumns(REQUEST_TABLE_COLUMNS);

  const updateAndCleanAfterRequest = () => {
    dispatch(setCheckedRequestIds([]));
    updateFetch();
    return () => {
      dispatch(deselectCurrentOrder());
    };
  };

  const onTableRowSelect = useCallback(
    requestId => {
      if (!requestId || requestId === currentReqId) {
        dispatch(deselectCurrentOrder());
        dispatch(deselectCurrentRequest());
        return;
      }
      dispatch(getRequest(requestId));
      dispatch(setCurrent(requestId));

      //cleaning redux state currentNote from previous requests
      dispatch(clearNote());
    },
    [currentReqId],
  );

  const onTableCheckboxChange = checked => {
    if (currentRequestIds && checked.join("") === currentRequestIds.join("")) return;
    dispatch(setCheckedRequestIds(checked));
  };

  const onCombineRequestsBtnClick = () => {
    dispatch(addToStockPool(currentRequestIds)).then(({meta, payload}) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(fetchRequests({window: "Requests in SP"}));
        toast.success(t("Requests were merged successfully"));
        updateAndCleanAfterRequest();
        setDefaultCheck([payload.requestId]);
        onTableRowSelect(payload.requestId);
      }
    });
  };

  const onSplitRequestBtnClick = () => {
    if (!currentReqId) {
      toast.error(t("You must select one request!"));
      return;
    }
    dispatch(splitRequests(currentReqId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(t("Request before split"));
        setSplitModalActive(true);
      }
    });
  };

  const onDisbandStockPool = () => {
    Swal.fire({
      title: "",
      text: t("disband sp warn"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(disbandStockPool(currStockPool.stockPoolId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            toast.success(t("disband success"));
            dispatch(fetchRequests({window: "Requests in SP"}));
            dispatch(fetchRequests({window: "Paid requests"}));
            dispatch(deselectStockPool());
            dispatch(deselectCurrentRequest());
            setCurrent(null);
          }
        });
      }
    });
  };

  return (
    <PageContainer title={t("req stat read")}>
      <div className="form_wrapper">
        <RequestReadyFilterForm window={REQ_WINDOW_TYPES.PAID} />

        <div>
          <ReactTable
            defaultData={tableData}
            columns={columns}
            onSelect={onTableRowSelect}
            current={currentReqId ?? null}
            defaultMultiSelection={defaultCheck}
            setMultiSelect={onTableCheckboxChange}
            className={"scrollX"}
            enableMultiSelection={true}
            loading={requestLoading}
            style={{height: "25em"}}
          />

          <div className="fdr gap mb mt">
            <FunctionButton
              name={"split req"}
              onClick={onSplitRequestBtnClick}
              disabled={!currentReqId || currentRequestSKUs.length < 2 || reqInfo?.status.statusId === 12}
            />
            <FunctionButton
              name={"Merge into the Stock Pool"}
              onClick={onCombineRequestsBtnClick}
              disabled={!areRequestsChecked}
              tooltipMessage={"TP creating attention"}
              autoWidth
            />
          </div>
        </div>
        <StockPoolTable
          spModalActive={spModalActive}
          setSpModalActive={setSpModalActive}
          reqStat={reqInfo?.status.statusId}
        />

        <div className="fc gap mb mt w100 jcc" style={{position: "relative"}}>
          <FunctionButton
            autoWidth
            name={"Open a Stock Pool"}
            onClick={() => {
              setSpModalActive(true);
            }}
            disabled={!currStockPool || reqInfo?.status?.statusId !== 12}
          />
          <FunctionButton
            name={"Disband the Stock Pool"}
            onClick={onDisbandStockPool}
            disabled={!currStockPool || reqInfo?.status?.statusId !== 12}
            autoWidth
            style={{position: "absolute", right: 0}}
          />
        </div>
      </div>

      <ModalForm
        closable={true}
        Component={SplitOrderSku}
        updateAndCleanAfterRequest={updateAndCleanAfterRequest}
        label={t("separat")}
        active={splitModalActive}
        setModalActive={setSplitModalActive}
        // className={styles.order_split_modal}
        // isStatus={true}
        isStatus={SPLIT_TYPES.READY_REQUEST}
      />
    </PageContainer>
  );
};
const ReadyStatusReq = withRequestWithFilter(ReadyStatusReqBase, REQ_WINDOW_TYPES.PAID);

export default ReadyStatusReq;
