import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";

export const LOGIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  // {
  //   header: "\u29BF",
  //   accessorKey: "statusCircle",
  //   size: 50,
  //   cell: circleColor => (
  //     <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
  //       <AvailabilityStatusCIrcule color={circleColor.getValue()} />
  //     </div>
  //   ),
  // },

  // {
  //   header: "Request status",
  //   accessorKey: "requestStatus",
  //   isSortable: true,
  //   size: 70,
  // },
  {
    header: "Application №",
    accessorKey: "orderNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    isSortable: true,
    size: 70,
  },
  // {
  //   header: "Tr pool №",
  //   accessorKey: "trPoolNumber",
  //   isSortable: true,
  //   size: 70,
  // },

  {
    header: "CO Manager",
    accessorKey: "managerCo",
    isSortable: true,
    size: 70,
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
    isSortable: true,
    size: 70,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    isSortable: true,
    size: 70,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    isSortable: true,
    size: 70,
  },
  {
    header: "Readiness date",
    accessorKey: "createdAt",
    isSortable: true,
    size: 70,
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
    isSortable: true,
    size: 70,
  },
  {
    header: "Product country",
    accessorKey: "country",
    isSortable: true,
    size: 70,
  },
  {
    header: "Prof №",
    accessorKey: "proformaNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
    isSortable: true,
    size: 70,
  },
  // {
  //   header: "Tr pool №",
  //   accessorKey: "trPoolNumber",
  //   isSortable: true,
  //   size: 70,
  // },
  {
    header: "Note",
    accessorKey: "note",
    isSortable: true,
    size: 70,
  },
  {
    header: "Action Place",
    accessorKey: "actionPlace",
    isSortable: true,
    size: 70,
  },
]);
