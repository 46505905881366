import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import FunctionButton from "./FunctionButton";

const ControlSaveBtns = ({seveAll}) => {
  const {t} = useTranslation();

  return (
    <div style={{height: "15%"}}>
      <div className="fixed_button__container save_btns">
        <FunctionButton
          name={t("Save")}
          onClick={seveAll}
          style={{width: "12em"}}
          // disabled
          //  disabled={!props.isEdited}
        />

        <FunctionButton name={t("Save and post")} style={{width: "12em", padding: "1px 0"}} disabled />

        <FunctionButton name={t("Cancel")} style={{width: "12em"}} disabled />
      </div>
    </div>
  );
};

ControlSaveBtns.propTypes = {
  seveAll: PropTypes.func,
};

export default ControlSaveBtns;
