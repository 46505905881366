import {useTranslation} from "react-i18next";

import PageContainer from "../../conteiners/PageContainer";

import FactoryDistribution from "./Autodistribution/FactoryDistribution";
import ControlTermsSettings from "./ControlTermsSettings/ControlTermsSettings";
import FactoryList from "./FactoryList/FactoryList";
import RequestTemplates from "./RequestTemplates/RequestTemplates";

import styles from "./common/styles/VedSettingsGeneral.module.scss";

const VedSettingsGeneral = () => {
  const {t} = useTranslation();

  // const factory = useSelector(selectCurrentFactoryName);

  // const initialValues = useMemo(
  //   () => ({
  //     reqAutoNote: "",
  //     factory: factory || "",
  //     variant: "",
  //     toWhom: "",
  //     copy: "",
  //     theme: "",
  //     reqText: "",
  //     toFactoryDate: "",
  //     pfReceivingDate: "",
  //     pfControlDate: "",
  //     paymentDate: "",
  //     paymentReadyDate: "",
  //     readyControlDate: "",
  //     logistDate: "",
  //   }),
  //   [factory],
  // );

  return (
    // <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
    <PageContainer title={t("Main settings")}>
      {/* <VEDSettingsFactoryFilters /> */}
      <div className={styles.pageContainer}>
        <div className={styles.leftColumn}>
          <FactoryDistribution />
          <RequestTemplates />
          <ControlTermsSettings />
        </div>
        <div className={styles.rightColumn}>
          <FactoryList />
        </div>
      </div>
    </PageContainer>
    // </Formik>
  );
};

export default VedSettingsGeneral;
