import {createSelector} from "@reduxjs/toolkit";

export const selectAllProform = state => state.proforms.proforms;
export const selectCurrentProform = state => state.proforms.currentProform;
export const selectCurrentForRequest = state => state.proforms.currentForRequest;
export const selectCurrentApproval = state => state.proforms.currentApproval;
export const selectApprovals = state => state.proforms.approvals;
export const selectProformsLoading = state => state.proforms.proformsLoading;
export const selectUserApprovals = state => state.proforms.userApprovals;
export const selectProformsError = state => state.proforms.error;
export const selectDisapprovalReasons = createSelector(
  state => state.proforms.currentApproval?.disapprovalReasons,
  disapprovalReasons => disapprovalReasons ?? [],
);

// export const selectDisapprovalReasons = state => state.proforms.currentApproval?.disapprovalReasons ?? [];
