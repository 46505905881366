import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchFactoryContactConditions} from "../../../../redux/operations/FactoryOperations";
import {
  selectCurrentConditionId,
  selectCurrentContactId,
  selectCurrentFactoryId,
  selectFactoryContactConditions,
  selectReferences,
} from "../../../../redux/selectors/FactorySelectors";
import {setCurrentConditionId} from "../../../../redux/slices/FactorySlice";
import {CONDITIONS_DELIVERY_COLUMNS} from "../../../conditions&contactsFactory/utils/constants";
import {FACTORY_MODAL_TYPES} from "../../../factoryModals/utils/constants";

import useTermsStatusOptionsService from "./getTermsStatusOptionsService";
import useTimeUnitsService from "./getTimeUnitsService";

const useFactoryConditionFormService = modalType => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const columns = useColumns(CONDITIONS_DELIVERY_COLUMNS);
  const termsStatusOptions = useTermsStatusOptionsService();

  const currentConditionId = useSelector(selectCurrentConditionId);
  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const currentContactId = useSelector(selectCurrentContactId);
  const factoryContactConditions = useSelector(selectFactoryContactConditions);
  const references = useSelector(selectReferences);
  const timeUnits = references?.timeUnits ?? [];

  useEffect(() => {
    if (!currentFactoryId || !currentContactId) {
      return;
    }

    if (modalType !== FACTORY_MODAL_TYPES.CREATE_FACTORY) {
      dispatch(fetchFactoryContactConditions({factoryId: currentFactoryId, contactId: currentContactId}));
    }
  }, [currentFactoryId, currentContactId]);

  const current = currentConditionId;

  const termsOptions = useTimeUnitsService();

  // function formTermsField(termsFrom, termsTo, selectedTimeUnit) {
  //   const {multiplier, engName} = selectedTimeUnit ?? {};
  //   const TIME_UNITS_FOR_TABLE = {
  //     day: i18n.language === "en" ? "d" : "дн",
  //     week: i18n.language === "en" ? "w" : "тжд",
  //     month: i18n.language === "en" ? "mnth" : "м",
  //     quarter: i18n.language === "en" ? "qrt" : "кв",
  //     year: i18n.language === "en" ? "y" : "р",
  //   };
  //   const unitName = TIME_UNITS_FOR_TABLE[engName];
  //   return termsFrom && termsTo
  //     ? `${t("from")} ${Math.ceil(termsFrom * multiplier)} ${t("to")} ${Math.ceil(termsTo * multiplier)} ${unitName}`
  //     : "";
  // }

  function formTermsField(termsFrom, termsTo, unitId) {
    const {multiplier, engName} = timeUnits.find(unit => unit.unitId === unitId) ?? {};

    const TIME_UNITS_FOR_TABLE = {
      day: i18n.language === "en" ? "d" : "дн",
      week: i18n.language === "en" ? "w" : "тжд",
      month: i18n.language === "en" ? "mnth" : "м",
      quarter: i18n.language === "en" ? "qrt" : "кв",
      year: i18n.language === "en" ? "y" : "р",
    };
    const unitName = TIME_UNITS_FOR_TABLE[engName];
    return termsFrom && termsTo
      ? `${t("from")} ${Math.ceil(termsFrom * multiplier)} ${t("to")} ${Math.ceil(termsTo * multiplier)} ${unitName}`
      : "";
  }

  const factoryConditionTableData = factoryContactConditions
    ? factoryContactConditions.map(
        ({conditionId, discount, discountNote, unitId, termsFrom, termsTo, termsNote, termsCondition}, index) => {
          return {
            index: ++index,
            id: conditionId,
            discount,
            discountNote,
            terms: formTermsField(termsFrom, termsTo, unitId),
            termsNote,
            termsCondition,
          };
        },
      )
    : [];

  function onConditionTableRowSelect(id) {
    if (id === currentConditionId) {
      dispatch(setCurrentConditionId(null));
      return;
    }
    dispatch(setCurrentConditionId(id));
  }

  // function onTermOptionSelect(option) {
  //   if (!option) return;
  //   dispatch(setCurrentTimeUnitId(option.value));
  // }

  return {
    columns,
    termsStatusOptions,
    factoryConditionTableData,
    onConditionTableRowSelect,
    current,
    termsOptions,
    formTermsField,
  };
};

export default useFactoryConditionFormService;
