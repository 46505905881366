import {useTranslation} from "react-i18next";

import DateStatusCircle from "../../../../../utilities/DateStatusCircle";
import {DateInput} from "../../../../Inputs";

const PaymentDates = ({labelPlan, labelFact, ttType, name, createdDate, planned, actual}) => {
  const {t} = useTranslation();

  return (
    <div className="payment-dates">
      <div className="on-payment">
        <DateStatusCircle plannedDate={planned} createdAtDate={createdDate} actualDate={actual} />
        <div>{t("Date")}</div>
        <DateInput label={labelPlan} name={`${name}Plan`} readOnly />
      </div>
      <div className="on-payment">
        <div>{t("Date")}</div>
        <DateInput label={labelFact} question={ttType} name={`${name}Fact`} readOnly />
      </div>
    </div>
  );
};

export default PaymentDates;
