import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {fetchList} from "../../../../../../hook/axios.hook";
import {checkEmptyFields} from "../../../../../../utilities/checkEmptyFields";
import useToaster from "../../../../../../utilities/hooks/useToaster";
import {fetchAllStockPoolsNew} from "../../../../redux/operations";

const useTPreqDistribFilter = ({setSelectedFilters}) => {
  const dispatch = useDispatch();
  const Toaster = useToaster();

  const [areFiltersDefined, setAreFiltersDefined] = useState(false);
  const [allFilters, setAllFilters] = useState([]);

  useEffect(() => {
    fetchList(`/stockPools/getFilters`, "get", {params: {window: "Incoming"}}).then(resp => setAllFilters(resp ?? []));
  }, []);

  const initialValues = {managerCoId: "", countryId: "", brandId: ""};

  const brandOptions =
    allFilters && allFilters.brands ? allFilters.brands.map(brand => ({title: brand.name, value: brand.brandId})) : [];
  const coManagerOptions =
    allFilters && allFilters.coManagers
      ? allFilters.coManagers.map(coManager => ({title: coManager.name, value: coManager.managerCoId}))
      : [];
  const countryOptions =
    allFilters && allFilters.countries
      ? allFilters.countries.map(country => ({title: country.shortName, value: country.countryId}))
      : [];

  function onFilter(values) {
    const notEmptyValues = checkEmptyFields(values);

    if (Object.keys(notEmptyValues).length === 0) {
      Toaster.toastError("You must define at least one filter value first!");
      return;
    }

    setSelectedFilters(notEmptyValues);
    Toaster.toastSuccess("Filters were applied");
    setAreFiltersDefined(true);
    dispatch(fetchAllStockPoolsNew({window: "Incoming", ...notEmptyValues}));
  }
  function delFilters(setValues) {
    Toaster.toastSuccess("Filters were deleted");
    setAreFiltersDefined(false);
    dispatch(fetchAllStockPoolsNew({window: "Incoming"}));
    setValues(initialValues);
  }

  return {
    onFilter,
    delFilters,
    areFiltersDefined,
    brandOptions,
    coManagerOptions,
    countryOptions,
    initialValues,
  };
};

export default useTPreqDistribFilter;
