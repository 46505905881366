import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";

export const REQ_TEMPLATES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 5,
  },
  {
    header: "Factory",
    accessorKey: "name",
    size: 85,
  },
  {
    header: "Factory conditions",
    accessorKey: "factoryConditions",
    size: 95,
  },
  {
    header: "Contact person",
    accessorKey: "contactPerson",
    size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
    size: 15,
  },
]);
