import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import {PriceInput, TextInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";
import MoneyReadInput from "../../../../Inputs/MoneyReadInput";

const SummBlock = () => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {
    values: {amount, listPrice, retailPrice, discount, topDiscount, topDiscountCheck, discountCheck},
  } = useFormikContext();

  const [summ, setSumm] = useState(null),
    [total, setTotal] = useState(null),
    [toPayment, setToPayment] = useState(null);

  useEffect(() => {
    var initPrice = retailPrice || listPrice;
    var sum_ = +initPrice * (amount || 1);
    setSumm(sum_);
  }, [amount, retailPrice, listPrice]);

  useEffect(() => {
    setTotal(discountCheck && discount ? summ - (summ * discount) / 100 : summ);
  }, [summ, discount, discountCheck]);

  useEffect(() => {
    setToPayment(topDiscountCheck && topDiscount ? total - (total * topDiscount) / 100 : total);
  }, [summ, total, topDiscount, topDiscountCheck]);

  useEffect(() => {
    if (values?.sku?.minimumPrice && (topDiscountCheck || discountCheck)) {
      if (toPayment && +values?.sku?.minimumPrice > toPayment) {
        Swal.fire({
          text: t("kpf discount"),
          icon: "error",
          confirmButtonText: "Ok",
          showCancelButton: false,
        });
      }
    }
  }, [toPayment]);

  return (
    <div className="flex_container summ-block">
      <div className="summ-item">
        <PriceInput
          label={t("Price")}
          name="listPrice"
          width="6em"
          procent="100"
          readOnly={true}
          labelStyles={{width: "5em"}}
        />
      </div>
      <div className="summ-item">
        <PriceInput
          label={t("RR price")}
          name="retailPrice"
          procent={values?.retailPrice && values?.listPrice ? (values.retailPrice * 100) / values.listPrice : 0}
          readOnly={true}
          labelStyles={{width: "5em"}}
        />
      </div>
      <div className="summ-item">
        <FieldWithSample name="discountCheck" label={t("Discount")} style={{display: "flex", alignItems: "end"}}>
          <TextInput
            name="discount"
            type="number"
            width="6em"
            className="procent"
            min={0}
            max={100}
            generalClassName="summ-item"
          />
        </FieldWithSample>
      </div>
      <div className="summ-item">
        <FieldWithSample name="topDiscountCheck" label={t("Spec disc")} style={{display: "flex", alignItems: "end"}}>
          <TextInput name="topDiscount" width="6em" type="number" className="procent" min={0} max={100} />
        </FieldWithSample>
      </div>
      <div className="summ-item">
        <PriceInput
          label={t("Min price")}
          name="sku.minimumPrice"
          procent="100%"
          readOnly={true}
          labelStyles={{width: "5em"}}
        />
      </div>
      <TextInput
        label={t("Quantity")}
        name="amount"
        type="number"
        width="4em"
        min={1}
        generalClassName="summ-item"
        labelStyles={{width: "5em"}}
      />

      <div className="summ-item">
        <MoneyReadInput
          label={t("Sum")}
          name="summ"
          rawValue={summ}
          readOnly={true}
          width="8em"
          labelStyles={{width: "5em"}}
        />
      </div>

      <div className="summ-item">
        <MoneyReadInput
          label={t("Total")}
          name="total"
          rawValue={total}
          readOnly={true}
          width="8em"
          labelStyles={{width: "5em"}}
        />
      </div>

      <div className="summ-item">
        <MoneyReadInput
          label={t("Payment")}
          name="toPayment"
          rawValue={toPayment}
          readOnly={true}
          max={100}
          width="8em"
          labelStyles={{width: "5em"}}
        />
      </div>
      <div className="summ-item"></div>
    </div>
  );
};

export default SummBlock;
