import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import saveIcon from "../../../../images/icons/save.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../Inputs";
import CheckBoxWithLabel from "../../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel";
import PhoneInput from "../../../Inputs/PhoneInput";
import {Select} from "../../../Inputs/SelectField";

import useContactsAddService from "./utils/hooks/getContactsAddService";
import useNewFactoryContactsService from "./utils/hooks/getNewFactoryContactsService";

//create new conditions and contacts modal
const FactoryNewCondAndContacts = ({onAddContactBtnClick = () => {}}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {columns, contactTypeOptions, contactsTableData, onContactsTableRowSelect, current} = useContactsAddService();

  const {onSaveContactNoteBtnClick, contactNotePlaceholer, isContactNoteFieldDisabled} = useNewFactoryContactsService();

  return (
    <>
      <div style={{marginBottom: "0.5em"}}>2. {t("reprs contacts")}</div>

      <div className="edit_block">
        <div className="f_b_wrapper edit_item">
          <TextInput label="E-MAIL" name="email" type="text" width="100%" generalClassName="w100" />
        </div>

        <div className="f_tabl_wrapper edit_item edit_item_row"></div>

        <TextInput label={t("cont pers CP")} name="contactName" type="text" generalClassName="w100 edit_item" />

        <div className="edit_item edit_item_row edit_item_m">
          <Select
            label={t("Type")}
            name="contactTypeId"
            options={contactTypeOptions}
            multi={true}
            labelwidth="3em"
            width="9.5em"
          />
        </div>
        <div className="f_b_wrapper edit_item">
          <PhoneInput label={t("tel")} name="phone" inputProps={{name: "phone"}} type="text" width="80%" />
        </div>

        <div className="f_tabl_wrapper edit_item edit_item_row">
          <CheckBoxWithLabel label="mark as" checked />
        </div>
      </div>
      <ReactTable
        defaultData={contactsTableData}
        columns={columns}
        className="factory_edit_table"
        current={current}
        onSelect={onContactsTableRowSelect}
      />
      <div className="f_b_wrapper">
        <TextInput
          label={t("notes to cp")}
          name="newContactNotes"
          type="text"
          labelStyles={{width: "12em"}}
          width="100%"
          generalClassName="w100"
          placeholder={contactNotePlaceholer}
          disabled={isContactNoteFieldDisabled}
        />
        <ImageButton
          src={saveIcon}
          alt="save"
          width={1.5}
          height={1}
          onClick={() => onSaveContactNoteBtnClick(values)}
          disabled={isContactNoteFieldDisabled}
        />
      </div>
      <TextAreaInput name="notes" width={"101%"} readOnly />
      <div className="fcc jcfe" style={{marginTop: "0.5em"}}>
        <FunctionButton name="save cp sm" autoWidth onClick={() => onAddContactBtnClick(values)} />
      </div>
    </>
  );
};
FactoryNewCondAndContacts.propTypes = {onAddContactBtnClick: PropTypes.func};
export default FactoryNewCondAndContacts;
