import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {getSuccess, getWarn} from "../../../../utilities/toasts";
import SplitUniModal from "../../../Requests/OrderProcessing/splitOrderModal/SplitUniModal";
import {fetchOrderByRequest} from "../../../Requests/RequestsSlice";
import {deleteSplitedOrder, transferToOrder, updateSplitedOrder} from "../../redux/operations";
import {
  selectNewOrder,
  selectSplitedOrder,
  selectSplitedOrderSkus,
  selectWarehouseRequests,
  selectWarehouseStockPools,
} from "../../redux/selectors";

const SplitDefectedOrderModal = ({
  setModalActive,
  active,
  setCouldModalClose,
  updateAndCleanAfterRequest,
  isStatus,
}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const requests = useSelector(selectWarehouseRequests);
  const stockPools = useSelector(selectWarehouseStockPools);

  const splitedOrder = useSelector(selectSplitedOrder);
  const newOrder = useSelector(selectNewOrder);

  useEffect(() => {
    dispatch(fetchOrderByRequest());
  }, []);

  const [secondRequestSku, setSecondRequestSku] = useState([]);

  const onUnloadMethod = () => {
    return dispatch(deleteSplitedOrder(newOrder.orderId)).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        getWarn(t("Order splitting was cancelled"));
      }
    });
  };

  const selectedSkuRequest = requests?.find(item => item?.order?.orderId === splitedOrder?.orderId);

  const managerCoName =
    i18n.language === "en"
      ? selectedSkuRequest?.order?.co?.managerCo?.persona?.engFirstName +
        " " +
        selectedSkuRequest?.order?.co?.managerCo?.persona?.engLastName
      : selectedSkuRequest?.order?.co?.managerCo?.persona?.firstName +
        " " +
        selectedSkuRequest?.order?.co?.managerCo?.persona?.lastName;

  const managerFeaName =
    i18n.language === "en"
      ? selectedSkuRequest?.request?.managerFea?.persona?.engFirstName +
        " " +
        selectedSkuRequest?.request?.managerFea?.persona?.engLastName
      : selectedSkuRequest?.request?.managerFea?.persona?.firstName +
        " " +
        selectedSkuRequest?.request?.managerFea?.persona?.lastName;

  const initialValues = useMemo(() => {
    return {
      requestNumber: splitedOrder?.orderNumber || "",
      managerCo: managerCoName || "",
      managerFea: managerFeaName || "",
      secondRequestNumber: newOrder?.orderNumber || "",
      deliveryDate: "",
      note: "",
    };
  }, [splitedOrder, newOrder]);

  const onNoteUpdate = note => {
    dispatch(updateSplitedOrder({orderId: newOrder?.orderId, note: note}));
  };

  const onSeparate = () => {
    dispatch(
      transferToOrder({
        orderId: newOrder.orderId,
        params: {
          skuRequestIds: secondRequestSku.map(req => {
            return req.skuRequestId;
          }),
        },
      }),
    ).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        getSuccess(t("Request was splitted successfully"));
        Swal.fire({
          title: "",
          text: `${t("Split order", {spNumber: stockPools?.[0]?.stockPoolNumber})}`,
          icon: "info",
          confirmButtonText: t("Continue"),
        });
        setModalActive(false);
        updateAndCleanAfterRequest();
      }
    });
  };

  return (
    <div style={{height: "100%", maxWidth: "100%", maxHeight: "100%"}}>
      <SplitUniModal
        setCouldModalClose={setCouldModalClose}
        isStatus={isStatus}
        selector={selectSplitedOrderSkus}
        initialValues={initialValues}
        onUnloadMethod={onUnloadMethod}
        onSeparate={onSeparate}
        secondRequestSku={secondRequestSku}
        setSecondRequestSku={setSecondRequestSku}
        onNoteUpdate={onNoteUpdate}
      />
    </div>
  );
};

export default SplitDefectedOrderModal;
