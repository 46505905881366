export const SETTINGS_STOREGE_SERV_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Warehouse name",
    accessorKey: "wareHouseName",
  },
  {
    header: "Service type",
    accessorKey: "ServiceType",
  },
  {
    header: "Service name",
    accessorKey: "serviceName",
  },
  {
    header: "Currency",
    accessorKey: "currency",
  },
  {
    header: "Price(base)",
    accessorKey: "price",
  },
  {
    header: "Note",
    accessorKey: "note",
  },
]);

export const SETTINGS_STOREGE_SERV_TYPE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Service type",
    accessorKey: "serviceType",
  },
  {
    header: "Service type description",
    accessorKey: "serviceTypeDescription",
  },
]);

export const SETTINGS_STOREGE_TRECK_SCHEM_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Service type",
    accessorKey: "serviceType",
  },
  {
    header: "Currency",
    accessorKey: "currency",
  },
  {
    header: "Dt",
    accessorKey: "dt",
  },
  {
    header: "Kt",
    accessorKey: "kt",
  },
  {
    header: "Price",
    accessorKey: "price",
  },
  {
    header: "Purpose",
    accessorKey: "purpose",
  },
]);
