import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import {TextInput} from "../../../Inputs";
import UserSelect from "../../../Inputs/preparedInputs/UserSelect";
import {Select} from "../../../Inputs/SelectField";

import useFactoryDistributionService from "./useFactoryDistributionService";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const FactoryDistribution = () => {
  const {t} = useTranslation();

  const {
    productGroupOptions,
    factoryAppoinColumns,
    calcFilteredFactoryAppoinData,
    onReqAutodistrTableRowSelect,
    current,
    onAddBtnClick,
    onDeleteBtnClick,
    initialValues,
    areBtnsDisabled,
  } = useFactoryDistributionService();

  return (
    <Formik initialValues={initialValues}>
      {({values}) => (
        <Form>
          <section className={styles.reqAutodistrSection}>
            <h2 className={styles.sectionHeader}>
              1. <span className={styles.underLined}>{t("Request Autodistribution")}</span>
            </h2>
            <div className={styles.filtersRow}>
              <TextInput label={t("Factory")} name="factoryName" />
              <Select label={t("Product group")} name="productGroup" options={productGroupOptions} width="80%" />
              <UserSelect
                name="managerFea"
                width="200px"
                label={"Manager FEA"}
                className={styles.noTextWrap}
                labelwidth="120px"
              />
            </div>

            <div className={styles.sectionRow}>
              <ReactTable
                className={styles.reqAutodistrTableWrapper}
                tableClassName={styles.reqAutodistrTable}
                columns={factoryAppoinColumns}
                defaultData={calcFilteredFactoryAppoinData(values)}
                onSelect={onReqAutodistrTableRowSelect}
                current={current}
              />
            </div>
            <div className={styles.buttonsRow}>
              <FunctionButton
                name="Delete"
                disabled={areBtnsDisabled}
                className={styles.saveReqAutoBtn}
                onClick={() => onDeleteBtnClick(values)}
                autoWidth
              />
              <FunctionButton
                name="Add"
                disabled={areBtnsDisabled}
                className={styles.saveReqAutoBtn}
                onClick={() => onAddBtnClick(values)}
                autoWidth
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default FactoryDistribution;
