export default function formTerms({termsFrom, termsTo, unitId, timeUnits, language}) {
  if (!termsFrom && !termsFrom) return "";

  const timeUnitsString = timeUnits.reduce(
    (acc, {unitId, engName, name}) => ({
      ...acc,
      [unitId]: language === "en" ? engName[0] : name[0],
    }),
    {},
  );

  return language === "en"
    ? `from ${termsFrom} to ${termsTo} ${timeUnitsString[unitId]}`
    : `від ${termsFrom} до ${termsTo} ${timeUnitsString[unitId]}`;
}
