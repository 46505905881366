import {CARGOS_STORE_NAME} from "./CargosSlice";

export const selectAllCargos = state => state[CARGOS_STORE_NAME].all;
export const selectChosenCargo = state => state[CARGOS_STORE_NAME].chosen;
export const selectAddedCargo = state => state[CARGOS_STORE_NAME].added;

export const selectCargosPaymentDocTypes = state => state[CARGOS_STORE_NAME].paymentDocTypes;
export const selectAllCargosPaymentDocs = state => state[CARGOS_STORE_NAME].allPaymentDocs;
export const selectCargosPaymentDoc = state => state[CARGOS_STORE_NAME].paymentDoc;
export const selectCurrentCargoDocFile = state => state[CARGOS_STORE_NAME].currentDocFile;

export const selectCargosDocTypes = state => state[CARGOS_STORE_NAME].cargoDocTypes;
export const selectAllCargosDocs = state => state[CARGOS_STORE_NAME].allCargoDocs;

export const selectCargosLoading = state => state[CARGOS_STORE_NAME].cargosLoading;
