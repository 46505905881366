import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import delIcon from "../../../../../images/icons/delete.png";
import saveIcon from "../../../../../images/icons/save.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

export const LOG_REQUISITES_BLOCK_TYPES = Object.freeze({WAREHOUSE: "warehouse", TRANSPORTER: "transporter"});

const LogRequisites = ({
  requisiteOptions,
  type,
  onAddMoreRequisitesBtnClick,
  onSaveRequisiteBtnClick,
  onDeleteRequisiteBtnClick,
  onRequisiteNumberChange,
  disabled = false,
}) => {
  const {t} = useTranslation();
  const {setValues, values} = useFormikContext();

  const fieldsDisabled =
    disabled ||
    values?.requisitesNumber === "" ||
    !values?.requisitesNumber ||
    (values?.requisitesNumber &&
      values?.requisitesNumber !== "" &&
      !values["requisitesNumber"].toString().includes("temp#"));

  return (
    <div>
      <div className="row">
        <Select
          label={
            type === LOG_REQUISITES_BLOCK_TYPES.WAREHOUSE ? t("Warehouse requisites") : t("Transporter requisites")
          }
          name="requisitesNumber"
          options={requisiteOptions}
          width="5rem"
          labelClassName="underlined"
          makeEffect={option => onRequisiteNumberChange({option, setValues})}
          selectOnly={true}
          readOnly={disabled}
        />
        <FunctionButton name="Add more..." autoWidth onClick={onAddMoreRequisitesBtnClick} disabled={disabled} />
      </div>
      <TextInput readOnly={fieldsDisabled} label={t("BENEFICIARY")} name={"beneficiary"} autolabel />
      <TextInput readOnly={fieldsDisabled} label={t("BANK of Beneficiary")} name={"benefBank"} autolabel />
      <TextInput readOnly={fieldsDisabled} label={t("SWIFT CODE")} name={"swiftCode"} autolabel />
      <TextInput readOnly={fieldsDisabled} label={t("IBAN")} name={"iban"} autolabel />
      <div className="row">
        <TextInput readOnly={fieldsDisabled} label={t("Account")} name={"account"} autolabel generalClassName="fGrow" />
        <div className="fc gap0_5">
          <ImageButton
            src={saveIcon}
            alt="save"
            width={2}
            height={1.3}
            onClick={onSaveRequisiteBtnClick}
            disabled={fieldsDisabled}
            tooltipMessage="Save new requisites"
          />
          <ImageButton
            src={delIcon}
            alt="delete"
            width={1.2}
            height={1.2}
            onClick={onDeleteRequisiteBtnClick}
            disabled={
              disabled ||
              values?.requisitesNumber === "" ||
              !values?.requisitesNumber ||
              (values?.requisitesNumber &&
                values?.requisitesNumber !== "" &&
                values["requisitesNumber"].toString().includes("temp#"))
            }
            tooltipMessage="Delete selected requisites"
          />
        </div>
      </div>
    </div>
  );
};

LogRequisites.propTypes = {
  requisiteOptions: PropTypes.array,
  type: PropTypes.oneOf(Object.values(LOG_REQUISITES_BLOCK_TYPES)).isRequired,
  onAddMoreRequisitesBtnClick: PropTypes.func,
  onSaveRequisiteBtnClick: PropTypes.func,
  onDeleteRequisiteBtnClick: PropTypes.func,
  onRequisiteNumberChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default LogRequisites;
