import {dateFormat} from "./../../../../../../../utilities/dateFormat";
export default function getApprovalDates(proforma, currentApprovalId) {
  if (!proforma || !currentApprovalId || proforma.length === 0)
    return {controlPlannedPfDate: "", controlActualPfDate: ""};
  const currentApproval = proforma.approvals.find(approval => approval.approvalId === currentApprovalId);
  return {
    controlPlannedPfDate: dateFormat(currentApproval?.createdAt),
    controlActualPfDate: dateFormat(currentApproval?.updatedAt),
  };
}
