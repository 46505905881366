import {useTranslation} from "react-i18next";

import ReactTable from "../../../../../../conteiners/ReactTable";

import useFinSchemaToFactoryService from "./useFinSchemeToFactoryService";

const FinSchemaToFactory = () => {
  const {t} = useTranslation();

  const {finSchemaTableColumns, finSchemaTableData} = useFinSchemaToFactoryService();

  return (
    <>
      <div className="header-blocks q_wrapper">{t("Financial payment scheme")}</div>
      <ReactTable
        defaultData={finSchemaTableData}
        columns={finSchemaTableColumns}
        current={null}
        className={"project_table"}
        style={{height: "4em"}}
        selectable={false}
      />
    </>
  );
};

export default FinSchemaToFactory;
