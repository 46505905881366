import React from "react";
import {useTranslation} from "react-i18next";

import ReactTable from "../../../../../../conteiners/ReactTable";
import PaymentDates from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/PaymentDates";

import useTopBlockService from "./useTopBlockService";

const TopBlock = () => {
  const {t} = useTranslation();

  const {createdDate, planned, actual, onPaymentTableData, onPaymentTableColumnes, onPaymentTableRowSelect, current} =
    useTopBlockService();

  return (
    <>
      <div className="fc jcsb aibl">
        <div className="header-blocks header-blocks_last">1.{t("For payment list") + ":"} </div>
        <PaymentDates
          labelPlan={t("on payment (p)")}
          labelFact={t("on payment (f)")}
          name={"onPayment"}
          createdDate={createdDate}
          planned={planned}
          actual={actual}
        />
      </div>
      <ReactTable
        defaultData={onPaymentTableData}
        columns={onPaymentTableColumnes}
        onSelect={onPaymentTableRowSelect}
        current={current}
        className={"project_table"}
        style={{minHeight: "2em", marginBottom: "10px"}}
      />
    </>
  );
};

export default TopBlock;
