import {useCallback, useMemo} from "react";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

import {LOADING_STATUS} from "./constants";

const ViewTable = ({thsName, items, loadingStatus}) => {
  const formThs = useCallback(thList => {
    var ths = [];
    var i = 0;
    for (var prop in thList) {
      ths.push(
        <th key={i} width={thList[prop] + "%"}>
          {prop}
        </th>,
      );
      i++;
    }
    return ths;
  }, []);
  const formTds = data => {
    var tds = [];
    var i = 1;

    for (var prop in data) {
      tds.push(
        <td title={data[prop]} key={i}>
          {data[prop]}
        </td>,
      );

      i++;
      if (i > Object.keys(thsName).length) break;
    }
    return tds;
  };

  const itemRefs = useMemo(() => {
    return [];
  }, [items]);
  const ths = useMemo(() => {
    return formThs(thsName);
  }, []);

  function formContent(items) {
    if (items && items.length !== 0) {
      return items.map(item => {
        const tds = formTds(item);
        return <tr key={item.id}>{tds}</tr>;
      });
    }
  }

  const content = formContent(items, itemRefs);

  return (
    <table className="project_table">
      <tbody>
        <tr>{ths}</tr>
        {loadingStatus === LOADING_STATUS.COMPLETE || loadingStatus === LOADING_STATUS.IDLE ? (
          content
        ) : (
          <tr>
            <td colSpan="5" rowSpan={5}>
              <Spinner />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

ViewTable.propTypes = {
  thsName: PropTypes.object,
  items: PropTypes.array,
  loadingStatus: PropTypes.oneOf(Object.values(LOADING_STATUS)),
};

export default ViewTable;
