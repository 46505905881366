import React from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import ReadOnlyFields from "../../../../hoc/ReadOnlyFields";
import FieldWithSample from "../../../../Inputs/FieldWithSample";
import useVWCharInputsService from "../../../../SKU/viewWidthCharInputs/vwCharInputsService";

const VolumeWeightCharacteristicsKpf = () => {
  const {values} = useFormikContext();

  const {t} = useTranslation();

  const {area, dimension, volume, weight} = useVWCharInputsService("6em", "Dimension");

  return (
    <div className="" style={{display: "flex", flexDirection: "row", gap: "10px"}}>
      <ReadOnlyFields>
        {values?.length !== null ||
        values?.width !== null ||
        (values?.height !== null && (+values?.length > 0 || +values?.width > 0 || +values?.height > 0)) ? (
          <FieldWithSample name="overallSizeCheck" style={{alignItems: "self-end", width: "auto"}}>
            {dimension}
          </FieldWithSample>
        ) : null}
        {values?.area !== null && +values?.area > 0 && (
          <FieldWithSample name="areaCheck" style={{alignItems: "self-end", width: "auto"}}>
            {area}
          </FieldWithSample>
        )}
        {values?.volume !== null && +values?.volume > 0 && (
          <FieldWithSample name="volumeCheck" style={{alignItems: "self-end", width: "auto"}}>
            {volume}
          </FieldWithSample>
        )}
        {values?.weight !== null && +values?.weight > 0 && (
          <FieldWithSample name="weightCheck" style={{alignItems: "self-end", width: "auto"}}>
            {weight}
          </FieldWithSample>
        )}
        {values?.pallet !== null && (
          <FieldWithSample name="palletCheck" style={{alignItems: "self-end", width: "auto"}}>
            <div className=" nowrap autolabel">
              <label>{t("Pallet")}</label>

              <input name="" value={values.pallet?.name} readOnly={true} style={{width: "7em"}} />

              <label>{t("Length")}</label>
              <input name="" value={values.pallet?.length} readOnly={true} style={{width: "5em"}} />
              <label>{t("Width")}</label>
              <input name="" value={values.pallet?.width} readOnly={true} style={{width: "5em"}} />
            </div>
          </FieldWithSample>
        )}
      </ReadOnlyFields>
    </div>
  );
};

export default VolumeWeightCharacteristicsKpf;
