import accounting from "accounting";

export function moneyFormat(number) {
  const options = {
    symbol: "€",
    decimal: ".",
    thousand: "'",
    precision: 2,
    format: "%s%v",
  };

  return accounting.formatMoney(number, options);
}

export function numberFormat(number) {
  const options = {
    decimal: ".",
    thousand: "'",
    precision: 2,
    format: "%s%v",
  };
  const res = accounting.formatNumber(number, options);
  return res == 0 ? "" : res;
}
