import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {
  getCurrentKp,
  getCurrentSubKp,
  getCurrentSubKpDocs,
  getKpInfoForKp,
  makeOrder,
  saveKpfAsMain,
  selectSubKp,
} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getError, getSuccess} from "../../../../utilities/toasts";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";

import "./KpPreview.scss";

const KpPreview = () => {
  const {t} = useTranslation();

  const currentSubKp = useSelector(getCurrentSubKp),
    docsArray = useSelector(getCurrentSubKpDocs),
    currentKp = useSelector(getCurrentKp);

  const dispatch = useDispatch();

  const [savedSubKp, setSavedSubKp] = useState(null);

  const onSaveAsMain = () => {
    Swal.fire({
      title: "",
      text: `${t("CO will be saved as the main one", {cofNumber: currentSubKp.cofNumber})}`,
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        if (!currentSubKp.docsArray) {
          getError(t("cof without data"));
        } else {
          const cursubkp = currentSubKp.cofId;
          dispatch(saveKpfAsMain(currentSubKp.cofId))
            .then(({meta}) => {
              if (meta.requestStatus === "fulfilled") {
                getSuccess(t("CO was successfully saved as the main one", {cofNumber: currentSubKp.cofNumber}));
                return dispatch(getKpInfoForKp(currentKp.coId));
              }
            })
            .then(res => {
              if (res.meta.requestStatus === "fulfilled") {
                dispatch(selectSubKp({kp: cursubkp, type: "f"}));
              }
            });
        }
      }
    });
  };
  const navigate = useNavigate();

  const onMakeOrder = () => {
    if (!currentKp?.bringTo) {
      Swal.fire({
        title: "",
        text: `${t("enter bringTo at first")}`,
        icon: "error",
        confirmButtonText: t("Go to project"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(answ => {
        if (answ.isConfirmed) {
          navigate("/ba3a/project", {state: {currentKpId: currentKp.coId, currentProjectId: currentKp.projectId}});
        }
      });
      return;
    }
    Swal.fire({
      title: "",
      text: `${t("Will be created an order for FEA")}`,
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(makeOrder(currentSubKp.cofId)).then(({meta}) => {
          if (meta.requestStatus === "fulfilled") {
            getSuccess(t("An order for FEA was successfully created"));
            // navigate("/ba3a/fea/reqRegistr");
          }
        });
      }
    });
  };

  return (
    <div className="kpPreview__container">
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>
          <label className="label">
            {t("CO preview")}
            {currentSubKp?.cofNumber || currentSubKp?.coaNumber}
          </label>
          <p></p>
        </div>
      </div>

      <div className="kpPreview__box">
        <NewPdfViewer fileArray={docsArray || []} />
      </div>
      <div style={{gap: "1.5em", justifyContent: "space-between"}} className="fc">
        <FunctionButton
          name={t("Save as main")}
          style={{width: "200px"}}
          disabled={currentSubKp?.isMain || !currentSubKp}
          onClick={onSaveAsMain}
        />
        <FunctionButton
          name={t("Create an Order for FEA")}
          autoWidth
          disabled={!currentSubKp?.isMain || !currentSubKp}
          onClick={onMakeOrder}
        />
      </div>
    </div>
  );
};

export default KpPreview;
