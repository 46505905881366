import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {withFormik} from "formik";

import {Select} from "../../../../Inputs/SelectField";
import {selectCarrierFilters} from "../../../redux/Carriers/selectors";
import {selectWarehouseFilters} from "../../../redux/Warehouses/selectors";

import withLogSettingsFilters from "./withLogSettingsFilters";

export const LOG_SETTINGS_FILTERS_TYPES = Object.freeze({STORAGE: "storage", TRANSPORTER: "transporter"});

const BaseLogSettingsFilters = ({type}) => {
  const {
    i18n: {language},
    t,
  } = useTranslation();

  const storedWarehouseFilters = useSelector(selectWarehouseFilters);
  const storedCarrierFilters = useSelector(selectCarrierFilters);
  const storedFilters = type === LOG_SETTINGS_FILTERS_TYPES.TRANSPORTER ? storedCarrierFilters : storedWarehouseFilters;

  const typeOptions =
    storedFilters?.types?.map(({typeId, engName, ukrName}) => ({
      title: language === "en" ? engName : ukrName,
      value: typeId,
    })) ?? [];
  const nameOptions =
    storedFilters?.names?.map(name => ({
      title: name,
      value: name,
    })) ?? [];
  const addressOptions =
    storedFilters?.addresses?.map(address => ({
      title: address,
      value: address,
    })) ?? [];
  const noteOptions =
    storedFilters?.notes?.map(note => ({
      title: note,
      value: note,
    })) ?? [];

  return (
    <div className="f_block w100" style={{paddingTop: "0.7em"}}>
      <Select label={t("Type") + ":"} name="typeId" options={typeOptions} width="100%" />
      <Select label={t("Address") + ":"} name="address" options={addressOptions} width="100%" />
      <Select label={t("Title") + ":"} name="name" options={nameOptions} width="100%" />
      <Select label={t("Note") + ":"} name="note" options={noteOptions} width="100%" />
    </div>
  );
};

const LogSettingsFiltersRow = withLogSettingsFilters(BaseLogSettingsFilters);

const LogSettingsFilters = withFormik({mapPropsToValues: () => ({}), handleSubmit: () => {}, enableReinitialize: true})(
  LogSettingsFiltersRow,
);

export default LogSettingsFilters;
