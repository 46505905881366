import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

import {LOADING_STATUS} from "./constants";

const TableWithSelect = ({
  thsName,
  items,
  current,
  onSelect,
  loadingStatus,
  paginatable = false,
  onPaginate,
  formatStatus,
  ...props
}) => {
  //!scrollX isn`t used

  const scrollContainerRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const handleScroll = () => {
    const scrollTop = scrollContainerRef.current.scrollTop,
      clientHeight = scrollContainerRef.current.clientHeight,
      scrollHeight = scrollContainerRef.current.scrollHeight;
    // Проверяем, достигнут ли скроллом низ контейнера
    const isBottom = Math.ceil(scrollTop + clientHeight + 5) > scrollHeight;
    setIsScrolledToBottom(isBottom);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (paginatable) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    // add event listener for Tab functionality
    scrollContainer.addEventListener("keydown", handleTabKeyPress);

    return () => {
      if (paginatable) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }

      // remove event listener for Tab functionality
      scrollContainer.removeEventListener("keydown", handleTabKeyPress);
    };
  }, [items, activeRowIndex]);

  useEffect(() => {
    if (isScrolledToBottom) {
      if (loadingStatus === "loading" || loadingStatus === true) return;
      onPaginate();
    }
  }, [isScrolledToBottom]);

  const formThs = useCallback(thList => {
    var ths = [];
    var i = 0;
    for (var prop in thList) {
      ths.push(
        <th key={i} width={thList[prop] + "%"}>
          {prop}
        </th>,
      );
      i++;
    }

    return ths;
  }, []);
  const formTds = data => {
    // console.log("data", data);
    var tds = [];
    var i = 1;

    for (var prop in data) {
      tds.push(
        <td title={data[prop] ? data[prop] : ""} key={i}>
          {data[prop]}
        </td>,
      );

      i++;
      if (i > Object.keys(thsName).length) break;
    }

    return tds;
  };

  const itemRefs = useMemo(() => {
    return [];
  }, [items]);

  const ths = useMemo(() => {
    return formThs(thsName);
  }, []);

  function formContent() {
    if (items && items.length !== 0) {
      if (Object.prototype.hasOwnProperty.call(items[0], "status")) {
        Object.prototype.hasOwnProperty.call(items[0], "status");
        return items.map((item, i) => {
          const statusInfo = formatStatus(item.status);
          // console.log("statusInfo", statusInfo);
          const tds = formTds(item, 55);
          return (
            <tr
              style={{color: statusInfo[1], cursor: "pointer"}}
              key={`${item.i}-${item.id}`}
              onClick={() => {
                onSelect(item.id, item.type);
                focusOnItem(i);
              }}
              ref={el => (itemRefs[i] = {element: el, itemId: item.id})}
            >
              {tds.slice(0, -1)}
              <td>{statusInfo[0]}</td>
            </tr>
          );
        });
      } else
        return items.map((item, i) => {
          const tds = formTds(item);
          return (
            <tr
              key={item.id}
              onClick={() => {
                onSelect(item.id, item.type);
                focusOnItem(i);
              }}
              ref={el => (itemRefs[i] = {element: el, itemId: item.id})}
              style={{cursor: "pointer"}}
            >
              {tds}
            </tr>
          );
        });
    }
  }
  const focusOnItem = id => {
    itemRefs.forEach((item, i) => {
      item.element.classList.remove("item_selected");
    });
    itemRefs[id].element.classList.add("item_selected");

    setActiveRowIndex(id);
  };

  useEffect(() => {
    if (current) {
      itemRefs.forEach((item, i) => {
        if (item.itemId === current) focusOnItem(i);
      });
    } else {
      itemRefs.forEach(item => {
        item.element.classList.remove("item_selected");
      });
    }
  }, [current, itemRefs]);

  const content = formContent(items, itemRefs);

  const handleTabKeyPress = event => {
    if (event.key === "Tab" && items.length > 0) {
      event.preventDefault();
      const currentActiveIndex = activeRowIndex !== null ? activeRowIndex : -1;
      const nextActiveIndex = (currentActiveIndex + 1) % items.length;
      setActiveRowIndex(nextActiveIndex);
      focusOnItem(nextActiveIndex);

      const selectedItem = items[nextActiveIndex];
      if (selectedItem) {
        onSelect(selectedItem.id);
      }
    }
  };

  return (
    <div
      ref={scrollContainerRef}
      className={`project_list`}
      tabIndex={-1}
      style={{height: "100%", overflowX: "hidden"}}
    >
      <table className={`project_table fixedLayout`} {...props}>
        <thead>
          <tr>{ths}</tr>
        </thead>
        <tbody>
          {content}
          {loadingStatus === LOADING_STATUS.COMPLETE || loadingStatus === LOADING_STATUS.IDLE ? null : (
            <tr>
              <td colSpan="5" rowSpan={5}>
                <Spinner />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

TableWithSelect.propTypes = {
  thsName: PropTypes.object,
  items: PropTypes.array,
  // current: PropTypes.number,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null]), PropTypes.string]),
  onSelect: PropTypes.func,
  loadingStatus: PropTypes.oneOf(["complete", "idle", "loading", "error"]),
  scrollX: PropTypes.bool,
  formatStatus: PropTypes.func,
  props: PropTypes.array,
};

export default TableWithSelect;
