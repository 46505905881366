import {useTranslation} from "react-i18next";

import crossIcon from "../../../../../images/icons/delete.png";
import saveIcon from "../../../../../images/icons/save.png";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../../../utilities/ToolTip";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../../Inputs";
import PdfFilePreview from "../../../../Inputs/fileSelect/preview/PdfPreview";
import {Select} from "../../../../Inputs/SelectField";

import useTransporterPayment from "./useTransporterPayment";

const TransporterPayment = ({currentSkuRequest, setPdfFileArray}) => {
  const {t} = useTranslation();
  const disabled = !currentSkuRequest;

  const {
    docTypePaymentOptions,
    transporterPaymentTableColumns,
    transporterPaymentTableData,
    onSaveDocBtnClick,
    isSaveDocBtnDisabled,
    currentDocId,
    onDocSelect,
    onDocFileAdd,
    onDocDelete,
    onFileDel,
    currentDoc,
    areCargosLoading,
  } = useTransporterPayment({currentSkuRequest, setPdfFileArray});

  return (
    <div className="mb0_5">
      <div className="fdr aibl jcsb">
        <div className="fdr">
          <div>3.</div>
          <div className="label underline">{t("Transporters Payment")}</div>
        </div>
        <Select
          label={t("Document type")}
          name="docTypePayment"
          options={docTypePaymentOptions}
          autolabel
          disabled={disabled}
        />
      </div>

      <div className="fdr jcsb gap">
        <DateInput label={t("Document, Date")} name="onDocDatePayment" autolabel disabled={disabled} />
        <TextInput name="docNumberPayment" label="№" question="d numb" autolabel disabled={disabled} />

        <div className="fdr">
          <ImageButton
            src={saveIcon}
            alt="save"
            className="btn__upload"
            width={2.3}
            height={1.5}
            disabled={disabled || isSaveDocBtnDisabled}
            onClick={onSaveDocBtnClick}
            tooltipMessage="Save doc (before adding files)"
          />
          <ToolTip title="Save/delete doc file" zIndex={1000}>
            <PdfFilePreview
              files={currentDoc?.files?.map(file => file.fileUrl) || []}
              accept={".pdf"}
              delFunc={onFileDel}
              addFunc={onDocFileAdd}
              disable={disabled || !currentDocId}
              loading={areCargosLoading}
            />
          </ToolTip>
          <ImageButton
            src={crossIcon}
            alt="delete"
            className="btn__upload"
            width={1.5}
            height={1.5}
            disabled={disabled || !currentDocId || currentDoc?.files.length > 0}
            onClick={onDocDelete}
            tooltipMessage="Delete doc. You must delete all the doc files first!"
          />
        </div>
      </div>
      <ReactTable
        defaultData={transporterPaymentTableData}
        columns={transporterPaymentTableColumns}
        onSelect={onDocSelect}
        current={currentDocId}
        loading={currentSkuRequest && areCargosLoading}
        style={{height: "6em"}}
        className="tableTac overflowX"
      />
    </div>
  );
};

export default TransporterPayment;
