import {useTranslation} from "react-i18next";

const useNameLangService = () => {
  const {
    i18n: {language},
  } = useTranslation();
  return language === "en" ? "Eng" : "Ukr";
};

export default useNameLangService;
