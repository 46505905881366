import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {getCurentOrder, getRequestInfo, setCurentOrder} from "../../../Requests/RequestsSlice";

import {ORDER_INFO_COLUMNS} from "./constants";

const useOrderIndoService = orders => {
  const curentOrder = useSelector(getCurentOrder);
  console.log("curentOrder :", curentOrder);
  const dispatch = useDispatch();

  const [currentOrderId, setCurrentOrderId] = useState(null);

  const orderTableColumns = useColumns(ORDER_INFO_COLUMNS);
  // const curentRequest = useSelector(getRequestInfo);

  useEffect(() => {
    return () => {
      setCurrentOrderId(null);
    };
  }, []);

  const orderTableData = useMemo(() => {
    return (
      orders?.map((order, i) => ({
        number: i + 1,
        id: order.orderId,
        orderNumber: order.orderNumber,
        orderSumm: "",
        orderCountry: order?.co?.project?.country?.fullName || "",
        note: order?.co?.note || "",
      })) || []
    );
  }, [orders]);

  const onOrderSelect = id => {
    if (id) {
      const order = orders.find(order => order.orderId === id);
      dispatch(setCurentOrder(order));
      setCurrentOrderId(id);
    } else {
      dispatch(setCurentOrder(null));
      setCurrentOrderId(null);
    }
  };
  return {orderTableColumns, orderTableData, curentOrder, onOrderSelect, currentOrderId};
};

export default useOrderIndoService;
