import {Form, Formik} from "formik";

import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import DeliveryInfo from "../common/DeliveryInfo/DeliveryInfo";
import DocumentsInfo from "../common/DocumentsInfo/DocumentsInfo";
import {GROUP_AND_TYPE_MODES} from "../common/GroupAndType/constants";
import GroupAndType from "../common/GroupAndType/GroupAndType";
import HeaderInputRow from "../common/HeaderInputRow/HeaderInputRow";
import OrderFullSection from "../common/OrderFullSection/OrderFullSection";
import ShipmentInfo from "../common/ShipmentInfo/ShipmentInfo";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";

import StockPoolInfo from "./StockPoolInfo/StockPoolInfo";
import useLogModTpPoolCheckList from "./useLogModTpPoolCheckList";

const LogModTpPoolCheckList = ({onItemOpen, setTabComponent}) => {
  const {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    currentSP,
    documentsTableData,
    chosenCargo,
  } = useLogModTpPoolCheckList();

  return (
    <div className="page_flex_container">
      <Formik initialValues={initialValues} enableReinitialize={true}>
        <Form className="w100 fcCol flexg1">
          <HeaderInputRow headerInputNames={headerInputNames} />
          <Divider />
          <OrderFullSection
            firstColumn={
              <>
                <StockPoolInfo chosenCargo={chosenCargo} setTabComponent={setTabComponent} onItemOpen={onItemOpen} />
                <DeliveryInfo />
                <CheckListModalsTodo path={"stockPool"} entityId={currentSP?.stockPoolId || null} />
              </>
            }
            secondColumn={
              <>
                <GroupAndType mode={GROUP_AND_TYPE_MODES.TP} orders={currentSP?.orders || []} />
                <DocumentsInfo documentsTableData={documentsTableData} />
                <ShipmentInfo packingSpaces={currentSP?.packingSpaces || []} type="TP" />
              </>
            }
          ></OrderFullSection>
          <ReactTable
            columns={orderTableColumns}
            defaultData={orderTableData}
            className="w100 pb"
            style={{height: "250px", flexGrow: 1}}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default LogModTpPoolCheckList;
