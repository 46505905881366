import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput} from "../../../Inputs";

import {GROUP_AND_TYPE_MODES} from "./constants";
import useGroupAndTypeService from "./useGroupAndTypeService";

const GroupAndType = ({headerNumber = 3, mode = GROUP_AND_TYPE_MODES.ORDER, orders, height = "7.5em"}) => {
  const {t} = useTranslation();
  const {groupAndTypeTableColumns, groupAndTypeTableData} = useGroupAndTypeService(mode, orders);

  return mode === GROUP_AND_TYPE_MODES.TEXTAREA ? (
    <div>
      <div className="fc jcsb aibl">
        <div className="header-block mb0">{`${headerNumber}. ${t("Group and Type")}:`}</div>
      </div>
      <TextAreaInput name="groupAndTypeInfo" height="60px" readOnly />
    </div>
  ) : (
    <div>
      <div className="fc jcsb aibl">
        <div className="header-block">{`${headerNumber}. ${t("Group and Type")}:`}</div>
        {/* <TextInput label={t("Order № ")} name="orderNumber" autolabel className="mb0_5" /> */}
      </div>

      <ReactTable
        selectable={false}
        columns={groupAndTypeTableColumns}
        defaultData={groupAndTypeTableData}
        style={{height: height}}
      />
    </div>
  );
};

GroupAndType.propTypes = {
  headerNumber: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(GROUP_AND_TYPE_MODES)),
};
export default GroupAndType;
