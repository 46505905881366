import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {STATUS_COLORS} from "../../../utilities/colorConstants";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import {makeRegisterRqstsTableData, makeRegisterSPTableData} from "../common/functions";
import {setFetchedStockPool} from "../redux/LogistSlice";
import {cancelAllStockPoolAppointments, fetchAllStockPoolsNew, fetchStockPoolFilters} from "../redux/operations";
import {
  selectBossRequests,
  selectBossStockPools,
  selectFetchedStockPool,
  selectIncomingStockPools,
  selectIsLogistLoading,
} from "../redux/selectors";

const useTpListBossService = ({isBossModalOpen, setBossModalOpen}) => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  useEffect(() => {
    dispatch(setFetchedStockPool(null));
    dispatch(fetchAllStockPoolsNew({window: "Incoming"}));
    dispatch(fetchStockPoolFilters({window: "Boss"}));
    dispatch(cancelAllStockPoolAppointments());
  }, []);

  const bossFilterManager = makeFilterManager(LOGIST_WINDOW_TYPES.BOSS);
  const savedFilters = bossFilterManager.getFilters();

  useEffect(() => {
    dispatch(fetchAllStockPoolsNew({window: "Boss", ...savedFilters}));
  }, [isBossModalOpen]);

  const isLoading = useSelector(selectIsLogistLoading);
  const bossStockPools = useSelector(selectBossStockPools);
  const bossRequests = useSelector(selectBossRequests);
  const incomingStockPools = useSelector(selectIncomingStockPools);
  const selectedSP = useSelector(selectFetchedStockPool);

  const leftSideTableData = makeRegisterSPTableData(bossStockPools, i18n.language);
  const tpListBossTableData = makeRegisterRqstsTableData(bossRequests, true);
  const statusBtnColor = incomingStockPools && incomingStockPools.length > 0 ? STATUS_COLORS.RED : STATUS_COLORS.GREEN;
  const isStatusBtnDisabled = !incomingStockPools || incomingStockPools.length === 0;
  const areFiltersDisabled = Boolean(selectedSP);
  // const initialValues = {
  //   reqNumber: "",
  //   atFactoryDate: "",
  //   addedToSPDate: "",
  //   addedToTpDate: "",
  //   inTransitDate: "",
  //   inStockDate: "",
  // };

  const onLeftSideTableRowSelect = stockPoolId => {
    setTimeout(() => {
      if (!stockPoolId) {
        dispatch(setFetchedStockPool(null));
        dispatch(fetchAllStockPoolsNew({window: "Boss", ...savedFilters}));
        return;
      }
      dispatch(setFetchedStockPool(stockPoolId));
      dispatch(fetchAllStockPoolsNew({window: "Boss", ...savedFilters, stockPoolIds: stockPoolId}));
    }, 100);
  };
  const onTpListBossTableRowSelect = () => {};

  const onFactoryBtnClick = () => {};
  const onTransporterBtnClick = () => {};
  const onStorageBtnClick = () => {};
  const onStatusBtnClick = () => {
    setBossModalOpen(true);
  };
  // const onShowHistoryBtnClick = () => {};

  function onModalClose() {
    dispatch(cancelAllStockPoolAppointments()).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllStockPoolsNew({window: "Incoming"}));
      }
    });
    setBossModalOpen(false);
  }

  return {
    tpListBossTableData,
    onTpListBossTableRowSelect,
    isLoading,
    onFactoryBtnClick,
    onTransporterBtnClick,
    onStorageBtnClick,
    leftSideTableData,
    onLeftSideTableRowSelect,
    statusBtnColor,
    onStatusBtnClick,
    // onShowHistoryBtnClick,
    // initialValues,
    onModalClose,
    isStatusBtnDisabled,
    areFiltersDisabled,
    selectedSP,
  };
};

export default useTpListBossService;
