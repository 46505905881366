export const columnsForPayment = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: <input type="radio" name="headerR" value={""} checked disabled={true} style={{height: "0.7em"}} />,
    accessorKey: "radioBtn",
    size: 15,
    cell: value => {
      var key = value.getValue();
      if (key) {
        return (
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <input type="radio" name="paymentDocRadio" value={key} />
          </div>
        );
      }
    },
  },
  {
    header: "Doc. for payment",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    // size: 100,
  },
  // {
  //   header: "Notes",
  //   accessorKey: "notes",
  //   // size: 100,
  // },
];

export const paymentColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Doc. for payment",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    // size: 100,
  },
  {
    header: "Note for factory manager",
    accessorKey: "notes",
    // size: 100,
  },
];

export const FIN_SCHEMA_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 10,
  },
  // {
  //   header: <input type="radio" name="headerR" value={""} checked disabled={true} style={{height: "0.7em"}} />,
  //   accessorKey: "radioBtn",
  //   size: 15,
  //   cell: value => {
  //     var key = value.getValue();
  //     if (key) {
  //       return (
  //         <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
  //           <input type="radio" name="paymentSchemasRadio" value={key} />
  //         </div>
  //       );
  //     }
  //   },
  // },
  {
    header: "Payment scheme",
    accessorKey: "name",
    // size: 100,
  },
  {
    header: "Description",
    accessorKey: "desc",
    // size: 100,
  },
]);

export const onPaymentColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Status*",
    accessorKey: "status",
    // size: 15,
  },
  {
    header: "Doc. for payment N",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "pDocType",
    // size: 100,
  },
  {
    header: "Payment scheme",
    accessorKey: "paymentSchema",
    // size: 100,
  },
  {
    header: "Note for CO manager",
    accessorKey: "noteToFD",
    // size: 100,
  },
  {
    header: "Payment doc",
    accessorKey: "cDocType",
    // size: 100,
  },
  {
    header: "Payment doc N",
    accessorKey: "confirmingDoc",
    // size: 100,
  },
  {
    header: "Note for FEA manager",
    accessorKey: "noteToFEA",
    // size: 100,
  },
];
