// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reminder input[type=text] {
  width: 90%;
}
.reminder button {
  border: 0;
  background: none;
  cursor: pointer;
}
.reminder button:disabled {
  cursor: auto;
}
.reminder_note {
  box-sizing: border-box;
}

.reminder_table {
  margin-top: 5px;
}

.reminder_form {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 0.5em;
}

.datePicker {
  position: relative;
}
.datePicker input {
  left: 2px;
  top: 3px;
  color: rgba(204, 38, 38, 0);
  z-index: 2;
  position: absolute;
  width: 15px;
  height: 15px;
  padding: 0;
  border: 0;
  background-color: rgba(255, 255, 255, 0);
}
.datePicker input:focus {
  outline: none;
  border-bottom: 0;
}
.datePicker input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}
.datePicker img {
  left: 0;
  top: 0;
  z-index: 1;
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Reminder.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AAEE;EACE,SAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AACI;EACE,YAAA;AACN;AAEE;EACE,sBAAA;AAAJ;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,WAAA;EAEA,2BAAA;EACA,qBAAA;EACA,oBAAA;AAAF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,SAAA;EACA,QAAA;EACA,2BAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;EACA,wCAAA;AAEJ;AADI;EACE,aAAA;EACA,gBAAA;AAGN;AAAE;EACE,UAAA;EACA,eAAA;AAEJ;AAAE;EACE,OAAA;EACA,MAAA;EACA,UAAA;EACA,kBAAA;AAEJ","sourcesContent":[".reminder {\n  input[type=\"text\"] {\n    width: 90%;\n  }\n  button {\n    border: 0;\n    background: none;\n    cursor: pointer;\n    &:disabled {\n      cursor: auto;\n    }\n  }\n  &_note {\n    box-sizing: border-box;\n  }\n}\n.reminder_table {\n  margin-top: 5px;\n}\n.reminder_form {\n  width: 100%;\n  //   text-align: ;\n  justify-content: flex-start;\n  align-items: flex-end;\n  margin-bottom: 0.5em;\n}\n.datePicker {\n  position: relative;\n  input {\n    left: 2px;\n    top: 3px;\n    color: rgba(204, 38, 38, 0);\n    z-index: 2;\n    position: absolute;\n    width: 15px;\n    height: 15px;\n    padding: 0;\n    border: 0;\n    background-color: rgba(255, 255, 255, 0);\n    &:focus {\n      outline: none;\n      border-bottom: 0;\n    }\n  }\n  input[type=\"date\"]::-webkit-calendar-picker-indicator {\n    opacity: 0;\n    cursor: pointer;\n  }\n  img {\n    left: 0;\n    top: 0;\n    z-index: 1;\n    position: absolute;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
