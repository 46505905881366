import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import DateInput from "../DateInput";
import TextInput from "../TextInput";

import checkImg from "./buttonx.png";

const ControlCheck = ({name, date, initialChecked = false, readOnly = true}) => {
  const [checked, setChecked] = useState(initialChecked);
  const {t} = useTranslation();

  return (
    <div className="fc nowrap autolabel" style={{gap: "0.3em", alignItems: "flex-end"}}>
      {!readOnly && (
        <input
          type="checkbox"
          checked={checked}
          // onChange={()=>{setChecked(i=>!i)}}
          onChange={() => {}}
          // readOnly={true}
          disabled
        />
      )}
      <TextInput label={t("Control")} name={name} width="100%" readOnly={readOnly} />
      {/* <TextInput label={t("From")} name={date} width="8em" readOnly={readOnly} /> */}
      <DateInput label={t("From")} name={date} width="8em" readOnly={readOnly} />

      {!readOnly && <img src={checkImg} alt="" style={{height: "1.6em"}} draggable="false" />}
    </div>
  );
};

export default ControlCheck;
