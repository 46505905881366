import {useRef} from "react";
import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../../hook/debounced";
import ClosedEye from "../../../images/icons/closeEye.png";
import OpenedEye from "../../../images/icons/openEye.png";
import QuestionItem from "../../../utilities/QuestionItem";
import useInputsTooltip from "../hook/InputsTooltipService";

import styles from "./PasswordInput.module.scss";

const PasswordInput = ({
  label,
  width = "100%",
  className = "",
  generalClassName = "",
  inputClassName = "",
  question = null,
  func = null,
  autolabel,
  labelWrap,
  labelStyles,
  handleInputChange = () => {},
  disabled = false,
  placeholder = "",
  hasTitle = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown} = useInputsTooltip(field);

  const {handleChange} = useFormikContext();

  const funcDebounce = useDebouncedCallback(value => {
    if (func !== null) func(value);
  }, 1000);

  const onChange = e => {
    handleChange(e);
    handleInputChange(e);
    if (e.target.value.length > 0) {
      setTooltipShown(false);
    }
    if (func !== null) {
      funcDebounce(e.target.value);
    }
  };

  const passwordInputRef = useRef();
  const showPasswordBtnRef = useRef();

  function onToggleShowPasswordBtnClick() {
    const inputElType = passwordInputRef.current.type;
    passwordInputRef.current.type = inputElType === "password" ? "text" : "password";
    showPasswordBtnRef.current.style.backgroundImage =
      inputElType === "password" ? `url(${ClosedEye})` : `url(${OpenedEye})`;
  }

  return (
    <div className={`project_input ${generalClassName} ${labelWrap ? "" : "labelWrap"}`} style={props.style}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className + " " + styles.passwordInputWrapper}>
          <input
            {...props}
            {...field}
            type="password"
            className={inputClassName}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            value={field.value || ""}
            onChange={onChange}
            onMouseEnter={question ? onMouseEnter : null}
            onMouseLeave={question ? onMouseLeave : null}
            title={hasTitle && field.value ? field.value : null}
            ref={passwordInputRef}
          />
          <button
            type="button"
            className={styles.toggleShowPasswordBtn}
            ref={showPasswordBtnRef}
            onClick={onToggleShowPasswordBtnClick}
          ></button>
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  className: PropTypes.string,
  question: PropTypes.string,
  func: PropTypes.func,
  autolabel: PropTypes.bool,
  labelStyles: PropTypes.object,
  handleInputChange: PropTypes.func,
  generalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hasTitle: PropTypes.bool,
  props: PropTypes.array,
};

export default PasswordInput;
