import {useEffect, useMemo, useState} from "react";

import {checkEmptyFilterFields} from "../checkEmptyFilterFields";

const useSessionFilters = filtersKey => {
  const [filters, setFiltrs] = useState(null);

  useEffect(() => {
    setFiltrs(getFilters());
  }, []);

  const getFilters = () => {
    let parsedValue = null;
    try {
      parsedValue = JSON.parse(sessionStorage.getItem(filtersKey));
    } catch (error) {
      console.error(error);
    }
    return parsedValue;
  };
  const setFilters = value => sessionStorage.setItem(filtersKey, JSON.stringify(checkEmptyFilterFields(value)));
  const clearFilters = () => sessionStorage.removeItem(filtersKey);

  const initialFilters = useMemo(() => getFilters() || null, []);

  return {filters, setFilters, clearFilters, initialFilters};
};

export default useSessionFilters;
