import {useTranslation} from "react-i18next";
import {Formik} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {TextAreaInput, TextInput} from "../../../../Inputs";
import Spinner from "../../../../spinner/Spinner";
import TemplatesTable from "../common/TemplatesTable/TemplatesTable";

import useReqTemplatesModalService from "./useReqTemplatesModalService";

import styles from "../modTempates.module.scss";

const ReqTemplatesModal = ({currentConditionId}) => {
  const {t} = useTranslation();
  const {
    onSaveTemplateBtnClick,
    onDeleteTemplateBtnClick,
    initialValues,
    calcBtnsDisabled,
    isDeleteTemplateBtnDisabled,
    loading,
  } = useReqTemplatesModalService();

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({values}) => (
        <section className={styles.reqTemplatesSection}>
          <h2 className={styles.sectionHeader}>
            2. <span className={styles.underLined}>{t("Factory Request Templates")}</span>
          </h2>
          <TemplatesTable currentConditionId={currentConditionId} />
          {loading && <Spinner />}
          <div className={styles.sectionRow + " " + styles.reqOptions}>
            <TextInput label={t("cont pers CP") + ":"} name="contactPerson" width="12em" />
            <ul className={styles.requisitesWrapper}>
              <li>
                <TextInput label={t("To whom") + ":"} name="email" width="30em" />
              </li>
              <li>
                <TextInput label={t("Copy") + ":"} name="carbonCopy" width="30em" />
              </li>
              <li>
                <TextInput label={t("Theme") + ":"} name="subject" width="30em" />
              </li>
            </ul>
          </div>
          <TextAreaInput className={styles.letterTextInput} name="content" areaWidth="100%" />
          <div className={styles.buttonRow}>
            <FunctionButton
              name="Delete"
              onClick={onDeleteTemplateBtnClick}
              autoWidth
              disabled={isDeleteTemplateBtnDisabled || calcBtnsDisabled(values)}
            />
            <FunctionButton
              name="Save"
              onClick={() => onSaveTemplateBtnClick(values)}
              autoWidth
              disabled={calcBtnsDisabled(values)}
            />
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ReqTemplatesModal;
