import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import PageContainer from "../../conteiners/PageContainer";
import LogSettingsSectionBlock from "../../Logist/LogSettings/LogSettingsSectionBlock/LogSettingsSectionBlock";
import ModalForm from "../../Modal/FormModal";

import AccountHolderTypeModal from "./modals/AccountHolderTypeModal/AccountHolderTypeModal";
import AccountOpeningModal from "./modals/AccountOpeningModal/AccountOpeningModal";
import BalanceSheetAccountsModal from "./modals/BalanceSheetAccountsModal/BalanceSheetAccountsModal";
import CreatingBatchModal from "./modals/CreatingBatchModal/CreatingBatchModal";
import CT_Type from "./modals/CT_Type/CT_Type";

const FinSettings = () => {
  const {t} = useTranslation();

  const FIN_SETTINGS_MODAL_TYPES = Object.freeze({
    BALANCE_SHEET: t("Balance sheet accounts"),
    ACCOUNT_OPENING: t("Account opening"),
    CREATING_BATCH: t("Creating a batch posting template"),
    ACCOUNT_HOLDER_TYPE: t("Type of Account Holder (AH)"),
    CT_TYPE: t("Type of cash transactions (CT)"),
  });

  const [currentType, setCurrentType] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const currentComponent = useMemo(() => {
    switch (currentType) {
      case FIN_SETTINGS_MODAL_TYPES.BALANCE_SHEET:
        return BalanceSheetAccountsModal;

      case FIN_SETTINGS_MODAL_TYPES.ACCOUNT_OPENING:
        return AccountOpeningModal;

      case FIN_SETTINGS_MODAL_TYPES.CREATING_BATCH:
        return CreatingBatchModal;

      case FIN_SETTINGS_MODAL_TYPES.ACCOUNT_HOLDER_TYPE:
        return AccountHolderTypeModal;

      case FIN_SETTINGS_MODAL_TYPES.CT_TYPE:
        return CT_Type;

      default:
        return null;
    }
  }, [currentType]);

  const firstColumnBlocks = [
    {
      headingNumber: 1,
      heading: "Balance sheet accounts",
      text: "Chart of Accounts",
      onClick: () => {
        setCurrentType(FIN_SETTINGS_MODAL_TYPES.BALANCE_SHEET);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 2,
      heading: "Account opening",
      text: "The operation of opening",
      onClick: () => {
        setCurrentType(FIN_SETTINGS_MODAL_TYPES.ACCOUNT_OPENING);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 3,
      heading: "Creating a batch posting template",
      text: "Creation of a package",
      onClick: () => {
        setCurrentType(FIN_SETTINGS_MODAL_TYPES.CREATING_BATCH);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 4,
      heading: "Type of Account Holder (AH)",
      text: "Create account holder type",
      onClick: () => {
        setCurrentType(FIN_SETTINGS_MODAL_TYPES.ACCOUNT_HOLDER_TYPE);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 5,
      heading: "Type of cash transactions (CT)",
      text: "Creating a type of cash transaction",
      onClick: () => {
        setCurrentType(FIN_SETTINGS_MODAL_TYPES.CT_TYPE);
        setModalActive(true);
      },
      disabled: false,
    },
  ];

  return (
    <PageContainer title={t("Settings (FinBlock)")}>
      <div className="h100 grid3Col">
        <div className="borderRight paddingRight">
          {firstColumnBlocks.map(({headingNumber, heading, text, onClick, disabled}) => (
            <LogSettingsSectionBlock
              key={headingNumber}
              headingNumber={headingNumber}
              heading={heading}
              text={text}
              onClick={onClick}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="paddingLeft paddingRight"></div>
        <div className="borderLeft paddingLeft"></div>
      </div>

      <ModalForm
        closable={true}
        Component={currentComponent}
        label={currentType}
        active={modalActive}
        setModalActive={setModalActive}
        className="largeModal"
      />
    </PageContainer>
  );
};

export default FinSettings;
