import {useTranslation} from "react-i18next";

import {TextInput} from "../../../Inputs";

import "../../../Requests/RequestRegister/requestRegister.scss";

const FactoryInfoFields = () => {
  const {t} = useTranslation();

  return (
    <div className="fcc factory_info_fields">
      <div className="fcc factory_info_item">
        <TextInput
          label={t("C date")}
          name="createdAt"
          type="text"
          labelStyles={{width: "12em"}}
          generalClassName="filter_item"
        />
        <TextInput
          label={t("created by")}
          name="creator"
          type="text"
          generalClassName="filter_item"
          width="20em"
          labelStyles={{width: "9em"}}
        />
      </div>
      <div className="fcc factory_info_item">
        <TextInput
          label={t("e date")}
          name="updatedAt"
          type="text"
          labelStyles={{width: "14em"}}
          generalClassName="filter_item"
        />
        <TextInput
          label={t("edited by")}
          name="modifier"
          type="text"
          width="20em"
          labelStyles={{width: "9em"}}
          generalClassName="filter_item"
        />
      </div>
    </div>
  );
};

export default FactoryInfoFields;
