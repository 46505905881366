import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";
import Swal from "sweetalert2";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {getExpandedDocs, profExpanded} from "../../Requests/RequestsInProgress/utils/functions/expandDataForTable";
import {deselectSkuRequest} from "../redux/LogistSlice";
import {fetchAllStockPoolsNew, fetchStockPoolBySkuId, sendTP, takeTpToWork} from "../redux/operations";
import {
  selectAllCarrierDocs,
  selectCarrier,
  selectCarriers,
  selectCurrentSkuRequest,
  selectTransporterRequests,
} from "../redux/selectors";

import {DOCUMENTS_FOR_TRANSPORTER_TABLE_COLUMNS, MAIN_TRANSPORTATION_TABLE_COLUMNS} from "./utils/constants";

const useTransportationTPService = formikRef => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const allRequests = useSelector(selectTransporterRequests),
    carriers = useSelector(selectCarriers),
    currentSkuRequest = useSelector(selectCurrentSkuRequest),
    currentCarrier = useSelector(selectCarrier),
    allCarrierDocs = useSelector(selectAllCarrierDocs);

  const [isTakenToWork, setIsTakenToWork] = useState(false);
  const [isFinalDestination, setIsFinalDestination] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setIsFinalDestination(currentSkuRequest?.stockPool?.cargo?.isFinal || false);
  }, [currentSkuRequest]);

  const carrierAddress =
    currentSkuRequest && currentCarrier
      ? currentCarrier?.contacts
          ?.map(
            (item, index) =>
              `${index + 1}. ${item?.title || "---"} / ${item?.address || "---"} / ${item?.phone || "---"} / ${
                item.email || "---"
              }\n`,
          )
          .join("")
      : "";

  const commonFields = {
    tpNumber: currentSkuRequest?.stockPool?.cargo?.cargoNumber || "",
    warehouseId: currentSkuRequest?.stockPool?.cargo?.endPoint?.warehouseId || "",
    shipmentDate:
      currentSkuRequest?.stockPool?.cargo?.dates?.find(item => item.dateType.dateTypeId === 1)?.actualDate || "",
    deliveryDate: "",
  };

  useEffect(() => {
    if (currentSkuRequest) {
      const cargo = currentSkuRequest?.stockPool?.cargo;
      if (cargo?.factory) {
        if (cargo?.factory?.factoryContacts.length > 0) {
          formikRef.current.setValues({
            ...commonFields,
            loadingPlace: cargo?.factory?.name,
            loadingPlaceField: cargo?.factory?.factoryContacts
              ?.map(
                (item, index) =>
                  `${index + 1}. ${item.address || "---"} / ${item.phone || "---"} / ${item.email || "---"}\n`,
              )
              .join(""),
          });
        } else {
          formikRef.current.setValues({
            ...commonFields,
            loadingPlace: cargo?.factory?.name,
            loadingPlaceField: `${cargo?.factory?.name} (address not specified)`,
          });
        }
      } else {
        formikRef.current.setValues({
          ...commonFields,
          loadingPlace: cargo?.startPoint?.name,
          loadingPlaceField: cargo?.startPoint?.address,
        });
      }
    } else {
      formikRef.current.resetForm();
    }
  }, [currentSkuRequest]);

  useEffect(() => {
    if (currentCarrier) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        transporterContactsField: carrierAddress,
        transporterTypeId: currentCarrier?.type?.typeId,
        transporterId: currentCarrier?.carrierId,
      });
    } else {
      formikRef.current.setValues({
        ...formikRef.current.values,
        transporterContactsField: "",
        transporterTypeId: "",
        transporterId: "",
      });
    }
  }, [currentCarrier]);

  const formInitialValues = useMemo(() => {
    return {
      tpNumber: "",
      transporterContactsField: "",
      loadingPlace: "",
      loadingPlaceField: "",
      transporterTypeId: "",
      transporterId: "",
      warehouseId: "",
      shipmentDate: "",
      deliveryDate: "",
      onDocDate: "",
      docNumber: "",
      docType: "",
    };
  }, []);

  const mainTransportationTableColumns = useColumns(MAIN_TRANSPORTATION_TABLE_COLUMNS);

  const docsForTransporterTableColumns = useColumns(DOCUMENTS_FOR_TRANSPORTER_TABLE_COLUMNS);

  const mainTransportationTableData = useMemo(() => {
    return allRequests?.map(({order, request, skuRequestId, stockPool}, index) => {
      const managerCoName =
        i18n.language === "en"
          ? order?.co.managerCo?.persona?.engFirstName + " " + order?.co.managerCo?.persona?.engLastName
          : order?.co.managerCo?.persona?.firstName + " " + order?.co.managerCo?.persona?.lastName;

      const managerFeaName =
        i18n.language === "en"
          ? request?.managerFea?.persona?.engFirstName + " " + request?.managerFea?.persona?.engLastName
          : request?.managerFea?.persona?.firstName + " " + request?.managerFea?.persona?.lastName;

      return {
        statusTP: i18n.language === "en" ? stockPool.cargo.status.engName : stockPool.cargo.status.ukrName,
        transportPoolNumber: stockPool?.cargo?.cargoNumber,
        stockPoolNumber: stockPool.stockPoolNumber || "",
        orderNumber: order.orderNumber,
        managerCo: managerCoName || "",
        factory: request.brand?.name,
        sum: request?.requestSum,
        deliveryPlace: stockPool?.cargo?.endPoint?.name || stockPool?.cargo?.endPoint?.address,
        bringTo: dateFormat(order.co.bringTo),
        deliveryCountry: order?.co?.project?.country?.shortName,
        profNumber: profExpanded(request?.proforms),
        invoiceNumber: getExpandedDocs(stockPool?.docs, 2) || "-",
        managerFea: managerFeaName || "",
        notes: request?.note,
        number: index + 1,
        id: skuRequestId,
      };
    });
  }, [allRequests]);

  const onTPTableSelect = id => {
    if (!id || currentSkuRequest?.skuRequestId === id) {
      dispatch(deselectSkuRequest());

      return;
    }
    dispatch(fetchStockPoolBySkuId(id));
    setIsTakenToWork(false);
  };

  function onTPTableDoubleClick() {
    if (!currentSkuRequest) return;

    dispatch(fetchStockPoolBySkuId(currentSkuRequest.skuRequestId));
    setModalOpen(true);
  }

  const onTakeToWorkClick = () => {
    dispatch(takeTpToWork(currentSkuRequest.stockPool.cargo.cargoId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllStockPoolsNew({window: "Transporter"}));
        setIsTakenToWork(true);
        // dispatch(fetchStockPoolBySkuId(currentSkuRequest.skuRequestId));
      }
    });
  };

  const onStartTransportationClick = (values, resetForm, setValues) => {
    if (values?.deliveryDate !== "") {
      dispatch(
        sendTP({
          cargoId: currentSkuRequest?.stockPool?.cargo?.cargoId,
          data: {
            carrierId: currentCarrier?.carrierId,
            endPointId: values?.warehouseId || values?.warehouseAddressId,
            arrivalDate: values?.deliveryDate,
          },
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllStockPoolsNew({window: "Transporter"}));
          dispatch(deselectSkuRequest());
          setValues({transporterContactsField: "", transporterId: ""});
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: `${t("Fill in the delivery date")}`,
        icon: "info",
        confirmButtonText: t("Continue"),
      });
    }
  };

  return {
    formInitialValues,
    mainTransportationTableColumns,
    mainTransportationTableData,
    docsForTransporterTableColumns,
    // transporterPaymentTableColumns,
    allRequests,
    onTPTableSelect,
    onTakeToWorkClick,
    // carrierOptions,
    // carrierTypesOptions,
    carriers,
    currentSkuRequest,
    onStartTransportationClick,
    currentCarrier,
    allCarrierDocs,
    isTakenToWork,
    // onFinelDestinationCheck,
    isFinalDestination,
    onTPTableDoubleClick,
    isModalOpen,
    setModalOpen,
  };
};

export default useTransportationTPService;
