import React from "react";

import infinity from "./spin.gif";
const Spinner = ({style = {}}) => {
  return (
    <img
      alt=""
      src={infinity}
      style={{width: "10em", height: "auto", position: "absolute", top: "35%", left: "calc(50% - 100px)", ...style}}
      draggable="false"
    />
  );
};

export default Spinner;
