import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {getCurrentKp} from "../../../selectors/generalSelectors";
import {getCatalogsLoading} from "../../../slices/CatalogsSlice";
import {deselectKp, getKpInfoForKp} from "../../../slices/KPSlice";
import PageControlContainer from "../../conteiners/PageControlContainer";

import KpInfoForm from "./KpInfo/KpInfoForm";
import KpPreview from "./KpPreview/KpPreview";
import KpReminder from "./kpReminder/KpReminder";
import TypedKpAddForm from "./TypedKp/TypedKpAddForm";

import "./KpForm.scss";

const Kp = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const catalogsLoading = useSelector(getCatalogsLoading);
  const currentKp = useSelector(getCurrentKp);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.needUpd && location?.state?.coId) {
      dispatch(getKpInfoForKp(location?.state?.coId));
    }
    return () => {
      dispatch(deselectKp());
    };
  }, []);

  return (
    <PageControlContainer title={currentKp ? `${t("CO")} ${currentKp.coNumber}` : t("CO")} loading={catalogsLoading}>
      <div className="two-column-page__container">
        <div className="left-side">
          <KpInfoForm />
          <TypedKpAddForm />
        </div>
        <div className="right-side ">
          <KpPreview />
          <KpReminder />
        </div>
      </div>
    </PageControlContainer>
  );
};
export default Kp;
