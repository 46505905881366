import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import translit from "ua-en-translit";

import {TextAreaInput} from "../../../Inputs";
import {CROP_IMG_CATEGORIES} from "../../../Inputs/fileSelect/cropper/cropImgSizes";
import ImgBox from "../../../Inputs/fileSelect/ImgBox/ImgBox";
import ImgFilePreview from "../../../Inputs/fileSelect/preview/ImgFilePreview";
// import { getSkuImages, } from "../../SkuSlice";
import {
  addColorImages,
  addDecorImages,
  addDrawingImages,
  addSkuImages,
  delColorImages,
  delDecorImages,
  delDrawingImages,
  delSkuImages,
  getColorImages,
  getCurrentSku,
  getDecorImages,
  getDrawings,
  getImagesLoading,
  getSkuImages,
} from "../../SkuSlice";

const Decoration = ({imagerefs}) => {
  const {t, i18n} = useTranslation();

  const currentSku = useSelector(getCurrentSku);

  const skuImages = useSelector(getSkuImages),
    colorImages = useSelector(getColorImages),
    decorImages = useSelector(getDecorImages),
    drawings = useSelector(getDrawings);
  const imgLoading = useSelector(getImagesLoading);

  const dispatch = useDispatch();

  const addImg = (arrFiles, type) => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    const dataObj = {skuId: currentSku?.skuId || currentSku, formdata};

    switch (type) {
      case "sku":
        dispatch(addSkuImages(dataObj));
        break;
      case "decor":
        dispatch(addDecorImages(dataObj));
        break;
      case "color":
        dispatch(addColorImages(dataObj));
        break;
      case "drawings":
        dispatch(addDrawingImages(dataObj));
        break;
      default:
        break;
    }
  };

  function onDell(imgUrl, type) {
    const dataObj = {skuId: currentSku?.skuId || currentSku, imgUrl};
    switch (type) {
      case "sku":
        dispatch(delSkuImages(dataObj));
        break;
      case "decor":
        dispatch(delDecorImages(dataObj));
        break;
      case "color":
        dispatch(delColorImages(dataObj));
        break;
      case "drawings":
        dispatch(delDrawingImages(dataObj));
        break;
      default:
        break;
    }
  }

  return (
    <div className="middle_sku __decoration-color" style={{height: "100%"}}>
      <div className="fc nowrap jcc half bigger" style={{gap: "3em", height: "100%"}}>
        <div className="fc" style={{alignItems: "flex-end"}}>
          <ImgBox label={t("General view")} classNames="sku_item_image" imgs={skuImages || []}></ImgBox>
          <ImgFilePreview
            imgs={skuImages || []}
            delFunc={file => {
              onDell(file, "sku");
            }}
            accept={"image/*"}
            addFunc={file => {
              addImg(file, "sku");
            }}
            disable={!currentSku}
            necessaryCrop={true}
            category={CROP_IMG_CATEGORIES.VED_PRODUCT_COLOR_AND_DECORATION}
            loading={imgLoading}
          />
        </div>

        <div className="fc" style={{alignItems: "flex-end"}}>
          <ImgBox label={t("Drawings")} classNames="sku_item_image" imgs={drawings || []}></ImgBox>
          <ImgFilePreview
            imgs={drawings || []}
            delFunc={file => {
              onDell(file, "drawings");
            }}
            accept={"image/*"}
            addFunc={file => {
              addImg(file, "drawings");
            }}
            disable={!currentSku}
            necessaryCrop={true}
            category={CROP_IMG_CATEGORIES.VED_PRODUCT_COLOR_AND_DECORATION}
            loading={imgLoading}
          />
        </div>
      </div>
      <div className="fc nowrap half smaller">
        <div
          className=" project_form_wrapper"
          style={{
            //   width: "90%",
            height: "100%",
            // maxWidth: "300px",
            marginLeft: "1em",
          }}
        >
          <div className="flex_container nowrap " style={{alignItems: "flex-start", gap: "5px"}}>
            <ImgBox label={t("Finishing") + ":"} classNames="smaller-image-box" imgs={decorImages || []} />

            <ImgFilePreview
              imgs={decorImages || []}
              delFunc={file => {
                onDell(file, "decor");
              }}
              accept={"image/*"}
              addFunc={file => {
                addImg(file, "decor");
              }}
              disable={!currentSku}
              necessaryCrop={true}
              category={CROP_IMG_CATEGORIES.VED_PRODUCT_COLOR_AND_DECORATION}
              loading={imgLoading}
            />
          </div>
          <div className="flex_container nowrap " style={{alignItems: "flex-start", gap: "5px"}}>
            <ImgBox label={t("Color") + ":"} classNames="smaller-image-box" imgs={colorImages || []} />

            <ImgFilePreview
              imgs={colorImages || []}
              delFunc={file => {
                onDell(file, "color");
              }}
              accept={"image/*"}
              addFunc={file => {
                addImg(file, "color");
              }}
              disable={!currentSku}
              necessaryCrop={true}
              category={CROP_IMG_CATEGORIES.VED_PRODUCT_COLOR_AND_DECORATION}
              loading={imgLoading}
            />
          </div>
        </div>
        <div
          className="autolabel fc nowrap"
          style={{
            marginLeft: "15px",
            width: "100%",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* <div style={{ height: "50%", width: "100%" }}> */}
          <TextAreaInput label={t("Finishing type")} name="materialType" width="95%" height="7em" />
          {/* </div> */}
          {/* <div style={{ height: "50%", width: "100%" }}> */}
          <TextAreaInput label={t("Color type")} id="colorType" name="colorType" width="95%" height="7em" />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

Decoration.propTypes = {imagerefs: PropTypes.array};

export default Decoration;
