import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";

import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import NewPdfViewer from "../../Project/Documents/DocumentView/NewPdfViewer";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import {deselectSkuRequest} from "../redux/LogistSlice";

import WarehouseAdressPart from "./WarehouseBlockParts/WarehouseAdressPart";
import WarehouseDocsAndCargoPart from "./WarehouseBlockParts/WarehouseDocsAndCargoPart/WarehouseDocsAndCargoPart";
import WarehouseFilters from "./WarehouseBlockParts/WarehouseFilters";
import WarehouseLowerMainTablesPart from "./WarehouseBlockParts/WarehouseLowerMainTablesPart/WarehouseLowerMainTablesPart";
import WarehouseMainButtons from "./WarehouseBlockParts/WarehouseMainButtons";
import WarehouseUpperPart from "./WarehouseBlockParts/WarehouseUpperPart";
import useWarehouseBlockService from "./useWarehouseBlockService";

const WarehouseBlock = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [creatingTPModalActive, setCreatingTPModalActive] = useState(false);

  const [pdfFileArray, setPdfFileArray] = useState(null);
  const [checkedSp, setCheckedSp] = useState([]);

  const {initialValues, warehouseStockPoolsAfterAcception} = useWarehouseBlockService(selectedWarehouse);

  useEffect(() => {
    setCheckedSp([]);
  }, [warehouseStockPoolsAfterAcception]);

  useEffect(() => {
    dispatch(deselectSkuRequest());
  }, []);

  return (
    <PageContainer title={t("Process block 'Warehouse'")}>
      <WarehouseFilters
        window={LOGIST_WINDOW_TYPES.WAREHOUSE}
        setSelectedWarehouse={setSelectedWarehouse}
        selectedWarehouse={selectedWarehouse}
      />
      <Divider />
      <div className="fdr gap w100 mb " style={{height: "33em"}}>
        <div className="fdc jcsb w100">
          <Formik initialValues={initialValues} enableReinitialize>
            <Form>
              <WarehouseUpperPart selectedWarehouse={selectedWarehouse} />
              <Divider />
              <div className="fdr">
                <WarehouseAdressPart selectedWarehouse={selectedWarehouse} />
                <WarehouseDocsAndCargoPart setPdfFileArray={setPdfFileArray} />
              </div>
              <Divider />
            </Form>
          </Formik>
          <WarehouseMainButtons
            selectedWarehouse={selectedWarehouse}
            checkedSp={checkedSp}
            setCreatingTPModalActive={setCreatingTPModalActive}
            creatingTPModalActive={creatingTPModalActive}
            setCheckedSp={setCheckedSp}
          />
        </div>
        <div className="" style={{width: "40%", height: "28em"}}>
          <NewPdfViewer fileArray={pdfFileArray || []} hiding={false} />
        </div>
      </div>

      <WarehouseLowerMainTablesPart
        selectedWarehouse={selectedWarehouse}
        setCheckedSp={setCheckedSp}
        checkedSp={checkedSp}
      />
    </PageContainer>
  );
};

export default WarehouseBlock;
