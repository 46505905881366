import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import PageContainer from "../../conteiners/PageContainer";
import ModCheckList from "../CheckLists/ModCheckList";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import LeftSideTable from "../common/LeftSideTable/LeftSideTable";
import FullLogistFiltersForm from "../common/LogistFilters/LogistFilters";
import LogistTable from "../common/LogistTable/LogistTable";
import LogModRqstCheckListMC from "../SleepingRequests/LogModRqstCheckList.modal_container";

import useTpListManagerService from "./useTpListManagerService";

export const TP_LIST_MANAGER_MODAL_TYPES = Object.freeze({
  sp: "SP",
  tp: "TP",
  request: "REQUEST",
});

const TpListManager = () => {
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(TP_LIST_MANAGER_MODAL_TYPES.sp);

  const {
    tpListManagerTableData,
    onTpListManagerTableRowSelect,
    isLoading,
    onFactoryBtnClick,
    onTransporterBtnClick,
    onStorageBtnClick,
    // initialValues,
    // onShowHistoryBtnClick,
    leftSideTableData,
    onLeftSideTableRowSelect,
    areFiltersDisabled,
    selectedSP,
  } = useTpListManagerService();

  return (
    <PageContainer title={t("Request Register in progress")}>
      <div className="page_flex_container">
        <div className="w100">
          <FullLogistFiltersForm window={LOGIST_WINDOW_TYPES.DISTRIBUTED} disabled={areFiltersDisabled} />
          <div className="border border_gap2"></div>
        </div>

        <div className="fc nowrap gap flexg1 w100">
          <LeftSideTable
            tableData={leftSideTableData}
            isLoading={isLoading}
            onSelect={onLeftSideTableRowSelect}
            setModalType={setModalType}
            setModalOpen={setModalOpen}
          />
          <LogistTable
            tableData={tpListManagerTableData}
            isLoading={isLoading}
            onSelect={onTpListManagerTableRowSelect}
            setModalOpen={setModalOpen}
            setModalType={setModalType}
          />
        </div>
        {/* <Formik initialValues={initialValues}>
          <Form style={{width: "100%"}}>
            <TextInput label={t("Request №")} name="reqNumber" generalClassName="mb2x ml10em" width="15em" />
            <div className="fc nowrap mb2x w100 gap2x">
              <FunctionButton name="Show history" onClick={onShowHistoryBtnClick} disabled autoWidth />
              <StagesScale />
            </div>
          </Form>
        </Formik> */}

        <div className="fc g2 w100 mb">
          <FunctionButton name="Factory" onClick={onFactoryBtnClick} disabled />
          <FunctionButton name="Transporter" onClick={onTransporterBtnClick} disabled />
          <FunctionButton name="Warehouse" onClick={onStorageBtnClick} disabled />
        </div>

        {/* <LogModRqstCheckListMC
          modalShow={isModalOpen}
          setModalShow={setModalOpen}
          type={modalType}
          selectedSP={selectedSP}
          modalTypes={TP_LIST_MANAGER_MODAL_TYPES}
        /> */}
        <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
      </div>
    </PageContainer>
  );
};

export default TpListManager;
