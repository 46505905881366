import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

const useToaster = () => {
  const {t} = useTranslation();

  return {
    toastSuccess: text => {
      toast.success(t(text), {autoClose: 1000});
    },
    toastError: text => {
      toast.error(t(text), {autoClose: 1000});
    },
    toastWarning: text => {
      toast.warning(t(text), {autoClose: 1000});
    },
    toastInfo: text => {
      toast.info(t(text), {autoClose: 1000});
    },
  };
};

export default useToaster;
