import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat";
import useRequestDateIdsService, {
  findRequestDateType,
  REQUEST_DATES_IDS,
} from "../../../../utilities/requestDatesIdsService";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import {
  confirmReadiness,
  fetchRequests,
  getForReadiness,
  getRequest,
  getRequestInfo,
  selectAllTransportDocs,
  selectdatesWithPostponed,
  selectFactoryData,
  selectPackingSpace,
  selectTransportDoc,
} from "../../RequestsSlice";

import AdressBlock from "./AdressBlock";
import InvoiceAndPackBlock from "./InvoiceAndPackBlock";
import MeasurementBlock from "./MeasurementBlock";
import ReadinessDatesBlock from "./ReadinessDatesBlock";

import styles from "./ReceiptGoodsModal.module.scss";

const ReceiptGoodsModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo),
    currentTransportDoc = useSelector(selectTransportDoc),
    allDatesWithPostponed = useSelector(selectdatesWithPostponed);

  useEffect(() => {
    if (currentRequest) {
      dispatch(getForReadiness(currentRequest?.requestId));
    }
  }, [currentRequest]);

  //initiial values
  const initialValues = useMemo(() => {
    return {
      readinessDate: "",
      readinessControlDate: "",
      readinessNote: "",
      readinessActualDate:
        dateFormat(findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.READINESS_ID)?.actualDate) || "",

      factoryName:
        currentRequest?.brand.factoryContacts.length !== 0 ? currentRequest?.brand.factoryContacts?.[0].contactId : "",
      factoryNote:
        currentRequest?.brand?.factoryContacts.length !== 0
          ? `${t("cont pers")}: ${t("Name")}: ${currentRequest?.brand.factoryContacts?.[0]?.name} / ${t("tel")}: ${
              currentRequest?.brand.factoryContacts?.[0]?.phone
            } / E-mail: ${currentRequest?.brand.factoryContacts?.[0]?.email} `
          : `${currentRequest?.brand?.name} (address not specified)`,

      onDocDate: currentTransportDoc ? currentTransportDoc?.dateFrom : "",
      docNumber: currentTransportDoc ? currentTransportDoc?.docNumber : "",
      docUploadDate: currentTransportDoc ? dateFormat(currentTransportDoc?.createdAt) : "",
      docType: currentTransportDoc ? currentTransportDoc?.docType.docTypeId : "",

      note: "",
      quantity: "",
      place: "",
      length: "",
      width: "",
      height: "",
      selectedProportionOption: "",
      selectVolumeProportion: "",
      volume: "",
      selectWeightProportion: "",
      weight: "",
    };
  }, [currentRequest, currentTransportDoc]);
  // currentRequest?.brand.factoryContacts

  //event handlers
  const onReceiptGoodsFormSubmit = () => {};

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      // validationSchema={validatingObj}
      onSubmit={onReceiptGoodsFormSubmit}
    >
      <Form className={`${styles.modalReadinessContainer} autolabel`}>
        <ReadinessDatesBlock allDatesWithPostponed={allDatesWithPostponed} />
        <section
          className={
            findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.READINESS_ID)?.actualDate
              ? styles.detailsSection
              : styles.detailsSection_disabled
          }
        >
          <div className={styles.leftColumn}>
            <AdressBlock />

            <InvoiceAndPackBlock currentTransportDoc={currentTransportDoc} element={currentRequest} isReadyMod />

            <section className={styles.proportionsSection}>
              <MeasurementBlock element={currentRequest} isReadyMod />
            </section>
          </div>
          <div className={styles.rightColumn}>
            <div className="kpPreview__box" style={{height: "102%"}}>
              <NewPdfViewer fileArray={currentTransportDoc ? [currentTransportDoc?.fileUrl] : []} />
            </div>
          </div>
        </section>
      </Form>
    </Formik>
  );
};

export default ReceiptGoodsModal;
