import ClosedEye from "../../../src/images/icons/closeEye.png";
import OpenedEye from "../../../src/images/icons/openEye.png";

const SWAL_PASSWORD_BTN_ID = "swal-password-button";
const SWAL_PASSWORD_INPUT_ID = "swal-password-input";

export function makeSwalShowPassword() {
  const onShowPasswordBtnClick = () => {
    const swalInputEl = document.querySelector(`#${SWAL_PASSWORD_INPUT_ID}`);
    const swalPasswordBtnEl = document.querySelector(`#${SWAL_PASSWORD_BTN_ID}`);
    const inputElType = swalInputEl.type;
    swalInputEl.type = inputElType === "password" ? "text" : "password";
    swalPasswordBtnEl.style.backgroundImage = inputElType === "password" ? `url(${ClosedEye})` : `url(${OpenedEye})`;
  };

  return {
    html: `<div class="swalPasswordWrapper"><input id=${SWAL_PASSWORD_INPUT_ID} type="password" name="password" class="swal2-input swalPasswordInput" /><button type="button" id=${SWAL_PASSWORD_BTN_ID} class="swalShowPasswordBtn"></button></div>`,
    didRender: popup => {
      const swalPasswordBtnEl = popup.querySelector(`#${SWAL_PASSWORD_BTN_ID}`);
      swalPasswordBtnEl.addEventListener("click", onShowPasswordBtnClick);
    },
    willClose: popup => {
      const swalPasswordBtnEl = popup.querySelector(`#${SWAL_PASSWORD_BTN_ID}`);
      swalPasswordBtnEl.removeEventListener("click", onShowPasswordBtnClick);
    },
  };
}
