import React, {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import {calcStatusColorViaPlannedDate} from "../../../../utilities/DateStatusCircle";
import {stockPoolNumber_TABLE_COLUMNS} from "../../../Logist/SleepingRequests/constants";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {getExpandedDocs, profExpanded} from "../../RequestsInProgress/utils/functions/expandDataForTable";
import {REQ_WINDOW_TYPES} from "../../RequestsInProgress/utils/functions/requestsFiltersManager";
import {
  clearNote,
  deselectCurrentOrder,
  deselectCurrentRequest,
  fetchRequests,
  getAllStatusRequest,
  getCurrentRequestId,
  getRequest,
  getRequestsInStockPool,
  getSPRequests,
  getStockPoolInfo,
  setCurrent,
} from "../../RequestsSlice";

const useModalStockPoolTableService = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const requestsInStockPool = useSelector(getRequestsInStockPool);
  const currStockPool = useSelector(getStockPoolInfo);

  const stockPoolTableColumns = useColumns(stockPoolNumber_TABLE_COLUMNS);

  const stockPoolTableData = useMemo(() => {
    console.log("requestsInStockPool", requestsInStockPool);
    return requestsInStockPool?.map(
      (
        {
          requestId,
          status,
          brand,
          brand: {factoryContacts},
          managerFea,
          createdAt,
          updatedAt,
          requestDates,
          orders,
          proforms,
          transportDocs,
          ...rest
        },
        index,
      ) => {
        const statusName = status?.[i18n.language === "en" ? "engName" : "ukrName"] || "";
        const lastDate = requestDates?.reduce((prev, current) => {
          return prev?.dateId > current?.dateId ? prev : current;
        });
        const closerBringTo = orders?.reduce((prev, current) => {
          const prevDate = prev?.bringTo;
          const currentDate = current?.bringTo;
          return prevDate > currentDate;
        });
        const color = calcStatusColorViaPlannedDate(lastDate?.plannedDate, lastDate?.createdAt, lastDate?.actualDate);
        return {
          ...rest,
          number: index + 1,
          requestStatus: statusName,
          managerCo: orders?.[0]?.co?.managerCo?.name || "",
          managerFea: managerFea?.name || "",
          factory: brand?.name || (factoryContacts && factoryContacts[0]?.name) || "",
          id: requestId,
          createdAt: dateFormat(createdAt),
          updatedAt: dateFormat(closerBringTo),
          noname: "",
          statusCircle: color,
          country: orders?.[0]?.co?.project?.country?.shortName,
          bringTo: dateFormat(orders?.[0].co?.bringTo),
          // stockPoolNumber: stockPool?.stockPoolNumber ?? "-",
          stockPoolNumber: currStockPool?.stockPoolNumber ?? "-",

          packL: getExpandedDocs(transportDocs, 1),
          invoice: getExpandedDocs(transportDocs, 2),
          prof: profExpanded(proforms),
        };
      },
    );
  }, [requestsInStockPool]);

  return {
    stockPoolTableColumns,
    stockPoolTableData,
  };
};

export default useModalStockPoolTableService;
