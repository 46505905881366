import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../hook/debounced";
import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

const TextInput = ({
  label,
  width = "100%",
  className = "",
  generalClassName = "",
  inputClassName = "",
  question = null,
  func = null,
  autolabel,
  labelWrap,
  labelStyles,
  handleInputChange = () => {},
  disabled = false,
  placeholder = "",
  hasTitle = false,
  inputref,
  style,
  ...props
}) => {
  const [field, meta] = useField(props);
  const {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown} = useInputsTooltip(field);

  const {handleChange, submitCount} = useFormikContext();

  const funcDebounce = useDebouncedCallback(value => {
    if (func !== null) func(value);
  }, 1000);

  const onChange = e => {
    handleChange(e);
    handleInputChange(e);
    if (e.target.value.length > 0) {
      setTooltipShown(false);
    }
    if (func !== null) {
      funcDebounce(e.target.value);
    }
  };

  return (
    <div className={`project_input ${generalClassName} ${labelWrap ? "" : "labelWrap"}`} style={style}>
      {label && (
        <label
          htmlFor={props.name}
          style={{...labelStyles, whiteSpace: "pre"}}
          className={autolabel ? "autolabel" : ""}
        >
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div className="project_input_container" style={{width: width, position: "relative"}}>
        <div className={className}>
          <input
            {...props}
            {...field}
            className={inputClassName}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            value={field.value || ""}
            onChange={onChange}
            onMouseEnter={question ? onMouseEnter : null}
            onMouseLeave={question ? onMouseLeave : null}
            title={hasTitle && field.value ? field.value : null}
            ref={inputref}
          />
        </div>
        {meta.touched && meta.error && submitCount > 0 ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  className: PropTypes.string,
  question: PropTypes.string,
  func: PropTypes.func,
  autolabel: PropTypes.bool,
  labelStyles: PropTypes.object,
  handleInputChange: PropTypes.func,
  generalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hasTitle: PropTypes.bool,
  props: PropTypes.array,
};

export default TextInput;
