import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import {isEqual} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import translit from "ua-en-translit";
import * as Yup from "yup";

import plusIcon from "../../../images/icons/plus.png";
import saveImg from "../../../images/icons/save.png";
import {getCurrentKp} from "../../../selectors/generalSelectors";
import {addAnnex, addAnnexFile, delAnnexFile, getKpAnnexes, updateAnnex} from "../../../slices/KPSlice";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {getInfo} from "../../../utilities/toasts";
import {DateInput, MoneyInput, Select} from "../../Inputs";
import PdfFilePreview from "../../Inputs/fileSelect/preview/PdfPreview";
import {Select as SelectNew} from "../../Inputs/SelectField";
import {getDocsLoading} from "../ProjectSlice";

import {RegistrButton} from "./utils/RegistrButton";

const AddKPDocumentForm = ({files, setFile, forcedUpdate, docRef, focusOnDoc}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(null);

  const initialValues = useMemo(() => {
    return {annexId: "", note: "", budget: null, bringTo: null};
  }, []);

  const onUnpade = values => {
    const {note, budget, bringTo} = values;
    return dispatch(
      updateAnnex({
        annexId: current.annexId,
        data: {
          note,
          budget,
          bringTo: bringTo === "" ? null : bringTo,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        return res.payload.annex;
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        enableReinitialize
        validateOnBlur={false}
        validationSchema={Yup.object({
          note: Yup.string()
            .typeError(t("Enter annex note"))
            .required([t("Required")]),
          bringTo: Yup.date()
            .typeError(t("Enter date"))
            .required([t("Enter date")]),
        })}
        onSubmit={(values, actions) => {
          if (current) {
            onUnpade(values).then(annex => {
              var {annexId, annexNumber, note, budget} = annex;

              setCurrent({annexId, annexNumber, note, budget});

              setFile(annex?.docsArray || []);
            });
          }
        }}
      >
        <DocForm
          setFile={setFile}
          files={files}
          docRef={docRef}
          focusOnDoc={focusOnDoc}
          forcedUpdate={forcedUpdate}
          onUnpade={onUnpade}
          setCurrent={setCurrent}
          current={current}
          dispatch={dispatch}
        />
        {/* }}  */}
      </Formik>
    </>
  );
};

const DocForm = ({setFile, docRef, focusOnDoc, setCurrent, current, onUnpade, dispatch}) => {
  const {values, resetForm, setValues, setFieldValue} = useFormikContext();

  const {t} = useTranslation();

  const {annexId, annexNote} = values;

  const annexes = useSelector(getKpAnnexes),
    currentKp = useSelector(getCurrentKp),
    docsLoading = useSelector(getDocsLoading);

  const registrList = useMemo(() => {
    var notes = [];
    annexes.forEach(annex => {
      const {annexId, note, annexNumber} = annex;
      notes.push({id: annexId, annexNumber, note});
    });
    return notes;
  }, [annexes]);

  const annexNumberList = useMemo(() => {
    return annexes?.map(annex => ({title: annex.annexNumber, value: annex.annexId})) || [];
  }, [annexes]);

  const noteList = useMemo(() => {
    return annexes?.map(annex => ({value: annex.note, annexNote: annex.note, noteId: annex.annexId})) || [];
  }, [annexes]);

  useEffect(() => {
    if (annexNote?.length !== 0) {
      const currAn = annexes.find(ann => ann.note === annexNote && ann.annexId === values.noteId);
      setFieldValue("note", current?.note);
      setFieldValue("annexId", currAn?.annexId);
    }
  }, [annexNote]);

  const changesCheck = () => {
    if (current && values.annexNumber) {
      const {docsArray, annexId: c1, deadline: v1, noteId: m1, ...restCurr} = current;
      const {annexId: c2, annexNote, deadline: v2, noteId, ...restValues} = values;

      const eq = isEqual(restValues, restCurr);
      if (!eq) {
        Swal.fire({
          title: "",
          text: `${t("Save document")} №${values.annexNumber}?`,
          icon: "question",
          confirmButtonText: t("Yes"),
          showCancelButton: true,
          cancelButtonText: t("No"),
        }).then(answ => {
          if (answ.isConfirmed) {
            onUnpade(restValues);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (currentKp && annexes.length !== 0 && currentKp.coId !== current?.coId) {
      let annx = [...annexes];
      let first = annx.pop();

      setTimeout(() => {
        setFieldValue("annexId", first.annexId);
      }, 100);
    } else {
      resetForm();
      setCurrent(null);
    }
  }, [currentKp]);

  useEffect(() => {
    if (annexId && annexId !== "") {
      changesCheck();
      var annex_ = annexes.find(item => item.annexId === annexId);
      if (annex_) {
        const {docsArray, ...rest} = annex_;
        setFile(docsArray);
        setCurrent({...annex_, bringTo: currentKp?.bringTo});
        setValues({...rest, bringTo: currentKp?.bringTo});
      } else {
        setCurrent(null);
      }
    }
  }, [annexId]);

  const onFileAdding = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      if (current && current?.docsArray && current?.docsArray.length !== 0) {
        var exist = current?.docsArray.includes(`annexes/annex_doc_${current.annexId}_${file.name}`);
        if (exist) {
          Swal.fire({
            title: "",
            text: `${t("File name")} ${file.name} ${t("Already exist")}`,
            icon: "warning",
            confirmButtonText: "Ок",
            customClas: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          continue;
        }
      }
      const filename = translit(file.name);
      formdata.append("annex", file, filename);
    }

    if (formdata.has("annex")) {
      dispatch(addAnnexFile({annexId: current.annexId, formdata})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setFile(res.payload.docsArray);
          setCurrent(item => {
            return {...item, docsArray: res.payload.docsArray};
          });
        }
      });
    }
  };

  const onFileDel = url => {
    dispatch(delAnnexFile({annexId: current.annexId, url})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFile(res.payload.docsArray);
        setCurrent(item => {
          return {...item, docsArray: res.payload.docsArray};
        });
      }
    });
    // }
    // });
  };

  const onPlusClick = () => {
    if (currentKp) {
      resetForm();

      dispatch(addAnnex(currentKp.coId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          Swal.fire({
            title: "",
            text: `${t("Annex added", {annexNumber: res.payload.annex.annexNumber})}`,
            icon: "info",
            confirmButtonText: "OK",
          });
          setFieldValue("annexId", res.payload.annex.annexId);
        }
      });
    } else {
      getInfo(t("CO at first"));
    }
  };
  const onFocus = i => {
    focusOnDoc(i);
    setFile(current?.docsArray);
  };

  const dateInpDisabled = useMemo(() => !annexId || annexId === "", [annexId]);

  return (
    <Form
      className="add-kp-doc-form"
      ref={el => {
        docRef.current[2] = el;
      }}
      onFocus={() => {
        onFocus(2);
      }}
    >
      <div className="number_block">
        <div>2.</div>
        <div className="block_content">
          <SelectNew
            label={t("Additional")}
            name="annexId"
            options={annexNumberList}
            inputClassName="ref_input"
            selectOnly={true}
            width="72%"
            question={t("Info")}
          />
          <Select label={t("Briefly about")} width="68.3%" name="note" options={noteList} disabled={!currentKp} />
          <MoneyInput label={t("CO amount")} name="budget" width="65%" readOnly={!currentKp} />

          <DateInput
            label={t("Take To:")}
            name="bringTo"
            labelW={"6.6em"}
            flatpikrConfig={{
              minDate: moment().format("DD-MM-YYYY"),
            }}
            readOnly={!currentKp || dateInpDisabled}
          />
          <div className="cont">
            <ImageButton
              src={plusIcon}
              alt="add remind"
              width={1.2}
              height={1.2}
              tooltipMessage="New entry"
              onClick={onPlusClick}
              disabled={!currentKp}
            />
            <ImageButton
              src={saveImg}
              alt="save"
              type={BUTTON_TYPES.SUBMIT}
              width={2}
              height={1.3}
              disabled={!currentKp || dateInpDisabled}
            />
            <div className="jcsp">
              <RegistrButton label={t("CO")} list={registrList} fieldName={"note"} idFieldName="annexId" />
            </div>
            <PdfFilePreview
              files={current?.docsArray || []}
              accept={".pdf"}
              delFunc={onFileDel}
              addFunc={onFileAdding}
              disable={!current}
              loading={docsLoading}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

AddKPDocumentForm.propTypes = {
  files: PropTypes.array,
  setFile: PropTypes.func,
  forcedUpdate: PropTypes.bool,
  docRef: PropTypes.any,
  focusOnDoc: PropTypes.func,
};

export default AddKPDocumentForm;
