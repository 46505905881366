import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../../utilities/QuestionItem";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import {deselectSku, fetchSkuCatalogs} from "../../../SKU/SkuSlice";
import {
  deselectCurrentOrder,
  deselectCurrentRequest,
  getCurrentRequestSKUs,
  getCurrentSkuRequestId,
  getRequestInfo,
  getRequestSkuInfo,
  getSkuRequestInfo,
  getThirdStatusRequests,
  setCurrentSkuRequestId,
} from "../../RequestsSlice";
import SKU_MistakeCorrection from "../SKU_MistakeCorrection/SKU_MistakeCorrection";
import useColumns from "../utils/hooks/useColumns";

import {REQUEST_SKU_BLOCK_COLUMNS} from "./utils/constants";
import ViewSkuFromTable from "./ViewSkuFromTable";

import styles from "./RequestSkuBlock.module.scss";

const RequestSkuBlock = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);
  const currentSkuInRequest = useSelector(getRequestSkuInfo);
  const currentSkuRequestId = useSelector(getCurrentSkuRequestId);
  const currentRequestSKUs = useSelector(getCurrentRequestSKUs);

  const isEditSkuBtnDisabled = currentSkuRequestId === null;

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
    dispatch(deselectCurrentRequest());
  }, []);

  const [modalActive, setModalActive] = useState(false);
  const [isSkuMistakeCorrectionModalActive, setSkuMistakeCorrectionModalActive] = useState(false);

  const columns = useColumns(REQUEST_SKU_BLOCK_COLUMNS);

  const RequestSkuBlockTableData = currentRequestSKUs.map(
    ({
      skuRequestId,
      article,
      brand,
      productGroup,
      productType,
      name,
      nameEng,
      material,
      color,
      listPrice,
      retailPrice,
      length,
      width,
      height,
      weight,
      amount,
      area,
      volumeWeight,
    }) => ({
      id: skuRequestId,
      article: brand.prefix + "-" + article,
      brandName: brand.name,
      productGroup: productGroup?.name,
      productType: productType?.name,
      name: i18n === "eng" ? nameEng : name,
      material,
      color,
      listPrice,
      retailPrice,
      length,
      width,
      height,
      weight,
      amount,
      area,
      volumeWeight,
    }),
  );

  const handleRowDoubleClick = rowData => {
    dispatch(getSkuRequestInfo(rowData.id)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setModalActive(true);
      }
    });
  };

  const handleRowSelect = skuRequestId => {
    if (!skuRequestId || skuRequestId === currentSkuRequestId) {
      dispatch(setCurrentSkuRequestId(null));
      return;
    }
    dispatch(setCurrentSkuRequestId(skuRequestId));
  };

  const onEditSkuBtnClick = () => {
    setSkuMistakeCorrectionModalActive(true);
  };

  const onSkuMistakeModalClose = () => {
    dispatch(deselectSku());
    setSkuMistakeCorrectionModalActive(false);
  };

  return (
    <div className="">
      <div className="q_item">
        <div className="q_wrapper">
          <QuestionItem title="double click" />
          SKU {t("In order small")} {currentRequest?.requestNumber}
        </div>
        <FunctionButton name="Edit SKU" onClick={onEditSkuBtnClick} disabled={isEditSkuBtnDisabled} />
      </div>
      <ReactTable
        defaultData={RequestSkuBlockTableData ?? []}
        columns={columns}
        onSelect={handleRowSelect}
        current={currentSkuRequestId}
        onRowDoubleClick={handleRowDoubleClick}
        className={styles.skuTableWrapper}
        // tableClassName={styles.skuTable}
        // className={styles.status3Table}
      />
      <ModalForm
        closable={true}
        Component={ViewSkuFromTable}
        element={currentSkuInRequest}
        label={t(`${t("View SKU")} ${currentSkuInRequest?.brand?.prefix + "-" + currentSkuInRequest?.article} ${t("in order")} ${currentRequest?.requestNumber}`)}
        active={modalActive}
        setModalActive={setModalActive}
      />
      <ModalForm
        closable={true}
        Component={SKU_MistakeCorrection}
        label={t("SKU mistake correction")}
        active={isSkuMistakeCorrectionModalActive}
        setModalActive={setSkuMistakeCorrectionModalActive}
        onModalClose={onSkuMistakeModalClose}
      />
    </div>
  );
};

export default RequestSkuBlock;
