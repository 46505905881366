import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import ControlCheck from "../../../../Inputs/controlCheck/ControlCheck";
import {getCurrentSku} from "../../../SkuSlice";
import useVWCharInputsService from "../../../viewWidthCharInputs/vwCharInputsService";

import SkuVwCharCheckboxe from "./SkuVwCharCheckboxe";

const VolumeWeightCharacteristics = ({numberOfChecked, setNumberOfChecked}) => {
  const {t} = useTranslation();

  const currentSku = useSelector(getCurrentSku);

  const {
    values: {length: l, width: w, height: h, volumeCheck, areaCheck, dimensionCoef, areaCoef, volumeCoef},
    setFieldValue,
  } = useFormikContext();

  const {area, dimension, volume, weight, quantity, skuMeasurementUnit, pallet} = useVWCharInputsService(
    "6.5em",
    "Dimension",
  );

  useEffect(() => {
    if (
      areaCheck &&
      l > 0 &&
      w > 0 &&
      (!currentSku.area || currentSku.area === "" || currentSku.area == "0" || currentSku.area == 0)
    ) {
      // var are = (l / dimensionCoef) * (w / dimensionCoef) * parseFloat(areaCoef);
      // const unit = areaMq.find(unit => unit.unitId === areaUnitId);
      var are = l * w;
      setFieldValue("area", are);
    }
    if (
      volumeCheck &&
      l > 0 &&
      w > 0 &&
      h > 0 &&
      (!currentSku.volume || currentSku.volume === "" || currentSku.volume == "0" || currentSku.volume == 0)
    ) {
      // var vol = ((l * w * h) / Math.pow(dimensionCoef, 3)) * parseFloat(volumeCoef);
      var vol = l * w * h;
      setFieldValue("volume", vol);
    }
  }, [l, w, h, volumeCheck, areaCheck]);

  useEffect(() => {
    if (currentSku && currentSku instanceof Object) {
      const {weightCheck, areaCheck, volumeCheck, overallSizeCheck, palletCheck} = currentSku;
      const mas = [weightCheck, areaCheck, volumeCheck, overallSizeCheck, palletCheck];
      const count = mas.filter(element => element === true).length;
      setNumberOfChecked(count);
    } else {
      setNumberOfChecked(0);
    }
  }, [currentSku]);

  return (
    <div className="vwCharacter">
      <label htmlFor="" className="label">
        {t("VWC header")}
      </label>

      <div className="fc jcsb autolabel" style={{marginBottom: "1em"}}>
        {skuMeasurementUnit}
        {quantity}
      </div>

      <SkuVwCharCheckboxe
        name="overallSizeCheck"
        setNumberOfChecked={setNumberOfChecked}
        numberOfChecked={numberOfChecked}
      >
        {dimension}
      </SkuVwCharCheckboxe>

      <SkuVwCharCheckboxe name="areaCheck" setNumberOfChecked={setNumberOfChecked} numberOfChecked={numberOfChecked}>
        {area}
      </SkuVwCharCheckboxe>

      <SkuVwCharCheckboxe name="volumeCheck" setNumberOfChecked={setNumberOfChecked} numberOfChecked={numberOfChecked}>
        {volume}
      </SkuVwCharCheckboxe>

      <SkuVwCharCheckboxe name="weightCheck" setNumberOfChecked={setNumberOfChecked} numberOfChecked={numberOfChecked}>
        {weight}
      </SkuVwCharCheckboxe>

      <SkuVwCharCheckboxe name="palletCheck" setNumberOfChecked={setNumberOfChecked} numberOfChecked={numberOfChecked}>
        {pallet}
      </SkuVwCharCheckboxe>

      <div style={{position: "absolute", bottom: "1em", right: "1em"}}>
        <ControlCheck name="dd" date="ddd" initialChecked={false} />
      </div>
    </div>
  );
};

export default VolumeWeightCharacteristics;
