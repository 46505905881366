import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {
  getAreaMeasurementQuantites,
  getBrands,
  getColors,
  getCountries,
  getLengthMeasurementQuantites,
  getMaterials,
  getMeasurementObjects,
  getMeasurementUnits,
  getPallets,
  getProductGroups,
  getProductTypes,
  getSkuOptionsList,
  getVolumeMeasurementQuantites,
  getWeightMeasurementQuantites,
} from "../../selectors/skuSelectors";

const useSkuCatalogsService = () => {
  const {i18n} = useTranslation();

  const brands = useSelector(getBrands),
    colors = useSelector(getColors),
    countries = useSelector(getCountries),
    materials = useSelector(getMaterials),
    types = useSelector(getProductTypes),
    groups = useSelector(getProductGroups),
    measurementUnits = useSelector(getMeasurementUnits),
    pallets = useSelector(getPallets),
    measurementObjects = useSelector(getMeasurementObjects),
    // skuOptions = useSelector(getSkuOptionsList),
    lengthMQ = useSelector(getLengthMeasurementQuantites),
    areaMQ = useSelector(getAreaMeasurementQuantites),
    volumeMQ = useSelector(getVolumeMeasurementQuantites),
    weightMq = useSelector(getWeightMeasurementQuantites);

  const namingType = useMemo(() => (i18n.language === "en" ? "engName" : "name"), [i18n.language]);

  const brandList = useMemo(
      () =>
        brands.map(item => {
          return {title: item.name, value: item.brandId};
        }),
      [brands],
    ),
    colorsList = useMemo(
      () =>
        colors.map(item => {
          return {title: item[namingType], value: item.colorId};
        }),
      [colors, namingType],
    ),
    countriesList = useMemo(
      () =>
        countries.map(item => {
          return {title: item.shortName.replace(/ +/g, " ").trim(), value: item.countryId};
        }),
      [countries],
    ),
    materialsList = useMemo(
      () =>
        materials.map(item => {
          return {title: item[namingType], value: item.materialId};
        }),
      [materials, namingType],
    ),
    measurementUnitsList = useMemo(
      () =>
        measurementUnits.map(item => {
          return {title: item[namingType], value: item.unitId};
        }),
      [measurementUnits, namingType],
    ),
    productGroups = useMemo(
      () =>
        groups.map(item => {
          return {title: item.name, value: item.productGroupId};
        }),
      [groups],
    ),
    productTypes = useMemo(
      () =>
        types.map(item => {
          return {title: item[namingType], value: item.productTypeId};
        }),
      [types, namingType],
    ),
    palletsList = useMemo(
      () =>
        pallets.map(item => {
          return {title: item.name, value: item.palletId, length: item.length, width: item.width};
        }),
      [pallets, namingType],
    ),
    measurementObjectsList = useMemo(
      () =>
        measurementObjects.map(item => {
          return {title: item[namingType], value: item.objectId};
        }),
      [pallets, namingType],
    ),
    lengthMeasurementQuantites = useMemo(
      () =>
        lengthMQ.map(item => {
          return {title: item[namingType], value: item.unitId, multiplier: item.multiplier};
        }),
      [lengthMQ, namingType],
    ),
    areaMeasurementQuantites = useMemo(
      () =>
        areaMQ.map(item => {
          return {title: item[namingType], value: item.unitId, multiplier: item.multiplier};
        }),
      [areaMQ, namingType],
    ),
    volumeMeasurementQuantites = useMemo(
      () =>
        volumeMQ.map(item => {
          return {title: item[namingType], value: item.unitId, multiplier: item.multiplier};
        }),
      [volumeMQ, namingType],
    ),
    weightMeasurementQuantites = useMemo(
      () =>
        weightMq.map(item => {
          return {title: item[namingType], value: item.unitId, multiplier: item.multiplier};
        }),
      [weightMq, namingType],
    );

  return {
    brandList,
    colorsList,
    countriesList,
    materialsList,
    productGroups,
    productTypes,
    measurementUnitsList,
    palletsList,
    measurementObjectsList,
    weightMeasurementQuantites,
    volumeMeasurementQuantites,
    areaMeasurementQuantites,
    lengthMeasurementQuantites,
  };
};

export default useSkuCatalogsService;
