export function makeFilterManager(filtersKey) {
  return {
    getFilters: () => {
      let parsedValue = null;
      try {
        parsedValue = JSON.parse(sessionStorage.getItem(filtersKey));
      } catch (error) {
        console.error(error);
      }
      return parsedValue;
    },
    setFilters: value => sessionStorage.setItem(filtersKey, JSON.stringify(value)),
    clearFilters: () => sessionStorage.setItem(filtersKey, null),
  };
}
