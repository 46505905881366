import React from 'react';
import { useTranslation } from 'react-i18next';

import ReactTable from '../../../conteiners/ReactTable';
import useColumns from '../../../Requests/OrderProcessing/utils/hooks/useColumns';

import { ADDITIONAL_SERV_TABLE_COLUMNS } from './constants';

const AdditionalServices = ({headerNumber = 5}) => {
    const {t} = useTranslation();
    const documentsTableColumns = useColumns(ADDITIONAL_SERV_TABLE_COLUMNS);
    const additionalServicesTableData = []
    return (
      <div  className='form_wrapper'>
        <div className="header-block">{`${headerNumber}. ${t("Register of additional services")}:`}</div>
        <ReactTable selectable={false} columns={documentsTableColumns} defaultData={additionalServicesTableData || [] } />
      </div>
    )
};

export default AdditionalServices;