import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {countObjectValuesSumm} from "../../../../utilities/countObjectValuesSumm";
import {
  addNewCargo,
  addSPToCargo,
  fetchAllCargos,
  fetchAllStockPoolsNew,
  removeFromCargo,
} from "../../redux/operations";
import {
  selectAllCargos,
  selectFactoryCreatingCargoSP,
  selectWarehouseCreatingCargoStockPools,
} from "../../redux/selectors";

const useFactoryCreatingTPMod = (selectedFactory, selectedWarehouse, identifier) => {
  const dispatch = useDispatch();

  const [selectedSP, setSelectedSp] = useState([]);
  const [curentCargoSP, setCurentCargoSP] = useState(null);
  const {values, setValues, setFieldValue} = useFormikContext();
  const [isFinalDestination, setIsFinalDestination] = useState(false);

  const cargos = useSelector(selectAllCargos);

  const factoryCreatingCargoSP = useSelector(selectFactoryCreatingCargoSP);
  const warehouseCreatingCargoSP = useSelector(selectWarehouseCreatingCargoStockPools);

  const cargoOptions = useMemo(() => {
    if (cargos)
      return cargos?.map(cargo => ({value: cargo.cargoId, title: cargo.cargoNumber, isFinal: cargo.isFinal} || []));
  }, [cargos]);

  const defineFinalDestination = option => {
    setIsFinalDestination(!!option?.isFinal);
  };

  useEffect(() => {
    if (selectedFactory) {
      updateAndClear();
      setFieldValue("factory", selectedFactory?.title);
    } else if (selectedWarehouse) {
      updateAndClear();
      setFieldValue("warehouse", selectedWarehouse?.title);
    } else {
      updateAndClear();
      // setFieldValue("factory", selectedFactory?.title);
    }
  }, []);

  useEffect(() => {
    setFieldValue("transportPoolNumber", "");
    setFieldValue("transportPoolId", "");
  }, [values?.type]);

  useEffect(() => {
    let totalV = 0;
    let totalW = 0;
    const selectedType = factoryCreatingCargoSP || warehouseCreatingCargoSP;

    if (selectedSP.length > 0) {
      selectedType?.forEach(stockPool => {
        if (selectedSP.includes(stockPool.stockPoolId)) {
          if (stockPool?.packingSpaces?.length > 0) {
            totalV += countObjectValuesSumm(stockPool.packingSpaces, "volume");
            totalW += countObjectValuesSumm(stockPool.packingSpaces, "weight");
          }
        }
      });
    }
    setFieldValue("totalWeight", totalW || "");
    setFieldValue("totalVolume", totalV || "");
  }, [selectedSP]);

  const addAndDistribute = () => {
    dispatch(
      addNewCargo({
        stockPoolIds: selectedSP,
        warehouseId: values?.warehouseAddressId || values?.warehouseId,
        cargoNumber: values?.transportPoolNumber,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setValues({...values, warehouseId: "", warehouseName: "", warehouseAddress: ""});
        updateAndClear();
      }
    });
  };

  const addStockPoolToCargo = () => {
    dispatch(
      addSPToCargo({
        cargoId: values?.transportPoolId,
        stockPoolIds: selectedSP,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setValues({...values, transportPoolId: "", warehouseId: ""});
        updateAndClear();
      }
    });
  };

  const removeSPFromCargo = () => {
    dispatch(removeFromCargo([curentCargoSP])).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        updateAndClear();
      }
    });
  };

  const updateAndClear = () => {
    if (identifier === "factory") {
      dispatch(fetchAllCargos({factoryId: selectedFactory.value}));
      dispatch(fetchAllStockPoolsNew({window: "Factory Creating Cargo", factoryId: selectedFactory.value}));
      dispatch(fetchAllStockPoolsNew({window: "Factory Cargo", factoryId: selectedFactory.value}));
    } else {
      dispatch(fetchAllCargos({warehouseId: selectedWarehouse.value}));
      dispatch(fetchAllStockPoolsNew({window: "Warehouse Creating Cargo", warehouseId: selectedWarehouse?.value}));
      dispatch(fetchAllStockPoolsNew({window: "Warehouse Cargo", warehouseId: selectedWarehouse?.value}));
    }
  };

  return {
    setSelectedSp,
    addAndDistribute,
    addStockPoolToCargo,
    cargoOptions,
    curentCargoSP,
    setCurentCargoSP,
    removeSPFromCargo,
    selectedSP,
    defineFinalDestination,
    isFinalDestination,
  };
};

export default useFactoryCreatingTPMod;
