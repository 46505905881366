import {useTranslation} from "react-i18next";

import {TextAreaInput, TextInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";

const DecorColorBlock = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="decor-color-block">
        <FieldWithSample
          name="materialCheck"
          label={t("Finishing")}
          className="text-area_width"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextInput name="material" width="10em" />
        </FieldWithSample>
        <FieldWithSample
          name="materialTypeCheck"
          label={t("Finishing type")}
          style={{display: "flex", flexDirection: "column"}}
        >
          <TextAreaInput name="materialType" width={"10em"} />
        </FieldWithSample>
      </div>
      <div className="decor-color-block decor-color-block_c">
        <FieldWithSample
          name="colorCheck"
          label={t("Color")}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextInput name="color" width="10em" />
        </FieldWithSample>
        <FieldWithSample
          name="colorTypeCheck"
          label={t("Color type")}
          style={{display: "flex", flexDirection: "column"}}
        >
          <TextAreaInput name="colorType" width={"10em"} />
        </FieldWithSample>
      </div>
    </>
  );
};

export default DecorColorBlock;
