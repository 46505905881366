import {useTranslation} from "react-i18next";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import usePaymentsDoc from "./utils/hook/paymentDocService";

const DocumentsForPayment = ({data, columns}) => {
  const {t} = useTranslation();

  const columnsForPayment = useColumns(columns);
  // const {onDocSelect, current} = usePaymentsDoc(1);
  return (
    <>
      <div className="header-blocks">{t("Document list for payment")}</div>
      <ReactTable
        defaultData={data}
        columns={columnsForPayment}
        // onSelect={() => {}}
        // current={current}
        className={"project_table docs_for_payment_table"}
        selectable={false}
        style={{height: "8.5em"}}
      />
    </>
  );
};

export default DocumentsForPayment;
