export const ORDER_INFO_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
    size: 100,
    isSortable: true,
  },

  {
    header: "Request №",
    accessorKey: "requestNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Sum",
    accessorKey: "orderSumm",
    size: 100,
    isSortable: true,
  },
  {
    header: "Country",
    accessorKey: "orderCountry",
    size: 100,
    isSortable: true,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 100,
  },
]);
