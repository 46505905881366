import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {STATUS_COLORS} from "../../../utilities/colorConstants";
import DateStatusCircle from "../../../utilities/DateStatusCircle";
import QuestionItem from "../../../utilities/QuestionItem";
import ReadOnlyFields from "../../hoc/ReadOnlyFields";
import {DateInput} from "../../Inputs";
import {getRequestInfo} from "../RequestsSlice";

const InProgressCard = ({...props}) => {
  const {t} = useTranslation();
  const {
    header,
    dateStartLabel,
    dateEndLabel,
    buttonName,
    dateStartName,
    dateEndName,
    frame,
    onClick,
    descText,
    type,
    disabledDate,
    forcedDisable,
    createdAtDate,
    showButton,
  } = props;

  const currentRequest = useSelector(getRequestInfo);

  const {values} = useFormikContext();

  const buttonPastDisabled = values[disabledDate] && values[disabledDate] !== "";

  const plannedDate = values[dateStartName];
  const actualDate = values[dateEndName];
  const createdDate = values[createdAtDate];

  const btnDisabled =
    forcedDisable ||
    ((!values[dateStartName] || values[dateStartName] === "") &&
      (!values[dateEndName] || values[dateEndName] === "")) ||
    currentRequest?.statusId >= 12;

  const redinessButtonDisabled =
    (dateStartLabel === "" ? values.plannedReadinessDate === "" : values.onPaymentDateE === "") ||
    currentRequest?.statusId >= 12;

  return (
    <div className="card" style={{border: `1px solid ${frame}`}}>
      <div className="header-wrapper">
        <div className="header-card">
          <DateStatusCircle plannedDate={plannedDate} createdAtDate={createdDate} actualDate={actualDate} />

          <div className="names q_wrapper">
            {t("Date")}
            <QuestionItem title={"date tip"} />
          </div>
        </div>
        <p className="names">{header}</p>
      </div>
      <ReadOnlyFields>
        <div className="dates">
          {dateStartName && <DateInput label={dateStartLabel} name={dateStartName} />}
          {dateEndName && <DateInput label={dateEndLabel} name={dateEndName} />}
        </div>
      </ReadOnlyFields>
      <FunctionButton
        name={buttonName}
        className={showButton ? "card_btn" : "card_btn_dnt_show"}
        tooltipMessage={descText}
        onClick={onClick}
        disabled={type === "receipt" ? redinessButtonDisabled : buttonPastDisabled || btnDisabled || type === "logist"}
      />
    </div>
  );
};

InProgressCard.propTypes = {
  statusButton: PropTypes.string,
  header: PropTypes.string,
  dateStartLabel: PropTypes.string,
  dateEndLabel: PropTypes.string,
  buttonName: PropTypes.string,
  dateStartName: PropTypes.string,
  dateEndName: PropTypes.string,
  frame: PropTypes.oneOf(Object.values(STATUS_COLORS)),
  onInProgressCardBtnClick: PropTypes.func,
};

export default InProgressCard;
