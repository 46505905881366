import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../../../utilities/QuestionItem";
import ReactTable from "../../../../conteiners/ReactTable";
// import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import {sendForPayment} from "./redux/PaymentOperationsSlice";
import {getDocsForPayment, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import useSchemasData from "./utils/hook/schemasData";
import {FIN_SCHEMA_COLUMNS} from "./columnsForPaymentTables";

const FinScheme = ({data, columns, current}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [schemaIds, setSchemaIds] = useState([]);

  // const {paymentSchemas} = usePaymentsData();
  const payment = useSelector(getPayment);
  const docsForPayment = useSelector(getDocsForPayment);

  const finSchemeColumns = useColumns(columns);

  return (
    <>
      <div className="header-blocks q_wrapper">
        <QuestionItem title="select scheme" />
        {t("Financial payment scheme")}
      </div>
      <ReactTable
        defaultData={data}
        columns={finSchemeColumns}
        // onSelect={() => {}}
        // defaultValue={current}
        className={"project_table docs_for_payment_table"}
        selectable={false}
        style={{height: "8.5em"}}
        // setSelectedItems={setSchemaIds}
      />
      {/* <div className="button-pay">
        <FunctionButton
          name={t("Send for payment")}
          autoWidth
          tooltipMessage="send doc"
          // disabled={true}
          onClick={onSendForPayment}
        />
      </div> */}
    </>
  );
};

export default FinScheme;
