import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import ReadOnlyFields from "../../../hoc/ReadOnlyFields";
import {TextInput} from "../../../Inputs";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {setCurenCheckLIstFile} from "../../redux/LogistSlice";
import {selectCurrentSkuRequest} from "../../redux/selectors";
import withLogistCheckList from "../CheckListsHOC";

import {REQUEST_SKU_SHORT_COLUMNS} from "./../../../Requests/OrderProcessing/RequestSkuBlock/utils/constants";

const OrderCheckLIst = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const curentSkuRequest = useSelector(selectCurrentSkuRequest);

  useEffect(() => {
    if (curentSkuRequest?.request?.proforms) {
      dispatch(setCurenCheckLIstFile({id: -1, url: null}));
    }
    return () => {
      dispatch(setCurenCheckLIstFile(null));
    };
  }, [curentSkuRequest]);

  const columns = useColumns(REQUEST_SKU_SHORT_COLUMNS);

  const RequestSkuBlockTableData = curentSkuRequest?.sku.map(
    (
      {
        skuRequestId,
        article,
        brand,
        productGroup,
        productType,
        name,
        nameEng,
        material,
        color,
        listPrice,
        retailPrice,
        length,
        width,
        height,
        weight,
        amount,
        area,
        volumeWeight,
      },
      i,
    ) => ({
      id: skuRequestId,
      number: i + 1,
      article: brand.prefix + "-" + article,
      brandName: brand.name,
      productGroup: productGroup?.name,
      productType: productType?.name,
      name: i18n === "eng" ? nameEng : name,
      material,
      color,
      listPrice,
      retailPrice,
      length,
      width,
      height,
      weight,
      amount,
      area,
      volumeWeight,
    }),
  );

  return (
    <>
      <div className=""> {`4. ${t("SKU in the Order (Amount by Prof.)")}:`}</div>
      <div style={{height: "16.2em", maxWidth: "560px"}}>
        <ReactTable
          defaultData={RequestSkuBlockTableData ?? []}
          columns={columns}
          selectable={false}
          className="scrollX"
        />
      </div>
      <div className="">{`5. ${t("Details of the recipient")}:`}</div>
      <div style={{backgroundColor: "white", padding: "1em", maxWidth: "560px"}} className="thin_border_container">
        <ReadOnlyFields>
          <TextInput label={t("Payer")} name="payer" autolabel />
          <TextInput label={t("Payer Address") + ":"} name="payerAddress" autolabel />
          <TextInput label={t("Recipient") + ":"} name="recipient" autolabel />
          <TextInput label={t("Recipient Address") + ":"} name="recipientAddress" autolabel />
          <TextInput label={t("Customs clearance")} name="decommissioning" autolabel />
          <div className="fc jcsb nowrap">
            <TextInput label={t("Delivery condition") + ":"} name="delCondition" autolabel />
            <TextInput label={t("Take To") + ":"} name="takeTo" autolabel />
            <TextInput label={t("Product country") + ":"} name="productCountry" autolabel />
          </div>
        </ReadOnlyFields>
      </div>
    </>
  );
};

export default withLogistCheckList(OrderCheckLIst);
