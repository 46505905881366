import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";
import * as Yup from "yup";

import ReactTable from "../../../../conteiners/ReactTable";
import {NoteInput, TextInput} from "../../../../Inputs";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {addWarehouseServiceType, deleteWarehouseServiceType} from "../../../redux/Warehouses/operations";
import {selectWarehouseServiceTypes} from "../../../redux/Warehouses/selectors";
import {SETTINGS_STOREGE_SERV_TYPE_COLUMNS} from "../constants";

const ServicesType = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [curentType, setCurrentType] = useState();

  const columns = useColumns(SETTINGS_STOREGE_SERV_TYPE_COLUMNS);
  const types = useSelector(selectWarehouseServiceTypes);
  const {submitForm, resetForm} = useFormikContext();
  const typesTableData =
    types?.map((type, index) => ({
      id: type.typeId,
      number: ++index,
      serviceType: type.name || "",
      serviceTypeDescription: type?.note || "",
    })) || [];

  const createNewType = () => {
    submitForm().then(values => {
      if (values) {
        dispatch(addWarehouseServiceType({...values})).then(() => resetForm());
      }
    });
  };

  const onRemoveType = () => {
    Swal.fire({
      title: t("Sure"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteWarehouseServiceType(curentType)).then(() => {
          setCurrentType(null);
          resetForm();
        });
      }
    });
  };

  return (
    <div className="w100 mt0_5 w100">
      <TextInput name={"name"} label={t("Service type")} />
      <NoteInput
        name={"note"}
        label={t("Опис типу послуги") + ":"}
        deleteIcon
        onDelete={onRemoveType}
        autolabel
        deleteDisabled={!curentType}
        action={createNewType}
        width={"100%"}
      />
      <div style={{height: "11em"}}>
        <ReactTable columns={columns} defaultData={typesTableData} onSelect={setCurrentType} current={curentType} />
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({name: "", note: ""}),
  handleSubmit: values => values,
  enableReinitialize: true,
  validationSchema: () =>
    Yup.object({
      name: Yup.string().required([t("Required field")]),
      note: Yup.string().required([t("Required field")]),
    }),
})(ServicesType);
