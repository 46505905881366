import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import ReqTemplatesModal from "../../settings/modTemplates/ReqTemplatesModal/ReqTemplatesModal";

import useRequestTemplatesService from "./useRequestTemplatesService";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const RequestTemplates = () => {
  const {t} = useTranslation();

  const {
    reqTemplatesTableColumns,
    reqTemplatesTableData,
    onReqTemplatesTableRowSelect,
    isEditListBtnDisabled,
    onEditListBtnClick,
    isReqTemplatesModalActive,
    setReqTemplatesModalActive,
    onReqTemplatesModalClose,
    currentConditionId,
  } = useRequestTemplatesService();

  return (
    <section className={styles.reqTextSection}>
      <h2 className={styles.sectionHeader}>
        2. <span className={styles.underLined}>{t("Fabric Request Templates")}</span>
      </h2>
      <ReactTable
        className={styles.reqAutodistrTableWrapper}
        tableClassName={styles.reqAutodistrTable}
        columns={reqTemplatesTableColumns}
        defaultData={reqTemplatesTableData}
        onSelect={onReqTemplatesTableRowSelect}
        current={currentConditionId}
      />
      <FunctionButton
        name="Edit list"
        disabled={isEditListBtnDisabled}
        className={styles.reqTextSaveBtn}
        onClick={onEditListBtnClick}
        autoWidth
      />
      <ModalForm
        closable={true}
        Component={ReqTemplatesModal}
        label={t("Factory Request Templates")}
        active={isReqTemplatesModalActive}
        setModalActive={setReqTemplatesModalActive}
        onModalClose={onReqTemplatesModalClose}
      />
    </section>
  );
};

export default RequestTemplates;
