import React from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {flexRender} from "@tanstack/react-table";

const AddToKpfTableService = ({children, className, table}) => {
  return (
    <>
      <div
        // table_dnd_container
        className={` project_table ${className}`}
      >
        <table style={{borderCollapse: "collapse"}}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      position: "relative",
                      width: header.getSize(),
                      opacity: header.column.columnDef.isDisabled ? 0.33 : 1,
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                      ></div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </>
  );
};

export default AddToKpfTableService;
