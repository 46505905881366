import {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

const ControlFieldsChanges = ({editFunc, forcedUpdate, updFunc}) => {
  const {values, initialValues} = useFormikContext();

  const [isEdited, setIsEdited] = useState(false);
  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [values]);

  useEffect(() => {
    editFunc(isEdited);
  }, [isEdited]);

  useEffect(() => {
    if (isEdited) {
      if (updFunc != null) updFunc(values);
      setIsEdited(false);
    }
  }, [forcedUpdate]);

  return null;
};

ControlFieldsChanges.propTypes = {
  editFunc: PropTypes.func,
  forcedUpdate: PropTypes.bool,
  updFunc: PropTypes.func,
};

export default ControlFieldsChanges;
