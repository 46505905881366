export const WR_TERMS_MAIN_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Country",
    accessorKey: "country",
    size: 25,
    isSortable: true,
  },
  {
    header: "Warehouse",
    accessorKey: "warehouse",
    size: 25,
    isSortable: true,
  },
  {
    header: "Start Date",
    accessorKey: "startDate",
    size: 20,
    isSortable: true,
  },
  {
    header: "Description",
    accessorKey: "description",
    size: 75,
  },
  {
    header: "Type",
    accessorKey: "type",
    size: 20,
  },
  {
    header: "Term",
    accessorKey: "term",
    size: 15,
  },
  {
    header: "Notification",
    accessorKey: "notification",
    size: 120,
  },
]);

export const START_DATES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Start Date",
    accessorKey: "startDateName",
    isSortable: true,
  },
  {
    header: "Description",
    accessorKey: "description",
  },
]);

export const WR_SETTINGS_INFO_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Start Date",
    accessorKey: "startDate",
    size: 25,
    isSortable: true,
  },
  {
    header: "Description",
    accessorKey: "description",
    size: 45,
    isSortable: true,
  },
  {
    header: "Type",
    accessorKey: "type",
    size: 20,
  },
  {
    header: "Term",
    accessorKey: "term",
    size: 30,
  },
  {
    header: "Notification",
    accessorKey: "notification",
    size: 120,
  },
]);
