import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchAllWarehouses} from "../../redux/operations";
import {selectWarehouses} from "../../redux/selectors";

const useWarehouseOptions = () => {
  const warehouses = useSelector(selectWarehouses);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!warehouses || warehouses?.length === 0) {
      dispatch(fetchAllWarehouses());
    }
  }, []);

  const warehousesOptions =
    warehouses?.map(house => ({title: house?.name, value: house.warehouseId, address: house?.address || ""})) || [];

  return {warehousesOptions};
};

export default useWarehouseOptions;
