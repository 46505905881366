import {useTranslation} from "react-i18next";
import {useFormikContext, withFormik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

import useOrderAcceptanceYes from "./useOrderAcceptanceYes";

const OrderAcceptanceYes = ({acceptedSku}) => {
  const {t} = useTranslation();

  const {
    skuTableColumns,
    skuTableData,
    onSkuTableSelect,
    currentSkuId,
    usersOptions,
    isSaveForSignatureBtnDisabled,
    exportToPdf,
  } = useOrderAcceptanceYes({
    acceptedSku,
  });
  const {setFieldValue} = useFormikContext();
  return (
    <div>
      <div className="fdr jcc gap2x">
        <TextInput label="FDP" name="fdp" width="100%" autolabel disabled />
        <TextInput label={t("Request №") + ":"} name="requestNumber" width="100%" autolabel disabled />
        <TextInput label={t("Order № ") + ":"} name="orderNumber" width="100%" autolabel disabled />
        <TextInput label={t("Manager CO")} name="managerCo" width="12em" autolabel readOnly />
        <TextInput label={t("Logist") + ":"} name="logist" width="100%" autolabel disabled />
      </div>
      <Divider />
      <div className="fdr gap2x aic jcsb">
        <TextInput label={t("City") + ":"} name="city" width="100%" autolabel disabled />
        <div style={{fontSize: "20px", width: "20em", textAlign: "center", lineHeight: "1.5"}}>{t("Assets Act")}</div>
        <TextInput label={t("Act Date") + ":"} name="actDate" width="100%" autolabel />
      </div>
      <div className="fdr w100 jcsb gap2x">
        <div className="fcCols w50">
          <Select
            label={t("Transfered by") + ":"}
            name="transferedBy"
            options={usersOptions}
            autolabel
            width="22em"
            makeEffect={() => setFieldValue("transferByYourOption", "")}
          />
          <TextAreaInput name="transferByYourOption" width={"100%"} placeholder={t("or enter transfered")} />
        </div>
        <div className="fcCols w50">
          <Select
            label={t("Accepts by") + ":"}
            name="acceptedBy"
            options={usersOptions}
            autolabel
            width="22em"
            makeEffect={() => setFieldValue("acceptedByYourOption", "")}
          />
          <TextAreaInput name="acceptedByYourOption" width={"100%"} placeholder={t("or enter accepted")} />
        </div>
      </div>
      <TextAreaInput name="actInfo" width={"100%"} placeholder={t("Act Logist Info")} />
      <ReactTable
        defaultData={skuTableData}
        columns={skuTableColumns}
        onSelect={onSkuTableSelect}
        current={currentSkuId}
        className={"scrollX mt mb tableTac"}
        style={{height: "22em"}}
      />
      <div className="fdr aife jcsb">
        <TextAreaInput name="claimsInfo" width={"40em"} height="2em" placeholder={t("Claims info")} readOnly />
        <FunctionButton
          name="Save for signature"
          tooltipMessage="signatureTooltip"
          autoWidth
          disabled={isSaveForSignatureBtnDisabled}
          onClick={exportToPdf}
        />
      </div>
    </div>
  );
};

// const OrderAcceptanceYes = withFormik({
//   enableReinitialize: true,
//   mapPropsToValues: () => ({

//   }),
// })(OrderAcceptanceYesBase);

export default OrderAcceptanceYes;
