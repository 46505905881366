// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordering_table__O5t-B {
  height: 200px;
}
@media screen and (min-width: 1440px) {
  .ordering_table__O5t-B {
    height: 270px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Requests/RequestRegister/ordering/ordering.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EAHF;IAII,aAAA;EAEF;AACF","sourcesContent":[".table {\n  height: 200px;\n\n  @media screen and (min-width: 1440px) {\n    height: 270px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ordering_table__O5t-B`
};
export default ___CSS_LOADER_EXPORT___;
