import {useEffect, useRef} from "react";

export const useHotkey = (func, ...codes) => {
  const pressed = useRef([]);

  function onKeyDown(event) {
    pressed.current = [...pressed.current, event.code];
    for (let code of codes) {
      if (!pressed.current.includes(code)) {
        return;
      }
    }
    pressed.current = [];
    func();
  }

  function onKeyUp(event) {
    pressed.current = pressed.current.filter(code => code !== event.code);
  }

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);
};
