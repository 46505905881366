// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/icons/openEye.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput_passwordInputWrapper__hs6eT {
  position: relative;
}

.PasswordInput_toggleShowPasswordBtn__swiA6 {
  position: absolute;
  top: -1.7em;
  right: 5px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: transform 200ms ease-in-out;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
}
.PasswordInput_toggleShowPasswordBtn__swiA6:active {
  transform: translate(0px, 1px);
}`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/PasswordInput/PasswordInput.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,SAAA;EACA,eAAA;EACA,uCAAA;EACA,yDAAA;EAEA,2BAAA;EACA,4BAAA;EACA,0BAAA;AAAF;AAEE;EACE,8BAAA;AAAJ","sourcesContent":[".passwordInputWrapper {\n  position: relative;\n}\n\n.toggleShowPasswordBtn {\n  position: absolute;\n  top: -1.7em;\n  right: 5px;\n  padding: 0;\n  width: 30px;\n  height: 30px;\n  background-color: transparent;\n  border: 0;\n  cursor: pointer;\n  transition: transform 200ms ease-in-out;\n  background-image: url(\"../../../images/icons/openEye.png\");\n\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: 17px 17px;\n\n  &:active {\n    transform: translate(0px, 1px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordInputWrapper": `PasswordInput_passwordInputWrapper__hs6eT`,
	"toggleShowPasswordBtn": `PasswordInput_toggleShowPasswordBtn__swiA6`
};
export default ___CSS_LOADER_EXPORT___;
