import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import InvoiceAndPackBlock from "../../RequestsInProgress/ReceiptGoodsModal/InvoiceAndPackBlock";
import MeasurementBlock from "../../RequestsInProgress/ReceiptGoodsModal/MeasurementBlock";

const StockPoolRegistrDocs = ({stockPool, currentTransportDoc}) => {
  const initialValues = useMemo(() => ({}));
  const {t} = useTranslation();

  return (
    <div className="h100" style={{width: "50%"}}>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={Yup.object().shape({
          managerFea: Yup.string().required(t("mng fea")),
          date: Yup.date().required(t("Enter date")),
        })}
        onSubmit={() => {}}
      >
        {({values}) => (
          <Form className="fcCol">
            <InvoiceAndPackBlock currentTransportDoc={currentTransportDoc} element={stockPool} />
            <div className="w100">
              <MeasurementBlock tHeight={"7em"} element={stockPool} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StockPoolRegistrDocs;
