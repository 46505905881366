/* eslint-disable react/display-name */
import {Form, Formik} from "formik";
import * as Yup from "yup";

export const withFormikForm = FormComponent => {
  return ({initialValues, onSubmit, validationObject}) => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationObject)}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={onSubmit}
        >
          <Form>
            <FormComponent />
          </Form>
        </Formik>
      </div>
    );
  };
};
