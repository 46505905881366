export const getPaymentDocs = state => state.payment.paymentDocs;
export const getPayment = state => state.payment.payment;
export const getPayments = state => state.payment.payments;
export const getReaquestPayments = state => state.payment.requestPayments;
export const getDocsForPayment = state => state.payment.docsForPayment;
export const getDocTypes = state => state.payment.docTypes;
export const getDocsByPayment = state => state.payment.docsByPayment;
export const getPaymentSchemas = state => state.payment.paymentSchemas;
export const getCurrentDocument = state => state.payment.currentDocument;
export const getCurrentPayment = state => state.payment.currentPayment;
export const getCurrentPaymentId = state => state.payment.currentPaymentId;
export const selectCurrentPaymentPayDoc = state => state.payment.currentPayment?.paymentDoc ?? null;
export const selectCurrentPaymentId = state => state.payment.currentPayment?.paymentId ?? null;
export const selectCurrentPaymentRequestId = state => state.payment.currentPayment?.request?.requestId ?? null;
export const getPaymentsLoader = state => state.payment.paymentsLoader;
