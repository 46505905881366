import React from "react";
import {useTranslation} from "react-i18next";

import {TextAreaInput} from "../../../Inputs";

const FactoryInfo = ({headerNumber = 1}) => {
  const {t} = useTranslation();

  return (
    <div>
      <div className="fc jcsb aibl">
        <div className="header-block mb0">{`${headerNumber}. ${t("Factory Address")}:`}</div>
      </div>
      <TextAreaInput name="factoryInfo" height="40px" />
    </div>
  );
};

export default FactoryInfo;
