import {useTranslation} from "react-i18next";

import ReactTable from "../../../../../../conteiners/ReactTable";
import {DateInput, TextAreaInput} from "../../../../../../Inputs";
import FinSchemaToFactory from "../FinSchemaToFactory/FinSchemeToFactory";

import useOnPaymentBlockService from "./useOnPaymentBlockService";

const OnPaymentBlock = () => {
  const {t} = useTranslation();

  const {onPaymentTableColumns, onPaymentTableData} = useOnPaymentBlockService();

  return (
    <div>
      <DateInput label={t("Date: Pay to") + ":"} name={"payToDate"} readOnly />
      <ReactTable
        defaultData={onPaymentTableData}
        columns={onPaymentTableColumns}
        current={""}
        className={"project_table"}
        style={{height: "4em"}}
        selectable={false}
      />
      <TextAreaInput name="requestNote" width={"100%"} placeholder={t("note zed factory")} readOnly />
      <FinSchemaToFactory />
      <div className="border"></div>
    </div>
  );
};

export default OnPaymentBlock;
