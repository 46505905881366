import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, useFormikContext, withFormik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

import WarehouseSplitCreateModalFilters from "./WarehouseSplitCreateModalParts/filters/WarehouseSplitCreateModalFilters";
import SplitCreateLowerTables from "./WarehouseSplitCreateModalParts/SplitCreateLowerTables";
import SplitCreateUpperTables from "./WarehouseSplitCreateModalParts/SplitCreateUpperTables";
import useWarehouseSplitCreateModal from "./WarehouseSplitCreateModalParts/useWarehouseSplitCreateModal";

const WarehouseSplitCreateModalBase = ({selectedWarehouse}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    stockPoolOptions,
    onAddToNewSpClick,
    onAddToExistingSpClick,
    spNewButtonDisable,
    spButtonDisable,
    onReturnToWarehouseClick,
    setSelectedFilters,
    setSelectedSp,
    setSelectedUpperSkuRequests,
    setSelectedLowerSkuRequests,
    selectedLowerSkuRequests,
  } = useWarehouseSplitCreateModal(selectedWarehouse);

  const {setFieldValue} = useFormikContext();

  return (
    <div className="fdc w100">
      <WarehouseSplitCreateModalFilters setSelectedFilters={setSelectedFilters} selectedWarehouse={selectedWarehouse} />
      <Form>
        <Divider />

        <SplitCreateUpperTables
          setSelectedSp={setSelectedSp}
          setSelectedUpperSkuRequests={setSelectedUpperSkuRequests}
        />

        <FunctionButton name="Disband SP" autoWidth disabled />

        <div className="mb mt fc nowrap jcc" style={{gap: "10em"}}>
          <div>
            <label className="checkBox_label fdr w100 aibl" style={{marginBottom: "2.5em"}}>
              <Field type="radio" name="type" value="newSP" />
              <div style={{marginLeft: "1em", marginRight: "5em", fontWeight: "500", fontStyle: "normal"}}>
                {t("Create new SP")}
              </div>
            </label>

            <label className="checkBox_label fdr w100 aibl mt">
              <Field type="radio" name="type" value="SP" />
              <div style={{marginLeft: "1em", marginRight: "5em", fontWeight: "500", fontStyle: "normal"}}>
                {t("Add to existing SP")}
              </div>
            </label>
          </div>
          <div className="mb gap fcCol" style={{alignItems: "initial"}}>
            <TextInput label={t("SP №")} name="stockPoolNumber" width="12.35em" className="mb" />
            <Select
              label={t("SP №")}
              name="stockPoolId"
              options={stockPoolOptions}
              makeEffect={option => {
                setFieldValue("isFinalDest", option.isFinal);
              }}
            />
          </div>
          <div className="mb gap fcCol" style={{alignItems: "initial"}}>
            <div className="fc nowrap w100 jcfe mt ">
              <span style={{marginRight: "1em"}}>{t("mark as Final Destination")}</span>
              <Field type="checkbox" name={"newSpFinalDest"} disabled={spNewButtonDisable} />
            </div>
            <div className="fc nowrap w100 jcfe mt ">
              <span style={{marginRight: "1em"}}>{"Final Destination"}</span>
              <Field type="checkbox" name={"isFinalDest"} disabled />
            </div>
          </div>
          <div style={{alignItems: "flex-start", gap: "30px"}} className="fcCol">
            <FunctionButton name="Add to new SP" autoWidth disabled={spNewButtonDisable} onClick={onAddToNewSpClick} />
            <FunctionButton
              name="Add to existing SP"
              autoWidth
              disabled={spButtonDisable}
              onClick={onAddToExistingSpClick}
            />
          </div>
        </div>
        <SplitCreateLowerTables setSelectedLowerSkuRequests={setSelectedLowerSkuRequests} />
        <div className="fdr jcfs" style={{marginLeft: "21.5em"}}>
          <FunctionButton
            name="Return to Warehouse"
            autoWidth
            onClick={onReturnToWarehouseClick}
            disabled={selectedLowerSkuRequests.length === 0}
          />
        </div>
      </Form>
    </div>
  );
};

const WarehouseSplitCreateModal = withFormik({
  handleSubmit: () => {},
  mapPropsToValues: () => ({
    factory: "",
    totalVolume: "",
    totalWeight: "",
    type: "newSP",
    stockPoolNumber: "",
    stockPoolId: "",
    warehouseName: "",
    warehouseId: "",
    warehouseAddressId: "",
  }),
  enableReinitialize: true,
})(WarehouseSplitCreateModalBase);

export default WarehouseSplitCreateModal;
