/* eslint-disable react/display-name */
import {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

import SkuVedForm from "./SkuVedForm";

export const withSkuForm = (saveLabel, onSubmit, onCancel, OnReloadComponent) => {
  return props => {
    const {t} = useTranslation();

    return (
      <SkuVedForm onSubmit={onSubmit}>
        {/* <SubmitButton/> */}
        <FunctionButton name={saveLabel} type="submit" />
        <FunctionButton name={t("Cancel")} onClick={onCancel} />
        {/* <OnReload handleBeforeUnload={handleBeforeUnload}/> */}
        {/* {needONReload ? <OnReloadComponent/> : null} */}
        <OnReloadComponent />
      </SkuVedForm>
    );
  };
};

const OnReload = ({handleBeforeUnload}) => {
  const {values} = useFormikContext();

  const onUnload = useCallback(() => {
    console.log("reload");
    handleBeforeUnload(values);
  }, [values]);

  useEffect(() => {
    // Добавляем обработчик события beforeunload при монтировании компонента
    window.addEventListener("beforeunload", onUnload);

    // Возвращаем функцию очистки, чтобы удалить обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener("    ", onUnload);
    };
  }, [values]);

  return (
    <button type="button" onClick={onUnload}>
      dd
    </button>
  );
};
