import {Children, cloneElement, isValidElement, useEffect, useRef} from "react";
import {Field, useFormikContext} from "formik";
import PropTypes from "prop-types";

import "./utils/fieldWithSample.scss";

const FieldWithSample = ({label, children, labelWrap, name, ...props}) => {
  const {values} = useFormikContext();
  const isChecked = Boolean(values[name]);
  const inputref = useRef();

  useEffect(() => {
    if (isChecked && inputref.current && inputref.current !== document.activeElement) {
      inputref.current.focus();
    }
    if (!isChecked && inputref.current) {
      inputref.current.blur();
    }
  }, [isChecked, inputref.current]);

  return (
    <div className={`fieldWithSample ${labelWrap ? "" : "autolabel"}`} style={props.style}>
      <label className="label " style={{display: "flex", whiteSpace: "nowrap"}}>
        <Field name={name} {...props} type="checkbox" style={{marginBottom: "4px"}} />
        {label && <span>{label}</span>}
      </label>
      <div className={isChecked ? "" : "fieldWithSample__disabled"} style={{width: "100%"}} tabIndex="0">
        {Children.map(children, (child, index) => {
          if (isValidElement(child) && index === 0) {
            return cloneElement(child, {
              inputref: inputref,
            });
          }
          return child;
        })}
      </div>
    </div>
  );
};

FieldWithSample.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  props: PropTypes.array,
};

export default FieldWithSample;
