import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {updateSku} from "../../../SkuSlice";
import SkuVedForm from "../SkuVedForm";
import {withSkuForm} from "../skuVedFormHOC";

// import CancelDublicateBtn from "./CancelDublicateBtn";

const SkuUpdateForm = ({setModalActive, typeForm}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = values => {
    const {skuId, ...rest} = values;
    dispatch(
      updateSku({
        sku: rest,
        skuId: skuId,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };

  const onCancel = useCallback(() => {
    Swal.fire({
      title: t("Cancel warning"),
      text: t("Cancel warning text"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("Go back"),
    }).then(res => {
      if (res.isConfirmed) {
        setModalActive(false);
      }
    });
  }, []);

  // const SkuForm = withSkuForm(t("Save sku"), onSubmit, onCancel, null);

  // return <SkuForm />;

  return (
    <SkuVedForm onSubmit={onSubmit}>
      <FunctionButton name={t("Save sku")} type="submit" />
      <FunctionButton name={t("Cancel")} onClick={onCancel} />
    </SkuVedForm>
  );
};

SkuUpdateForm.propTypes = {setModalActive: PropTypes.func, typeForm: PropTypes.string};

export default SkuUpdateForm;
