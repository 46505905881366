import {useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectFetchedStockPool} from "../../redux/selectors";

import {LEFT_SIDE_TABLE_COLUMNS} from "./constants";

const LeftSideTable = ({tableData, isLoading, onSelect}) => {
  const columns = useColumns(LEFT_SIDE_TABLE_COLUMNS);
  const selectedSP = useSelector(selectFetchedStockPool);

  // function onRowDoubleClick(selected) {
  //   if (!selected || !selected.id) return;
  //   const isTp = selected.trPoolNumber !== " - ";

  //   dispatch(fetchStockPoolUpdated({stockPoolId: selected.id}));
  //   if (isTp) {
  //     setModalType(TP_LIST_BOSS_MODAL_TYPES.tp);
  //   } else {
  //     setModalType(TP_LIST_BOSS_MODAL_TYPES.sp);
  //   }
  //   dispatch(fetchStockPoolBySkuId(selected.id));

  //   setModalOpen(true);
  // }

  return (
    <ReactTable
      defaultData={tableData}
      columns={columns}
      onSelect={onSelect}
      current={selectedSP?.stockPoolId}
      className={"scrollX"}
      tableClassName="request-table"
      loading={isLoading && !tableData.length}
      style={{width: "clamp(370px, 65rem, 850px)"}}
      // onRowDoubleClick={onRowDoubleClick}
    />
  );
};

export default LeftSideTable;
