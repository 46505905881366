const StringRow = ({data, columns}) => {
  return (
    <>
      {data.commonString !== "" ? (
        <tr>
          <td colSpan={columns.length}>
            <div> {data.commonString}</div>
          </td>
        </tr>
      ) : null}
    </>
  );
};
export default StringRow;
