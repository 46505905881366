export const TABLE_BLOCK_COLUMNS = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Request",
    accessorKey: "request",
    // size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    size: 250,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    size: 80,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 300,
  },

  {
    header: "Status*",
    accessorKey: "status",
    size: 80,
  },
];

export const REQUEST_CREATION_COLUMNS = [
  {
    header: "Request",
    accessorKey: "request",
    // size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    // size: 150,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 70,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    // size: 80,
  },
  {
    header: "Notes",
    accessorKey: "note",
    // size: 200,
  },

  {
    header: "Status*",
    accessorKey: "status",
    // size: 80,
  },
  {
    header: "DD to factory",
    accessorKey: "toFactoryDate",
    // size: 80,
  },
  {
    header: "DD proform",
    accessorKey: "proformDate",
    // size: 100,
  },
  {
    header: "DD control",
    accessorKey: "controlDate",
    // size: 100,
  },
  {
    header: "DD for payment",
    accessorKey: "paymentDate",
    // size: 100,
  },
  {
    header: "DD paid",
    accessorKey: "paidDate",
    // size: 100,
  },
  {
    header: "DD preReady",
    accessorKey: "preReadydate",
    // size: 100,
  },
  {
    header: "DD ReadyProduct",
    accessorKey: "readyProductdate",
    // size: 100,
  },
];
