import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {fetchList} from "../../../hook/axios.hook";
import {dateFormat} from "../../../utilities/dateFormat";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {DOCTYPES} from "../common/functions";
import {LOGIST_WINDOWS} from "../common/ShipmentInfo/constants";
import LogModFdOrderAcceptance from "../LogModFdOrderAcceptance/LogModFdOrderAcceptance";
import {fetchAllStockPoolsNew, fetchStockPoolBySkuId, fetchStockPoolFilters} from "../redux/operations";
import {
  selectFinalManagerRequests,
  selectFinalManagerStockPools,
  selectIsLogistLoading,
  selectStockPoolFilters,
} from "../redux/selectors";

import {FINAL_DEST_MANAGER_LEFT_SIDE_TABLE_COLUMNS, FINAL_DEST_MANAGER_RIGHT_SIDE_TABLE_COLUMNS} from "./constants";

const useFinalDestinationManager = () => {
  const {t} = useTranslation();
  const window = LOGIST_WINDOWS.FINAL_DESTINATION_MANAGER;
  const dispatch = useDispatch();
  const {setValues} = useFormikContext();

  const FinalDestinationBossFilterManager = makeFilterManager(LOGIST_WINDOWS.FINAL_DESTINATION_MANAGER);
  const chosenFilters = FinalDestinationBossFilterManager.getFilters();

  const currentUserId = useSelector(state => state.user?.user?.id);
  const filters = useSelector(selectStockPoolFilters);
  const stockPools = useSelector(selectFinalManagerStockPools);
  const allSkuRequests = useSelector(selectFinalManagerRequests);

  const [checkedTP, setCheckedTP] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentSkuRequestId, setCurrentSkuRequestId] = useState(null);
  const [isModalAcceptanceOpen, setModalAcceptanceOpen] = useState(false);

  const [tabs, setTabs] = useState([]);
  const [skuRequests, setSkuRequests] = useState(null);

  useEffect(() => {
    if (currentSkuRequestId) {
      setTabs([
        {
          label: `${t("Order Acceptance")}`,
          Component: LogModFdOrderAcceptance,
          props: {currentSkuRequestId: currentSkuRequestId, setModalTabs: setTabs},
        },
      ]);
    }
  }, [currentSkuRequestId]);

  useEffect(() => {
    dispatch(fetchStockPoolFilters({window: window}));
  }, []);

  useEffect(() => {
    if (currentUserId) dispatch(fetchAllStockPoolsNew({window, managerLogistId: currentUserId, ...chosenFilters}));
  }, [currentUserId]);

  const logistOptions =
    filters?.logisticManagers?.map(({name, managerLogistId}) => ({title: name, value: managerLogistId})) ?? [];
  const finalDestinationOptions =
    filters?.warehouses?.map(({name, warehouseId}) => ({title: name, value: warehouseId})) ?? [];
  const orderNumberOptions =
    filters?.orderNumbers?.map(orderNumber => ({title: orderNumber, value: orderNumber})) ?? [];
  const managerCoOptions = filters?.coManagers?.map(({name, managerCoId}) => ({title: name, value: managerCoId})) ?? [];
  const areFiltersDisabled = Boolean(checkedTP?.length > 0);
  const loading = useSelector(selectIsLogistLoading);

  const leftSideTableColumns = useColumns(FINAL_DEST_MANAGER_LEFT_SIDE_TABLE_COLUMNS);
  const leftSideTableData =
    stockPools?.map((stockPool, index) => ({
      number: ++index,
      id: stockPool.stockPoolId,
      stockPoolNumber: stockPool?.stockPoolNumber ?? " - ",
      trPoolNumber: stockPool?.cargo?.cargoNumber ?? " - ",
    })) ?? [];

  const rightSideTableColumns = useColumns(FINAL_DEST_MANAGER_RIGHT_SIDE_TABLE_COLUMNS);
  const rightSideTableData =
    (skuRequests ?? allSkuRequests)?.map(({skuRequestId, order, request, stockPool} = {}, index) => {
      return {
        number: ++index,
        id: skuRequestId ?? index,
        requestStatus: stockPool?.status?.ukrName ?? " - ",
        orderNumber: order?.orderNumber ?? " - ",
        requestNumber: request?.requestNumber ?? " - ",
        fd: stockPool?.warehouse?.name ?? stockPool?.cargo?.endPoint?.name ?? " - ",
        managerCo: order?.co?.managerCo?.name ?? " - ",
        logist: stockPool?.logist?.name ?? " - ",
        factory: request?.brand?.name ?? " - ",
        requestSum: request?.requestSum ?? " - ",
        createdAt:
          dateFormat((request?.requestDates ?? []).find(date => date.dateType.dateTypeId === 8)?.actualDate) ?? " - ",
        bringTo: dateFormat(order?.co?.bringTo) ?? " - ",
        country: order?.co?.project?.country?.shortName ?? " - ",
        proformaNumber: request?.proforms[0]?.proformaNumber ?? " - ",
        invoiceNumber:
          stockPool?.docs
            ?.filter(doc => doc?.docTypeId === DOCTYPES.INVOICE)
            .map(item => item.docNumber)
            .join(" ") || " - ",
        packingListNumber:
          stockPool?.docs
            ?.filter(doc => doc?.docTypeId === DOCTYPES.PACKING_LIST)
            .map(item => item.docNumber)
            .join(" ") || " - ",
        note: request?.note ?? " - ",
      };
    }) ?? [];

  function getFilterData(params) {
    dispatch(fetchAllStockPoolsNew({window, managerLogistId: currentUserId, ...params}));
  }
  function onLeftSideTableCheck(tpList) {
    setCheckedTP(prev => {
      if (prev.length === 0 && tpList.length === 0) {
        return tpList;
      }
      if (!tpList.length && prev.length) {
        setSkuRequests(null);
      } else {
        fetchList(`/stockPools/getAll`, "get", {
          params: {window, ...chosenFilters, stockPoolIds: tpList?.join(",")},
        }).then(data => setSkuRequests(data?.skuGroups ?? null));
      }
      return tpList;
    });
  }

  function onRightSideTableSelect(skuRequestId) {
    if (!skuRequestId) {
      setValues(prev => ({...prev, shippingDate: "", deliveryDate: ""}));
      setCurrentSkuRequestId(null);
      return;
    }
    setCurrentSkuRequestId(skuRequestId);
    const currentSkuRequest = allSkuRequests?.find(skuRequest => skuRequest.skuRequestId === skuRequestId);
    if (!currentSkuRequest) return;
    setValues(prev => ({
      ...prev,
      shippingDate:
        currentSkuRequest.stockPool.cargo?.dates?.find(date => date.dateType.dateTypeId === 1)?.actualDate ?? "",
      deliveryDate:
        currentSkuRequest.stockPool.cargo?.dates?.find(date => date.dateType.dateTypeId === 2)?.plannedDate ?? "",
    }));
  }

  function onRightSideTableDoubleClick(request) {
    dispatch(fetchStockPoolBySkuId(request.id));
    setModalOpen(true);
  }

  function onOpenForReceptionBtnClick() {
    setModalAcceptanceOpen(true);
  }

  //No functional currently
  function onCarrierTrackingBtnClick() {}

  return {
    onCarrierTrackingBtnClick,
    logistOptions,
    leftSideTableColumns,
    leftSideTableData,
    rightSideTableColumns,
    rightSideTableData,
    onRightSideTableSelect,
    getFilterData,
    finalDestinationOptions,
    orderNumberOptions,
    managerCoOptions,
    onLeftSideTableCheck,
    areFiltersDisabled,
    onRightSideTableDoubleClick,
    isModalOpen,
    setModalOpen,
    onOpenForReceptionBtnClick,
    isModalAcceptanceOpen,
    setModalAcceptanceOpen,
    loading,
    currentSkuRequestId,
    tabs,
    setTabs,
  };
};

export default useFinalDestinationManager;
