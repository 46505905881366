import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import moment from "moment";
import PropTypes from "prop-types";

import deleteIcon from "../../../../../images/icons/delete.png";
import saveIcon from "../../../../../images/icons/save.png";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../../../Inputs";
import SingleFilePicker from "../../../../Inputs/SingleFilePicker";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {TRANSPORTER_DOCS_TABLE_COLUMNS, WAREHOUSE_DOCS_TABLE_COLUMNS} from "./constants";

export const SETTINGS_DOCS_BLOCK_TYPES = Object.freeze({WAREHOUSE: "warehouse", TRANSPORTER: "transporter"});

const SettingsDocs = ({
  settingsDocsTableData,
  type,
  onSaveDocBtnClick,
  onDeleteDocBtnClick,
  disabled = false,
  onDocSelect,
  currentDocId,
  isCurrentDocFile,
  onDocFileAdd,
  onDocFileDelete,
  onDocNoteAdd,
  onDocNoteDelete,
  loading = false,
}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const warehouseDocsTableColumns = useColumns(WAREHOUSE_DOCS_TABLE_COLUMNS);
  const transporterDocsTableColumns = useColumns(TRANSPORTER_DOCS_TABLE_COLUMNS);
  const settingsDocsTableColumns =
    type === SETTINGS_DOCS_BLOCK_TYPES.WAREHOUSE ? warehouseDocsTableColumns : transporterDocsTableColumns;

  function onDocAddGeneral(file) {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.docNumber);
    formdata.append("docTypeId", values.docType);
    formdata.append("dateFrom", values.onDocDate);

    if (formdata.has("doc")) {
      onDocFileAdd(formdata);
    }
  }

  return (
    <div>
      <div className="row">
        <p className="underlined">
          {type === SETTINGS_DOCS_BLOCK_TYPES.WAREHOUSE ? t("Warehouse Documents") : t("Transporter Documents")}
        </p>
        <div className="row">
          <SingleFilePicker
            addFunc={onDocAddGeneral}
            accept={".pdf"}
            disabled={disabled || !currentDocId || isCurrentDocFile}
          />
          <ImageButton
            src={deleteIcon}
            alt="delete"
            className="btn__upload"
            width={1.5}
            height={1.5}
            onClick={onDocFileDelete}
            disabled={disabled || !currentDocId || !isCurrentDocFile}
            tooltipMessage="Delete doc file"
          />
        </div>
      </div>
      <TextInput readOnly={disabled} label={t("Document name")} name={"docName"} autolabel />
      <div className="row gap2x mb0_5">
        <DateInput
          label={t("Document date")}
          name={"docDate"}
          disabled={disabled}
          flatpikrConfig={{minDate: moment().format("DD-MM-YYYY")}}
        />
        <TextInput readOnly={disabled} label={t("№")} name={"docNumber"} autolabel generalClassName="fGrow" />
        <div className="row gap0_5">
          <ImageButton
            src={saveIcon}
            alt="save"
            width={2}
            height={1.3}
            onClick={onSaveDocBtnClick}
            disabled={disabled}
            tooltipMessage="Save new doc"
          />
          <ImageButton
            src={deleteIcon}
            alt="delete"
            width={1.2}
            height={1.2}
            onClick={onDeleteDocBtnClick}
            disabled={disabled}
            tooltipMessage="Delete selected doc"
          />
        </div>
      </div>
      <ReactTable
        className="tableTac"
        columns={settingsDocsTableColumns}
        defaultData={settingsDocsTableData}
        style={{height: "8rem"}}
        current={currentDocId}
        onSelect={onDocSelect}
        loading={loading}
      />
      <div className="row mt0_5 mb0_5">
        <TextInput
          readOnly={disabled}
          label={t("note")}
          name={"settingsDocsNoteInput"}
          autolabel
          generalClassName="fGrow"
        />
        <div className="row">
          <ImageButton
            src={saveIcon}
            alt="save"
            width={2}
            height={1.3}
            disabled={disabled}
            tooltipMessage="Add document note"
            onClick={onDocNoteAdd}
          />
          <ImageButton
            src={deleteIcon}
            alt="delete"
            width={1.2}
            height={1.2}
            disabled={disabled}
            tooltipMessage="Delete document note"
            onClick={onDocNoteDelete}
          />
        </div>
      </div>
      <TextAreaInput name={"settingsDocsNoteInputField"} className="mb" readOnly />
    </div>
  );
};

SettingsDocs.propTypes = {
  settingsDocsTableData: PropTypes.array.isRequired,
  type: PropTypes.oneOf(Object.values(SETTINGS_DOCS_BLOCK_TYPES)).isRequired,
  onSaveDocBtnClick: PropTypes.func,
  onDeleteDocBtnClick: PropTypes.func,
  disabled: PropTypes.bool,
  onDocSelect: PropTypes.func,
  currentDocId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  onDocFileAdd: PropTypes.func.isRequired,
  onDocFileDelete: PropTypes.func.isRequired,
  isCurrentDocFile: PropTypes.bool.isRequired,
  onDocNoteAdd: PropTypes.func.isRequired,
  onDocNoteDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default SettingsDocs;
