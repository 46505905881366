import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {DateInput, Select} from "../../Inputs";

import "./accountsForm.scss";

const GeneralAccForm = () => {
  const {t} = useTranslation();

  return (
    <Formik
      initialValues={{}}
      validateOnChange
      // enableReinitialize
      validationSchema={Yup.object({
        contractDeadline: Yup.date().required([t("Enter date")]),
        contractNumber: Yup.string().required([t("Contract number")]),
      })}
    >
      {formProps => (
        <Form className="all-acc-form">
          <AccFields />
        </Form>
      )}
    </Formik>
  );
};

const AccFields = () => {
  const {t} = useTranslation();

  return (
    <div className="acc_forms_container">
      <div className="flex_container" style={{width: "100%", gap: "1em"}}>
        <Select label={t("Account №")} options={[]} name="acc" width="8em" />
        <Select label={t("Type*")} options={[]} name="acc" width="5em" />
        <Select label={t("Balance account nomenclature")} options={[]} name="acc" width="40em" />

        <div className="flex_container" style={{flexDirection: "column", alignItems: "flex-start"}}>
          <label>{t("Account transaction period")}</label>
          <div className="flex_container">
            <DateInput label={t("From")} name="dd" />
            <DateInput label={t("To")} name="dd" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAccForm;
