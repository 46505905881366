import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {TextAreaInput, TextInput} from "../../../../Inputs";
import ImgBox from "../../../../Inputs/fileSelect/ImgBox/ImgBox";
import {getColorImages, getDecorImages, getDrawings, getSkuImages} from "../../../../SKU/SkuSlice";

const MistakeDecoration = () => {
  const {t} = useTranslation();

  const skuImages = useSelector(getSkuImages),
    colorImages = useSelector(getColorImages),
    decorImages = useSelector(getDecorImages),
    drawings = useSelector(getDrawings);

  return (
    <div className="middle_sku __decoration-color" style={{height: "100%"}}>
      <div className="fc nowrap jcc half bigger" style={{gap: "3em", height: "100%"}}>
        <div className="fc" style={{alignItems: "flex-end"}}>
          <ImgBox label={t("General view")} classNames="sku_item_image" imgs={skuImages || []}></ImgBox>
        </div>

        <div className="fc" style={{alignItems: "flex-end"}}>
          <ImgBox label={t("Drawings")} classNames="sku_item_image" imgs={drawings || []}></ImgBox>
        </div>
      </div>
      <div className="fc nowrap half smaller">
        <div
          className=" project_form_wrapper"
          style={{
            height: "100%",
            marginLeft: "1em",
          }}
        >
          <div className="flex_container nowrap " style={{alignItems: "flex-start", gap: "5px"}}>
            <ImgBox label={t("Finishing") + ":"} classNames="smaller-image-box" imgs={decorImages || []} />
            <TextInput name="material" readOnly width="7em" />
          </div>
          <div className="flex_container nowrap " style={{alignItems: "flex-start", gap: "5px"}}>
            <ImgBox label={t("Color") + ":"} classNames="smaller-image-box" imgs={colorImages || []} />
            <TextInput name="color" readOnly width="7em" />
          </div>
        </div>
        <div
          className="autolabel fc nowrap"
          style={{
            marginLeft: "15px",
            width: "100%",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <TextAreaInput label={t("Finishing type")} name="materialType" width="95%" height="7em" readOnly />

          <TextAreaInput label={t("Color type")} id="colorType" name="colorType" width="95%" height="7em" readOnly />
        </div>
      </div>
    </div>
  );
};

export default MistakeDecoration;
