import React from "react";
import {useTranslation} from "react-i18next";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CropIcon from "@mui/icons-material/Crop";
import {IconButton} from "@mui/material";

import {apiUrl} from "../../../../../api/baseURL";
import ToolTip from "../../../../../utilities/ToolTip";
const ImagePreviewItem = ({url, delFunc, temporary = false, index, cropFunc = () => {}}) => {
  const {t} = useTranslation();

  return (
    <div className="previewBoxItem">
      <div>
        <img src={url ? `${apiUrl}/${url}` : ""} alt="" width="200px" height="200px" draggable="false" />
      </div>
      <div
        className="previewBoxItem__del"
        onClick={() => {
          delFunc(url);
        }}
      >
        <ToolTip title={t("Delete image")}>
          <span>&#215;</span>
        </ToolTip>
      </div>
      {temporary ? (
        <div className="previewBoxItem__del">
          <ToolTip title={t("Delete image")}>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                cropFunc(url);
              }}
              sx={{background: "aliceblue", position: "absolute", top: 0, right: "-4px"}}
            >
              <CropIcon />
            </IconButton>
          </ToolTip>
        </div>
      ) : null}
    </div>
  );
};

export const ImageNotSavePreviewItem = ({url, delFunc, cropFunc = () => {}}) => {
  const {t} = useTranslation();

  return (
    <div
      className="previewBoxItem"
      style={{boxShadow: " 0 0 10px 5px rgba(255, 0, 0, 0.5)", border: "1px solid transparent"}}
    >
      <div>
        <img src={url ? `${url}` : ""} alt="" width="200px" height="200px" draggable="false" style={{opacity: "0.5"}} />
      </div>

      <div style={{position: "absolute", top: "-4px", right: "-4px", cursor: "pointer"}}>
        <ToolTip title={t("Delete image")}>
          <CancelOutlinedIcon
            onClick={() => {
              delFunc(url);
            }}
            sx={{background: "aliceblue", borderRadius: "50%"}}
          />
        </ToolTip>
      </div>

      <div style={{position: "absolute", bottom: 0, right: "0"}}>
        <ToolTip title={t("Crop image")}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              cropFunc(url);
            }}
            sx={{backgroundColor: "white"}}
          >
            <CropIcon />
          </IconButton>
        </ToolTip>
      </div>
    </div>
  );
};

export default ImagePreviewItem;
