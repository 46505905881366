import i18n from "../i18n";

import {numberFormat} from "./moneyFormat";

const countOvh = (value, unit) => {
  if (value && unit) {
    const number = Number(value);
    return number > 0
      ? numberFormat(number * Number(unit.multiplier)) +
          " " +
          (unit?.name ? unit[i18n.language === "en" ? "engName" : "name"] : null)
      : "";
  } else return "";
};

export const ovhJoin = sku => {
  try {
    var ovhValues = [];
    if (sku?.overallSizeCheck) {
      const {length, width, height, overallSizeUnit} = sku;
      var dimensionMass = [length, width, height];
      const hasDimensionValue = dimensionMass?.some(item => item !== null && item !== "0.00");
      const name = i18n.language === "en" ? "engName" : "name";
      const multiplier = Number(overallSizeUnit?.multiplier);

      if (hasDimensionValue) {
        const mappedDiman = dimensionMass.map(item => {
          const number = numberFormat(Number(item) * multiplier);
          return number === null || number === "0.00" ? "0" : number;
        });
        ovhValues.push(`${mappedDiman[0]}x${mappedDiman[1]}x${mappedDiman[2]} ${overallSizeUnit[name]}`);
      }
    }

    const ovhKeys = ["area", "volume", "weight", "pallet"];

    ovhKeys.forEach(key => {
      if (sku[key + "Check"]) {
        const ovh = countOvh(sku[key], sku[key + "Unit"]);
        if (ovh !== "") ovhValues.push(countOvh(sku[key], sku[key + "Unit"]));
      }
    });
    return ovhValues.join(" ");
  } catch (e) {
    console.log("e", e);
    return "";
  }
};
