import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import {setCurrentSkuImages, updateSku} from "../../../SKU/SkuSlice";
import {
  fetchRequestSKUs,
  getCurrentRequestId,
  getCurrentRequestSKUs,
  getCurrentSkuRequestId,
} from "../../RequestsSlice";

const useSKU_MistakeCorrectionService = ({onModalClose}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentRequestId = useSelector(getCurrentRequestId);
  const currentSkusInRequest = useSelector(getCurrentRequestSKUs);
  const currentSkuRequestId = useSelector(getCurrentSkuRequestId);
  const currentSku = currentSkusInRequest.find(sku => sku.skuRequestId === currentSkuRequestId);

  dispatch(
    setCurrentSkuImages({
      images: currentSku.addedOverallView,
      drawings: currentSku.addedImages,
      decorImages: currentSku.addedDecorImages,
      colorImages: currentSku.addedColorImages,
    }),
  );

  const CHANGABLE_FIELDS = Object.freeze(["productGroupId", "productTypeId"]);
  const notSpecifiedValue = t("not specified");

  const initialValues = {
    //From MistakeFilters
    article: currentSku ? currentSku.article || notSpecifiedValue : "",
    brandId: currentSku ? currentSku.brand.brandId || notSpecifiedValue : "",
    productGroupId: currentSku ? currentSku.productGroup.productGroupId || notSpecifiedValue : "",
    productTypeId: currentSku ? currentSku.productType.productTypeId || notSpecifiedValue : "",
    materialId: currentSku ? currentSku.brand.brandId || notSpecifiedValue : "",
    colorId: currentSku ? currentSku.coId || notSpecifiedValue : "",
    productSeries: currentSku ? currentSku.productSeries || notSpecifiedValue : "",
    batch: currentSku ? currentSku.batch || notSpecifiedValue : "",
    //From Decoration
    material: currentSku ? currentSku.material || notSpecifiedValue : "",
    color: currentSku ? currentSku.color || notSpecifiedValue : "",
    materialType: currentSku ? currentSku.materialType || notSpecifiedValue : "",
    colorType: currentSku ? currentSku.colorType || notSpecifiedValue : "",
    //From ControlCheck
    creatorName: currentSku ? currentSku.creator.name || notSpecifiedValue : "",
    createdAt: currentSku ? currentSku.createdAt || notSpecifiedValue : "",
  };

  const calcButtonsDisabled = values => {
    const valueKeys = Object.keys(values);
    for (let i = 0; i < valueKeys.length; i++) {
      if (!CHANGABLE_FIELDS.includes(valueKeys[i])) continue;
      if (values[valueKeys[i]] !== initialValues[valueKeys[i]]) return false;
    }
    return true;
  };

  const formSubmitBtnTooltipTitle = isBtnDisabled => (isBtnDisabled ? t("Make changes first") : "");

  function onCancel() {
    onModalClose();
  }
  function onSubmit(values) {
    let changedValues = {};
    for (const key in values) {
      if (CHANGABLE_FIELDS.includes(key)) {
        changedValues[key] = values[key];
      }
    }

    dispatch(updateSku({sku: changedValues, skuId: currentSku.skuId, isHardUpdate: true})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(t("SKU updated successfully"));
        dispatch(fetchRequestSKUs(currentRequestId));
      }
    });
    onModalClose();
  }
  function onPost(values) {
    console.log("values :", values);
    onModalClose();
  }
  return {initialValues, onCancel, onSubmit, onPost, calcButtonsDisabled, formSubmitBtnTooltipTitle};
};

export default useSKU_MistakeCorrectionService;
