import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, useFormikContext} from "formik";

import {useDebouncedCallback} from "../../../../hook/debounced";
import FilterButton from "../../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import {dateFormat} from "../../../../utilities/dateFormat";
import useSessionFilters from "../../../../utilities/hooks/useSessionFilters";
import ChosenFiltersLine from "../../../Requests/Filters/ChosenFiltersLine";
import {setWarehouseComingNull} from "../../redux/LogistSlice";
import {fetchAllStockPoolsNew} from "../../redux/operations";

import useLogistFilters from "./useLogistFilters";

/* eslint-disable react/display-name */
const withLogistFilters = BaseComponent => {
  return props => {
    const {window, setSelectedFactory = () => {}, setSelectedWarehouse = () => {}, selectedWarehouse} = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {values, setValues} = useFormikContext();
    const {filters, setFilters, clearFilters, initialFilters} = useSessionFilters(window);

    const [areFiltersDefined, setAreFiltersDefined] = useState(!!filters);

    useEffect(() => {
      if (initialFilters) {
        setValues({...initialFilters});
        setAreFiltersDefined(true);
      }
    }, []);

    const [savedFilters, setSavedFilters] = useState({});
    useEffect(() => {
      const filters = JSON.parse(sessionStorage.getItem(window));
      if (filters && Object.keys(filters).length > 0) setSavedFilters(filters);
    }, [values]);

    const {
      brandsOptions,
      // feaManagersOptions,
      coManagersOptions,
      requestStatusOptions,
      requestNumberOptions,
      logistOptions,
      clientNumberOptions,
      countryOptions,
      carriersOptions,
      cargoNumberOptions,
      invoiceNumberOptions,
      locationsOptions,
      warehouseOptions,
      stockPoolNumberOptions,
    } = useLogistFilters();

    const filterNames = useMemo(
      () => ({
        statusId: {name: t("Request status"), options: requestStatusOptions},
        requestNumber: {name: t("Request №"), options: requestNumberOptions},
        managerCoId: {name: t("CO Manager"), options: coManagersOptions},
        brandId: {name: t("Factory"), options: brandsOptions},
        proformaNumber: {name: t("Prof №"), value: savedFilters?.proformaNumber},
        clientNumber: {name: t("Client №"), options: clientNumberOptions},
        logistId: {name: t("Logist"), options: logistOptions},
        countryId: {name: t("Pr country"), options: countryOptions},
        dateStart: {name: t("From"), value: dateFormat(savedFilters?.dateStart)},
        dateEnd: {name: t("To"), value: dateFormat(savedFilters?.dateEnd)},
        cargoNumber: {name: t("TP №"), options: cargoNumberOptions},
        locationTPId: {name: t("location tp"), options: locationsOptions},
        carrierId: {name: t("Transporter"), options: carriersOptions},
        invoiceNumber: {name: t("Invoice №"), options: invoiceNumberOptions},
        stockPoolNumber: {name: t("SP №"), options: stockPoolNumberOptions},
        orderNumber: {name: t("Order № "), value: savedFilters?.orderNumber},
        warehouseId: {name: t("Warehouse"), options: warehouseOptions},
      }),
      [
        requestStatusOptions?.length,
        requestNumberOptions?.length,
        savedFilters?.requestNumber,
        coManagersOptions?.length,
        brandsOptions?.length,
        savedFilters?.proformaNumber,
        clientNumberOptions?.length,
        logistOptions?.length,
        countryOptions?.length,
        savedFilters?.dateStart,
        savedFilters?.dateStart,
        cargoNumberOptions?.length,
        carriersOptions?.length,
        invoiceNumberOptions?.length,
        locationsOptions?.length,
        stockPoolNumberOptions?.length,
        savedFilters?.orderNumber,
        warehouseOptions?.length,
      ],
    );

    // const initialValues = {
    //   statusId: filters ? filters.statusId : "",
    //   requestNumber: filters ? filters.requestNumber : "",
    //   managerFeaId: filters ? filters.managerFeaId : "",
    //   brandId: filters ? filters.brandId : "",
    //   managerCoId: filters ? filters.managerCoId : "",
    //   dateStart: filters ? filters.dateStart : "",
    //   dateEnd: filters ? filters.dateEnd : "",
    //   countryId: filters ? filters.countryId : "",
    // };

    const onFilter = newFilters => {
      const {
        statusId,
        requestNumber,
        managerCoId,
        managerFeaId,
        brandId,
        countryId,
        clientNumber,
        logistId,
        dateStart,
        dateEnd,
        stockPoolNumber,
        cargoNumber,
        locationTPId,
        carrierId,
        invoiceNumber,
        warehouseId,
        orderNumber,
        proformaNumber,
      } = newFilters ?? values;

      const params = checkEmptyFields({
        requestNumber,
        stockPoolNumber,
        statusId,
        managerFeaId,
        managerCoId,
        brandId,
        countryId,
        clientNumber,
        logistId,
        dateStart: new Date(dateStart).toJSON() || "",
        dateEnd: new Date(dateEnd).toJSON() || "",
        cargoNumber,
        locationTPId,
        carrierId,
        invoiceNumber,
        warehouseId,
        orderNumber,
        proformaNumber,
      });

      if (Object.keys(params).length > 0) {
        setAreFiltersDefined(true);
        delayedFilter(params);
        const result = {
          statusId,
          requestNumber,
          managerCoId,
          managerFeaId,
          brandId,
          countryId,
          clientNumber,
          logistId,
          dateStart,
          dateEnd,
          stockPoolNumber,
          cargoNumber,
          locationTPId,
          carrierId,
          invoiceNumber,
          warehouseId,
          orderNumber,
          proformaNumber,
        };
        setFilters(result);
        setSavedFilters(params);
      }
      if (newFilters && Object.keys(params).length === 0) {
        setAreFiltersDefined(false);
        delayedFilter(params);
        clearFilters();
        setSavedFilters(params);
      }
      if (selectedWarehouse) {
        dispatch(fetchAllStockPoolsNew({window: "Warehouse coming", warehouseId: selectedWarehouse?.value}));
      }
    };

    const updateFilterQuery = params => {
      dispatch(fetchAllStockPoolsNew({...params, window}));
    };

    const delFilters = () => {
      updateFilterQuery({window});
      clearFilters();
      const nullValues = {
        statusId: "",
        requestNumber: "",
        managerFeaId: "",
        brandId: "",
        managerCoId: "",
        clientNumber: "",
        logistId: "",
        dateStart: "",
        dateEnd: "",
        countryId: "",
        stockPoolNumber: "",
        cargoNumber: "",
        locationTPId: "",
        carrierId: "",
        invoiceNumber: "",
        warehouseId: "",
        orderNumber: "",
        proformaNumber: "",
      };
      setValues(nullValues);
      setSavedFilters({});
      setAreFiltersDefined(false);
      if (setSelectedFactory) setSelectedFactory(null);
      if (setSelectedWarehouse) {
        dispatch(setWarehouseComingNull());
      }
    };

    const delayedFilter = useDebouncedCallback(updateFilterQuery, 500);

    return (
      <Form style={{width: "100%"}}>
        <div className="fc jcfs aist w100 g1 autolabel nowrap ">
          <div style={{paddingRight: "2.5em"}}>
            <FilterButton
              onFilter={onFilter}
              delFilter={delFilters}
              questionTitle={t("Filter")}
              areFiltersDefined={areFiltersDefined}
              disabled={props.disabled}
            />
          </div>
          <BaseComponent {...props} disabled={props.disabled} />
        </div>
        <ChosenFiltersLine
          window={window}
          savedFilters={savedFilters}
          setSavedFilters={setSavedFilters}
          onFilter={onFilter}
          filterNames={filterNames}
        />
      </Form>
    );
  };
};

export default withLogistFilters;
