import {dateFormat} from "../../../../utilities/dateFormat";

export const makeStockPoolsTableData = stockPools =>
  stockPools
    ? stockPools.map((stockPool, index) => ({
        number: ++index,
        id: stockPool.stockPoolId,
        stockPoolNumber: stockPool.stockPoolNumber,
      }))
    : [];

export const makeStockPoolRqstsTableData = skuRequests =>
  skuRequests?.map(({order, request, productGroup, skuRequestId} = {}, index) => ({
    number: ++index,
    id: request?.requestId ?? skuRequestId ?? index,
    requestNumber: request?.requestNumber ?? " - ",
    factory: request?.brand?.name ?? " - ",
    requestSum: request?.requestSum ?? " - ",
    readinessDate: dateFormat((request?.requestDates ?? [])[0]?.actualDate) ?? " - ",
    productGroup: productGroup?.name ?? " - ",
    bringTo: dateFormat(order?.co?.bringTo) ?? " - ",
    productCountry: order?.co?.project?.country?.shortName ?? " - ",
    profCountry: (request?.proforms ?? [])[0]?.country?.shortName ?? "-",
    managerCo: order?.co?.managerCo?.name ?? " - ",
  })) ?? [];
