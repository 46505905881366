import {createSlice} from "@reduxjs/toolkit";
import {t} from "i18next";

import {getInfo, getSuccess} from "../../../../utilities/toasts";

import {
  addTransaction,
  addWarehouse,
  addWarehouseService,
  addWarehouseServiceType,
  deleteWarehouseServiceType,
  fetchAllWarehouses,
  fetchAllWarehouseService,
  fetchAllWarehouseServiceTypes,
  fetchAllWarehouseTypes,
  fetchWarehouseInfo,
  fetchWarehouseService,
  fetchWarehousesFilters,
  updateWarehouse,
  updateWarehouseService,
} from "./operations";

export const WAREHOUSES_STORE_NAME = "logistWarehouses";

const initialState = {
  warehouses: null,
  warehousesFilters: null,
  currentWarehouse: null,
  warehouseTypes: null,
  currentDocFile: null,

  currentWarehouseService: null,
  warehouseServices: null,
  warehouseServiceTypes: null,
  transactions: null,

  warehousesLoading: false,
  error: false,
};

const WarehousesSlice = createSlice({
  name: WAREHOUSES_STORE_NAME,
  initialState,
  reducers: {
    clearCurrentWarehouse: state => {
      state.currentWarehouse = null;
    },
    clearTransactions: state => {
      state.transactions = null;
      state.currentWarehouseService = null;
    },
    setCurrentWarehouseDocFile: (state, {payload}) => {
      state.currentDocFile = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllWarehouses.fulfilled, (state, {payload}) => {
        state.warehouses = payload;
      })
      .addCase(addWarehouse.fulfilled, () => {
        // state.warehouses.push(payload);
      })
      .addCase(updateWarehouse.fulfilled, (state, {payload}) => {
        state.warehouses = state.warehouses.map(whouse => {
          if (whouse.warehouseId === payload.warehouseId) {
            return {...whouse, ...payload};
          }
          return whouse;
        });
        state.currentWarehouse = {...state.currentWarehouse, ...payload};
      })
      .addCase(fetchWarehousesFilters.fulfilled, (state, {payload}) => {
        state.warehousesFilters = payload;
      })
      .addCase(fetchWarehouseInfo.fulfilled, (state, {payload}) => {
        state.currentWarehouse = payload;
      })
      //** Warehouse Types **/
      .addCase(fetchAllWarehouseTypes.fulfilled, (state, {payload}) => {
        state.warehouseTypes = payload;
      })

      //** Warehouse services **/
      .addCase(fetchAllWarehouseService.fulfilled, (state, {payload}) => {
        state.warehouseServices = payload;
      })
      .addCase(fetchAllWarehouseServiceTypes.fulfilled, (state, {payload}) => {
        state.warehouseServiceTypes = payload;
      })
      .addCase(addWarehouseService.fulfilled, (state, {payload}) => {
        state.warehouseServices.push(payload);
        getSuccess(t("warehouse service added"));
      })
      .addCase(addWarehouseServiceType.fulfilled, (state, {payload}) => {
        state.warehouseServiceTypes.push(payload);
        getSuccess(t("warehouse service type added"));
      })
      .addCase(fetchWarehouseService.fulfilled, (state, {payload}) => {
        const {transactions, ...service} = payload;
        state.transactions = transactions || null;
        state.currentWarehouseService = service;
      })
      .addCase(updateWarehouseService.fulfilled, (state, {payload}) => {
        state.currentWarehouseService = payload;
        getSuccess(t("updated"));
      })
      .addCase(deleteWarehouseServiceType.fulfilled, (state, {meta}) => {
        state.warehouseServiceTypes = state.warehouseServiceTypes.filter(type => type.typeId !== meta.arg);
        getSuccess(t("warehouse service type deleted"));
      })

      //** Warehouse services Transactions **/
      .addCase(addTransaction.fulfilled, (state, {payload}) => {
        state.transactions.push(payload);
        getSuccess(t("transaction added"));
      })
      // .addCase(deleteTransactionPackage.fulfilled, (state, {payload}) => {
      //   state.warehouseServiceTypes.push(payload) ;
      // })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(WAREHOUSES_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(WAREHOUSES_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(WAREHOUSES_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.warehousesLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.warehousesLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.warehousesLoading = false;
  state.error = error;
}

const {actions, reducer} = WarehousesSlice;
export const {clearCurrentWarehouse, setCurrentWarehouseDocFile, clearTransactions} = actions;

export default reducer;
