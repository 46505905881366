import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import * as Yup from "yup";

import UpDownButtons from "../../../../../../utilities/Buttons/UpDownButtons";
import {DateInput, TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

import useOrderingFormLog from "./useOrderingFormLog";

const OrderingFormLog = ({selectedForCancel, selectedSP, setSelectedSP, setSelectedForCancel, selectedFilters}) => {
  const {t} = useTranslation();

  const {initialValues, onAppoint, onCancel, isUpBtnDisabled, isDownBtnDisabled, managerLogOptions} =
    useOrderingFormLog({
      selectedForCancel,
      selectedSP,
      setSelectedSP,
      setSelectedForCancel,
      selectedFilters,
    });

  return (
    <div style={{margin: "1em 0 "}}>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={Yup.object().shape({
          managerLog: Yup.string().nullable().required(t("mng log er")),
          date: Yup.date().nullable().required(t("Enter date")),
        })}
        onSubmit={() => {}}
      >
        {({setValues, values}) => (
          <Form className="fc jcsb nowrap">
            <div className="fc jcfs autolabel g15">
              <Select
                name="managerLog"
                label={t("mng log")}
                // question="logM assign"
                options={managerLogOptions}
                width="15em"
                labelwidth="9em"
                disabled={isDownBtnDisabled}
              />
              <DateInput
                name="date"
                // question="dd to fact"
                label={t("proc date")}
                labelW="7em"
                flatpikrConfig={{
                  minDate: moment().add(1, "days").format("DD-MM-YYYY"),
                }}
                disabled={isDownBtnDisabled}
              />
              <TextInput
                name="note"
                placeholder={t("Add a Note to the Application ")}
                width="25em"
                disabled={isDownBtnDisabled}
              />
            </div>

            <UpDownButtons
              onUpClick={onCancel}
              onDownClick={() => {
                onAppoint({values, setValues});
              }}
              isUpBtnDisabled={isUpBtnDisabled}
              isDownBtnDisabled={isDownBtnDisabled}
              tooltipMessage={"distrib to logists"}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

OrderingFormLog.propTypes = {
  selectedForCancel: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  selectedSP: PropTypes.array,
  setSelectedSP: PropTypes.func.isRequired,
  setSelectedForCancel: PropTypes.func.isRequired,
};

export default OrderingFormLog;
