import {useState, useTransition} from "react";
import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../../hook/debounced";
import QuestionItem from "../../../utilities/QuestionItem";
import useInputsTooltip from "../hook/InputsTooltipService";

const InputSelect = ({
  label,
  width = "67%",
  className = "",
  generalClassName = "",
  inputClassName = "",
  question = null,
  func = null,
  autolabel,
  labelStyles,
  handleInputChange = () => {},
  disabled = false,
  placeholder = "",
  isSelectOn = false,
  handleSelect = () => {},
  onInputSelectItemClick = () => {},
  filterFunction = options => options,
  ...props
}) => {
  const [field, meta] = useField(props);
  const {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown} = useInputsTooltip(field);

  const [options, setOptions] = useState([]);
  const setFilteredOptions = options => setOptions(filterFunction(options));

  const [isPending, startTransition] = useTransition();

  const {handleChange} = useFormikContext();

  const funcDebounce = useDebouncedCallback(value => {
    if (func !== null) func(value);
  }, 1000);

  const onChange = e => {
    handleChange(e);
    handleInputChange(e);
    startTransition(() => handleSelect(e, setFilteredOptions));

    if (e.target.value.length > 0) {
      setTooltipShown(false);
    }
    if (func !== null) {
      funcDebounce(e.target.value);
    }
  };

  const onBlur = () => {
    if (!isSelectOn) return;
    setTimeout(() => setOptions([]), 100);
  };

  return (
    <div className={`project_input ${generalClassName}`} style={props.style}>
      {label && (
        <label htmlFor={props.name} style={autolabel ? {width: "auto"} : labelStyles}>
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className} onBlur={onBlur}>
          <input
            {...props}
            {...field}
            className={inputClassName}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            value={field.value || ""}
            onChange={onChange}
            onMouseEnter={question ? onMouseEnter : null}
            onMouseLeave={question ? onMouseLeave : null}
          />
          {isSelectOn && options.length > 0 ? (
            isPending ? (
              <ul className="inputSelect">Loading...</ul>
            ) : (
              <ul className="inputSelect">
                {options.map((option, i) => (
                  <li key={i} onClick={event => onInputSelectItemClick(event, setFilteredOptions)}>
                    {option}
                  </li>
                ))}
              </ul>
            )
          ) : null}
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
    </div>
  );
};

InputSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  className: PropTypes.string,
  question: PropTypes.string,
  func: PropTypes.func,
  autolabel: PropTypes.bool,
  labelStyles: PropTypes.object,
  handleInputChange: PropTypes.func,
  generalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isSelectOn: PropTypes.bool,
  handleSelect: PropTypes.func,
  onInputSelectItemClick: PropTypes.func,
  filterFunction: PropTypes.func,
  props: PropTypes.array,
};

export default InputSelect;
