import {useMemo} from "react";
import {useSelector} from "react-redux";

import useKpFinDataService from "../../../../KP/kp-f/kpFinTable/KpFinDataService";
import useKpFinTableService from "../../../../KP/kp-f/kpFinTable/KpFinTableService";
import useKpFinCollumnsService from "../../../../KP/kp-f/kpFinTable/utils/kpFinColumns";
import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";
import {selectCurrentProform} from "../../../../Requests/Proform/redux/selectors/ProformSelectors";
import {getRequestInfo} from "../../../../Requests/RequestsSlice";

const RAndVWithPdf = ({setCheckedSku}) => {
  const currentPf = useSelector(selectCurrentProform);
  const currReq = useSelector(getRequestInfo);

  const {getRequestVarificationSkuTableData} = useKpFinDataService();
  const {collumns} = useKpFinCollumnsService();

  const skuListForT = useMemo(() => currReq?.skuByRequest || [], [currReq]);

  const alreadyInspected = useMemo(() => {
    if (skuListForT.length > 0) {
      const skus = skuListForT.filter(sku => sku.isInspected);
      return skus.map(sku => sku.skuRequestId);
    } else return [];
  }, [skuListForT]);

  const kpFinTableData = useMemo(() => {
    return skuListForT?.map(getRequestVarificationSkuTableData) || [];
  }, [skuListForT]);

  const onReorder = () => {};
  const selectSkuPlan = () => {};
  const selectSkuRow = ids => {
    setCheckedSku(ids);
  };

  const {KpFinTableRender} = useKpFinTableService(
    collumns,
    kpFinTableData,
    onReorder,
    selectSkuPlan,
    false,
    false,
    true,
    selectSkuRow,
    alreadyInspected,
  );

  const KpFinTable = KpFinTableRender();

  return (
    <div className="rv_block" style={{height: "100%", overflow: "hidden"}}>
      <div style={{width: "70%"}}>{KpFinTable}</div>
      <div className="rv_right">
        <div className="kpPreview__box" style={{height: "100%", marginBottom: "0"}}>
          {currentPf && <NewPdfViewer fileArray={[currentPf.pdfFileUrl]} hiding={false}/>}
        </div>
      </div>
    </div>
  );
};

export default RAndVWithPdf;
