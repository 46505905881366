import PropTypes from "prop-types";

import MoneyInput from "./MoneyInput";

import "./utils/Inputs.scss";

const PriceInput = ({
  label,
  procent,
  width = "8em",
  labelStyles,
  // className = "", question = null,
  ...props
}) => {
  const roundedValue = procent ? parseFloat(procent).toFixed(2) : "";
  // const [field, meta] = useField(props)
  return (
    <div className="price_input">
      <MoneyInput width={width} label={label} {...props} labelStyles={labelStyles} />
      <div style={{display: "flex", alignItems: "self-end"}}>
        <input
          // value={procent || ""}
          // decimalsLimit={2}
          value={roundedValue}
          autoComplete="off"
          readOnly
          style={{width: "5em", paddingRight: "0.5em"}}
        />
        <span>%</span>
      </div>
    </div>
  );
};
PriceInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  props: PropTypes.array,
  width: PropTypes.string,
  procent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PriceInput;
