import React from "react";
import {Route, Routes} from "react-router-dom";

import TemporarilyUnavailable from "../Components/errorBoundary/TemporarilyUnavailable";

import FinAccounting from "./Finance/FinAccounting";
import FinBlock from "./Finance/FinBlock";

const FinancePage = () => {
  return (
    <Routes>
      <Route path="/fin-block/*" element={<FinBlock />} />
      {/* <Route path="/fin-accounting/*" element={<FinAccounting />} /> */}
      <Route path="/fin-accounting/*" element={<TemporarilyUnavailable />} />
    </Routes>
  );
};

export default FinancePage;
