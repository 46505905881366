import {createSelector} from "@reduxjs/toolkit";

export const designersCatalogSelector = createSelector(
  state => state.catalogs.designers,
  items => {
    return items.map(item => {
      return {
        value: item.name,
        designerId: item.designerId,
        designerBonus: item.bonus,
      };
    });
  },
);

export const managerWithOfficeSelector = createSelector(
  state => state.catalogs.orgStructures,
  state => state.person.users,
  (orgStructures, managers) => {
    return managers.map(item => {
      return {
        value: item.name,
        managerKpId: item.userId,
        orgStructureId: item.orgStructureId,
        // orgName: orgStructures.find(({orgStructureId}) => orgStructureId === item.orgStructureId).name || "",
      };
    });
  },
);

export const getOrgStructuresCatalog = state => {
  return state.catalogs.orgStructures.map(item => {
    return {
      value: item.fullName,
      orgStructureId: item.orgStructureId,
    };
  });
};
export const getOrgStructures = state => state.catalogs.orgStructures;
export const getmanagersCatalog = state => state.person.users;

export const getMeasurementQuantites = state => state.sku.measurementQuantites;
