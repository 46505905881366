export const ORDER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Event date",
    accessorKey: "eventDate",
    size: 70,
    isSortable: true,
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
    size: 100,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    size: 100,
  },
  {
    header: "SP №",
    accessorKey: "spNumber",
    size: 100,
    isSortable: true,
  },

  {
    header: "TP №",
    accessorKey: "tpNumber",
    size: 100,
    isSortable: true,
  },

  {
    header: "Event",
    accessorKey: "eventType",
    size: 70,
  },
  {
    header: "Event place",
    accessorKey: "actionPlace",
    size: 70,
  },
  {
    header: "Planned Event",
    accessorKey: "plannedEvent",
    size: 70,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 100,
  },
  // {
  //   header: "Transformed TP №",
  //   accessorKey: "transformedTpNumber",
  //   size: 100,
  //   isSortable: true,
  // },
]);
