import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

const LogSettingsSectionBlock = ({headingNumber, heading, text, buttonName, onClick = () => {}, disabled = false}) => {
  const {t} = useTranslation();

  const defaultButtonName = t("Open for changes");

  return (
    <div>
      <h3 className="underlined mb0_5">{`${headingNumber}. ${t(heading)}`}</h3>
      <div className="row">
        <p>{t(text)}</p>
        <FunctionButton
          className="h100 padding0_5 br"
          name={buttonName ?? defaultButtonName}
          onClick={onClick}
          width="7rem"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

LogSettingsSectionBlock.propTypes = {
  headingNumber: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default LogSettingsSectionBlock;
