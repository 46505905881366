import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useRequestFilters from "../filters.hook";
import withRequestFilters from "../FiltersHOC";

const BaseRequestFilters = () => {
  const {t} = useTranslation();
  const {brandsOptions, coManagersOptions} = useRequestFilters();

  return (
    <>
      <TextInput label={t("Requests") + ":"} name="requestNumber" />
      <Select label={t("CO Manager") + ":"} options={coManagersOptions} name="managerCoId" />
      <Select label={t("Factory") + ":"} options={brandsOptions} name="brandId" />

      <div className="period-wrapper">
        <div className="fc gap5">{t("Period")}</div>
        <DateInput label={t("From")} name="dateStart" />
        <DateInput label={t("To")} name="dateEnd" />
      </div>
    </>
  );
};

const ForVarificationFilter = withRequestFilters(BaseRequestFilters);

const ForVarificationFilterForm = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(ForVarificationFilter);

export default ForVarificationFilterForm;
