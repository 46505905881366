import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat";
import ReactTable from "../../../conteiners/ReactTable";
import {TextInput} from "../../../Inputs";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {deselectSkuRequest} from "../../redux/LogistSlice";
import {acceptCargo, fetchAllStockPoolsNew, fetchStockPoolBySkuId} from "../../redux/operations";
import {selectCurrentSkuRequest, selectWarehouseComingRequests} from "../../redux/selectors";
import {WAREHOUSE_UPPER_TP_AND_SP_TABLE_COLUMNS} from "../utils/constants";

const WarehouseUpperPart = ({selectedWarehouse}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const comingSkuGroups = useSelector(selectWarehouseComingRequests);
  const currentSkuRequest = useSelector(selectCurrentSkuRequest);

  const warehouseUpperTableColumns = useColumns(WAREHOUSE_UPPER_TP_AND_SP_TABLE_COLUMNS);

  const warehouseUpperTableData = useMemo(() => {
    if (selectedWarehouse && comingSkuGroups?.length > 0) {
      return comingSkuGroups?.map(({order, request, skuRequestId, stockPool}, index) => {
        const departureDate = dateFormat(
          stockPool?.cargo?.dates?.find(item => item.dateType.dateTypeId === 1).actualDate,
        );
        return {
          id: skuRequestId,
          number: index + 1,
          transportPoolNumber: stockPool?.cargo?.cargoNumber,
          stockPoolNumber: stockPool.stockPoolNumber || "",
          orderNumber: order.orderNumber,
          destination: stockPool?.cargo?.endPoint?.name || "",
          departure: stockPool?.cargo?.startPoint
            ? stockPool?.cargo?.startPointId
            : stockPool?.cargo?.factory?.name || "",
          departureDate: departureDate || "",
          notes: request?.note,
        };
      });
    } else {
      return [];
    }
  }, [comingSkuGroups, selectedWarehouse]);

  const onUpperWarehouseTableSelect = id => {
    if (!id || currentSkuRequest?.skuRequestId === id) {
      dispatch(deselectSkuRequest());
      return;
    }
    dispatch(fetchStockPoolBySkuId(id));
  };

  const onAcceptTpToWarehouseClick = () => {
    dispatch(acceptCargo({cargoId: currentSkuRequest?.stockPool?.cargoId, warehouseId: selectedWarehouse?.value})).then(
      res => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllStockPoolsNew({window: "Warehouse coming", warehouseId: selectedWarehouse?.value}));
          dispatch(fetchAllStockPoolsNew({window: "Warehouse"}));
          dispatch(deselectSkuRequest());
        }
      },
    );
  };

  return (
    <>
      <div className="fdr mb" style={{gap: "20em"}}>
        <TextInput label={t("TP №")} name="tpNumber" autolabel width="15em" />
        <FunctionButton
          name="Accept TP to warehouse"
          autoWidth
          disabled={!currentSkuRequest || !selectedWarehouse || currentSkuRequest?.stockPool?.cargo?.statusId !== 3}
          onClick={onAcceptTpToWarehouseClick}
        />
      </div>

      <ReactTable
        defaultData={warehouseUpperTableData}
        columns={warehouseUpperTableColumns}
        onSelect={onUpperWarehouseTableSelect}
        current={currentSkuRequest?.skuRequestId}
        className={"project_table"}
        style={{height: "6em"}}
      />
    </>
  );
};

export default WarehouseUpperPart;
