import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

import CloseBtn from "../../utilities/Buttons/CloseBtn";

const SmallWindowForm = ({label, navigatePath, Component}) => {
  const navigate = useNavigate();

  return (
    <div className="login-form__container">
      <div className="login_form">
        <label className="label login_form_label">{label}</label>
        {navigatePath && (
          <CloseBtn
            onClick={() => {
              navigate(navigatePath);
            }}
          />
        )}

        <Component />
      </div>
    </div>
  );
};

SmallWindowForm.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  navigatePath: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
};

export default SmallWindowForm;
