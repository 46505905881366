import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import {checkEmptyFilterFields} from "../../../../../../utilities/checkEmptyFilterFields";
import useTermsStatusOptionsService from "../../../factoryModalParts/utils/hooks/getTermsStatusOptionsService";
import {CLEAN_FILTERS, FILTER_STORAGE_NAME} from "../constants";

import useCountryIdOptionsService from "./getCountryIdOptionsService";
import useFactoryIdOptionsService from "./getFactoryIdOptionsService";

const useFactoryFiltersService = ({filters, setFilters}) => {
  const {t} = useTranslation();
  const {values, setValues} = useFormikContext();

  const storedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));

  // let initialValues = useMemo(
  //   () => ({
  //     factoryId: storedFilters ? storedFilters.factoryId : filters.factoryId,
  //     countryId: storedFilters ? storedFilters.countryId : filters.countryId,
  //     contactName: storedFilters ? storedFilters.contactName : filters.contactName,
  //     phone: storedFilters ? storedFilters.phone : filters.phone,
  //     email: storedFilters ? storedFilters.email : filters.email,
  //     condition: storedFilters ? storedFilters.condition : filters.condition,
  //     note: storedFilters ? storedFilters.note : filters.note,
  //     conditionsStatus: storedFilters ? storedFilters.conditionsStatus : filters.conditionsStatus,
  //   }),
  //   [filters, storedFilters],
  // );

  useEffect(() => {
    setValues({
      factoryId: storedFilters ? storedFilters.factoryId : filters.factoryId,
      countryId: storedFilters ? storedFilters.countryId : filters.countryId,
      contactName: storedFilters ? storedFilters.contactName : filters.contactName,
      phone: storedFilters ? storedFilters.phone : filters.phone,
      email: storedFilters ? storedFilters.email : filters.email,
      condition: storedFilters ? storedFilters.condition : filters.condition,
      note: storedFilters ? storedFilters.note : filters.note,
      conditionsStatus: storedFilters ? storedFilters.conditionsStatus : filters.conditionsStatus,
    });
  }, [filters?.length, storedFilters?.length]);

  const termsOptions = useTermsStatusOptionsService();
  const countryIdOptions = useCountryIdOptionsService();
  const factoryIdOptions = useFactoryIdOptionsService();
  const areFiltersDefined = !!JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME)) ?? false;

  function onFilter(newFilters) {
    const params = checkEmptyFilterFields(newFilters ?? values);
    setFilters(params);
    sessionStorage.setItem(FILTER_STORAGE_NAME, JSON.stringify(params));
    setSavedFilters(params);

    if (newFilters && Object.keys(params).length === 0) {
      sessionStorage.removeItem(FILTER_STORAGE_NAME);
      setSavedFilters(params);
    }
  }

  function delFilters() {
    setFilters(CLEAN_FILTERS);
    clearValues(values);
    setSavedFilters({});
    sessionStorage.setItem(FILTER_STORAGE_NAME, null);
  }

  function clearValues() {
    Object.keys(values).forEach(key => (values[key] = ""));
  }

  const [savedFilters, setSavedFilters] = useState({});
  useEffect(() => {
    const filters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));
    if (filters && Object.keys(filters).length > 0) setSavedFilters(filters);
  }, [values]);

  const filterNames = useMemo(
    () => ({
      factoryId: {name: t("Factory"), options: factoryIdOptions},
      countryId: {name: t("Country"), options: countryIdOptions},
      contactName: {name: t("cont pers CP"), value: savedFilters?.contactName},
      phone: {name: t("P#"), value: savedFilters?.phone},
      conditionsStatus: {name: t("Conditions status"), options: termsOptions},
      condition: {name: t("cond"), value: savedFilters?.condition},
      note: {name: t("Notes"), value: savedFilters?.note},
      email: {name: t("e-mail"), value: savedFilters?.email},
    }),
    [
      factoryIdOptions?.length,
      countryIdOptions?.length,
      savedFilters?.contactName,
      savedFilters?.phone,
      termsOptions?.length,
      savedFilters?.condition,
      savedFilters?.note,
      savedFilters?.email,
    ],
  );

  return {
    onFilter,
    delFilters,
    termsOptions,
    countryIdOptions,
    factoryIdOptions,
    areFiltersDefined,
    savedFilters,
    setSavedFilters,
    filterNames,
  };
};

export default useFactoryFiltersService;
