import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import saveIcon from "../../../../images/icons/save.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../../utilities/ToolTip";
import {TextAreaInput, TextInput} from "../../../Inputs";
import CheckBoxWithLabel from "../../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel";
import InputSelect from "../../../Inputs/InputSelect/InputSelect";
import PhoneInput from "../../../Inputs/PhoneInput";
import {Select} from "../../../Inputs/SelectField";
import {selectNewContact} from "../../redux/selectors/FactorySelectors";

import useNewFactoryContactsService from "./utils/hooks/getNewFactoryContactsService";

const NewFactoryContacts = ({disabled = false, onAddContactBtnClick}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const newContact = useSelector(selectNewContact);

  const {
    contactTypeOptions,
    onEmailContactInputChange,
    onInputSelectItemClick,
    onSaveContactNoteBtnClick,
    contactNotePlaceholer,
    isContactNoteFieldDisabled,
  } = useNewFactoryContactsService();

  const disabledTooltipTitle = disabled
    ? newContact
      ? t("New Contact has already been created!")
      : t("Complete Factory Address section first!")
    : "";

  return (
    <ToolTip title={disabledTooltipTitle}>
      <>
        <div style={{marginBottom: "0.5em"}}>2. {t("reprs contacts")}</div>
        <div className="edit_block">
          <div className="f_b_wrapper edit_item">
            <InputSelect
              label="E-MAIL"
              name="email"
              type="text"
              width="100%"
              generalClassName="w100"
              disabled={disabled}
              handleSelect={onEmailContactInputChange}
              onInputSelectItemClick={onInputSelectItemClick}
              isSelectOn
              labelStyles={{width: "20em"}}
            />
          </div>

          <div className="f_tabl_wrapper edit_item edit_item_row"></div>

          <TextInput
            label={t("cont pers CP")}
            name="contactName"
            type="text"
            generalClassName="w100 edit_item"
            disabled={disabled}
            labelStyles={{width: "20em"}}
          />

          <div className="edit_item edit_item_row edit_item_m">
            <Select
              label={t("Type")}
              name="contactTypeId"
              options={contactTypeOptions}
              multi={true}
              width="9.5em"
              disabled={disabled}
            />
          </div>
          <div className="f_b_wrapper edit_item">
            <PhoneInput
              label={t("tel")}
              name="phone"
              inputProps={{name: "phone"}}
              type="text"
              width="100%"
              generalClassName="w100"
              disabled={disabled}
              labelStyles={{width: "19em"}}
            />
          </div>

          <div className="f_tabl_wrapper edit_item edit_item_row">
            <CheckBoxWithLabel label="mark as" checked disabled={disabled} />
          </div>
        </div>
        <div className="factory_edit_table"></div>
        <div className="f_b_wrapper">
          <TextInput
            label={t("notes to cp")}
            name="newContactNotes"
            type="text"
            labelStyles={{width: "12em"}}
            width="100%"
            generalClassName="w100"
            disabled={(disabled && !newContact) || isContactNoteFieldDisabled}
            placeholder={contactNotePlaceholer}
          />
          <ImageButton
            src={saveIcon}
            alt="save"
            width={1.5}
            height={1}
            disabled={(disabled && !newContact) || isContactNoteFieldDisabled}
            onClick={() => onSaveContactNoteBtnClick(values)}
          />
        </div>
        <TextAreaInput name="contactNotes" width={"101%"} readOnly />
        <div className="fcc jcfe" style={{marginTop: "0.5em"}}>
          <FunctionButton name="save cp sm" autoWidth onClick={onAddContactBtnClick} disabled={disabled} />
        </div>
      </>
    </ToolTip>
  );
};

NewFactoryContacts.propTypes = {onAddContactBtnClick: PropTypes.func.isRequired, disabled: PropTypes.bool};

export default NewFactoryContacts;
