import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";

export const CONTROL_SETTINGS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 5,
  },
  {
    header: "Factory",
    accessorKey: "name",
    size: 60,
  },
  {
    header: "Factory conditions",
    accessorKey: "factoryConditions",
    size: 70,
  },
  {
    header: "controlTerms",
    accessorKey: "controlTerms",
    size: 150,
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
    size: 15,
  },
]);
