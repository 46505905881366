import React, {useEffect, useRef, useState} from "react";
import {Cropper} from "react-cropper";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import {IconButton} from "@mui/material";
import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

import "cropperjs/dist/cropper.css";
const ReactCropper = ({initialImage, cropBoxResizable = false, cropBoxSize, setCroppedImage, setOpenCrop}) => {
  const cropperRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageName, setCurrentImageName] = useState(null);

  const cropImage = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas({
        fillColor: "white",
      });
      if (croppedCanvas) {
        croppedCanvas.toBlob(blob => {
          if (blob) {
            const croppedFile = new File([blob], currentImageName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setCroppedImage(croppedFile);
          }
        }, "image/jpeg");
      }
    }
  };

  useEffect(() => {
    if (initialImage) {
      setCurrentImageName(initialImage.name);
      const reader = new FileReader();
      reader.readAsDataURL(initialImage);
      reader.onload = () => {
        setCurrentImage(reader.result);
      };
    }
  }, [initialImage]);

  return (
    <div style={{width: "80vw", height: "90vh", position: "relative"}}>
      <div style={{height: "calc(100% - 4em)", width: "100%"}}>
        <Cropper
          src={currentImage}
          style={{height: "100% ", width: "100%", background: "white"}}
          initialAspectRatio={cropBoxResizable ? NaN : 1}
          guides={false}
          ref={cropperRef}
          viewMode={cropBoxResizable ? 1 : 0}
          minCropBoxHeight={cropBoxSize?.height || 300}
          minCropBoxWidth={cropBoxSize?.width || 300}
          background={true}
          modal={true}
          responsive={true}
          dragMode={"move"}
          movable={true}
          rotatable={true}
          checkOrientation={false}
          cropBoxResizable={cropBoxResizable}
          cropBoxMovable={cropBoxResizable}
          toggleDragModeOnDblclick={false}
          zoomOnTouch={false}
          autoCropArea={1}
          // autoCropArea={1}
          // zoomTo={0.5}
        />
      </div>
      <div>
        <IconButton
          aria-label="delete"
          disabled={!(cropperRef?.current && currentImage)}
          color="primary"
          onClick={() => {
            cropperRef?.current?.cropper.rotate(-90);
          }}
        >
          <RotateLeftIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          disabled={!(cropperRef?.current && currentImage)}
          onClick={() => {
            cropperRef?.current?.cropper.rotate(90);
          }}
        >
          <RotateRightIcon />
        </IconButton>

        <div className="cropper_btns">
          <FunctionButton name={t("Cancel")} onClick={() => setCroppedImage(null)} />
          <FunctionButton name={t("Save")} onClick={cropImage} />
        </div>
      </div>
    </div>
  );
};

export default ReactCropper;
