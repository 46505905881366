import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchReferences} from "../../redux/operations/FactoryOperations";
import {
  selectCurrentConditionId,
  selectCurrentFactoryConditions,
  selectCurrentFactoryId,
  selectTimeUnits,
} from "../../redux/selectors/FactorySelectors";
import {setCurrentConditionId} from "../../redux/slices/FactorySlice";

import {CONTROL_SETTINGS_TABLE_COLUMNS} from "./constants";
import formControlTerms from "./formControlTerms";

const useControlTermsService = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();

  const currentConditionId = useSelector(selectCurrentConditionId);
  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const currentFactoryConditions = useSelector(selectCurrentFactoryConditions);
  const timeUnits = useSelector(selectTimeUnits);

  const [isTermsSettingsModalActive, setTermsSettingsModalActive] = useState(false);

  useEffect(() => {
    if (!timeUnits) {
      dispatch(fetchReferences());
    }
  }, [timeUnits]);

  const isEditListBtnDisabled = currentFactoryId === null;
  const noData = t("not specified");

  const termsSettingsTableColumns = useColumns(CONTROL_SETTINGS_TABLE_COLUMNS);
  const termsSettingsTableData = currentFactoryConditions
    ? currentFactoryConditions.map(
        ({conditionId, factory: {name}, termsCondition, requestEmailTemplate, controlTerms}, index) => ({
          id: conditionId,
          number: ++index,
          name: name ?? noData,
          factoryConditions: termsCondition ?? noData,
          controlTerms: formControlTerms({controlTerms, timeUnits, language}),
          status:
            requestEmailTemplate === null
              ? STATUS_COLORS.GREY
              : requestEmailTemplate
              ? STATUS_COLORS.GREEN
              : STATUS_COLORS.RED,
        }),
      )
    : [];

  function onTermsSettingsTableRowSelect(conditionId) {
    if (currentConditionId === conditionId) {
      dispatch(setCurrentConditionId(null));

      return;
    }
    dispatch(setCurrentConditionId(conditionId));
  }
  function onTermsSettingsModalClose() {}
  function onEditListBtnClick() {
    setTermsSettingsModalActive(true);
  }

  return {
    onEditListBtnClick,
    isEditListBtnDisabled,
    termsSettingsTableColumns,
    termsSettingsTableData,
    onTermsSettingsTableRowSelect,
    isTermsSettingsModalActive,
    setTermsSettingsModalActive,
    onTermsSettingsModalClose,
    currentConditionId,
  };
};

export default useControlTermsService;
