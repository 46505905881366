import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import FactoryRqstsModal from "../../OrderProcessing/FabricRequestsModal/FactoryRqstsModal";
import RequestCreation from "../../RequestCreation/RequestCreation";
import RequestPlanSkuTable from "../../RequestCreation/RequestPlanSkuTable";
import {fetchRequestSKUs, getRequestInfo, sendToFactory} from "../../RequestsSlice";

const FactoryEditRqsts = ({setModalActive}) => {
  const currentRequest = useSelector(getRequestInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentRequest?.requestId) {
      dispatch(fetchRequestSKUs(currentRequest?.requestId));
    }
  }, []);
  return (
    <FactoryRqstsModal>
      {/* <div className="req_table_width"> */}
      <Child setModalActive={setModalActive} />
      {/* </div> */}
    </FactoryRqstsModal>
  );
};

const Child = ({setModalActive}) => {
  const {submitForm} = useFormikContext();

  const onSubmit = () => {
    submitForm();
    // setTimeout(
    // setModalActive()
  };

  // return <RequestPlanSkuTable onSubmit={onSubmit} />;
  return <RequestCreation onSubmit={onSubmit} />;
};

export default FactoryEditRqsts;
