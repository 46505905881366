import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {getInfo, getSuccess} from "../../../../../utilities/toasts";
import {TextAreaInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";
import SingleFilePicker from "../../../../Inputs/SingleFilePicker";
import {getRequestInfo} from "../../../RequestsSlice";

import {addRequestPaymentDocs, createPayment, removePayment} from "./redux/PaymentOperationsSlice";
import {getCurrentDocument, getCurrentPayment, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import useSchemasData from "./utils/hook/schemasData";
import DocumentsForPayment from "./DocumentsForPayment";
import FinScheme from "./FinScheme";
import PaymentDates from "./PaymentDates";

const ForPaymentPart = ({initForPaymentDates}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const currentPayment = useSelector(getCurrentPayment);

  const [isAttached, setIsAttached] = useState(false);

  const {docTypes} = usePaymentsData();
  const payment = useSelector(getPayment);
  const currentRequest = useSelector(getRequestInfo);
  const currentDoc = useSelector(getCurrentDocument);

  const {values, setValues, setFieldValue} = useFormikContext(),
    {doc, documentType, docNote} = values;

  const disabledFilePicker = doc === "" || documentType === "";

  const onFileAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.doc);
    formdata.append("docTypeId", values.documentType);
    formdata.append("note", values.docNote);

    if (formdata.has("doc")) {
      dispatch(addRequestPaymentDocs({requestId: currentRequest.requestId, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("Payment document has been created"));
          setValues({
            doc: "",
            documentType: "",
            docNote: "",
          });
        }
      });
    }
  };

  // const attachDoc = () => {
  //   dispatch(attachFromRequest({paymentId: payment.paymentId, data: {docId: currentDoc.doc.docId}})).then(resp => {
  //     if (resp.meta.requestStatus === "fulfilled") {
  //       getSuccess(t("The document has been added for payment"));
  //       setIsAttached(true);
  //     }
  //   });
  // };

  const {paymentDocs, curentPaymentDoc, columnsForPayment, setCurrentPaymet} = usePaymentsData();
  const {paymentSchemas, curentSchema, finSchemeColumns, setCurShema} = useSchemasData();

  const onCreatePaymentRequest = () => {
    if (!curentPaymentDoc || !curentSchema) {
      return;
    }
    dispatch(
      createPayment({
        requestId: currentRequest.requestId,
        data: {
          docId: curentPaymentDoc,
          schemaId: curentSchema,
          note: docNote,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurShema(null);
        setCurrentPaymet(null);
        setFieldValue("docNote", "");
      }
    });
  };

  const onRemovePayment = () => {
    Swal.fire({
      title: "",
      text: t("del irrev"),
      icon: "question",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(removePayment(currentPayment.paymentId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getInfo(t("del pay req"));
          }
        });
      }
    });
  };

  return (
    <div>
      <div>1.{t("For payment")} </div>
      <div>
        <PaymentDates
          labelPlan={t("for payment (p)")}
          labelFact={t("for payment (f)")}
          ttType="load doc assign"
          name={"onPayment"}
          createdDate={initForPaymentDates?.createdAt}
          planned={initForPaymentDates?.plannedDate}
          actual={initForPaymentDates?.actualDate}
        />

        <div className="on-payment on-payment_download">
          <TextInput
            label={t("Doc. for payment")}
            question="num doc"
            name="doc"
            width="10em"
            labelStyles={{width: "9em"}}
          />

          <Select
            label={t("Document type")}
            question="doc type"
            options={docTypes}
            name="documentType"
            width="10em"
            labelwidth="9em"
          />
          <SingleFilePicker disabled={disabledFilePicker} addFunc={onFileAdding} accept={".pdf"} />
        </div>

        <DocumentsForPayment data={paymentDocs} columns={columnsForPayment} current={curentPaymentDoc} />
        <FinScheme data={paymentSchemas} columns={finSchemeColumns} current={curentSchema} />
        {/* <PaymentDocs /> */}
        <TextAreaInput
          name="docNote"
          width={"100%"}
          placeholder={t("Note for factory manager")}
          style={{marginTop: "10px", height: "4em"}}
        />
        <div className="buttons-table">
          <FunctionButton
            name={t("Delete payment request")}
            autoWidth
            tooltipMessage="Delete selected"
            disabled={!(currentPayment && currentPayment?.status?.statusId === 1)}
            // disabled={true}
            onClick={onRemovePayment}
          />
          <FunctionButton
            name={t("Create a payment request")}
            autoWidth
            tooltipMessage="list docs"
            disabled={!curentPaymentDoc || !curentSchema}
            onClick={onCreatePaymentRequest}
          />
        </div>

        <div className="border"></div>
      </div>
    </div>
  );
};

export default ForPaymentPart;
