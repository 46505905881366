import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

const useSwalDelete = () => {
  const {t} = useTranslation();

  function swalDelete(deleteFunc, message = "All data related to the selected Contact record will be deleted") {
    Swal.fire({
      title: t("Are you sure?"),
      text: t(message),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteFunc();
        Swal.fire(t("Deleted!"), t("Has been deleted."), "success");
      }
    });
  }
  return swalDelete;
};

export default useSwalDelete;
