/* eslint-disable react/display-name */
import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

import KpFinForm from "./KpFinForm";

export const withKpForm = (saveLabel, onSubmit, onCancel, isShowOverallView, toolTip) => {
  return props => {
    const {t} = useTranslation();

    return (
      <KpFinForm onSubmit={onSubmit} isShowOverallView={isShowOverallView}>
        <FunctionButton name={saveLabel} tooltipMessage={toolTip} type="submit" style={{width: "15em"}} />
        <FunctionButton
          name={t("Cancel")}
          onClick={onCancel}
          style={{marginLeft: "2em", position: "initial", width: "11em"}}
        />
      </KpFinForm>
    );
  };
};
