import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectReferences} from "../../../../redux/selectors/FactorySelectors";

const useTimeUnitsService = () => {
  const {i18n} = useTranslation();
  const references = useSelector(selectReferences);

  const {timeUnits} = references ?? [];
  const termsOptions = useMemo(
    () =>
      timeUnits?.map(timeUnit => ({
        title: i18n === "eng" ? timeUnit.engName : timeUnit.name,
        value: timeUnit.unitId,
        multiplier: timeUnit.multiplier,
      })),
    [timeUnits],
  );

  return termsOptions ?? [];
};

export default useTimeUnitsService;
