import {useTranslation} from "react-i18next";

import {BUTTON_COLORS} from "../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Spinner from "../../../spinner/Spinner";

import DistributeReqTable from "./parts/DistributeReqTable";
import DistributeStockPoolTable from "./parts/DistributeStockPoolTable/DistributeStockPoolTable";
import IncomingReqTable from "./parts/IncomingReqTable/IncomingReqTable";
import IncomingStockPoolTableSelect from "./parts/IncomingStockPoolTableSelect/IncomingStockPoolTableSelect";
import OrderingFormLog from "./parts/OrderingFormLog/OrderingFormLog";
import TPreqDistribFilter from "./parts/TPreqDistribFilter/TPreqDistribFilter";
import useTPRequestDistributionModal from "./useTPRequestDistributionModal";

const TPRequestDistributionModal = ({onModalClose}) => {
  const {t} = useTranslation();

  const {
    selectedSP,
    setSelectedSP,
    areStockPoolsSelected,
    areBtnsDisabled,
    addToProcessing,
    isLogistLoading,
    selectedForCancel,
    setSelectedForCancel,
    onCancel,
    selectedFilters,
    setSelectedFilters,
  } = useTPRequestDistributionModal({onModalClose});

  return (
    <div style={{height: "100%", width: "85vw"}}>
      {isLogistLoading && <Spinner style={{zIndex: 100}} />}
      <TPreqDistribFilter disabled={areStockPoolsSelected} setSelectedFilters={setSelectedFilters} />
      <div className="fcc gap">
        <IncomingStockPoolTableSelect selectedSP={selectedSP} setSelectedSP={setSelectedSP} />
        <IncomingReqTable selectedSP={selectedSP} />
      </div>

      <OrderingFormLog
        selectedForCancel={selectedForCancel}
        setSelectedForCancel={setSelectedForCancel}
        selectedSP={selectedSP}
        setSelectedSP={setSelectedSP}
        selectedFilters={selectedFilters}
      />
      <div className="fcc gap">
        <DistributeStockPoolTable selectedForCancel={selectedForCancel} setSelectedForCancel={setSelectedForCancel} />
        <DistributeReqTable />
      </div>

      <div className="fc jcsb" style={{marginTop: "1em "}}>
        <FunctionButton
          name={"Auto distribution of Requests"}
          autoWidth
          disabled
          tooltipMessage={"distrib settings"}
          color={BUTTON_COLORS.TRANSPARENT}
        />
        <div className="fc" style={{gap: "1em"}}>
          <FunctionButton
            name={"Add SP to processing"}
            onClick={addToProcessing}
            autoWidth
            tooltipMessage={"distribute SP"}
            disabled={areBtnsDisabled}
          />
          <FunctionButton
            name={t("Cancel")}
            tooltipMessage={"cancel stockpool tip"}
            onClick={onCancel}
            disabled={areBtnsDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default TPRequestDistributionModal;
