// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-project-form {
  height: 15em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #426ba6;
  gap: 1%;
  justify-content: space-between;
}
.add-project-form__container {
  width: 49%;
}
.add-project-form__container:nth-child(3) {
  width: 100%;
}

.project-add-form {
  border-bottom: 1px solid rgba(66, 108, 166, 0.368627451);
}

.project_form_wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 1440px) {
  .pw {
    width: 49%;
  }
}
@media screen and (min-width: 1805px) and (max-width: 1904px) {
  .pw {
    width: 44%;
  }
}
@media screen and (min-width: 1905px) {
  .pw {
    width: 50%;
  }
}

.flexg1 {
  flex-grow: 1;
}

.flexg2 {
  flex-grow: 2;
}`, "",{"version":3,"sources":["webpack://./src/Components/Project/ProjectAddForm/ProjectAddForm.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,OAAA;EACA,8BAAA;AACF;AAAE;EACE,UAAA;AAEJ;AAAI;EACE,WAAA;AAEN;;AAEA;EACE,wDAAA;AACF;;AACA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;EACA,8BAAA;AAEF;;AAEE;EADF;IAEI,UAAA;EAEF;AACF;AAAE;EALF;IAMI,UAAA;EAGF;AACF;AAFE;EARF;IASI,UAAA;EAKF;AACF;;AAFA;EACE,YAAA;AAKF;;AAHA;EACE,YAAA;AAMF","sourcesContent":[".add-project-form {\n  height: 15em;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  color: #426ba6;\n  gap: 1%;\n  justify-content: space-between;\n  &__container {\n    width: 49%;\n\n    &:nth-child(3) {\n      width: 100%;\n    }\n  }\n}\n.project-add-form {\n  border-bottom: 1px solid #426ca65e;\n}\n.project_form_wrapper {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.pw {\n  @media screen and (min-width: 1440px) {\n    width: 49%;\n  }\n\n  @media screen and (min-width: 1805px) and (max-width: 1904px) {\n    width: 44%;\n  }\n  @media screen and (min-width: 1905px) {\n    width: 50%;\n  }\n}\n\n.flexg1 {\n  flex-grow: 1;\n}\n.flexg2 {\n  flex-grow: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
