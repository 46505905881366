import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../utilities/ToolTip";
import ControlCheck from "../../../Inputs/controlCheck/ControlCheck";

import MistakeDecoration from "./MistakeDecoration/MistakeDecoration";
import MistakeFilters from "./MistakeFilters/MistakeFilters";
import useSKU_MistakeCorrectionService from "./useSKU_MistakeCorrectionService";

const SKU_MistakeCorrection = ({onModalClose}) => {
  const {t} = useTranslation();
  const {initialValues, onCancel, onSubmit, onPost, calcButtonsDisabled, formSubmitBtnTooltipTitle} =
    useSKU_MistakeCorrectionService({
      onModalClose,
    });

  return (
    <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} enableReinitialize>
      {({values}) => (
        <Form className="sku-ved-form" style={{width: "100%", height: "100%"}}>
          <MistakeFilters />
          <MistakeDecoration />
          <div className="fc jcfe" style={{width: "100%", height: "5em"}}>
            <ControlCheck name="creatorName" date="createdAt" initialChecked={false} readOnly />
          </div>

          <div className="skuForm_btns">
            <ToolTip title={formSubmitBtnTooltipTitle(calcButtonsDisabled(values))}>
              <FunctionButton
                name={t("Save SKU")}
                type="submit"
                onClick={() => onSubmit(values)}
                disabled={calcButtonsDisabled(values)}
                // disabled
              />
            </ToolTip>

            <FunctionButton name={t("Cancel")} onClick={() => onCancel(values)} />
            <FunctionButton name={t("Post SKU")} disabled onClick={() => onPost(values)} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SKU_MistakeCorrection;
