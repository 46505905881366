import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import {getCurrentKp} from "../../../selectors/generalSelectors";
import {getProjectKpList} from "../../../slices/KPSlice";
import TableWithSelect from "../../conteiners/TableWithSelect";

const ProjectKpList = ({onMakeEffect}) => {
  const {t} = useTranslation();

  const kpLoadingStatus = useSelector(state => state.kp.kpLoadingStatus);
  const kpList = useSelector(getProjectKpList);
  const currentKp = useSelector(getCurrentKp);
  const projectsLoadingStatus = useSelector(state => state.projects.projectsLoadingStatus);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //    if(currentProject) dispatch(fetchProjectKpList(currentProject.projectKey));
  // }, [currentProject]);

  const ths = useMemo(() => {
    return {
      [t("CO №")]: 12,
      [t("Manager")]: 13,
      [t("CO Manager")]: 13,
      [t("Note/desc")]: 39,
      [t("Date from")]: 9,
      [t("Date to")]: 9,
      [t("Status")]: 5,
    };
  }, []);

  // const onSelect = (id)=>{
  //     dispatch(getKpInfo(id))
  // }

  return (
    <TableWithSelect
      thsName={ths}
      items={kpList.map(transformKpForTable)}
      current={currentKp ? currentKp.coId : null}
      onSelect={onMakeEffect}
      loadingStatus={kpLoadingStatus === projectsLoadingStatus ? kpLoadingStatus : projectsLoadingStatus}
      formatStatus={formatStatus}
    />
  );
};

const formatStatus = status => {
  let statusColor = "gray";
  // var textColor = "#A3B9D9";
  let textColor = "#426BA6";
  switch (status) {
    case "tempSaved":
      statusColor = "green";
      // textColor = "#426BA6";
      break;
    case "unsaved":
      statusColor = "rgb(246, 248, 96)";
      // textColor = "#426BA6";
      break;
    default:
      break;
  }
  return [
    <label key="status-label" className="radio_container">
      <div className="circules_box">
        <div className="circule1"></div>
        <div className="circule2" style={{backgroundColor: statusColor}}></div>
      </div>
    </label>,
    textColor,
  ];
};

function transformKpForTable(kp) {
  const date1 = kp.startDate ? new Date(kp.startDate).toLocaleDateString() : "";
  const date2 = kp.finalDate ? new Date(kp.finalDate).toLocaleDateString() : "";
  return {
    number: kp.coNumber,
    projectManager: kp.project.projectManager.name,
    kpManager: kp.managerCo.name,
    notes: kp.note || "",
    creationDate: date1,
    finalDate: date2,
    status: "",
    id: kp.coId,
  };
}

ProjectKpList.propTypes = {onMakeEffect: PropTypes.func};

export default ProjectKpList;
