// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainToDo {
  max-width: 50vw;
  margin-top: 1%;
  margin-right: 2%;
}
.mainToDo__container {
  width: 630px;
  height: 260px;
  background: #ffffff;
}

.activeProjects {
  margin: 0 0;
}
.activeProjects__container {
  width: 100%;
  height: 349px;
  background: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/Components/MainPage/MainPage.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EAGA,cAAA;EACA,gBAAA;AADF;AAEE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AAAJ;;AAOA;EACE,WAAA;AAJF;AAKE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAHJ","sourcesContent":[".mainToDo {\n  max-width: 50vw;\n  // display: block;\n  // position: absolute;\n  margin-top: 1%;\n  margin-right: 2%;\n  &__container {\n    width: 630px;\n    height: 260px;\n    background: #ffffff;\n  }\n  //    .table{\n  //      border:1px solid #58595d4b\n  //    }\n}\n\n.activeProjects {\n  margin: 0 0;\n  &__container {\n    width: 100%;\n    height: 349px;\n    background: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
