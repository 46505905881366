import {createSelector} from "@reduxjs/toolkit";

const getSkuList = state => state.sku.skuList;

export const getSkuOptionsList = createSelector([getSkuList], skuList =>
  skuList.map(sku => ({title: sku.article, value: sku.skuId})),
);

export const getCountries = state => state.sku.countries;
export const getBrands = state => state.sku.brands;
export const getColors = state => state.sku.colors;
export const getMeasurementUnits = state => state.sku.measurementUnits;
export const getMaterials = state => state.sku.materials;
export const getProductrSeries = state => state.sku.producrSeries;
export const getProductGroups = state => state.sku.productGroups;
export const getProductTypes = state => state.sku.productTypes;
export const getPallets = state => state.sku.pallets;
export const getMeasurementObjects = state => state.sku.measurementObjects;
export const getAreaMeasurementQuantites = state => state.sku.areaMeasurementQuantites;
export const getLengthMeasurementQuantites = state => state.sku.lengthMeasurementQuantites;
export const getVolumeMeasurementQuantites = state => state.sku.volumeMeasurementQuantites;
export const getWeightMeasurementQuantites = state => state.sku.weightMeasurementQuantites;

export const getCurrentSku = state => state.sku.currentSku;
