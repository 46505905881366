import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import {getCheckedRequestIds, getCurrentRequestId, setCheckedRequestIds} from "../../RequestsSlice";

import useModalStockPoolTableService from "./ModalStockPoolTableService";
import useStockPoolTableService from "./StockPoolTableService";

const ModalStockPoolTable = () => {
  const currentRequestIds = useSelector(getCheckedRequestIds);
  const dispatch = useDispatch();

  const [defaultCheck, setDefaultCheck] = useState(null);

  const {
    stockPoolTableColumns,
    stockPoolTableData,
    // onStockPoolTableSelect
  } = useModalStockPoolTableService();

  const onTableCheckboxChange = checked => {
    if (currentRequestIds && checked.join("") === currentRequestIds.join("")) return;
    dispatch(setCheckedRequestIds(checked));
  };
  return (
    <ReactTable
      className="w100 scrollX"
      columns={stockPoolTableColumns}
      defaultData={stockPoolTableData}
      defaultMultiSelection={defaultCheck}
      setMultiSelect={onTableCheckboxChange}
      loading={false}
      enableMultiSelection
    />
  );
};

export default ModalStockPoolTable;
