import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat";
import {getInfo, getSuccess} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import SingleFilePicker from "../../../Inputs/SingleFilePicker";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {
  addTransportDoc,
  deleteTransportDoc,
  deselectTransportDoc,
  fetchAllTransportDocs,
  getRequestInfo,
  getTransportDoc,
  selectAllTransportDocs,
  selectTransportDocLoading,
} from "../../RequestsSlice";

import {DOCS_TABLE_COLUMNS} from "./utils/constants";
import useTransportDocsTableDataService from "./utils/transportDocsTableDataService";
import useDocRefs from "./utils/useDocRefs";

import styles from "./ReceiptGoodsModal.module.scss";

const InvoiceAndPackBlock = ({currentTransportDoc, isReadyMod, element}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo),
    allTransDocs = useSelector(selectAllTransportDocs),
    docsLoading = useSelector(selectTransportDocLoading);

  // const [currentDoc, setCurrentDoc] = useState(null);

  const {values, setFieldValue, setValues} = useFormikContext(),
    {onDocDate, docNumber, docType} = values;

  const docsTableColumns = useColumns(DOCS_TABLE_COLUMNS);
  const {docRefsOptions} = useDocRefs();

  useEffect(() => {
    isReadyMod && dispatch(fetchAllTransportDocs(currentRequest.requestId));

    return () => {
      dispatch(deselectTransportDoc());
    };
  }, []);

  const transportDocsTableData = useTransportDocsTableDataService(allTransDocs);
  console.log("transportDocsTableData", transportDocsTableData);

  const onDocAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.docNumber);
    formdata.append("docTypeId", values.docType);
    formdata.append("dateFrom", values.onDocDate);

    if (formdata.has("doc")) {
      const params = isReadyMod ? {requestId: element.requestId} : {stockPoolId: element.stockPoolId};
      dispatch(addTransportDoc({params, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("doc Success"));
          setFieldValue("docUploadDate", dateFormat(resp.payload.createdAt));
        }
      });
    }
  };

  const onDocSelect = id => {
    if (!id) {
      dispatch(deselectTransportDoc());
      !isReadyMod &&
        setValues({
          onDocDate: "",
          docNumber: "",
          docType: "",
        });
      return;
    }
    const currDoc = allTransDocs?.find(doc => doc.docId == id);

    !isReadyMod &&
      setValues({
        onDocDate: currDoc?.dateFrom,
        docNumber: currDoc?.docNumber,
        docType: currDoc?.docType?.docTypeId,
      });

    dispatch(getTransportDoc(currDoc.docId));
  };

  const onDocDelete = () => {
    Swal.fire({
      text: t("Delete document") + "?",
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteTransportDoc(currentTransportDoc.docId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getInfo(t("doc del"));
            dispatch(deselectTransportDoc());

            !isReadyMod &&
              setValues({
                onDocDate: "",
                docNumber: "",
                docType: "",
              });
          }
        });
      }
    });
  };

  return (
    <section className={styles.documentsSection}>
      <div className={styles.head}>
        <div>
          {" "}
          {isReadyMod && 2} {t("Documentation") + ":"}
        </div>
        <Select label={t("Document type")} name="docType" options={docRefsOptions} disabled={!!currentTransportDoc} />
      </div>
      <div className={styles.sectionRow}>
        <DateInput label={t("Date") + " Doc:"} name="onDocDate" labelW={"4.5em"} disabled={!!currentTransportDoc} />
        <TextInput
          name="docNumber"
          label="№ Doc"
          question="d numb"
          width="8em"
          labelStyles={{width: "5em"}}
          disabled={!!currentTransportDoc}
        />

        <SingleFilePicker
          disabled={onDocDate === "" || docType === "" || docNumber === "" || !!currentTransportDoc}
          addFunc={onDocAdding}
          accept={".pdf"}
        />
      </div>
      <ReactTable
        defaultData={transportDocsTableData}
        columns={docsTableColumns}
        onSelect={onDocSelect}
        current={currentTransportDoc?.docId}
        loading={docsLoading}
        style={{height: "8em"}}
      />
      <FunctionButton
        type="button"
        className={styles.delDocBtn}
        style={{
          marginTop: "10px",
        }}
        name="delete n"
        autoWidth
        disabled={!currentTransportDoc}
        onClick={onDocDelete}
      />
    </section>
  );
};

export default InvoiceAndPackBlock;
