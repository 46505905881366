export const GROUP_AND_TYPE_RQST_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 30,
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
    size: 50,
    isSortable: true,
  },
  {
    header: "Product group and type",
    accessorKey: "groupAndType",
    size: 100,
    isSortable: true,
  },
]);

export const GROUP_AND_TYPE_ORDER_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Product group and type",
    accessorKey: "orderSumm",
    size: 100,
    isSortable: true,
  },
]);

export const GROUP_AND_TYPE_SP_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "SP №",
    accessorKey: "spNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Product group and type",
    accessorKey: "orderSumm",
    size: 100,
    isSortable: true,
  },
]);

export const GROUP_AND_TYPE_TP_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Order number",
    accessorKey: "orderNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Product group and type",
    accessorKey: "groupAndType",
    size: 100,
    isSortable: true,
  },
]);

export const GROUP_AND_TYPE_MODES = Object.freeze({
  ORDER: "order",
  RQST: "rqst",
  SP: "sp",
  TP: "tp",
  TEXTAREA: "textarea",
});
