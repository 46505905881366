import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectStockPoolFilters} from "../../redux/selectors";

const useSleepingFilters = () => {
  const {
    i18n: {language},
  } = useTranslation();

  const filters = useSelector(selectStockPoolFilters);

  const brandsOptions =
    filters?.brands?.map(item => {
      return {title: item.name, value: item.brandId};
    }) ?? [];

  const countryOptions =
    filters?.countries?.map(item => {
      return {title: item.shortName, value: item.countryId};
    }) ?? [];

  const requestStatusOptions =
    filters?.statuses?.map(item => {
      return {title: item[language === "en" ? "engName" : "ukrName"], value: item.statusId};
    }) ?? [];

  const coManagersOptions =
    filters?.coManagers?.map(item => {
      const name = item?.persona
        ? language === "en"
          ? item.persona.engFirstName + " " + item.persona.engLastName
          : item.persona.firstName + " " + item.persona.lastName
        : item.name;
      return {
        title: name,
        value: item.managerCoId,
      };
    }) ?? [];

  const feaManagersOptions =
    filters?.feaManagers?.map(item => {
      const name = item?.persona
        ? language === "en"
          ? item.persona.engFirstName + " " + item.persona.engLastName
          : item.persona.firstName + " " + item.persona.lastName
        : item.name;
      return {
        title: name,
        value: item.managerFeaId,
      };
    }) ?? [];

  const requestNumberOptions = filters?.requestNumbers?.map(item => ({title: item, value: item})) ?? [];

  return {
    requestStatusOptions,
    requestNumberOptions,
    coManagersOptions,
    brandsOptions,
    feaManagersOptions,
    countryOptions,
  };
};

export default useSleepingFilters;
