import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {deselectCurrentRequest, getRequestInfo} from "../../RequestsSlice";

import {REQUEST_TABLE_COLUMNS} from "./utils/constants";

const RequestTable = ({defaultData, orderingModalActive, requestLoading, onSelect, ...props}) => {
  const columns = useColumns(REQUEST_TABLE_COLUMNS);
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);
  const isCurrentInFiltered = Boolean(
    defaultData && currentRequest && defaultData.find(rqst => rqst.requestNumber === currentRequest.requestNumber),
  );

  useEffect(() => {
    if (!isCurrentInFiltered) {
      dispatch(deselectCurrentRequest());
    }
  }, [isCurrentInFiltered]);

  return (
    <ReactTable
      defaultData={defaultData}
      columns={columns}
      onSelect={onSelect}
      current={isCurrentInFiltered ? currentRequest?.requestId : null}
      className={"scrollX w100"}
      tableClassName={"request-table"}
      loading={orderingModalActive ? false : requestLoading}
      {...props}
    />
  );
};

export default RequestTable;
