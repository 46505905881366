import React from "react";
import {useTranslation} from "react-i18next";

import ReactTable from "../../../conteiners/ReactTable";

import useFactoryListService from "./useFactoryListService";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const FactoryList = () => {
  const {t} = useTranslation();

  const {factoryListTableColumns, factoryListTableData, onFactoryListTableRowSelect, current} = useFactoryListService();

  return (
    <section className={styles.factoryListSection}>
      <h2 className={styles.sectionHeader}>
        4.
        <span className={styles.underLined}>
          {/* {t("Factories List with conditions and contacts")} */}
          {t("Factories List")}
        </span>
      </h2>
      <ReactTable
        className={styles.factoryListTableWrapper}
        columns={factoryListTableColumns}
        defaultData={factoryListTableData}
        onSelect={onFactoryListTableRowSelect}
        current={current}
      />
    </section>
  );
};

export default FactoryList;
