import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";
import * as Yup from "yup";

import ReactTable from "../../../../conteiners/ReactTable";
import {MoneyInput, NoteInput, TextInput} from "../../../../Inputs";
import CurrencySelect from "../../../../Inputs/preparedInputs/CurrencySelect";
import {Select} from "../../../../Inputs/SelectField";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import useWarehouseServicesTypeOptions from "../../../common/Warehouses/Services/useWarehouseServicesTypeOptions";
import useWarehouseOptions from "../../../common/Warehouses/useWarehouseOptions";
import {
  addWarehouseService,
  deleteWarehouseService,
  fetchAllWarehouseService,
  fetchWarehouseService,
  updateWarehouseService,
} from "../../../redux/Warehouses/operations";
import {selectCurrentWarehouseService, selectWarehouseServices} from "../../../redux/Warehouses/selectors";
import {clearTransactions} from "../../../redux/Warehouses/WarehousesSlice";
import {SETTINGS_STOREGE_SERV_COLUMNS} from "../constants";

const StoregeServForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const columns = useColumns(SETTINGS_STOREGE_SERV_COLUMNS);

  const services = useSelector(selectWarehouseServices);
  const curentService = useSelector(selectCurrentWarehouseService);

  const {warehousesOptions} = useWarehouseOptions();
  const warehouseServicesTypesOptions = useWarehouseServicesTypeOptions();

  const {values, submitForm, setValues, resetForm, initialValues} = useFormikContext();
  const {warehouseId, typeId, currency, currencyId, name, price, note} = values;

  const servicesTableData =
    services?.map((service, index) => ({
      id: service.serviceId,
      number: ++index,
      wareHouseName: service.warehouse.name,
      ServiceType: service.type.name,
      serviceName: service.name,
      currency: service.currencyId,
      price: service.price,
      note: service.note,
    })) || [];

  const updateAndClear = () => {
    const id = warehouseId === "" ? null : warehouseId;
    dispatch(fetchAllWarehouseService({warehouseId: id}));
  };

  useEffect(() => {
    updateAndClear();
  }, [warehouseId]);

  useEffect(() => {
    return () => {
      dispatch(clearTransactions());
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (curentService)
      setValues({
        name: curentService?.name || "",
        note: curentService?.note || "",
        price: curentService?.price || "",
        currencyId: curentService?.currency.currencyId || "",
        warehouseId: curentService?.warehouse?.warehouseId || warehouseId || "",
        typeId: curentService?.type.typeId || "",
      });
    else {
      setValues({
        ...initialValues,
        warehouseId: warehouseId || "",
      });
    }
  }, [curentService]);

  const createWarehouseService = () => {
    submitForm().then(values => {
      if (values) {
        if (curentService) {
          dispatch(
            updateWarehouseService({serviceId: curentService?.serviceId, data: {currencyId, name, price, note}}),
          ).then(() => updateAndClear());
          return;
        }
        dispatch(addWarehouseService({warehouseId, typeId, data: {currencyId, name, price, note}})).then(() =>
          resetForm(),
        );
      }
    });
  };

  const onServiceSelect = serviceId => {
    if (serviceId) {
      dispatch(fetchWarehouseService(serviceId));
    } else {
      dispatch(clearTransactions());
    }
  };

  const onServiseRemove = () => {
    Swal.fire({
      title: t("Sure"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteWarehouseService(curentService?.serviceId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            updateAndClear();
            resetForm();
            dispatch(clearTransactions());
          }
        });
      }
    });
  };

  return (
    <div className="fGrow fcCols w100">
      <div className="label underline mb0_5">Список послуг Склада:</div>
      <div className="autolabel w100 mb0_5">
        <div className="fc nowrap jcsb">
          <div>
            <Select
              name="warehouseId"
              label={t("Warehouse")}
              options={warehousesOptions}
              makeEffect={() => {
                if (curentService) {
                  dispatch(clearTransactions());
                }
              }}
            />
            <Select name="typeId" label={t("Service type")} options={warehouseServicesTypesOptions} />
          </div>
          <div>
            <CurrencySelect />
            <MoneyInput
              name={"price"}
              placeholder=""
              currency={currency || ""}
              label={t("Price")}
              autolabel
              width={"100%"}
            />
          </div>
        </div>
        <div>
          <TextInput name={"name"} label={t("Service name")} width={"100%"} />
          <NoteInput
            name={"note"}
            label={t("Note")}
            deleteDisabled={!curentService}
            action={createWarehouseService}
            width={"100%"}
            deleteIcon
            onDelete={onServiseRemove}
          />
        </div>
      </div>
      <div className="fGrow w100">
        <ReactTable
          columns={columns}
          defaultData={servicesTableData}
          onSelect={onServiceSelect}
          current={curentService?.serviceId || null}
        />
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    note: "",
    warehouseId: "",
    typeId: "",
    currencyId: "",
    price: "",
  }),
  handleSubmit: values => values,
  enableReinitialize: true,
  validationSchema: () =>
    Yup.object({
      name: Yup.string("").required([t("Required field")]),
      note: Yup.string().required([t("Required field")]),
      warehouseId: Yup.number(t("Оберіть склад зі списка"))
        .nullable()
        .required([t("Оберіть склад зі списка")]),
      typeId: Yup.number([t("Оберіть тип зі списка")])
        .nullable()
        .required([t("Оберіть тип зі списка")]),
      currencyId: Yup.number([t("Оберіть валюту зі списка")])
        .nullable()
        .required([t("Оберіть валюту зі списка")]),
      price: Yup.number()
        .nullable()
        .required([t("Required field")]),
    }),
})(StoregeServForm);
