import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../../../../hook/axios.hook";

const initialState = {
  currentDocument: {
    doc: null,
    number: null,
  },
  payments: [],
  payment: null,
  paymentDocs: [],
  // docsForPayment: [],
  // docsByPayment: [],
  docTypes: [],
  paymentSchemas: [],
  requestPayments: [],
  currentPayment: null,
  currentPaymentId: null,
  paymentsLoader: false,
};

export const getPaymentsForRequest = createAsyncThunk("payment/getPaymentsForRequest", async ({requestId}) => {
  const responce = fetchData(`/payments/getForRequest/${requestId}`, "get");
  return responce;
});
export const getAllPayments = createAsyncThunk("payment/getAllPayments", async () => {
  const responce = fetchList(`/payments/getAll`);
  return responce;
});

export const getPaymentInfo = createAsyncThunk("payment/getPaymentInfo", async paymentId => {
  const responce = fetchData(`/payments/get/${paymentId}`, "get");
  return responce;
});

export const attachFromRequest = createAsyncThunk("payment/attachFromRequest", async ({paymentId, data}) => {
  const responce = fetchData(`payments/docs/attachFromRequest/${paymentId}`, "post", data);
  return responce;
});

export const addConfirmingDoc = createAsyncThunk("payment/addConfirmingDoc", async ({paymentId, formData}) =>
  fetchData(`/payments/docs/add/${paymentId}`, "post", formData),
);

export const removeConfirmingDoc = createAsyncThunk("payment/removeConfirmingDoc", async paymentId => {
  fetchData(`/payments/docs/remove/${paymentId}`, "delete");
});

export const createPayment = createAsyncThunk("payment/createPayment", async ({requestId, data}) => {
  const responce = fetchData(`/payments/add/${requestId}`, "post", data);
  return responce;
});

export const removePayment = createAsyncThunk("payment/removePayment", async paymentId => {
  const responce = fetchData(`/payments/remove/${paymentId}`, "delete");
  return responce;
});

export const addRequestPaymentDocs = createAsyncThunk(
  "payment/addRequestPaymentDocs",
  async ({requestId, formData}) => {
    const responce = fetchData(`/requests/paymentDocs/add/${requestId}`, "post", formData);
    return responce;
  },
);

export const sendForPayment = createAsyncThunk("payment/sendForPayment", async requestId => {
  const responce = fetchData(`/payments/sendForPayment/${requestId}`, "patch");
  return responce;
});

export const payUp = createAsyncThunk("payment/payUp", async ({paymentId, data}) =>
  fetchData(`/payments/payUp/${paymentId}`, "patch", data),
);

export const confirmPayment = createAsyncThunk("payment/confirmPayment", async requestId => {
  const responce = fetchData(`/payments/confirmPayment/${requestId}`, "patch");
  return responce;
});

export const PAYMENT_STORE_NAME = "payment";

const paymentSlice = createSlice({
  name: PAYMENT_STORE_NAME,
  initialState,
  reducers: {
    selectDoc: (state, {payload}) => {
      const {number, docId} = payload;
      state.currentDocument.number = number;
      switch (number) {
        case 1: {
          if (docId === state.currentDocument?.doc?.docId) state.currentDocument.doc = null;
          else {
            const doc = state.paymentDocs.find(doc => doc.docId === docId);
            state.currentDocument.doc = {...doc, fileUrl: [doc?.fileUrl]};
          }
          state.currentPayment = null;
          break;
        }
        case 2: {
          if (docId === state.currentPayment?.paymentId) {
            state.currentPayment = null;
            state.currentDocument.doc = null;
          } else {
            const payment = state.requestPayments.find(doc => doc?.paymentId === docId);
            let fileArray = [];
            if (payment?.paymentDoc?.fileUrl) {
              fileArray.push(payment.paymentDoc.fileUrl);
            }
            if (payment?.confirmingDoc?.fileUrl) {
              fileArray.push(payment.confirmingDoc.fileUrl);
            }
            state.currentDocument.doc = {...payment, fileUrl: fileArray};
            state.currentPayment = payment;
          }

          break;
        }
        default:
          state.currentPayment = null;
          state.currentDocument.doc = null;
          break;
      }
    },
    clearCurrentPayment: state => {
      state.currentPayment = null;
    },
    clearCurrentDocument: state => {
      state.currentDocument = {doc: null, number: null};
    },
    clearRequestPayments: state => {
      state.requestPayments = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPaymentsForRequest.fulfilled, (state, {payload, meta}) => {
        const {docTypes, paymentSchemas, paymentDocs, payments} = payload;
        const paymentId = meta.arg.paymentId;

        state.paymentDocs = paymentDocs;
        state.requestPayments = payments;

        state.docTypes = docTypes;
        state.paymentSchemas = paymentSchemas;
        // state.currentPayment = {...state.currentPayment, paymentId};
        state.currentPaymentId = paymentId;
      })
      .addCase(getAllPayments.pending, (state, {payload}) => {
        state.paymentsLoader = true;
      })
      .addCase(getAllPayments.fulfilled, (state, {payload}) => {
        state.paymentsLoader = false;
        state.payments = payload;
      })
      .addCase(getAllPayments.rejected, (state, {payload}) => {
        state.paymentsLoader = false;
      })
      .addCase(attachFromRequest.fulfilled, (state, {payload}) => {
        const {docsForPayment} = payload;
        state.docsForPayment = docsForPayment;
      })
      .addCase(addConfirmingDoc.fulfilled, (state, {payload}) => {
        state.currentPayment.confirmingDoc = payload.doc;
        state.requestPayments = state.requestPayments.map(reqPayment => {
          if (reqPayment.paymentId === state.currentPayment.paymentId) {
            reqPayment.confirmingDoc = payload.doc;
          }
          return reqPayment;
        });
      })
      .addCase(addRequestPaymentDocs.fulfilled, (state, {payload}) => {
        const {doc} = payload;
        state.paymentDocs = [...state.paymentDocs, doc];
      })
      .addCase(removeConfirmingDoc.fulfilled, state => {
        state.currentPayment.confirmingDoc = null;
        state.requestPayments = state.requestPayments.map(reqPayment => {
          if (reqPayment.paymentId === state.currentPayment.paymentId) {
            reqPayment.confirmingDoc = null;
          }
          return reqPayment;
        });
      })

      .addCase(getPaymentInfo.fulfilled, (state, {payload}) => {
        state.currentPayment = payload?.payment ?? null;
      })
      .addCase(createPayment.fulfilled, (state, {payload}) => {
        state.requestPayments.push(payload);
      })
      .addCase(payUp.fulfilled, (state, {payload}) => {
        state.currentPayment = payload;
        state.requestPayments = state.requestPayments.map(requestPayment => {
          if (requestPayment.paymentId === state.currentPayment.paymentId) {
            requestPayment = payload;
          }
          return requestPayment;
        });
      })
      .addCase(removePayment.fulfilled, (state, {meta}) => {
        state.requestPayments = state.requestPayments.filter(paym => paym.paymentId !== meta.arg);
        // state.currentDocument.doc = null;
        state.currentPayment = null;
      })

      //matchers
      .addMatcher(
        action => action.type.startsWith(PAYMENT_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      .addMatcher(
        action => action.type.startsWith(PAYMENT_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      .addMatcher(
        action => action.type.startsWith(PAYMENT_STORE_NAME) && action.type.endsWith("/rejected"),
        state => handleRejected(state),
      )
      .addDefaultCase(() => {});
  },
});

//matcher functions
function handleFulfilled(state) {
  state.paymentsLoader = false;
  state.error = [];
}
function handlePending(state) {
  state.paymentsLoader = true;
  state.error = [];
}
function handleRejected(state, error) {
  state.paymentsLoader = false;
  state.error = error;
}

const {actions, reducer} = paymentSlice;

export const {selectDoc, clearCurrentPayment, clearRequestPayments, clearCurrentDocument} = actions;

export default reducer;
