import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import DocumentsInfo from "../../common/DocumentsInfo/DocumentsInfo";
import ShipmentInfo from "../../common/ShipmentInfo/ShipmentInfo";
import {selectCurrentSkuRequest} from "../../redux/selectors";
import withLogistCheckList from "../CheckListsHOC";

const RequestCheckList = () => {
  const curentSkuRequest = useSelector(selectCurrentSkuRequest);
  const {
    i18n: {language},
  } = useTranslation();

  const generateDocumentsTableData = () => {
    var docs = [];
    var counter = 1;
    curentSkuRequest?.request.proforms?.map(pdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(pdoc?.createdAt) || "",
        docType: "Проформа",
        docNumber: "Prof № " + pdoc?.proformaNumber || "",
        createdBy: "",
        url: pdoc?.pdfFileUrl || "",
      });
      counter++;
    });
    curentSkuRequest?.request.transportDocs?.map(tdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(tdoc?.dateFrom) || "",
        docType: tdoc?.docType[language === "en" ? "nameEng" : "nameUkr"] || "",
        docNumber: tdoc?.docNumber || "",
        createdBy: "",
        url: tdoc?.fileUrl || "",
      });
      counter++;
    });
    return docs;
  };

  const documentsTableData = generateDocumentsTableData();

  return (
    <>
      <div className="mb">
        <DocumentsInfo documentsTableData={documentsTableData} tableHeight={"11.5em"} />
      </div>
      <ShipmentInfo height={"15.3em"} packingSpaces={curentSkuRequest?.request?.packingSpaces} />
    </>
  );
};

export default withLogistCheckList(RequestCheckList);
