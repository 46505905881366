import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {getCurrentUser} from "../../../slices/UserSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import TableWithSelect from "../../conteiners/TableWithSelect";
import {fetchProjects, setCurrentProjectManager} from "../ProjectSlice";

const ProjectList = ({onMakeEffect}) => {
  const {t} = useTranslation();

  const {projects, currentProject} = useSelector(state => state.projects);
  const user = useSelector(getCurrentUser);

  // console.log("projects", projects);
  // console.log("currentProject", currentProject);

  const dispatch = useDispatch();

  useEffect(() => {
    // if (projects.length === 0 ) {
    if (user) {
      dispatch(
        setCurrentProjectManager({
          userId: user.id,
          name: user.name,
          orgStructureId: user.orgId,
          orgStructure: user.orgStructure,
        }),
      );
      // dispatch(fetchProjects());
    }
    // }
  }, [user]);

  // useEffect(() => {

  // }, [projects]);

  function transformProjectForTable(project) {
    return {
      name: project.projectNumber,

      designerName: project.designer?.name || "",
      projectAdress: project?.address || "",
      creationDate: dateFormat(project.createdAt),
      finalDate: dateFormat(project.deadline),
      status: project?.status?.engName || "",
      id: project.projectId,
    };
  }

  const ths = useMemo(() => {
    return {
      [t("Project #")]: 10,
      [t("Designer")]: 20,
      [t("Adress")]: 45,
      [t("Date from")]: 10,
      [t("Date to")]: 10,
      [t("Status")]: 5,
    };
  }, []);

  const data = useMemo(() => {
    return projects.map(transformProjectForTable);
  }, [projects]);

  return (
    <TableWithSelect
      thsName={ths}
      items={data}
      current={currentProject ? currentProject.projectId : null}
      onSelect={onMakeEffect}
      loadingStatus={"idle"}
      formatStatus={formatStatus}
    />
  );
};

const formatStatus = status => {
  var statusColor = "gray";
  var textColor = "#A3B9D9";
  switch (status) {
    case "tempSaved":
      statusColor = "green";
      textColor = "#426BA6";
      break;
    case "unsaved":
      statusColor = "rgb(246, 248, 96)";
      textColor = "#426BA6";
      break;
    default:
      break;
  }
  return [
    <label key="status-label" className="radio_container">
      <div className="circules_box">
        <div className="circule1"></div>
        <div className="circule2" style={{backgroundColor: statusColor}}></div>
      </div>
    </label>,
    <span key="status-text" style={{color: textColor}}></span>,
  ];
};

ProjectList.propTypes = {onMakeEffect: PropTypes.func};

export default ProjectList;
