export const WAREHOUSE_TYPES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Type",
    accessorKey: "warehouseType",
  },
]);

export const WAREHOUSE_NAMES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Warehouse name",
    accessorKey: "warehouseName",
  },
]);

export const TRANSPORTER_NAMES_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Transporter name",
    accessorKey: "transporterName",
  },
]);
