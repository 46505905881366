import {createSlice} from "@reduxjs/toolkit";

import {
  addNewCargo,
  addNewCargoPaymentDocFile,
  fetchAllCargoDocs,
  fetchAllCargos,
  fetchAllPaymentDocs,
  fetchCargo,
  fetchCargoDocTypes,
  fetchPaymentDoc,
  fetchPaymentDocTypes,
  updateCargo,
} from "./operations";

export const CARGOS_STORE_NAME = "logistCargos";

const initialState = {
  all: null,
  chosen: null,
  added: null,
  cargoDocTypes: null,
  allCargoDocs: null,
  paymentDocTypes: null,
  allPaymentDocs: null,
  paymentDoc: null,
  currentDocFile: null,

  cargosLoading: false,
  error: false,
};

const CargosSlice = createSlice({
  name: CARGOS_STORE_NAME,
  initialState,
  reducers: {
    setCurrentCargosDocFile: (state, {payload}) => {
      state.currentDocFile = payload;
    },
  },
  extraReducers: builder => {
    builder //get
      .addCase(fetchAllCargos.fulfilled, (state, {payload}) => {
        state.all = payload;
      })
      .addCase(fetchCargo.fulfilled, (state, {payload}) => {
        state.chosen = payload;
      })
      //post
      .addCase(addNewCargo.fulfilled, (state, {payload}) => {
        state.added = payload;
      })

      .addCase(updateCargo.fulfilled, () => {
        // let copy = {...state.currentSkuRequest};
        // copy.stockPool.cargo.isFinal = payload.isFinal;
        // state.currentSkuRequest = {...copy};
        // state.currentSkuRequest = {
        //   ...state.currentSkuRequest,
        //   stockPool: {
        //     ...state.currentSkuRequest.stockPool,
        //     cargo: {...state.currentSkuRequest.stockPool.cargo, isFinal: payload.isFinal},
        //   },
        // };
      })

      //*** Cargos payments docs */
      .addCase(fetchPaymentDocTypes.fulfilled, (state, {payload}) => {
        state.paymentDocTypes = payload;
      })
      .addCase(fetchAllPaymentDocs.fulfilled, (state, {payload}) => {
        state.allPaymentDocs = payload;
      })
      .addCase(fetchPaymentDoc.fulfilled, (state, {payload}) => {
        state.paymentDoc = payload;
      })
      .addCase(addNewCargoPaymentDocFile.fulfilled, () => {
        // state.currentDocFile = payload;
      })

      //*** Cargos docs */
      .addCase(fetchCargoDocTypes.fulfilled, (state, {payload}) => {
        state.cargoDocTypes = payload;
      })
      .addCase(fetchAllCargoDocs.fulfilled, (state, {payload}) => {
        state.allCargoDocs = payload;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(CARGOS_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(CARGOS_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(CARGOS_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.cargosLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.cargosLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.cargosLoading = false;
  state.error = error;
}

const {actions, reducer} = CargosSlice;
export const {setCurrentCargosDocFile} = actions;

export default reducer;
