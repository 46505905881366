import {useState} from "react";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import Filters, {FIELD_TYPES} from "../../Filters/Filters";
import {DateInput} from "../../Inputs";
import TabsModalDynamic from "../../Modal/TabsModalDynamic/TabsModalDynamic";
import ModCheckList from "../CheckLists/ModCheckList";
import {LOGIST_WINDOWS} from "../common/ShipmentInfo/constants";

import useFinalDestinationManager from "./useFinalDestinationManager";

const FinalDestinationManagerBase = () => {
  const {t} = useTranslation();

  const {
    onCarrierTrackingBtnClick,
    leftSideTableColumns,
    leftSideTableData,
    rightSideTableColumns,
    rightSideTableData,
    onRightSideTableSelect,
    getFilterData,
    finalDestinationOptions,
    orderNumberOptions,
    managerCoOptions,
    onLeftSideTableCheck,
    areFiltersDisabled,
    onRightSideTableDoubleClick,
    isModalOpen,
    setModalOpen,
    onOpenForReceptionBtnClick,
    isModalAcceptanceOpen,
    setModalAcceptanceOpen,
    loading,
    currentSkuRequestId,
    tabs,
    setTabs,
  } = useFinalDestinationManager();

  const filterFields = [
    {type: FIELD_TYPES.SELECT, label: "Final destination", name: "warehouseId", options: finalDestinationOptions},
    {type: FIELD_TYPES.TEXT, label: "TP №", name: "cargoNumber"},
    {type: FIELD_TYPES.SELECT, label: "Order number", name: "orderNumber", options: orderNumberOptions},
    {type: FIELD_TYPES.SELECT, label: "Manager CO", name: "managerCoId", options: managerCoOptions},
  ];

  return (
    <PageContainer title={t("Final Destination")}>
      <div className="page_flex_container mb">
        <div className="w100">
          <Filters
            window={LOGIST_WINDOWS.FINAL_DESTINATION_MANAGER}
            fields={filterFields}
            getFilterData={getFilterData}
            disabled={areFiltersDisabled}
          />
          <Divider />
        </div>

        <div className="row jcsb mb" style={{width: "900px"}}>
          <DateInput name="shippingDate" label="Date: Shipping (f)" readOnly />
          <DateInput name="deliveryDate" label="Date: Delivery (n)" readOnly />
          <FunctionButton name="Carrier tracking" onClick={onCarrierTrackingBtnClick} autoWidth disabled />
        </div>
        <div className="row fGrow w100 gap">
          <ReactTable
            enableMultiSelection
            setMultiSelect={onLeftSideTableCheck}
            className="tableTac"
            columns={leftSideTableColumns}
            defaultData={leftSideTableData}
            current={null}
            style={{height: "100%", width: "30%"}}
            loading={loading}
          />
          <ReactTable
            className="tableTac overflowX"
            columns={rightSideTableColumns}
            defaultData={rightSideTableData}
            current={currentSkuRequestId}
            onSelect={onRightSideTableSelect}
            style={{height: "100%"}}
            onRowDoubleClick={onRightSideTableDoubleClick}
            loading={loading}
          />
        </div>
        <div className="row jcfe w100 gap2x mt">
          <FunctionButton
            name="Open for Reception"
            onClick={onOpenForReceptionBtnClick}
            autoWidth
            disabled={!currentSkuRequestId}
          />
        </div>
      </div>

      {/* <TabsModalDynamic
        active={isModalAcceptanceOpen}
        setModalActive={setModalAcceptanceOpen}
        modalTabs={tabs}
        closable={true}
        setModalTabs={setTabs}
        closableTabs={false}
      /> */}
      <AcceptanceModals
        isModalAcceptanceOpen={isModalAcceptanceOpen}
        setModalAcceptanceOpen={setModalAcceptanceOpen}
        tabs={tabs}
        setTabs={setTabs}
      />
      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
    </PageContainer>
  );
};

const AcceptanceModals = withFormik({
  mapPropsToValues: () => ({
    fdp: "",
    requestNumber: "",
    orderNumber: "",
    managerCo: "",
    logist: "",
    city: "",
    actDate: "",
    commissionSelect: "",
    commissionInput: "",
    commissionTextArea: "",
    commissionNote: "",
    defectDescr: "",
    transferedBy: "",
    transferByYourOption: "",
    acceptedBy: "",
    acceptedByYourOption: "",
    actInfo: "",
    claimsInfo: "",
  }),
  handleSubmit: values => values,
  enableReinitialize: true,
})(({isModalAcceptanceOpen, setModalAcceptanceOpen, tabs, setTabs}) => {
  const [checkedSkuIds, setCheckedSkuIds] = useState([]);

  return (
    <TabsModalDynamic
      active={isModalAcceptanceOpen}
      setModalActive={setModalAcceptanceOpen}
      modalTabs={tabs}
      closable={true}
      setModalTabs={setTabs}
      closableTabs={false}
      checkedSkuIds={checkedSkuIds}
      setCheckedSkuIds={setCheckedSkuIds}
    />
  );
});

const FinalDestinationManager = withFormik({mapPropsToValues: () => ({}), enableReinitialize: true})(
  FinalDestinationManagerBase,
);
export default FinalDestinationManager;
