import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import {apiUrl} from "../../../../api/baseURL.js";
import Divider from "../../../../utilities/Divider/Divider.js";
import useKpFinDataService from "../../../KP/kp-f/kpFinTable/KpFinDataService.js";
import useKpFinTableService from "../../../KP/kp-f/kpFinTable/KpFinTableService.js";
import HeaderInputRow from "../../common/HeaderInputRow/HeaderInputRow.js";
import useLogistCheckList from "../useLogistCheckList.js";

const LogistRequestSkuTable = ({requestSkus}) => {
  const {t} = useTranslation();

  const {getRequestSkuTableData} = useKpFinDataService();
  const {headerInputNames, initialValues} = useLogistCheckList();

  const skuListForT = useMemo(() => {
    return (
      [...(requestSkus?.map(getRequestSkuTableData) ?? [])].sort(
        (prevSku, nextSku) => prevSku.number - nextSku.number,
      ) || []
    );
  }, [requestSkus]);

  const columns = useMemo(() => {
    const imgcellRender = value => {
      var imgs = value.getValue();
      if (imgs) {
        return (
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <div key={imgs} style={{position: "relative"}}>
              <img src={apiUrl + "/" + imgs} alt={imgs} style={{width: "5em", height: "5em", marginRight: "2px"}} />
            </div>
          </div>
        );
      }
    };
    return [
      {
        header: () => "№",
        accessorKey: "number",
        size: 15,
      },
      {
        header: () => "SKU",
        accessorKey: "article",
        toggled: true,
        // size: 200,
      },
      {
        header: () => [t("Brand w*")],
        accessorKey: "brandName",
      },
      {
        header: () => [t("Nomenclature")],
        accessorKey: "skuName",
      },
      {
        header: () => [t("Characteristics")],
        accessorKey: "characteristics",
        size: 300,
        toggled: true,
      },
      {
        header: () => [t("VWC")],
        accessorKey: "ovh",
      },
      {
        header: () => [t("Image")],
        accessorKey: "image1",
        cell: imgcellRender,
      },
      {
        header: () => [t("Image")],
        accessorKey: "image2",
        cell: imgcellRender,
      },
      {
        header: () => [t("Set description")],
        accessorKey: "complectDesc",
        toggled: true,
      },
      {
        header: () => [t("Quantity")],
        accessorKey: "amount",
      },
      {
        header: () => [t("Price pcs")],
        accessorKey: "price",
        numberFormat: true,
      },
      {
        header: () => [t("Sum €")],
        accessorKey: "paymentSumm",
        numberFormat: true,
      },
    ];
  }, []);

  const {KpFinTableRender} = useKpFinTableService(
    columns,
    skuListForT,
    () => {},
    () => {},
    false,
    false,
  );

  const kpFinTable = KpFinTableRender();

  return (
    <div style={{height: "50em"}}>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        <Form className="w100 h100 fcCol ">
          <HeaderInputRow headerInputNames={headerInputNames} />
          <Divider />
          <div style={{paddingTop: "1em", paddingBottom: "1em", width: "100%", height: "calc(100% - 5em)"}}>
            {kpFinTable}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default LogistRequestSkuTable;
