export const TP_POOL_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Event date",
    accessorKey: "eventDate",
    size: 70,
    isSortable: true,
  },
  {
    header: "TP №",
    accessorKey: "tpNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Transformed TP №",
    accessorKey: "transformedTpNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Event",
    accessorKey: "eventType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Event place",
    accessorKey: "eventPlace",
    size: 70,
    isSortable: true,
  },
  {
    header: "Planned Event",
    accessorKey: "plannedEvent",
    size: 70,
    isSortable: true,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 100,
  },

  {
    header: "Request №",
    accessorKey: "requestNumber",
    size: 100,
    isSortable: true,
  },
]);
