import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useToaster from "../../../../utilities/hooks/useToaster";
import {cleanUpAllStockPools, cleanUpAppointedStockPools} from "../../redux/LogistSlice";
import {cancelAllStockPoolAppointments, distributeStockPools, fetchAllStockPoolsNew} from "../../redux/operations";
import {selectAppointedStockPools, selectIsLogistLoading} from "../../redux/selectors";

const useTPRequestDistributionModal = ({onModalClose}) => {
  const dispatch = useDispatch();
  const Toaster = useToaster();

  const [selectedSP, setSelectedSP] = useState([]);
  const [selectedForCancel, setSelectedForCancel] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const isLogistLoading = useSelector(selectIsLogistLoading);
  const appointedStockPools = useSelector(selectAppointedStockPools);

  const areStockPoolsSelected = selectedSP?.length !== 0;
  const areBtnsDisabled = !appointedStockPools || appointedStockPools?.length === 0;

  //cleaning appointments on window reload
  useEffect(() => {
    function handleBeforeUnload() {
      dispatch(cancelAllStockPoolAppointments());
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(cancelAllStockPoolAppointments());
  }, []);

  useEffect(() => {
    if (selectedSP.length === 0) {
      dispatch(fetchAllStockPoolsNew({window: "Incoming"}));
    }
  }, []);

  function cleanModalData() {
    dispatch(cleanUpAppointedStockPools());
    dispatch(cleanUpAllStockPools());
  }

  function addToProcessing() {
    dispatch(distributeStockPools()).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Distributed successfully");
        cleanModalData();
        onModalClose();
      }
    });
  }

  function onCancel() {
    dispatch(cancelAllStockPoolAppointments()).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllStockPoolsNew({window: "Incoming", ...selectedFilters}));
        cleanModalData();
        Toaster.toastSuccess("Canceled");
      }
    });
  }

  return {
    selectedSP,
    setSelectedSP,
    areStockPoolsSelected,
    areBtnsDisabled,
    addToProcessing,
    isLogistLoading,
    selectedForCancel,
    setSelectedForCancel,
    onCancel,
    selectedFilters,
    setSelectedFilters,
  };
};

export default useTPRequestDistributionModal;
