export const CHECK_SKU_STORAGE_MODAL_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "SKU",
    accessorKey: "article",
    size: 100,
    isSortable: true,
  },
  {
    header: "Brand c",
    accessorKey: "brandName",
    size: 100,
    isSortable: true,
  },
  {
    header: "Group c",
    accessorKey: "group",
    size: 100,
    isSortable: true,
  },
  {
    header: "Type c",
    accessorKey: "type",
    size: 100,
    isSortable: true,
  },
  {
    header: "Nomenclature",
    accessorKey: "skuName",
    size: 100,
    isSortable: true,
  },
  {
    header: "Finishing",
    accessorKey: "finishing",
    size: 100,
    isSortable: true,
  },
  {
    header: "Color",
    accessorKey: "color",
    size: 100,
    isSortable: true,
  },
  {
    header: "Price",
    accessorKey: "price",
    size: 100,
    isSortable: true,
  },
  {
    header: "Quantity short",
    accessorKey: "amount",
    size: 100,
    isSortable: true,
  },
  {
    header: "Total sum",
    accessorKey: "totalSum",
    size: 100,
    isSortable: true,
  },
  {
    header: "Length mm",
    accessorKey: "length",
    size: 100,
    isSortable: true,
  },
  {
    header: "Width mm",
    accessorKey: "width",
    size: 100,
    isSortable: true,
  },
  {
    header: "Height mm",
    accessorKey: "height",
    size: 100,
    isSortable: true,
  },
  {
    header: "Weight kg",
    accessorKey: "weight",
    size: 100,
    isSortable: true,
  },
  {
    header: "Area m2",
    accessorKey: "area",
    size: 100,
    isSortable: true,
  },
  {
    header: "Volume m3",
    accessorKey: "volume",
    size: 100,
    isSortable: true,
  },
  {
    header: "On stock",
    accessorKey: "onStock",
    size: 100,
    isSortable: true,
  },
  {
    header: "Available",
    accessorKey: "available",
    size: 100,
    isSortable: true,
  },
  {
    header: "In order ",
    accessorKey: "inOrder",
    size: 100,
    isSortable: true,
  },
  {
    header: "In request",
    accessorKey: "inRequest",
    size: 100,
    isSortable: true,
  },
  {
    header: "Proforma",
    accessorKey: "proforma",
    size: 100,
    isSortable: true,
  },
  {
    header: "TP №",
    accessorKey: "tpNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Reservation",
    accessorKey: "reservation",
    size: 100,
    isSortable: true,
  },
  {
    header: "CO reservation",
    accessorKey: "coReservation",
    size: 100,
    isSortable: true,
  },
]);
