import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getCheckedRequestIds, getCurrentNote, getThirdStatusRequests} from "../../../RequestsSlice";

const useStatus4RequestsNotes = () => {
  const {t} = useTranslation();

  //getting redux state
  const checkedRequestIds = useSelector(getCheckedRequestIds);
  const trirdStatusRequests = useSelector(getThirdStatusRequests);
  const newNote = useSelector(getCurrentNote) ?? "";

  //if no status 3 requests checked
  if (checkedRequestIds.length === 0) return t("No request selected");

  const status4RequestsNotes = trirdStatusRequests
    .filter(request => checkedRequestIds.includes(request.requestId))
    .map(filteredRequest => filteredRequest.note + " // " + newNote)
    .join("\n");

  return status4RequestsNotes;
};

export default useStatus4RequestsNotes;
