import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {getSuccess, getWarn} from "../../../../utilities/toasts";
import {
  deleteRequest,
  deselectCurrentOrder,
  deselectCurrentRequest,
  getCurrentRequestSKUs,
  getRequestInfo,
  getSplitedOrder,
  transferSkuToRequest,
  updateRequest,
} from "../../RequestsSlice";
import {SPLIT_TYPES} from "../utils/splitTypes";

import SplitUniModal from "./SplitUniModal";

const SplitOrderSku = ({setModalActive, active, setCouldModalClose, updateAndCleanAfterRequest, isStatus}) => {
  const orders = useSelector(getSplitedOrder);
  const reqInfo = useSelector(getRequestInfo);

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const [secondRequestSku, setSecondRequestSku] = useState([]);

  const onUnloadMethod = () => {
    return dispatch(deleteRequest(orders.second.requestId)).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        getWarn(t("Order splitting was cancelled"));
      }
    });
  };

  const initialValues = useMemo(() => {
    return {
      requestNumber: !SPLIT_TYPES.NORMAL_ORDER ? orders?.first : reqInfo.requestNumber,
      secondRequestNumber: orders?.second?.requestNumber || "",
      dateD: orders?.second?.requestDates[0].plannedDate || "",
      note: "",
    };
  }, [orders]);

  const onSeparate = note => {
    if (note !== "") {
      dispatch(updateRequest({requestId: orders.second.requestId, note: note}));
    }
    dispatch(
      transferSkuToRequest({
        requestId: orders.second.requestId,
        data: {
          skuRequestIds: secondRequestSku.map(req => {
            return req.skuRequestId;
          }),
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getSuccess(t("Request was splitted successfully"));
        setModalActive(false);
        dispatch(deselectCurrentOrder());
        dispatch(deselectCurrentRequest());
        updateAndCleanAfterRequest();
      }
    });
  };

  return (
    <div className="wh100">
      <SplitUniModal
        setCouldModalClose={setCouldModalClose}
        isStatus={isStatus}
        selector={getCurrentRequestSKUs}
        initialValues={initialValues}
        onUnloadMethod={onUnloadMethod}
        onSeparate={onSeparate}
        secondRequestSku={secondRequestSku}
        setSecondRequestSku={setSecondRequestSku}
      />
    </div>
  );
};

export default SplitOrderSku;
