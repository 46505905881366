export const FACTORY_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "index",
    size: 25,
  },
  {
    header: "Factory",
    accessorKey: "name",
    size: 75,
  },
  {
    header: "Country",
    accessorKey: "country",
    size: 75,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    size: 55,
  },
  {
    header: "cont pers",
    accessorKey: "contactPerson",
    size: 75,
  },
  {
    header: "e-mail",
    accessorKey: "email",
    size: 120,
  },
  {
    header: "tel",
    accessorKey: "phone",
    size: 80,
  },
  {
    header: "terms",
    accessorKey: "terms",
    size: 120,
  },
  {
    header: "cond",
    accessorKey: "conditions",
    size: 100,
  },
  {
    header: "Notes",
    accessorKey: "notes",
    size: 150,
  },
  {
    header: "Adress",
    accessorKey: "address",
    size: 100,
  },
]);

export const CONTACTS_EDIT_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "index",
    size: 25,
  },
  {
    header: "cont pers",
    accessorKey: "name",
    // size: 100,
  },
  {
    header: "tel",
    accessorKey: "phone",
    // size: 100,
  },
  {
    header: "notes to cp",
    accessorKey: "cpNotes",
    // size: 100,
  },
  {
    header: "Type",
    accessorKey: "contactType",
    // size: 100,
  },
]);

export const CONDITIONS_DELIVERY_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "index",
    size: 25,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    // size: 100,
  },
  {
    header: "d note",
    accessorKey: "discountNote",
    // size: 100,
  },
  {
    header: "terms",
    accessorKey: "terms",
    // size: 100,
  },
  {
    header: "t note",
    accessorKey: "termsNote",
    // size: 100,
  },
  {
    header: "t cond",
    accessorKey: "termsCondition",
    // size: 100,
  },
]);

export const COMMON_FACTORY_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "index",
    size: 25,
  },
  {
    header: "Factory",
    accessorKey: "factoryName",
    size: 75,
  },
  {
    header: "Country",
    accessorKey: "country",
    size: 50,
  },
  {
    header: "cont pers",
    accessorKey: "contactPersonName",
    size: 100,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    size: 50,
  },
  {
    header: "e-mail",
    accessorKey: "email",
    size: 75,
  },
  {
    header: "tel",
    accessorKey: "phone",
    size: 75,
  },
  {
    header: "terms",
    accessorKey: "terms",
    size: 120,
  },
  {
    header: "cond",
    accessorKey: "termsCondition",
    size: 100,
  },
  {
    header: "Notes",
    accessorKey: "termsNote",
    size: 100,
  },
  {
    header: "Adress",
    accessorKey: "address",
    size: 100,
  },
]);

export const NEW_CONTACTS_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "index",
    size: 25,
  },
  {
    header: "P#",
    accessorKey: "phone",
    // size: 100,
  },
  {
    header: "e-mail",
    accessorKey: "email",
    // size: 100,
  },
]);

export const CLEAN_FILTERS = {
  factoryId: "",
  countryId: "",
  contactName: "",
  phone: "",
  email: "",
  condition: "",
  note: "",
  conditionsStatus: "",
};

export const FILTER_STORAGE_NAME = "factory_filters";
