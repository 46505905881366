import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import * as Yup from "yup";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import PageContainer from "../../../conteiners/PageContainer";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import CreateFactoryConditions from "../factoryModals/CreateFactoryConditions";
import CreateNewFactory from "../factoryModals/CreateNewFactory";
import EditFactoryConditions from "../factoryModals/EditFactoryConditions";

import {CLEAN_FILTERS} from "./utils/constants";
import useFactoryCCService from "./utils/hooks/getFactoryCCService";
import FactoryFiltersBase from "./FactoryFilters";
import FactoryInfoFields from "./FactoryInfoFields";

import "./factory.scss";

const FactoryCC = () => {
  const {t} = useTranslation();

  const [newFactoryModalActive, setNewFactoryModalActive] = useState(false);
  const [editCondModalActive, setEditCondModalActive] = useState(false);
  const [createCondModalActive, setCreateCondModalActive] = useState(false);

  const {
    initialValues,
    columns,
    factoryCCTableData,
    currentFactoryId,
    onFactoryConditionTableRowSelect,
    currentTableSelectedFactory,
    onNewFactoryModalClose,
    onEditCondFactoryModalClose,
    onCreateCondModalClose,
    areFactoryCCBtnsDisabled,
    filters,
    setFilters,
    isCurrentInFiltered,
  } = useFactoryCCService();

  return (
    <PageContainer title={t("factory cc")}>
      <div
        className="form_wrapper"
        style={{
          paddingBottom: "1em",
        }}
      >
        <FactoryFiltersBase filters={filters} setFilters={setFilters} />
        <div className="border border_gap2"></div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({})}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
        >
          <FactoryInfoFields />
        </Formik>
        <ReactTable
          defaultData={factoryCCTableData}
          columns={columns}
          onSelect={onFactoryConditionTableRowSelect}
          current={isCurrentInFiltered ? currentTableSelectedFactory : ""}
          tableClassName="factoryCCTable"
        />
        <div className="factory_buttons">
          <FunctionButton
            name="edit factory cond"
            autoWidth
            onClick={() => {
              setEditCondModalActive(true);
            }}
            disabled={areFactoryCCBtnsDisabled}
          />
          <FunctionButton
            name="Add new factory cond"
            autoWidth
            onClick={() => {
              setCreateCondModalActive(true);
            }}
            disabled={areFactoryCCBtnsDisabled}
          />
          <FunctionButton
            name="Create factory"
            autoWidth
            onClick={() => {
              setFilters(CLEAN_FILTERS);
              setNewFactoryModalActive(true);
            }}
          />
        </div>
        <ModalForm
          closable={true}
          Component={EditFactoryConditions}
          label={t("Edit Factory conditions and contacts")}
          active={editCondModalActive}
          setModalActive={setEditCondModalActive}
          onModalClose={onEditCondFactoryModalClose}
        />
        <ModalForm
          closable={true}
          Component={CreateFactoryConditions}
          label={t("Add new Factory conditions and contacts")}
          active={createCondModalActive}
          setModalActive={setCreateCondModalActive}
          onModalClose={onCreateCondModalClose}
        />
        <ModalForm
          closable={true}
          Component={CreateNewFactory}
          label={t("Create factory modal")}
          active={newFactoryModalActive}
          setModalActive={setNewFactoryModalActive}
          onModalClose={onNewFactoryModalClose}
        />
      </div>
    </PageContainer>
  );
};

export default FactoryCC;
