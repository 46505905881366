import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import MeasurementBlock from "../../../Requests/RequestsInProgress/ReceiptGoodsModal/MeasurementBlock";
import {selectTransportDoc} from "../../../Requests/RequestsSlice";
import FactoryInvoiceAndPackBlock from "../../FactoryBlock/utils/FactoryInvoiceAndPackBlock";

const DocAndPackBlockBase = ({currentStockPool}) => {
  const currentTransportDoc = useSelector(selectTransportDoc);
  const {setValues} = useFormikContext();

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      length: "",
      width: "",
      height: "",
      volume: "",
      weight: "",
      note: "",
      quantity: "",
      overallSizeUnitId: "",
      volumeUnitId: "",
      weightUnitId: "",
      place: "",
      onDocDate: "",
      docNumber: "",
      docType: "",
    }));
  }, [currentStockPool?.stockPoolId]);

  return (
    <div>
      <FactoryInvoiceAndPackBlock
        currentTransportDoc={currentTransportDoc}
        element={currentStockPool}
        // docsTableColumns={docsTableColumns}
        isNumberShown={false}
        headerClassName="underlined"
      >
        {/* <NoteInput name="docNotes" label={t("Note")} action={} deleteIcon={true} style={{marginBottom: 0}} /> */}
      </FactoryInvoiceAndPackBlock>

      <div className="w100">
        <p className="header-block underlined">{`${t("Shipment")}:`}</p>
        <MeasurementBlock tHeight={"7em"} element={currentStockPool} canDelete={false} noLabel />
      </div>
    </div>
  );
};

const DocAndPackBlock = withFormik({validateOnChange: true})(DocAndPackBlockBase);
export default DocAndPackBlock;
