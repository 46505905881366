import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getRequestRegistrFilters} from "./../RequestsSlice";

const useRequestFilters = () => {
  const {
    i18n: {language},
  } = useTranslation();

  const filters = useSelector(getRequestRegistrFilters);

  const brandsOptions = useMemo(() => {
    return (
      filters?.brands.map(item => {
        return {title: item.name, value: item.brandId};
      }) || []
    );
  }, [filters]);

  const countryOptions = useMemo(() => {
    return (
      filters?.countries.map(item => {
        return {title: item.alphaTwoCode, value: item.countryId};
      }) || []
    );
  }, [filters]);

  const requestStatusOptions = useMemo(() => {
    return (
      filters?.statuses.map(item => {
        return {title: item[language === "en" ? "engName" : "ukrName"], value: item.statusId};
      }) || []
    );
  }, [filters, language]);

  const coManagersOptions = useMemo(() => {
    return (
      filters?.coManagers.map(item => {
        const name =
          language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName;
        return {
          title: name,
          value: item.managerCoId,
        };
      }) || []
    );
  }, [filters, language]);

  const feaManagersOptions = useMemo(() => {
    return (
      filters?.feaManagers.map(item => {
        const name =
          language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName;
        return {
          title: name,
          value: item.managerFeaId,
        };
      }) || []
    );
  }, [filters, language]);

  return {brandsOptions, feaManagersOptions, coManagersOptions, requestStatusOptions, countryOptions};
};

export default useRequestFilters;
