import {useEffect, useMemo, useState} from "react";
import accounting from "accounting";
import {useField} from "formik";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

const NumberInput = ({
  label,
  width = "67%",
  className = "",
  question = null,
  autolabel,
  readOnly,
  labelStyles,
  labelWrap,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState("");

  const {setValue: setRawValue} = helpers;

  useEffect(() => {
    if (field.value === "") {
      setValue("");
    } else setValue(accounting.formatNumber(field.value, options));
  }, [field.value]);

  const options = useMemo(() => {
    return {
      // symbol : "€",
      decimal: ".",
      thousand: "'",
      precision: 2,
      format: "%s%v",
    };
  }, []);

  const handleInputChange = e => {
    const value = e.target.value;
    if (value < 0 || value === "-") {
      return;
    }
    setValue(value);
  };

  return (
    <div className={`project_input ${labelWrap ? "" : "labelWrap"}`}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className}>
          <input
            readOnly={readOnly}
            autoComplete="off"
            value={value}
            onBlur={() => {
              setRawValue(value);
            }}
            onChange={handleInputChange}
            type="number"
          />
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} /> : null}
    </div>
  );
};

NumberInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  props: PropTypes.array,
  className: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  autolabel: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default NumberInput;
