import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Field, Form, useFormikContext, withFormik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import ModCheckList from "../../CheckLists/ModCheckList";
import WarehousesForm from "../../common/Warehouses/WarehousesForm";

import InTransportPoolTables from "./tables/InTransportPoolTables";
import NotInTPTable from "./tables/NotInTPTable";
import useFactoryCreatingTPMod from "./useFactoryCreatingTPMod";

const CreatingTPModBase = ({selectedFactory, identifier, selectedWarehouse}) => {
  const {t} = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    setSelectedSp,
    addAndDistribute,
    addStockPoolToCargo,
    cargoOptions,
    curentCargoSP,
    setCurentCargoSP,
    removeSPFromCargo,
    selectedSP,
    defineFinalDestination,
    isFinalDestination,
  } = useFactoryCreatingTPMod(selectedFactory, selectedWarehouse, identifier, setModalOpen);

  const {values} = useFormikContext();

  return (
    <>
      <Form className="w100">
        <div className="border_bottom_container fdr pb jcc mt" style={{gap: "7em"}}>
          <TextInput
            label={identifier === "factory" ? t("Factory") : t("Warehouse")}
            name={identifier === "factory" ? "factory" : "warehouse"}
            width="12em"
            labelStyles={{width: "5em"}}
            readOnly
          />
          <TextInput label={t("Total volume")} name="totalVolume" width="12em" labelStyles={{width: "11em"}} readOnly />
          <TextInput label={t("Total weight")} name="totalWeight" width="12em" labelStyles={{width: "10em"}} readOnly />
        </div>

        <div className="fdr aibl gap5">
          <div>1. </div>
          <div className="mb mt label underline"> {t("Stock Pool")}</div>
        </div>

        <NotInTPTable
          setSelectedSp={setSelectedSp}
          selectedFactory={selectedFactory}
          selectedWarehouse={selectedWarehouse}
          setModalOpen={setModalOpen}
        />

        <div className="mt fc nowrap g15">
          <div className="fcCols">
            <label className=" autolabel label mb">
              <Field type="radio" name="type" value="newTP" style={{marginRight: "1em"}} />
              {t("Create new TP")}
            </label>

            <label className=" autolabel label mt">
              <Field type="radio" name="type" value="TP" style={{marginRight: "1em"}} />
              {t("Add to TP")}
            </label>
          </div>
          <div style={{marginLeft: "1em", paddingTop: "4em", width: "15em"}} className=" h100 flex_form ">
            {/* <TextInput label={t("TP №")} name="transportPoolNumber" width="15em" className="mb" readOnly={true} /> */}
            <Select
              label={t("TP №")}
              name="transportPoolId"
              options={cargoOptions || []}
              readOnly={values?.type !== "TP"}
              generalClassName="fGrow"
              makeEffect={defineFinalDestination}
              autolabel
            />
          </div>
          <div style={{width: "40em", marginRight: "5em", marginLeft: "5em"}}>
            <WarehousesForm />
            <div className="fc nowrap w100 jcfe mt ">
              <span style={{marginRight: "1em"}}>{"Final Destination"}</span>
              <input type="checkbox" checked={isFinalDestination} onChange={() => {}} disabled />
            </div>
          </div>
          <div style={{alignItems: "flex-start", gap: "20px"}} className="fc fd ">
            <FunctionButton
              name="Add the marked ones to a new TP"
              onClick={addAndDistribute}
              autoWidth
              disabled={
                !(
                  selectedSP.length > 0 &&
                  values?.type === "newTP" &&
                  ((values?.warehouseId && values?.warehouseId !== "") ||
                    (values?.warehouseAddressId && values?.warehouseAddressId !== ""))
                )
              }
            />
            <FunctionButton
              name="Add marked ones to a TP"
              onClick={addStockPoolToCargo}
              autoWidth
              disabled={
                !(
                  selectedSP.length > 0 &&
                  values?.type === "TP" &&
                  values?.transportPoolId !== "" &&
                  ((values?.warehouseId && values?.warehouseId !== "") ||
                    (values?.warehouseAddressId && values?.warehouseAddressId !== ""))
                )
              }
            />
          </div>
        </div>

        <div className="fdr aibl gap5">
          <div>2. </div>
          <div className="mb mt label underline"> {t("Transport Pool")}</div>
        </div>

        <InTransportPoolTables
          onCargoSelect={setCurentCargoSP}
          selectedFactory={selectedFactory}
          selectedWarehouse={selectedWarehouse}
          setModalOpen={setModalOpen}
        />
        {identifier === "factory" ? (
          <div className="fdr jcfe" style={{gap: "5em", marginRight: "2em"}}>
            <FunctionButton name="Add delivery location" autoWidth disabled />
            <FunctionButton name="Remove SP from TP" onClick={removeSPFromCargo} autoWidth disabled={!curentCargoSP} />
            <FunctionButton name="Cancel" autoWidth disabled />
          </div>
        ) : (
          <div className="jcfs jcfe" style={{marginLeft: "22em"}}>
            <FunctionButton name="Remove SP from TP" onClick={removeSPFromCargo} autoWidth disabled={!curentCargoSP} />
          </div>
        )}
      </Form>
      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
    </>
  );
};
const CreatingTPMod = withFormik({
  handleSubmit: () => {},
  mapPropsToValues: () => ({
    factory: "",
    totalVolume: "",
    totalWeight: "",
    type: "newTP",
    transportPoolNumber: "",
    transportPoolId: "",
    warehouseName: "",
    warehouseId: "",
    warehouseAddressId: "",
  }),
  enableReinitialize: true,
})(CreatingTPModBase);

export default CreatingTPMod;
