import {useCallback, useState} from "react";

import ModalWithTabs from "../ModalWithTabs";

export const ModalWithTabExample2 = () => {
  const [modalShow, setModalShow] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabsCounter, settabsCounter] = useState(1);

  const onTabOpen = () => {
    setTabs(prev => [
      ...prev,
      {
        label: "Tab" + tabsCounter,
        index: prev.length + 1,
      },
    ]);
    settabsCounter(i => i + 1);
  };

  const modalChildrensRender = useCallback(() => {
    return tabs.map((req, i) => <ExtaTab text={req.label} key={i} index={i} />);
  }, [tabs]);

  return (
    <div style={{width: "100%", marginTop: "100px"}}>
      <button onClick={() => setModalShow(true)}>modal</button>

      <ModalWithTabs
        closable={true}
        Component={MainTab}
        label={"MainTab"}
        active={modalShow}
        setModalActive={setModalShow}
        modalTabs={tabs}
        setModalTabs={setTabs}
        closableTabs={true}
        onTabOpen={onTabOpen}
      >
        {modalChildrensRender()}
      </ModalWithTabs>
    </div>
  );
};

const ExtaTab = ({text}) => {
  return <p> {text} </p>;
};

const MainTab = ({onTabOpen}) => {
  return (
    <div>
      <p> Main Tab</p>
      <button onClick={onTabOpen}>open new Tab</button>
    </div>
  );
};
