import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import PageContainer from "../../conteiners/PageContainer";
import FullRequestFiltersForm from "../Filters/filtersVariety/FullRequestFilter";
import withRequestWithFilter from "../hoc/RequestWithFilterHOC";
import RequestTable from "../RequestRegister/RequestTable/RequestTable";
import {getLoadingRequest} from "../RequestsSlice";

import {REQ_WINDOW_TYPES} from "./utils/functions/requestsFiltersManager";
import useRequests from "./utils/hooks/useRequests";
import InProgressSector from "./InProgressSector";

import "./InProgress.scss";

const RequestsInProgressBase = ({updateFetch}) => {
  const {t} = useTranslation();

  const requestLoading = useSelector(getLoadingRequest);

  const {tableData, onRequestSelect} = useRequests();

  return (
    <PageContainer title={t("Requests in progress")}>
      <div className="form_wrapper">
        <FullRequestFiltersForm window={REQ_WINDOW_TYPES.INPROGRESS} />
        <RequestTable defaultData={tableData} onSelect={onRequestSelect} requestLoading={requestLoading} />
        <InProgressSector showButton={true} />
      </div>
    </PageContainer>
  );
};
const RequestsInProgress = withRequestWithFilter(RequestsInProgressBase, REQ_WINDOW_TYPES.INPROGRESS);

export default RequestsInProgress;
