import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getInfo} from "../../../../utilities/toasts";
import PageContainer from "../../../conteiners/PageContainer";
import ModalForm from "../../../Modal/FormModal";
import ForVarificationFilterForm from "../../../Requests/Filters/filtersVariety/ForVarificationFilter";
import withRequestWithFilter from "../../../Requests/hoc/RequestWithFilterHOC";
import {selectCurrentProform} from "../../../Requests/Proform/redux/selectors/ProformSelectors";
import {REQ_WINDOW_TYPES} from "../../../Requests/RequestsInProgress/utils/functions/requestsFiltersManager";
import {getRequest, getRequestInfo, markAsInspectedRequestSku} from "../../../Requests/RequestsSlice";
import VerificationModal from "../VerificationModal/VerificationModal";

import RAndVTables from "./BlocksReceiptAndVerif/RAndVTables";
import RAndVWithPdf from "./BlocksReceiptAndVerif/RAndVWithPdf";

import "./rAndV.scss";

const ReceiptAndVerificationPfBase = () => {
  const {t} = useTranslation();
  const [verifModalActive, setVerifModalActive] = useState(false);
  const [checkedSku, setCheckedSku] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentPf = useSelector(selectCurrentProform);
  const currReq = useSelector(getRequestInfo);

  useEffect(() => {
    setCheckedSku([]);
  }, [currReq]);

  const inspect = () => {
    if (checkedSku?.length > 0)
      dispatch(markAsInspectedRequestSku({skuRequestIds: checkedSku})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          getInfo(t("marked skus"));
          dispatch(getRequest(currReq.requestId));
        }
      });
  };

  return (
    <PageContainer title={t("rec and ver")}>
      <div className="form_wrapper" style={{padding: "0.5em"}}>
        <div>
          <ForVarificationFilterForm window={REQ_WINDOW_TYPES.VARIFICATION} />
          <div className="border border_gap"></div>
          <RAndVTables currentPf={currentPf} currReq={currReq} />
          <div className="border border_gap"></div>
        </div>
        <RAndVWithPdf setCheckedSku={setCheckedSku} />

        <div className="rv_buttons">
          <FunctionButton name="marked skus" autoWidth disabled={checkedSku.length < 1} onClick={inspect} />
          <FunctionButton
            name="enter add costs"
            autoWidth
            onClick={() => {
              setVerifModalActive(true);
            }}
          />
            <FunctionButton
            name="return to prof"
            autoWidth
            onClick={() => {
              navigate("/ba3a/fea/reqInProgress");
            }}
          />      
        </div>
      </div>
      <ModalForm
        closable={false}
        Component={VerificationModal}
        label={t("verif modal")}
        active={verifModalActive}
        setModalActive={setVerifModalActive}
        className="v_modal"
      />
    </PageContainer>
  );
};
const ReceiptAndVerificationPf = withRequestWithFilter(ReceiptAndVerificationPfBase, REQ_WINDOW_TYPES.VARIFICATION);

export default ReceiptAndVerificationPf;
