// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.request_header {
  display: flex;
  flex-direction: row;
  align-items: self-end;
  gap: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.request_table_wrapper {
  height: 100%;
  margin-bottom: 10px;
}
.request_table_wrapper .export_btn {
  position: absolute;
  bottom: 1em;
  left: 40%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Requests/RequestCreation/requestCreation.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,QAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAEJ","sourcesContent":[".button-wrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\n.request_header {\n  display: flex;\n  flex-direction: row;\n  align-items: self-end;\n  gap: 3px;\n  margin-top: 15px;\n  margin-bottom: 15px;\n}\n\n.request_table_wrapper {\n  height: 100%;\n  margin-bottom: 10px;\n  .export_btn {\n    position: absolute;\n    bottom: 1em;\n    left: 40%;\n    // width: 100%;\n    // display: flex;\n    // justify-content: center;\n    // z-index: ;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
