import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import {TextAreaInput} from "../../../../Inputs";

const NameBlock = () => {
  const {t} = useTranslation();
  const {values, setFieldValue} = useFormikContext();
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (option, setFieldValue) => {
    if (option === "name") {
      setFieldValue("nameCheck", true);
      setFieldValue("nameEngCheck", false);
    } else {
      setFieldValue("nameCheck", false);
      setFieldValue("nameEngCheck", true);
    }
    setSelectedOption(option);
  };

  return (
    <div className="name-block">
      <div className="name-check-block">
        <label className="name-label">
          <input
            type="radio"
            name="nameCheck"
            value="name"
            checked={selectedOption === "name" || values.nameCheck}
            onChange={() => handleOptionChange("name", setFieldValue)}
            style={{marginRight: "9px"}}
          />
          {t("Nomenclature")}
        </label>
        <label className="name-label">
          <input
            type="radio"
            name="nameEngCheck"
            value="nameEng"
            checked={selectedOption === "nameEng" || values.nameEngCheck}
            onChange={() => handleOptionChange("nameEng", setFieldValue)}
            style={{marginRight: "9px"}}
          />
          {t("Nomenclature (eng)")}
        </label>
      </div>

      <TextAreaInput name={values.nameCheck ? "name" : "nameEng"} width="100%" height="4em" />
    </div>
  );
};

export default NameBlock;
