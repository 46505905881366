export default function formSearchParams(paramsObject) {
  if (!paramsObject) return "";
  const notEmptyParamsKeys = Object.keys(paramsObject).filter(key => Boolean(paramsObject[key]));
  const notEmptyParams = notEmptyParamsKeys.reduce((acc, key) => ({...acc, [key]: paramsObject[key]}), {});

  return Object.keys(notEmptyParams).reduce((acc, key) => {
    if (!acc) {
      return `?${key}=${paramsObject[key]}`;
    }
    return acc + `&${key}=${paramsObject[key]}`;
  }, "");
}
