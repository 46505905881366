import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import FilterButton from "../../../../utilities/Buttons/FilterBtn/FilterButton";
import {StatusRectangleBtn} from "../../../../utilities/Buttons/StatusFunctionButton/StatusRectangleBtn";
import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import QuestionItem from "../../../../utilities/QuestionItem";
import ReadOnlyFields from "../../../hoc/ReadOnlyFields";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useRequestFilters from "../../../Requests/Filters/filters.hook";

const ProformaPaymentsRegistrFilter = () => {
  const {t} = useTranslation();

  const {brandsOptions, feaManagersOptions, coManagersOptions} = useRequestFilters();

  const [buttonColor, setButtonColor] = useState(STATUS_COLORS.GREEN);

  const initialValues = useMemo(() => {
    return {
      request: "",
      order: "",
      managerFea: "",
      factory: "",
      managerCo: "",
      dateStart: "",
      dateEnd: "",
      note: "",
    };
  }, []);

  const filter = () => {};
  const delFilter = () => {};

  return (
    // <div>
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
      onSubmit={values => {}}
    >
      <Form className="" style={{width: "100%"}}>
        <div
          style={{
            gap: "1em",
            alignItems: "flex-start",
            width: "100%",
            opacity: "60%",
          }}
          className="fc jcfs autolabel nowrap"
        >
          <div className="flex_container" style={{flexDirection: "column"}}>
            <FilterButton onFilter={filter} delFilter={delFilter} />
            <StatusRectangleBtn
              onClick={() => {
                // setOrderingModalActive(true);
              }}
              color={buttonColor}
            />
          </div>
          <div
            className="grid2_5 fGrow"
            // style={{gridTemplate: "1fr 1fr / 1fr 1fr 1fr 1fr auto", width: "100%", columnGap: "10px"}}
          >
            <ReadOnlyFields>
              <TextInput label={t("Requests") + ":"} name="request" width="100%" />
              <TextInput label={t("Order number")} name="order" width="100%" />
              <Select label={t("CO Manager")} name="managerCo" options={coManagersOptions} width="100%" multi={true} />
              <Select label={t("Factory")} name="factory" options={brandsOptions} width="100%" multi={true} />
              <div className="period-wrapper">
                <div className="fc gap5">
                  <QuestionItem title={"Select date"} />
                  {t("Period")}
                </div>
                <div className="request-date-wrapper">
                  <DateInput label={t("From")} name="dateStart" />
                  <DateInput label={t("To")} name="dateEnd" />
                </div>
              </div>
              <TextInput label="Prof №" name="pf" width="100%" />
              <TextInput
                label={t("Invoice №")}
                name="invoice"
                width="100%"
                style={{width: "100%", marginRight: "5.7em"}}
              />
              <Select
                label={t("FEA Manager")}
                name="managerFea"
                options={feaManagersOptions}
                multi={true}
                width="100%"
              />
              <TextInput
                label={t("Notes")}
                name="notes"
                style={{width: "20em"}}
                placeholder={t("start typing a note")}
              />
            </ReadOnlyFields>
          </div>
        </div>
      </Form>
    </Formik>
    // </div>
  );
};

export default ProformaPaymentsRegistrFilter;
