import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import useNameLangService from "../../../../../../../utilities/hooks/useNameLangService";
import useColumns from "../../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectCurrentPaymentPayDoc} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";
import {PAYMENT_TO_FACTORY_MODAL_COLUMNS} from "../../../utils/constants";

const useOnPaymentBlockService = () => {
  const {t} = useTranslation();
  const nameLang = useNameLangService();

  const currentPaymentPayDoc = useSelector(selectCurrentPaymentPayDoc);
  const {docId, docNumber = "", docType = "", note = ""} = currentPaymentPayDoc ?? {};

  const onPaymentTableColumns = useColumns(PAYMENT_TO_FACTORY_MODAL_COLUMNS);

  const onPaymentTableData = currentPaymentPayDoc
    ? [{id: docId, number: 1, docNumber, docType: docType?.["name" + nameLang] || "", note: note || t("not specified")}]
    : [];
  return {onPaymentTableColumns, onPaymentTableData};
};

export default useOnPaymentBlockService;
