import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import {makeRegisterRqstsTableData, makeRegisterSPTableData} from "../common/functions";
import {setFetchedStockPool} from "../redux/LogistSlice";
import {fetchAllStockPoolsNew, fetchStockPoolFilters} from "../redux/operations";
import {
  selectDistributedRequests,
  selectDistributedStockPools,
  selectFetchedStockPool,
  selectIsLogistLoading,
} from "../redux/selectors";

const useTpListManagerService = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const managersFilterManager = makeFilterManager(LOGIST_WINDOW_TYPES.DISTRIBUTED);
  const savedFilters = managersFilterManager.getFilters();

  useEffect(() => {
    dispatch(setFetchedStockPool(null));
    dispatch(fetchStockPoolFilters({window: "Distributed"}));
    dispatch(fetchAllStockPoolsNew({window: "Distributed", ...savedFilters}));
  }, []);

  const isLoading = useSelector(selectIsLogistLoading);
  const managersStockPools = useSelector(selectDistributedStockPools);
  const managersRequests = useSelector(selectDistributedRequests);
  const selectedSP = useSelector(selectFetchedStockPool);

  const leftSideTableData = makeRegisterSPTableData(managersStockPools, i18n.language);
  const tpListManagerTableData = makeRegisterRqstsTableData(managersRequests, true);
  const areFiltersDisabled = Boolean(selectedSP);

  // const initialValues = {
  //   reqNumber: "",
  //   atFactoryDate: "",
  //   addedToSPDate: "",
  //   addedToTpDate: "",
  //   inTransitDate: "",
  //   inStockDate: "",
  // };

  function onLeftSideTableRowSelect(stockPoolId) {
    setTimeout(() => {
      if (!stockPoolId) {
        dispatch(setFetchedStockPool(null));
        dispatch(fetchAllStockPoolsNew({window: "Distributed", ...savedFilters}));
        return;
      }
      dispatch(setFetchedStockPool(stockPoolId));
      dispatch(fetchAllStockPoolsNew({window: "Distributed", ...savedFilters, stockPoolIds: stockPoolId}));
    }, 100);
  }
  function onTpListManagerTableRowSelect() {}

  function onFactoryBtnClick() {}
  function onTransporterBtnClick() {}
  function onStorageBtnClick() {}
  // function onShowHistoryBtnClick() {}

  function onLogModRqstModalClose() {}
  function onLogModSPCheckModalClose() {}
  function onLogModTrPoolModalClose() {}

  return {
    tpListManagerTableData,
    onTpListManagerTableRowSelect,
    isLoading,
    onFactoryBtnClick,
    onTransporterBtnClick,
    onStorageBtnClick,
    // initialValues,
    // onShowHistoryBtnClick,
    areFiltersDisabled,
    leftSideTableData,
    onLeftSideTableRowSelect,
    onLogModRqstModalClose,
    onLogModSPCheckModalClose,
    onLogModTrPoolModalClose,
    selectedSP,
  };
};

export default useTpListManagerService;
