const formNotes = notes => {
  if (!notes || !Array.isArray(notes)) return "";

  return notes.reduce((acc, current) => {
    if (!acc) return current.note;
    return acc + "\n" + current.note;
  }, "");
};

export default formNotes;
