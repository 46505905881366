import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {SHIPPING_TABLE_COLUMNS} from "./constants";

const useDeliveryInfoService = () => {
  const shippingTableColumns = useColumns(SHIPPING_TABLE_COLUMNS);
  const shippingTableData = [];

  function onSaveBtnClick() {}
  function onDeleteBtnClick() {}

  return {shippingTableColumns, shippingTableData, onSaveBtnClick, onDeleteBtnClick};
};

export default useDeliveryInfoService;
