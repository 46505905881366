import {Formik} from "formik";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import FactoryAdress from "../factoryModalParts/FactoryAdress";
import FactoryCommonTable from "../factoryModalParts/FactoryCommonTable";
import FactoryConditionForm from "../factoryModalParts/FactoryConditionForm";
import FactoryNewCondAndContacts from "../factoryModalParts/FactoryNewCondAndContacts";

import {FACTORY_MODAL_TYPES} from "./utils/constants";
import useFactoryService from "./utils/hooks/getFactoryService";

const CreateFactoryConditions = ({setModalActive}) => {
  const modalType = FACTORY_MODAL_TYPES.CREATE_CONDITIONS;

  const {
    onAddContactBtnClick,
    onAddConditionBtnClick,
    onSaveFactoryBtnClick,
    initialValues,
    onCancelBtnClick,
    isAddConditionBtnDisabled,
    isSaveFactoryAddingsDisabled,
  } = useFactoryService({modalType, setModalActive});

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({})}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
    >
      {({values}) => (
        <div className="wh100">
          <div className="factory_modal_wrapper">
            <div className="factory_address_block">
              <FactoryAdress />
            </div>

            <div className="factory_address_block">
              <FactoryNewCondAndContacts onAddContactBtnClick={onAddContactBtnClick} />
            </div>
          </div>
          <div className="border border_gap"></div>
          <FactoryConditionForm>
            <div className="fcc jcfe" style={{marginTop: "0.5em"}}>
              <FunctionButton
                name="Save d t create"
                autoWidth
                disabled={isAddConditionBtnDisabled}
                onClick={() => onAddConditionBtnClick(values)}
              />
            </div>
          </FactoryConditionForm>
          <FactoryCommonTable onCancelBtnClick={onCancelBtnClick} modalType={modalType}>
            <FunctionButton name="Save ch" disabled={isSaveFactoryAddingsDisabled} onClick={onSaveFactoryBtnClick} />
          </FactoryCommonTable>
        </div>
      )}
    </Formik>
  );
};

CreateFactoryConditions.propTypes = {setModalActive: PropTypes.func};

export default CreateFactoryConditions;
