// import moment from "moment";

// import {dateFormat} from "../../../../utilities/dateFormat";
// import {REQUEST_DATES_IDS, findRequestDateType} from "../../../../utilities/requestDatesIdsService";

// export const getForReadinessService = data => {
//   const {requestDates, requestPostponedDates} = data;

//   const readiness = findRequestDateType(requestDates, REQUEST_DATES_IDS.READINESS_ID);
//   const readinessControl = findRequestDateType(requestDates, REQUEST_DATES_IDS.READINESS_CONTROL_ID);

//   if (requestDates.length <= 1) {
//     return [];
//   }

//   if (requestPostponedDates.length === 0) {
//     return [
//       {
//         id: findRequestDateType(requestDates, "READINESS_ID")?.dateId,
//         readinessDate: dateFormat(findRequestDateType(requestDates, "READINESS_ID")?.plannedDate),
//         actualReadinessDate: dateFormat(findRequestDateType(requestDates, "READINESS_ID")?.actualDate),
//         readinessControlDate: dateFormat(findRequestDateType(requestDates, "READINESS_CONTROL_ID")?.plannedDate),
//         note: findRequestDateType(requestDates, "READINESS_ID")?.note,
//       },
//     ];
//   } else if (requestPostponedDates.length === 2) {
//     return [
//       {
//         id: findRequestDateType(requestDates, "READINESS_ID")?.dateId,
//         readinessDate: dateFormat(findRequestDateType(requestDates, "READINESS_ID")?.plannedDate),
//         actualReadinessDate: dateFormat(findRequestDateType(requestDates, "READINESS_ID")?.actualDate),
//         readinessControlDate: dateFormat(findRequestDateType(requestDates, "READINESS_CONTROL_ID")?.plannedDate),
//         note: findRequestDateType(requestDates, "READINESS_ID")?.note,
//       },
//       {
//         id: requestPostponedDates[0].dateId,
//         readinessDate: dateFormat(requestPostponedDates[0].plannedDate),
//         readinessControlDate: dateFormat(requestPostponedDates[1].plannedDate),
//         note: requestPostponedDates[0].note,
//       },
//     ];
//   } else {
//     return [
//       {
//         id: findRequestDateType(requestDates, "READINESS_CONTROL_ID")?.dateId,
//         readinessDate: dateFormat(findRequestDateType(requestDates, "READINESS_ID")?.plannedDate),
//         actualReadinessDate: dateFormat(findRequestDateType(requestDates, "READINESS_ID")?.actualDate),
//         readinessControlDate: dateFormat(findRequestDateType(requestDates, "READINESS_CONTROL_ID")?.plannedDate),
//         note: findRequestDateType(requestDates, "READINESS_ID")?.note,
//       },
//       {
//         id: requestPostponedDates[1].dateId,
//         readinessControlDate: dateFormat(requestPostponedDates[1].plannedDate),
//         readinessDate: dateFormat(requestPostponedDates[0].plannedDate),
//         note: requestPostponedDates[0].note,
//       },
//       {
//         id: requestPostponedDates[3].dateId,
//         readinessControlDate: dateFormat(requestPostponedDates[3].plannedDate),
//         readinessDate: dateFormat(requestPostponedDates[2].plannedDate),
//         note: requestPostponedDates[2].note,
//       },
//     ];
//   }
// };

import {dateFormat} from "../../../../utilities/dateFormat";
import {findRequestDateType, REQUEST_DATES_IDS} from "../../../../utilities/requestDatesIdsService";

export const getForReadinessService = data => {
  const {requestDates, requestPostponedDates} = data;

  const readiness = findRequestDateType(requestDates, REQUEST_DATES_IDS.READINESS_ID);
  const readinessControl = findRequestDateType(requestDates, REQUEST_DATES_IDS.READINESS_CONTROL_ID);

  if (requestDates.length <= 1) {
    return [];
  }

  if (requestPostponedDates.length === 0) {
    return [
      {
        id: readiness?.dateId,
        readinessDate: dateFormat(readiness?.plannedDate),
        actualReadinessDate: dateFormat(readiness?.actualDate),
        readinessControlDate: dateFormat(readinessControl?.plannedDate),
        note: readiness?.note,
      },
    ];
  } else if (requestPostponedDates.length === 2) {
    return [
      {
        id: readiness?.dateId,
        readinessDate: dateFormat(readiness?.plannedDate),
        actualReadinessDate: dateFormat(readiness?.actualDate),
        readinessControlDate: dateFormat(readinessControl?.plannedDate),
        note: readiness?.note,
      },
      {
        id: requestPostponedDates[0].dateId,
        readinessDate: dateFormat(requestPostponedDates[0].plannedDate),
        readinessControlDate: dateFormat(requestPostponedDates[1].plannedDate),
        note: requestPostponedDates[0].note,
      },
    ];
  } else {
    return [
      {
        id: readinessControl?.dateId,
        readinessDate: dateFormat(readiness?.plannedDate),
        actualReadinessDate: dateFormat(readiness?.actualDate),
        readinessControlDate: dateFormat(readinessControl?.plannedDate),
        note: readiness?.note,
      },
      {
        id: requestPostponedDates[1].dateId,
        readinessControlDate: dateFormat(requestPostponedDates[1].plannedDate),
        readinessDate: dateFormat(requestPostponedDates[0].plannedDate),
        note: requestPostponedDates[0].note,
      },
      {
        id: requestPostponedDates[3].dateId,
        readinessControlDate: dateFormat(requestPostponedDates[3].plannedDate),
        readinessDate: dateFormat(requestPostponedDates[2].plannedDate),
        note: requestPostponedDates[2].note,
      },
    ];
  }
};
