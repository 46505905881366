import React from "react";
import {useSelector} from "react-redux";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {LOGIST_REQ_TABLE} from "../../../../Requests/RequestRegister/RequestTable/utils/constants";
import {selectAppointedRequests} from "../../../redux/selectors";
import {makeStockPoolRqstsTableData} from "../constants";

import styles from "../TPRequestDistributionModal.module.scss";

const DistributeReqTable = () => {
  const appointedRqsts = useSelector(selectAppointedRequests);

  const columns = useColumns(LOGIST_REQ_TABLE);
  const defaultData = makeStockPoolRqstsTableData(appointedRqsts);

  return (
    <div className={" table_xscroll__container "} style={{height: "17em", width: "100%"}}>
      <ReactTable
        defaultData={defaultData}
        columns={columns}
        unchangeableBg
        selectable={false}
        tableClassName={styles.centerTable}
      />
    </div>
  );
};

export default DistributeReqTable;
