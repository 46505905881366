import {useTranslation} from "react-i18next";

import {STATUS_COLORS} from "../../../../../utilities/colorConstants";
import {dateFormat} from "../../../../../utilities/dateFormat";

const usePaymentsData = paymentsRaw => {
  const {t} = useTranslation();
  const noData = t("not specified");

  return [...(paymentsRaw ?? [])].reverse().map((payment, index) => {
    const {paymentId, paymentSum, request, createdAt, paidAt, confirmingDoc} = payment;

    const circleColor = paidAt ? STATUS_COLORS.GREEN : STATUS_COLORS.RED;

    return {
      id: JSON.stringify({
        requestId: request?.requestId,
        paymentId: paymentId,
        requestNumber: request?.requestNumber,
      }),
      number: index + 1,
      date: dateFormat(createdAt),
      dt: noData,
      kt: noData,
      summ: paymentSum || noData,
      isPaid: circleColor,
      paymentPurpose: noData,
      paymentDocument: confirmingDoc?.docNumber || noData,
      proforma: request?.cos ? request?.cos[0]?.coNumber : noData,
      factory: request?.brand?.name,
    };
  });
};

export default usePaymentsData;
