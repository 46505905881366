import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FieldWithSample from "../../../../Inputs/FieldWithSample";

const AllCheckBlock = () => {
  const {t} = useTranslation();
  const {values, setValues} = useFormikContext();

  const {allChar} = values;

  useEffect(() => {
    if (typeof allChar === "boolean") {
      var val = !!allChar;

      setValues({
        ...values,
        complectDescCheck: val,
        nameCheck: val,

        charCheck: val,
        overallView: val,
        productView: val,
        blueprints: val,
        materialCheck: val,
        colorCheck: val,
        productSeriesCheck: val,
        materialTypeCheck: val,
        colorTypeCheck: val,
        batchCheck: val,
        volumeWeightCharCheck: val,

        overallSizeCheck: val,
        weightCheck: val,
        volumeCheck: val,
        palletCheck: val,
        areaCheck: val,
      });
    }
  }, [allChar]);

  return (
    <div style={{marginBottom: "10px"}}>
      <FieldWithSample name="allChar" label={t("Select all")} />
    </div>
  );
};
export default AllCheckBlock;
