const getStatusControlTableData = proforms => {
  if (proforms && proforms.length > 0) {
    return proforms.map((proforma, i) => {
      const statusProfControl = () => {
        switch (true) {
          case !proforma?.isInControl && !proforma?.isApproved && !proforma?.isDeclined:
            return "Just added";
          case proforma?.isInControl && !proforma?.approvals[0]?.isApproved && !proforma?.approvals[0]?.isDeclined:
            return "On control";
          case proforma?.approvals[0]?.isApproved:
            return "Pass control";
          case proforma?.approvals[0]?.isDeclined:
            return "Pass control";
          default:
            return "";
        }
      };

      const {proformaId, proformaNumber} = proforma;

      return {
        number: i + 1,
        id: proformaId,
        pfNumb: proformaNumber,
        statControl: statusProfControl(),
      };
    });
  } else {
    return [];
  }
};

export default getStatusControlTableData;
