import {useMemo} from "react";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

import AdditionalVerificationFields from "./BlocksVerificationModal/AdditionalVerificationFields";
import MainVerificationFields from "./BlocksVerificationModal/MainVerificationFields";
import TableVerification from "./BlocksVerificationModal/TableVerification";

const VerificationModal = ({setModalActive}) => {
  const initialValues = useMemo(
    () => ({
      sku: "",
      vwc: "",
      price: "",
      quantity: "",
      sum: "",
    }),
    [],
  );

  return (
    <>
      <div className="form_wrapper">
        <Formik initialValues={initialValues} enableReinitialize>
          <Form>
            {/* style={{height: "12.5em"}} */}
            <MainVerificationFields />
            <div className="border border_gap"></div>

            <AdditionalVerificationFields />
            <div className="border border_gap"></div>
          </Form>
        </Formik>
        <div style={{height: "100%"}}>
          <TableVerification />
        </div>
        <div className="v_buttons">
          <FunctionButton name="Save" autoWidth disabled />
          <FunctionButton
            name="Cancel"
            autoWidth
            onClick={() => {
              setModalActive(false);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VerificationModal;
