import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {BUTTON_COLORS} from "../../../utilities/Buttons/constants";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import TableWithSelect from "../../conteiners/TableWithSelect";

import "./accWithFilter.scss";

const AccListWithFilter = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const accounts = [],
    currentAcc = null;

  const [type, setType] = useState("ALL");

  useEffect(() => {}, []);

  function transformForTable(project) {
    const date1 = project.creationDate ? new Date(project.creationDate).toLocaleDateString() : "";
    const date2 = project.finalDate ? new Date(project.finalDate).toLocaleDateString() : "";
    return {
      id: project.projectKey,
      // designerId : project.designerId || '',
      designerName: project.designerName || "",
      projectAdress: project.projectAdress || "",
      creationDate: date1,
      finalDate: date2,
      status: project.projectStatus || "",
    };
  }

  const ths = useMemo(() => {
    return {
      "№": 10,
      [t("Account №")]: 20,
      [t("Type*")]: 10,
      [t("Nomenclature")]: 50,
      [t("Status")]: 10,
    };
  }, []);

  // const onSelect = (id)=>{
  //   dispatch(fetchProjectById(id))
  // }

  return (
    <div className="project_form_wrapper" style={{}}>
      <div className="filter_acc_btns flex_container">
        <FunctionButton
          name={t("Balance accounts")}
          color={BUTTON_COLORS.TRANSPARENT}
          onClick={() => {
            setType("BALANCE");
          }}
        />
        <FunctionButton
          name={t("Commercial accounts")}
          color={BUTTON_COLORS.TRANSPARENT}
          onClick={() => {
            setType("COMMERCIAL");
          }}
        />
        <FunctionButton
          name={t("Operating accounts")}
          color={BUTTON_COLORS.TRANSPARENT}
          onClick={() => {
            setType("OPERATING");
          }}
        />
      </div>

      <div className="project_list" style={{height: "100%"}}>
        <TableWithSelect
          thsName={ths}
          items={accounts.map(transformForTable)}
          current={currentAcc?.accKey}
          onSelect={() => {}}
          loadingStatus={"idle"}
          // formatStatus={formatStatus}
        />
      </div>
    </div>
  );
};

export default AccListWithFilter;
