import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import DeleteIcon from "../../../../images/icons/delete.png";
import SaveIcon from "../../../../images/icons/save.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../../Inputs";
import CheckListModalsTodo from "../Todo/CheckListModalsTodo";

import useDeliveryInfoService from "./useDeliveryInfoService";

const DeliveryInfo = ({headerNumber = 2, children = null}) => {
  const {t} = useTranslation();
  const {shippingTableColumns, shippingTableData, onSaveBtnClick, onDeleteBtnClick} = useDeliveryInfoService();

  return (
    <div>
      <div className="header-block mb0">{`${headerNumber}. ${t("Delivery Information")}:`}</div>
      <TextAreaInput name="shippingInfoArea" width={"100%"} height="40px" className="mb0_5" readOnly />
      {children}
      {/* <div className="fc nowrap gap jcsb aist mb0_5">
        <TextInput label={"ToDo:"} name="todo" autolabel className="mb0_5" />
        <div className="fc aist jcsb nowrap">
          <DateInput name="deliveryDate" />
          <ImageButton src={SaveIcon} onClick={onSaveBtnClick} width={2.3} height={1.4} disabled />
          <ImageButton src={DeleteIcon} onClick={onDeleteBtnClick} width={1.4} height={1.4} disabled />
        </div>
      </div> */}
      {/*  */}
      {/* <ReactTable columns={shippingTableColumns} defaultData={shippingTableData} style={{height: "60px"}} /> */}
    </div>
  );
};

DeliveryInfo.propTypes = {headerNumber: PropTypes.string, children: PropTypes.node};

export default DeliveryInfo;
