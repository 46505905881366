import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import Swal from "sweetalert2";

import saveIcon from "../../../../images/icons/bold_save.png";
import delIcon from "../../../../images/icons/closedelete.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {addTemporaryPlace, deleteWarehouse, fetchAllWarehouses} from "../../redux/operations";
import {selectWarehouses} from "../../redux/selectors";

import useWarehouseOptions from "./useWarehouseOptions";

const WarehousesForm = ({children}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const warehouses = useSelector(selectWarehouses);

  const {values, setValues, setFieldValue} = useFormikContext();

  const {warehouseName, warehouseAddressId} = values;

  const {warehousesOptions} = useWarehouseOptions();

  useEffect(() => {
    if (!warehouses || warehouses?.length === 0) {
      dispatch(fetchAllWarehouses());
    }
  }, []);

  const onSaveBtnClick = () => {
    // if (warehouseId && warehouseId !== "") {
    //   dispatch(updateWarehouse({warehouseId, data: {address: warehouseAddress}}));
    //   return;
    // }
    dispatch(
      addTemporaryPlace({
        address: warehouseName,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setValues({
          ...values,
          warehouseName: "",
          warehouseId: "",
          warehouseAddress: res.payload.address,
          warehouseAddressId: res.payload.warehouseId,
        });
      }
    });
  };

  const onDelete = () => {
    Swal.fire({
      title: "",
      text: t("delete wereHouse address?", {address: values.warehouseAddress}),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
      customClas: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteWarehouse(warehouseAddressId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            setValues({
              ...values,
              warehouseName: "",
              warehouseId: null,
              warehouseAddress: "",
              warehouseAddressId: null,
            });
          }
        });
      }
    });
  };

  const saveBtnDisabled =
    !(warehouseName && warehouseName !== "") || (!!warehouseAddressId && warehouseAddressId !== "");

  return (
    <div style={{width: "100%"}}>
      <Select
        label={t("Місце Доставки")}
        name="warehouseId"
        options={warehousesOptions}
        autolabel
        readOnly={!!warehouseAddressId && warehouseAddressId !== ""}
        makeEffect={option => {
          setFieldValue("warehouseAddress", option?.address);
        }}
      />
      <div style={{width: "100%"}}>
        <div className="fc nowrap w100">
          <TextInput
            label={t("або додати Місце")}
            name="warehouseName"
            readOnly={!!warehouseAddressId && warehouseAddressId !== ""}
            style={{width: "100%"}}
            autolabel={true}
          />
          <ImageButton src={saveIcon} width={1.6} height={1.5} onClick={onSaveBtnClick} disabled={saveBtnDisabled} />
          <ImageButton
            src={delIcon}
            size={1.5}
            disabled={!(!!warehouseAddressId && warehouseAddressId !== "")}
            onClick={onDelete}
            tooltipMessage={"видалити запис"}
          />
        </div>
        {children}
        <TextAreaInput name="warehouseAddress" readOnly />
      </div>
    </div>
  );
};

export const WarehousesFullForm = withFormik({
  mapPropsToValues: () => ({warehouseName: "", warehouseId: "", warehouseAdressId: ""}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(WarehousesForm);

export default WarehousesForm;
