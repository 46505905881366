import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import {calcStatusColorViaPlannedDate} from "../../../../utilities/DateStatusCircle";
import {stockPoolNumber_TABLE_COLUMNS} from "../../../Logist/SleepingRequests/constants";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {getExpandedDocs, profExpanded} from "../../RequestsInProgress/utils/functions/expandDataForTable";
import {REQ_WINDOW_TYPES} from "../../RequestsInProgress/utils/functions/requestsFiltersManager";
import {
  deselectCurrentOrder,
  deselectCurrentRequest,
  fetchRequests,
  fetchRequestStockPoolUpdated,
  getCurrentRequestId,
  getRequest,
  getSPRequests,
  setCurrent,
  setCurrentRequestInfo,
} from "../../RequestsSlice";

const useStockPoolTableService = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const sPRequests = useSelector(getSPRequests);
  const currentReqId = useSelector(getCurrentRequestId);

  useEffect(() => {
    dispatch(fetchRequests({window: REQ_WINDOW_TYPES.SP}));
  }, []);

  const stockPoolTableColumns = useColumns(stockPoolNumber_TABLE_COLUMNS);

  const stockPoolTableData = useMemo(() => {
    return sPRequests?.map(
      (
        {
          requestId,
          status,
          brand,
          brand: {factoryContacts},
          managerFea,
          managerCo,
          createdAt,
          updatedAt,
          requestDates,
          cos,
          stockPool,
          transportDocs,
          proforms,
          ...rest
        },
        index,
      ) => {
        const statusName = status?.[i18n.language === "en" ? "engName" : "ukrName"] || "";
        const lastDate = requestDates.reduce((prev, current) => {
          return prev.dateId > current.dateId ? prev : current;
        });

        const color = calcStatusColorViaPlannedDate(lastDate.plannedDate, lastDate.createdAt, lastDate.actualDate);
        return {
          ...rest,
          number: index + 1,
          requestStatus: statusName,
          managerCo: managerCo?.name || "",
          managerFea: managerFea?.name || "",
          factory: (factoryContacts && factoryContacts[0]?.name) || brand?.name || "",
          id: requestId,
          createdAt: dateFormat(createdAt),
          updatedAt: dateFormat(updatedAt),
          noname: "",
          statusCircle: color,
          country: cos[0]?.project?.country?.shortName,
          bringTo: dateFormat(cos[0].bringTo),
          stockPoolNumber: stockPool?.stockPoolNumber || "",
          packL: getExpandedDocs(transportDocs, 1),
          invoice: getExpandedDocs(transportDocs, 2),
          prof: profExpanded(proforms),
        };
      },
    );
  }, [sPRequests]);

  const onStockPoolTableSelect = requestId => {
    if (!requestId || requestId === currentReqId) {
      dispatch(deselectCurrentOrder());
      dispatch(deselectCurrentRequest());
      return;
    }

    dispatch(getRequest(requestId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchRequestStockPoolUpdated(res.payload.stockPool?.stockPoolId));
        dispatch(setCurrent(requestId));
      }
    });
  };

  return {
    stockPoolTableColumns,
    stockPoolTableData,
    onStockPoolTableSelect,
    // current, spId
  };
};

export default useStockPoolTableService;
