import {makeFilterManager} from "../../../../../utilities/makeFilterManager/makeFilterManager";

const REQ_FILTERS_REGISTER_SESSION_KEY = "req_filters_register";
const REQ_FILTERS_IN_PROGRESS_SESSION_KEY = "req_filters_in_progress";
const REQ_FILTERS_VALIDATION_SESSION_KEY = "req_filters_validation";
const REQ_FILTERS_READINESS_SESSION_KEY = "req_filters_readiness";

export const REQ_TYPES = Object.freeze({
  REQ_REGISTER: "requestRegister",
  REQ_IN_PROGRESS: "requestInProgress",
  REQ_VALIDATION: "receiptAndVerific",
  REQ_READINESS: "readyRequests",
});

const REQ_TYPES_KEYS = Object.freeze({
  [REQ_TYPES.REQ_REGISTER]: REQ_FILTERS_REGISTER_SESSION_KEY,
  [REQ_TYPES.REQ_IN_PROGRESS]: REQ_FILTERS_IN_PROGRESS_SESSION_KEY,
  [REQ_TYPES.REQ_VALIDATION]: REQ_FILTERS_VALIDATION_SESSION_KEY,
  [REQ_TYPES.REQ_READINESS]: REQ_FILTERS_READINESS_SESSION_KEY,
});

export const reqFiltersManager = reqType => {
  const filtersKey = REQ_TYPES_KEYS[reqType];

  return makeFilterManager(filtersKey);
};

export const REQ_WINDOW_TYPES = Object.freeze({
  REGISTER: "Request register",
  INCOMING: "Incoming requests",
  APPOINTED: "Appointed requests",
  DISTRIBUTED: "Distributed requests",
  PROCESSED: "Processed request",
  INPROGRESS: "Requests in progress",
  PAID: "Paid requests",
  SP: "Requests in SP",
  SLEPPING: "Sleeping requests",
  INCOMING_LOGIST: "Incoming logistic requests",
  VARIFICATION: "Requests for varification",
  SLEPPING_IN_SP: "Sleeping requests in SP",
});
