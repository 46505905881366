import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {ORDER_TABLE_COLUMNS} from "../LogModRqstCheckList/constants";
import {selectFetchedStockPool} from "../redux/selectors";

const useLogModSPCheckList = () => {
  // const dispatch = useDispatch();
  const currentSP = useSelector(selectFetchedStockPool);
  // const bossStockPools = useSelector(selectBossStockPools);

  const {t} = useTranslation();

  // useEffect(() => {
  //   if (!bossStockPools) {
  //     dispatch(fetchAllStockPoolsUpdated({window: "Boss"}));
  //   }
  // }, [bossStockPools]);

  const initialValues = {
    //HeaderInputRow
    SPlocation: "-",
    creatingDate: currentSP?.createdAt || "-",
    placeNumber: currentSP?.packingSpaces?.length || "",
    generalWeight: (currentSP?.packingSpaces ?? {})[0]?.weight ?? "-",
    generalVolume: (currentSP?.packingSpaces ?? {})[0]?.volume ?? "-",
    shippingInfoArea: "-",
  };
  const headerInputNames = [
    {name: "SPlocation", label: "Location of SP", autolabel: true},
    {name: "creatingDate", label: "Date of formation of SP", autolabel: true, type: "date"},
    {name: "placeNumber", label: t("General") + ":  \t\t\t   " + t("Places quantity"), autolabel: true, width: "3em"},
    {name: "generalWeight", label: "weight (kg)", autolabel: true, width: "5em"},
    {name: "generalVolume", label: "volume (m3)", width: "5em"},
  ];
  const orderTableColumns = useColumns(ORDER_TABLE_COLUMNS);
  const orderTableData = []; //makeLogModSPCheckTableData()
  const pdfFileArray = [];

  const documentsTableData = useMemo(() => {
    var docs = [];
    var counter = 1;
    // currentSP?.requests?.forEach(request => {
    //   request?.proforms?.forEach(prof => {
    //     docs.push({
    //       id: counter - 1,
    //       number: counter,
    //       docDate: dateFormat(prof?.createdAt) || "",
    //       docType: "Проформа",
    //       docNumber: "Prof № " + prof?.proformaNumber || "",
    //       createdBy: "",
    //       url: prof?.pdfFileUrl || "",
    //     });
    //     counter++;
    //   });
    // });

    currentSP?.transportDocs?.map(tdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(tdoc?.dateFrom) || "",
        docType: tdoc?.docType.nameUkr || "",
        docNumber: tdoc?.docNumber || "",
        createdBy: "",
        url: tdoc?.fileUrl || "",
      });
      counter++;
    });
    return docs;
  }, [currentSP]);

  return {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    documentsTableData,
    currentSP,
  };
};

export default useLogModSPCheckList;

export const makeLogModSPCheckTableData = stockPools =>
  stockPools
    ? stockPools.map(
        ({requestId, requestNumber, createdAt, orders = [], note, stockPoolNumber, cargo} = {}, index) => ({
          number: ++index,
          id: requestId ?? index,
          eventDate: "-",
          requestNumber,
          orderNumber: orders?.length > 1 ? "multi" : orders[0]?.co?.coNumber ?? "-",

          createdAt: dateFormat(createdAt),

          bringTo: dateFormat(orders[0]?.co?.bringTo) ?? "-",

          trPoolNumber: cargo?.cargoNumber ?? "-",
          stockPoolNumber,
          eventType: "-",
          actionPlace: "-",
          plannedEvent: "-",
          note,
          transformedTpNumber: "-",
        }),
      )
    : [];
