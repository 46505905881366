import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  fetchOrderByRequest,
  getCurentOrder,
  getRequestInfo,
  getStockPoolCheckLIst,
  setCurentOrder,
} from "../../Requests/RequestsSlice";
import {TP_POOL_TABLE_COLUMNS} from "../LogModTpPoolCheckList/constants";

const useLogModOrderCheckListService = orderId => {
  // const [curentOrder, setCurentOrder] = useState(null);
  const curentRequest = useSelector(getRequestInfo);
  const curentOrder = useSelector(getCurentOrder);
  const currentSP = useSelector(getStockPoolCheckLIst);

  const requestSkus = curentOrder?.skuRequests || curentOrder?.skuRequest;

  const dispatch = useDispatch();

  useEffect(() => {
    if (orderId) {
      try {
        const order = curentRequest.orders.find(order => order.orderId === orderId);
        if (order) {
          dispatch(fetchOrderByRequest({requestId: curentRequest.requestId, coId: orderId}));
        }
        // console.log("currentreq");
      } catch {
        // console.log("currentSP");
        const order = currentSP?.orders?.find(order => order.orderId === orderId);
        if (order) {
          dispatch(setCurentOrder(order));
        }
      }
    }
    return () => {
      dispatch(setCurentOrder(null));
    };
  }, [orderId]);

  const initialValues = useMemo(
    () => ({
      //HeaderInputRow
      managerCO: curentOrder?.co?.managerCo?.name || "",
      coNumber: curentOrder?.co?.coNumber || "",
      transportTo: curentOrder?.co?.bringTo || "",
      // requestNumber: "",
      // managerFea: "",
      // executionDate: "",

      //FactoryInfo
      factoryInfo: curentOrder?.brand?.factoryContacts?.map(contact => contact?.address).join("\n") || "",
      //DeliveryInfo
      shippingInfoArea: "",
      payer: "",
      payerAddress: "",
      recipient: "",
      recipientAddress: "",
      decommissioning: "",
      delCondition: "",
      logist: "",
      todo: "",
      //GroupAndType
      groupAndTypeInfo: curentOrder?.productGroups
        ?.map(entry => entry?.productGroup?.name + ", " + entry?.productType?.name)
        .join("\n"),

      // curentOrder?.SkuRequest?.productGroup.name +"\n"+ curentOrder?.SkuRequest?.productType.name,
      //DocumentsInfo
      documentType: "",
      documentNumber: "",
      //ShipmentInfo
      destination: "",
      shipmentRqstNumber: "",
    }),
    [curentOrder],
  );
  const headerInputNames = [
    {name: "managerCO", label: "CO Manager", readOnly: true, width: "15rem"},
    {name: "coNumber", label: "CO №", autolabel: true, readOnly: true},
    {name: "transportTo", label: "Transport To", autolabel: true, readOnly: true, type: "date"},
    // {name: "requestNumber", label: "Request №", autolabel: true},
    // {name: "managerFea", label: "Manager FEA"},
    // {name: "executionDate", label: "Execution date", autolabel: true},
  ];
  const orderTableColumns = useColumns(TP_POOL_TABLE_COLUMNS);
  const orderTableData = [];
  const pdfFileArray = [];

  const documentsTableData = useMemo(() => {
    var docs = [];
    var counter = 1;
    curentOrder?.requests?.forEach(request => {
      request?.proforms?.forEach(prof => {
        docs.push({
          id: counter - 1,
          number: counter,
          docDate: dateFormat(prof?.createdAt) || "",
          docType: "Проформа",
          docNumber: "Prof № " + prof?.proformaNumber || "",
          createdBy: "",
          url: prof?.pdfFileUrl || "",
        });
        counter++;
      });
      request?.transportDocs?.forEach(tdoc => {
        docs.push({
          id: counter - 1,
          number: counter,
          docDate: dateFormat(tdoc?.dateFrom) || "",
          docType: tdoc?.docType?.nameUkr || "",
          docNumber: tdoc?.docNumber || "",
          createdBy: "",
          url: tdoc?.fileUrl || "",
        });
        counter++;
      });
    });

    return docs;
  }, [curentOrder]);

  return {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    // onCheckSkuBtnClick,
    documentsTableData,
    curentOrder,
    requestSkus,
  };
};

export default useLogModOrderCheckListService;
