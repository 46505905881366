import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../../utilities/dateFormat";
import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {getAppointRequests} from "../../RequestsSlice";
import {MANAGER_CO_APPOINTED_REQ_TABLE, MANAGER_CO_REQ_TABLE} from "../RequestTable/utils/constants";

import styles from "./ordering.module.scss";

const AppointedRequestTable = ({setSelectedForCancel}) => {
  const {t, i18n} = useTranslation();

  const [currentReq, setCurrentReq] = useState(null);
  const appointedRequests = useSelector(getAppointRequests);

  useEffect(() => {
    if (currentReq) setSelectedForCancel([currentReq]);
  }, [currentReq]);

  function compositeProductGroupNames(productGroups) {
    if (!productGroups) return "";
    return productGroups.reduce(
      (acc, productGroup) => (acc === "" ? productGroup.name : acc + ",  " + productGroup.name),
      "",
    );
  }

  const transformForTable = (item, i) => {
    const {requestId, status, brand, managerFea, managerCo, createdAt, updatedAt, cos, productGroups, ...rest} = item;
    const statusName = status?.engName ? status[i18n.language === "en" ? "engName" : "ukrName"] : "";
    const closerBringTo = cos.reduce((prev, current) => {
      const prevDate = prev.bringTo;
      const currentDate = current.bringTo;
      return prevDate > currentDate;
    });
    return {
      ...rest,
      number: i + 1,
      status: statusName,
      managerCo: managerCo?.name || "",
      managerFea: managerFea?.name || "",
      coNumber: cos[0]?.coNumber || "",

      factory: brand?.name || "",
      id: requestId,
      createdAt: dateFormat(createdAt),
      updatedAt: dateFormat(closerBringTo),
      noname: "",
      productGroup: compositeProductGroupNames(productGroups),

      country: cos[0]?.project?.country?.shortName,
      bringTo: dateFormat(cos[0]?.bringTo),
    };
  };
  const defaultData = useMemo(() => {
    return appointedRequests.map(transformForTable);
  }, [appointedRequests, i18n.language]);

  useEffect(() => {
    console.log();
  }, [setCurrentReq]);

  const columns = useColumns(MANAGER_CO_APPOINTED_REQ_TABLE);

  return (
    <div
      // style={{height: "100%", width: "100%", display: "flex"}}
      className={styles.table + " table_xscroll__container w"}
    >
      <ReactTable
        defaultData={defaultData}
        columns={columns}
        current={currentReq}
        enableMultiSelection={true}
        setMultiSelect={setSelectedForCancel}
      />
    </div>
  );
};

export default AppointedRequestTable;
