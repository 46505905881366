export default function formControlTerms({controlTerms, timeUnits, language}) {
  if (!controlTerms || !timeUnits) return "";

  const timeUnitsString = timeUnits.reduce(
    (acc, {unitId, engName, name}) => ({
      ...acc,
      [unitId]: language === "en" ? engName[0] : name[0],
    }),
    {},
  );

  return controlTerms.reduce(
    (acc, {unitId, calculatedOption}) =>
      acc +
      (acc ? " / " : "") +
      (
        Number.parseFloat(calculatedOption) * timeUnits.find(timeUnit => timeUnit.unitId === unitId)?.multiplier ?? 1
      ).toFixed(2) +
      timeUnitsString[unitId],
    "",
  );
}
