export const LEFT_SIDE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 30,
  },

  {
    header: "Status SP",
    accessorKey: "spStatus",
    isSortable: true,
    size: 60,
  },
  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
    isSortable: true,
    size: 60,
  },
  {
    header: "Tr pool №",
    accessorKey: "trPoolNumber",
    isSortable: true,
    size: 60,
  },
  {
    header: "Action Place",
    accessorKey: "actionPlace",
    isSortable: true,
    size: 70,
  },
]);
