import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import ReactTable from "../../../../../conteiners/ReactTable";
import ModalForm from "../../../../../Modal/FormModal";
import RequestPlanSkuTable from "../../../../../Requests/RequestCreation/RequestPlanSkuTable";
import {getRequestInfo} from "../../../../../Requests/RequestsSlice";

import useIncomingReqTable from "./useIncomingReqTable";

import styles from "../../TPRequestDistributionModal.module.scss";

const IncomingReqTable = ({selectedSP}) => {
  const {t} = useTranslation();

  const currentRequest = useSelector(getRequestInfo);

  const {columns, defaultData, onIncomingReqTableRowDoubleClick, isModalOpen, setIsModalOpen} = useIncomingReqTable({
    selectedSP,
  });

  return (
    <div className={" table_xscroll__container "} style={{height: "17em", width: "100%"}}>
      <ReactTable
        defaultData={defaultData ?? []}
        columns={columns}
        onSelect={() => {}}
        current={null}
        unchangeableBg
        tableClassName={styles.centerTable}
        onRowDoubleClick={onIncomingReqTableRowDoubleClick}
      />
      <ModalForm
        closable={true}
        Component={RequestPlanSkuTable}
        label={t("request review") + currentRequest?.requestNumber}
        active={isModalOpen}
        setModalActive={setIsModalOpen}
        className="modal_sizes"
      />
    </div>
  );
};

IncomingReqTable.propTypes = {selectedSP: PropTypes.array.isRequired};

export default IncomingReqTable;
