import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import {throttle} from "lodash";

import {TextInput} from "../../Inputs";
import {getCheckedRequestIds, getCurrentNote, setCurrentNote} from "../RequestsSlice";

import useStatus4RequestsNotes from "./utils/hooks/useStatus4RequestsNotes";

import styles from "./RequestBlock.module.scss";

const RequestBlock = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  //getting redux state
  const currentNote = useSelector(getCurrentNote);
  const checkedRequestIds = useSelector(getCheckedRequestIds);

  //disable notes input if no requests checked
  const isInputDisabled = checkedRequestIds?.length === 0;

  const status4RequestsNotes = useStatus4RequestsNotes();

  //event handlers
  const onNoteInputChange = e => {
    const newNote = e.target.value;
    const trottledFunc = throttle(() => dispatch(setCurrentNote(newNote)), 500);
    trottledFunc();
  };

  const initialValues = {
    notesInputField: currentNote ?? "",
    status4RequestsNotes,
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form className={styles.reqBlockWrapper}>
        <TextInput
          width="100%"
          name="notesInputField"
          type="text"
          placeholder={t("Add note")}
          generalClassName={styles.reqBlockInput}
          handleInputChange={onNoteInputChange}
          disabled={isInputDisabled}
        />
        <Field as="textarea" name="status4RequestsNotes" disabled className={styles.reqBlockTextarea} />
      </Form>
    </Formik>
  );
};

export default RequestBlock;
