import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchSkuCatalogs} from "../../Components/SKU/SkuSlice";
import {getProductGroups} from "../../selectors/skuSelectors";

const useProductGroupsOptionsService = () => {
  const dispatch = useDispatch();
  const productGroups = useSelector(getProductGroups);

  useEffect(() => {
    if (!productGroups || productGroups?.length === 0) {
      dispatch(fetchSkuCatalogs());
    }
  }, [productGroups.length]);

  return productGroups.map(productGroup => ({title: productGroup.name, value: productGroup.productGroupId})) ?? [];
};

export default useProductGroupsOptionsService;
