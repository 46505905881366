import {useEffect, useState} from "react";

const ClickableTr = ({onClick, onDoubleClick, children, ...props}) => {
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (clickCount === 1) {
      const timer = setTimeout(() => {
        onClick();
        setClickCount(0);
      }, 300);

      return () => clearTimeout(timer);
    } else if (clickCount === 2) {
      onDoubleClick();
      setClickCount(0);
    }
  }, [clickCount]);

  const handleClick = () => {
    setClickCount(prevCount => prevCount + 1);
  };

  return (
    <tr onClick={handleClick} onDoubleClick={e => e.preventDefault()} {...props}>
      {children}
    </tr>
  );
};

export default ClickableTr;
