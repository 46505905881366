import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import Filters, {FIELD_TYPES} from "../../Filters/Filters";
import {DateInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import ModCheckList from "../CheckLists/ModCheckList";
import {LOGIST_WINDOWS} from "../common/ShipmentInfo/constants";

import useFinalDestinationBoss from "./useFinalDestinationBoss";

const FinalDestinationBossBase = () => {
  const {t} = useTranslation();

  const {
    onCarrierTrackingBtnClick,
    logistOptions,
    onAppointNewLogisticianBtnClick,
    leftSideTableColumns,
    leftSideTableData,
    rightSideTableColumns,
    rightSideTableData,
    onRightSideTableSelect,
    getFilterData,
    finalDestinationOptions,
    orderNumberOptions,
    managerCoOptions,
    usersOptions,
    onLeftSideTableCheck,
    areFiltersDisabled,
    onRightSideTableDoubleClick,
    isAppointNewLogistBtnDisabled,
    isModalOpen,
    setModalOpen,
    loading,
    currentSkuRequestId,
  } = useFinalDestinationBoss();

  const filterFields = [
    {type: FIELD_TYPES.SELECT, label: "Final destination", name: "warehouseId", options: finalDestinationOptions},
    {type: FIELD_TYPES.TEXT, label: "TP №", name: "cargoNumber"},
    {type: FIELD_TYPES.SELECT, label: "Order number", name: "orderNumber", options: orderNumberOptions},
    {type: FIELD_TYPES.SELECT, label: "Manager CO", name: "managerCoId", options: managerCoOptions},
    {type: FIELD_TYPES.SELECT, label: "Logist", name: "managerLogistId", options: logistOptions},
  ];

  return (
    <PageContainer title={t("Final Destination (Boss)")}>
      <div className="page_flex_container mb">
        <div className="w100">
          <Filters
            window={LOGIST_WINDOWS.FINAL_DESTINATION_BOSS}
            fields={filterFields}
            getFilterData={getFilterData}
            disabled={areFiltersDisabled}
          />
          <Divider />
        </div>

        <div className="row jcsb mb" style={{width: "900px"}}>
          <DateInput name="shippingDate" label="Date: Shipping (f)" readOnly />
          <DateInput name="deliveryDate" label="Date: Delivery (n)" readOnly />
          <FunctionButton name="Carrier tracking" onClick={onCarrierTrackingBtnClick} autoWidth disabled />
        </div>

        <div className="row fGrow w100 gap">
          <ReactTable
            enableMultiSelection
            setMultiSelect={onLeftSideTableCheck}
            className="tableTac"
            columns={leftSideTableColumns}
            defaultData={leftSideTableData}
            current={null}
            style={{height: "100%", width: "30%"}}
            loading={loading}
          />
          <ReactTable
            className="tableTac overflowX"
            columns={rightSideTableColumns}
            defaultData={rightSideTableData}
            current={currentSkuRequestId}
            onSelect={onRightSideTableSelect}
            style={{height: "100%"}}
            onRowDoubleClick={onRightSideTableDoubleClick}
            loading={loading}
          />
        </div>
        <div className="row jcfs w100 gap2x mb mt">
          <Select label={t("Logist")} name="logist" options={usersOptions} width="20rem" optionsAbove />
          <FunctionButton
            name="Appoint a new logistician"
            onClick={onAppointNewLogisticianBtnClick}
            autoWidth
            disabled={isAppointNewLogistBtnDisabled}
            tooltipMessage="At least one transport pool must be chosen"
          />
        </div>
      </div>

      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />
    </PageContainer>
  );
};

const FinalDestinationBoss = withFormik({
  handleSubmit: () => {},
  mapPropsToValues: () => ({shippingDate: "", deliveryDate: "", logistName: ""}),
  enableReinitialize: true,
})(FinalDestinationBossBase);
export default FinalDestinationBoss;
