import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Divider} from "@mui/material";

import {fetchData} from "../../../../hook/axios.hook";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectTransportDoc, setPackingSpaces} from "../../../Requests/RequestsSlice";
import {LOGIST_WINDOWS} from "../../common/ShipmentInfo/constants";
import SPwithRequestTables from "../../common/SPwithRequestTables/SPwithRequestTables";
import CheckListModalsTodo from "../../common/Todo/CheckListModalsTodo";

import {SP_TABLE_STORAGE_ADD_SERVICES_MODAL} from "./constants";
import DocAndPackBlock from "./DocAndPackBlock";
import Filters from "./Filters";
// import NoteLine from "./NoteLine";
import WarehouseServiceList from "./WarehouseServiceList";

const StorageAddServicesModal = ({currentWarehouseId}) => {
  const spTableRowColumnes = useColumns(SP_TABLE_STORAGE_ADD_SERVICES_MODAL);

  const [currentStockPool, setCurrentStockPool] = useState(null);
  const currentTransportDoc = useSelector(selectTransportDoc);

  const [filterProps, setFilterProps] = useState({warehouseId: currentWarehouseId});

  const pdfFileArray = currentTransportDoc?.fileUrl ? [currentTransportDoc.fileUrl] : [];

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentStockPool(null);
  }, [filterProps]);

  const onSPSelect = id => {
    if (id) {
      fetchData(`/stockPools/getUpdate/${id}`, "get").then(resp => {
        setCurrentStockPool(resp);
        dispatch(setPackingSpaces(resp.packingSpaces));
      });
    } else {
      dispatch(setPackingSpaces([]));
      setCurrentStockPool(null);
    }
  };

  return (
    <div className="w100">
      <Filters warehouseId={currentWarehouseId} setFilterProps={setFilterProps} />
      <Divider />
      <div className="grid3Col mb">
        <div className="flex_form">
          <WarehouseServiceList currentStockPool={currentStockPool} currentWarehouseId={currentWarehouseId} />
          <CheckListModalsTodo
            tableheight={"13.5em"}
            path={"stockPool"}
            entityId={currentStockPool?.stockPoolId || null}
          />
        </div>
        <div>
          <DocAndPackBlock currentStockPool={currentStockPool} />
        </div>
        <div className="kpPreview__box" style={{width: "100%", height: "100%"}}>
          <NewPdfViewer fileArray={pdfFileArray} hiding={false} />
        </div>
      </div>
      <SPwithRequestTables
        spTableColumnes={spTableRowColumnes}
        ifCheck={true}
        onSPSelect={onSPSelect}
        filterProps={filterProps}
        window={LOGIST_WINDOWS.WAREHOUSE_CREATING_STOCKPOOL}
        requestSelectable={false}
      />
    </div>
  );
};

export default StorageAddServicesModal;
