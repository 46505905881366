import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import useNameLangService from "../../../../../../../utilities/hooks/useNameLangService";
import useColumns from "../../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {FIN_SCHEMA_COLUMNS} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/columnsForPaymentTables";
import {getCurrentPayment} from "../../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

const useFinSchemaToFactoryService = () => {
  const {t} = useTranslation();
  const nameLang = useNameLangService();

  const currentPayment = useSelector(getCurrentPayment);
  const currentPaymentSchema = currentPayment?.paymentSchema;

  const finSchemaTableColumns = useColumns(FIN_SCHEMA_COLUMNS);
  const finSchemaTableData = currentPaymentSchema
    ? [
        {
          id: currentPaymentSchema.schemaId,
          number: 1,
          name: currentPaymentSchema["name" + nameLang],
          desc: t("not specified"),
        },
      ]
    : [];

  return {finSchemaTableColumns, finSchemaTableData};
};

export default useFinSchemaToFactoryService;
