import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";

import {getCurrentUser} from "../../../../selectors/userSelectors";
import QuestionItem from "../../../../utilities/QuestionItem";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useCountryIdOptionsService from "../../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import {getAllStatusRequest} from "../../RequestsSlice";
import useRequestFilters from "../filters.hook";
import withRequestFilters from "../FiltersHOC";

const BaseRequestFilters = ({window}) => {
  const {t} = useTranslation();
  const {brandsOptions, feaManagersOptions, coManagersOptions, requestStatusOptions} = useRequestFilters();
  const countryOptions = useCountryIdOptionsService();
  const allReq = useSelector(getAllStatusRequest);

  const currentUser = useSelector(getCurrentUser);

  const {setFieldValue} = useFormikContext();

  const requestNumberOptions = useMemo(() => {
    return allReq.length > 0
      ? allReq.map(item => {
          return {title: item.requestNumber, value: item.requestNumber};
        })
      : [];
  }, [allReq]);

  useEffect(() => {
    if (currentUser) {
      setFieldValue("feaManagerInProgress", currentUser?.user?.name);
    }
  }, [currentUser]);

  return (
    <>
      <div className="f_block" style={{paddingTop: "0.7em"}}>
        <Select
          label={t("Request status")}
          name="statusId"
          options={requestStatusOptions}
          width="12em"
          labelwidth="8em"
        />
        <Select
          label={t("Request №")}
          name="requestNumber"
          options={requestNumberOptions}
          width="12em"
          labelwidth="8em"
        />
        <Select label={t("CO Manager")} name="managerCoId" options={coManagersOptions} width="12em" labelwidth="8em" />
        <Select label={t("Factory")} name="brandId" options={brandsOptions} width="12em" labelwidth="8em" />

        <TextInput label="Prof №" name="proformaNumber" width="12em" labelStyles={{width: "8em"}} disabled />
        <TextInput label={t("Invoice №")} name="invoiceNumber" width="12em" labelStyles={{width: "8em"}} disabled />

        {window === "Requests in progress" ? (
          <TextInput label={t("FEA Manager")} name="feaManagerInProgress" width="12em" labelwidth="8em" readOnly />
        ) : (
          <Select
            label={t("FEA Manager")}
            name={"managerFeaId"}
            options={feaManagersOptions}
            width="12em"
            labelwidth="8em"
          />
        )}

        <Select label={t("Pr country")} name="countryId" options={countryOptions} width="12em" labelwidth="8em" />
      </div>
      <div className="period-wrapper" style={{paddingTop: "0.7em"}}>
        <QuestionItem title={"Select date"} />
        {t("d period")}
        <div className="request-date-wrapper">
          <DateInput label={t("From")} name="dateStart" />
          <DateInput label={t("To")} name="dateEnd" />
        </div>
      </div>
    </>
  );
};

const FullRequestFilters = withRequestFilters(BaseRequestFilters);

const FullRequestFiltersForm = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(FullRequestFilters);

export default FullRequestFiltersForm;
