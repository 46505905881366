import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import saveIcon from "../../../../images/icons/save.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {TextInput} from "../../../Inputs";
import ModalForm from "../../../Modal/FormModal";
import ModShowSkuPlan from "../ModShowSkuPlan/ModShowSkuPlan";

import useCheckSkuStorageModal from "./useCheckSkuStorageModal";

const CheckSkuStorageModal = ({selectedOrderId}) => {
  const {t} = useTranslation();
  const {
    initialValues,
    skuTableColumns,
    skuTableData,
    onSkuCheck,
    onSaveNoteBtnClick,
    onSaveCheckedSkusBtnClick,
    onCancelBtnClick,
    noteInputRef,
    areBtnsDisabled,
    onSkuTableDoubleClick,
    checkedSkuIds,
    isModalActive,
    setModalActive,
    currentOrderNumber,
    currentSp,
  } = useCheckSkuStorageModal({selectedOrderId});

  return (
    <div className="fcCol aistr w100">
      <Formik enableReinitialize initialValues={initialValues}>
        <Form>
          <div className="row jcsa gap2x mt">
            <TextInput readOnly label={t("Order number")} name={"orderNumber"} autolabel />
            <TextInput readOnly label={t("Manager CO")} name={"managerCo"} autolabel />
            <TextInput readOnly label={t("FEA Manager")} name={"managerFea"} autolabel />
          </div>
        </Form>
      </Formik>

      <ReactTable
        className="tableTac mt2x mb2x fGrow w100 overflowX"
        columns={skuTableColumns}
        defaultData={skuTableData ?? []}
        current={null}
        enableMultiSelection
        setMultiSelect={onSkuCheck}
        loading={false}
        onRowDoubleClick={onSkuTableDoubleClick}
        defaultMultiSelection={checkedSkuIds}
      />
      <div className="grid2Col">
        <div className="row">
          <input
            type="text"
            placeholder={t("Add a note to the Request in this field")}
            className="w100"
            ref={noteInputRef}
          />
          <ImageButton
            src={saveIcon}
            alt="save"
            width={2}
            height={1.3}
            onClick={onSaveNoteBtnClick}
            disabled={false}
            tooltipMessage="Save a note"
          />
        </div>
        <div className="row jcfe gap2x">
          <FunctionButton
            name="Save checked as Defect"
            autoWidth
            onClick={onSaveCheckedSkusBtnClick}
            disabled={areBtnsDisabled}
          />
          <FunctionButton name="Cancel" autoWidth onClick={onCancelBtnClick} disabled={areBtnsDisabled} />
        </div>
      </div>

      <ModalForm
        closable={true}
        Component={ModShowSkuPlan}
        label={t("SKU review in the order №") + currentOrderNumber}
        active={isModalActive}
        setModalActive={setModalActive}
        onModalClose={() => {
          setModalActive(false);
        }}
        currentSp={currentSp}
      />
    </div>
  );
};

export default CheckSkuStorageModal;
