export const FINAL_DEST_BOSS_LEFT_SIDE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    isSortable: true,
  },
  {
    header: "Stock Pool №",
    accessorKey: "stockPoolNumber",
    isSortable: true,
  },
  {
    header: "Transport Pool №",
    accessorKey: "trPoolNumber",
    isSortable: true,
  },
]);

export const FINAL_DEST_BOSS_RIGHT_SIDE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Status",
    accessorKey: "requestStatus",
    isSortable: true,
    size: 40,
  },
  {
    header: "Application №",
    accessorKey: "orderNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "-FD-",
    accessorKey: "fd",
    isSortable: true,
    size: 70,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    isSortable: true,
    size: 70,
  },
  {
    header: "Logist",
    accessorKey: "logist",
    isSortable: true,
    size: 70,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    isSortable: true,
    size: 70,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    isSortable: true,
    size: 70,
  },
  {
    header: "Readiness date",
    accessorKey: "createdAt",
    isSortable: true,
    size: 70,
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
    isSortable: true,
    size: 70,
  },
  {
    header: "Product country",
    accessorKey: "country",
    isSortable: true,
    size: 70,
  },
  {
    header: "Prof №",
    accessorKey: "proformaNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
    isSortable: true,
    size: 70,
  },
  {
    header: "Note",
    accessorKey: "note",
    isSortable: true,
    size: 70,
  },
]);
