import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import {findRequestDateType, REQUEST_DATES_IDS} from "../../../../../utilities/requestDatesIdsService";
import ReactTable from "../../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../../Inputs";
import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";
import {getRequestInfo} from "../../../RequestsSlice";

import {getPaymentsForRequest} from "./redux/PaymentOperationsSlice";
import {getCurrentDocument, getCurrentPayment, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import DocsByPaymentTable from "./DocsByPaymentTable";
import ForPaymentPart from "./ForPaymentPart";
import OnPaymentPart from "./OnPaymentPart";

const ModalPaymentDocuments = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);
  const currentPayment = useSelector(getCurrentPayment);
  const currentDoc = useSelector(getCurrentDocument);

  // const [curentPayment, setCurrentPaymet] = useState(null);

  const initForPaymentDates = findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.FOR_PAYMENT_ID);
  const initOnPaymentDates = findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.PAYMENT_ID);

  const initialValues = useMemo(
    () => ({
      onPaymentPlan: initForPaymentDates?.plannedDate || "",
      onPaymentFact: initForPaymentDates?.actualDate || "",
      doc: "",
      documentType: "",
      docNote: "",
      requestNote: currentRequest?.note || "",
      onPaymentConfirmPlan: initOnPaymentDates?.plannedDate || "",
      onPaymentConfirmFact: initOnPaymentDates?.actualDate || "",
      paymentSum: currentPayment?.paymentSum,
    }),
    [currentRequest, currentPayment],
  );
  const onPaymentDocsSubmit = () => {};

  useEffect(() => {
    dispatch(getPaymentsForRequest({requestId: currentRequest.requestId}));
  }, []);

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onPaymentDocsSubmit}>
      <Form style={{height: "100%"}}>
        <div className="form_wrapper">
          <div className="paynent-main">
            <div className="left-part w100">
              <ForPaymentPart initForPaymentDates={initForPaymentDates} />
              <OnPaymentPart initOnPaymentDates={initOnPaymentDates} />
            </div>

            {/* <div className="right-part w100"> */}
            <div className="kpPreview__box" style={{height: "100%", maxHeight: "45em", width: "580px"}}>
              <NewPdfViewer fileArray={currentDoc?.doc ? [...currentDoc.doc.fileUrl] : []} />
            </div>
            {/* </div> */}
          </div>
          <DocsByPaymentTable />
        </div>
      </Form>
    </Formik>
  );
};

export default ModalPaymentDocuments;
