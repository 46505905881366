import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import moment from "moment";
import Swal from "sweetalert2";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {findRequestDateType, REQUEST_DATES_IDS} from "../../../../utilities/requestDatesIdsService";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {
  addReadinessDate,
  confirmReadiness,
  getRequest,
  getRequestInfo,
  selectdatesWithPostponed,
} from "../../RequestsSlice";

import {READINESS_TABLE_COLUMNS} from "./utils/constants";

import styles from "./ReceiptGoodsModal.module.scss";

const ReadinessDatesBlock = ({allDatesWithPostponed}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);

  const readinessTableColumns = useColumns(READINESS_TABLE_COLUMNS);

  const {values, resetForm} = useFormikContext();

  const {readinessDate, readinessControlDate, readinessNote} = values;

  const reqDates = currentRequest?.requestDates ?? [];

  const onAddDataClick = () => {
    const formattedReadinessDate = moment(readinessDate, "YYYY-MM-DD");
    const formattedReadinessControlDate = moment(readinessControlDate, "YYYY-MM-DD");

    if (formattedReadinessControlDate.isBefore(formattedReadinessDate)) {
      dispatch(
        addReadinessDate({
          requestId: currentRequest.requestId,
          data: {
            plannedDate: new Date(readinessDate).toJSON(),
            controlDate: new Date(readinessControlDate).toJSON(),
            note: readinessNote,
          },
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          resetForm();
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: t("Readiness info"),
        icon: "error",
      });
      return;
    }
  };

  const onProductReadyClick = () => {
    dispatch(confirmReadiness(currentRequest.requestId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getRequest(currentRequest.requestId));
      }
    });
  };

  const addDataButtonDisabled =
    allDatesWithPostponed.length === 0
      ? readinessControlDate === "" || readinessDate === ""
      : readinessNote === "" ||
        readinessDate === "" ||
        readinessControlDate === "" ||
        allDatesWithPostponed.length === 3 ||
        findRequestDateType(reqDates, REQUEST_DATES_IDS.READINESS_ID)?.actualDate;

  return (
    <section className={styles.readinessSection}>
      <div className={styles.readinessColumn}>
        <DateInput
          name="readinessDate"
          question="fea fab"
          flatpikrConfig={{
            minDate: moment(findRequestDateType(reqDates, REQUEST_DATES_IDS.PAYMENT_ID)?.actualDate)
              .add(2, "days")
              .format("DD-MM-YYYY"),
          }}
          label={t("Date: Readiness")}
          readOnly={
            allDatesWithPostponed.length === 3 ||
            findRequestDateType(reqDates, REQUEST_DATES_IDS.READINESS_ID)?.actualDate
          }
          labelW={"13em"}
        />
        <DateInput
          name="readinessControlDate"
          question="fea func"
          flatpikrConfig={{
            minDate: moment(findRequestDateType(reqDates, REQUEST_DATES_IDS.PAYMENT_ID)?.actualDate)
              .add(1, "days")
              .format("DD-MM-YYYY"),
          }}
          label={t("Date: Readiness control")}
          readOnly={
            allDatesWithPostponed.length === 3 ||
            findRequestDateType(reqDates, REQUEST_DATES_IDS.READINESS_ID)?.actualDate
          }
          labelW={"13em"}
        />

        <TextInput name="readinessNote" placeholder={t("note readiness")} width="22.5em" />
      </div>
      <div className={styles.readinessColumn} style={{width: "650px"}}>
        <ReactTable
          className={styles.readinessTableWrapper}
          tableClassName={styles.readinessTable}
          columns={readinessTableColumns}
          defaultData={allDatesWithPostponed}
        />
        <FunctionButton
          className={styles.addDataBtn}
          name="Add data"
          autoWidth
          disabled={addDataButtonDisabled}
          onClick={() => {
            onAddDataClick();
          }}
        />
      </div>
      <div className={styles.readdCol}>
        <div className={styles.readinessColumnT}>
          <TextInput
            name="readinessActualDate"
            label={t("Date: Readiness (f)")}
            width="10em"
            readOnly
            generalClassName={styles.readinessDateInput}
          />
          <div>
            <FunctionButton
              type="button"
              className={styles.transferToLogistBtn}
              name="The product is ready"
              autoWidth
              onClick={() => {
                onProductReadyClick();
              }}
              disabled={
                allDatesWithPostponed?.length === 0 ||
                !!findRequestDateType(currentRequest?.requestDates, REQUEST_DATES_IDS.READINESS_ID)?.actualDate
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReadinessDatesBlock;
