import React from "react";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import OrderProcessing from "../Components/Requests/OrderProcessing/OrderProcessing";
import FactoryRequestModificate from "../Components/Requests/RequestCreation/FactoryRequest/FactoryRequestModificate";
import RequestCreation from "../Components/Requests/RequestCreation/RequestCreation";
import RequestRegister from "../Components/Requests/RequestRegister/RequestRegister";
import RequestsInProgress from "../Components/Requests/RequestsInProgress/RequestsInProgress";
import ReadyStatusReq from "../Components/Requests/RequestWithReadyStatus/ReadyStatusReq";
import SkuVed from "../Components/SKU/VED/SkuVed";
import SkuVed2 from "../Components/SKU/VED/SkuVed2";
import FactoryCC from "../Components/VED/settings/conditions&contactsFactory/FactoryCC";
import VedSettingsGeneral from "../Components/VED/VedSetiingsGeneral/VedSettingsGeneral";
import ReceiptAndVerificationPf from "../Components/VED/Verification/ReceiptAndVerificationPf/ReceiptAndVerificationPf";

const SkuVedPage = () => {
  return (
    <Routes>
      <Route path="/bos/request-registr" element={<RequestRegister />} />
      <Route path="/orderProcess" element={<OrderProcessing />} />
      <Route path="/reqCreate" element={<RequestCreation />} />
      <Route path="/requestToFactoryMod" element={<FactoryRequestModificate />} />
      <Route path="/reqInProgress" element={<RequestsInProgress />} />
      <Route path="/readyReq" element={<ReadyStatusReq />} />
      <Route path="/factorySettings" element={<FactoryCC />} />
      <Route path="/vedSettingsGeneral" element={<VedSettingsGeneral />} />
      <Route path="/receiptAndVerific" element={<ReceiptAndVerificationPf />}></Route>
    </Routes>
  );
};

export default SkuVedPage;
