export const DOCUMENTS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Document date",
    accessorKey: "docDate",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
    size: 100,
    isSortable: true,
  },

  {
    header: "Added by",
    accessorKey: "createdBy",
    size: 100,
    isSortable: true,
  },
]);

export const LOGIST_FACTORY_DOCUMENTS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Document date",
    accessorKey: "docDate",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
    size: 100,
    isSortable: true,
  },

  {
    header: "Load date",
    accessorKey: "createdAt",
    size: 100,
    isSortable: true,
  },
]);
