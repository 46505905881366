import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectStockPoolFilters} from "../../redux/selectors";

const useLogistFilters = () => {
  const {
    i18n: {language},
  } = useTranslation();

  const filters = useSelector(selectStockPoolFilters);
  // console.log("filters", filters);

  const brandsOptions = useMemo(() => {
    return (
      filters?.brands?.map(item => {
        return {title: item.name, value: item.brandId};
      }) || []
    );
  }, [filters]);

  const countryOptions = useMemo(() => {
    return (
      filters?.countries?.map(item => {
        return {title: item.shortName, value: item.countryId};
      }) || []
    );
  }, [filters]);

  const requestStatusOptions = useMemo(() => {
    return (
      filters?.statuses?.map(item => {
        return {title: item[language === "en" ? "engName" : "ukrName"], value: item.statusId};
      }) || []
    );
  }, [filters, language]);

  const coManagersOptions = useMemo(() => {
    return (
      filters?.coManagers?.map(item => {
        const name = item?.persona
          ? language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName
          : item.name;
        return {
          title: name,
          value: item.managerCoId,
        };
      }) || []
    );
  }, [filters, language]);

  const feaManagersOptions = useMemo(() => {
    return (
      filters?.feaManagers?.map(item => {
        const name = item?.persona
          ? language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName
          : item.name;
        return {
          title: name,
          value: item.managerFeaId,
        };
      }) || []
    );
  }, [filters, language]);

  const requestNumberOptions = filters?.requestNumbers?.map(item => ({title: item, value: item})) ?? [];

  const logistOptions = filters?.logisticManagers?.map(item => ({title: item.name, value: item.managerLogistId})) ?? [];

  const clientNumberOptions = filters?.clientNumbers?.map(item => ({title: item, value: item})) ?? [];

  const invoiceNumberOptions = filters?.invoiceNumbers?.map(item => ({title: item, value: item})) ?? [];

  const cargoNumberOptions = filters?.cargoNumbers?.map(item => ({title: item, value: item})) ?? [];

  const locationsOptions = filters?.locations?.map(item => ({title: item, value: item})) ?? [];

  const stockPoolNumberOptions = filters?.stockPoolNumbers?.map(item => ({title: item, value: item})) ?? [];

  const orderNumberOptions = filters?.orderNumbers?.map(item => ({title: item, value: item})) ?? [];

  const carriersOptions = useMemo(() => {
    return (
      filters?.carriers?.map(item => {
        return {title: item.name, value: item.carrierId};
      }) || []
    );
  }, [filters]);

  const warehouseOptions = useMemo(() => {
    return (
      filters?.warehouses?.map(item => {
        return {title: item.name || item.address, value: item.warehouseId};
      }) || []
    );
  }, [filters]);

  return {
    brandsOptions,
    feaManagersOptions,
    coManagersOptions,
    requestStatusOptions,
    countryOptions,
    requestNumberOptions,
    logistOptions,
    clientNumberOptions,
    invoiceNumberOptions,
    cargoNumberOptions,
    locationsOptions,
    carriersOptions,
    stockPoolNumberOptions,
    warehouseOptions,
    orderNumberOptions,
    filters,
  };
};

export default useLogistFilters;
