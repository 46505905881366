import {createSlice} from "@reduxjs/toolkit";

import {fetchAllReminders, fetchSystemDates} from "./operations";

export const REMINDERS_STORE_NAME = "logistReminders";

const initialState = {
  systemDates: null,
  reminders: null,

  remindersLoading: false,
  error: false,
};

const RemindersSlice = createSlice({
  name: REMINDERS_STORE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //get
      .addCase(fetchSystemDates.fulfilled, (state, {payload}) => {
        state.systemDates = payload;
      })
      .addCase(fetchAllReminders.fulfilled, (state, {payload}) => {
        state.reminders = payload;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(REMINDERS_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(REMINDERS_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(REMINDERS_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.remindersLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.remindersLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.remindersLoading = false;
  state.error = error;
}

const {actions, reducer} = RemindersSlice;
export const {smth} = actions;

export default reducer;
