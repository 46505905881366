import {useEffect, useRef} from "react";

function IndeterminateCheckbox({indeterminate, className = "", disabled, ...rest}) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} disabled={disabled} className={className + " cursor-pointer"} {...rest} />;
}
export default IndeterminateCheckbox;
