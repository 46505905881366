import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../../../../utilities/ToolTip";
import {MoneyInput, TextAreaInput, TextInput} from "../../../../../../Inputs";
import {Select} from "../../../../../../Inputs/SelectField";
import SingleFilePicker from "../../../../../../Inputs/SingleFilePicker";

import usePaidBlockService from "./usePaidBlockService";

const PaidBlock = () => {
  const {t} = useTranslation();

  const {
    onPayUpBtnClick,
    onDeleteConfirmingDocBtnClick,
    docTypeOptions,
    onFileAdding,
    isPaidBlockDisabled,
    isConfirmingDocPickerDisabled,
    confirmingDocPickerTitle,
    isPayUpBtnDisabled,
    isRemoveConfirmingDocBtnDisabled,
    payUpBtnTitle,
    arePrePdfFieldsDisabled,
  } = usePaidBlockService();

  return (
    <>
      <div className="header-blocks header-blocks_last" style={{marginBottom: "28px"}}>
        2.{t("Payment documents") + ":"}
      </div>
      <div className="fc nowrap jcsb">
        <TextInput
          label={t("Payment doc for f") + " №*:"}
          name="confirmingDocNumber"
          width="10em"
          autolabel
          readOnly={arePrePdfFieldsDisabled}
        />
        <Select
          label={t("Type")}
          options={docTypeOptions}
          name="confirmingDocType"
          width="7em"
          // labelwidth="2em"
          autolabel
          readOnly={arePrePdfFieldsDisabled}
        />
      </div>
      <div className="fc jcc" style={{padding: "1em"}}>
        <ToolTip title={confirmingDocPickerTitle}>
          <SingleFilePicker disabled={isConfirmingDocPickerDisabled} addFunc={onFileAdding} accept={".pdf"} />
        </ToolTip>
      </div>
      <MoneyInput
        label={t("Document sum") + " *:"}
        name="confirmingDocSum"
        width="10em"
        autolabel
        generalStyles={{justifyContent: "flex-end"}}
        disabled={isPaidBlockDisabled}
      />
      <TextAreaInput
        name="confirmingDocNote"
        width={"101%"}
        placeholder={t("enter note")}
        style={{marginTop: "10px"}}
        disabled={isPaidBlockDisabled}
      />
      <div className="button-pay">
        <FunctionButton
          name="Delete payment record"
          autoWidth
          disabled={isRemoveConfirmingDocBtnDisabled}
          onClick={onDeleteConfirmingDocBtnClick}
        />
        <ToolTip title={payUpBtnTitle}>
          <FunctionButton name="Document is payed" autoWidth disabled={isPayUpBtnDisabled} onClick={onPayUpBtnClick} />
        </ToolTip>
      </div>
    </>
  );
};

export default PaidBlock;
