import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ModalWithTabs from "../../Modal/ModalWithTabs/ModalWithTabs";
import RequestPlanSkuTable from "../../Requests/RequestCreation/RequestPlanSkuTable";
import {getRequestInfo, getStockPoolCheckLIst} from "../../Requests/RequestsSlice";
import LogModOrderCheckList from "../LogModOrderCheckList/LogModOrderCheckList";
import LogModRqstCheckList from "../LogModRqstCheckList/LogModRqstCheckList";
import LogModSPCheckList from "../LogModSPCheckList/LogModSPCheckList";
import LogModTpPoolCheckList from "../LogModTpPoolCheckList/LogModTpPoolCheckList";
import {selectChosenCargo, selectFetchedStockPool} from "../redux/selectors";

import {SLEEPING_REQ_MODAL_TYPES} from "./constants";

const LogModRqstCheckListMC = ({modalShow, setModalShow, type, selectedSP, modalTypes = SLEEPING_REQ_MODAL_TYPES}) => {
  const {t} = useTranslation();
  const [tabs, setTabs] = useState([]);
  const currentRequest = useSelector(getRequestInfo);
  const currentSP = useSelector(getStockPoolCheckLIst);
  const fetchedSP = useSelector(selectFetchedStockPool);
  const chosenCargo = useSelector(selectChosenCargo);
  const onItemOpen = ({item}) => {
    const ifAlreadyOpen = tabs.find(tab => tab.orderId == item?.orderId);
    if (ifAlreadyOpen) {
      return;
    }
    setTabs(prev => [
      ...prev,
      {
        label: `${t("Order number")} ${item?.orderNumber}`,
        index: prev.length + 1,
        orderId: item?.orderId,
      },
    ]);
  };

  const modalChildrensRender = useCallback(() => {
    return tabs.map((req, i) => <LogModOrderCheckList text={req.label} key={i} index={i} orderId={req.orderId} />);
  }, [tabs]);

  let Component, label;
  switch (type) {
    case modalTypes.request:
      Component = LogModRqstCheckList;
      label = `Інформація по Заявці № ${currentRequest?.requestNumber}`;
      break;
    case modalTypes.requestInfo:
      Component = RequestPlanSkuTable;
      label = t("request review") + currentRequest?.requestNumber;
      break;
    case modalTypes.sp:
      Component = LogModSPCheckList;
      label = `${t("Stock Pool №")} ${
        currentSP?.stockPoolNumber ?? selectedSP?.stockPoolNumber ?? fetchedSP?.stockPoolNumber
      }`;
      break;
    case modalTypes.tp:
      Component = LogModTpPoolCheckList;
      label = `${t("Transport Pool")} № ${chosenCargo?.cargoNumber ?? ""}`;
      break;
    case modalTypes.order:
      Component = LogModOrderCheckList;
      label = `${t("Order number")} ${currentRequest?.requestNumber}`;
      break;
  }

  return (
    <>
      <ModalWithTabs
        closable={true}
        Component={Component}
        label={label}
        active={modalShow}
        setModalActive={setModalShow}
        modalTabs={tabs}
        setModalTabs={setTabs}
        onItemOpen={onItemOpen}
        closableTabs={true}
      >
        {modalChildrensRender()}
      </ModalWithTabs>
    </>
  );
};

export default LogModRqstCheckListMC;
