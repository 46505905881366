import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {BUTTON_COLORS} from "../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {makeFilterManager} from "../../../../utilities/makeFilterManager/makeFilterManager";
import ModalForm from "../../../Modal/FormModal";
import {LOGIST_WINDOW_TYPES} from "../../common/constants";
import CreatingTPMod from "../../FactoryBlock/FactoryCreatingTP/CreatingTPMod";
import {deselectSkuRequest} from "../../redux/LogistSlice";
import {fetchAllStockPoolsNew} from "../../redux/operations";
import {selectWarehouseRequests} from "../../redux/selectors";
import ChoiseOfTransformationMod from "../ChoiseOfTransformationMod/ChoiseOfTransformationMod";
import StorageAddServicesModal from "../StorageAddServicesModal/StorageAddServicesModal";

const WarehouseMainButtons = ({
  selectedWarehouse,
  checkedSp,
  creatingTPModalActive,
  setCreatingTPModalActive,
  setCheckedSp,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // const [creatingTPModalActive, setCreatingTPModalActive] = useState(false);
  const [isStorageAddServicesModalActive, setStorageAddServicesModalActive] = useState(false);
  const [isChoiseOfTransfModalActive, setChoiseOfTransfModalActive] = useState(false);

  const warehouseRequestsAfterAcception = useSelector(selectWarehouseRequests);

  const formTpDisable = warehouseRequestsAfterAcception?.some(item => item?.stockPool?.status?.statusId !== 8);

  const onAddServicesBtnClick = () => {
    setStorageAddServicesModalActive(true);
  };

  const WarehouseFilterManager = makeFilterManager(LOGIST_WINDOW_TYPES.WAREHOUSE);
  const warehouseFilters = WarehouseFilterManager.getFilters();
  return (
    <div className="fdr jcsb" style={{marginLeft: "-0.7em"}}>
      <FunctionButton
        name="Financial control"
        disabled
        style={{height: "3em", width: "12em"}}
        color={BUTTON_COLORS.RED}
      />
      <FunctionButton
        name="Financial transactions"
        style={{height: "3em", width: "12em"}}
        disabled
        color={BUTTON_COLORS.RED}
      />
      <FunctionButton
        name="Documents registration"
        style={{height: "3em", width: "12em"}}
        disabled
        color={BUTTON_COLORS.RED}
      />
      <FunctionButton
        name="Additional services"
        style={{height: "3em", width: "12em"}}
        disabled={!warehouseFilters?.warehouseId}
        onClick={onAddServicesBtnClick}
      />
      <FunctionButton
        name="SP transformation"
        style={{height: "3em", width: "12em"}}
        onClick={() => {
          setChoiseOfTransfModalActive(true);
          dispatch(deselectSkuRequest());
        }}
        disabled={checkedSp?.length !== 1 || !warehouseFilters?.warehouseId}
      />
      <FunctionButton
        name="Form TP"
        style={{height: "3em", width: "12em"}}
        onClick={() => {
          setCreatingTPModalActive(true);
        }}
        disabled={!warehouseFilters?.warehouseId || !formTpDisable}
      />

      <ModalForm
        closable={true}
        Component={CreatingTPMod}
        label={t("Creating (adding) to the TP")}
        active={creatingTPModalActive}
        setModalActive={setCreatingTPModalActive}
        selectedWarehouse={selectedWarehouse}
        identifier="warehouse"
        onModalClose={() => {
          dispatch(fetchAllStockPoolsNew({window: "Warehouse", ...warehouseFilters}));
        }}
        className="modal_sizes"
      />
      <ModalForm
        className="largeModal modal_sizes"
        closable={true}
        Component={StorageAddServicesModal}
        label={t("Additional services on the Warehouse for SP №")}
        active={isStorageAddServicesModalActive}
        setModalActive={setStorageAddServicesModalActive}
        currentWarehouseId={warehouseFilters?.warehouseId}
        onModalClose={() => {
          dispatch(fetchAllStockPoolsNew({window: "Warehouse", ...warehouseFilters}));
        }}
      />
      <ModalForm
        closable={true}
        Component={ChoiseOfTransformationMod}
        label={t("Choose transformation type")}
        active={isChoiseOfTransfModalActive}
        setModalActive={setChoiseOfTransfModalActive}
        checkedSp={checkedSp}
        onModalClose={() => {
          dispatch(fetchAllStockPoolsNew({window: "Warehouse", ...warehouseFilters}));
        }}
        selectedWarehouse={selectedWarehouse}
        setCheckedSp={setCheckedSp}
      />
    </div>
  );
};

export default WarehouseMainButtons;
