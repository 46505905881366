import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {fetchData} from "../../../../hook/axios.hook";
import {dateFormat} from "../../../../utilities/dateFormat";
import useSwal from "../../../../utilities/hooks/useSwal";
import useToaster from "../../../../utilities/hooks/useToaster";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import {ovhJoin} from "../../../../utilities/ovhJoin";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addDefectPhoto,
  clearDefect,
  deleteDefectPhoto,
  getDefect,
  selectDefect,
  updateDefect,
} from "../../../Requests/RequestsSlice";
import {selectCurrentSkuRequest} from "../../redux/selectors";
import {ORDER_ACCEPTANCE_NOT_COLUMNS} from "../constants";
import {generatePdf} from "../PDF/pdfAccNO";

const useOrderAcceptanceNo = ({notAcceptedSku}) => {
  const {initialValues, values, setValues, setFieldValue} = useFormikContext();
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const Toaster = useToaster();
  const SWAL = useSwal();
  const dispatch = useDispatch();

  const currentSp = useSelector(selectCurrentSkuRequest);
  const users = useSelector(state => state.person.users);
  const defect = useSelector(selectDefect);

  const [currentSkuId, setCurrentSkuId] = useState(null);
  const skuTableCurrent = notAcceptedSku?.find(sku => sku.skuId === currentSkuId);

  const fileArray = useMemo(() => {
    return defect?.photos?.map(photo => photo.imageUrl) ?? [];
  }, [defect?.photos.length]);

  useEffect(() => {
    if (!currentSp || !currentSp?.stockPoolId) {
      setValues(initialValues);
    } else {
      setValues(prev => ({
        ...prev,
        fdp: currentSp.stockPool?.cargo?.endPoint?.name ?? " - ",
        requestNumber: currentSp.request.requestNumber ?? "",
        orderNumber: currentSp.order.orderNumber ?? "11",
        managerCo: makeNameFromPersona({persona: currentSp.order.co.managerCo.persona, language}) ?? "",
        logist: makeNameFromPersona({persona: currentSp.stockPool.logist.persona, language}) ?? "",
        city: (currentSp.request.brand.factoryContacts ?? [])[0]?.address ?? "",
        actDate: dateFormat(Date.now()),
        commissionNote: [t("Act Logist Info No", {logistList: values?.commissionTextArea})],
        defectDescr: defect?.description ?? "",
      }));
    }
  }, [currentSp?.stockPoolId, defect?.description]);

  useEffect(() => {
    setFieldValue("commissionNote", [t("Act Logist Info No", {logistList: values?.commissionTextArea})]);
  }, [values?.commissionTextArea]);

  useEffect(() => {
    if (skuTableCurrent) {
      dispatch(getDefect(skuTableCurrent.defect.defectId));
    }
  }, [currentSkuId]);

  useEffect(() => {
    return () => {
      dispatch(clearDefect());
      setCurrentSkuId(null);
    };
  }, []);

  const commissionOptions =
    users?.map(({userId, persona}) => ({value: userId, title: makeNameFromPersona({persona, language})})) ?? [];
  const skuTableColumns = useColumns(ORDER_ACCEPTANCE_NOT_COLUMNS);

  const skuTableData =
    notAcceptedSku?.map((sku, index) => {
      const {
        brand,
        skuId,
        skuRequestId,
        name,
        nameEng,
        amount,
        retailPrice,
        complectDesc,
        material,
        materialType,
        color,
        measurementUnit,
        defect,
      } = sku;
      const ovh = ovhJoin(sku);
      return {
        id: skuId,
        skuRequestId,
        number: ++index,
        skuNumber: skuId,
        brandName: brand?.name ?? " - ",
        skuName: (language === "en" ? nameEng : name) ?? " - ",
        characteristics: [material, materialType, color].filter(item => !!item && item !== "").join("; "),
        ovh: ovh,
        complectDesc: complectDesc ?? " - ",
        amount: amount ?? " - ",
        measurementUnit: measurementUnit[language === "en" ? "engName" : "name"] ?? " - ",
        price: retailPrice ?? " - ",
        sum: (retailPrice * amount) / 1 ?? " - ",
        defectId: defect.defectId,
      };
    }) ?? [];

  const isAddCommissionBtnDisabled = !(values.commissionSelect || values.commissionInput);
  const isRemoveCommissionBtnDisabled = !values.commissionTextArea;
  const isSaveDefectImgBtnDisabled = !defect?.description;

  const isMarkedSkusIssuedBtnDisabled =
    values.commissionTextArea === "" || values.commissionNote === "" || notAcceptedSku.length === 0;

  const exportToPdf = async () => {
    const tablesData = await Promise.all(
      skuTableData.map(async sku => {
        const defect = await fetchData(`/skuRequest/defects/get/${sku.defectId}`, "get");
        return {
          ...sku,
          description: defect?.description,
          photos: defect?.photos,
        };
      }),
    );
    console.log("tablesData :>> ", tablesData);
    const common = {
      city: values?.city,
      date: values?.actDate,
      commission: values?.commissionTextArea,
      actText: t("Act Logist Info No", {logistList: values?.commissionTextArea}),
    };

    var pdf = generatePdf(tablesData, skuTableColumns, common);
    pdf.download(`act_defect_sp-${currentSp?.stockPoolId}_doc`);
    // pdf.open();
  };

  function onAddCommissionBtnClick() {
    const selectedCommissionOption = commissionOptions.find(option => option.value === values.commissionSelect);

    const nameSet = new Set(values.commissionTextArea.split(", "));
    nameSet.add(selectedCommissionOption?.title || "");
    nameSet.add(values.commissionInput);

    setFieldValue("commissionTextArea", [...nameSet].filter(text => text != "" && text).join(", "));
    setFieldValue("commissionInput", "");
    setFieldValue("commissionSelect", "");
  }

  function onRemoveCommissionBtnClick() {
    setValues(prev => ({...prev, commissionTextArea: prev.commissionTextArea.split(", ").slice(0, -1).join(", ")}));
  }

  function onSkuTableSelect(skuId) {
    if (!skuId) {
      dispatch(clearDefect());
      setCurrentSkuId(null);
    }
    setCurrentSkuId(skuId);
  }

  function onSaveBtnClick() {
    if (!defect || !values.defectDescr) return;

    dispatch(updateDefect({defectId: defect.defectId, description: values.defectDescr})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        Toaster.toastSuccess("Defect updated");
        dispatch(getDefect(skuTableCurrent.defect.defectId));
      }
    });
  }

  function onDefectPhotoAdd(arrFiles) {
    if (!defect || !currentSkuId) return;

    const formdata = new FormData();
    for (const file of arrFiles) {
      const filename = file.name;
      formdata.append("photo", file, filename);
    }

    if (formdata.has("photo")) {
      dispatch(addDefectPhoto({defectId: defect.defectId, formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          Toaster.toastSuccess("doc Success");
          dispatch(getDefect(skuTableCurrent.defect.defectId));
        }
      });
    }
  }

  function onDefectPhotoDelete(url) {
    if (!defect) return;

    const photoId = defect.photos.find(photo => photo.imageUrl === url)?.photoId;
    if (!photoId) return;

    SWAL.confirm({
      confirmFunc: () => {
        dispatch(deleteDefectPhoto(photoId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.toastSuccess("File deleted");
            dispatch(getDefect(defect.defectId));
          }
        });
      },
    });
  }

  function onDelBtnClick() {}
  function onMarkedSkusIssuedBtnClick() {}

  return {
    commissionOptions,
    skuTableColumns,
    skuTableData,
    onAddCommissionBtnClick,
    onRemoveCommissionBtnClick,
    fileArray,
    onDefectPhotoAdd,
    onDelBtnClick,
    onSaveBtnClick,
    onMarkedSkusIssuedBtnClick,
    isMarkedSkusIssuedBtnDisabled,
    isAddCommissionBtnDisabled,
    isRemoveCommissionBtnDisabled,
    onSkuTableSelect,
    currentSkuId,
    onDefectPhotoDelete,
    isSaveDefectImgBtnDisabled,
    exportToPdf,
  };
};

export default useOrderAcceptanceNo;
