import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../../utilities/dateFormat";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {DOCS_TABLE_COLUMNS} from "../../../../Requests/RequestsInProgress/ReceiptGoodsModal/utils/constants";
import {selectCurrentSkuRequest} from "../../../redux/selectors";
import {WAREHOUSE_CARGO_TABLE_COLUMNS} from "../../utils/constants";

const useWarehouseDocsAndCargoPartService = (setPdfFileArray = () => {}) => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const currentSkuRequest = useSelector(selectCurrentSkuRequest);

  const [currentWarehouseDoc, setCurrentWarehouseDoc] = useState(null);
  const [currentPackSpace, setCurrentPackSpace] = useState(null);

  const warehouseCargoTableColumns = useColumns(WAREHOUSE_CARGO_TABLE_COLUMNS);
  const warehouseDocumentsTableColumns = useColumns(DOCS_TABLE_COLUMNS);

  const warehouseDocumentsTableData = useMemo(() => {
    return (
      currentSkuRequest?.stockPool?.docs?.map(({docId, dateFrom, docType, docNumber, createdAt}, index) => {
        const docTypeName = i18n.language === "en" ? docType.nameEng : docType.nameUkr;

        return {
          number: index + 1,
          id: docId,
          docDate: dateFormat(dateFrom),
          docType: docTypeName,
          docNumber,
          createdAt: dateFormat(createdAt),
        };
      }) || []
    );
  }, [currentSkuRequest?.stockPool?.docs]);

  const warehouseCargoTableData = useMemo(
    () =>
      currentSkuRequest?.stockPool?.packingSpaces?.map((space, index) => {
        const {spaceNumber, volume, weight, note, length, width, height, spaceId} = space;

        const dimenSh = [length, width, height];
        const updatedNumbers = dimenSh.map(num => (num === null || num === "0.00" ? "0" : num));

        const proportions = updatedNumbers.every(item => item === "0")
          ? ""
          : `${updatedNumbers[0]}x${updatedNumbers[1]}x${updatedNumbers[2]}`;

        return {
          id: spaceId,
          number: ++index,
          stockPoolNumber: currentSkuRequest?.stockPool?.stockPoolNumber ?? " - ",
          placeNumber: spaceNumber ?? " - ",
          proportions: proportions ?? " - ",
          weight: weight ?? " - ",
          volume: volume ?? " - ",
          note: note ?? " - ",
        };
      }) || [],
    [currentSkuRequest?.stockPool?.packingSpaces],
  );

  useEffect(() => {
    if (currentWarehouseDoc?.stockPoolId !== currentSkuRequest?.stockPoolId) {
      setPdfFileArray([]);
    }
  }, [currentWarehouseDoc, currentSkuRequest]);

  const onWarehouseDocSelect = id => {
    if (!id || currentWarehouseDoc?.docId === id) {
      setCurrentWarehouseDoc(null);
      setPdfFileArray([]);
      return;
    }
    const currDoc = currentSkuRequest?.stockPool?.docs?.find(doc => doc.docId === id);

    setCurrentWarehouseDoc(currDoc);
    setPdfFileArray([currDoc?.fileUrl]);
  };

  return {
    warehouseCargoTableColumns,
    warehouseDocumentsTableColumns,
    warehouseDocumentsTableData,
    warehouseCargoTableData,
    onWarehouseDocSelect,
    currentWarehouseDoc,
    currentPackSpace,
  };
};

export default useWarehouseDocsAndCargoPartService;
