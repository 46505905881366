import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../utilities/QuestionItem";
import PageContainer from "../../conteiners/PageContainer";
import {
  fetchRequests,
  getCheckedRequestIds,
  getCurrentNote,
  setCheckedRequestIds,
  setCurrentSkuRequestId,
  turnIntoRequest,
} from "../RequestsSlice";

import OrderBlock from "./OrderBlock/OrderBlock";
import RequestSkuBlock from "./RequestSkuBlock/RequestSkuBlock";
import TableBlock from "./TableBlock/TableBlock";

import "./order.scss";
import styles from "./OrderProcessing.module.scss";

const OrderProcessing = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequestIds = useSelector(getCheckedRequestIds);
  let note = useSelector(getCurrentNote);
  note = note?.replaceAll("\n", " / ");

  const updateAndCleanAfterRequest = () => {
    dispatch(setCheckedRequestIds([]));
    dispatch(fetchRequests({window: "Distributed requests"}));
  };

  const onCreateRequestBtnClick = () => {
    if (currentRequestIds.length === 0) {
      toast.error(t("Please select at least one request"));
      return;
    }
    dispatch(turnIntoRequest({requestIds: currentRequestIds, note})).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("Request was created successfully"));
        dispatch(setCurrentSkuRequestId(null));
      }
    });
    updateAndCleanAfterRequest();
  };

  return (
    <PageContainer title={t("Order processing")}>
      <div className="form_wrapper">
        <section className={styles.orderAndSkuBlocksSection}>
          <div className={styles.orderBlockWrapper}>
            <OrderBlock />
          </div>
          <div className={styles.requestSkuBlockWrapper}>
            <RequestSkuBlock />
          </div>
        </section>
        <section className={styles.reqBlocksSection}>
          <h2 className={styles.reqBlockHeader + " label"} style={{width: "50%", paddingRight: "105px"}}>
            2.
            {t("Requests")}
            <QuestionItem title="only1" />
            <FunctionButton
              name={t("Create request")}
              onClick={onCreateRequestBtnClick}
              disabled={!currentRequestIds?.length > 0}
              autoWidth
              style={{margin: "0 auto"}}
            />
          </h2>

          <TableBlock />
        </section>
      </div>
    </PageContainer>
  );
};

export default OrderProcessing;
