import {Form, Formik} from "formik";

import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";

import OnPaymentBlock from "./PaymentToFactoryParts/OnPaymentBlock/OnPaymentBlock";
import PaidBlock from "./PaymentToFactoryParts/PaidBlock/PaidBlock";
import TopBlock from "./PaymentToFactoryParts/TopBlock/TopBlock";
import usePaymentToFactoryModalService from "./usePaymentToFactoryModalService";

const PaymentToFactoryModal = () => {
  const {initialValues, fileArray} = usePaymentToFactoryModalService();

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form style={{display: "flex", flexDirection: "column"}}>
        <TopBlock />

        <div className="paynent-main paynent-main-general">
          <div className="left-part factory_line">
            <OnPaymentBlock />
            <PaidBlock />
          </div>

          <div className="right-part">
            <div className="kpPreview__box" style={{height: "100%"}}>
              <NewPdfViewer fileArray={fileArray} />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default PaymentToFactoryModal;
