import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import PageContainer from "../../conteiners/PageContainer";
import ModalForm from "../../Modal/FormModal";

import LogModSettingsDeadlines from "./LogModSettingsDeadlines/LogModSettingsDeadlines";
import LogModSettingsStorage from "./LogModSettingsStorage/LogModSettingsStorage";
import LogModSettingsStorageServices from "./LogModSettingsStorageServices/LogModSettingsStorageServices";
import LogModSettingsTransporter from "./LogModSettingsTransporter/LogModSettingsTransporter";
import LogSettingsSectionBlock from "./LogSettingsSectionBlock/LogSettingsSectionBlock";

const LogSettings = () => {
  const {t} = useTranslation();

  const LOG_SETTINGS_MODAL_TYPES = Object.freeze({
    STORAGE: t("Settings Warehouse"),
    TRANSPORTER: t("Settings Transporter"),
    DEADLINES: t("References: Setting control terms"),
    SERVICE_LIST: t("Directories: List of Warehouse services"),
  });

  const [currentType, setCurrentType] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const currentComponent = useMemo(() => {
    switch (currentType) {
      case LOG_SETTINGS_MODAL_TYPES.STORAGE:
        return LogModSettingsStorage;

      case LOG_SETTINGS_MODAL_TYPES.TRANSPORTER:
        return LogModSettingsTransporter;

      case LOG_SETTINGS_MODAL_TYPES.DEADLINES:
        return LogModSettingsDeadlines;

      case LOG_SETTINGS_MODAL_TYPES.SERVICE_LIST:
        return LogModSettingsStorageServices;

      default:
        return null;
    }
  }, [currentType]);

  const firstColumnBlocks = [
    {
      headingNumber: 1,
      heading: "Warehouses",
      text: "WarehousesBlockText",
      onClick: () => {
        setCurrentType(LOG_SETTINGS_MODAL_TYPES.STORAGE);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 2,
      heading: "Transporters",
      text: "TransportersBlockText",
      onClick: () => {
        setCurrentType(LOG_SETTINGS_MODAL_TYPES.TRANSPORTER);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 3,
      heading: "Warehouse service list",
      text: "WarehouseServiceListBlockText",
      onClick: () => {
        setCurrentType(LOG_SETTINGS_MODAL_TYPES.SERVICE_LIST);
        setModalActive(true);
      },
      disabled: false,
    },
    {
      headingNumber: 4,
      heading: "Logist block document type",
      text: "LogistBlockDocumentTypeText",
      onClick: () => {},
      disabled: true,
    },
    {
      headingNumber: 5,
      heading: "Control terms by document types",
      text: "ControlTermsByDocumentTypesBlockText",
      onClick: () => {
        setCurrentType(LOG_SETTINGS_MODAL_TYPES.DEADLINES);
        setModalActive(true);
      },
      disabled: false,
    },
  ];

  return (
    <PageContainer title={t("Settings (Logist)")}>
      <div className="h100 grid3Col">
        <div className="borderRight paddingRight">
          {firstColumnBlocks.map(({headingNumber, heading, text, onClick, disabled}) => (
            <LogSettingsSectionBlock
              key={headingNumber}
              headingNumber={headingNumber}
              heading={heading}
              text={text}
              onClick={onClick}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="paddingLeft paddingRight"></div>
        <div className="borderLeft paddingLeft"></div>
      </div>

      <ModalForm
        closable={true}
        Component={currentComponent}
        label={currentType}
        active={modalActive}
        setModalActive={setModalActive}
        className="largeModal"
      />
    </PageContainer>
  );
};

export default LogSettings;
