import React from "react";
import {Route, Routes} from "react-router-dom";

import FinSettings from "../../Components/FinBlock/FinSettings/FinSettings";
import PaymentsToFactory from "../../Components/FinBlock/PaymentsForPf/PaymentsToFactory/PaymentsToFactory";

const FinBlock = () => {
  return (
    <Routes>
      <Route path="/paymentsregistr" element={<PaymentsToFactory />}></Route>
      <Route path="/fin_settings" element={<FinSettings />}></Route>
    </Routes>
  );
};

export default FinBlock;
