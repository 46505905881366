// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpPreview__container {
  min-height: 60vh;
  margin-bottom: 1em;
}
.kpPreview__box {
  width: 100%;
  height: 90%;
  background-color: white;
  margin-bottom: 10px;
}
.kpPreview__box .page-control-bar {
  bottom: 1em;
}
.kpPreview__box .doc_view__options {
  bottom: 1em;
}
.kpPreview__box .doc_view__download.pdf {
  bottom: 0em;
}`, "",{"version":3,"sources":["webpack://./src/Components/KP/kp/KpPreview/KpPreview.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,kBAAA;AAAR;AAEI;EACI,WAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AAEO;EAAmB,WAAA;AAC1B;AAAQ;EAAmB,WAAA;AAG3B;AADY;EAEI,WAAA;AAEhB","sourcesContent":[".kpPreview{\n    &__container{\n        min-height: 60vh;\n        margin-bottom: 1em;\n    }\n    &__box{\n        width: 100%;\n        height: 90%;\n        background-color: white;\n        margin-bottom: 10px;\n\n       .page-control-bar {bottom: 1em;} \n        .doc_view__options{bottom: 1em;}\n        .doc_view__download {\n            &.pdf{\n             \n                bottom:0em;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
