import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectDoc} from "../../redux/PaymentOperationsSlice";
import {
  getDocTypes,
  getPaymentDocs,
  getPaymentSchemas,
  getReaquestPayments,
  selectCurrentPaymentPayDoc,
} from "../../redux/PaymentSelectors";

const usePaymentsData = () => {
  const dispatch = useDispatch();

  const {
    i18n: {language},
  } = useTranslation();

  const docTypesRaw = useSelector(getDocTypes),
    paymentDocsRaw = useSelector(getPaymentDocs),
    // docsForPaymentRaw = useSelector(getDocsForPayment),
    // docsByPaymentRaw = useSelector(getDocsByPayment),
    requestPaymentsRaw = useSelector(getReaquestPayments),
    paymentSchemasRaw = useSelector(getPaymentSchemas);

  const nameLang = language === "en" ? "Eng" : "Ukr";
  const [curentPaymentDoc, setCurrentPaymet] = useState(null);

  const changeRadio = value => {
    setCurrentPaymet(value);
    dispatch(selectDoc({number: 1, docId: value}));
  };

  const columnsForPayment = [
    {
      header: "№",
      accessorKey: "number",
      size: 15,
    },
    {
      header: <input type="radio" name="headerR" value={""} checked disabled={true} style={{height: "0.7em"}} />,
      accessorKey: "radioBtn",
      size: 15,
      cell: value => {
        var key = value.getValue();
        if (key) {
          return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <input
                type="radio"
                name="paymentDocRadio"
                value={key}
                checked={curentPaymentDoc === key}
                onChange={e => {
                  e.stopPropagation();
                  changeRadio(key);
                }}
              />
            </div>
          );
        }
      },
    },
    {
      header: "Doc. for payment",
      accessorKey: "docNumber",
    },
    {
      header: "Document type",
      accessorKey: "docType",
    },
  ];

  // const getDocData = useCallback(
  //   docs => {
  //     return (
  //       docs?.map((doc, i) => ({
  //         id: doc.docId,
  //         number: i + 1,
  //         doc: doc.docNumber,
  //         docType: doc?.docType?.["name" + nameLang] || doc?.docTypeId,
  //         notes: doc.note,
  //       })) || []
  //     );
  //   },
  //   [nameLang],
  // );

  const docTypes =
    docTypesRaw?.map(type => ({
      title: type["name" + nameLang],
      value: type.docTypeId,
    })) || [];

  const paymentSchemas = useMemo(
    () =>
      paymentSchemasRaw?.map((schema, i) => ({
        id: schema.schemaId,
        radioBtn: schema.schemaId,
        number: i + 1,
        name: schema["name" + nameLang],
        desc: "",
      })) || [],
    [paymentSchemasRaw, nameLang],
  );

  const paymentDocs = useMemo(
    () =>
      paymentDocsRaw?.map((doc, i) => {
        return {
          id: doc.docId,
          radioBtn: doc.docId,
          number: i + 1,
          docNumber: doc.docNumber,
          docType: doc?.docType?.["name" + nameLang] || doc?.docTypeId,
        };
      }) || [],
    [paymentDocsRaw],
  );

  const currentPaymentPayDoc = useSelector(selectCurrentPaymentPayDoc);
  // console.log("currentPaymentPayDoc :", currentPaymentPayDoc);
  const {docId, docNumber = "", docType = "", note = ""} = currentPaymentPayDoc ?? {};
  // const docsForPayment = currentPaymentPayDoc?.map(({docId, docNumber = "", docType = "", note = ""} = {}, i) => ({
  //   id: docId,
  //   number: i + 1,
  //   docNumber,
  //   docType: docType?.["name" + nameLang] || "",
  //   note,
  // }));
  const docsForPayment = currentPaymentPayDoc
    ? [{id: docId, number: 1, docNumber, docType: docType?.["name" + nameLang] || "", note}]
    : [];

  const requestPayments = useMemo(
    () =>
      requestPaymentsRaw?.map((doc, i) => {
        const {paymentId, paymentDoc, paymentSchema, status, noteToFD, confirmingDoc, noteToFEA} = doc;
        return {
          id: paymentId,
          number: i + 1,
          status: status?.ukrName,
          doc: paymentDoc?.docNumber,
          pDocType: paymentDoc?.docType?.["name" + nameLang] || paymentDoc?.docType?.docTypeId,
          paymentSchema: paymentSchema?.["name" + nameLang] || "",
          noteToFD,
          confirmingDoc: confirmingDoc?.docNumber,
          cDocType: confirmingDoc?.docType?.["name" + nameLang] || confirmingDoc?.docType?.docTypeId,
          noteToFEA,
        };
      }),
    [requestPaymentsRaw],
  );

  return {
    docTypes,
    paymentSchemas,
    paymentDocs,
    docsForPayment,
    requestPayments,
    columnsForPayment,
    curentPaymentDoc,
    setCurrentPaymet,
  };
};
export default usePaymentsData;
