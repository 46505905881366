import {useTranslation} from "react-i18next";

import {dateFormat} from "../../../../../utilities/dateFormat";

const useTransportDocsTableDataService = docs => {
  const {i18n} = useTranslation();

  return (
    docs?.map(({docId, docType, docNumber, createdAt, dateFrom}, i) => {
      const docTypeN = i18n === "eng" ? docType?.nameEng : docType?.nameUkr;

      return {
        id: docId,
        number: i + 1,
        docType: docTypeN ?? " - ",
        docNumber: docNumber ?? " - ",
        docDate: dateFormat(dateFrom) ?? " - ",
        createdAt: dateFormat(createdAt) ?? " - ",
      };
    }) || []
  );
};

export default useTransportDocsTableDataService;
