import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import {fetchList} from "../../../../../hook/axios.hook";
import delIcon from "../../../../../images/icons/delete.png";
import saveIcon from "../../../../../images/icons/save.png";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../../conteiners/ReactTable";
import {TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {
  TRANSPORTER_NAMES_TABLE_COLUMNS,
  WAREHOUSE_NAMES_TABLE_COLUMNS,
  WAREHOUSE_TYPES_TABLE_COLUMNS,
} from "./constants";

export const TYPE_NAME_TABLES_BLOCK_TYPES = Object.freeze({WAREHOUSE: "warehouse", TRANSPORTER: "transporter"});

const TypeNameTables = ({
  firstTableData,
  secondTableData,
  type,
  firstTableCurrent,
  secondTableCurrent,
  onFirstTableSelect,
  onSecondTableSelect,
  onTypeSave,
  onTypeDelete,
  onNameSave,
  onNameDelete,
}) => {
  const {t} = useTranslation();

  const warehouseTypesTableColumns = useColumns(WAREHOUSE_TYPES_TABLE_COLUMNS);
  const warehouseNamesTableColumns = useColumns(WAREHOUSE_NAMES_TABLE_COLUMNS);

  const transporterNamesTableColumns = useColumns(TRANSPORTER_NAMES_TABLE_COLUMNS);

  const secondTableColumns =
    type === TYPE_NAME_TABLES_BLOCK_TYPES.WAREHOUSE ? warehouseNamesTableColumns : transporterNamesTableColumns;

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (type === TYPE_NAME_TABLES_BLOCK_TYPES.WAREHOUSE) {
      fetchList("references/countries").then(countries => {
        setCountries(countries);
      });
    }
  }, []);

  const countryOptions = countries?.map(({countryId, shortName}) => ({title: shortName, value: countryId}));

  const {values} = useFormikContext();
  const areTypeBtnsDisabled = !values.type;
  const areNameBtnsDisabled =
    type === TYPE_NAME_TABLES_BLOCK_TYPES.WAREHOUSE
      ? !values.itemName || !values.warehouseCountry || !values.type
      : !values.itemName || !values.type;

  return (
    <div className="grid2Col mb">
      <div>
        <div className="row mb0_5">
          <TextInput label={t("Type")} name={"type"} autolabel generalClassName="fGrow" />
          <div className="row gap0_5">
            <ImageButton
              src={saveIcon}
              onClick={onTypeSave}
              alt="save"
              width={2}
              height={1.3}
              disabled={areTypeBtnsDisabled}
              tooltipMessage="Save new type"
            />
            <ImageButton
              src={delIcon}
              onClick={onTypeDelete}
              alt="delete"
              width={1.2}
              height={1.2}
              disabled={areTypeBtnsDisabled}
              tooltipMessage="Delete chosen type"
            />
          </div>
        </div>
        <ReactTable
          className="tableTac"
          columns={warehouseTypesTableColumns}
          defaultData={firstTableData}
          style={{height: "21rem"}}
          current={firstTableCurrent}
          onSelect={onFirstTableSelect}
        />
      </div>
      <div>
        {type === TYPE_NAME_TABLES_BLOCK_TYPES.WAREHOUSE ? (
          <Select label={t("Warehouse Country")} name="warehouseCountry" options={countryOptions} />
        ) : null}
        <div className="row mb0_5">
          <TextInput
            label={t(type === TYPE_NAME_TABLES_BLOCK_TYPES.WAREHOUSE ? "Warehouse name" : "Title")}
            name={"itemName"}
            autolabel
          />
          <div className="row gap0_5">
            <ImageButton
              src={saveIcon}
              onClick={onNameSave}
              alt="save"
              width={2}
              height={1.3}
              disabled={areNameBtnsDisabled}
              tooltipMessage="Save new name"
            />
            <ImageButton
              src={delIcon}
              onClick={onNameDelete}
              alt="delete"
              width={1.2}
              height={1.2}
              disabled={!secondTableCurrent}
              tooltipMessage="Delete chosen name"
            />
          </div>
        </div>
        <ReactTable
          className="tableTac"
          columns={secondTableColumns}
          defaultData={secondTableData}
          style={{height: "19.5rem"}}
          current={secondTableCurrent}
          onSelect={onSecondTableSelect}
        />
      </div>
    </div>
  );
};

TypeNameTables.propTypes = {
  firstTableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  secondTableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.oneOf(Object.values(TYPE_NAME_TABLES_BLOCK_TYPES)).isRequired,
  onFirstTableSelect: PropTypes.func,
  onSecondTableSelect: PropTypes.func,
  firstTableCurrent: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  secondTableCurrent: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  onTypeSave: PropTypes.func,
  onTypeDelete: PropTypes.func,
  onNameSave: PropTypes.func,
  onNameDelete: PropTypes.func,
};

export default TypeNameTables;
