import React, {useEffect, useState} from "react";
import {Stack, Switch} from "@mui/material";
import {styled} from "@mui/material/styles";

const SwitchStyled = styled(Switch)(({theme}) => ({
  marginLeft: 0,
  "& .MuiSwitch-input": {
    width: "300%",
    height: "100%",
  },
}));

const PdfFormatSwith = ({setFormat}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setFormat(checked ? "A3" : "A4");
  }, [checked]);

  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="center">
        <p id="checkFontSize">A4</p>
        <SwitchStyled checked={checked} onChange={handleChange} />
        <p>A3</p>
      </Stack>
    </div>
  );
};

export default PdfFormatSwith;
