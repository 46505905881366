import React from "react";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import SkuVed from "../Components/SKU/VED/SkuVed";
import SkuVed2 from "../Components/SKU/VED/SkuVed2";

const SKUPage = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <SkuVed />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sku2"
        element={
          <ErrorBoundary>
            <SkuVed2 />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
};

export default SKUPage;
