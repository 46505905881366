import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, useFormikContext, withFormik} from "formik";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../hook/debounced";
import FilterButton from "../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../utilities/checkEmptyFields";
import {dateFormat} from "../../utilities/dateFormat";
import useSessionFilters from "../../utilities/hooks/useSessionFilters";
import {DateInput, TextAreaInput, TextInput} from "../Inputs";
import {Select} from "../Inputs/SelectField";
import ChosenFiltersLine from "../Requests/Filters/ChosenFiltersLine";

export const FIELD_TYPES = Object.freeze({TEXT: "text", SELECT: "select", DATE: "date", TEXTAREA: "textarea"});

const BaseFilters = ({
  window,
  fields = [],
  getFilterData = () => {},
  onFiltersSet = () => {},
  onFiltersDel = () => {},
  disabled = false,
  className = null,
  style = {},
}) => {
  const {t} = useTranslation();

  const {values, setValues} = useFormikContext();
  const {filters, setFilters, clearFilters, initialFilters} = useSessionFilters(window);

  const [areFiltersDefined, setAreFiltersDefined] = useState(!!filters);

  useEffect(() => {
    if (initialFilters) {
      setValues({...initialFilters});
      setAreFiltersDefined(true);
    }
  }, []);

  const FieldsMarkup = fields.map(field => {
    const {
      type,
      name,
      label,
      options,
      width = "100%",
      readOnly = false,
      makeEffect = () => {},
      className = null,
      style = {},
    } = field;

    switch (type) {
      case FIELD_TYPES.TEXT:
        return (
          <TextInput
            label={t(label)}
            name={name}
            width={width}
            readOnly={readOnly}
            className={className}
            style={style}
            key={name}
          />
        );
      case FIELD_TYPES.SELECT:
        return (
          <Select
            label={t(label)}
            name={name}
            options={options}
            width={width}
            readOnly={readOnly}
            makeEffect={makeEffect}
            style={style}
            key={name}
          />
        );
      case FIELD_TYPES.DATE:
        return (
          <DateInput
            label={label}
            name={name}
            options={options}
            width={width}
            readOnly={readOnly}
            style={style}
            flatpikrConfig={field.flatpikrConfig}
            key={name}
          />
        );
      case FIELD_TYPES.TEXTAREA:
        return (
          <TextAreaInput
            label={label}
            name={name}
            options={options}
            width={width}
            readOnly={readOnly}
            style={style}
            placeholder={field.placeholder ?? ""}
            key={name}
          />
        );
      default:
        return null;
    }
  });

  const [savedFilters, setSavedFilters] = useState({});
  useEffect(() => {
    const filters = JSON.parse(sessionStorage.getItem(window));
    if (filters && Object.keys(filters).length > 0) setSavedFilters(filters);
  }, [values]);

  const filterNames =
    fields?.reduce(
      (result, field) => ({
        ...result,
        [field.name]: {
          name: t(field.label),
          options: field.options,
          value: field.name?.startsWith("date")
            ? dateFormat(savedFilters[field.name])
            : typeof savedFilters[field.name] === "number"
            ? ""
            : savedFilters[field.name],
        },
      }),
      {},
    ) ?? {};

  function onFilter(newFilters) {
    const params = checkEmptyFields(newFilters ?? values);

    if (Object.keys(params).length > 0) {
      setAreFiltersDefined(true);
      delayedFilter(params);
      setFilters(params);
      setSavedFilters(params);
    }
    if (newFilters && Object.keys(params).length === 0) {
      setAreFiltersDefined(false);
      delayedFilter(params);
      clearFilters();
      setSavedFilters(params);
    }

    onFiltersSet(params);
  }

  const updateFilterQuery = params => {
    getFilterData(params);
  };

  const delFilters = () => {
    updateFilterQuery({window});
    clearFilters();
    setValues(values =>
      Object.keys(values)
        .map(key => ({[key]: ""}))
        .reduce((prev, item) => ({...prev, ...item}), {}),
    );
    setAreFiltersDefined(false);
    setSavedFilters({});
    onFiltersDel();
  };

  const delayedFilter = useDebouncedCallback(updateFilterQuery, 500);

  return (
    <Form style={{width: "100%", marginBottom: "10px"}}>
      <div className="fc jcfs aist w100 g1 autolabel nowrap ">
        <div style={{paddingRight: "2.5em"}}>
          <FilterButton
            onFilter={onFilter}
            delFilter={delFilters}
            questionTitle={"Filter"}
            areFiltersDefined={areFiltersDefined}
            disabled={disabled}
          />
        </div>
        <div className={`f_block w100 ${className}`} style={{paddingTop: "0.7em", ...style}}>
          {FieldsMarkup}
        </div>
      </div>
      <ChosenFiltersLine
        window={window}
        savedFilters={savedFilters}
        setSavedFilters={setSavedFilters}
        onFilter={onFilter}
        filterNames={filterNames}
      />
    </Form>
  );
};

const Filters = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(BaseFilters);

Filters.propTypes = {
  window: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  getFilterData: PropTypes.func.isRequired,
  onFiltersSet: PropTypes.func,
  onFiltersDel: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Filters;
