import ToolTip from "../../ToolTip";

export const StatusRectangleBtn = ({color, disabled, title = "indicator", ...props}) => {
  return (
    <ToolTip title={title}>
      <button type="button" className="status-button" disabled={disabled} {...props}>
        <div className="status-circle-external">
          <div className="status-circle-internal " style={{background: color}}></div>
        </div>
      </button>
    </ToolTip>
  );
};
