export const SP_REQUEST_TABLE_WAREHOUSE_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
  },
  {
    header: "Logist",
    accessorKey: "logist",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Date of Readiness",
    accessorKey: "readinessDate",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "country",
  },
  {
    header: "Pf №",
    accessorKey: "proformaNumber",
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
  },
  {
    header: "Notes",
    accessorKey: "notes",
  },
]);

export const LOWER_SP_REQUEST_TABLE_WAREHOUSE_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Stock Pool №",
    accessorKey: "spNumber",
  },
]);
