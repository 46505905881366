import {useField} from "formik";

const InvisibleInput = ({...props}) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <input {...props} {...field} autoComplete="off" style={{display: "none"}} />
      {meta.error ? (
        <div className="error" style={{marginTop: "2.2em"}}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default InvisibleInput;
