import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {TextAreaInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

const TermsSettings = ({
  termTypeOptions,
  onSaveFirstTermBtnClick,
  onDeleteFirstTermBtnClick,
  onSaveSecondTermBtnClick,
  onDeleteSecondTermBtnClick,
  isCurrentReminder,
}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  return (
    <div>
      <div>
        <p className="mt0_5">{t("Notification settings for reminders")}</p>
        <div>
          <div className="row" style={{gap: "1rem"}}>
            <Select label={t("Type")} name="firstTermType" options={termTypeOptions} width="7rem" autolabel />
            <TextInput label={t("First term")} name={"firstTerm"} autolabel generalClassName="fGrow" />
          </div>
          <TextAreaInput name={"firstTermAreaField"} className="mb0_5" />
          <div className="row mb">
            <FunctionButton
              name="Save"
              autoWidth
              onClick={onSaveFirstTermBtnClick}
              disabled={!values.startDate || !values.warehouse || !values.firstTermType || !values.firstTerm}
            />
            <FunctionButton
              name="Delete"
              autoWidth
              onClick={onDeleteFirstTermBtnClick}
              disabled={!isCurrentReminder || !values.firstTermType}
            />
          </div>
        </div>
        <div>
          <div className="row" style={{gap: "1rem"}}>
            <Select label={t("Type")} name="secondTermType" options={termTypeOptions} width="7rem" autolabel />
            <TextInput label={t("Second term")} name={"secondTerm"} autolabel generalClassName="fGrow" />
          </div>
          <TextAreaInput name={"secondTermAreaField"} className="mb0_5" />
          <div className="row">
            <FunctionButton
              name="Save"
              autoWidth
              onClick={onSaveSecondTermBtnClick}
              disabled={!values.startDate || !values.warehouse || !values.secondTermType || !values.secondTerm}
            />
            <FunctionButton
              name="Delete"
              autoWidth
              onClick={onDeleteSecondTermBtnClick}
              disabled={!isCurrentReminder || !values.secondTermType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TermsSettings.propTypes = {
  termTypeOptions: PropTypes.array.isRequired,
  onSaveFirstTermBtnClick: PropTypes.func.isRequired,
  onDeleteFirstTermBtnClick: PropTypes.func.isRequired,
  onSaveSecondTermBtnClick: PropTypes.func.isRequired,
  onDeleteSecondTermBtnClick: PropTypes.func.isRequired,
  isCurrentReminder: PropTypes.bool.isRequired,
};
export default TermsSettings;
