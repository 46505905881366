import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../../../utilities/QuestionItem";
import {getSuccess} from "../../../../../utilities/toasts";
import {TextInput} from "../../../../Inputs";
import {fetchMixedRequests, getRequestInfo} from "../../../RequestsSlice";
import {REQ_WINDOW_TYPES} from "../../utils/functions/requestsFiltersManager";

import {confirmPayment, getPaymentsForRequest, sendForPayment} from "./redux/PaymentOperationsSlice";
import {getCurrentPayment, getReaquestPayments} from "./redux/PaymentSelectors";
import PaymentDates from "./PaymentDates";

const OnPaymentPart = ({initOnPaymentDates}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);
  const requestPayments = useSelector(getReaquestPayments);
  const currentPayment = useSelector(getCurrentPayment);

  const onSendForPayment = () => {
    dispatch(sendForPayment(currentRequest.requestId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled")
        dispatch(getPaymentsForRequest({requestId: currentRequest.requestId}));
    });
  };

  const onConfirm = () => {
    dispatch(confirmPayment(currentRequest.requestId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getSuccess(t("All documents are paid"));
        //! dispatch(fetchMixedRequests({window: REQ_WINDOW_TYPES.INPROGRESS}));
      }
    });
  };

  const confirmBtnDisabled = useMemo(() => requestPayments?.every(payment => !!payment?.paidAt), [requestPayments]);

  return (
    <div>
      <div className="fc jcsb header-blocks">
        <div className=" header-blocks_last">2.{t("On payment")} </div>
        <FunctionButton
          name={t("Send for payment")}
          autoWidth
          tooltipMessage="send doc"
          disabled={requestPayments?.length === 0}
          onClick={onSendForPayment}
        />
      </div>
      <PaymentDates
        labelPlan={t("on payment (p)")}
        labelFact={t("on payment (f)")}
        ttType="fact payment"
        name={"onPaymentConfirm"}
        createdDate={initOnPaymentDates?.createdAt}
        planned={initOnPaymentDates?.plannedDate}
        actual={initOnPaymentDates?.actualDate}
      />
      <div className="fc nowrap g1 jcsb">
        <div className="header-blocks q_wrapper">
          <QuestionItem title="list confirm" />
          {t("Document list")}
        </div>
        <TextInput
          autolabel
          label={t("Document summ")}
          name="paymentSum"
          width="6em"
          labelStyles={{width: "9em"}}
          disabled
        />
        <FunctionButton
          name={t("All documents are paid")}
          autoWidth
          // tooltipMessage="Delete selected"
          disabled={!confirmBtnDisabled}
          onClick={onConfirm}
        />
      </div>
    </div>
  );
};

export default OnPaymentPart;
