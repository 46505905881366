import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {default as PhInput} from "react-phone-input-2";
import {useField, useFormikContext} from "formik";
import {PropTypes} from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

import "react-phone-input-2/lib/style.css";

const PhoneInput = ({
  label,
  width = "67%",
  className = "",
  question = null,
  autolabel,
  readOnly,
  disabled = false,
  labelWrap,
  labelStyles,
  ...props
}) => {
  const {setFieldValue} = useFormikContext();

  const [field, meta, helpers] = useField(props);
  const {i18n} = useTranslation();

  const country = i18n.language === "en" ? "us" : "ua";

  const placeholder = {
    ua: "+380 (95) 111-22-33 ",
    us: "+(1) - (XXX) - XXX - XXXX",
  };

  const inputStyle = {
    border: "0",
    borderBottom: "1px solid #e2e2e2",
    background: "transparent",
    width: "100%",
  };
  const buttonStyle = {
    border: "0",
    background: "transparent",
  };

  const onPhoneInputChange = phone => {
    setFieldValue(props.name, phone);
  };

  const defaultFieldValue = i18n.language === "en" ? "+(1)" : "+380";

  return (
    <div className={`project_input phoneInput ${labelWrap ? "" : "labelWrap"}`}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative", height: "1.5em"}}>
        <div className={className}>
          <PhInput
            readOnly={readOnly}
            autoComplete="off"
            value={field.value || defaultFieldValue}
            //   onBlur={() => {
            //     setRawValue(value);
            //   }}
            country={country}
            onlyCountries={["ua", "us"]}
            // masks={{ua: '+380(dd) ddd-dd-dd', us: '+(1) - (XXX) - XXX - XXXX'}}
            onChange={onPhoneInputChange}
            //  onChange={phone => setValue(phone)}
            placeholder={placeholder[country]}
            inputStyle={inputStyle}
            countryCodeEditable={false}
            //   buttonStyle={buttonStyle}
            buttonClass={"country_btn"}
            disabled={disabled}
          />
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} /> : null}
    </div>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  props: PropTypes.array,
  className: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  autolabel: PropTypes.bool,
  inputProps: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PhoneInput;
