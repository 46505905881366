import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {makeRegisterRqstsTableData, makeRegisterSPTableData} from "../../../common/functions";
import {LOGIST_WINDOWS} from "../../../common/ShipmentInfo/constants";
import useStockPoolTables from "../../../common/useStockPoolTables";
import {fetchStockPoolBySkuId} from "../../../redux/operations";
import {
  selectFactoryCreatingCargoRequests,
  selectFactoryCreatingCargoStockPools,
  selectWarehouseCreatingCargoStockPools,
} from "../../../redux/selectors";
import {SP_REQUEST_TABLE_FACTORY_CREATING_MODAL, SP_TABLE_FACTORY_CREATING_MODAL} from "../constants";

const NotInTPTable = ({setSelectedSp, selectedFactory, selectedWarehouse, setModalOpen}) => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const [requestsData, setRequestsData] = useState([]);

  const factoryId = selectedFactory?.value;
  const warehouseId = selectedWarehouse?.value;

  const creatingFetchData = selectedFactory
    ? {
        filterProps: {factoryId},
        window: LOGIST_WINDOWS.FACTORY_CREATING_CARGO,
      }
    : {
        filterProps: {warehouseId},
        window: LOGIST_WINDOWS.WAREHOUSE_CREATING_CARGO,
      };

  const {spRequests, onStockPoolCheck, setCheckedSP} = useStockPoolTables(creatingFetchData);

  const spcolumns = useColumns(SP_TABLE_FACTORY_CREATING_MODAL);

  const stockPoolsFactoryCreating = useSelector(selectFactoryCreatingCargoStockPools);
  const stockPoolsWarehouseCreating = useSelector(selectWarehouseCreatingCargoStockPools);

  const chosedSpData = selectedFactory ? stockPoolsFactoryCreating : stockPoolsWarehouseCreating;

  const spdata = makeRegisterSPTableData(chosedSpData, i18n.language);

  const reqcolumns = useColumns(SP_REQUEST_TABLE_FACTORY_CREATING_MODAL);
  const requestsCreating = useSelector(selectFactoryCreatingCargoRequests);

  const reqdata = makeRegisterRqstsTableData(requestsData, true);

  const onWarehouseRqstsTableRowDoubleClick = skuRequest => {
    dispatch(fetchStockPoolBySkuId(skuRequest.id));
    setModalOpen(true);
  };

  useEffect(() => {
    setCheckedSP([]);
    setRequestsData(requestsCreating);
  }, [requestsCreating]);

  useEffect(() => {
    if (spRequests?.lenght !== 0) setRequestsData(spRequests);
    else {
      setRequestsData(requestsCreating);
    }
  }, [spRequests]);

  return (
    <div className="fdr gap2x">
      <ReactTable
        className="tableTac"
        defaultData={spdata}
        columns={spcolumns}
        onSelect={() => {}}
        selectable={false}
        current={null}
        setMultiSelect={checked => {
          setSelectedSp(checked);
          onStockPoolCheck(checked);
        }}
        enableMultiSelection={true}
        style={{width: "25%", height: "15em"}}
      />
      <ReactTable
        defaultData={reqdata}
        columns={reqcolumns}
        onSelect={() => {}}
        selectable={false}
        current={null}
        onRowDoubleClick={onWarehouseRqstsTableRowDoubleClick}
        className="w100 pb scrollX tableTac"
        style={{width: "100%", height: "15em"}}
      />
    </div>
  );
};

export default NotInTPTable;
