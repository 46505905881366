/* eslint-disable react/display-name */
import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import useSessionFilters from "../../../utilities/hooks/useSessionFilters";
import {fetchFilters, fetchMixedRequests} from "../RequestsSlice";

const withRequestWithFilter = (BaseComponent, window) => {
  return props => {
    const {filters, initialFilters} = useSessionFilters(window);
    const dispatch = useDispatch();

    useEffect(() => {
      const fetchFilterValues = checkEmptyFilterFields(initialFilters);
      dispatch(fetchMixedRequests({window, ...fetchFilterValues}));
      dispatch(fetchFilters({window}));
    }, []);

    const updateFetch = () => {
      const fetchFilterValues = checkEmptyFilterFields(filters);
      dispatch(fetchMixedRequests({window, ...fetchFilterValues}));
    };

    return <BaseComponent updateFetch={updateFetch} {...props} />;
  };
};

export default withRequestWithFilter;
