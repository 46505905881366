import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {getSuccess} from "../../../../../utilities/toasts";
import {addEmailTemplate, deleteEmailTemplate, updateEmailTemplate} from "../../../redux/operations/FactoryOperations";
import {selectCurrentFactoryConditionById, selectFactoriesLoading} from "../../../redux/selectors/FactorySelectors";

const useReqTemplatesModalService = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentCondition = useSelector(selectCurrentFactoryConditionById);
  const loading = useSelector(selectFactoriesLoading);

  //data
  const {representativeContact, requestEmailTemplate} = currentCondition ?? {};
  const {email = "", carbonCopy = "", subject = "", content = ""} = requestEmailTemplate ?? {};
  const initialValues = {
    contactPerson: representativeContact?.name ?? "",
    email: email || representativeContact?.email || "",
    carbonCopy,
    subject,
    content,
  };
  const isDeleteTemplateBtnDisabled = !currentCondition;

  function calcBtnsDisabled(values) {
    const {email, carbonCopy, subject, content} = values;

    return !currentCondition || !email || !carbonCopy || !subject || !content;
  }

  //event handlers
  function onSaveTemplateBtnClick(values) {
    if (!currentCondition) return;

    const {email, carbonCopy, subject, content} = values;
    if (!email || !carbonCopy || !subject || !content) return;

    requestEmailTemplate
      ? dispatch(
          updateEmailTemplate({
            templateId: requestEmailTemplate.templateId,
            values: {email, carbonCopy, subject, content},
          }),
        ).then(response => {
          if (response.meta.requestStatus === "fulfilled") {
            getSuccess(t("Email template updated successfully"));
          }
        })
      : dispatch(
          addEmailTemplate({conditionId: currentCondition.conditionId, values: {email, carbonCopy, subject, content}}),
        ).then(response => {
          if (response.meta.requestStatus === "fulfilled") {
            getSuccess(t("Email template added successfully"));
          }
        });
  }
  function onDeleteTemplateBtnClick() {
    if (!requestEmailTemplate) return;

    dispatch(deleteEmailTemplate(requestEmailTemplate.templateId)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        getSuccess(t("Email template deleted successfully"));
      }
    });
  }

  return {
    onSaveTemplateBtnClick,
    onDeleteTemplateBtnClick,
    initialValues,
    calcBtnsDisabled,
    isDeleteTemplateBtnDisabled,
    loading,
  };
};

export default useReqTemplatesModalService;
