import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import findCountryById from "../../../../utilities/findCountyById";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchAllFactories, fetchFactoryConditions, fetchFilters} from "../../redux/operations/FactoryOperations";
import {selectCountries, selectCurrentFactoryId, selectFactories} from "../../redux/selectors/FactorySelectors";
import {cleanCurrentFactoryConditions, setCurrentFactoryId} from "../../redux/slices/FactorySlice";
import {FACTORY_LIST_TABLE_COLUMNS} from "../common/utils/constants";

const useFactoryListService = () => {
  const dispatch = useDispatch();

  const allFactories = useSelector(selectFactories);
  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const countries = useSelector(selectCountries);

  //   const VEDSettingsFilterManager = makeFilterManager(VED_SETTINGS_GENERAL_FILTERS_STORAGE_KEY);
  //   const filters = VEDSettingsFilterManager.getFilters();

  useEffect(() => {
    // dispatch(fetchAllFactories(filters));
    dispatch(fetchAllFactories());
    dispatch(fetchFilters());
    dispatch(setCurrentFactoryId(null));
  }, []);

  const factoryListTableColumns = useColumns(FACTORY_LIST_TABLE_COLUMNS);
  const factoryListTableData =
    allFactories?.map(({factoryId, name, countryId}, index) => ({
      id: factoryId,
      index: ++index,
      name,
      country: findCountryById({countryId, countries}),
      //   discount: "",
      //   contactPerson: "",
      //   email: "",
      //   phone: "",
      //   terms: "",
      //   conditions: "",
      //   note: "",
      //   toDo: "",
    })) ?? [];
  const current = currentFactoryId ?? "";

  function onFactoryListTableRowSelect(factoryId) {
    if (!factoryId || currentFactoryId === factoryId) {
      dispatch(setCurrentFactoryId(null));
      dispatch(cleanCurrentFactoryConditions());
      return;
    }
    dispatch(setCurrentFactoryId(factoryId));
    dispatch(fetchFactoryConditions(factoryId));
  }

  return {factoryListTableColumns, factoryListTableData, onFactoryListTableRowSelect, current};
};

export default useFactoryListService;
