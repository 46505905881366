import {buttonClasses} from "@mui/base/Button";
import {Tab as BaseTab, tabClasses} from "@mui/base/Tab";
import {TabPanel as BaseTabPanel} from "@mui/base/TabPanel";
import {TabsList as BaseTabsList} from "@mui/base/TabsList";
import {styled} from "@mui/system";

export const Tab = styled(BaseTab)`
  font-size: 1.1em;
  font-weight: 400;
  background-color: #f5f5f5;

  border-radius: 1px 25px 1px 0px;
  padding: 0 0.8em;
  line-height: 1.5em;
  color: #426ba6;
  border: 0;
  color: #426ca665;
  opacity: 0.5;
  white-space: nowrap;
  width: 100%;
  max-width: 20%;
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  &:focus {
    outline: null;
  }
  &:first-of-type {
    width: auto;
    overflow-x: visible;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    background-color: #f5f5f5;
    opacity: 1;
    color: #426ba6;
    overflow-x: visible;
  }

  &.${buttonClasses.disabled} {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const TabPanel = styled(BaseTabPanel)(
  ({theme}) => `

   flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 1012px;
    position: relative;
 
  `,
);

export const TabsList = styled(BaseTabsList)(
  ({theme}) => `
  // position: absolute;
  // top: -1.4em;
  // left: 0;
  font-size: 1.1em;
  font-weight: 400;
  padding: 0 0;
  line-height: 1.5em;

  display: flex;
  align-items: center;
  justify-content:flex-start;
  align-content: space-between;

     flex-wrap: nowrap;
overflow:hidden;
width:100%
  `,
);
