export const MAIN_TRANSPORTATION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Status*",
    accessorKey: "statusTP",
  },

  {
    header: "Transport Pool №",
    accessorKey: "transportPoolNumber",
  },
  {
    header: "SP №",
    accessorKey: "stockPoolNumber",
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
  },
  {
    header: "Manager CO",
    accessorKey: "managerCo",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Sum",
    accessorKey: "sum",
  },
  {
    header: "Delivery place",
    accessorKey: "deliveryPlace",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "deliveryCountry",
  },
  {
    header: "Prof №",
    accessorKey: "profNumber",
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
  },

  {
    header: "Manager FEA",
    accessorKey: "managerFea",
  },
  {
    header: "Notes",
    accessorKey: "notes",
  },
]);

export const DOCUMENTS_FOR_TRANSPORTER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "date doc",
    accessorKey: "docDate",
  },

  {
    header: "Document type",
    accessorKey: "docType",
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
  },
  {
    header: "upl date",
    accessorKey: "createdAt",
  },
  {
    header: "Added by",
    accessorKey: "addedBy",
  },
]);

export const TRANSPORTER_PAYMENT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Status*",
    accessorKey: "status",
  },
  {
    header: "date doc",
    accessorKey: "docDate",
  },

  {
    header: "Document type",
    accessorKey: "documentType",
  },
  {
    header: "Document №",
    accessorKey: "documentNumber",
  },
  {
    header: "upl date",
    accessorKey: "uploadDate",
  },
  {
    header: "Added by",
    accessorKey: "addedBY",
  },
]);
