export const SP_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "SP №",
    accessorKey: "spNumber",
    size: 100,
    isSortable: true,
  },
]);

export const RQST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    size: 100,
    isSortable: true,
  },
]);
