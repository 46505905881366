import PropTypes from "prop-types";

export const formatStatus = status => {
  var statusColor = "gray";
  var textColor = "#A3B9D9";
  switch (status) {
    case "tempSaved":
      statusColor = "green";
      textColor = "#426BA6";
      // textStyle.fontWeight = "400"
      break;
    case "unsaved":
      statusColor = "rgb(246, 248, 96)";
      textColor = "#426BA6";
      // textStyle.fontWeight = "400"
      break;
    default:
      break;
  }
  return [
    <>
      <label className="radio_container">
        <div className="circules_box">
          <div className="circule1"></div>
          <div className="circule2" style={{backgroundColor: statusColor}}></div>
        </div>
      </label>
      <span style={{color: textColor}}></span>
    </>,
  ];
};

formatStatus.propTypes = {status: PropTypes.oneOf(["tempSaved", "unsaved"])};
