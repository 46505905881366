import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Tabs} from "@mui/base/Tabs";

import CloseBtn from "../../../utilities/Buttons/CloseBtn";
import ToolTip from "../../../utilities/ToolTip";

import {Tab, TabPanel, TabsList} from "./styledTabs";

const ModalWithTabs = ({
  label,
  active,
  setModalActive,
  Component,
  onModalClose = () => {},
  closable = false,
  className = "",
  modalInnerWrapperClassName = "",
  modalTabs,
  setModalTabs,
  closableTabs = false,
  children,
  ...props
}) => {
  const [couldClose, setCouldClose] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const handleEscapeKey = event => {
    if (event.key === "Escape" && closable && couldClose) {
      setModalActive(false);
      if (closable) onModalClose();
    }
  };

  useEffect(() => {
    if (closable) {
      window.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
      if (closableTabs) {
        setModalTabs([]);
      }
    };
  }, []);

  useEffect(() => {
    if (closableTabs) {
      setModalTabs([]);
    }
  }, [active]);

  useEffect(() => {
    if (closableTabs) {
      if (modalTabs.length > 0) {
        setTabValue(modalTabs?.[modalTabs.length - 1]?.index || 1);
      } else {
        setTabValue(0);
      }
    }
  }, [modalTabs]);

  const removeFocusFromButton = isActive => {
    if (isActive) {
      const openButton = document.activeElement;
      if (openButton && openButton.blur) {
        openButton.blur(); // remove focus on button when modal was closed by Escape
      }
    }
  };

  const onTabClose = index => {
    if (tabValue === index) {
      setTabValue(index - 1);
    }
    setModalTabs(prevTabs => prevTabs.filter(tab => tab.index !== index));
  };

  const tabsGenerate = tabs => {
    return tabs.map(tab => {
      let index = tab.index;
      return (
        <Tab key={index} value={index} disabled={tab.disabled}>
          <ToolTip title={tab.tooltipTitle ?? ""}>
            <div>{tab.label}</div>
          </ToolTip>
          {closableTabs && (
            <span
              style={{marginLeft: "1em", cursor: "pointer"}}
              onClick={e => {
                e.preventDefault();
                onTabClose(index);
              }}
            >
              &#215;
            </span>
          )}
        </Tab>
      );
    });
  };

  useEffect(() => {
    removeFocusFromButton(active);
  }, [active]);

  const tabContent = useCallback(() => {
    return React.Children.map(children, child => <TabPanel value={child.props.index}>{child}</TabPanel>);
  }, [modalTabs]);

  const tabsRender = useMemo(() => tabContent(), [modalTabs]);
  const tabsButtons = tabsGenerate(modalTabs);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {active ? (
        <div className="modal-form__container ">
          <Tabs className={`modal_form ${className}`} onChange={handleTabChange} value={tabValue}>
            <div className="madal_header">
              <TabsList>
                {/* <Tab key={0} value={0}>
                  {label}
                </Tab> */}
                {tabsButtons}
              </TabsList>
              {closable && (
                <CloseBtn
                  style={{position: "relative", top: "0.2em", marginLeft: "2em"}}
                  onClick={() => {
                    if (couldClose) setModalActive(false);
                    if (closable) onModalClose();
                  }}
                />
              )}
            </div>
            <div className="overflow_form_modal">
              <div className={`${modalInnerWrapperClassName} tabmodal_form__child`}>
                {/* <TabPanel value={0}>
                  <Component
                    setModalActive={setModalActive}
                    active={active}
                    setCouldModalClose={setCouldClose}
                    onModalClose={onModalClose}
                    {...props}
                  />
                </TabPanel> */}
                {tabsRender}
              </div>
            </div>
          </Tabs>
        </div>
      ) : null}
    </>
  );
};

export default ModalWithTabs;
