import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../../utilities/dateFormat";
import {makeFilterManager} from "../../../../../utilities/makeFilterManager/makeFilterManager";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SP_CHECK_TABLE} from "../../../../Requests/RequestRegister/RequestTable/utils/constants";
import {
  getExpandedDocs,
  profExpanded,
} from "../../../../Requests/RequestsInProgress/utils/functions/expandDataForTable";
import {deselectStockPool} from "../../../../Requests/RequestsSlice";
import {LOGIST_WINDOW_TYPES} from "../../../common/constants";
import {LOGIST_WINDOWS} from "../../../common/ShipmentInfo/constants";
import {deselectSkuRequest, setFetchedStockPool} from "../../../redux/LogistSlice";
import {fetchAllStockPoolsNew, fetchStockPoolBySkuId} from "../../../redux/operations";
import {selectCurrentSkuRequest, selectWarehouseRequests, selectWarehouseStockPools} from "../../../redux/selectors";
import {WAREHOUSE_LOWER_MAIN_TABLE_COLUMNS} from "../../utils/constants";

const useWarehouseLowerMainTablesPartService = (setCheckedSp, setModalOpen) => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const warehouseSpTpTableColumns = useColumns(SP_CHECK_TABLE);
  const warehouseMainTableColumns = useColumns(WAREHOUSE_LOWER_MAIN_TABLE_COLUMNS);

  const warehouseStockPoolsAfterAcception = useSelector(selectWarehouseStockPools);
  const warehouseRequestsAfterAcception = useSelector(selectWarehouseRequests);
  const currentSkuRequest = useSelector(selectCurrentSkuRequest);

  const warehouseSpTpTableData = useMemo(() => {
    return warehouseStockPoolsAfterAcception?.map(({cargo, stockPoolNumber, stockPoolId}, index) => ({
      id: stockPoolId,
      number: index + 1,
      transportPoolNumber: cargo?.cargoNumber || "",
      stockPoolNumber: stockPoolNumber || "",
    }));
  }, [warehouseStockPoolsAfterAcception]);

  const warehouseMainTableData = useMemo(() => {
    return warehouseRequestsAfterAcception?.map(({order, request, skuRequestId, stockPool}, index) => {
      const managerCoName =
        i18n.language === "en"
          ? order?.co.managerCo?.persona?.engFirstName + " " + order?.co.managerCo?.persona?.engLastName
          : order?.co.managerCo?.persona?.firstName + " " + order?.co.managerCo?.persona?.lastName;

      const logistName =
        i18n.language === "en"
          ? stockPool?.logist?.persona?.engFirstName + " " + stockPool?.logist?.persona?.engLastName
          : stockPool?.logist?.persona?.firstName + " " + stockPool?.logist?.persona?.lastName;

      return {
        id: skuRequestId,
        number: index + 1,
        status: i18n.language === "en" ? stockPool?.status?.engName : stockPool?.status?.ukrName,
        requestNumber: request?.requestNumber,
        orderNumber: order.orderNumber,
        warehouse: stockPool?.warehouse?.name,
        managerCo: managerCoName,
        logist: logistName,
        factory: request?.brand?.name,
        requestSum: request?.requestSum,
        bringTo: dateFormat(order?.co?.bringTo),
        country: order?.co?.project?.country?.shortName || "",
        proformaNumber: profExpanded(request?.proforms),
        invoiceNumber: getExpandedDocs(stockPool?.docs, 2) || "-",
        packingListNumber: getExpandedDocs(stockPool?.docs, 1) || "-",
        notes: request?.note,
      };
    });
  }, [warehouseRequestsAfterAcception]);

  const WarehouseFilterManager = makeFilterManager(LOGIST_WINDOW_TYPES.WAREHOUSE);
  const warehouseFilters = WarehouseFilterManager.getFilters();

  useEffect(() => {
    dispatch(deselectStockPool());
    dispatch(setFetchedStockPool(null));
  }, []);

  useEffect(() => {
    dispatch(setFetchedStockPool(null));
  }, [
    warehouseFilters?.stockPoolNumber,
    warehouseFilters?.warehouseId,
    warehouseFilters?.orderNumber,
    warehouseFilters?.invoiceNumber,
    warehouseFilters?.managerCoId,
  ]);

  const onWarehouseStockPoolCheck = spList => {
    if (!spList.length) {
      dispatch(fetchAllStockPoolsNew({window: LOGIST_WINDOWS.WAREHOUSE, ...warehouseFilters}));
    } else {
      dispatch(
        fetchAllStockPoolsNew({
          window: LOGIST_WINDOWS.WAREHOUSE,
          ...warehouseFilters,
          stockPoolIds: spList?.join(","),
        }),
      );
    }
    setCheckedSp(spList);
  };

  const onMainWarehouseTableSelect = id => {
    if (!id || currentSkuRequest?.skuRequestId === id) {
      dispatch(deselectSkuRequest());
      return;
    }
    dispatch(fetchStockPoolBySkuId(id));
  };

  const onWarehouseRqstsTableRowDoubleClick = skuRequest => {
    dispatch(fetchStockPoolBySkuId(skuRequest.id));
    setModalOpen(true);
  };

  return {
    onWarehouseRqstsTableRowDoubleClick,
    onMainWarehouseTableSelect,
    onWarehouseStockPoolCheck,
    warehouseMainTableData,
    warehouseSpTpTableData,
    warehouseMainTableColumns,
    warehouseSpTpTableColumns,
  };
};

export default useWarehouseLowerMainTablesPartService;
