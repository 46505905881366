import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import styles from "./CheckBoxWithLabel.module.scss";

const CheckBoxWithLabel = ({
  checked = false,
  label,
  labelClassName = "",
  inputClassName = "",
  onCheck = () => {},
  disabled = false,
}) => {
  const {t} = useTranslation();
  const [isChecked, setIsChecked] = useState(checked);

  //event handlers
  const onCheckBoxCheck = () => {
    setIsChecked(prev => !prev);
    onCheck();
  };

  //classnames
  const labelFinalClassName = `${styles.label} ${inputClassName} ${disabled ? styles.disabled : ""}`;
  const inputFinalClassName = `${styles.input} ${labelClassName}`;

  return (
    <label className={labelFinalClassName}>
      <span>{t(label)}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onCheckBoxCheck}
        className={inputFinalClassName}
        disabled={disabled}
      />
    </label>
  );
};

CheckBoxWithLabel.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  onCheck: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CheckBoxWithLabel;
