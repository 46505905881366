import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SP_TABLE} from "../../../../../Requests/RequestRegister/RequestTable/utils/constants";
import {fetchAllStockPoolsNew} from "../../../../redux/operations";
import {selectIncomingStockPools} from "../../../../redux/selectors";
import {makeStockPoolsTableData} from "../../constants";

const useIncomingStockPoolTableSelect = ({setSelectedSP}) => {
  const dispatch = useDispatch();

  const allStockPools = useSelector(selectIncomingStockPools);
  const defaultData = makeStockPoolsTableData(allStockPools);
  const columns = useColumns(SP_TABLE);

  function onStockPoolCheck(selected) {
    if (!selected) return;
    setSelectedSP(prev => {
      if (selected.length === 0) {
        dispatch(fetchAllStockPoolsNew({window: "Incoming"}));
      } else {
        dispatch(fetchAllStockPoolsNew({window: "Incoming", stockPoolIds: selected.join(",")}));
        if (prev.join("," === selected.join(","))) return selected;
      }
      return selected;
    });
  }

  return {defaultData, columns, onStockPoolCheck};
};

export default useIncomingStockPoolTableSelect;
