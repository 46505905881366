import {useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";

import {MODE} from "../../../gitIgnore/appMode";

const MyLink = props => {
  let activeStyle = {
    fontWeight: 600,
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === props.to) {
      document.title = props.name + " / " + MODE;
    }
  }, [location]);

  return (
    <li className="nav-item">
      <NavLink end to={props.to} style={({isActive}) => (isActive ? props.style || activeStyle : undefined)}>
        {props.name}
      </NavLink>
    </li>
  );
};

export default MyLink;
