import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchDocsRefs, selectDocsRefs} from "../../../RequestsSlice";

const useDocRefs = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const docsRefs = useSelector(selectDocsRefs);

  useEffect(() => {
    dispatch(fetchDocsRefs());
  }, []);

  const docRefsOptions = useMemo(() => {
    return (
      docsRefs?.map(item => {
        return {title: item[i18n.language === "en" ? "nameEng" : "nameUkr"], value: item.docTypeId};
      }) || []
    );
  }, [docsRefs, i18n.language]);

  return {docRefsOptions};
};

export default useDocRefs;
