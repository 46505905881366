import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useField, useFormikContext} from "formik";
import {debounce} from "lodash";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

export const Select = ({
  label,
  width = "100%",
  // styles = null,
  id,
  options, ///// должен быть вида [{value: ###, dependentField: ### }, ....]
  makeEffect, /// функция (не обязательная) которая должна сработать когда происходит выбор из выпадающего списка
  question, ///// пояснение к полю
  // nomination = "",
  multi = false, ///если передаём в массив выбора объекты с несколькими dependentField например {orgId, orgName, orgCode}
  minFilterLength = 3, //// минимальная длина строки для фильтрации поля
  errorField,
  paginatiable = false,
  generalClassName = "",
  labelwidth = "",
  labelWrap,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  // const [filteredList, setFilteredList] = useState([])
  const [filter, setFilter] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [field, meta, helpers] = useField(props);

  const {value} = field;

  const {setFieldValue, handleChange, initialValues, errors} = useFormikContext();
  const {tooltipShown, onMouseEnter, onMouseLeave} = useInputsTooltip(field);

  // useEffect(() => {
  //     setShowOptions(false)
  // }, []);

  //////////фильтрация

  const updateFilterQuery = value => {
    if (value && value.length >= minFilterLength) {
      setFilter(value);
    } else setFilter("");
  };

  const delayedFilter = debounce(updateFilterQuery, 500);

  useEffect(() => {
    if (options && options.length > 0) {
      var filtered = options.filter(option => {
        return option.value && option.value.toLowerCase().includes(filter.toLowerCase());
      });
      setFilteredOptions(filtered);
      if (filter !== "" && !showOptions && filtered.length > 0 && filtered.length !== options.length) {
        openOptions();
      }
    }
  }, [filter, options]);

  /////////////////////////
  const timeout = useRef();

  const handleSelectOption = option => {
    clearTimeout(timeout.current);
    setShowOptions(false);
    setFilter("");
    if (makeEffect != null) makeEffect(option);
    helpers.setValue(option.value);

    for (var key in option) {
      if (key === "value" || key === value) {
        continue;
      }

      setFieldValue(key, option[key]);

      // props.setCurrent({contractNumber: option.value, ...option});
    }
  };

  // изменит поля на котрые влияет данное поле (визывается когда срабатывает onChange тоесть при ручном изменении данного поля)
  const onMakeEffect = useCallback(
    value => {
      const later = () => {
        clearTimeout(timeout.current);
        var item = options.find(
          option => option.value.toUpperCase() === value.replace(/ +/g, " ").trim().toUpperCase(),
        );

        for (var key in options[0]) {
          if (key === "value") {
            continue;
          }
          setFieldValue(key, item ? item[key] : "");
        }
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, 2000);
    },
    [options, value],
  );

  const optionList = useMemo(() => {
    return filteredOptions.map((option, i) => {
      var {value} = option;
      return (
        <li
          className="select_option"
          key={i}
          onClick={() => {
            handleSelectOption(option);
          }}
        >
          {value.length > 32 ? value.slice(0, 32) + "..." : value}
        </li>
      );
    });
  }, [filteredOptions]);

  useEffect(() => {
    // setShowOptions(false)
    setFilter("");
  }, [options]);

  const openOptions = () => {
    if (filteredOptions.length > 0 && !props.readOnly) setShowOptions(true);
  };

  useEffect(() => {
    setFilter("");
  }, [initialValues]);

  const showAllOptions = useCallback(() => {
    setFilter("");
  }, []);

  const scrollContainerRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    if (paginatiable && showOptions) {
      const scrollContainer = scrollContainerRef.current;

      const handleScroll = () => {
        // Проверяем, достигнут ли скроллом низ контейнера

        const isBottom =
          Math.round(scrollContainer.scrollTop + scrollContainer.clientHeight) === scrollContainer.scrollHeight;
        setIsScrolledToBottom(isBottom);
      };

      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [showOptions]);

  // useEffect(() => {
  //   if (isScrolledToBottom) {
  //     console.log("ul bottom");
  //   }
  // }, [isScrolledToBottom]);

  return (
    <div className={`${generalClassName} project_input select ${labelWrap ? "" : "labelWrap"}`} id={id}>
      {label && (
        <label htmlFor={props.id} style={{width: labelwidth}} className={props.autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div
        style={{display: "flex", position: "relative", width: width, maxHeight: "100px"}}
        onMouseLeave={() => setTimeout(() => setShowOptions(false), 100)}
      >
        <input
          {...field}
          {...props}
          value={field.value || ""}
          autoComplete="off"
          style={{width: "100%"}}
          onClick={e => {
            openOptions();
          }}
          onChange={e => {
            handleChange(e);
            delayedFilter(e.target.value);
            if (multi) onMakeEffect(e.target.value);
          }}
          onMouseEnter={question ? onMouseEnter : null}
          onMouseLeave={question ? onMouseLeave : null}
        />
        {meta.error && meta.touched ? <div className="error">{meta.error}</div> : null}
        {errors[errorField] && meta.touched ? <div className="error">{errors[errorField]}</div> : null}

        {!props.readOnly && (
          <div
            className={`select__list_button ${props.disabled ? "disable" : ""}`}
            onClick={() => {
              if (props.disabled) return;
              if (!props.readOnly) {
                showAllOptions();
                setShowOptions(i => (i = !i));
              }
            }}
          >
            ▼
          </div>
        )}
        {showOptions && (
          <div className="select__list ">
            <ul className={paginatiable ? "select__list_scroll" : null} ref={scrollContainerRef}>
              {optionList}
            </ul>
          </div>
        )}
        {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
  id: PropTypes.string,
  options: PropTypes.array,
  makeEffect: PropTypes.func,
  multi: PropTypes.bool,
  minFilterLength: PropTypes.number,
  errorField: PropTypes.string,
  paginatiable: PropTypes.bool,
  labelwidth: PropTypes.string,
};
