import {Route, Routes} from "react-router-dom";

import TranslationButton from "../Components/Translation/TranslationButton";

const Translation = () => {
  return (
    <Routes>
      <Route path="/" element={<TranslationButton />} />
    </Routes>
  );
};

export default Translation;
