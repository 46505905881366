import {useSelector} from "react-redux";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import {getCurrentPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import usePaymentsDoc from "./utils/hook/paymentDocService";
import {onPaymentColumns} from "./columnsForPaymentTables";

const DocsByPaymentTable = () => {
  const columns = useColumns(onPaymentColumns);
  const currentPayment = useSelector(getCurrentPayment);

  const {requestPayments} = usePaymentsData();

  const {onDocSelect} = usePaymentsDoc(2);

  const onPaymentSelect = paymentId => {
    onDocSelect(paymentId);
  };

  return (
    <div style={{height: "100%", paddingBottom: "1em"}}>
      <ReactTable
        defaultData={requestPayments}
        columns={columns}
        onSelect={onPaymentSelect}
        current={currentPayment?.paymentId}
        className={"scrollX"}
        style={{height: "100%", minHeight: "7em"}}
      />
    </div>
  );
};

export default DocsByPaymentTable;
