import {useTranslation} from "react-i18next";

import {apiUrl} from "../../../../api/baseURL";
import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SKUS_TABLE_COLUMNS} from "../../LogModFdOrderAcceptance/constants";

const ModShowSkuPlan = ({currentSp}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const skusTableColumns = useColumns(SKUS_TABLE_COLUMNS);
  skusTableColumns.splice(
    4,
    0,
    {
      header: () => [t("Image")],
      accessorKey: "image1",
      cell: imgcellRender,
    },
    {
      header: () => [t("Image")],
      accessorKey: "image2",
      cell: imgcellRender,
    },
  );
  const skusTableData =
    currentSp?.sku?.map(
      (
        {
          brand,
          skuId,
          name,
          nameEng,
          amount,
          retailPrice,
          complectDesc,
          topDiscount,
          material,
          materialType,
          color,
          height,
          length,
          width,
          discount,
          listPrice,
          overallSizeUnit,
          sku,
        },
        index,
      ) => {
        return {
          id: skuId,
          number: ++index,
          brandName: brand?.name ?? " - ",
          skuName: (language === "en" ? nameEng : name) ?? " - ",
          characteristics: `${material ?? ""}; ${materialType ?? ""}; ${color}` ?? " - ",
          ovh:
            `${length} * ${width} * ${height} ${language === "en" ? overallSizeUnit.engName : overallSizeUnit.name}` ??
            " - ",
          image1: sku.images[0] ?? " - ",
          image2: sku.images[1] ?? "",
          complectDesc: complectDesc ?? " - ",
          amount: amount ?? " - ",
          price: retailPrice ?? " - ",
          paymentSumm: (retailPrice * amount) / 1 ?? " - ",
          discount: discount ?? " - ",
          totalPaymentSum: listPrice ?? " - ",
          totalDiscount: topDiscount ?? " - ",
          payable: listPrice * (topDiscount ?? 1) ?? " - ",
        };
      },
    ) ?? [];

  function imgcellRender(value) {
    const imgs = value.getValue();

    if (imgs) {
      return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <div key={value} style={{position: "relative"}}>
            <img src={apiUrl + "/" + imgs} alt={imgs} style={{width: "5em", height: "5em", marginRight: "2px"}} />
          </div>
        </div>
      );
    }
    return " - ";
  }

  return (
    <div className="fcCol">
      <ReactTable
        className="tableTac overflowX fGrow mt mb"
        columns={skusTableColumns}
        defaultData={skusTableData}
        loading={false}
        style={{height: "unset"}}
        selectable={false}
      />
    </div>
  );
};

export default ModShowSkuPlan;
