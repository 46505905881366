import {createSlice} from "@reduxjs/toolkit";

import {LOGIST_WINDOWS} from "../common/ShipmentInfo/constants";
import {SLEEPING_WINDOW_PARAMS} from "../SleepingRequests/constants";

import {
  addCarrierDoc,
  addNewCargo,
  addNewWarehouseAccount,
  addNewWarehouseContact,
  addNewWarehouseDoc,
  addNewWarehouseDocFile,
  addNewWarehouseType,
  addWarehouse,
  appointStockPool,
  cancelAllStockPoolAppointments,
  cancelStockPoolAppointment,
  deleteCarrierDoc,
  deleteWarehouseAccount,
  deleteWarehouseContact,
  deleteWarehouseDoc,
  deleteWarehouseDocFile,
  deleteWarehouseType,
  distributeStockPools,
  fetchAllCargos,
  fetchAllCarrierDocs,
  fetchAllCarrierDocTypes,
  fetchAllCarriers,
  fetchAllCarrierTypes,
  fetchAllStockPoolsNew,
  fetchAllStockPoolsUpdated,
  fetchAllWarehouses,
  fetchAllWarehouseTypes,
  fetchCargo,
  fetchCarrierFilters,
  fetchFiltersForLogistics,
  fetchRequestsForLogistics,
  fetchStockPoolBySkuId,
  fetchStockPoolBySkuRequestId,
  fetchStockPoolFilters,
  fetchStockPoolUpdated,
  fetchWarehouseInfo,
  fetchWarehousesFilters,
  getCarrier,
  sendTP,
  splitDefectedOrder,
  takeTpToWork,
  updateCargo,
  updateSplitedOrder,
  updateWarehouse,
  updateWarehouseDoc,
} from "./operations";

export const LOGIST_STORE_NAME = "logist";

const initialState = {
  stockPools: {
    incoming: null,
    appointed: null,
    boss: null,
    distributed: null,
    factory: null,

    factoryCargo: null,
    creatingCargo: null,

    selectedInModal: null,

    transporter: null,
    warehouseComing: null,
    warehouse: null,
    warehouseCreatingSp: null,
    warehouseSp: null,
    warehouseCreatingCargo: null,
    warehouseCargo: null,
    finalBoss: null,
    finalManager: null,
  },
  fetchedStockPool: null,
  requests: {
    incoming: null,
    appointed: null,
    boss: null,
    distributed: null,
    factory: null,
    factoryCargo: null,
    creatingCargo: null,
    forLogistics: {sleeping: null, sleepingInSp: null},

    transporter: null,
    warehouseComing: null,
    warehouse: null,
    warehouseCreatingSp: null,
    warehouseSp: null,
    warehouseCreatingCargo: null,
    warehouseCargo: null,
    finalBoss: null,
    finalManager: null,
  },
  cargos: {all: null, chosen: null, added: null},
  currentCargo: null,
  warehouses: {
    warehouses: null,
    currentWarehouse: null,
    warehouseTypes: null,
    // addedWarehouseType: null,
    // addedWarehouseContact: null,
    // addedWarehouseAccount: null,
    // addedWarehouseDoc: null,
    // addedWarehouseDocFile: null,
    currentDocFile: null,
  },

  filters: null,

  logistLoading: false,
  error: false,

  // carriers: {
  //   carriers: null,
  //   currentCarrier: null,
  //   carrierTypes: [],
  //   carrierDocs: [],
  //   carrierDoc: null,
  //   carrierDocTypes: [],
  //   carrierDocsLoading: false,
  // },
  carriers: [],
  carrierTypes: [],
  carrier: null,
  carrierDocs: [],
  carrierDoc: null,
  carrierDocTypes: [],
  carrierDocsLoading: false,

  currentSkuRequest: null,
  curentCheckListDoc: null,

  splitedOrder: null,
  newOrder: null,
  splitedOrderSkus: [],
};

const LogistSlice = createSlice({
  name: LOGIST_STORE_NAME,
  initialState,
  reducers: {
    cleanUpAllStockPools: state => {
      state.stockPools = {
        incoming: null,
        appointed: null,
        boss: null,
        distributed: null,
        factory: null,
        selectedInModal: null,
        transporter: null,
      };
    },
    cleanUpAppointedStockPools: state => {
      state.stockPools.appointed = null;
    },
    setFetchedStockPool: (state, {payload}) => {
      state.fetchedStockPool = payload;
    },
    setSelectedInModalStockPool: (state, {payload}) => {
      state.stockPools.selectedInModal = payload;
    },

    removeStockPoolFromChosen: (state, {payload}) => {
      state.chosenStockPools = state.chosenStockPools?.filter(stockPool => stockPool.stockPoolId !== payload) ?? [];
    },
    setCurenCheckLIstFile: (state, {payload}) => {
      state.curentCheckListDoc = payload;
    },
    deselectSkuRequest: (state, {payload}) => {
      state.currentSkuRequest = null;
    },
    deselectCarrierDoc: (state, {payload}) => {
      state.carrierDoc = null;
    },
    clearCurrentWarehouse: state => {
      state.warehouses.currentWarehouse = null;
    },
    setCurrentWarehouseDocFile: (state, {payload}) => {
      state.warehouses.currentDocFile = payload;
    },
    setWarehouseComingNull: (state, {payload}) => {
      state.stockPools.warehouseComing = null;
      state.requests.warehouseComing = null;
    },

    deselectOrderSkus: (state, {payload}) => {
      state.splitedOrderSkus = [];
    },
    deselectFetchedStockPool: (state, {payload}) => {
      state.fetchedStockPool = null;
    },
  },
  extraReducers: builder => {
    builder
      //get
      .addCase(fetchAllStockPoolsUpdated.fulfilled, (state, {meta, payload: {stockPools, requests}}) => {
        const window = meta.arg?.window;
        switch (window) {
          case "Incoming":
            state.stockPools.incoming = stockPools;
            state.requests.incoming = requests;
            break;
          case "Appointed":
            state.stockPools.appointed = stockPools;
            state.requests.appointed = requests;
            break;
          case "Boss":
            state.stockPools.boss = stockPools;
            state.requests.boss = requests;
            break;
          case "Distributed":
            state.stockPools.distributed = stockPools;
            state.requests.distributed = requests;
            break;
          case "Factory Creating Cargo":
            state.stockPools.creatingCargo = stockPools;
            state.requests.creatingCargo = requests;
            break;
          case "Factory Cargo":
            state.stockPools.factoryCargo = stockPools;
            state.requests.factoryCargo = requests;
            break;
          case "Transporter":
            state.stockPools.transporter = stockPools;
            state.requests.transporter = requests;
            break;
          default:
            state.stockPools.factory = stockPools;
            state.requests.factory = requests;
            break;
        }
      })
      .addCase(fetchAllStockPoolsNew.fulfilled, (state, {meta, payload: {stockPools, skuGroups}}) => {
        const window = meta.arg?.window;
        switch (window) {
          case LOGIST_WINDOWS.INCOMING:
            if (!meta.arg?.stockPoolIds) {
              state.stockPools.incoming = stockPools;
            }
            state.requests.incoming = skuGroups;
            break;
          case LOGIST_WINDOWS.APPOINTED:
            meta.arg?.stockPoolIds ? (state.fetchedStockPool = stockPools) : (state.stockPools.appointed = stockPools);
            state.requests.appointed = skuGroups;
            break;
          case LOGIST_WINDOWS.BOSS:
            if (meta.arg?.stockPoolIds === undefined) {
              state.stockPools.boss = stockPools;
            }
            if (meta.arg?.stockPoolIds !== undefined) {
              state.fetchedStockPool = stockPools[0];
            }
            state.requests.boss = skuGroups;
            break;
          case LOGIST_WINDOWS.DISTRIBUTED:
            if (meta.arg?.stockPoolIds === undefined) {
              state.stockPools.distributed = stockPools;
            }
            if (meta.arg?.stockPoolIds !== undefined) {
              state.fetchedStockPool = stockPools[0];
            }
            state.requests.distributed = skuGroups;
            break;
          case LOGIST_WINDOWS.FACTORY_CREATING_CARGO:
            if (!meta.arg?.stockPoolIds) {
              state.stockPools.creatingCargo = stockPools;
            }
            state.requests.creatingCargo = skuGroups;
            break;
          case LOGIST_WINDOWS.FACTORY_CARGO:
            if (!meta.arg?.stockPoolIds) {
              state.stockPools.factoryCargo = stockPools;
            }
            state.requests.factoryCargo = skuGroups;
            break;
          case LOGIST_WINDOWS.TRANSPORTER:
            state.stockPools.transporter = stockPools;
            state.requests.transporter = skuGroups;
            break;
          case LOGIST_WINDOWS.WAREHOUSE_COMING:
            state.stockPools.warehouseComing = stockPools;
            state.requests.warehouseComing = skuGroups;
            break;
          // case LOGIST_WINDOWS.WAREHOUSE:
          //   state.stockPools.warehouse = stockPools;
          //   state.requests.warehouse = skuGroups;
          //   break;
          case LOGIST_WINDOWS.WAREHOUSE_CREATING_STOCKPOOL:
            if (!meta.arg?.stockPoolIds) {
              state.stockPools.warehouseCreatingSp = stockPools;
            }
            state.requests.warehouseCreatingSp = skuGroups;
            break;
          case LOGIST_WINDOWS.WAREHOUSE_STOCKPOOL:
            if (!meta.arg?.stockPoolIds) {
              state.stockPools.warehouseSp = stockPools;
            }
            state.requests.warehouseSp = skuGroups;
            break;
          case LOGIST_WINDOWS.WAREHOUSE_CREATING_CARGO:
            state.stockPools.warehouseCreatingCargo = stockPools;
            state.requests.warehouseCreatingCargo = skuGroups;
            break;
          case LOGIST_WINDOWS.WAREHOUSE_CARGO:
            state.stockPools.warehouseCargo = stockPools;
            state.requests.warehouseCargo = skuGroups;
            break;
          case LOGIST_WINDOWS.FINAL_DESTINATION_BOSS:
            state.stockPools.finalBoss = stockPools;
            state.requests.finalBoss = skuGroups;
            break;
          case LOGIST_WINDOWS.FINAL_DESTINATION_MANAGER:
            state.stockPools.finalManager = stockPools;
            state.requests.finalManager = skuGroups;
            break;

          default:
            if (!meta.arg?.stockPoolIds) {
              state.stockPools.factory = stockPools;
              state.stockPools.warehouse = stockPools;
            }
            state.requests.factory = skuGroups;
            state.requests.warehouse = skuGroups;

            break;
        }
      })
      .addCase(fetchStockPoolUpdated.fulfilled, (state, {meta, payload}) => {
        if (meta.arg?.type === "modal") {
          state.stockPools.selectedInModal = payload;
        } else {
          state.fetchedStockPool = payload;
        }
      })
      .addCase(fetchStockPoolBySkuId.fulfilled, (state, {payload}) => {
        state.currentSkuRequest = payload;
      })
      .addCase(fetchStockPoolBySkuRequestId.fulfilled, (state, {meta, payload}) => {
        if (meta.arg?.type === "modal") {
          state.stockPools.selectedInModal = payload;
        } else {
          state.fetchedStockPool = payload;
        }
        // state.fetchedStockPool = payload;
      })
      .addCase(fetchRequestsForLogistics.fulfilled, (state, {meta, payload}) => {
        switch (meta.arg?.window) {
          case SLEEPING_WINDOW_PARAMS.SLEEPING:
            state.requests.forLogistics.sleeping = payload;
            break;
          case SLEEPING_WINDOW_PARAMS.SLEEPING_IN_SP:
            state.requests.forLogistics.sleepingInSp = payload;
            break;
        }
      })
      .addCase(fetchStockPoolFilters.fulfilled, (state, {payload}) => {
        state.filters = payload;
      })
      .addCase(fetchFiltersForLogistics.fulfilled, (state, {payload}) => {
        state.filters = payload;
      })
      // .addCase(fetchStockPoolFiltersUpdated.fulfilled, (state, {payload}) => {
      //   state.filters = payload;
      // })
      //put
      .addCase(appointStockPool.fulfilled, state => {
        state.fetchedStockPool = null;
      })
      .addCase(cancelStockPoolAppointment.fulfilled, state => {
        state.fetchedStockPool = null;
      })
      .addCase(cancelAllStockPoolAppointments.fulfilled, state => {
        state.stockPools.appointed = null;
        state.requests.appointed = null;
        state.fetchedStockPool = null;
      })
      .addCase(distributeStockPools.fulfilled, state => {
        state.stockPools.incoming = null;
        state.stockPools.appointed = null;
        state.requests.incoming = null;
        state.requests.appointed = null;
      })

      //*** Cargos ***/
      //get
      .addCase(fetchAllCargos.fulfilled, (state, {payload}) => {
        state.cargos.all = payload;
      })
      .addCase(fetchCargo.fulfilled, (state, {payload}) => {
        state.cargos.chosen = payload;
      })
      //post
      .addCase(addNewCargo.fulfilled, (state, {payload}) => {
        state.cargos.added = payload;
      })

      .addCase(updateCargo.fulfilled, (state, {payload}) => {
        // let copy = {...state.currentSkuRequest};
        // copy.stockPool.cargo.isFinal = payload.isFinal;
        // state.currentSkuRequest = {...copy};
        // state.currentSkuRequest = {
        //   ...state.currentSkuRequest,
        //   stockPool: {
        //     ...state.currentSkuRequest.stockPool,
        //     cargo: {...state.currentSkuRequest.stockPool.cargo, isFinal: payload.isFinal},
        //   },
        // };
      })
      //*** Warehouses ***/
      .addCase(fetchAllWarehouses.fulfilled, (state, {payload}) => {
        state.warehouses.warehouses = payload;
      })
      .addCase(addWarehouse.fulfilled, () => {
        // state.warehouses.push(payload);
      })
      .addCase(updateWarehouse.fulfilled, (state, {payload}) => {
        state.warehouses.warehouses = state.warehouses.warehouses.map(whouse => {
          if (whouse.warehouseId === payload.warehouseId) {
            return {...whouse, ...payload};
          }
          return whouse;
        });
        state.warehouses.currentWarehouse = {...state.warehouses.currentWarehouse, ...payload};
      })
      .addCase(fetchWarehousesFilters.fulfilled, (state, {payload}) => {
        state.filters = payload;
      })
      .addCase(fetchWarehouseInfo.fulfilled, (state, {payload}) => {
        state.warehouses.currentWarehouse = payload;
      })
      //** Warehouse Types **/
      .addCase(fetchAllWarehouseTypes.fulfilled, (state, {payload}) => {
        state.warehouses.warehouseTypes = payload;
      })
      // .addCase(addNewWarehouseType.fulfilled, () => {
      //   // state.warehouses.addedWarehouseType=payload;
      // })
      // .addCase(deleteWarehouseType.fulfilled, () => {
      //   // state.warehouses.addedWarehouseType=null;
      // })
      //** Warehouse Contacts **/
      // .addCase(addNewWarehouseContact.fulfilled, () => {
      //   // state.warehouses.addedWarehouseContact=payload;
      // })
      // .addCase(deleteWarehouseContact.fulfilled, () => {
      //   // state.warehouses.addedWarehouseContact=null;
      // })
      //** Warehouse Accounts **/
      // .addCase(addNewWarehouseAccount.fulfilled, () => {
      //   // state.warehouses.addedWarehouseAccount=payload;
      // })
      // .addCase(deleteWarehouseAccount.fulfilled, () => {
      //   // state.warehouses.addedWarehouseAccount=null;
      // })
      //** Warehouse Docs **/
      // .addCase(addNewWarehouseDoc.fulfilled, () => {
      //   // state.warehouses.addedWarehouseDoc=payload;
      // })
      // .addCase(updateWarehouseDoc.fulfilled, () => {
      //   // state.warehouses.addedWarehouseDoc=payload;
      // })
      // .addCase(deleteWarehouseDoc.fulfilled, () => {
      //   // state.warehouses.addedWarehouseDoc=null;
      // })
      //* Warehouse Docs Files */
      // .addCase(addNewWarehouseDocFile.fulfilled, (state, {payload}) => {
      //   state.warehouses.currentDocFile = {id: payload.fileId, url: payload.fileUrl};
      // })
      // .addCase(deleteWarehouseDocFile.fulfilled, () => {
      //   // state.warehouses.addedWarehouseDocFile=null;
      // })

      //***Carriers***/
      .addCase(fetchCarrierFilters.fulfilled, (state, {payload}) => {
        state.filters = payload;
      })
      .addCase(fetchAllCarrierTypes.fulfilled, (state, {payload}) => {
        state.carrierTypes = payload;
      })
      .addCase(fetchAllCarriers.fulfilled, (state, {payload}) => {
        state.carriers = payload;
      })
      .addCase(getCarrier.fulfilled, (state, {payload}) => {
        state.carrier = payload;
      })
      .addCase(fetchAllCarrierDocTypes.fulfilled, (state, {payload}) => {
        state.carrierDocTypes = payload;
      })
      // Carrier Docs

      .addCase(fetchAllCarrierDocs.fulfilled, (state, {payload}) => {
        state.carrierDocs = payload;
      })
      .addCase(addCarrierDoc.pending, (state, {payload}) => {
        state.carrierDocsLoading = true;
      })
      .addCase(addCarrierDoc.fulfilled, (state, {payload}) => {
        state.carrierDocsLoading = false;
        state.carrierDoc = payload;
        state.carrierDocs = [payload, ...state.carrierDocs];
      })
      .addCase(deleteCarrierDoc.fulfilled, (state, action) => {
        state.carrierDocs = state.carrierDocs.filter(req => req.docId !== action.meta.arg);
      })
      .addCase(splitDefectedOrder.fulfilled, (state, action) => {
        //TODO
        const {payload, meta} = action;
        // console.log("splitRequests payload", payload);
        state.splitedOrder = payload.order;
        // const firstOrderNumber =
        //   state.thirdStatusRequests.length > 0
        //     ? state.thirdStatusRequests.find(req => req.requestId === meta.arg)?.requestNumber
        //     : state.currentRequestInfo.requestNumber;
        state.newOrder = payload.newOrder;
        state.splitedOrderSkus = payload.order.sku;
      })
      .addCase(updateSplitedOrder.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.newOrder = payload;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(LOGIST_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(LOGIST_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(LOGIST_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.logistLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.logistLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.logistLoading = false;
  state.error = error;
}

const {actions, reducer} = LogistSlice;
export const {
  cleanUpAllStockPools,
  cleanUpChosenStockPools,
  removeStockPoolFromChosen,
  setChosenStockPools,
  cleanUpAppointedStockPools,
  setChosenFilters,
  setFetchedStockPool,
  setSelectedInModalStockPool,
  setCurenCheckLIstFile,
  deselectSkuRequest,
  deselectCarrierDoc,
  clearCurrentWarehouse,
  setCurrentWarehouseDocFile,
  setWarehouseComingNull,
  deselectOrderSkus,
  deselectFetchedStockPool,
} = actions;

export default reducer;
