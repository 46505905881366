import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import {Select} from "../../../Inputs/SelectField";

import StartDates from "./StartDates/StartDates";
import TermsSettings from "./TermsSettings/TermsSettings";
import WrSettingsInfo from "./WrSettingsInfo/WrSettingsInfo";
import useLogModSettingsDeadlines from "./useLogModSettingsDeadlines";

const LogModSettingsDeadlines = () => {
  const {t} = useTranslation();

  const {
    initialValues,
    wrTermsMainTableColumns,
    wrTermsMainTableData,
    onWrTermsMainTableSelect,
    countryOptions,
    warehouseOptions,
    startDateOptions,
    startDatesTableData,
    onSaveFirstTermBtnClick,
    onDeleteFirstTermBtnClick,
    onSaveSecondTermBtnClick,
    onDeleteSecondTermBtnClick,
    termTypeOptions,
    wrSettingsInfoTableData,
    onCountrySelect,
    isCountrySelected,
    onStartDatesTableSelect,
    currentDateId,
    onWarehouseSelect,
    warehouseInSettingsOptions,
    onWrSettingsWarehouseSelect,
    currentReminderId,
    areRemindersLoading,
  } = useLogModSettingsDeadlines();

  return (
    <div className="page_flex_container noGapDiv">
      <Formik initialValues={initialValues} enableReinitialize>
        {({values, setValues}) => (
          <Form>
            <div className="grid2Col gap">
              <div className="borderRight paddingRight">
                <h4 className="sectionHeader underlined">{t("Setting control terms")}</h4>
                <div className="row mb">
                  <Select
                    label={t("Country")}
                    name="country"
                    options={countryOptions}
                    width="12rem"
                    makeEffect={item => onCountrySelect({item, setValues})}
                  />
                  <Select
                    label={t("Warehouse")}
                    name="warehouse"
                    options={warehouseOptions}
                    width="20rem"
                    disabled={!isCountrySelected}
                    question={"Select a country first"}
                    makeEffect={item => onWarehouseSelect({item, setValues})}
                  />
                </div>
                <Divider />
                <div className="grid2Col gap2x">
                  <StartDates
                    startDateOptions={startDateOptions}
                    startDatesTableData={startDatesTableData}
                    onStartDatesTableSelect={onStartDatesTableSelect}
                    currentDateId={currentDateId}
                  />
                  <TermsSettings
                    termTypeOptions={termTypeOptions}
                    onSaveFirstTermBtnClick={() => onSaveFirstTermBtnClick(values)}
                    onDeleteFirstTermBtnClick={() => onDeleteFirstTermBtnClick(setValues)}
                    onSaveSecondTermBtnClick={() => onSaveSecondTermBtnClick(values)}
                    onDeleteSecondTermBtnClick={() => onDeleteSecondTermBtnClick(setValues)}
                    isCurrentReminder={!!currentReminderId}
                  />
                </div>
              </div>
              <div className="paddingLeft" style={{paddingTop: "40px"}}>
                <WrSettingsInfo
                  warehouseOptions={warehouseInSettingsOptions}
                  onWrSettingsWarehouseSelect={onWrSettingsWarehouseSelect}
                  wrSettingsInfoTableData={wrSettingsInfoTableData}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ReactTable
        className="tableTac mt2x"
        columns={wrTermsMainTableColumns}
        defaultData={wrTermsMainTableData}
        current={currentReminderId}
        onSelect={onWrTermsMainTableSelect}
        style={{height: "20rem"}}
        loading={areRemindersLoading}
      />
    </div>
  );
};

export default LogModSettingsDeadlines;
