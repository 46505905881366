import {useRef} from "react";
import PropTypes from "prop-types";

import {useHotkey} from "../../hooks/useHotkey";
import ImageButton from "../ImageBtn";

import filterOffImg from "./src/8679938_filter_off_line_icon.png";
import filterImg from "./src/8680055_filter_line_icon.png";
const FilterButton = ({
  onFilter,
  delFilter,
  questionTitle = "",
  areFiltersDefined = false,
  disabled = false,
  children,
}) => {
  const containerRef = useRef();

  useHotkey(onFilter, "Space", "KeyF");
  useHotkey(delFilter, "Escape");

  return (
    <div className="filters_container" ref={containerRef}>
      <div className="flex_container nowrap" style={{height: "2.9em"}}>
        <ImageButton
          src={filterImg}
          alt="filter"
          tooltipMessage={questionTitle}
          onClick={() => onFilter()}
          size={2}
          className={"filterBtn " + (areFiltersDefined ? "definedFilters" : "")}
          disabled={disabled}
        />

        <ImageButton src={filterOffImg} alt="filter off" onClick={delFilter} size={2} disabled={disabled} />
      </div>
      {children}
    </div>
  );
};

FilterButton.propTypes = {
  onFilter: PropTypes.func,
  delFilter: PropTypes.func,
  areFiltersDefined: PropTypes.bool,
};

export default FilterButton;
