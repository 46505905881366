import {useMemo} from "react";
import {useSelector} from "react-redux";

import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {getRequestInfo} from "../../../Requests/RequestsSlice";

import {
  GROUP_AND_TYPE_MODES,
  GROUP_AND_TYPE_ORDER_COLUMNS,
  GROUP_AND_TYPE_RQST_COLUMNS,
  GROUP_AND_TYPE_SP_COLUMNS,
  GROUP_AND_TYPE_TP_COLUMNS,
} from "./constants";

const useGroupAndTypeService = (mode, orders) => {
  const curentRequest = useSelector(getRequestInfo);
  const columnsDrafts = {
    [GROUP_AND_TYPE_MODES.ORDER]: GROUP_AND_TYPE_RQST_COLUMNS,
    [GROUP_AND_TYPE_MODES.RQST]: GROUP_AND_TYPE_ORDER_COLUMNS,
    [GROUP_AND_TYPE_MODES.SP]: GROUP_AND_TYPE_SP_COLUMNS,
    [GROUP_AND_TYPE_MODES.TP]: GROUP_AND_TYPE_TP_COLUMNS,
  };
  const groupAndTypeTableColumns = useColumns(columnsDrafts[mode]);
  const groupAndTypeTableData = useMemo(() => {
    try {
      return (
        orders?.map((order, i) => {
          const {
            orderId,
            orderNumber,
            SkuRequest: {productGroup, productType},
          } = order;
          return {
            number: i + 1,
            id: orderId,
            orderNumber: orderNumber,
            groupAndType: productGroup?.name + ", " + productType.name,
          };
        }) || []
      );
    } catch (e) {
      var typegroups = [];
      var counter = 0;

      orders?.forEach((order, i) => {
        order.skuRequests?.forEach((sku, j) => {
          typegroups.push({
            number: counter + 1,
            id: sku.skuRequestId,
            orderNumber: order.orderNumber,
            groupAndType: sku.productGroup?.name + ", " + sku.productType.name,
          });
          counter++;
        });
      });
      return typegroups;
    }
  }, [orders]);

  return {groupAndTypeTableColumns, groupAndTypeTableData};
};

export default useGroupAndTypeService;
