// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rv_block {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 1em;
}

.rv_right {
  width: 30%;
}

.rv_left {
  width: 70%;
}

.rv_buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 1em;
}

.v_main_fields {
  display: flex;
  flex-wrap: nowrap;
  gap: 3em;
}

.v_add_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
}

.v_add_item {
  flex-basis: calc(18% - 10px);
}

.v_buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;
  gap: 1em;
  margin-top: 1em;
}

.v_add_button {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 0.5em;
}

.v_modal {
  height: 80vh;
  width: 90vw;
}`, "",{"version":3,"sources":["webpack://./src/Components/VED/Verification/ReceiptAndVerificationPf/rAndV.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,QAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,QAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;EACA,eAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF","sourcesContent":[".rv_block {\n  width: 100%;\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 1em;\n}\n\n.rv_right {\n  width: 30%;\n}\n\n.rv_left {\n  width: 70%;\n}\n\n.rv_buttons {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  margin-top: 1em;\n}\n\n.v_main_fields {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 3em;\n}\n\n.v_add_fields {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: end;\n}\n\n.v_add_item {\n  flex-basis: calc(18% - 10px);\n}\n\n.v_buttons {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: end;\n  gap: 1em;\n  margin-top: 1em;\n}\n\n.v_add_button {\n  display: flex;\n  justify-content: end;\n  flex-wrap: wrap;\n  gap: 0.5em;\n}\n\n.v_modal {\n  height: 80vh;\n  width: 90vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
