import React from "react";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {NumberInput, TextInput} from "../../../../Inputs";
import InputWithMeasureUnitsReverse from "../../../../Inputs/InputWithMeasureUnitsReverse";
import TemplatesTable from "../common/TemplatesTable/TemplatesTable";

import {TIME_UNITS_INPUT_LIST_DATA} from "./constants";
import useTermsSettingsModalService from "./useTermsSettingsModalService";

import styles from "../modTempates.module.scss";

const TermsSettingsModal = () => {
  const {t} = useTranslation();
  const {onSaveTermsBtnClick, onDeleteTermsBtnClick, initialValues, measureUnitsList, areBtnsDisabled} =
    useTermsSettingsModalService();

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({values}) => (
        <section className={styles.reqTemplatesSection}>
          <h2 className={styles.sectionHeader}>
            3. <span className={styles.underLined}>{t("Control Terms Settings")}</span>
          </h2>
          <TemplatesTable />
          <div className={styles.sectionRow + " " + styles.fabricOptions}>
            <div className={styles.leftGroup}>
              <TextInput label={t("For Factory") + ":"} name="factoryName" width="26.6em" />
              <TextInput label={t("Date: '1' => Date: '2' by default") + ":"} name="defaultDate" width="15em" />
            </div>
            <ol className={styles.termsList}>
              {TIME_UNITS_INPUT_LIST_DATA.map(element => (
                <li key={element.inputName}>
                  <InputWithMeasureUnitsReverse
                    measureUnitsList={measureUnitsList}
                    name={element.unitName}
                    label={t(element.label) + ":"}
                    selectWidth="65px"
                  >
                    <NumberInput name={element.inputName} width="6em" />
                  </InputWithMeasureUnitsReverse>
                </li>
              ))}
            </ol>
          </div>
          <TextInput
            generalClassName={styles.controlTerms}
            label={t("Control terms plan / fact") + ":"}
            name="controlTermsSummary"
            disabled
            autolabel
            width="75%"
          />
          <div className={styles.buttonRow}>
            <FunctionButton
              name="Delete"
              disabled
              // disabled={areBtnsDisabled}
              onClick={onDeleteTermsBtnClick}
              autoWidth
            />
            <FunctionButton
              name="Save"
              disabled={areBtnsDisabled}
              onClick={() => onSaveTermsBtnClick(values)}
              autoWidth
            />
          </div>
        </section>
      )}
    </Formik>
  );
};

export default TermsSettingsModal;
