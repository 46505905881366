export const FACTORY_ID_SESSION_STORAGE = "factoryId";

export function getFactoryIdSessionStorage() {
  return JSON.parse(sessionStorage.getItem(FACTORY_ID_SESSION_STORAGE)) ?? {};
}

export function setFactoryIdSessionStorage(factoryId, modalType) {
  sessionStorage.setItem(FACTORY_ID_SESSION_STORAGE, JSON.stringify({factoryId, modalType}));
}

export function cleanFactoryIdSessionStorage() {
  sessionStorage.setItem(FACTORY_ID_SESSION_STORAGE, null);
}
