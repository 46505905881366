import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Field, useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {fetchData, fetchList} from "../../../../hook/axios.hook";
import {dateFormat} from "../../../../utilities/dateFormat";
import {getInfo, getSuccess} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, MoneyInput, NoteInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import SingleFilePicker from "../../../Inputs/SingleFilePicker";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import useWarehouseServicesOptions from "../../common/Warehouses/Services/useWarehouseServicesOptions";
import {selectIsLogistLoading} from "../../redux/selectors";

import {WAREHOUSE_SERVICE_TABLE_COLUMNS} from "./constants";

const WarehouseServiceList = ({currentStockPool, currentWarehouseId}) => {
  const {t} = useTranslation();
  const docsLoading = useSelector(selectIsLogistLoading);

  const [services, setServices] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);

  const [currentStockServiceId, setCurrentStockService] = useState(null);
  const {values, setFieldValue, setValues, initialValues, submitForm} = useFormikContext();

  const updateList = () => {
    fetchList(`/stockPools/stockServices/getAll?stockPoolId=${currentStockPool.stockPoolId}`).then(res => {
      setServices(res);
    });
  };

  useEffect(() => {
    if (currentStockPool) {
      updateList();
      setCurrentStockService(null);
      const orders =
        currentStockPool?.orders?.map(order => ({
          title: order.orderNumber,
          value: order.orderId,
        })) || [];
      setOrderOptions(orders);
    } else {
      setServices([]);
      setValues(prev => ({
        ...prev,
        spNumber: "",
        orderId: "",
        serviceId: null,
        price: "",
        serviceDate: "",
        sum: "",
        warehouseServiceNote: "",
      }));
    }
    setCurrentStockService(null);
    setFieldValue("spNumber", currentStockPool?.stockPoolNumber || "");
  }, [currentStockPool?.stockPoolId]);

  useEffect(() => {
    if (currentStockServiceId === null) {
      setValues({
        ...initialValues,
        spNumber: values.spNumber,
        orderId: "",
        serviceId: null,
        price: "",
        serviceDate: "",
        sum: "",
        warehouseServiceNote: "",
      });
      return;
    } else {
      try {
        const serv = services.find(s => s.stockServiceId === currentStockServiceId);
        setValues({
          ...values,
          orderId: serv?.orderId || "",
          serviceDate: serv.date,
          price: serv.service.price,
          serviceId: serv?.service?.serviceId || "",
          sum: serv?.sum || "",
          warehouseServiceNote: serv?.note || "",
        });
      } catch (e) {
        console.log("e");
      }
    }
  }, [currentStockServiceId]);

  const warehouseServiceTableData =
    services?.map((serv, index) => ({
      id: serv.stockServiceId,
      number: ++index,
      serviceType: serv.service.name,
      date: dateFormat(serv.date),
      price: serv.service.price,
      sum: serv.sum,
      note: serv.note,
    })) || [];

  const warehouseServiceTableColumns = useColumns(WAREHOUSE_SERVICE_TABLE_COLUMNS);

  const warehouseServiceOptions = useWarehouseServicesOptions(currentWarehouseId);

  const onDocAdding = () => {};

  const onStockServiceSave = () => {
    submitForm().then(values => {
      if (values) {
        const data = {
          orderId: values.orderId,
          sum: values.sum,
          date: values.serviceDate,
          note: values.warehouseServiceNote,
        };
        if (currentStockServiceId) {
          fetchData(`/stockPools/stockServices/update/${currentStockServiceId}`, "patch", {sum: values.sum}).then(
            res => {
              setServices(prev =>
                prev.map(ser => {
                  if (ser.stockServiceId === res.stockServiceId) return {...ser, sum: values.sum};
                  return ser;
                }),
              );
              getSuccess("Оновлено");
            },
          );
        } else {
          fetchData(
            `/stockPools/stockServices/add/${values.serviceId}/${currentStockPool.stockPoolId}`,
            "post",
            data,
          ).then(res => {
            updateList();
            setCurrentStockService(res.stockServiceId);
            getSuccess("");
          });
        }
      }
    });
  };

  const onStockServiceSaveNote = () => {
    if (currentStockServiceId) {
      fetchData(`/stockPools/stockServices/update/${currentStockServiceId}`, "patch", {
        note: values.warehouseServiceNote,
      }).then(res => {
        // setServices(prev => [...prev, res]);
        setServices(prev =>
          prev.map(ser => {
            if (ser.stockServiceId === res.stockServiceId) return {...ser, note: res.note};
            return ser;
          }),
        );
        getSuccess("Примітку оновлено");
      });
    }
  };

  const onStockServiceDelete = () => {
    Swal.fire({
      title: "",
      text: t("Sure"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        fetchData(`/stockPools/stockServices/remove/${currentStockServiceId}`, "delete");
      }
    });
  };

  const onStockServiceNoteUpdate = value => {
    fetchData(`/stockPools/stockServices/update/${currentStockServiceId}`, "patch", {note: value}).then(res => {
      getInfo(t("updated"));
      setServices(prev =>
        prev.map(serv => {
          if (serv.stockServiceId === currentStockServiceId) {
            return {...serv, note: res.note};
          }
          return serv;
        }),
      );
    });
  };

  return (
    <div className="autolabel fGrow flex_form mb">
      <p className="underlined">{`${t("Warehouse service List")}:`}</p>
      <div className="row mb0_5">
        <TextInput name="spNumber" label={t("SP №")} disabled style={{width: "14em"}} />
        {/* <CheckBoxWithLabel label="" onCheck={() => {}} /> */}
        <div className="fc nowrap ">
          <Field name="confirm" type="checkbox" className="mr_05" disabled={!currentStockPool} />
          <Select
            name="orderId"
            label={t("Order number")}
            width="8em"
            options={orderOptions}
            disabled={!values.confirm}
          />
        </div>
      </div>
      <div className="row mb0_5">
        <Select
          disabled={!currentStockPool}
          label={t("Service type")}
          name="serviceId"
          options={warehouseServiceOptions}
          // disabled={true}
          style={{width: "60%"}}
          makeEffect={option => {
            setFieldValue("price", option?.price || "");
          }}
        />
        <MoneyInput name="price" label={t("Price")} style={{width: "10rem"}} disabled />

        <SingleFilePicker disabled={true} addFunc={onDocAdding} accept={".pdf"} />
      </div>
      <div className="row">
        <DateInput
          label={t("Service date (n)") + ": "}
          disabled={!currentStockPool}
          name="serviceDate"
          labelW={"7.5em"}
        />
        <NoteInput
          disabled={!currentStockPool}
          action={onStockServiceSave}
          deleteDisabled={!currentStockServiceId}
          onDelete={onStockServiceDelete}
          name="sum"
          label={t("Sum (f)")}
          deleteIcon={true}
          width={"5rem"}
          style={{marginBottom: 0}}
        />
      </div>
      <NoteInput
        disabled={!currentStockPool}
        name="warehouseServiceNote"
        action={onStockServiceSaveNote}
        onDelete={() => {
          onStockServiceNoteUpdate(null);
          setFieldValue("warehouseServiceNote", "");
        }}
        label={t("Note")}
        width={"100%"}
        deleteIcon={true}
        deleteDisabled={!currentStockServiceId}
      />

      <div style={{flexGrow: 1}}>
        <ReactTable
          className="tableTac"
          columns={warehouseServiceTableColumns}
          defaultData={warehouseServiceTableData}
          onSelect={setCurrentStockService}
          current={currentStockServiceId}
          loading={docsLoading}
        />
      </div>
    </div>
  );
};

export default withFormik({
  handleSubmit: values => {
    // console.log(values);
    return values;
  },
  validationSchema: () =>
    Yup.object({
      // orderId: Yup.number().nullable().required(t("Required field")),
      serviceId: Yup.number().nullable().required(t("Required field")),
      // sum: Yup.string().required([t("Required field")]),
      serviceDate: Yup.string().required([t("Required field")]),
      // note: Yup.string().required(t("Required field")),
      // date: Yup.date().required(t("Required field")),
    }),
  mapPropsToValues: () => ({
    spNumber: "",
    orderId: "",
    serviceId: null,
    price: "",
    serviceDate: "",
    sum: "",
    warehouseServiceNote: "",
  }),
  enableReinitialize: true,
})(WarehouseServiceList);
