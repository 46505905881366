import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {Field, Form, Formik, useFormikContext} from "formik";
import {values} from "lodash";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {useDebouncedCallback} from "../../../hook/debounced";
import {designersCatalogSelector, getOrgStructures} from "../../../selectors/CatalogSelectors";
import {getCurrentProject} from "../../../selectors/generalSelectors";
import {deselectKp, setKpList} from "../../../slices/KPSlice";
import {getClients, getUsers, getUsersFull} from "../../../slices/PersonSlice";
import {getCurrentUser} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ControlFieldsChanges from "../../../utilities/ControllChanges";
import {getError, getSuccess} from "../../../utilities/toasts.js";
import {InvisibleInput, ListSelect, NumberInput, Select, TextInput} from "../../Inputs/index.js";
import {Select as SelectField} from "../../Inputs/SelectField";
import {remindsFetched} from "../../reminder/RemindsSlice";
import useCountryIdOptionsService from "../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService.js";
import {
  addProject,
  deselectProject,
  fetchProjectByUserId,
  getCurrentProjectManager,
  getProjectStatus,
  getProjectStatuses,
  setCurrentProjectManager,
  updateProject,
  updateProjectStatus,
} from "../ProjectSlice";

import {langKeyMapper} from "./constants";

import "./ProjectAddForm.scss";
import "../../../Styles/Buttons.scss";

const ProjectAddForm = ({updates, onMakeEffect, setIsEdited}) => {
  const {t, i18n} = useTranslation();

  const designersCatalog = useSelector(designersCatalogSelector),
    orgStructuresCatalog = useSelector(getOrgStructures),
    currentProjectManager = useSelector(getCurrentProjectManager);

  const [isDisabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const {projectsLoadingStatus, projectsIdList} = useSelector(state => state.projects);
  const countryIdOptions = useCountryIdOptionsService();

  const currentProject = useSelector(getCurrentProject);

  const clients = useSelector(getClients);
  const clientsCatalog = useMemo(
    () => clients.map(client => ({title: client.clientNumber, value: client.clientId})),
    [clients],
  );

  const user = useSelector(state => state.user.user);

  const projectsCatalog = useMemo(() => {
    return projectsIdList.map(item => {
      return {value: item.number, id: item.id};
    });
  }, [projectsIdList]);

  const initialValues = useMemo(() => {
    return {
      d1: "",
      projectKey: "",
      designNumber: "",
      designerId: null,
      address: "",
      status: "",
      clientBalance: "",
      clientNumber: "",
      clientId: "",
      orgName: currentProjectManager?.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
      orgStructureId: currentProjectManager?.orgStructureId || "",
      userName: currentProjectManager?.name || "",
      userId: currentProjectManager?.userId || "",
      designerName: "",
      designerBonus: "",
      designer: "",
      countryId: "",
    };
  }, [i18n.language, currentProjectManager, orgStructuresCatalog]);

  const onProjectDelete = () => {
    if (currentProject) {
      Swal.fire({
        title: [t("Sure")],
        text: [t("Status change")],
        icon: "question",
        confirmButtonText: [t("Yes")],
        showCancelButton: true,
        cancelButtonText: [t("No")],
      }).then(value => {
        if (value.isConfirmed) {
          dispatch(
            updateProjectStatus({
              projectKey: currentProject.projectId,
              status: "Видалeний",
            }),
          );
        } else {
          return;
        }
      });
    } else {
      Swal([t("Project at first")]);
    }
  };

  const onUpdate = values => {
    if (!currentProject) {
      getError("Спочатку потрібно створити проект або обрати існуючий");
      return;
    }
    if (!values.countryId || values.countryId === "") {
      getError("Країна поставки повинна бути вказана");
      return;
    }
    dispatch(
      updateProject({
        projectId: values.projectId,
        data: {
          designerId: values.designerId,
          designNumber: values.designNumber,
          address: values.address,
          statusId: values.statusId,
          countryId: values.countryId,
        },
      }),
    );
  };

  const onEffect = useDebouncedCallback(onMakeEffect, 1000);

  const currentFormState = useMemo(() => {
    if (!currentProject) return initialValues;
    return {
      ...currentProject,
      orgName: currentProject.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
    };
  }, [currentProject, currentProjectManager, orgStructuresCatalog]);

  return (
    <Formik
      initialValues={currentFormState}
      validationSchema={Yup.object().shape({
        clientId: Yup.number()
          .typeError(t("Client ne"))
          .required([t("Client number")]),
        countryId: Yup.number()
          .typeError(t("Country ne"))
          .required([t("Country number")]),
      })}
      validateOnChange={!currentProject}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, {resetForm}) => {
        dispatch(
          addProject({
            clientId: values.clientId,
            project: {
              orgStructureId: values.orgStructureId,
              designerId: values.designerId,
              designNumber: values.designNumber,
              address: values.address,
              statusId: values.statusId,
              countryId: values.countryId,
            },
          }),
        ).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            onEffect(res.payload.project.projectId);
            // getSuccess(`${"Project"} ${res.payload.project.projectId} ${t("created")}`);
          }
        });
      }}
    >
      {({values}) => (
        <Form className="project_form">
          <ControlFieldsChanges editFunc={setIsEdited} forcedUpdate={updates} updFunc={onUpdate} />
          <div className="add-project-form">
            <div className="add-project-form__container">
              <DisabledFields setDisabled={setDisabled} />
              <SelectField
                label={t("Client #")}
                name="clientId"
                options={clientsCatalog}
                readOnly={!!currentProject}
                width="100%"
              />
              <Select
                label={t("Project #")}
                name="projectNumber"
                options={projectsCatalog}
                makeEffect={option => {
                  onEffect(option.id);
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    onEffect(values.projectId);
                  }
                }}
                readOnly={!!currentProject}
                width="100%"
              />
              <TextInput
                label={t("Design P")}
                id="designNumber"
                name="designNumber"
                type="text"
                question={t("Design number")}
                width="100%"
                readOnly={isDisabled}
                // readOnly={values.clientId === ""}
              />
            </div>
            <div className="add-project-form__container autolabel pw">
              <TextInput
                label={t("Balance")}
                id="clientBalance"
                name="clientBalance"
                type="text"
                width="67%"
                labelStyles={{width: "8.5em"}}
                readOnly={true}
                // autolabel={true}
              />
              <Status currentProject={currentProject} readOnly={isDisabled} />

              <TextInput label={t("Offic")} name="orgName" labelStyles={{width: "8.5em"}} width="67%" readOnly={true} />
              {/* <TextInput label={t("Manager")} name="userName" readOnly={true} /> */}
              <Manager />
            </div>
            <div className="add-project-form__container fc g1">
              <SelectField
                label={t("Delivery country:")}
                name="countryId"
                options={countryIdOptions}
                width="10em"
                autolabel
                generalClassName={"left-drop"}
                // readOnly={isDisabled}
              />

              <TextInput
                label={t("Adress")}
                id="projectAdress"
                name="address"
                type="text"
                width="100%"
                autolabel
                generalClassName={"flexg2"}
                readOnly={isDisabled}
              />
            </div>
            <div className="add-project-form__container">
              <Select
                label={t("Designer")}
                name="designerName"
                multi={true}
                options={designersCatalog}
                readOnly={isDisabled}
              />
              <TextInput
                label={t("Designer %")}
                name="designerBonus"
                readOnly={true}
                width="67%"
                className="procent"
                question={t("designer info")}
              />
            </div>
            <div className="add-project-form__container" style={{width: "50%"}}>
              <div className="fixed_button__container " style={{marginTop: "3%"}}>
                <FunctionButton
                  type="submit"
                  id="create_project"
                  name={t("Create p")}
                  disabled={
                    !currentProject && projectsLoadingStatus === "complete" && values?.userId === user?.id
                      ? false
                      : true
                  }
                  title={!currentProject && projectsLoadingStatus === "complete" ? null : [t("Tap proj")]}
                />

                <FunctionButton
                  name={t("Delete p")}
                  className="fixed_button fixed_button__delete "
                  onClick={onProjectDelete}
                  // disabled={currentProject && canDeleteProj ? false : true}
                  disabled={true}
                  // title={currentProject && canDeleteProj? null :"Неможливо видалити проект у якого є КП" }
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const DisabledFields = ({setDisabled}) => {
  const {
    values: {clientId},
  } = useFormikContext();

  useEffect(() => {
    setDisabled(clientId === "" || !clientId);
  }, [clientId]);

  return null;
};

const Status = props => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const statusCatalog = useSelector(getProjectStatus);

  useEffect(() => {
    if (statusCatalog.length < 1) {
      dispatch(getProjectStatuses());
    }
  }, []);

  const formattedStatusCatalog = statusCatalog.map(statusItem => {
    const value = statusItem[langKeyMapper[i18n.language]];
    return {title: value, value: statusItem.statusId};
  });

  // console.log("formattedStatusCatalog", formattedStatusCatalog);

  // const {setFieldValue} = useFormikContext();

  // const changeStatus = value => {
  //   const selectedId =
  //     statusCatalog.find(statusItem => statusItem[langKeyMapper[i18n.language]] === value)?.statusId || 1;

  //   if (currentProject && selectedId) {
  //     Swal.fire({
  //       title: "",
  //       text: [t("Update s")],
  //       icon: "question",
  //       confirmButtonText: [t("Yes")],
  //       showCancelButton: true,
  //       cancelButtonText: [t("No")],
  //     }).then(answ => {
  //       if (answ.isConfirmed) {
  //         console.log("currentProject.projectId", currentProject.projectId);
  //         dispatch(
  //           updateProjectStatus({
  //             projectId: currentProject.projectId,
  //             statusId: selectedId,
  //           }),
  //         ),
  //           setFieldValue("status", currentProject.status[langKeyMapper[i18n.language]]);
  //       }
  //     });
  //   }
  // };
  return (
    <SelectField
      label={t("Status p")}
      selectOnly={true}
      name="statusId"
      width="67%"
      labelwidth="8.5em"
      // labelStyles={{width: "12em"}}
      options={formattedStatusCatalog}
      {...props}
    />
  );
};

const Manager = props => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {values} = useFormikContext();
  const {userId, userName} = values;
  // const usersSel = useSelector(getUsers);
  const fullusers = useSelector(getUsersFull);
  const currentProjectManager = useSelector(getCurrentProjectManager);
  const currentProject = useSelector(getCurrentProject);

  const usersSelector = createSelector([getUsers], users =>
    users?.map(item => {
      return {
        value: item.name,
        userId: item.userId,
      };
    }),
  );

  const users = useSelector(usersSelector);

  useEffect(() => {
    // if(userId && userId !=="" && userId !== initialValues.userId){

    // //   setInterimUSerId(userId)
    // //   setInterimUSerName(userName)

    // //  dispatch(deselectProject())
    // //  dispatch(setKpList([]))//3
    //   const user = users.find((u)=>{return u.userId === userId})
    //   dispatch(setCurrentProjectManager(user))
    // }
    if (userId && userId !== "" && userId !== currentProjectManager?.userId) {
      // dispatch(fetchProjectByUserId(userId)).then(()=>{

      const user = fullusers.find(u => {
        return u.userId === userId;
      });

      dispatch(setCurrentProjectManager(user));
      // dispatch(deselectProject())
      // })
    }
  }, [userId]);

  useEffect(() => {
    if (currentProjectManager)
      dispatch(fetchProjectByUserId(currentProjectManager?.userId)).then(() => {
        if (currentProject) {
          dispatch(deselectProject());
          dispatch(setKpList([]));
          dispatch(deselectKp());
          dispatch(remindsFetched([]));
        }
      });
  }, [currentProjectManager]);
  // }, []);

  // useEffect(() => {

  //   if(interimUserId){
  //     dispatch(fetchProjectByUserId(interimUserId)).then(()=>{
  //       setValues({...initialValues,  userName: interimUserName, userId: interimUserId})
  //     })
  //   }
  // }, [interimUserId]);

  useEffect(() => {
    // if(userName === ""){
    //   // dispatch(fetchProjects())
    //   setFieldValue("userId", currentUser?.userId)
    //   // setInterimUSerName()
    // }
  }, [userName]);

  return (
    <Select
      label={t("Manager")}
      name="userName"
      labelwidth="12em"
      width="100%"
      options={users}
      multi={true}
      {...props}
    />
  );
};

ProjectAddForm.propTypes = {updates: PropTypes.bool, onMakeEffect: PropTypes.func, setIsEdited: PropTypes.func};

export default ProjectAddForm;
