import React, {useState} from "react";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

import ServicesType from "./components/ServicesType";
import StoregeServForm from "./components/StoregeServForm";
import TransactionScheme from "./components/TransactionScheme";

const LogModSettingsStorageServices = () => {
  return (
    <div className="w100 fc nowrap mt" style={{height: "80vh", width: "80vw"}}>
      <div className=" w50 fcCols h100 " style={{paddingRight: "1em"}}>
        <StoregeServForm />
        <ServicesType />
      </div>
      <div className="vertical-line " />
      <div className="fcCols h100 w50" style={{paddingLeft: "1em"}}>
        <TransactionScheme />
        <div style={{height: "13.6em", margin: "1em 0"}}></div>
        <FunctionButton name={"Відкрити рахунок"} disabled={true} className="right_fixed_btn" />
      </div>
    </div>
  );
};

export default LogModSettingsStorageServices;
