import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";

import useStockPoolInfoService from "./useStockPoolInfoService";

export const TRPOOL_CHECKLIST_MODAL_TYPES = Object.freeze({sp: "SP", order: "ORDER"});

const StockPoolInfo = ({headerNumber = 1, chosenCargo, setTabComponent, onItemOpen}) => {
  const {t} = useTranslation();

  const {
    spTableColumns,
    spTableData,
    orderTableColumns,
    orderTableData,
    onOpenInTabBtnClick,
    onSpTableRowSelect,
    onOrderTableRowSelect,
    isOpenInTabBtnDisabled,
    selectedInModalSp,
    selectedOrderId,
  } = useStockPoolInfoService({chosenCargo, setTabComponent, onItemOpen});

  return (
    <div>
      <div className="fc jcsb nowrap gap aibl w100">
        <div className="header-block">{`${headerNumber}. ${t("Stock Pool")}:`}</div>
        <FunctionButton name="Open in a tab" onClick={onOpenInTabBtnClick} disabled={isOpenInTabBtnDisabled} />
      </div>
      <div className="fc gap5 nowrap">
        <ReactTable
          columns={spTableColumns}
          defaultData={spTableData}
          style={{height: "180px", flexBasis: "50%"}}
          onSelect={onSpTableRowSelect}
          current={selectedInModalSp?.stockPoolId ?? ""}
        />
        <ReactTable
          columns={orderTableColumns}
          defaultData={orderTableData}
          style={{height: "180px"}}
          onSelect={onOrderTableRowSelect}
          current={selectedOrderId}
        />
      </div>
    </div>
  );
};

StockPoolInfo.propTypes = {headerNumber: PropTypes.string};
export default StockPoolInfo;
