import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DateInput, TextAreaInput, TextInput} from "../../../Inputs";
import {selectFetchedStockPool} from "../../redux/selectors";

const CurentSPInfo = ({onFinelDestinationCheck, isFinalDestination}) => {
  const {t} = useTranslation();
  const currentSP = useSelector(selectFetchedStockPool);

  return (
    <div className="fdc gap5">
      <div className="fc nowrap autolabel gap mb">
        <TextInput readOnly={true} label={t("SP №")} name={"stockPoolNumber"} />
        <DateInput label={t("Date of shipment")} name={"shipmentDate"} />
      </div>
      <div className="fc nowrap w100 jcfe mt ">
        <span style={{marginRight: "1em"}}>{t("mark as Final Destination")}</span>
        <input
          type="checkbox"
          checked={isFinalDestination}
          // checked={currentSkuRequest?.stockPool?.cargo?.isFinal || false}
          onClick={e => onFinelDestinationCheck(e.target.checked)}
          disabled={!currentSP || currentSP?.cargoId}
        />
      </div>
      <TextAreaInput label={`1. ${t("Factory/Postman Address")}`} name="spAddress" areaHeight={"10em"} />
      <TextAreaInput label={`2. ${t("Next Delivery address")}`} name="nextDeliveryAddress" areaHeight={"10em"} />
    </div>
  );
};

export default CurentSPInfo;
