import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";
import moment from "moment";

import {BUTTON_COLORS} from "../../../utilities/Buttons/constants";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../Inputs";
import ModalForm from "../../Modal/FormModal";
// <<<<<<< bugfix/logist
import ModCheckList from "../CheckLists/ModCheckList";
// =======
// import ModalForm from "../../Modal/FormModal";
// >>>>>>> develop
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import OrderFullSection from "../common/OrderFullSection/OrderFullSection";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";
import WarehousesForm from "../common/Warehouses/WarehousesForm";
import LogModSettingsTransporter from "../LogSettings/LogModSettingsTransporter/LogModSettingsTransporter";
import {
  fetchAllCarrierDocTypes,
  fetchAllCarriers,
  fetchAllCarrierTypes,
  fetchAllStockPoolsNew,
} from "../redux/operations";

import DocsForTransporter from "./TransportationTpParts/DocsForTransporter";
import LoadingPlace from "./TransportationTpParts/LoadingPlace";
import TransportationTPFilters from "./TransportationTpParts/TransportationTPFilters";
import TransporterContacts from "./TransportationTpParts/TransporterContacts";
import TransporterPayment from "./TransportationTpParts/TransporterPayment/TransporterPayment";
import useTransportationTPService from "./useTransportationTPService";

const TransportationTP = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllStockPoolsNew({window: "Transporter"}));
    dispatch(fetchAllCarrierTypes());
    dispatch(fetchAllCarriers());
    dispatch(fetchAllCarrierDocTypes());
  }, []);

  const [addCarrierModalActive, setAddCarrierModalActive] = useState(false);
  const [pdfFileArray, setPdfFileArray] = useState([]);
  const formikRef = useRef();

  const {
    formInitialValues,
    mainTransportationTableColumns,
    mainTransportationTableData,
    onTPTableSelect,
    onTakeToWorkClick,
    currentSkuRequest,
    onStartTransportationClick,
    currentCarrier,
    isTakenToWork,
    // onFinelDestinationCheck,
    isFinalDestination,
    onTPTableDoubleClick,
    isModalOpen,
    setModalOpen,
  } = useTransportationTPService(formikRef);

  const transportButtonDisable =
    !currentSkuRequest || currentSkuRequest?.stockPool?.cargo?.statusId !== 2 || !currentCarrier;

  return (
    <PageContainer title={t("Transportation TP")}>
      <TransportationTPFilters window={LOGIST_WINDOW_TYPES.TRANSPORTER} />
      <Divider />
      <Formik initialValues={formInitialValues} enableReinitialize={true} innerRef={formikRef}>
        <Form className="w100 fcCol mb">
          <OrderFullSection
            maxHeight={"32em"}
            fileArray={pdfFileArray}
            firstColumn={
              <div style={{borderRight: "1px solid #A3B9D9", paddingRight: "1em"}}>
                <div className="fdr jcsb mb0_5">
                  <TextInput label={t("TP №") + ":"} name="tpNumber" autolabel disabled={!currentSkuRequest} />
                  <FunctionButton
                    name="Accept to work"
                    autoWidth
                    disabled={
                      !currentSkuRequest || currentSkuRequest?.stockPool?.cargo?.status?.statusId !== 1 || isTakenToWork
                    }
                    onClick={onTakeToWorkClick}
                  />
                </div>

                <TransporterContacts />
                <DocsForTransporter element={currentSkuRequest} setPdfFileArray={setPdfFileArray} />

                <TransporterPayment currentSkuRequest={currentSkuRequest} setPdfFileArray={setPdfFileArray} />

                <div className="fdr jcsb" style={{marginLeft: "-0.5em"}}>
                  <FunctionButton name="Create debt" color={BUTTON_COLORS.RED} autoWidth disabled />
                  <FunctionButton name="Create payment" color={BUTTON_COLORS.RED} autoWidth disabled />
                </div>
              </div>
            }
            secondColumn={
              <div>
                <div className="fdr gap2x mb">
                  <DateInput label={t("Date: Shipment")} name="shipmentDate" readOnly />
                  <DateInput
                    label={t("Date: Delivery (p)")}
                    name="deliveryDate"
                    flatpikrConfig={{
                      minDate: moment().add(1, "days").format("DD-MM-YYYY"),
                    }}
                    disabled={!currentSkuRequest}
                  />
                </div>
                <div className="fdr jcc mb">
                  <FunctionButton name="Transporter tracking" color={BUTTON_COLORS.RED} autoWidth disabled />
                </div>

                <LoadingPlace />
                <div className="mb">
                  <WarehousesForm>
                    <div className="fc nowrap w100 jcfe mt ">
                      <span style={{marginRight: "1em"}}>{"Final Destination"}</span>
                      <input type="checkbox" checked={isFinalDestination} onChange={() => {}} />
                    </div>
                  </WarehousesForm>
                </div>
                <CheckListModalsTodo path={"stockPool"} entityId={currentSkuRequest?.stockPoolId || null} />
              </div>
            }
          />
        </Form>
      </Formik>

      <ReactTable
        columns={mainTransportationTableColumns}
        defaultData={mainTransportationTableData}
        onSelect={onTPTableSelect}
        current={currentSkuRequest?.skuRequestId}
        onRowDoubleClick={onTPTableDoubleClick}
      />

      <div className="fdr mt mb w100 jcsb">
        <FunctionButton name="Add trasporter" autoWidth onClick={() => setAddCarrierModalActive(true)} />
        <FunctionButton
          name="Start transportation"
          autoWidth
          disabled={transportButtonDisable}
          onClick={() =>
            onStartTransportationClick(
              formikRef?.current?.values,
              formikRef?.current?.resetForm,
              formikRef?.current?.setValues,
            )
          }
        />
        <FunctionButton name="Reform TP" color={BUTTON_COLORS.RED} autoWidth disabled />
      </div>

      <ModCheckList modalShow={isModalOpen} setModalShow={setModalOpen} />

      <ModalForm
        closable={true}
        Component={LogModSettingsTransporter}
        label={t("Settings Transporter")}
        active={addCarrierModalActive}
        setModalActive={setAddCarrierModalActive}
        className="largeModal"
      />
    </PageContainer>
  );
};

export default TransportationTP;
