import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqualWith} from "lodash";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {deleteSku, getCurrentSku, updateSku} from "../../../SkuSlice";
import {withSkuForm} from "../skuVedFormHOC";

const SkuDuplicateForm = ({setModalActive, typeForm}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentSku = useSelector(getCurrentSku);

  const onDublicateSubmit = values => {
    const {skuId, volumeWeight, ...restSk} = currentSku;
    const {skuId: i, ...restV} = values;
    if (isEqualWith(restSk, restV)) {

      Swal.fire({
        title: t("Nothing has changed"),
        text: t("Cancel duplicate SKU"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Yes"),
        cancelButtonText: t("Return and make changes"),
      }).then(resp => {
        if (resp.isConfirmed) {
          dispatch(deleteSku(skuId)).then(() => {
            setModalActive(false);
          });
        }
      });
    } else {
      dispatch(
        updateSku({
          sku: restV,
          skuId: skuId,
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setModalActive(false);
        }
      });
    }
  };
  const onCancel = () => {
    Swal.fire({
      title: t("Cancel warning"),
      text: "Скасувати дублювання?",
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("Cancel"),
    }).then(res => {
      if (res.isConfirmed) {
        dispatch(deleteSku(currentSku.skuId)).then(() => {
          setModalActive(false);
        });
      }
    });
  };

  const onReload = useCallback(
    values => {
      const {skuId, volumeWeight, ...restSk} = currentSku;
      const {volumeWeight: d, skuId: i, ...restV} = values;

      if (isEqualWith(restSk, restV)) {
        dispatch(deleteSku(skuId));
      } else {
        dispatch(updateSku({sku: restV, skuId: skuId}));
      }
    },
    [currentSku],
  );

  const SkuForm = withSkuForm(t("Save sku"), onDublicateSubmit, onCancel, OnReload);

  return <SkuForm />;
};

const OnReload = () => {
  const {values} = useFormikContext();
  const [changed, setChanged] = useState(false);
  const dispatch = useDispatch();
  const currentSku = useSelector(getCurrentSku);

  useEffect(() => {
    if (!changed) {
      try {
        const {skuId, volumeWeight, areaUnitId, volumeUnitId, weightUnitId, overallSizeUnitId, ...restSk} = currentSku;
        const {
          volumeWeight: e,
          skuId: i,
          areaUnitId: a,
          volumeUnitId: b,
          weightUnitId: c,
          overallSizeUnitId: d,
          ...restV
        } = values;

        console.log(isEqualWith(restSk, restV));
        if (isEqualWith(restSk, restV) === false) {
          setChanged(true);
        }
      } catch {
        console.log("");
      }
    }
  }, [values]);

  const onUnload = useCallback(() => {
    if (!changed) {
      console.log(currentSku.skuId);
      try {
        dispatch(deleteSku(currentSku.skuId));
      } catch (e) {
        console.log(e);
      }


    } else {
      // dispatch(updateSku({sku:restV,  skuId: skuId}))
      // console.log("upd");
    }
  }, [changed]);


  useEffect(() => {
    // Добавляем обработчик события beforeunload при монтировании компонента
    window.addEventListener("beforeunload", onUnload);

    // Возвращаем функцию очистки, чтобы удалить обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  return <></>;
};

SkuDuplicateForm.propTypes = {setModalActive: PropTypes.func, typeForm: PropTypes.string};

export default SkuDuplicateForm;
