import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchProformById} from "../../../../Requests/Proform/redux/operations/ProformOperations";
import {deselectCurrentPf} from "../../../../Requests/Proform/redux/slices/ProformSlice";
import {STATUS_CONTROL_TABLE_COLUMNS} from "../../../../Requests/RequestsInProgress/modalsInProgress/PfOperationsModal/utils/constants";
import getStatusControlTableData from "../../../../Requests/RequestsInProgress/modalsInProgress/PfOperationsModal/utils/hooks/getStatusControlTableData";
import {deselectCurrentRequest, getAllStatusRequest, getRequest} from "../../../../Requests/RequestsSlice";
import {REQUEST_RAndV_COLUMNS} from "../constants";
import getRequestTableData from "../utils/getRequestTableData";

const RAndVTables = ({currentPf, currReq}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const requests = useSelector(getAllStatusRequest);

  const [currReqRow, setCurrReqRow] = useState(currReq);

  const requestTableColoumns = useColumns(REQUEST_RAndV_COLUMNS);
  const profTableColoumns = useColumns(STATUS_CONTROL_TABLE_COLUMNS);

  const requestTableData = getRequestTableData(requests);
  const statusProfTableData = getStatusControlTableData(currReqRow?.proforms);

  const translatedStatusProfTableData = statusProfTableData.map(item => {
    return {...item, statControl: t(item?.statControl)};
  });

  const onRequestSelect = id => {
    if (!id || (currReqRow && id === currReqRow?.requestId)) {
      dispatch(deselectCurrentRequest());
      return;
    }
    dispatch(getRequest(id));
  };

  useEffect(() => {
    dispatch(deselectCurrentPf());
    setCurrReqRow(currReq || null);
  }, [currReq]);

  const onPfSelect = id => {
    if (!id || (currentPf && currentPf.proformaId === id)) {
      dispatch(deselectCurrentPf());
      return;
    }
    dispatch(fetchProformById(id));
  };

  useEffect(() => {
    return () => {
      dispatch(deselectCurrentPf());
    };
  }, []);

  return (
    <div className="rv_block">
      <ReactTable
        defaultData={requestTableData}
        columns={requestTableColoumns}
        onSelect={onRequestSelect}
        current={currReqRow?.requestId || null}
        className="rv_left"
        style={{height: "8em"}}
      />

      <ReactTable
        style={{height: "8em"}}
        columns={profTableColoumns}
        defaultData={translatedStatusProfTableData}
        className="rv_right"
        onSelect={onPfSelect}
        current={currentPf?.proformaId || null}
      />
    </div>
  );
};

export default RAndVTables;
