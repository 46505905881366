export function checkEmptyFilterFields(filters) {
  if (!filters || Object.keys(filters).length === 0) return {};

  return Object.entries(filters).reduce((obj, [key, value]) => {
    if ((value || value === false) && value !== "") {
      obj[key] = value;
    }
    return obj;
  }, {});
}
