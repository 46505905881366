import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import SaveIcon from "../../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import LeftRightBtns from "../../../../utilities/Buttons/LeftRightBtns";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../Inputs";
import ImgFilePreview from "../../../Inputs/fileSelect/preview/ImgFilePreview";
import {Select} from "../../../Inputs/SelectField";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";

import useOrderAcceptanceNo from "./useOrderAcceptanceNo";

const OrderAcceptanceNo = ({notAcceptedSku}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {
    commissionOptions,
    skuTableColumns,
    skuTableData,
    onAddCommissionBtnClick,
    onRemoveCommissionBtnClick,
    fileArray,
    onDefectPhotoAdd,
    // onDelBtnClick,
    onSaveBtnClick,
    onMarkedSkusIssuedBtnClick,
    isMarkedSkusIssuedBtnDisabled,
    isAddCommissionBtnDisabled,
    isRemoveCommissionBtnDisabled,
    onSkuTableSelect,
    currentSkuId,
    onDefectPhotoDelete,
    isSaveDefectImgBtnDisabled,
    exportToPdf,
  } = useOrderAcceptanceNo({notAcceptedSku});

  return (
    <div className="fGrow column aistr jcfs">
      <div className="row">
        <TextInput label={t("FDP")} name="fdp" width="12em" autolabel readOnly />
        <TextInput label={t("Request №")} name="requestNumber" width="12em" autolabel readOnly />
        <TextInput label={t("Order number")} name="orderNumber" width="12em" autolabel readOnly />
        <TextInput label={t("Manager CO")} name="managerCo" width="12em" autolabel readOnly />
        <TextInput label={t("Logist")} name="logist" width="12em" autolabel readOnly />
      </div>
      <Divider />
      <div className="row mb0_5">
        <TextInput label={t("city")} name="city" width="25em" autolabel readOnly />
        <div className="column jcc">
          <span className="uppercase">{t("The act of")}</span>
          <span>{t("defrauding material values")}</span>
        </div>
        <TextInput label={t("Act Date") + ":"} name="actDate" width="100%" autolabel />
      </div>
      <div className="row gap2x jcsb">
        <div className="column">
          <Select
            label={t("Commission Composition")}
            name="commissionSelect"
            options={commissionOptions}
            width="25em"
          />
          <TextInput label={t("Commission Composition")} name="commissionInput" width="25em" autolabel />
        </div>
        <LeftRightBtns
          onRightClick={onAddCommissionBtnClick}
          onLeftClick={onRemoveCommissionBtnClick}
          isLeftBtnDisabled={isRemoveCommissionBtnDisabled}
          isRightBtnDisabled={isAddCommissionBtnDisabled}
        />
        <TextAreaInput name="commissionTextArea" height="4.5em" placeholder={t("Commission Composition")} readOnly />
      </div>
      <TextAreaInput name="commissionNote" height="4.5em" className="mb" />
      <ReactTable
        className="tableTac overflowX fGrow"
        columns={skuTableColumns}
        defaultData={skuTableData}
        onSelect={onSkuTableSelect}
        current={currentSkuId}
      />
      <div className="row aife gap2x mb0_5">
        <TextAreaInput
          name="defectDescr"
          className="asstr"
          height="unset"
          placeholder={t("Defect description")}
          disabled={!currentSkuId}
        />
        <div className="row">
          <ImageButton src={SaveIcon} onClick={onSaveBtnClick} disabled={!currentSkuId || !values.defectDescr} />
          <ToolTip title={"Upload defect img (Save defect description first)"} bottom>
            <ImgFilePreview
              accept={"image/*"}
              imgs={fileArray}
              delFunc={onDefectPhotoDelete}
              addFunc={onDefectPhotoAdd}
              disable={!currentSkuId || isSaveDefectImgBtnDisabled}
            />
          </ToolTip>

          {/* <ImageButton src={DeleteIcon} onClick={onDelBtnClick} disabled={!currentSkuId} tooltipMessage="Delete doc" /> */}
        </div>
        <div className="kpPreview__box mb0" style={{height: "140px", padding: "2px", maxWidth: "250px"}}>
          <NewPdfViewer fileArray={fileArray} hiding={false} />
        </div>
      </div>

      <FunctionButton
        name="Marked SKUs are issued"
        autoWidth
        onClick={exportToPdf}
        style={{alignSelf: "flex-end"}}
        disabled={isMarkedSkusIssuedBtnDisabled}
      />
    </div>
  );
};

OrderAcceptanceNo.propTypes = {notAcceptedSku: PropTypes.array.isRequired};

export default OrderAcceptanceNo;
