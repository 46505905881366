import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {SHIPMENT_TABLE_COLUMNS} from "./constants";

const useShipmentInfo = ({packingSpaces}) => {
  const shipmentTableColumns = useColumns(SHIPMENT_TABLE_COLUMNS);
  // const curentRequest = useSelector(getRequestInfo);
  // const rowData = curentRequest?.packingSpaces ?? packingSpaces;

  const shipmentTableData =
    packingSpaces?.map((sp, i) => ({
      number: i + 1,
      id: sp.spaceId,
      note: sp?.note || " - ",
      placeNumber: sp?.spaceNumber || " - ",
      lwh: sp?.length + "/" + sp?.width + "/" + sp?.height || " - ",
      volume: sp?.volume || " - ",
      weight: sp?.weight || " - ",
    })) || [];
  // },[curentRequest]);

  return {shipmentTableColumns, shipmentTableData};
};

export default useShipmentInfo;
