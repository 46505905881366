import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import * as Yup from "yup";

import {getCurrentKp} from "../../../../selectors/generalSelectors";
import {DateInput, MoneyInput, TextInput} from "../../../Inputs";

import KpList from "./KpList";

const KpInfoForm = ({forcedUpdate}) => {
  const {t, i18n} = useTranslation();

  const currentKp = useSelector(getCurrentKp);

  const initialValues = {
    projectNumber: "",
    coNumber: "",
    projectManagerName: "",
    projectManagerId: "",
    note: "",
    status: "",
    finalDate: "",
    startDate: "",
    totalKpSum: "",
    projectSum: "",
    datePayment: "",
    managerKpName: "",
    managerKpId: "",
    orgName: "",
    advancePayment: "",
    advancePaymentDeadline: "",
  };

  const currentFormState = useMemo(() => {
    if (!currentKp) return initialValues;
    return {
      ...currentKp,
      projectManagerName: currentKp.project.projectManager.name,
      orgName: currentKp.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
    };
  }, [currentKp]);

  return (
    <div
      style={{
        height: "40%",
        width: "100%",
        paddingBottom: "1.5em",
        borderBottom: "1px solid #426ca65e",
      }}
    >
      <Formik
        initialValues={currentFormState}
        enableReinitialize
        validationSchema={Yup.object({})}
        onSubmit={values => console.log(JSON.stringify(values, null, 2))}
      >
        <Form style={{height: "100%"}}>
          <div className="form_wrapper">
            <div className="project_list" style={{height: "100%"}}>
              <KpList forcedUpdate={forcedUpdate} />
            </div>
            <div className="kpInfoForm">
              <div style={{display: "flex", flexDirection: "column", gap: "3.8px"}}>
                <TextInput label={t("Project #")} name="project.projectNumber" readOnly={true} />
                <TextInput label={t("Manager")} name="projectManagerName" readOnly={true} />

                <TextInput label={t("CO №")} name="coNumber" readOnly={true} />
              </div>
              <div style={{width: "50%", marginLeft: "2em", marginTop: "2em"}} className="closeInput">
                <TextInput label={t("Offic")} name="orgName" width="8em" readOnly={true} autolabel />

                <div className="flex_container nowrap autolabel" style={{gap: "2.5em"}}>
                  <DateInput label={t("From")} name="startDate" width="85" readOnly={true} />

                  <DateInput label={t("To")} name="finalDate" width="85" readOnly={true} />
                </div>

                <div className="flex_container nowrap " style={{gap: "2.5em", alignItems: "end"}}>
                  <MoneyInput label={t("Advance")} name="advancePayment" width="7.5em" autolabel />
                  <DateInput
                    label={t("To")}
                    id="prepaymentDate"
                    name="advancePaymentDeadline"
                    type="date"
                    flatpikrConfig={{
                      minDate: moment().format("DD-MM-YYYY"),
                    }}
                    width="85"
                  />
                </div>
              </div>
            </div>
            <div className=" autolabel flex_container nowrap w">
              <div className=" autolabel flex_container  nowrap " style={{gap: "1em"}}>
                <MoneyInput label={t("Total CO sum")} name="totalKpSum" width="7em" readOnly={true} />
                <MoneyInput label={t("From sum")} name="projectSum" width="7em" readOnly={true} />
              </div>
              <DateInput label={t("From")} name="datePayment" width="85" readOnly={true} />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

KpInfoForm.propTypes = {forcedUpdate: PropTypes.bool};

export default KpInfoForm;
