import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {setSelectedInModalStockPool} from "../redux/LogistSlice";
import {fetchCargo} from "../redux/operations";
import {selectChosenCargo, selectFetchedStockPool} from "../redux/selectors";

import {TP_POOL_TABLE_COLUMNS} from "./constants";

const useLogModTpPoolCheckList = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  // const currentSP = useSelector(getStockPoolCheckLIst);
  const currentSP = useSelector(selectFetchedStockPool);
  const cargoId = currentSP?.cargoId;
  const chosenCargo = useSelector(selectChosenCargo);

  useEffect(() => {
    if (cargoId) dispatch(fetchCargo(cargoId));
  }, [cargoId]);

  useEffect(() => {
    dispatch(setSelectedInModalStockPool(null));
  }, []);

  const factoryContacts = chosenCargo?.factory?.factoryContacts[0];

  const initialValues = {
    //HeaderInputRow
    status: " - ",
    startPointPlace: chosenCargo?.startPoint?.name ?? " - ",
    startPointDate: dateFormat(chosenCargo?.startPoint?.createdAt) ?? " - ",
    endPointPlace: chosenCargo?.endPoint?.name ?? " - ",
    endPointDate: dateFormat(chosenCargo?.endPoint?.createdAt) ?? " - ",
    //DeliveryInfo
    shippingInfoArea: factoryContacts
      ? `${t("Contacts:")} ${factoryContacts?.name ?? t("no name")}, ${factoryContacts?.email ?? t("no email")}, ${
          factoryContacts?.phone ?? t("no phone")
        }`
      : " - ",
  };
  const headerInputNames = [
    {name: "status", label: "Status", autolabel: true},
    {name: "startPointPlace", label: "Start point  place"},
    {name: "startPointDate", label: "date", autolabel: true},
    {name: "endPointPlace", label: "Arrival  place", autolabel: true},
    {name: "endPointDate", label: "date"},
  ];
  const orderTableColumns = useColumns(TP_POOL_TABLE_COLUMNS);
  const orderTableData = [];
  const pdfFileArray = [];

  const documentsTableData = useMemo(() => {
    const docs = [];
    let counter = 1;

    chosenCargo?.docs?.map(tdoc => {
      docs.push({
        id: counter - 1,
        number: counter,
        docDate: dateFormat(tdoc?.dateFrom) || "",
        docType: tdoc?.docType.nameUkr || "",
        docNumber: tdoc?.docNumber || "",
        createdBy: "",
        url: tdoc?.fileUrl || "",
      });
      counter++;
    });
    return docs;
  }, [chosenCargo]);

  return {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    pdfFileArray,
    currentSP,
    documentsTableData,
    chosenCargo,
  };
};

export default useLogModTpPoolCheckList;
