import PropTypes from "prop-types";

import ReactTable from "../../../../../conteiners/ReactTable";

import useDistributeStockPoolTable from "./useDistributeStockPoolTable";

const DistributeStockPoolTable = ({selectedForCancel, setSelectedForCancel}) => {
  const {columns, defaultData, onAppointedStockPoolSelect} = useDistributeStockPoolTable({
    setSelectedForCancel,
  });

  return (
    <div className={" table_xscroll__container "} style={{height: "17em", width: "15em"}}>
      <ReactTable
        defaultData={defaultData ?? []}
        columns={columns}
        onSelect={onAppointedStockPoolSelect}
        current={selectedForCancel ?? ""}
        selectable
      />
    </div>
  );
};

DistributeStockPoolTable.propTypes = {
  selectedForCancel: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  setSelectedForCancel: PropTypes.func.isRequired,
};

export default DistributeStockPoolTable;
