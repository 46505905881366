import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {withFormik} from "formik";

import {Select} from "../../../Inputs/SelectField";
import useLogistFilters from "../../common/LogistFilters/useLogistFilters";
import withLogistFilters from "../../common/LogistFilters/withLogistFilters";
import {fetchStockPoolFilters} from "../../redux/operations";

const BaseTransportationTPFilters = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStockPoolFilters({window: "Transporter"}));
  }, []);

  const {coManagersOptions, invoiceNumberOptions, cargoNumberOptions, locationsOptions, carriersOptions} =
    useLogistFilters();

  return (
    <>
      <div className="f_block w100" style={{paddingTop: "0.7em", height: "4%"}}>
        <Select label={t("TP №") + ":"} name="cargoNumber" options={cargoNumberOptions} width="100%" />
        <Select label={t("location tp") + ":"} name="locationTPId" options={[]} width="100%" />
        <Select label={t("CO Manager") + ":"} name="managerCoId" options={coManagersOptions} width="100%" />
        <Select label={t("Transporter") + ":"} name="carrierId" options={carriersOptions} width="100%" />
        <Select label={t("Invoice №") + ":"} name="invoiceNumber" options={invoiceNumberOptions} width="100%" />
      </div>
    </>
  );
};

const LogistFilters = withLogistFilters(BaseTransportationTPFilters);

const TransportationTPFilters = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(LogistFilters);

export default TransportationTPFilters;
