import React from "react";
import {useTranslation} from "react-i18next";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  TP_REQUEST_TABLE_FACTORY_CREATING_MODAL,
  TP_TABLE_FACTORY_CREATING_MODAL,
} from "../../FactoryBlock/FactoryCreatingTP/constants";
import {makeRegisterRqstsTableData, makeRegisterSPTableData} from "../functions";
import useStockPoolTables from "../useStockPoolTables";

const SPwithRequestTables = ({
  onSPSelect = () => {},
  setCheckedCargo = () => {},
  onRequestSelect = () => {},
  currentRequest = null,
  spTableColumnes,
  ifCheck = false,
  cargoSelectable = true,
  requestSelectable = false,
  filterProps,
  window,
}) => {
  const {i18n} = useTranslation();
  const {allStockPools, spRequests, onStockPoolSelect, currentSPId, onStockPoolCheck, setCurrentSpId} =
    useStockPoolTables({
      filterProps,
      window,
    });

  const spcolumns = useColumns(TP_TABLE_FACTORY_CREATING_MODAL);
  const spdata = makeRegisterSPTableData(allStockPools.stockPools, i18n.language);

  const reqcolumns = useColumns(TP_REQUEST_TABLE_FACTORY_CREATING_MODAL);
  const reqdata = makeRegisterRqstsTableData(spRequests);

  return (
    <div className="fdr gap2x mb">
      {ifCheck ? (
        <ReactTable
          className="tableTac"
          defaultData={spdata}
          columns={spTableColumnes || spcolumns}
          onSelect={id => {
            onSPSelect(id, allStockPools);
            setCurrentSpId(id);
          }}
          selectable={cargoSelectable}
          current={currentSPId}
          setMultiSelect={checked => {
            setCheckedCargo(checked);
            onStockPoolCheck(checked);
          }}
          enableMultiSelection={true}
          defaultMultiSelection={currentSPId}
          style={{width: "25%", height: "15em"}}
        />
      ) : (
        <ReactTable
          className="tableTac"
          defaultData={spdata}
          columns={spTableColumnes || spcolumns}
          onSelect={id => {
            onSPSelect(id, allStockPools);
            onStockPoolSelect(id);
          }}
          selectable={true}
          current={currentSPId}
          style={{width: "35%", height: "15em"}}
        />
      )}

      <ReactTable
        className="tableTac"
        defaultData={reqdata}
        columns={reqcolumns}
        selectable={requestSelectable}
        current={currentRequest}
        onSelect={onRequestSelect}
        style={{width: "100%", height: "15em", overflow: "auto"}}
      />
    </div>
  );
};

export default SPwithRequestTables;
