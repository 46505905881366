import {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

const SkuVwCharCheckboxe = ({name, children, numberOfChecked, setNumberOfChecked}) => {
  const {values, setFieldValue, errors} = useFormikContext();
  const [rawValue, setRawValue] = useState(false);

  const onCH = value => {
    if (!value) {
      setNumberOfChecked(i => (i = i - 1));
      setFieldValue(name, value);
      return;
    }
    if (value && numberOfChecked < 2) {
      setNumberOfChecked(i => (i = i + 1));

      setFieldValue(name, value);
    }
  };

  useEffect(() => {
    setRawValue(values[name]);
  }, [values[name]]);

  return (
    <div className="fieldWithSample" style={{alignItems: "baseline", position: "relative"}}>
      <input
        type="checkbox"
        checked={rawValue}
        onChange={e => {
          onCH(e.target.checked);
        }}
      />
      {errors[name] ? <div className="error">{errors[name]}</div> : null}
      <div className={values[name] ? "" : "fieldWithSample__disabled"} style={{width: "100%"}}>
        {children}
      </div>
    </div>
  );
};

SkuVwCharCheckboxe.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  numberOfChecked: PropTypes.number,
  setNumberOfChecked: PropTypes.func,
};

export default SkuVwCharCheckboxe;
