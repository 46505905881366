import {apiUrl} from "../../../../../api/baseURL";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import cancel from "../cancel.png";

const MainRow = ({setFieldValue, values, data, chengeable, ...props}) => {
  return (
    <tr {...props}>
      {Object.entries(data).map(([key, value], index) => {
        if (key.startsWith("image") && value !== "") {
          return (
            <td key={index} style={{border: "0.02px solid rgba(88, 89, 93, 0.2941176471)"}}>
              <div style={{display: "flex", justifyContent: "center", position: "relative"}}>
                <img src={apiUrl + "/" + value} alt={`Image ${index}`} style={{width: "100px", height: "100px"}} />
                {chengeable && (
                  <ImageButton
                    src={cancel}
                    alt="cancel"
                    width={1}
                    height={1}
                    onClick={() => {
                      setFieldValue(
                        "addedImages",
                        values.addedImages.filter(item => item !== value),
                      );
                    }}
                    style={{
                      position: "absolute",
                      border: "0",
                      background: "transparent",
                      cursor: "pointer",
                      right: "3px",
                    }}
                  />
                )}
              </div>
            </td>
          );
        } else {
          return (
            <td
              key={index}
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                border: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
              }}
            >
              {value}
            </td>
          );
        }
      })}
    </tr>
  );
};

export default MainRow;
