import {useTranslation} from "react-i18next";

import crossIcon from "../../../../images/icons/delete.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import SingleFilePicker from "../../../Inputs/SingleFilePicker";
import useDocsForTransporterService from "../utils/useDocsForTransporterService";

const DocsForTransporter = ({element, setPdfFileArray}) => {
  const {t} = useTranslation();

  const {
    docsForTransporterTableColumns,
    cargosDocTypesOptions,
    onDocSelect,
    currentDoc,
    onDocDelete,
    cargoDocsLoading,
    onDocAdding,
    docsForTransporterTableData,
    values,
  } = useDocsForTransporterService(element, setPdfFileArray);

  const {onDocDate, docNumber, docType} = values;

  return (
    <div className="mb0_5">
      <div className="fdr aibl jcsb">
        <div className="fdr">
          <div>2.</div>
          <div className="label underline">{t("Documents fot Transporter")}</div>
        </div>
        <Select
          label={t("Document type")}
          name="docType"
          options={cargosDocTypesOptions}
          autolabel
          disabled={!element}
        />
      </div>

      <div className="fdr jcsb">
        <DateInput label={t("Document, Date")} name="onDocDate" autolabel disabled={!element} />
        <TextInput name="docNumber" label="№" question="d numb" autolabel disabled={!element} />
        <div className="fdr">
          <SingleFilePicker
            addFunc={onDocAdding}
            accept={".pdf"}
            disabled={!element || currentDoc || onDocDate === "" || docType === "" || docNumber === ""}
          />
          <ImageButton
            src={crossIcon}
            alt="delete"
            className="btn__upload"
            width={1.5}
            height={1.5}
            onClick={() => {
              onDocDelete(currentDoc.docId);
            }}
            disabled={!currentDoc || !element}
          />
        </div>
      </div>
      <ReactTable
        defaultData={docsForTransporterTableData}
        columns={docsForTransporterTableColumns}
        onSelect={onDocSelect}
        current={currentDoc?.docId}
        loading={cargoDocsLoading}
        style={{height: "6em"}}
        className="tableTac overflowX"
      />
    </div>
  );
};

export default DocsForTransporter;
