import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectReferences} from "../../../../redux/selectors/FactorySelectors";

const useContactTypesOptionsService = () => {
  const {i18n} = useTranslation();

  const references = useSelector(selectReferences);

  return (
    references?.contactTypes.map(contactType => ({
      title: i18n === "eng" ? contactType.engName : contactType.ukrName,
      value: contactType.contactTypeId,
    })) ?? []
  );
};

export default useContactTypesOptionsService;
