import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {dateFormat} from "../../../../../utilities/dateFormat";
import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  getExpandedDocs,
  profExpanded,
} from "../../../../Requests/RequestsInProgress/utils/functions/expandDataForTable";
import {selectWarehouseSpRequests, selectWarehouseSpStockPools} from "../../../redux/selectors";

import {LOWER_SP_REQUEST_TABLE_WAREHOUSE_MODAL, SP_REQUEST_TABLE_WAREHOUSE_MODAL} from "./constants";

const SplitCreateLowerTables = ({setSelectedLowerSkuRequests}) => {
  const {i18n} = useTranslation();
  const requests = useSelector(selectWarehouseSpRequests);
  const stockPools = useSelector(selectWarehouseSpStockPools);

  const spColumns = useColumns(LOWER_SP_REQUEST_TABLE_WAREHOUSE_MODAL);
  const spData = useMemo(() => {
    return stockPools
      ? stockPools.map(({stockPoolId, stockPoolNumber}, index) => ({
          id: stockPoolId,
          number: index + 1,
          spNumber: stockPoolNumber,
        }))
      : [];
  }, [stockPools]);

  const spRequestColumns = useColumns(SP_REQUEST_TABLE_WAREHOUSE_MODAL);
  const spRequestData = useMemo(() => {
    return requests
      ? requests.map(({order, request, skuRequestId, stockPool}, index) => {
          const managerCoName =
            i18n.language === "en"
              ? order?.co.managerCo?.persona?.engFirstName + " " + order?.co.managerCo?.persona?.engLastName
              : order?.co.managerCo?.persona?.firstName + " " + order?.co.managerCo?.persona?.lastName;

          const logistName =
            i18n.language === "en"
              ? stockPool?.logist?.persona?.engFirstName + " " + stockPool?.logist?.persona?.engLastName
              : stockPool?.logist?.persona?.firstName + " " + stockPool?.logist?.persona?.lastName;

          const managerFeaName =
            i18n.language === "en"
              ? request?.managerFea?.persona?.engFirstName + " " + request?.managerFea?.persona?.engLastName
              : request?.managerFea?.persona?.firstName + " " + request?.managerFea?.persona?.lastName;

          return {
            id: skuRequestId,
            number: index + 1,
            orderNumber: order.orderNumber,
            requestNumber: request?.requestNumber,
            managerCo: managerCoName,
            managerFea: managerFeaName,
            logist: logistName,
            factory: request?.brand?.name,
            requestSum: request?.requestSum,
            readinessDate: dateFormat(request?.requestDates?.find(item => item.dateTypeId === 8).actualDate),
            bringTo: dateFormat(order?.co?.bringTo),
            country: order?.co?.project?.country?.shortName || "",
            proformaNumber: profExpanded(request?.proforms),
            invoiceNumber: getExpandedDocs(stockPool?.docs, 2) || "-",
            packingListNumber: getExpandedDocs(stockPool?.docs, 1) || "-",
            notes: request?.note,
          };
        })
      : [];
  }, [requests]);

  return (
    <div className="fdr gap mb">
      <ReactTable defaultData={spData} columns={spColumns} selectable={true} style={{width: "18%", height: "15em"}} />
      <ReactTable
        defaultData={spRequestData}
        columns={spRequestColumns}
        selectable={true}
        enableMultiSelection={true}
        defaultMultiSelection={null}
        setMultiSelect={checked => {
          setSelectedLowerSkuRequests(checked);
        }}
        className="w100 pb scrollX"
        style={{width: "100%", height: "15em"}}
      />
    </div>
  );
};

export default SplitCreateLowerTables;
