// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkBox_label {
  font-weight: 400;
  font-style: italic;
  width: 15%;
}

.kpInfoForm {
  display: flex;
  width: 100%;
}

.typeKp {
  opacity: 30%;
}

.w {
  gap: 12em;
}
@media screen and (min-width: 1440px) {
  .w {
    gap: 11.5em;
  }
}
@media screen and (min-width: 1905px) {
  .w {
    gap: 12em;
  }
}

.subKp_disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Components/KP/kp/KpForm.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EAEA,UAAA;AAAF;;AAKA;EACE,aAAA;EAEA,WAAA;AAHF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,SAAA;AAFF;AAGE;EAFF;IAGI,WAAA;EAAF;AACF;AACE;EALF;IAMI,SAAA;EAEF;AACF;;AACA;EACE,2BAAA;EACA,yBAAA;EAIA,iBAAA;EAGA,oBAAA;EAEA,yBAAA;EACA,iBAAA;EACA,oBAAA;EAEA,kBAAA;AAAF","sourcesContent":[".checkBox_label {\n  font-weight: 400;\n  font-style: italic;\n  // font-size:13px;\n  width: 15%;\n}\n.add-kp-form {\n  // width:65%\n}\n.kpInfoForm {\n  display: flex;\n\n  width: 100%;\n}\n.typeKp {\n  opacity: 30%;\n}\n\n.w {\n  gap: 12em;\n  @media screen and (min-width: 1440px) {\n    gap: 11.5em;\n  }\n  @media screen and (min-width: 1905px) {\n    gap: 12em;\n  }\n}\n\n.subKp_disabled {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n\n  -moz-user-select: -moz-none;\n  -o-user-select: none;\n  -khtml-user-select: none;\n  -webkit-user-select: none;\n  user-select: none;\n  pointer-events: none;\n\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
