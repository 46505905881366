import React from "react";
import {useTranslation} from "react-i18next";

import FunctionButton from "../../utilities/Buttons/FunctionButton";
import PageControlContainer from "../conteiners/PageControlContainer";

import AllAccountsForm from "./accountsForm/AllAccountsForm";
import GeneralAccForm from "./accountsForm/GeneralAccForm";
import AccFullList from "./accountsList/AccFullList";
import AccListWithFilter from "./accountsList/AccListWithFilter";

const Accounts = () => {
  const {t} = useTranslation();

  return (
    <PageControlContainer title={[t("Accounts")]} loading={false}>
      <div className="form_wrapper">
        <div className="flex_container" style={{height: "25em", margin: "1em"}}>
          <div
            style={{
              height: "23em",
              width: "50%",
              borderBottom: "1px solid #426ca681",
            }}
          >
            <AccListWithFilter />
          </div>
          <div
            style={{
              height: "23em",
              width: "50%",
              borderBottom: "1px solid #426ca681",
            }}
          >
            <AllAccountsForm />
          </div>
        </div>

        <div className="form_wrapper" style={{margin: "1em", marginTop: "0", paddingBottom: "2em"}}>
          <GeneralAccForm />
          <AccFullList />
        </div>

        <div
          style={{
            position: "absolute",
            gap: "1.5em",
            bottom: "1em",
            left: "1em",
          }}
          className="fc"
        >
          <FunctionButton name={t("Go to Warehouse")} />

          <FunctionButton name={t("Dublicate SKU")} />
        </div>
      </div>
    </PageControlContainer>
  );
};

export default Accounts;
