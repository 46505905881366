// export const reqDates = {
//   creation: {dateId: 1, nameEng: "Creation", nameUkr: "Створення"},
//   distribution: {dateId: 2, nameEng: "Distribution", nameUkr: "Розпреділення"},
//   sendingToFactory: {dateId: 3, nameEng: "Sending to the factory", nameUkr: "Відправка на фабрику"},
//   gettingProf: {dateId: 4, nameEng: "Getting a proforma", nameUkr: "Отримання проформи"},
//   profControl: {dateId: 5, nameEng: "Proforma control", nameUkr: "Контроль проформи"},
//   forPayment: {dateId: 6, nameEng: "For payment", nameUkr: "На оплату"},
//   payment: {dateId: 7, nameEng: "Payment", nameUkr: "Оплата"},
//   readiness: {dateId: 8, nameEng: "Readiness", nameUkr: "Готовність"},
//   readinessControl: {dateId: 9, nameEng: "Readiness control", nameUkr: "Контроль готовності"},
//   transferToLogistics: {dateId: 10, nameEng: "Transfer to logistics", nameUkr: "Передача в логістику"},
// };

export const REQUEST_DATES_IDS = Object.freeze({
  CREATION_ID: 1,
  DISTRIBUTION_ID: 2,
  SENDING_TO_FACTORY_ID: 3,
  GETTING_PROF_ID: 4,
  PROF_CONTROL_ID: 5,
  FOR_PAYMENT_ID: 6,
  PAYMENT_ID: 7,
  READINESS_ID: 8,
  READINESS_CONTROL_ID: 9,
  TRANSFER_TO_LOGISTICS_ID: 10,
});

export const findRequestDateType = (arrayToFind, dateTypeKey) => {
  return arrayToFind?.find(item => item.dateType.dateTypeId === dateTypeKey);
};
