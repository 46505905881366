import moment from "moment";
import PropTypes from "prop-types";

import AvailabilityStatusCIrcule from "./AvailabilityStatusCIrcule";
import {STATUS_COLORS} from "./colorConstants";
import {fullDateFormat} from "./dateFormat";

export function calcStatusColorViaPlannedDate(plannedDate, createdDate, factDate) {
  const startDate = fullDateFormat(createdDate, "YYYY-MM-DDTHH:mm:ss.sssZ");
  const endDate = fullDateFormat(plannedDate, "YYYY-MM-DDTHH:mm:ss.sssZ");

  if (startDate > endDate) return STATUS_COLORS.RED;
  let currentDate = moment();
  // console.log("currentDate", currentDate);

  if (factDate) {
    currentDate = moment(factDate, "YYYY-MM-DDTHH:mm:ss.sssZ");
  }

  const todayRangeDays = endDate - startDate;
  let actualRangeDays = currentDate - startDate;

  const percent = (actualRangeDays / todayRangeDays) * 100;

  // console.log("percent", percent);

  if (percent <= 80) {
    return STATUS_COLORS.GREEN;
  } else if (percent > 80 && percent <= 120) {
    return STATUS_COLORS.YELLOW;
  } else {
    return STATUS_COLORS.RED;
  }
}

calcStatusColorViaPlannedDate("2023-10-26T13:56:26.000Z", "2023-10-23T13:56:26.000Z", "2023-11-07T13:56:26.000Z");

const DateStatusCircle = ({
  plannedDate = "",
  createdAtDate,
  actualDate = "",
  defaultColor = STATUS_COLORS.GREY,
  tooltipMessage = "",
}) => {
  let statusColor = defaultColor;

  if (plannedDate && createdAtDate) {
    statusColor = calcStatusColorViaPlannedDate(plannedDate, createdAtDate, actualDate);
  }
  return <AvailabilityStatusCIrcule color={statusColor} tooltipMessage={tooltipMessage} />;
};

DateStatusCircle.propTypes = {
  plannedDate: PropTypes.string,
  defaultColor: PropTypes.oneOf(Object.values(STATUS_COLORS)),
  tooltipMessage: PropTypes.string,
};

export default DateStatusCircle;
