import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  selectCurrentConditionId,
  selectCurrentFactoryConditions,
  selectCurrentFactoryId,
} from "../../redux/selectors/FactorySelectors";
import {setCurrentConditionId} from "../../redux/slices/FactorySlice";

import {REQ_TEMPLATES_TABLE_COLUMNS} from "./constants";

const useRequestTemplatesService = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const currentFactoryConditions = useSelector(selectCurrentFactoryConditions);
  const currentConditionId = useSelector(selectCurrentConditionId);

  const [isReqTemplatesModalActive, setReqTemplatesModalActive] = useState(false);

  const isEditListBtnDisabled = currentFactoryId === null;
  const noData = t("not specified");

  const reqTemplatesTableColumns = useColumns(REQ_TEMPLATES_TABLE_COLUMNS);
  const reqTemplatesTableData = currentFactoryConditions
    ? currentFactoryConditions.map(
        ({conditionId, factory: {name}, termsCondition, representativeContact, requestEmailTemplate} = {}, index) => ({
          id: conditionId,
          number: ++index,
          name: name ?? noData,
          factoryConditions: termsCondition ?? noData,
          contactPerson: representativeContact.name ?? noData,
          status: requestEmailTemplate ? STATUS_COLORS.GREEN : STATUS_COLORS.RED,
        }),
      )
    : [];

  function onReqTemplatesTableRowSelect(conditionId) {
    if (currentConditionId === conditionId) {
      dispatch(setCurrentConditionId(null));
      return;
    }
    dispatch(setCurrentConditionId(conditionId));
  }
  function onReqTemplatesModalClose() {}
  function onEditListBtnClick() {
    setReqTemplatesModalActive(true);
  }

  return {
    reqTemplatesTableColumns,
    reqTemplatesTableData,
    onReqTemplatesTableRowSelect,
    isEditListBtnDisabled,
    onEditListBtnClick,
    isReqTemplatesModalActive,
    setReqTemplatesModalActive,
    onReqTemplatesModalClose,
    currentConditionId,
    setCurrentConditionId,
  };
};

export default useRequestTemplatesService;
