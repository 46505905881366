import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectCurrentSkuRequest, selectWarehouseRequests, selectWarehouseStockPools} from "../redux/selectors";

const useWarehouseBlockService = selectedWarehouse => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const currentSkuRequest = useSelector(selectCurrentSkuRequest);
  const warehouseStockPoolsAfterAcception = useSelector(selectWarehouseStockPools);
  const warehouseRequestsAfterAcception = useSelector(selectWarehouseRequests);

  const warehouseAddressWithoutSelection = warehouseRequestsAfterAcception?.find(
    item => item?.stockPool?.warehouse?.warehouseId === currentSkuRequest?.stockPool?.warehouseId,
  );

  const initialValues = useMemo(
    () => ({
      tpNumber: currentSkuRequest?.stockPool?.cargo?.cargoNumber || "",
      warehouseContactsField: selectedWarehouse
        ? selectedWarehouse?.address
        : warehouseAddressWithoutSelection?.stockPool?.warehouse?.address,
    }),
    [currentSkuRequest, selectedWarehouse],
  );

  return {
    initialValues,
    warehouseStockPoolsAfterAcception,
  };
};

export default useWarehouseBlockService;
