import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import i18n from "../../../../i18n";
import {TextAreaInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {getCarrier} from "../../redux/operations";
import {selectCarriers, selectCarrierTypes, selectCurrentSkuRequest} from "../../redux/selectors";

const TransporterContacts = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const carriers = useSelector(selectCarriers),
    currentSkuRequest = useSelector(selectCurrentSkuRequest),
    carrierTypes = useSelector(selectCarrierTypes);

  const carrierOptions = useMemo(() => {
    if (carriers) return carriers?.map(carrier => ({value: carrier?.carrierId, title: carrier?.name} || []));
  }, [carriers]);

  const carrierTypesOptions = useMemo(() => {
    if (carrierTypes)
      return carrierTypes?.map(
        type => ({value: type.typeId, title: i18n.language === "en" ? type?.engName : type?.ukrName} || []),
      );
  }, [carrierTypes]);

  return (
    <>
      <div className="fdr jcsb mb0_5">
        <Select
          label={t("Transporter type") + ":"}
          autolabel
          options={carrierTypesOptions}
          name="transporterTypeId"
          disabled={!currentSkuRequest}
        />
        <Select
          label={t("Transporter") + ":"}
          autolabel
          options={carrierOptions}
          name="transporterId"
          makeEffect={e => {
            dispatch(getCarrier(e.value));
          }}
          disabled={!currentSkuRequest}
        />
      </div>
      <div>
        <div className="fdr">
          <div>1.</div>
          <div className="label underline">{t("Transporter contacts")}</div>
        </div>
        <TextAreaInput name="transporterContactsField" width={"100%"} readOnly className="mb0_5" />
      </div>
    </>
  );
};

export default TransporterContacts;
