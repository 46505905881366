import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";

import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import {deselectCurrentRequest} from "../../Requests/RequestsSlice";
import DeliveryInfo from "../common/DeliveryInfo/DeliveryInfo";
import DocumentsInfo from "../common/DocumentsInfo/DocumentsInfo";
import GroupAndType from "../common/GroupAndType/GroupAndType";
import HeaderInputRow from "../common/HeaderInputRow/HeaderInputRow";
import OrderFullSection from "../common/OrderFullSection/OrderFullSection";
import OrderInfo from "../common/OrderInfo/OrderInfo";
import ShipmentInfo from "../common/ShipmentInfo/ShipmentInfo";
import CheckListModalsTodo from "../common/Todo/CheckListModalsTodo";

import useLogModSPCheckList from "./useLogModSPCheckList";

const LogModSPCheckList = ({onItemOpen, isNoOpenNewTabBtn = false}) => {
  const dispatch = useDispatch();
  const {
    initialValues,
    headerInputNames,
    orderTableColumns,
    orderTableData,
    // pdfFileArray,
    documentsTableData,
    currentSP,
  } = useLogModSPCheckList();

  useEffect(() => {
    dispatch(deselectCurrentRequest());
  }, []);

  return (
    <div className="page_flex_container">
      <Formik initialValues={initialValues} enableReinitialize={true}>
        <Form className="w100 fcCol flexg1">
          <HeaderInputRow headerInputNames={headerInputNames} />
          <Divider />
          <OrderFullSection
            maxHeight={"34em"}
            firstColumn={
              <>
                <OrderInfo
                  onItemOpen={onItemOpen}
                  orders={currentSP?.orders || []}
                  dontShowButton={isNoOpenNewTabBtn}
                />
                <DeliveryInfo />
                <CheckListModalsTodo path={"stockPool"} entityId={currentSP?.stockPoolId || null} />
              </>
            }
            secondColumn={
              <>
                <GroupAndType orders={currentSP?.orders || []} />
                <DocumentsInfo documentsTableData={documentsTableData} />
                <ShipmentInfo packingSpaces={currentSP?.packingSpaces || []} />
              </>
            }
          ></OrderFullSection>
          <ReactTable
            columns={orderTableColumns}
            defaultData={orderTableData}
            className="w100 pb"
            style={{height: "15em", flexGrow: 1}}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default LogModSPCheckList;
