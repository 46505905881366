import {dateFormat} from "../../../utilities/dateFormat";
import {getExpandedDocs, profExpanded} from "../../Requests/RequestsInProgress/utils/functions/expandDataForTable";

export const makeRegisterSPTableData = (stockPools, lang = "en") =>
  stockPools?.map((stockPool, index) => ({
    number: ++index,
    id: stockPool.stockPoolId,
    spStatus: (lang === "en" ? stockPool?.status?.engName : stockPool?.status?.ukrName) || " - ",
    stockPoolNumber: stockPool?.stockPoolNumber ?? " - ",
    trPoolNumber: stockPool?.cargo?.cargoNumber ?? " - ",
    actionPlace: " - ",
  })) || [];

export const DOCTYPES = Object.freeze({PACKING_LIST: 1, INVOICE: 2});

export const makeRegisterRqstsTableData = skuRequests =>
  skuRequests?.map(({skuRequestId, order, request, stockPool} = {}, index) => {
    return {
      number: ++index,
      id: skuRequestId,
      requestNumber: request?.requestNumber ?? " - ",
      requestStatus: stockPool?.status?.ukrName ?? " - ",
      orderNumber: order?.orderNumber ?? " - ",
      managerFea: request?.managerFea?.name ?? " - ",
      factory: request?.brand?.name ?? " - ",
      requestSum: request?.requestSum ?? " - ",
      createdAt: dateFormat((stockPool?.docs ?? [])[0]?.createdAt) ?? " - ",
      bringTo: dateFormat(order?.co?.bringTo) ?? " - ",
      country: order?.co?.project?.country?.shortName ?? " - ",
      managerCo: order?.co?.managerCo?.name ?? " - ",
      proformaNumber: profExpanded(request?.proforms) || " - ",
      packingListNumber: getExpandedDocs(stockPool?.docs, 1) || "-",
      invoiceNumber: getExpandedDocs(stockPool?.docs, 2) || "-",
      note: request?.note ?? " - ",
      actionPlace: " - ",
      readinessDate: dateFormat(request?.requestDates?.[0].actualDate) || " - ",
      logist: stockPool?.logist?.name ?? " - ",
    };
  }) || [];

// packingListNumber: getExpandedDocs(stockPool?.docs, 1) || "-",

export function formFactoryContactsField(
  factory,
  t = value => {
    return value;
  },
) {
  if (!factory) return "";
  return `${t("Name")}: ${factory?.name ?? " - "} / ${t("phone")}: ${
    factory?.factoryContacts?.flatMap(contact => contact.phone)?.join(", ") || " - "
  } / ${t("email")}: ${factory?.factoryContacts?.flatMap(contact => contact.email)?.join(", ") || " - "} \n ${t(
    "Address",
  )}:  ${factory?.factoryContacts?.flatMap(contact => contact.address)?.join(", ") || " - "}`;
}

export function formPlaceAddress(
  placePoint,
  t = value => {
    return value;
  },
) {
  if (!placePoint) return "";

  return `${t("Name")}: ${placePoint?.name ?? " - "} \n${t("Address")}:  ${placePoint?.address || " - "} \n${t(
    "Note",
  )}:  ${placePoint?.note || " - "}`;
}
