import {toast} from "react-toastify";
import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import Swal from "sweetalert2";

import {deleteReq, fetchData, fetchList} from "../hook/axios.hook";
import {getError} from "../utilities/toasts";

const initialState = {
  kpList: null,
  projectKpList: [],
  kpIdList: [],
  currentKp: null,
  kpLoadingStatus: "idle",
  editedKp: null,
  subKpList: [],
  currentSubKp: null,
  docsArray: [],
  annexes: [],
};

// export const fetchProjectKpList = createAsyncThunk(
//     'kp/fetchProjectKpList',
//     async (id) => {
//         const responce = fetchData(`/kp/getForProject/${id}`,'get')
//         return responce;
//     }
// );

export const getKpInfo = createAsyncThunk("kp/getKpInfo", async coId => {
  const responce = fetchData(`/kp/get/${coId}`, "get");
  return responce;
});
export const getKpInfoForKp = createAsyncThunk("kp/getKpInfoForKp", async id => {
  const responce = fetchData(`/kp/get/${id}?window=kp`, "get");
  return responce;
});

export const createKp = createAsyncThunk("kp/createKp", async ({newCo, projectId}) => {
  const responce = fetchData(`/kp/add/${projectId}`, "post", newCo);
  return responce;
});
export const createKpA = createAsyncThunk("kp/createKpA", async ({coId, newKp}) => {
  const responce = fetchData(`/kp/a/add/${coId}`, "post", newKp);
  return responce;
});
export const createKpF = createAsyncThunk("kp/createKpF", async ({coId, newKp}) => {
  const responce = fetchData(`/kp/f/add/${coId}`, "post", newKp);
  return responce;
});

export const fetchKpList = createAsyncThunk("kp/fetchKpList", async () => {
  const responce = fetchData(`/kp/getForCurrent`, "get");
  return responce;
});

export const deleteKp = createAsyncThunk("kp/deleteKp", async coId => {
  const responce = fetchData(`/kp/delete/${coId}`, "delete");
  return responce;
});

//annex
export const addAnnex = createAsyncThunk("projects/addAnnex", async coId => {
  const responce = fetchList(`/annexes/add/${coId}`, "post");
  return responce;
});
export const updateAnnex = createAsyncThunk("projects/updateAnnex", async ({annexId, data}) => {
  const responce = fetchData(`/annexes/update/${annexId} `, "patch", data);
  return responce;
});
export const addAnnexFile = createAsyncThunk("projects/addAnnexFile", async ({annexId, formdata}) => {
  const responce = fetchData(`/annexes/docs/update/${annexId}`, "put", formdata, {
    "Content-Type": "multipart/form-data charset=utf-8",
  });
  return responce;
});

export const delAnnexFile = createAsyncThunk("projects/delAnnexFile", async ({annexId, url}) => {
  const responce = deleteReq(`/annexes/docs/delete/${annexId}`, {
    docUrl: url,
  });
  return responce;
});
///////////////////////

export const updateNote = createAsyncThunk("kp/updateNote", async newNote => {
  const responce = fetchData(`/kp/updateNote`, "patch", newNote);
  return responce;
});

export const updateSubKpNote = createAsyncThunk("kp/updateSubKpNote", async ({id, type, newNote}) => {
  const responce = fetchData(`kp/${type}/update/${id}`, "patch", {
    note: newNote,
  });
  return responce;
});

export const updateKp = createAsyncThunk("kp/updateKp", async ({coId, newKp}) => {
  const responce = fetchData(`/kp/update/${coId}`, "patch", newKp);
  return responce;
});

export const updateKpPrePayment = createAsyncThunk("kp/updateKpPrePayment", async info => {
  const responce = fetchData(`/kp/updatePrePayment`, "patch", info);
  return responce;
});

export const saveKpfAsMain = createAsyncThunk("kp/saveKpfAsMain", async cofId => {
  const responce = fetchData(`/kp/f/saveAsMain/${cofId}`, "post");
  return responce;
});

export const makeOrder = createAsyncThunk("kp/makeOrder", async cofId => {
  const responce = fetchData(`/kp/f/makeOrders/${cofId}`, "post");
  return responce;
});

function moveElementForward(mas, element) {
  const index = mas.indexOf(element);
  var arr = [...mas];

  if (index > -1 && index < arr.length) {
    // Если элемент найден и не является последним элементом массива
    // Удаляем элемент из текущей позиции
    arr.splice(index, 1);
    // Вставляем элемент на следующую позицию
    arr.splice(0, 0, element);
  }

  // Возвращаем измененный массив
  return arr;
}

const kpSlice = createSlice({
  name: "kp",
  initialState,
  reducers: {
    kpCreated: (state, action) => {
      state.projectKpList.unshift(action.payload);
      state.currentKp = action.payload;
    },
    kpDeleted: state => {
      state.projectKpList = state.projectKpList.filter(item => item.id !== state.currentKp.id);
      state.currentKp = null;
    },
    deselectKp: state => {
      state.currentKp = null;

      state.docsArray = [];
      state.subKpList = [];
      state.annexes = [];
    },
    sortKpList: (state, {payload}) => {
      const element = state.kpList.find(kp => {
        return kp.kpId === payload;
      });
      state.kpList = moveElementForward(state.kpList, element);
    },

    selectKp: (state, {payload}) => {
      if (!payload) state.currentKp = null;
      else
        state.kpList.forEach(item => {
          if (item.coId === payload) state.currentKp = item;
        });
    },
    selectSubKp: (state, {payload}) => {
      if (!payload) {
        state.currentSubKp = null;
        state.docsArray = [];
      } else {
        state.subKpList.forEach(item => {
          if (payload.type === "f" && item.cofId === payload.kp) {
            state.currentSubKp = item;
            state.docsArray = item.docsArray;
          } else if (payload.type === "a" && item.coaId === payload.kp) {
            state.currentSubKp = item;
          }
        });
      }
    },
    kpDocAdded: (state, action) => {
      state.docsArray = action.payload;
    },

    setKpList: (state, action) => {
      state.docsArray = [];
      state.projectKpList = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchKpList.pending, state => {
        state.kpLoadingStatus = "loading";
      })
      .addCase(fetchKpList.fulfilled, (state, {payload}) => {
        state.kpLoadingStatus = "complete";

        if (state.currentKp) {
          const element = payload.kpList.find(kp => kp.coId === state.currentKp.coId);
          state.kpList = moveElementForward(payload.kpList, element);
        } else {
          state.kpList = payload.kpList;
        }
      })
      .addCase(fetchKpList.rejected, state => {
        state.kpLoadingStatus = "error";
      })
      .addCase(getKpInfo.fulfilled, (state, {payload}) => {
        state.currentKp = {
          ...payload,
          startDate: payload.startDate,
          finalDate: payload.finalDate,
          managerKpId: payload.managerCo.userId,
          managerKpName: payload.managerCo.name,
          designerName: payload.designer?.name || "",
          designerId: payload.designer?.designerId || "",
          designerBonus: payload.designer?.bonus || "",
        };
        state.docsArray = [];
        state.annexes = payload.annexes;
      })
      .addCase(getKpInfoForKp.fulfilled, (state, {payload}) => {
        state.currentKp = payload;

        state.subKpList = [...payload.cofs, ...payload.coas];

        state.currentSubKp = null;
        state.docsArray = [];
      })

      .addCase(createKp.fulfilled, (state, {payload}) => {
        // Swal.fire({
        //   title: ``,
        //   text: ` ${payload.message} `,
        //   icon: "info",
        //   confirmButtonText: "Ок",
        // });
      })
      .addCase(createKp.rejected, (state, {error}) => {
        toast.error(error);
      })

      .addCase(deleteKp.fulfilled, state => {
        state.projectKpList = state.projectKpList.filter(item => item.coId !== state.currentKp.coId);
        state.currentKp = null;
      })
      .addCase(deleteKp.rejected, (state, {error}) => {
        toast.error(error);
      })

      //annex
      .addCase(addAnnex.fulfilled, (state, {payload}) => {
        state.annexes.push(payload.annex);
      })
      .addCase(updateAnnex.fulfilled, (state, {payload}) => {
        const {annex} = payload;
        state.annexes = state.annexes.map(item => {
          if (item.annexId === annex.annexId) {
            return annex;
          } else return item;
        });
        state.currentKp = {...state.currentKp, bringTo: annex?.bringTo};
      })
      .addCase(addAnnexFile.pending, state => {
        state.docsLoading = true;
      })
      .addCase(addAnnexFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.annexes = state.annexes.map(annex => {
          if (annex.annexId === meta.arg.annexId) {
            return {...annex, docsArray: payload.docsArray};
          } else {
            return annex;
          }
        });
        state.docsLoading = false;
      })
      .addCase(delAnnexFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.annexes = state.annexes.map(annex => {
          if (annex.annexId === meta.arg.annexId) {
            return {...annex, docsArray: payload.docsArray};
          } else {
            return annex;
          }
        });
      })
      //////////////////

      .addCase(updateNote.fulfilled, (state, {payload}) => {
        const {kp} = payload;
        state.projectKpList = state.projectKpList.map(item => {
          if (item.coId === kp.coId) {
            return {...item, kpNote: kp.kpNote};
          } else return item;
        });
      })
      .addCase(updateNote.rejected, (state, {error}) => {
        toast.error(error);
      })

      .addCase(updateSubKpNote.fulfilled, (state, {payload}) => {
        const kp = payload?.cof || payload?.coa;

        state.subKpList = state.subKpList.map(item => {
          if (item.cofId ? item.cofId === kp.cofId : item.coaId === kp.coaId) {
            state.currentSubKp = {...item, note: kp.note};
            return {
              ...item,
              note: kp.note,
            };
          } else return item;
        });
      })

      .addCase(updateKp.pending, state => {
        state.kpLoadingStatus = "loading";
      })
      .addCase(updateKp.fulfilled, (state, {payload}) => {
        const {co} = payload;

        const newCoL = state.projectKpList.map(item => {
          if (item.coId === co.coId) {
            return {
              ...co,
              projectManagerName: item.projectManagerName,
            };
          } else return item;
        });
        state.projectKpList = newCoL;
        state.currentKp = {...state.currentKp, ...co};
        state.kpLoadingStatus = "complete";
      })
      .addCase(updateKp.rejected, (state, {error}) => {
        toast.error(error);
      })

      .addCase(updateKpPrePayment.fulfilled, (state, {payload}) => {
        const {kp} = payload;

        const newKp = {
          ...kp,
          startDate: kp.startDate,
          finalDate: kp.finalDate,
          advancePaymentDeadline: kp.advancePaymentDeadline,
        };
        const newKpL = state.kpList.map(item => {
          if (item.coId === kp.coId) {
            return newKp;
          } else return item;
        });
        state.kpList = newKpL;
        state.currentKp = newKp;
      })

      .addCase(createKpF.fulfilled, (state, {payload}) => {
        const {cof} = payload;
        var newSubKp = {...cof, type: "f"};
        state.subKpList.unshift(newSubKp);

        state.currentSubKp = newSubKp;
      })
      .addCase(createKpA.fulfilled, (state, {payload}) => {
        const {coa} = payload;
        var newSubKp = {...coa, type: "a"};
        state.subKpList.unshift(newSubKp);

        state.currentSubKp = newSubKp;
      })
      .addCase(saveKpfAsMain.pending, state => {
        state.kpLoadingStatus = "loading";
      })
      .addCase(saveKpfAsMain.fulfilled, (state, {payload}) => {
        state.kpLoadingStatus = "idle";
      })

      // .addCase(makeOrder.rejected, (state, action) => {
      //   console.log(action.error.message);
      //   getError(payload);
      // })
      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = kpSlice;

export default reducer;
export const {
  kpCreated,
  kpDeleted,
  selectKp,
  selectKpInProject,
  setEditedKp,
  setKpList,
  deselectKp,
  selectSubKp,
  kpDocAdded,
  sortKpList,
} = actions;

export const getCurrentSubKp = state => state.kp.currentSubKp;
export const getCurrentSubKpDocs = state => state.kp.docsArray;
export const getProjectKpList = state => state.kp.projectKpList;
export const getKpList = state => state.kp.kpList;
export const getCurrentKp = state => state.kp.currentKp;
export const getSubKps = state => state.kp.subKpList;
export const getKpAnnexes = state => state.kp.annexes;
