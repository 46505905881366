import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import RequestBlock from "../../RequestBlock/RequestBlock";
import {
  clearNote,
  // deleteRequest,
  deselectCurrentOrder,
  deselectCurrentRequest,
  fetchRequests,
  getCheckedRequestIds,
  getCurrentRequestId,
  getCurrentRequestSKUs,
  getRequest,
  getThirdStatusRequests,
  mergeRequests,
  returnOrder,
  setCheckedRequestIds,
  setCurrent,
  splitRequests,
} from "../../RequestsSlice";
import SimilarSkuBlock from "../../SimilarSkuBlock/SimilarSkuBlock";
import SplitOrderSku from "../splitOrderModal/splitOrderSku";
import useColumns from "../utils/hooks/useColumns";
import {SPLIT_TYPES} from "../utils/splitTypes";

import {REQ_WINDOW_TYPES} from "./../../RequestsInProgress/utils/functions/requestsFiltersManager";
import {ORDER_BLOCK_COLUMNES} from "./utils/constants";

import styles from "./OrderBlock.module.scss";

const OrderBlock = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const [splitModalActive, setSplitModalActive] = useState(false);

  const [defaultCheck, setDefaultCheck] = useState(null);

  const currentRequestIds = useSelector(getCheckedRequestIds);
  const currentRequestSKUs = useSelector(getCurrentRequestSKUs);

  const areRequestsChecked = currentRequestIds?.length > 1;
  const currentOrder = useSelector(getCurrentRequestId);

  // let note = useSelector(getCurrentNote);
  // note = note?.replaceAll("\n", " / ");

  const status3Requests = useSelector(getThirdStatusRequests) ?? [];

  const status3TableData = useMemo(() => {
    return status3Requests?.map(
      ({requestNumber, managerCo, requestSum, brand, createdAt, note, status, requestId}, index) => ({
        id: requestId,
        number: index + 1,
        requestNumber,
        managerCo: managerCo?.name,
        factory: brand?.name,
        requestSum,
        createdAt: dateFormat(createdAt),
        note,
        status: i18n.language === "en" ? status.engName : status.ukrName,
      }),
    );
  }, [status3Requests]);

  useEffect(() => {
    dispatch(
      fetchRequests({
        window: REQ_WINDOW_TYPES.DISTRIBUTED,
        showidenticalSku: true,
      }),
    );

    return () => {
      dispatch(deselectCurrentOrder());
    };
  }, []);

  useEffect(() => {
    dispatch(deselectCurrentRequest());
    dispatch(deselectCurrentOrder());
  }, []);

  const status3Columns = useColumns(ORDER_BLOCK_COLUMNES);

  const updateAndCleanAfterRequest = () => {
    dispatch(setCheckedRequestIds([]));
    dispatch(fetchRequests({window: "Distributed requests"}));
  };

  //event handlers
  //for tables
  const onStatus3TableRowSelect = useCallback(
    requestId => {
      if (!requestId || requestId === currentOrder) {
        dispatch(deselectCurrentOrder());
        dispatch(deselectCurrentRequest());
        return;
      }

      dispatch(getRequest(requestId));
      dispatch(setCurrent(requestId));
      //cleaning redux state currentNote from previous requests
      dispatch(clearNote());
    },
    [currentOrder],
  );

  const onStatus3TableCheckboxChange = checked => {
    if (currentRequestIds && checked.join("") === currentRequestIds.join("")) return;
    dispatch(setCheckedRequestIds(checked));
  };

  //for buttons
  // const onCreateRequestBtnClick = () => {
  //   if (currentRequestIds.length === 0) {
  //     toast.error(t("Please select at least one request"));
  //     return;
  //   }
  //   dispatch(turnIntoRequest({requestIds: currentRequestIds, note})).then(({meta}) => {
  //     if (meta.requestStatus === "fulfilled") {
  //       toast.success(t("Request was created successfully"));
  //       dispatch(setCurrentSkuRequestId(null));
  //     }
  //   });
  //   updateAndCleanAfterRequest();
  // };

  const onCombineRequestsBtnClick = () => {
    dispatch(mergeRequests(currentRequestIds)).then(({meta, payload}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("Requests were merged successfully"));
        updateAndCleanAfterRequest();
        setDefaultCheck([payload.requestId]);
        onStatus3TableRowSelect(payload.requestId);
      }
    });
  };

  const onSplitRequestBtnClick = () => {
    if (!currentOrder) {
      toast.error(t("You must select one request!"));

      return;
    }
    dispatch(splitRequests(currentOrder)).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("Request before split"));
        setSplitModalActive(true);
      }
    });
  };

  const onReturnOrderBtnClick = () => {
    dispatch(returnOrder(currentRequestIds)).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("return order info"));
        updateAndCleanAfterRequest();
      }
    });
  };

  // const onDel = useCallback(() => {
  //   dispatch(deleteRequest(currentOrder));
  // }, [currentOrder]);

  return (
    <>
      <div className={styles.orderBlock}>
        <div className={styles.blockHeader}>
          <div className={styles.blockHeaderLeft}>
            <label>{`1. ${t("Orders")}:`}</label>
            <FunctionButton
              name={t("Create special order")}
              tooltipMessage={"specOrd"}
              autoWidth
              className={styles.specialOrderBtn}
              disabled={true}
            />
          </div>
          <SimilarSkuBlock />
        </div>
        <ReactTable
          defaultData={status3TableData}
          columns={status3Columns}
          onSelect={onStatus3TableRowSelect}
          current={currentOrder ?? null}
          defaultMultiSelection={defaultCheck}
          setMultiSelect={onStatus3TableCheckboxChange}
          className={styles.status3Table}
          enableMultiSelection={true}
        />

        <div className="button-block ">
          <FunctionButton
            name={t("Combine orders")}
            onClick={onCombineRequestsBtnClick}
            disabled={!areRequestsChecked}
            autoWidth
          />
          <FunctionButton
            name={t("Split orders")}
            onClick={onSplitRequestBtnClick}
            disabled={!currentOrder || currentRequestSKUs.length < 2}
            autoWidth
          />
          <FunctionButton
            name={t("return ord")}
            onClick={onReturnOrderBtnClick}
            disabled={!currentRequestIds?.length > 0}
            autoWidth
          />

          {/* <FunctionButton
            name={t("Create request")}
            onClick={onCreateRequestBtnClick}
            disabled={!currentRequestIds?.length > 0}
            autoWidth
          /> */}
          {/* кнопка удаления (на всякий случай) */}
          {/* <FunctionButton
            name="Cancel"
            onClick={() => {
              console.log("del", currentOrder);
              onDel();
            }}
          /> */}
        </div>
        <RequestBlock />
      </div>
      <ModalForm
        closable={true}
        Component={SplitOrderSku}
        updateAndCleanAfterRequest={updateAndCleanAfterRequest}
        label={t("Order separation")}
        active={splitModalActive}
        setModalActive={setSplitModalActive}
        // className={styles.order_split_modal}
        // isStatus={false}
        isStatus={SPLIT_TYPES.NORMAL_ORDER}
      />
    </>
  );
};

export default OrderBlock;
