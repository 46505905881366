import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useProductGroupsOptionsService from "../../../../utilities/hooks/useProductGroupsOptionsService";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  factoryAppointManager,
  factoryDisappointManager,
  fetchFactoryGroups,
} from "../../redux/operations/FactoryOperations";
import {selectCurrentFactoryId, selectFactoryGroups} from "../../redux/selectors/FactorySelectors";
import {REQ_AUTODISTRIBUTION_TABLE_COLUMNS} from "../common/utils/constants";

const useFactoryDistributionService = () => {
  const dispatch = useDispatch();

  const [currentGroupId, setCurrentGroupId] = useState(null);

  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const factoryGroups = useSelector(selectFactoryGroups);
  const currentFactoryGroup = factoryGroups?.find(factoryGroup => factoryGroup.productGroupId === currentGroupId);
  const current = "";
  const areBtnsDisabled = currentGroupId === null;

  useEffect(() => {
    if (currentFactoryId) {
      dispatch(fetchFactoryGroups(currentFactoryId));
    }
  }, [currentFactoryId]);

  //data
  const productGroupOptions = useProductGroupsOptionsService();
  const initialValues = {
    factoryId: currentFactoryGroup?.factoryId ?? "",
    productGroupId: currentFactoryGroup?.productGroupId ?? "",
    managerFeaId: currentFactoryGroup?.managerFeaId ?? "",
  };
  const factoryAppoinColumns = useColumns(REQ_AUTODISTRIBUTION_TABLE_COLUMNS);
  const factoryAppoinData =
    factoryGroups?.map(({productGroupId, factory, productGroup, managerFea}, i) => ({
      number: i + 1,
      factory: factory.name,
      productGroup: productGroup.name,
      managerFea: managerFea.name,
      id: productGroupId,
    })) || [];

  function calcFilteredFactoryAppoinData({managerFeaId, productGroupId}) {
    return productGroupId || managerFeaId
      ? factoryAppoinData?.filter(factoryGroup =>
          productGroupId && managerFeaId
            ? factoryGroup.productGroupId === productGroupId && factoryGroup.managerFeaId === managerFeaId
            : productGroupId
            ? factoryGroup.productGroupId === productGroupId
            : factoryGroup.managerFeaId === managerFeaId,
        )
      : factoryAppoinData;
  }

  //event handlers
  function onReqAutodistrTableRowSelect(productGroupId) {
    if (productGroupId === currentGroupId) {
      setCurrentGroupId(null);
      return;
    }
    setCurrentGroupId(productGroupId);
  }
  function onAddBtnClick({factoryId, productGroupId, managerFeaId}) {
    dispatch(factoryAppointManager({factoryId, productGroupId, managerFeaId}));
  }
  function onDeleteBtnClick({factoryId, productGroupId}) {
    dispatch(factoryDisappointManager({factoryId, productGroupId}));
  }

  return {
    productGroupOptions,
    factoryAppoinColumns,
    calcFilteredFactoryAppoinData,
    onReqAutodistrTableRowSelect,
    current,
    onAddBtnClick,
    onDeleteBtnClick,
    initialValues,
    areBtnsDisabled,
  };
};

export default useFactoryDistributionService;
