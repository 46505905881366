import {configureStore} from "@reduxjs/toolkit";

import {instance} from "../api/apiSetup";
import kpf from "../Components/KP/kp-f/KpFinSlice";
import logistCargos from "../Components/Logist/redux/Cargos/CargosSlice";
import logistCarriers from "../Components/Logist/redux/Carriers/CarriersSlice";
import logist from "../Components/Logist/redux/LogistSlice";
import logistReminders from "../Components/Logist/redux/Reminders/RemindersSlice";
import logistWarehouses from "../Components/Logist/redux/Warehouses/WarehousesSlice";
import projects from "../Components/Project/ProjectSlice";
import reminds from "../Components/reminder/RemindsSlice";
import proforms from "../Components/Requests/Proform/redux/slices/ProformSlice";
import payment from "../Components/Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import requests from "../Components/Requests/RequestsSlice";
import sku from "../Components/SKU/SkuSlice";
import factories from "../Components/VED/redux/slices/FactorySlice";
import catalogs from "../slices/CatalogsSlice";
import kp from "../slices/KPSlice";
import person from "../slices/PersonSlice";
import user, {setNotAuth} from "../slices/UserSlice";

const stringMiddleware = () => next => action => {
  if (typeof action === "string") {
    return next({
      type: action,
    });
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    user,
    projects,
    catalogs,
    kp,
    reminds,
    person,
    sku,
    kpf,
    requests,
    proforms,
    payment,
    factories,
    logist,
    logistCarriers,
    logistWarehouses,
    logistReminders,
    logistCargos,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(stringMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

// const notAuth = ()=> ;

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch(setNotAuth());
      // sessionStorage.removeItem('access_token')
      return Promise.reject(error);
    } else {
      throw error;
    }
  },
);
