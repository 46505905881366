import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {getCurrentKp} from "../../../selectors/generalSelectors";
import {deselectKp, getKpInfo, getProjectKpList} from "../../../slices/KPSlice";
import {getCurrentUser} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ProjectAddKPForm from "../ProjectAddKP/ProjectAddKPForm";
import ProjectKpList from "../ProjectList/ProjectKpList";

const KpContainer = ({setGlobalSave, forcedUpdate}) => {
  const {t} = useTranslation();

  const currentKp = useSelector(getCurrentKp);
  const kpList = useSelector(getProjectKpList);
  const user = useSelector(getCurrentUser);
  const [isEdited, setIsEdited] = useState(false);
  const [initialUpd, setInitialUpd] = useState(forcedUpdate);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (kpList.length > 0 && location?.state) {
      try {
        const {currentKpId} = location.state;
        if (currentKpId) {
          select(currentKpId);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [kpList]);

  useEffect(() => {
    dispatch(deselectKp());
  }, []);

  useEffect(() => {
    if (isEdited) setGlobalSave(true);
  }, [isEdited]);

  useEffect(() => {
    setInitialUpd(forcedUpdate);
  }, [forcedUpdate]);

  const select = value => {
    if (currentKp && currentKp.coId === value) {
      dispatch(deselectKp());
      // .then(res=>{dispatch(annexesFetched(null)) })
    } else {
      dispatch(getKpInfo(value));
      // .then(res=>{
      //     if(res.meta.requestStatus ==='fulfilled'){
      //         dispatch(annexesFetched(res.payload.annexes))
      //     }
      // })
    }
  };

  const onMakeEffect = value => {
    if (isEdited && currentKp) {
      Swal.fire({
        title: "",
        text: `${t("CO changes")} ${currentKp.coNumber} ?`,
        icon: "question",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("No"),
      })
        .then(answ => {
          if (answ.isConfirmed) {
            setInitialUpd(i => (i = !i));
          }
          setTimeout(() => select(value), 0);
        })
        .then(() => {
          select(value);
        });
    } else {
      select(value);
    }
  };

  const onOpenCoClick = () => {
    if (currentKp?.managerCoId !== user?.id) {
      Swal.fire({
        title: "",
        text: `${t("Co of another mng")}`,
        icon: "info",
        confirmButtonText: "OK",
      });
    } else {
      return navigate("/ba3a/co");
    }
  };

  return (
    <div className="form_wrapper">
      <ProjectAddKPForm
        updates={initialUpd}
        onMakeEffect={onMakeEffect}
        setIsEdited={setIsEdited}
        isEdited={isEdited}
      />

      <div style={{height: "100%", position: "relative"}}>
        <ProjectKpList onMakeEffect={onMakeEffect} />

        {/* <div style={{position: "absolute", bottom: "1em", right: "0"}}> */}
        <FunctionButton
          name={t("Open CO")}
          style={{
            width: "10em",
            position: "absolute",
            right: "0",
            bottom: "-2em",
          }}
          onClick={onOpenCoClick}
          disabled={!currentKp}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

KpContainer.propTypes = {setGlobalSave: PropTypes.func, forcedUpdate: PropTypes.bool};

export default KpContainer;
