import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  clearCurrentPayment,
  clearRequestPayments,
  getAllPayments,
  getPaymentsForRequest,
} from "../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import {
  getCurrentPayment,
  getPayments,
  getPaymentsLoader,
} from "../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

import PaymentToFactoryModal from "./PaymentToFactoryModal/PaymentToFactoryModal";
import {PAYMENT_TO_FACTORY_COLUMNS} from "./utils/constants";
import usePaymentsData from "./utils/getPaymentsData";

const PaymentDocumentsList = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [paymentToFactoryModal, setPaymentToFactoryModal] = useState(false);
  const [current, setCurrent] = useState(null);

  const columns = useColumns(PAYMENT_TO_FACTORY_COLUMNS);
  const payments = useSelector(getPayments);
  const currentPayment = useSelector(getCurrentPayment);
  const paymentsLoader = useSelector(getPaymentsLoader);

  const defaultData = usePaymentsData(payments);

  useEffect(() => {
    if (!paymentToFactoryModal) {
      dispatch(getAllPayments());
    }
  }, [paymentToFactoryModal]);

  const fetchPaymentInfo = jsonId => {
    let complexId = null;
    try {
      complexId = JSON.parse(jsonId);
    } catch (error) {
      console.error(error);
    }
    const requestId = complexId?.requestId;
    const paymentId = complexId?.paymentId;
    dispatch(getPaymentsForRequest({requestId, paymentId}));
  };

  const onSelect = id => {
    if (id === current) {
      setTimeout(() => {
        if (!paymentToFactoryModal) {
          setCurrent(null);
        }
      }, 500);

      return;
    }
    setCurrent(id);
  };

  function onModalOpen() {
    if (!current) return;
    fetchPaymentInfo(current);
    setPaymentToFactoryModal(true);
  }

  function onModalClose() {
    dispatch(clearCurrentPayment());
    dispatch(clearRequestPayments());
  }

  return (
    <>
      <div style={{height: "100%", marginTop: "10px", width: "100%", overflow: "hidden"}}>
        <ReactTable
          defaultData={defaultData}
          columns={columns}
          onSelect={onSelect}
          current={current}
          className={"scrollX"}
          tableClassName={"request-table"}
          loading={paymentsLoader}
          onRowDoubleClick={onModalOpen}
        />
      </div>
      <div className="right-side-btn">
        <FunctionButton
          name={"Open"}
          onClick={onModalOpen}
          // disabled={!current}
        />
      </div>
      <ModalForm
        closable={true}
        Component={PaymentToFactoryModal}
        label={t("P on Req") + " " + currentPayment?.request?.requestNumber}
        active={paymentToFactoryModal}
        setModalActive={setPaymentToFactoryModal}
        onModalClose={onModalClose}
        // className="inProgressModal"
        // modalInnerWrapperClassName="over"
      />
    </>
  );
};

export default PaymentDocumentsList;
