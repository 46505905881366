export const WAREHOUSE_UPPER_TP_AND_SP_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Transport Pool №",
    accessorKey: "transportPoolNumber",
  },
  {
    header: "Stock Pool №",
    accessorKey: "stockPoolNumber",
  },

  {
    header: "Order № ",
    accessorKey: "orderNumber",
  },
  {
    header: "Destination",
    accessorKey: "destination",
  },
  {
    header: "Departure place",
    accessorKey: "departure",
  },
  {
    header: "Departure date",
    accessorKey: "departureDate",
  },
  {
    header: "Note",
    accessorKey: "notes",
  },
]);

export const WAREHOUSE_ADRESS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Date",
    accessorKey: "date",
  },
  {
    header: "Address and contacts",
    accessorKey: "adressAndContacts",
  },
]);

export const WAREHOUSE_CARGO_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "L.W.H(s).",
    accessorKey: "proportions",
    size: 10,
  },
  {
    header: "volume",
    accessorKey: "volume",
    size: 10,
  },
  {
    header: "weight (kg)",
    accessorKey: "weight",
    size: 10,
  },
  {
    header: "SP №",
    accessorKey: "stockPoolNumber",
    size: 10,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 10,
  },
]);

export const WAREHOUSE_LOWER_MAIN_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Status*",
    accessorKey: "status",
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
  },
  {
    header: "Order № ",
    accessorKey: "orderNumber",
  },
  {
    header: "Warehouse",
    accessorKey: "warehouse",
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
  },
  {
    header: "Logist",
    accessorKey: "logist",
  },
  {
    header: "Factory",
    accessorKey: "factory",
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
  },
  {
    header: "Bring to",
    accessorKey: "bringTo",
  },
  {
    header: "Product country",
    accessorKey: "country",
  },
  {
    header: "Prof №",
    accessorKey: "proformaNumber",
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
  },
  {
    header: "Notes",
    accessorKey: "notes",
  },
]);
