import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../../Inputs";
import MoneyReadInput from "../../../../Inputs/MoneyReadInput";
import {Select} from "../../../../Inputs/SelectField";

const AdditionalVerificationFields = () => {
  const {t} = useTranslation();

  return (
    <div className="v_add_fields">
      <Select label={t("Type exp") + ":"} name="typeExp" options={[]} width="100%" generalClassName="v_add_item" />

      <TextInput label={t("Note") + ":"} name="noteExp" width="100%" generalClassName="v_add_item" />

      <MoneyReadInput label={t("Price pcs")} name="price" generalClassName="v_add_item" />

      <TextInput
        label={t("ex numb") + ":"}
        name="expNumber"
        width="100%"
        generalClassName="v_add_item"
        autolabel
        labelStyles={{width: "18em"}}
      />

      <div className="v_add_item ">
        <MoneyReadInput label={t("Sum") + ":"} name="expSum" />
      </div>

      <Select label={t("Type") + ":"} name="type" options={[]} width="100%" generalClassName="v_add_item" />

      <TextInput label={t("curr pr") + ":"} name="currPrice" width="100%" generalClassName="v_add_item" />

      <MoneyReadInput label={t("new pr") + ":"} name="newPrice" generalClassName="v_add_item" />

      <div className="v_add_item"></div>

      <div className="v_add_item v_add_button">
        <FunctionButton name="add ex" />
        <FunctionButton name="Save" />
      </div>
    </div>
  );
};

export default AdditionalVerificationFields;
