import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchUsers, getUsersFull} from "../../../slices/PersonSlice";
import {Select} from "../SelectField";

const UserSelect = ({label = "Manager", ...props}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector(getUsersFull);

  const name = language === "en" ? ["engFirstName", "engFirstName"] : ["firstName", "lastName"];

  useEffect(() => {
    if (users?.length < 1) {
      dispatch(fetchUsers());
    }
  }, []);

  const usersOptions = useMemo(
    () =>
      users?.map(user => {
        return {
          title: user.persona[name[0]] + " " + user.persona[name[1]],
          value: user.userId,
        };
      }) || [],
    [users],
  );

  return <Select {...props} label={t(label) + ":"} name="userId" options={usersOptions} />;
};

export default UserSelect;
