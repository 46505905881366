// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter_acc_btns {
  gap: 1em;
  margin: 0 0 0.5em 0;
}
.filter_acc_btns button {
  background: #FFFFFF;
  border: 1px solid #DAE1EC;
  color: inherit;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/accounts/accountsList/accWithFilter.scss"],"names":[],"mappings":"AAAA;EACI,QAAA;EACA,mBAAA;AACJ;AAAI;EACI,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;AAER","sourcesContent":[".filter_acc_btns{\n    gap: 1em;\n    margin: 0 0 0.5em 0;\n    button{\n        background: #FFFFFF;\n        border: 1px solid #DAE1EC;\n        color: inherit;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
