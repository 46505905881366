import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import Project from "../Components/Project/Project";
const ProjectPage = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Project />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
};

export default ProjectPage;
