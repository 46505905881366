import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import formTerms from "../../../../../../utilities/formTerms";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  selectCurrentConditionId,
  selectCurrentFactoryConditions,
  selectTimeUnits,
} from "../../../../redux/selectors/FactorySelectors";
import {setCurrentConditionId} from "../../../../redux/slices/FactorySlice";

import {TEMPLATES_TABLE_COLUMNS} from "./constants";

const useTemplatesTableService = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();

  const currentFactoryConditions = useSelector(selectCurrentFactoryConditions);
  const currentConditionId = useSelector(selectCurrentConditionId);
  const timeUnits = useSelector(selectTimeUnits);

  const templatesTableColumns = useColumns(TEMPLATES_TABLE_COLUMNS);
  const templatesTableData =
    currentFactoryConditions?.map(
      (
        {
          conditionId,
          discount,
          termsFrom,
          termsTo,
          unitId,
          termsNote,
          termsCondition,
          representativeContact: {email, phone, name: contactPerson} = {},
          factory: {name, address, country: {shortName: country}} = {},
        },
        index,
      ) => ({
        id: conditionId,
        index: ++index,
        name,
        country,
        contactPerson,
        discount,
        email,
        phone,
        terms: formTerms({termsFrom, termsTo, unitId, timeUnits, language}),
        termsNote,
        termsCondition,
        address,
      }),
    ) ?? [];

  //event handlers
  function onTemplatesTableRowSelect(conditionId) {
    if (currentConditionId === conditionId) {
      dispatch(setCurrentConditionId(null));
      return;
    }
    dispatch(setCurrentConditionId(conditionId));
  }

  return {templatesTableColumns, templatesTableData, onTemplatesTableRowSelect, currentConditionId};
};

export default useTemplatesTableService;
