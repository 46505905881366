import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import {countObjectValuesSumm} from "../../../../../utilities/countObjectValuesSumm";
import {addNewStockPool, addToStockPool, fetchAllStockPoolsNew, returnToStock} from "../../../redux/operations";
import {
  selectWarehouseCreatingStockPools,
  selectWarehouseRequests,
  selectWarehouseSpStockPools,
} from "../../../redux/selectors";

const useWarehouseSplitCreateModal = selectedWarehouse => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {values, setFieldValue} = useFormikContext();
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [selectedSP, setSelectedSp] = useState([]);
  const [selectedUpperSkuRequests, setSelectedUpperSkuRequests] = useState([]);
  const [selectedLowerSkuRequests, setSelectedLowerSkuRequests] = useState([]);

  const stockPools = useSelector(selectWarehouseSpStockPools);
  const creatingCargoSP = useSelector(selectWarehouseCreatingStockPools);
  const requests = useSelector(selectWarehouseRequests);

  const stockPoolOptions = useMemo(() => {
    return (
      stockPools?.map(item => {
        return {title: item.stockPoolNumber, value: item.stockPoolId, isFinal: item.isFinal};
      }) || []
    );
  }, [stockPools]);

  const updateLists = () => {
    dispatch(
      fetchAllStockPoolsNew({
        window: "Warehouse Creating StockPool",
        warehouseId: selectedWarehouse?.value,
      }),
    );

    dispatch(
      fetchAllStockPoolsNew({
        window: "Warehouse StockPool",
        warehouseId: selectedWarehouse?.value,
      }),
    );
  };

  useEffect(() => {
    updateLists();
  }, []);

  useEffect(() => {
    if (values.newSpFinalDest) {
      Swal.fire({
        title: t("Attention"),
        text: t("Info FD"),
        icon: "info",
        confirmButtonText: t("Ok"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(answ => {
        if (!answ.isConfirmed) {
          setFieldValue("newSpFinalDest", false);
        }
      });
    }
  }, [values.newSpFinalDest]);

  const onAddToNewSpClick = () => {
    dispatch(
      addNewStockPool({
        warehouseId: requests[0]?.stockPool?.warehouse?.warehouseId,
        params: {
          stockNumber: values.stockPoolNumber,
          skuRequestIds: selectedUpperSkuRequests,
          isFinal: values.newSpFinalDest,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        updateLists();
        setFieldValue("stockPoolNumber", "");
        setFieldValue("newSpFinalDest", false);
      }
    });
  };

  const onAddToExistingSpClick = () => {
    dispatch(
      addToStockPool({
        stockPoolId: values.stockPoolId,
        params: {skuRequestIds: selectedUpperSkuRequests},
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        updateLists();
        setFieldValue("stockPoolId", "");
      }
    });
  };

  const spNewButtonDisable =
    selectedUpperSkuRequests.length === 0 ||
    values.type === "" ||
    values.type === "SP" ||
    values.stockPoolNumber === "";

  const spButtonDisable =
    selectedUpperSkuRequests.length === 0 || values.type === "" || values.type === "newSP" || values.stockPoolId === "";

  const onReturnToWarehouseClick = () => {
    dispatch(
      returnToStock({
        skuRequestIds: selectedLowerSkuRequests,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        updateLists();
      }
    });
  };
  return {
    stockPoolOptions,
    onAddToNewSpClick,
    onAddToExistingSpClick,
    spNewButtonDisable,
    spButtonDisable,
    onReturnToWarehouseClick,
    setSelectedFilters,
    setSelectedSp,
    setSelectedUpperSkuRequests,
    setSelectedLowerSkuRequests,
    selectedLowerSkuRequests,
  };
};

export default useWarehouseSplitCreateModal;
