import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import {addContactNote, fetchContact} from "../../../../redux/operations/FactoryOperations";
import {
  selectContacts,
  selectCurrentContact,
  selectCurrentContactId,
} from "../../../../redux/selectors/FactorySelectors";
import {setCurrentContactId} from "../../../../redux/slices/FactorySlice";

import useContactTypesOptionsService from "./getContactTypesService";

const useNewFactoryContactsService = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const allContacts = useSelector(selectContacts);
  const currentContactId = useSelector(selectCurrentContactId);
  const currentContact = useSelector(selectCurrentContact);

  const contactTypeOptions = useContactTypesOptionsService();
  const contactNotePlaceholer = !currentContactId ? t("Select/add a contact first before adding notes") : "";
  const isContactNoteFieldDisabled = !currentContactId;

  function onEmailContactInputChange(e, setOptions) {
    const inputValue = e.target.value;
    if (/^$/.test(inputValue)) {
      setOptions([]);
      return;
    }

    if (!allContacts) return;
    const matches =
      allContacts
        .filter(contact => contact.email.toLowerCase().startsWith(inputValue.toLowerCase()))
        ?.map(contact => contact.email) ?? [];
    setOptions(matches);
  }

  function onInputSelectItemClick(event, setOptions) {
    if (!allContacts) return;
    const selectedEmail = event.target.textContent;
    const selectedContact = allContacts.find(contact => contact.email === selectedEmail);
    if (!selectedContact) return;
    dispatch(setCurrentContactId(selectedContact.contactId));
    setOptions([]);
  }

  function onSaveContactNoteBtnClick(values) {
    const contactNotes = values.newContactNotes;

    if (!contactNotes) {
      toast.error(t("Enter a note first!"));
      return;
    }

    if (!currentContactId && !currentContact) {
      toast.error(t("Save a contact first!"));
      return;
    }

    dispatch(
      addContactNote({contactId: currentContactId ?? currentContact.contactId, data: {note: contactNotes}}),
    ).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("New contact note saved!"));
        dispatch(fetchContact(currentContactId ?? currentContact.contactId));
      }
    });
    dispatch(fetchContact(currentContactId));
  }

  return {
    contactTypeOptions,
    onEmailContactInputChange,
    onInputSelectItemClick,
    onSaveContactNoteBtnClick,
    contactNotePlaceholer,
    isContactNoteFieldDisabled,
  };
};

export default useNewFactoryContactsService;
