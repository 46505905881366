import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {setCurenCheckLIstFile} from "../../redux/LogistSlice";
import {getCurentCheckListDoc} from "../../redux/selectors";

import {DOCUMENTS_TABLE_COLUMNS} from "./constants";

const DocumentsInfo = ({headerNumber = 4, tableHeight = "7.5em", documentsTableData = null}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const curentDoc = useSelector(getCurentCheckListDoc);
  const documentsTableColumns = useColumns(DOCUMENTS_TABLE_COLUMNS);
  const onselect = id => {
    if (id === null) {
      dispatch(setCurenCheckLIstFile(null));
      return;
    }
    const doc = documentsTableData?.find(doc => doc.id === id);
    if (doc) dispatch(setCurenCheckLIstFile({id: id, url: doc.url}));
  };

  useEffect(() => {
    return () => {
      dispatch(setCurenCheckLIstFile(null));
    };
  }, []);

  return (
    <div className="h100">
      <div className="header-block">{`${headerNumber}. ${t("Documents")}:`}</div>
      <ReactTable
        className="tableTac"
        onSelect={onselect}
        current={curentDoc ? curentDoc.id : null}
        columns={documentsTableColumns}
        defaultData={documentsTableData || []}
        style={{height: tableHeight}}
      />
    </div>
  );
};

DocumentsInfo.propTypes = {headerNumber: PropTypes.string, tableHeight: PropTypes.string};
export default DocumentsInfo;
