import {Component} from "react";

class TemporarilyUnavailable extends Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          height: "90vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label style={{position: "absolute", margin: "0 auto"}}>Service temporarily unavailable</label>
      </div>
    );
  }
}

export default TemporarilyUnavailable;
