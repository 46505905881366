import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchAllWarehouseServiceTypes} from "../../../redux/Warehouses/operations";
import {selectWarehouseServiceTypes} from "../../../redux/Warehouses/selectors";

const useWarehouseServicesTypeOptions = () => {
  const servicesTypes = useSelector(selectWarehouseServiceTypes);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!servicesTypes || servicesTypes?.length === 0) {
      dispatch(fetchAllWarehouseServiceTypes());
    }
  }, []);

  const warehouseServicesTypesOptions = servicesTypes?.map(type => ({title: type?.name, value: type.typeId})) || [];

  return warehouseServicesTypesOptions;
};

export default useWarehouseServicesTypeOptions;
