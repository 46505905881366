import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import ReactTable from "../../../../conteiners/ReactTable";
import {Select} from "../../../../Inputs/SelectField";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {WR_SETTINGS_INFO_TABLE_COLUMNS} from "../constants";

const WrSettingsInfo = ({warehouseOptions, wrSettingsInfoTableData, onWrSettingsWarehouseSelect}) => {
  const {t} = useTranslation();
  const wrSettingsInfoTableColumns = useColumns(WR_SETTINGS_INFO_TABLE_COLUMNS);

  return (
    <div className="fcCol aist h100">
      <p className="mb">{t("Settings information for the selected warehouse (filter)")}</p>
      <Select
        label={t("Choose a Warehouse")}
        name="warehouseInSettingsInfo"
        options={warehouseOptions}
        generalClassName="w100"
        makeEffect={onWrSettingsWarehouseSelect}
      />
      <ReactTable
        tableClassName="fGrow"
        className="tableTac mt fGrow"
        columns={wrSettingsInfoTableColumns}
        defaultData={wrSettingsInfoTableData}
        style={{height: "7rem"}}
        loading={false}
        selectable={false}
      />
    </div>
  );
};

WrSettingsInfo.propTypes = {
  warehouseOptions: PropTypes.array.isRequired,
  onWrSettingsWarehouseSelect: PropTypes.func.isRequired,
  wrSettingsInfoTableData: PropTypes.array.isRequired,
};
export default WrSettingsInfo;
