import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import PageContainer from "../../../conteiners/PageContainer";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../../Inputs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchForCurrentApproval} from "../../../Requests/Proform/redux/operations/ProformOperations";
import {
  selectApprovals,
  selectCurrentApproval,
  selectDisapprovalReasons,
} from "../../../Requests/Proform/redux/selectors/ProformSelectors";
import {setCurrentApproval} from "../../../Requests/Proform/redux/slices/ProformSlice";

import Facsimile from "./Facsimile/Facsimile";
import {PF_CONTROL_BTN_TYPES, PfControlBtn} from "./PfControlBtn/PfControlBtn";
import {ReasonAddingForm} from "./ReasonAddingForm/ReasonAddingForm";
import {DISAPPROVE_DETAILS_TABLE_COLUMNS, PF_CONTROL_TABLE_COLUMNS} from "./utils/constants";

import styles from "./KpPfControl.module.scss";

const KpPfControl = () => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  //redux state
  const approvals = useSelector(selectApprovals);
  const currentApproval = useSelector(selectCurrentApproval);
  const approvalId = currentApproval?.approvalId ?? null;

  useEffect(() => {
    dispatch(fetchForCurrentApproval());
  }, []);

  const nameType = i18n.language === "en" ? "engFirstName" : "firstName";
  const nameType2 = i18n.language === "en" ? "engLastName" : "lastName";

  //data
  const pdfFileUrlArr = currentApproval?.proforma?.pdfFileUrl ? [currentApproval.proforma.pdfFileUrl] : [];
  const controlPfTableColumns = useColumns(PF_CONTROL_TABLE_COLUMNS);

  const approvalsData = useMemo(() => {
    const approvalsMas = [];
    var i = 0;
    approvals?.forEach(appr => {
      if (appr?.proforma?.request) {
        var managerFea = appr?.proforma?.request.managerFea;

        const circleColor = () => {
          switch (true) {
            case !appr.isApproved && !appr.isDeclined:
              return STATUS_COLORS.GREY;
            case appr.isApproved:
              return STATUS_COLORS.GREEN;
            default:
              return STATUS_COLORS.RED;
          }
        };

        approvalsMas.push({
          id: appr.approvalId,
          number: i + 1,
          pfNumber: appr.proforma?.proformaNumber || "",
          coNumber:
            appr?.proforma?.request?.orders?.length > 1
              ? {
                  text: appr?.proforma?.request?.orders?.map(orders => orders.co.coNumber).join(", "),
                  expand: true,
                }
              : appr?.proforma?.request?.orders[0].co.coNumber,
          requestNumber: appr?.proforma?.request?.requestNumber || "",
          managerFEA: managerFea ? managerFea.persona[nameType] + " " + managerFea.persona[nameType2] : "",
          status: circleColor(),
        });
        i++;
      }
    });
    return approvalsMas;
  }, [approvals]);

  const disapprovTableColumns = useColumns(DISAPPROVE_DETAILS_TABLE_COLUMNS);

  const disapprovalReasons = useSelector(selectDisapprovalReasons);

  const disapproveTableData = currentApproval
    ? disapprovalReasons.map((el, index) => ({...el, number: index + 1, id: el.reasonId}))
    : [];

  //initiial values
  const initialValues = {
    pfNumber: currentApproval ? currentApproval.proforma.proformaNumber : "",
    controlPfP: currentApproval ? currentApproval.proforma.request.requestDates[0]?.plannedDate : "",
    controlPfF: currentApproval ? currentApproval.proforma.request.requestDates[0]?.actualDate : "",
    skuNumber: "",
    disapprovReason: "",
    disapprovDescription: "",

    noteForManagerFEA: "",

    createdPin: "",
    repeatedPin: "",
    noteFromManagerFEA: currentApproval
      ? currentApproval?.proforma?.note
        ? currentApproval.proforma.note
        : t("No note from manager FEA")
      : "",
  };

  const onApprovalsTableRowSelect = approvalId => {
    if (!approvalId || (currentApproval && approvalId === currentApproval.approvalId)) {
      dispatch(setCurrentApproval(null));
      return;
    }
    const current = approvals?.find(approval => approval.approvalId === approvalId);
    dispatch(setCurrentApproval(current));
  };

  return (
    <PageContainer title={t("Control PF")}>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
        {({values, resetForm}) => (
          <Form className={`${styles.pageContainer} autolabel`}>
            <div className={styles.leftColumn}>
              <section className={styles.controlSection}>
                <div className={styles.sectionRow}>
                  <h2 className={styles.sectionHeader}>
                    1. <span className={styles.underLined}>{t("Pf Control") + ":"}</span>
                  </h2>
                  <div className={styles.sectionRow}>
                    <TextInput label={t("Pf №") + ":"} name="pfNumber" disabled />
                  </div>
                </div>
                <div className={styles.sectionRow}>
                  <DateInput name="controlPfP" label={t("Date: Pf control (p):")} disabled readOnly />
                  <DateInput name="controlPfF" label={t("Date: Pf control (f):")} disabled readOnly />
                </div>
                <ReactTable
                  className={styles.controlPFTableWrapper}
                  tableClassName={styles.controlPFTable}
                  columns={controlPfTableColumns}
                  defaultData={approvalsData}
                  onSelect={onApprovalsTableRowSelect}
                  current={currentApproval?.approvalId || null}
                />
                <PfControlBtn
                  type={PF_CONTROL_BTN_TYPES.APPROVE}
                  disabled={!currentApproval || currentApproval?.isApproved || currentApproval?.isDeclined}
                  approvalId={approvalId}
                />
              </section>
              <section className={styles.controlSection}>
                <h2 className={styles.sectionHeader}>
                  2. <span className={styles.underLined}>{t("NOT approved") + ":"}</span>
                </h2>
                <ReasonAddingForm values={values} approvalId={approvalId} resetForm={resetForm} />
                <ReactTable
                  style={{height: "100px"}}
                  columns={disapprovTableColumns}
                  current={null}
                  defaultData={disapproveTableData}
                />
                <TextAreaInput
                  name="noteForManagerFEA"
                  className={styles.noteForManagerFEA}
                  width="101%"
                  height="50px"
                  placeholder={t("Enter your note for the manager FEA")}
                />
                <PfControlBtn
                  type={PF_CONTROL_BTN_TYPES.DISAPPROVE}
                  disabled={!currentApproval || currentApproval?.isDeclined || currentApproval?.isApproved}
                  approvalId={approvalId}
                  note={values.noteForManagerFEA}
                />
              </section>
              <Facsimile values={values} currentApproval={currentApproval} resetForm={resetForm} />
            </div>
            <div className={styles.rightColumn}>
              <TextAreaInput
                className={styles.noteFromManagerFEA}
                name="noteFromManagerFEA"
                width="100%"
                height="50px"
                readOnly
              />
              <div className={styles.pdfWrapper}>
                {currentApproval && <NewPdfViewer hiding={false} fileArray={pdfFileUrlArr} />}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

export default KpPfControl;
