import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import {fetchData} from "../../../../hook/axios.hook";
import FilterButton from "../../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

const Filters = ({warehouseId, setFilterProps}) => {
  const [warehousesOption, setWarehouses] = useState([]);
  const [managersOption, setManagers] = useState([]);

  const {
    i18n: {language},
  } = useTranslation();
  const {setFieldValue, values, initialValues, setValues} = useFormikContext();

  useEffect(() => {
    const params = {window: "Warehouse Creating StockPool", warehouseId: warehouseId};
    fetchData(`stockPools/getFilters`, "get", {params}).then(res => {
      let warehouses =
        res.warehouses?.map(item => {
          return {title: item.name || item.address, value: item.warehouseId};
        }) || [];
      let managers =
        res.feaManagers?.map(item => {
          const name = item?.persona
            ? language === "en"
              ? item.persona.engFirstName + " " + item.persona.engLastName
              : item.persona.firstName + " " + item.persona.lastName
            : item.name;
          return {
            title: name,
            value: item.managerFeaId,
          };
        }) || [];
      setWarehouses(warehouses);
      setManagers(managers);
    });
    setFieldValue("warehouseId", warehouseId);
  }, []);

  const onFilter = () => {
    setFilterProps(checkEmptyFields(values));
  };
  const delFilters = () => {
    setFilterProps({warehouseId: warehouseId});
    setValues({...initialValues, warehouseId});
  };

  return (
    <div className="mb fc w100 jcsb">
      <FilterButton
        onFilter={() => onFilter()}
        delFilter={() => delFilters()}
        questionTitle={[t("Filter")]}
        areFiltersDefined={true}
      />

      <Select
        label={t("Warehouse") + ":"}
        options={warehousesOption}
        name="warehouseId"
        width="12em"
        autolabel
        readOnly
      />
      <TextInput label={t("SP №") + ":"} name="stockPoolNumber" width="12em" autolabel />
      <TextInput label={t("Order № ") + ":"} name="orderNumber" width="12em" autolabel />
      <Select label={t("Manager FEA") + ":"} options={managersOption} name="managerFeaId" autolabel />
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({name: "", note: ""}),
  handleSubmit: values => values,
  enableReinitialize: true,
})(Filters);
