import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {fetchData} from "../../../../hook/axios.hook";
import ReminderHoc from "../../../reminder/ReminderHoc";
import ReminderSimple from "../../../reminder/ReminderSimple";
import {remindsFetched} from "../../../reminder/RemindsSlice";

const CheckListModalsTodo = ({path, entityId, tableheight = "10em"}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (entityId) {
      const params = {[path + "Id"]: entityId};
      fetchData(`/${path}s/todos/getAll`, "get", {params}).then(res => {
        dispatch(remindsFetched(res));
      });
    }
    if (!entityId) dispatch(remindsFetched([]));
  }, [entityId]);

  const addRemind = newRemind => {
    return fetchData(`/${path}s/todos/add/${entityId}`, "post", {...newRemind});
  };

  const updateRemind = newRemind => {
    return fetchData(`/${path}s/todos/update/${newRemind.todoId}`, "patch", newRemind.data);
  };

  return (
    <ReminderSimple
      height={tableheight}
      create={addRemind}
      update={updateRemind}
      loading={loading}
      disabled={!entityId}
    />
  );
};

CheckListModalsTodo.propTypes = {forcedUpdate: PropTypes.bool, setGlobalSave: PropTypes.func};

export default CheckListModalsTodo;
