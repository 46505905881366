import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ModalWithTabs from "../../Modal/ModalWithTabs/ModalWithTabs";
import {selectCurrentSkuRequest} from "../redux/selectors";

import LogistRequestSkuTable from "./tabsType/LogistRequestSkuTable";
import OrderCHeckLIst from "./tabsType/OrderCheckLIst";
import RequestCheckList from "./tabsType/RequestCheckList";
import SPCheckLIst from "./tabsType/SPCheckLIst";

const ModCheckList = ({modalShow, setModalShow}) => {
  const {t} = useTranslation();
  const curentSkuRequest = useSelector(selectCurrentSkuRequest);
  const isSpInSkuRequest = Boolean(curentSkuRequest?.stockPool);

  const tabs = [
    {
      label: `${t("Order number")} ${curentSkuRequest?.order?.orderNumber}`,
      index: 0,
    },
    {
      label: `${t("Request №")} ${curentSkuRequest?.request?.requestNumber}`,
      index: 1,
    },
    {
      label: `${t("Stock pool №")} ${curentSkuRequest?.stockPool?.stockPoolNumber ?? ""}`,
      index: 2,
      disabled: !isSpInSkuRequest,
      tooltipTitle: !isSpInSkuRequest ? "No stockpool" : "",
    },
    {
      label: `${t("SKU у Замовленні")} `,
      index: 3,
    },
  ];
  return (
    <>
      <ModalWithTabs
        closable={true}
        active={modalShow}
        setModalActive={setModalShow}
        modalTabs={tabs}
        closableTabs={false}
      >
        <OrderCHeckLIst index={0} />
        <RequestCheckList index={1} />
        <SPCheckLIst index={2} />
        <LogistRequestSkuTable index={3} requestSkus={curentSkuRequest?.sku} />
      </ModalWithTabs>
    </>
  );
};

export default ModCheckList;
