export const getClientsList = () => {
  const promise = new Promise(resolve => {
    setTimeout(() => {
      resolve(["11-NOK", "12-NOK"]);
    }, 200);
  });
  return promise;
};

export const getManagersList = () => {
  const promise = new Promise(resolve => {
    setTimeout(() => {
      resolve(["Тарас Шевченко", "менеджер Виталька"]);
    }, 200);
  });
  return promise;
};

export const getDesignersrsList = () => {
  const promise = new Promise(resolve => {
    setTimeout(() => {
      resolve(["дизайнер Артур", "дизайнер Луи"]);
    }, 200);
  });
  return promise;
};
