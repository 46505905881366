import React from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import ReactTable from "../../../../conteiners/ReactTable";
import {Select} from "../../../../Inputs/SelectField";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {START_DATES_TABLE_COLUMNS} from "../constants";

const StartDates = ({startDateOptions, startDatesTableData, onStartDatesTableSelect, currentDateId}) => {
  const {t} = useTranslation();
  const startDatesTableColumns = useColumns(START_DATES_TABLE_COLUMNS);
  const {setValues} = useFormikContext();

  return (
    <div className="fcCol aist jcfs">
      <Select
        label={t("Start Date")}
        name="startDate"
        options={startDateOptions}
        readOnly
        question={"Select from the table below"}
      />
      <ReactTable
        tableClassName="fGrow"
        className="tableTac mt fGrow"
        columns={startDatesTableColumns}
        defaultData={startDatesTableData}
        current={currentDateId}
        onSelect={dateId => onStartDatesTableSelect({dateId, setValues})}
        style={{height: "7rem"}}
        loading={false}
      />
    </div>
  );
};

StartDates.propTypes = {
  startDateOptions: PropTypes.array.isRequired,
  startDatesTableData: PropTypes.array.isRequired,
  onStartDatesTableSelect: PropTypes.func.isRequired,
  currentDateId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
};
export default StartDates;
