import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import flatpickr from "flatpickr";
import PropTypes from "prop-types";

import calendarIcon from "../../../images/icons/calendar.png";
import QuestionItem from "../../../utilities/QuestionItem";
import {Ukrainian} from "../utils/ukrLocale";

import "flatpickr/dist/flatpickr.min.css";

export const DateField = ({
  label,
  className = "",
  question,
  labelWrap,
  labelW,
  flatpikrConfig,
  setValue = null,
  value,
  disabled = false,
  ...props
}) => {
  const inputref = useRef();
  const [date, setDateValue] = useState("");
  const {t, i18n} = useTranslation();
  const [flatpickrInstance, setInstanse] = useState(null);

  useEffect(() => {
    setInstanse(
      flatpickr(inputref.current, {
        dateFormat: "d.m.Y",
        clickOpens: false,
        weekNumbers: true,
        locale: i18n.language === "en" ? "en" : Ukrainian,
        onChange: function (selectedDates, dateStr, instance) {
          // if (value) {
          setValue(selectedDates[0].toJSON());
          // }
        },
        ...flatpikrConfig,
      }),
    );

    return () => {
      // Очистка экземпляра Flatpickr при размонтировании компонента
      setInstanse(null);
    };
  }, []);

  useEffect(() => {
    const newValue = value;

    if (typeof newValue === "string" && newValue !== "" && flatpickrInstance) {
      var d = flatpickr.formatDate(new Date(newValue), "d.m.Y");
      setDateValue(d);
      if (d) flatpickrInstance.setDate(new Date(newValue), false);
    } else if ((!newValue || newValue === "") && flatpickrInstance) {
      setDateValue("");
      flatpickrInstance.setDate("", false);
    }
  }, [value, flatpickrInstance]);

  const inp = useMemo(() => {
    return (
      <input
        className="calendar"
        ref={inputref}
        value={date}
        type="text"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        style={{width: "9em", cursor: "auto"}}
        readOnly={true} // Отключение возможности ввода текста, так как Flatpickr будет отвечать за выбор даты
      />
    );
  }, [date]);

  // useEffect(()=>{
  //   setValue(va)
  // },[date])

  return (
    <div className={`project_input ${labelWrap ? "" : "labelWrap"}`} style={{position: "relative"}}>
      <div className={`${className} datePicker_withText`}>
        <label htmlFor={props.name} style={{marginRight: "5px", width: labelW || "auto"}} className={"autolabel"}>
          {typeof label === "string" ? t(label) : label}
        </label>
        {inp}
        {!props.readOnly && (
          <img
            className="datePicker_img"
            src={calendarIcon}
            style={{cursor: disabled ? "auto" : "pointer"}}
            alt=""
            onClick={() => {
              if (disabled) return;
              flatpickrInstance && flatpickrInstance.open();
            }}
          />
        )}
        {question ? (
          <div className="question-date">
            <QuestionItem title={question} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

DateField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  props: PropTypes.array,
};

export default DateField;
